export default {
  DAY_ALIGN: '|',
  HIGH_ALIGN: '|',
  LOW_ALIGN: '|',

  DAY_WIDTH_RATIO: 0.196,
  DAY_HEIGHT_RATIO: 0.0791,
  DAY_ONE_POSITION_X_RATIO: 0.0934,
  DAY_ONE_POSITION_Y_RATIO: 0.0771,
  DAY_TWO_POSITION_X_RATIO: 0.4082,
  DAY_TWO_POSITION_Y_RATIO: 0.0771,
  DAY_THREE_POSITION_X_RATIO: 0.7225,
  DAY_THREE_POSITION_Y_RATIO: 0.0771,

  ICON_WIDTH_RATIO: 0.3565,
  ICON_HEIGHT_RATIO: 0.6313,
  ICON_ONE_POSITION_X_RATIO: -0.0387,
  ICON_ONE_POSITION_Y_RATIO: 0.1417,
  ICON_TWO_POSITION_X_RATIO: 0.2761,
  ICON_TWO_POSITION_Y_RATIO: 0.1417,
  ICON_THREE_POSITION_X_RATIO: 0.5904,
  ICON_THREE_POSITION_Y_RATIO: 0.1417,

  HIGH_WIDTH_RATIO: 0.2129,
  HIGH_HEIGHT_RATIO: 0.2493,
  HIGH_ONE_POSITION_X_RATIO: 0.0849,
  HIGH_ONE_POSITION_Y_RATIO: 0.5844,
  HIGH_TWO_POSITION_X_RATIO: 0.3997,
  HIGH_TWO_POSITION_Y_RATIO: 0.5844,
  HIGH_THREE_POSITION_X_RATIO: 0.714,
  HIGH_THREE_POSITION_Y_RATIO: 0.5844,

  LOW_WIDTH_RATIO: 0.1,
  LOW_HEIGHT_RATIO: 0.1349,
  LOW_ONE_POSITION_X_RATIO: 0.1414,
  LOW_ONE_POSITION_Y_RATIO: 0.7851,
  LOW_TWO_POSITION_X_RATIO: 0.4562,
  LOW_TWO_POSITION_Y_RATIO: 0.7851,
  LOW_THREE_POSITION_X_RATIO: 0.7705,
  LOW_THREE_POSITION_Y_RATIO: 0.7851,
};
