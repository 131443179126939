import PropTypes from 'prop-types';
import React from 'react';
import {Modal, ModalBody, ModalFooter} from 'reactstrap';

import './unsavedModal.scss';

/**
 * The UnsavedModal component.
 */
class UnsavedModal extends React.Component {
  /**
   * Modal text
   *
   * @type {string}
   */
  content = '';

  /**
   * Triggered when the user clicks discard.
   */
  onDiscard = () => {
    const {onDiscard} = this.props;

    onDiscard();
  };

  /**
   * Triggered when the user clicks cancel.
   */
  onCancel = () => {
    const {onCancel} = this.props;

    onCancel();
  };

  /**
   * Triggered when the user clicks save.
   */
  onSave = () => {
    const {onSave} = this.props;

    onSave();
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {hasError, isOpen} = this.props;

    if (!isOpen) {
      return null;
    }

    return (
      <Modal className="unsaved-modal" isOpen={isOpen} toggle={this.onCancel} centered>
        <ModalBody>
          {(hasError) ? (
            <p className="site-label">
              Changes cannot be saved due to errors.
              <br />
              Do you want to discard your changes?
            </p>
          ) : (
            <p className="site-label">
              Do you want to save your changes?
            </p>
          )}
          <p className="site-label">{this.content}</p>
        </ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-default" onClick={this.onDiscard}>
            Discard
          </button>
          <button type="button" className="btn btn-default" onClick={this.onCancel}>
            Cancel
          </button>
          {(!hasError) && (
            <button type="button" className="btn btn-dark" onClick={this.onSave}>
              Save
            </button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

UnsavedModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,

  hasError: PropTypes.bool,
  onCancel: PropTypes.func,
  onDiscard: PropTypes.func,
  onSave: PropTypes.func,
};

export default UnsavedModal;
