import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import CollectionSidebarList from '../../../pages/dashboard/components/collectionSidebarList/CollectionSidebarList';
import ContentItems from '../../../common/contentItems/ContentItems';
import {CONTENT_LIST_COLLECTION} from '../../../../constants/contentConstants';

/**
 * The PublishReplace component.
 */
export class PublishReplace extends React.Component {
  /**
   * The selected collection id used to search for the content.
   *
   * @type {?number}
   */
  @observable collectionId = null;

  /**
   * Sets the collection id.
   *
   * @param {?{id: number}} selectedCollection
   */
  @action setCollectionId = (selectedCollection) => {
    this.collectionId = (selectedCollection) ? selectedCollection.id : null;
  };

  /**
   * Called when content is selected.
   *
   * @param {number} selectedContentId
   */
  @action onContentSelected = (selectedContentId) => {
    const {onContentSelected} = this.props;
    onContentSelected(selectedContentId, this.collectionId);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      contentId,
      user,
    } = this.props;

    return (
      <div className="publish-replace d-flex">
        <CollectionSidebarList
          onSelect={this.setCollectionId}
          selected={this.collectionId}
          user={user}
        />

        <div className="flex-fill pb-5 pl-3">
          {(this.collectionId) ? (
            <h4 className="choose-template">Choose which template to replace</h4>
          ) : (
            <h4 className="choose-collection">Choose a collection</h4>
          )}

          {(this.collectionId) && (
            <ContentItems
              activeContentId={contentId}
              onContentSelect={this.onContentSelected}
              contentListId={this.collectionId}
              contentListType={CONTENT_LIST_COLLECTION}
            />
          )}
        </div>
      </div>
    );
  }
}

PublishReplace.propTypes = {
  onContentSelected: PropTypes.func.isRequired,

  contentId: PropTypes.number,
  user: PropTypes.object,
};

PublishReplace.defaultProps = {};

export default observer(PublishReplace);
