import React from 'react';
import PropTypes from 'prop-types';
import {withAuth0} from '@auth0/auth0-react';

/**
 * The SignupLinkPage component.
 *
 * This component is needed so external sites can link to the apps auth0 signup page.
 * With this, an external site can use the link https://app.projectcontent.com/signup-link
 * Now this app will generate the necesary auth0 signup route and redirect to it.
 *
 * This is needed because the auth0 signup page needs query params that can only be
 * generated by this app. These query params expire after a period of time.
 */
export class SignupLinkPage extends React.Component {
  /**
   * Redirects user to auth0 signup page on load/mount.
   */
  componentDidMount() {
    const {auth0} = this.props;
    auth0.loginWithRedirect({
      authorizationParams: {
        'screen_hint': 'signup',
      }});
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    return (<></>
    );
  }
}

SignupLinkPage.propTypes = {
  auth0: PropTypes.object,
};

export default withAuth0(SignupLinkPage);
