import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {observable, action} from 'mobx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';

import OpacitySelect from '../opacitySelect/OpacitySelect';

/**
 * The AdvancedFeedIconControls component.
 */
export class AdvancedFeedIconControls extends React.Component {
  /**
   * Whether or not the opacity control group is open.
   *
   * @type {boolean}
   */
  @observable isOpacityControlGroupOpen = false;

  @action toggleOpacityControlGroup = () => {
    this.isOpacityControlGroupOpen = !this.isOpacityControlGroupOpen;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      /** @type {DisplayEditorStore} */ displayEditorStore,
      /** @type {ObservableMap} */ entity,
      /** @type {GameStore} */ game,
    } = this.props;

    if (game.composeMode) {
      // Compose mode disables editing these values.
      return null;
    }

    return (
      <div className="advanced-feed-icon-controls control-group">

        <div className="group-header">
          <span className="group-header-label">Opacity</span>
          <button
            type="button"
            className="btn"
            onClick={this.toggleOpacityControlGroup}
          >
            <FontAwesomeIcon icon={this.isOpacityControlGroupOpen ? faChevronUp : faChevronDown} />
          </button>
        </div>

        {this.isOpacityControlGroupOpen && (<div className="group-controls">
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="stroke-selector-input">Opacity</label>
                <OpacitySelect
                  displayEditorStore={displayEditorStore}
                  game={game}
                  entity={entity}
                />
              </div>
            </div>
          </div>
        </div>)}
      </div>
    );
  }
}

AdvancedFeedIconControls.propTypes = {
  displayEditorStore: PropTypes.object.isRequired,
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(AdvancedFeedIconControls);
