import classNames from 'classnames';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import {onClickEntityEvent} from '../utils';

/**
 * The DisplayFeedIcon component.
 */
export class DisplayFeedIcon extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, game, style, topStyle, className, onEntityClick} = this.props;

    const entityId = entity.get('id');
    const feedicon = entity.get('feedicon');

    if (!feedicon.url) {
      return null;
    }

    const imageSource = feedicon.url;

    const imageId = `display-feed-icon-el-${entityId}`;

    const onClickEvent = onClickEntityEvent(game, entity, onEntityClick);

    return (
      <div
        id={entityId}
        className={classNames('display-feed-icon', className)}
        style={{
          ...topStyle,
          pointerEvents: 'none',
        }}
      >
        <div
          style={{
            ...style,
            opacity: feedicon.opacity
          }}
        >
          <img
            id={imageId}
            height={style.height}
            width={style.width}
            src={imageSource}
            style={{
              cursor: onClickEvent ? 'pointer' : 'inherit',
              pointerEvents: 'auto',
              objectFit: 'contain',
            }}
            onClick={onClickEvent}
          />
        </div>
      </div>
    );
  }
}

DisplayFeedIcon.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
  style: PropTypes.object.isRequired,
  topStyle: PropTypes.object.isRequired,

  className: PropTypes.string,
  onEntityClick: PropTypes.func,
};

export default observer(DisplayFeedIcon);
