import {toJS} from 'mobx';
import PropTypes from 'prop-types';
import React from 'react';
import NumericInput from 'react-numeric-input';

import {actionUpdateComponent} from '../../../../../display/components/action/actionUpdateComponent';
import {getComposeForSource} from '../../../../../display/components/common/composeComponent';
import {
  DEFAULT_STROKE,
  getTextForSource,
  getTextFromSource
} from '../../../../../display/components/type/textComponent';

import './strokeSizeSelect.scss';

/**
 * The minimum stroke size.
 * @const {string}
 */
const STROKE_SIZE_MIN = 0;

/**
 * The maximum stroke size.
 * @const {string}
 */
const STROKE_SIZE_MAX = 100;

/**
 * The StrokeSizeSelect component.
 *
 * @param {{}} props
 * @param {DisplayEditorStore} props.displayEditorStore
 * @param {ObservableMap} props.entity
 * @param {GameStore} props.game
 * @returns {React.Component}
 */
export const StrokeSizeSelect = (props) => {
  const {
    /** @type DisplayEditorStore} */ displayEditorStore,
    /** @type {ObservableMap} */ entity,
    /** @type {GameStore} */ game,
  } = props;

  /**
   * Updates the text entity when it is changed.
   *
   * @param {number} valueAsNumber
   */
  const onChange = (valueAsNumber) => {
    const actionParams = {
      entityId: entity.get('id'),
    };

    const composeSource = getComposeForSource(entity);
    const textSource = getTextForSource(entity);
    if (!textSource.text.stroke) {
      textSource.text.stroke = {...DEFAULT_STROKE};
    }
    textSource.text.stroke.width = valueAsNumber;

    game.addAction(actionParams, actionUpdateComponent(
      getTextFromSource(
        {...textSource, ...composeSource},
        toJS(displayEditorStore.variables)
      )
    ));
  };

  const text = entity.get('text');
  const value = (text.stroke) ? text.stroke.width : 0;

  return (
    <div className="stroke-size-select">
      <NumericInput
        id="stroke-selector-input"
        className="form-control form-control-sm"
        min={STROKE_SIZE_MIN}
        max={STROKE_SIZE_MAX}
        value={value}
        onChange={onChange}
        mobile
      />
    </div>
  );
};

StrokeSizeSelect.propTypes = {
  displayEditorStore: PropTypes.object.isRequired,
  entity: PropTypes.object.isRequired,
  game: PropTypes.object.isRequired,
};

export default StrokeSizeSelect;
