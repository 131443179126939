import React from 'react';
import PropTypes from 'prop-types';
import {withAuth0} from '@auth0/auth0-react';

import mainConfig from '../../../config/main';

import './logoutPage.scss';

/**
 * The LogoutPage component.
 */
export class LogoutPage extends React.Component {
  /**
   * Logs out the user when the page is loaded/mounted.
   */
  componentDidMount() {
    const {auth0} = this.props;
    auth0.logout({
      logoutParams: {
        returnTo: `${mainConfig.app.url}/dashboard`,
      },
    });
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    return (
      <div id="logout-page" className="system-page p-3">
        <div className="alert alert-info">
          Logging You Out
        </div>
      </div>
    );
  }
}

LogoutPage.propTypes = {
  auth0: PropTypes.object,
};

export default withAuth0(LogoutPage);
