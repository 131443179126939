import lodash from 'lodash';
import {runInAction, toJS} from 'mobx';

import {PERCENT, PIXEL} from '../components/action/actionUnitComponent';
import {clearTransitionCache, setEntityComponents, updateEntity} from '../ecs/entityHelper';

/**
 * The name of the system.
 * @const {string}
 */
export const ACTION_UNIT_SYSTEM = 'actionUnitSystem';

/**
 * The number to divide by to convert to a percentage.
 *
 * @const {number}
 */
const TO_PERCENT = 100;

/**
 * Gets a new instance of the action unit system.
 *
 * @param {GameStore} game
 * @returns {{name: string, runActions: systemRunActions}}
 */
export function actionUnitSystem(game) {
  /**
   * Called right before the game loop updates.
   *
   * @param {ObservableArray} actions
   */
  function systemRunActions(actions) {
    actions.forEach((actionEntity) => {
      // First check for required components.
      if (!actionEntity.has('actionUnit')) {
        return;
      }

      const action = actionEntity.get('action');
      const unitUpdates = actionEntity.get('actionUnit');
      const entity = game.getEntity(action.entityId);
      if (!entity) {
        return;
      }

      const {position, size} = unitUpdates;
      if (!position && !size) {
        return;
      }

      const safeSize = toJS(size);
      const safePosition = toJS(position);

      updateSize(entity, safeSize);
      updatePosition(entity, safePosition);
      updateImage(entity, safeSize, safePosition);

      clearTransitionCache(entity);
    });
  }

  /**
   * Updates the size component for the entity.
   *
   * @param {{}} entity
   * @param {{}} size
   */
  function updateSize(entity, size) {
    if (!size || !lodash.size(size)) {
      return;
    }

    const currentSize = entity.get('size');
    const newSize = lodash.cloneDeep(currentSize);
    if (size.height) {
      const {newValue, isPercent} = getNewSizeParam(true, size.height, currentSize);
      if (newValue !== null) {
        newSize.height = newValue;
        newSize.heightIsPercent = isPercent;
        newSize.default.height = newValue;
        newSize.default.heightIsPercent = isPercent;
      }
    }
    if (size.width) {
      const {newValue, isPercent} = getNewSizeParam(false, size.width, currentSize);
      if (newValue !== null) {
        newSize.width = newValue;
        newSize.widthIsPercent = isPercent;
        newSize.default.width = newValue;
        newSize.default.widthIsPercent = isPercent;
      }
    }

    updateEntityWithParams(entity, 'size', newSize);
  }

  /**
   * Updates the image component for the entity.
   *
   * @param {{}} entity
   * @param {{}} size
   * @param {{}} position
   */
  function updateImage(entity, size, position) {
    if (!entity || !entity.get('image')) {
      return;
    }

    const currentImage = entity.get('image');
    const newImage = lodash.cloneDeep(currentImage);

    if (size && lodash.size(size)) {
      if (size.height) {
        const {newValue, isPercent} = getNewSizeParam(true, size.height, currentImage);
        if (newValue !== null) {
          newImage.height = newValue;
          newImage.heightIsPercent = isPercent;
          newImage.default.height = newValue;
          newImage.default.heightIsPercent = isPercent;
        }
      }
      if (size.width) {
        const {newValue, isPercent} = getNewSizeParam(false, size.width, currentImage);
        if (newValue !== null) {
          newImage.width = newValue;
          newImage.widthIsPercent = isPercent;
          newImage.default.width = newValue;
          newImage.default.widthIsPercent = isPercent;
        }
      }
    }

    if (position && lodash.size(position)) {
      if (position.y) {
        const {newValue, isPercent} = getNewPositionParam(true, position.y, currentImage);
        if (newValue !== null) {
          newImage.y = newValue;
          newImage.yIsPercent = isPercent;
          newImage.default.y = newValue;
          newImage.default.yIsPercent = isPercent;
        }
      }
      if (position.x) {
        const {newValue, isPercent} = getNewPositionParam(false, position.x, currentImage);
        if (newValue !== null) {
          newImage.x = newValue;
          newImage.xIsPercent = isPercent;
          newImage.default.x = newValue;
          newImage.default.xIsPercent = isPercent;
        }
      }
    }

    updateEntityWithParams(entity, 'image', newImage);
  }

  /**
   * Updates the position component for the entity.
   *
   * @param {{}} entity
   * @param {{}} position
   */
  function updatePosition(entity, position) {
    if (!position || !lodash.size(position)) {
      return;
    }

    const currentPosition = entity.get('position');
    const newPosition = lodash.cloneDeep(currentPosition);

    if (position.y) {
      const {newValue, isPercent} = getNewPositionParam(true, position.y, currentPosition);

      if (newValue !== null) {
        newPosition.y = newValue;
        newPosition.yIsPercent = isPercent;
        newPosition.default.y = newValue;
        newPosition.default.yIsPercent = isPercent;
      }
    }
    if (position.x) {
      const {newValue, isPercent} = getNewPositionParam(false, position.x, currentPosition);

      if (newValue !== null) {
        newPosition.x = newValue;
        newPosition.xIsPercent = isPercent;
        newPosition.default.x = newValue;
        newPosition.default.xIsPercent = isPercent;
      }
    }

    updateEntityWithParams(entity, 'position', newPosition);
  }

  /**
   * Gets the new size parameters.
   *
   * @param {boolean} isHeight
   * @param {string} unit
   * @param {{}} currentSize
   * @param {boolean} isCrop
   * @returns {{newValue: (number|string), isPercent: boolean}}
   */
  function getNewSizeParam(isHeight, unit, currentSize, isCrop) {
    let currentIsPercent = true;
    if (!isCrop) {
      currentIsPercent = (isHeight) ? currentSize.default.heightIsPercent : currentSize.default.widthIsPercent;
    }

    if (currentIsPercent && unit === PERCENT) {
      return {newValue: null, isPercent: false};
    } else if (!currentIsPercent && unit === PIXEL) {
      return {newValue: null, isPercent: false};
    }

    const gameResolution = (isHeight) ? game.resolution.height : game.resolution.width;
    const size = currentSize.default ? currentSize.default : currentSize;
    const currentValue = (isHeight) ? size.height : size.width;

    if (unit === PIXEL) {
      const currentPercentage = Number.parseFloat(currentValue);
      const toPixels = Number((currentPercentage / TO_PERCENT) * gameResolution);

      return {
        newValue: toPixels,
        isPercent: false,
      };
    }

    const decimalLimit = 4;
    const toPercent = (Number.parseFloat(currentValue) / gameResolution) * TO_PERCENT;

    return {
      newValue: Number.parseFloat(toPercent).toFixed(decimalLimit) + '%',
      isPercent: true,
    };
  }

  /**
   * Gets the new position parameters.
   *
   * @param {boolean} isY
   * @param {string} unit
   * @param {{}} currentPosition
   * @param {boolean} isCrop
   * @returns {{newValue: (number|string), isPercent: boolean}}
   */
  function getNewPositionParam(isY, unit, currentPosition, isCrop) {
    const position = (currentPosition.default) ? currentPosition.default : currentPosition;
    let currentIsPercent = true;
    if (!isCrop) {
      currentIsPercent = (isY) ? position.yIsPercent : position.xIsPercent;
    }

    if (currentIsPercent && unit === PERCENT) {
      return {newValue: null, isPercent: false};
    } else if (!currentIsPercent && unit === PIXEL) {
      return {newValue: null, isPercent: false};
    }

    const gameResolution = (isY) ? game.resolution.height : game.resolution.width;
    const currentValue = (isY) ? position.y : position.x;

    if (unit === PIXEL) {
      const currentPercentage = Number.parseFloat(currentValue);
      const toPixels = Number((currentPercentage / TO_PERCENT) * gameResolution);

      return {
        newValue: toPixels,
        isPercent: false,
      };
    }

    const decimalLimit = 4;
    const toPercent = (Number.parseFloat(currentValue) / gameResolution) * TO_PERCENT;

    return {
      newValue: Number.parseFloat(toPercent).toFixed(decimalLimit) + '%',
      isPercent: true,
    };
  }

  /**
   * Updates the given entity with the differences given.
   *
   * @param {{}} entity
   * @param {string} componentName
   * @param {{}} params
   */
  function updateEntityWithParams(entity, componentName, params) {
    runInAction('actionUnitSystemUpdateEntity', () => {
      if (entity.has(componentName)) {
        updateEntity(entity, componentName, params);
      } else if (params !== null) {
        setEntityComponents(entity, {[componentName]: params});
      }
    });
  }

  return {
    name: ACTION_UNIT_SYSTEM,
    runActions: systemRunActions
  };
}
