import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * The TransitionIndicator component.
 */
class TransitionIndicator extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {controlOffset, direction, fillColor, offsetX, offsetY} = this.props;

    const style = {
      cursor: 'wait'
    };

    const clockSize = 40;
    const halfClockSize = clockSize / 2;
    const lineLength = controlOffset + 40; // eslint-disable-line no-magic-numbers

    let cx = null;
    let cy = null;
    let clockX = null;
    let clockY = null;

    let endPoint = null;
    if (direction === 'down') {
      cx = offsetX;
      cy = offsetY + lineLength;

      clockX = offsetX - halfClockSize;
      clockY = offsetY + lineLength - halfClockSize;

      endPoint = `L ${offsetX},${offsetY + lineLength}`;
    } else if (direction === 'left') {
      cx = offsetX - lineLength;
      cy = offsetY;

      clockX = offsetX - lineLength;
      clockY = offsetY - halfClockSize;

      endPoint = `L ${offsetX - lineLength},${offsetY}`;
    } else if (direction === 'left') {
      cx = offsetX + lineLength;
      cy = offsetY;

      clockX = offsetX + lineLength;
      clockY = offsetY - halfClockSize;

      endPoint = `L ${offsetX + lineLength},${offsetY}`;
    } else {
      cx = offsetX;
      cy = offsetY - lineLength;

      clockX = offsetX - halfClockSize;
      clockY = offsetY - lineLength - halfClockSize;

      endPoint = `L ${offsetX},${offsetY - lineLength}`;
    }

    const linePath = [
      `M ${offsetX},${offsetY}`,
      endPoint,
      'Z',
    ].join(' ');

    const clockPath = 'M11.088,2.542c0.063-0.146,0.103-0.306,0.103-0.476c0-0.657-0.534-1.19-1.19-1.19c-0.657'
      + ',0-1.19,0.533-1.19,1.19c0,0.17,0.038,0.33,0.102,0.476c-4.085,0.535-7.243,4.021-7.243,8.252c0,4.601'
      + ',3.73,8.332,8.332,8.332c4.601,0,8.331-3.73,8.331-8.332C18.331,6.562,15.173,3.076,11.088,2.542z M10'
      + ',1.669c0.219,0,0.396,0.177,0.396,0.396S10.219,2.462,10,2.462c-0.22,0-0.397-0.177-0.397-0.396S9.78'
      + ',1.669,10,1.669z M10,18.332c-4.163,0-7.538-3.375-7.538-7.539c0-4.163,3.375-7.538,7.538-7.538c4.162'
      + ',0,7.538,3.375,7.538,7.538C17.538,14.957,14.162,18.332,10,18.332z M10.386,9.26c0.002-0.018,0.011-0.034'
      + ',0.011-0.053V5.24c0-0.219-0.177-0.396-0.396-0.396c-0.22,0-0.397,0.177-0.397,0.396v3.967c0,0.019,0.008'
      + ',0.035,0.011,0.053c-0.689,0.173-1.201,0.792-1.201,1.534c0,0.324,0.098,0.625,0.264,0.875c-0.079'
      + ',0.014-0.155,0.043-0.216,0.104l-2.244,2.244c-0.155,0.154-0.155,0.406,0,0.561s0.406,0.154,0.561'
      + ',0l2.244-2.242c0.061-0.062,0.091-0.139,0.104-0.217c0.251,0.166,0.551,0.264,0.875,0.264c0.876,0'
      + ',1.587-0.711,1.587-1.587C11.587,10.052,11.075,9.433,10.386,9.26z M10,11.586c-0.438'
      + ',0-0.793-0.354-0.793-0.792c0-0.438,0.355-0.792,0.793-0.792c0.438,0,0.793,0.355,0.793,0.792C10.793'
      + ',11.232,10.438,11.586,10,11.586z';

    return (
      <g className="transition-path" pointerEvents="visiblePainted">
        <path
          stroke={fillColor}
          strokeOpacity="1"
          strokeWidth="4"
          strokeLinecap="square"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
          pointerEvents="visiblePainted"
          d={linePath}
          style={style}
        />

        <g transform={`translate(${clockX},${clockY})`}>
          <svg
            className="svg-icon"
            fillRule="evenodd"
            fill="none"
            stroke="none"
            strokeLinecap="square"
            strokeMiterlimit="10"
            overflow="hidden"
            preserveAspectRatio="none"
            pointerEvents="none"
            viewBox="0 0 20 20"
            width="40"
            height="40"
          >
            <path
              stroke={fillColor}
              strokeOpacity="1"
              strokeWidth="1"
              strokeLinecap="square"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              pointerEvents="visiblePainted"
              d={clockPath}
              style={style}
            />
          </svg>
        </g>

        <text
          className="transition-label"
          x={cx}
          y={cy}
          dx={10}
          dy={10}
        >Transitioning</text>
      </g>
    );
  }
}

TransitionIndicator.propTypes = {
  fillColor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  offsetX: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  offsetY: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

  controlOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
};

TransitionIndicator.defaultProps = {
  controlOffset: 0,
  direction: 'up',
};

export default observer(TransitionIndicator);
