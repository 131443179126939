import {action, observable, when} from 'mobx';
import {includes} from 'lodash';

import {EXPIRES_IN, EXPIRE_TIME} from '../../../constants/storeConstants';
import {STATE_FULFILLED, STATE_PENDING, STATE_PRE, STATE_REJECTED} from '../../../constants/asyncConstants';
import {getCase} from '../../../utils/apiStore';

/**
 * ApiBaseStore
 */
class ApiBaseStore {
  /**
   * State of the store
   *
   * @type {string}
   */
  @observable state = STATE_PRE;

  /**
   * Expiration time of what is in the store
   *
   * @type {?Date}
   */
  @observable expireTime = null;

  /**
   * Source of truth data for the store
   *
   * @type {any}
   */
  @observable data = null;

  /**
   * Error for the store
   *
   * @type {?Error}
   */
  @observable error = null;

  /**
   * Gets the fulfilled value of the store.
   * This is used in case().
   *
   * @returns {?{}}
   */
  getFulfilled() {
    return this.data;
  }

  /**
   * Gets the rejected value of the store.
   * This is used in case().
   *
   * @returns {?Error}
   */
  getRejected() {
    return this.error;
  }

  /**
   * Gets the state of the store
   *
   * @returns {string}
   */
  getState() {
    return this.state;
  }

  /**
   * Clears all the store's info
   */
  @action clear() {
    this.state = STATE_PRE;
    this[EXPIRE_TIME] = null;
    this.data = null;
    this.error = null;
  }

  /**
   * Replace the store's data and reset expiration
   *
   * @param {any} data
   */
  @action replace(data) {
    this.state = STATE_FULFILLED;
    this[EXPIRE_TIME] = Date.now() + EXPIRES_IN;
    this.data = data;
    this.error = null;
  }

  /**
   * Refresh data if necessary
   *  Main method to use when you want to use this store
   *
   * @param {boolean} force Whether or not to force the refresh
   */
  refresh(force) {
    if (force || !this.isDataAvailable()) {
      this.clear();

      this.makeRequest();
    }
  }

  /**
   * Checks if item is available in the store
   *  True if state = pending or fulfilled, and not expired
   *
   * @returns {boolean}
   */
  isDataAvailable() {
    const isValidState = includes([STATE_PENDING, STATE_FULFILLED], this.state);
    const isNotExpired = (this[EXPIRE_TIME] > Date.now());

    return (isValidState && isNotExpired);
  }

  /**
   * Makes the specified server request
   *  This should be overwritten in every class that extends this.
   */
  @action makeRequest() {
    this.state = STATE_REJECTED;
    const notOverwrittenError = new Error(
      `The makeRequest function was not overwritten in the ${this.constructor.name} class declaration`
    );

    this.error = notOverwrittenError;

    throw notOverwrittenError;
  }

  /**
   * Runs handlers based on changes in the state.
   *
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  case(handlers) {
    const getFulfilled = () => this.getFulfilled();
    const getRejected = () => this.getRejected();

    return getCase(this.state, getFulfilled, getRejected, handlers);
  }

  /**
   * Gets a promise for this store.
   *
   * @returns {Promise}
   */
  getPromise() {
    return new Promise((resolve, reject) => {
      when(
        () => {
          return (this.state === STATE_FULFILLED || this.state === STATE_REJECTED);
        },
        () => {
          if (this.state === STATE_REJECTED) {
            reject(this.getRejected());
            return;
          }

          resolve(this.getFulfilled());
        },
        {name: `${this.constructor.name}GetPromise`}
      );
    });
  }
}

export default ApiBaseStore;
export const doNotInject = true;
