import interact from 'interactjs/src/index';
import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {findDOMNode} from 'react-dom';

import {between} from '../../../../utils/mathHelper';

/**
 * A higher order component wrapper that handles making a slider handle's draggable.
 *
 * @param {Object} WrappedComponent
 * @returns {Object}
 */
export default function sliderInteractHocWrapper(WrappedComponent) {
  /**
   * The SliderInteractHoc higher ticket component.
   */
  class SliderInteractHoc extends React.Component {
    /**
     * The slider react component.
     *
     * @type {?{}}
     */
    @observable slider = null;

    /**
     * The DOM elements for the slider handles.
     *
     * @type {Array.<HTMLElement>}
     */
    @observable handleEls = [];

    /**
     * The interactJS objects initialized on the handles.
     *
     * @type {Array.<{draggable: function, resizable: function}>}
     */
    @observable interactions = [];

    /**
     * @constructor
     * @param {{}} props
     * @param {{}} componentContext
     */
    constructor(props, componentContext) {
      super(props, componentContext);
    }

    /**
     * Triggered when the component just mounted to the page.
     */
    componentDidMount() {
      this.initInteraction();
    }

    /**
     * Triggered when the component is about to unmount.
     */
    componentWillUnmount() {
      this.stopInteraction();
    }

    /**
     * Triggered right after the wrapped component is added to OR removed from the page.
     *
     * @param {{}} domEl
     */
    @action onChangeMount = (domEl) => {
      if (this.slider) {
        return;
      }

      this.slider = domEl;
      const sliderEl = findDOMNode(domEl);
      if (!sliderEl) {
        return;
      }

      this.handleEls.replace(
        [...sliderEl.querySelectorAll('.multi-slider-handle')]
      );
    };

    /**
     * Handles the drag move event.
     *
     * @param {number} activeIndex
     * @returns {function({})}
     */
    onMove = (activeIndex) => {
      /**
       * Updates the handle and values when after the drag event triggers.
       *
       * @param {{dx: number, dy: number}} dragEvent
       */
      return (dragEvent) => {
        const {minSeparation, minValue, maxValue} = this.slider.props;
        const width = this.slider.getSliderWidth();

        const percentChange = dragEvent.dx / width;
        const totalDiff = percentChange * (maxValue - minValue);

        const allValues = this.slider.getValues();
        const currentValue = allValues[activeIndex];

        let newValue = between(currentValue + totalDiff, minValue, maxValue);
        if (activeIndex) {
          const startValue = allValues[0];
          if (newValue - minSeparation <= startValue) {
            newValue = startValue + minSeparation;
          }
        } else {
          const endValue = allValues[1];
          if (newValue + minSeparation >= endValue) {
            newValue = endValue - minSeparation;
          }
        }

        if (activeIndex === 0) {
          this.props.onChangeStart(newValue);
        } else if (activeIndex === 1) {
          this.props.onChangeEnd(newValue);
        }
      };
    };

    /**
     * Starts the interactJS code.
     */
    @action initInteraction = () => {
      // Make sure we don't have multiple dragging interactions on this element.
      if (this.interactions.length) {
        this.interactions.forEach((interaction) => {
          interaction.unset();
        });
        this.interactions.clear();
      }

      this.handleEls.forEach((handleEl, index) => {
        const interaction = interact(handleEl);
        interaction.draggable({
          onmove: this.onMove(index)
        });

        this.interactions.push(interaction);
      });
    };

    /**
     * Unbinds the dragging code.
     */
    @action stopInteraction = () => {
      if (!this.interactions.length) {
        return;
      }

      this.interactions.forEach((interaction) => {
        interaction.unset();
      });
      this.interactions.clear();
    };

    /**
     * Renders the WrappedComponent.
     *
     * @returns {Object}
     */
    render() {
      if (this.props.ref) {
        throw new Error('SliderInteractHoc will override ref property given to the wrapped component.');
      }

      return (
        <WrappedComponent
          {...this.props}
          ref={this.onChangeMount}
        />
      );
    }
  }

  SliderInteractHoc.propTypes = {
    onChangeEnd: PropTypes.func.isRequired,
    onChangeStart: PropTypes.func.isRequired,
    ref: PropTypes.func,
  };

  return observer(SliderInteractHoc);
}
