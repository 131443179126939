import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLongArrowLeft} from '@fortawesome/free-solid-svg-icons';

import inject from '../../../../hoc/injectHoc';
import prestoLogoUrl from '../../../../../assets/images/presto.svg';

import './editorNavItem.scss';

/**
 * The EditorNavItem component.
 */
export class EditorNavItem extends React.Component {
  /**
   * Navigates back from the editor.
   */
  onBackClick = () => {
    const {navigateContentStore} = this.props;

    navigateContentStore.navigateToDashboard();
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    return (
      <div id="editor-nav-item" className="flex-fill">
        <div className="nav-back">
          <button
            className="btn btn-outline-dark nav-back-button"
            type="button"
            onClick={this.onBackClick}
          >
            <FontAwesomeIcon icon={faLongArrowLeft} />
          </button>
        </div>
        <div className="presto-header">
          <div className="presto-logo">
            <img className="logo" src={prestoLogoUrl} />
          </div>
          <div className="presto-label-main">
            <div className="presto-label">Presto</div>
            <div className="presto-sub-label">by Project Content</div>
          </div>
        </div>
      </div>
    );
  }
}

EditorNavItem.propTypes = {
  navigateContentStore: MobxPropTypes.observableObject,
};

EditorNavItem.wrappedComponent = {};
EditorNavItem.wrappedComponent.propTypes = {
  navigateContentStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(EditorNavItem)(
  observer(EditorNavItem)
);
