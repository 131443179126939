import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import ConfirmModal from '../../../modals/confirm/ConfirmModal';
import {CONTENT_LIST_COLLECTION} from '../../../../constants/contentConstants';

/**
 * Multi Select Actions component
 */
class MultiSelectActions extends Component {
  /**
   * Whether or not the delete modal is open.
   *
   * @type {boolean}
   */
  @observable isDeleteModalOpen = false;

  /**
   * Handles when the delete modal should open
   */
  @action openDeleteConfirmModal = () => {
    this.isDeleteModalOpen = true;
  };

  /**
   * Handles when the delete modal should close
   */
  @action closeDeleteConfirmModal = () => {
    this.isDeleteModalOpen = false;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {onDelete, onCancel, contentListType} = this.props;

    const removeActionType = contentListType === CONTENT_LIST_COLLECTION ? 'Un-publish' : 'Delete';

    const onDeleteContents = (shouldDelete) => {
      this.closeDeleteConfirmModal();

      if (shouldDelete) {
        onDelete();
      }
    };

    return (
      <>
        <button
          type="button"
          className="btn btn-sm text-secondary mr-2"
          onClick={onCancel}
        >
          Deselect All
        </button>
        <button
          type="button"
          className="btn btn-sm btn-danger"
          onClick={this.openDeleteConfirmModal}
        >
          {removeActionType} Selected
        </button>

        {(this.isDeleteModalOpen) && (
          <ConfirmModal
            isOpen={true}
            onComplete={onDeleteContents}
            title={`${removeActionType} Files?`}
            confirmText={`Are you sure you want to ${removeActionType} the selected files?`}
            isYesNo={true}
          />
        )}
      </>
    );
  }
}

MultiSelectActions.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  contentListType: PropTypes.string,
};

export default observer(MultiSelectActions);
