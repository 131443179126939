import PropTypes from 'prop-types';
import React from 'react';
import {Modal, ModalBody, ModalFooter} from 'reactstrap';

/**
 * The MessageModal component.
 */
class MessageModal extends React.Component {
  /**
   * Triggered when the modal is closed.
   */
  onCloseModal = () => {
    const {onClose} = this.props;

    onClose();
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {isOpen, bodyText} = this.props;

    if (!isOpen) {
      return null;
    }

    let body = bodyText;
    if (bodyText && typeof bodyText === 'function') {
      body = bodyText();
    }

    return (
      <Modal className="confirm-modal" isOpen={isOpen} toggle={this.onCloseModal} centered>
        <ModalBody>
          {(body) && (
            <div>
              {body}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-dark"
            onClick={this.onCloseModal}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

MessageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  bodyText: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.element]),
};

export default MessageModal;
