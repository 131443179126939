export const onClickEntityEvent = (game, entity, onEntityClick) => {
  const composeMode = game.composeMode;
  const entityCompose = entity.has('compose')
    ? entity.get('compose')
    : null;

  // allow click if
  //  not in compose mode
  //  or in compose mode and layer is editable
  return !composeMode
      || (composeMode && entityCompose && entityCompose.canEdit)
    ? (clickEvent) => onEntityClick(clickEvent, entity)
    : undefined;
};
