import interact from 'interactjs/src/index';
import lodash from 'lodash';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {findDOMNode} from 'react-dom';

import * as resizeCorner from '../../constants/resizeConstants';
import {actionPositionComponent} from '../../display/components/action/actionPositionComponent';
import {
  getIsEntityTransitioning,
  getIsPositionLocked,
  getIsSizeLocked,
  getScaleFromElement,
  initEntitySize
} from '../../utils/dragDropHelper';
import {mapToRotatedFrame} from '../../utils/mathHelper';

/**
 * Re-sizes the entity.
 *
 * @param {ObservableMap} entity
 * @param {GameStore} game
 * @param {{dx: number, dy: number}} delta
 * @param {string} position
 * @param {boolean} isRotated
 */
function resizeEntity(entity, game, delta, position, isRotated) {
  let positionDelta = {
    y: 0,
    x: 0,
  };
  let sizeDelta = {
    width: 0,
    height: 0,
  };

  let safePosition = position;
  if (!isRotated) {
    safePosition = getRotatedPosition(entity, position);
  }

  let rotationDelta = lodash.cloneDeep(delta);
  const currentPosition = entity.get('position') || {};
  if (currentPosition.rotate) {
    rotationDelta = mapToRotatedFrame(currentPosition.rotate, delta);
  }

  if (rotationDelta.dx) {
    if (lodash.includes(resizeCorner.LEFTS, safePosition)) {
      positionDelta.x += rotationDelta.dx;
      sizeDelta.width -= rotationDelta.dx;
    } else if (lodash.includes(resizeCorner.RIGHTS, safePosition)) {
      sizeDelta.width += rotationDelta.dx;
    }
  }

  if (rotationDelta.dy) {
    if (lodash.includes(resizeCorner.TOPS, safePosition)) {
      positionDelta.y += rotationDelta.dy;
      sizeDelta.height -= rotationDelta.dy;
    } else if (lodash.includes(resizeCorner.BOTTOMS, safePosition)) {
      sizeDelta.height += rotationDelta.dy;
    }
  }

  if (!positionDelta.x && !positionDelta.y) {
    positionDelta = null;
  }
  if (!sizeDelta.width && !sizeDelta.height) {
    sizeDelta = null;
  }

  const currentInteraction = entity.get('interaction');
  const isCropping = Boolean(currentInteraction && currentInteraction.isCropping);

  game.addAction({entityId: entity.get('id')}, actionPositionComponent(
    true,
    positionDelta,
    sizeDelta,
    null,
    !isCropping
  ));
}

/**
 * Rotates the corner position based on the rotation of the entity.
 *
 * @param {{}} entity
 * @param {string} position
 * @returns {string}
 */
function getRotatedPosition(entity, position) {
  const currentPosition = entity.get('position');
  const rotate = (currentPosition && currentPosition.rotate) ? currentPosition.rotate : 0;

  if (!rotate) {
    return position;
  }

  const step = 22.5;
  const rotationOffset = Math.ceil(Math.floor(rotate / step) / 2);
  if (!rotationOffset) {
    return position;
  }

  const rotatedCursors = lodash.reverse(resizeCorner.ROTATED_CURSORS.slice(0));
  const startIndex = lodash.findIndex(rotatedCursors, {position});
  if (startIndex < 0) {
    return position;
  }

  const newIndex = (startIndex + rotationOffset) % rotatedCursors.length;

  return rotatedCursors[newIndex].position;
}

/**
 * Gets whether or not the entity is active.
 *
 * @param {ObservableMap} entity
 * @returns {boolean}
 */
function getIsActive(entity) {
  const interaction = entity.get('interaction');

  if (getIsPositionLocked(entity) || getIsSizeLocked(entity)) {
    return false;
  }

  return Boolean(interaction && interaction.isActive);
}

/**
 * A higher order component wrapper that handles making an entity resizable.
 *
 * @param {Object} WrappedComponent
 * @returns {Object}
 */
export default function entityResizeHocWrapper(WrappedComponent) {
  /**
   * The EntityResizeHoc higher order component.
   */
  class EntityResizeHoc extends React.Component {
    /**
     * Whether or not this entity is currently active.
     * Used to track the change.
     *
     * @type {boolean}
     */
    @observable isActive = false;

    /**
     * The DOM element for the element.
     *
     * @type {HTMLElement}
     */
    @observable domEl = null;

    /**
     * The DOM element for the display element.
     *
     * @type {HTMLElement}
     */
    @observable displayEl = null;

    /**
     * The interactJS object initialized on the DOM element.
     *
     * @type {{draggable: function, resizable: function}}
     */
    @observable interaction = null;

    /**
     * Triggered right after the component mounts to the page.
     */
    componentDidMount() {
      this.checkActiveStateChange();
    }

    /**
     * Triggered when the component is about to unmount.
     */
    componentWillUnmount() {
      this.stopDragging();
    }

    /**
     * Triggered when an observed item updates.
     */
    componentWillReact = () => {
      this.checkActiveStateChange();
    };

    /**
     * Checks whether or not the active state has changed and either allows it to be dragged or stops it from
     * dragging.
     */
    @action checkActiveStateChange = () => {
      const entities = this.props.entities || [this.props.entity];
      const isActive = lodash.some(entities, (entity) => {
        return getIsActive(entity);
      });

      if (!this.isActive && isActive) {
        this.initInteraction();
        this.isActive = true;
      } else if (this.isActive && !isActive) {
        this.stopDragging();
        this.isActive = false;
      }
    };

    /**
     * Triggered right after the wrapped component is added to OR removed from the page.
     *
     * @param {{}} domEl
     */
    @action onChangeMount = (domEl) => {
      if (this.domEl) {
        return;
      }

      this.domEl = findDOMNode(domEl);
      this.displayEl = document.getElementById('display-source');
    };

    /**
     * Handles the resize move event.
     *
     * @param {{dx: number, dy: number}} dragEvent
     */
    onResize = (dragEvent) => {
      const entities = this.props.entities || [this.props.entity];
      const game = this.props.game;
      const isRotated = this.props.isRotated;
      const resizePosition = this.props.resizePosition;
      const scale = getScaleFromElement(this.displayEl);

      entities.forEach((entity) => {
        if (getIsEntityTransitioning(entity, game.timer.elapsedTime, ['position'])) {
          // TODO: Indicate to the user why dragging isn't working.
          return;
        }

        resizeEntity(entity, game, {
          dy: dragEvent.dy / scale,
          dx: dragEvent.dx / scale,
        }, resizePosition, isRotated);
      });
    };

    /**
     * Starts the interactJS code.
     */
    @action initInteraction = () => {
      const entities = this.props.entities || [this.props.entity];

      if (this.interaction) {
        // Make sure we don't have multiple dragging interactions on this element.
        this.interaction.unset();
      }

      entities.forEach((entity) => {
        initEntitySize(entity, this.props.game);
      });

      const interaction = interact(this.domEl);
      interaction.draggable({
        onmove: this.onResize,
      });

      this.interaction = interaction;
    };

    /**
     * Unbinds the dragging code.
     */
    @action stopDragging = () => {
      if (!this.interaction) {
        return;
      }

      this.interaction.unset();
      this.interaction = null;
    };

    /**
     * Renders the WrappedComponent.
     *
     * @returns {Object}
     */
    render() {
      if (!WrappedComponent) {
        return null;
      }

      if (this.props.ref) {
        throw new Error('EntityResizeHoc will override ref property given to the wrapped component.');
      }

      return (
        <WrappedComponent
          {...this.props}
          ref={this.onChangeMount}
        />
      );
    }
  }

  EntityResizeHoc.propTypes = {
    game: PropTypes.shape({
      setEntityComponents: PropTypes.func,
    }).isRequired,
    resizePosition: PropTypes.string.isRequired,

    entities: PropTypes.arrayOf(MobxPropTypes.observableMap),
    entity: MobxPropTypes.observableMap,
    isRotated: PropTypes.bool,
    ref: PropTypes.func,
  };

  EntityResizeHoc.defaultProps = {
    isRotated: true,
  };

  return observer(EntityResizeHoc);
}
