import lodash from 'lodash';
import {isObservable, toJS} from 'mobx';

/**
 * The list of valid blend modes and their name.
 *
 * @const {Object.<string, {value: string, label: string}>}
 */
export const BLEND_MODES = {
  normal: {value: 'normal', label: 'none'},
  color: {value: 'color', label: 'color'},
  colorBurn: {value: 'color-burn', label: 'color burn'},
  colorDodge: {value: 'color-dodge', label: 'color dodge'},
  darken: {value: 'darken', label: 'darken'},
  difference: {value: 'difference', label: 'difference'},
  exclusion: {value: 'exclusion', label: 'exclusion'},
  hardLight: {value: 'hard-light', label: 'hard light'},
  hue: {value: 'hue', label: 'hue'},
  lighten: {value: 'lighten', label: 'lighten'},
  luminosity: {value: 'luminosity', label: 'luminosity'},
  multiply: {value: 'multiply', label: 'multiply'},
  overlay: {value: 'overlay', label: 'overlay'},
  saturation: {value: 'saturation', label: 'saturation'},
  screen: {value: 'screen', label: 'screen'},
  softLight: {value: 'soft-light', label: 'soft light'},
};

/**
 * The effect component.
 *
 * @param {{blendMode: number}} effectProperties
 * @returns {{effect: {default: {}}}}
 */
export function effectComponent(effectProperties) {
  const effects = addEffectsToComponent(effectProperties, {});

  const defaults = {
    blendMode: BLEND_MODES.normal.value,
  };

  return {
    effect: {
      ...defaults,
      ...effects,
      default: {
        ...defaults,
        ...effects,
      },
    },
  };
}

/**
 * Adds effects into the given simple component object.
 * This is not for directly updating an entity!
 *
 * @param {{}} effects
 * @param {{}} component
 * @returns {{}}
 */
export function addEffectsToComponent(effects, component) {
  const safeComponent = (isObservable(component)) ? toJS(component) : component;

  const validProperties = lodash.pick(effects, [
    'blendMode'
  ]);

  const safeProperties = {};
  lodash.forEach(validProperties, (propertyValue, propertyName) => {
    safeProperties[propertyName] = propertyValue;
  });

  return {
    ...safeComponent,
    ...safeProperties,
  };
}

/**
 * Gets the effect component from the source item.
 *
 * @param {{effect: {}}} item
 * @returns {{effect: {}}}
 */
export function getEffectFromSource(item) {
  if (!item.setup || !item.setup.effect) {
    return {};
  }

  return effectComponent(item.setup.effect);
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {ObservableMap} entity
 * @returns {{}}
 */
export function getEffectForSource(entity) {
  if (!entity.has('effect')) {
    return {};
  }

  const effect = toJS(entity.get('effect'));

  return {
    setup: {
      effect: effect.default,
    },
  };
}
