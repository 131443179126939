import React from 'react';
import PropTypes from 'prop-types';

/**
 * The UpdatePlanAlert component.
 *
 * @param {{}} props
 * @returns {{}}
 */
function UpdatePlanAlert(props) {
  const {message} = props;
  return (
    <div className="alert alert-warning mt-5" role="alert">
      {message}
    </div>
  );
}

UpdatePlanAlert.propTypes = {
  message: PropTypes.string.isRequired
};

export default UpdatePlanAlert;
