import {action} from 'mobx';

import apiFileGetOneStore from './apiFileGetOneStore';
import ApiBaseStore from '../common/apiBaseStore';
import {STATE_PENDING, STATE_FULFILLED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';

/**
 * Deletes a file.
 */
class ApiFileDeleteStore extends ApiBaseStore {
  /**
   * Starts the file delete process.
   *
   * @param {number|string} fileId
   */
  @action makeRequest(fileId) {
    const safeFileId = String(fileId);

    this.state = STATE_PENDING;
    this.data = null;
    this.error = null;
    this[EXPIRE_TIME] = Date.now() + EXPIRES_PENDING;

    serverApi.fileDelete(safeFileId).then(
      action('fileDeleteSuccess', (wasSuccessful) => {
        this.state = STATE_FULFILLED;
        this.data = wasSuccessful;
        this.error = null;
        this[EXPIRE_TIME] = Date.now() + EXPIRES_IN;

        apiFileGetOneStore.clear(safeFileId);
      })
    ).catch(
      action('fileDeleteError', (folderCreateError) => {
        this.state = STATE_FULFILLED;
        this.data = null;
        this.error = folderCreateError;
      })
    );
  }
}

export default new ApiFileDeleteStore();
