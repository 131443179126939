/**
 * This file is a "fork" of
 * {@url https://github.com/sstur/draft-js-utils/blob/master/packages/draft-js-export-markdown/src/stateToMarkdown.js}
 */

/* eslint-disable no-magic-numbers */

import {getEntityRanges, BLOCK_TYPE, ENTITY_TYPE, INLINE_STYLE} from 'draft-js-utils';
import lodash from 'lodash';

import {
  COLOR_PREFIX,
  FONT_SIZE_PREFIX,
  LETTER_SPACING_PREFIX,
  LINE_HEIGHT_FIXED,
  LINE_HEIGHT_PREFIX,
  styleToMarkdownMap
} from '../constants/styleConstants';

const {BOLD, CODE, ITALIC, STRIKETHROUGH, UNDERLINE} = INLINE_STYLE;

/**
 * The amount of indentation to use for code blocks.
 *
 * @const {string}
 */
const CODE_INDENT = '    ';

/**
 * Generates Markdown from a draftJS contentState.
 */
class MarkupGenerator {
  /**
   * The blocks.
   *
   * @type {Array.<ContentBlock>}
   */
  blocks = [];

  /**
   * The content state.
   *
   * @type {?ContentState}
   */
  contentState = null;

  /**
   * The current block index.
   *
   * @type {number}
   */
  currentBlock = 0;

  /**
   * The output array.
   *
   * @type {string[]}
   */
  output = [];

  /**
   * The total number of blocks.
   *
   * @type {number}
   */
  totalBlocks = 0;

  /**
   * The list item counts.
   *
   * @type {{}}
   */
  listItemCounts = {};

  /**
   * @constructor
   * @param {ContentState} contentState
   */
  constructor(contentState) {
    this.contentState = contentState;
  }

  /**
   * Generates the Markdown.
   *
   * @returns {string}
   */
  generate() {
    this.output = [];
    this.blocks = this.contentState.getCurrentContent().getBlockMap().toArray();
    this.totalBlocks = this.blocks.length;
    this.currentBlock = 0;
    this.listItemCounts = {};
    while (this.currentBlock < this.totalBlocks) {
      this.processBlock();
    }
    return this.output.join('');
  }

  /**
   * Processes a content block.
   */
  processBlock() {
    let block = this.blocks[this.currentBlock];
    let blockType = block.getType();

    const typeToMarker = {
      [BLOCK_TYPE.HEADER_ONE]: '# ',
      [BLOCK_TYPE.HEADER_TWO]: '## ',
      [BLOCK_TYPE.HEADER_THREE]: '### ',
      [BLOCK_TYPE.HEADER_FOUR]: '#### ',
      [BLOCK_TYPE.HEADER_FIVE]: '##### ',
      [BLOCK_TYPE.HEADER_SIX]: '###### ',
      [BLOCK_TYPE.BLOCKQUOTE]: ' > ',
      [BLOCK_TYPE.CODE]: CODE_INDENT,
    };

    if (typeToMarker[blockType]) {
      this.insertLineBreaks(1);
      this.output.push(typeToMarker[blockType] + this.renderBlockContent(block) + '\n');
      this.currentBlock += 1;
      return;
    }

    if (styleToMarkdownMap[blockType]) {
      this.insertLineBreaks(1);
      const mdTag = styleToMarkdownMap[blockType];
      if (Array.isArray(mdTag)) {
        this.output.push(mdTag[0] + this.renderBlockContent(block) + mdTag[1] + '\n');
      } else {
        this.output.push(mdTag + this.renderBlockContent(block) + '\n');
      }
      this.currentBlock += 1;
      return;
    }

    if (this.processListBlock(blockType, block)) {
      return;
    }

    this.insertLineBreaks(1);
    this.output.push(this.renderBlockContent(block) + '\n');
    this.currentBlock += 1;
  }

  /**
   * Processes ordered lists and unordered lists.
   *
   * @param {string} blockType
   * @param {{}} block
   * @returns {boolean} True if the block was processed, false otherwise.
   */
  processListBlock(blockType, block) {
    const OL_TYPE = BLOCK_TYPE.ORDERED_LIST_ITEM;
    const UL_TYPE = BLOCK_TYPE.UNORDERED_LIST_ITEM;

    if (blockType !== OL_TYPE && blockType !== UL_TYPE) {
      return false;
    }

    const blockDepth = block.getDepth();
    const lastBlock = this.getLastBlock();
    const lastBlockType = lastBlock ? lastBlock.getType() : null;
    const lastBlockDepth = (lastBlock && canHaveDepth(lastBlockType)) ? lastBlock.getDepth() : null;

    if (lastBlockType !== blockType && lastBlockDepth !== blockDepth - 1) {
      this.insertLineBreaks(1);

      // Insert an additional line break if following opposite list type.
      if (blockType === UL_TYPE && lastBlockType === OL_TYPE) {
        this.insertLineBreaks(1);
      } else if (blockType === OL_TYPE && lastBlockType === UL_TYPE) {
        this.insertLineBreaks(1);
      }
    }

    const indent = ' '.repeat(blockDepth * 4);

    if (blockType === UL_TYPE) {
      this.output.push(indent + '- ' + this.renderBlockContent(block) + '\n');
    } else if (blockType === OL_TYPE) {
      // TODO: figure out what to do with two-digit numbers
      const count = this.getListItemCount(block) % 10;
      this.output.push(
        indent + `${count}. ` + this.renderBlockContent(block) + '\n',
      );
    }

    this.currentBlock += 1;
    return true;
  }

  /**
   * Gets the last content block.
   *
   * @returns {ContentBlock}
   */
  getLastBlock() {
    return this.blocks[this.currentBlock - 1];
  }

  /**
   * Gets the next content block.
   *
   * @returns {ContentBlock}
   */
  getNextBlock() {
    return this.blocks[this.currentBlock + 1];
  }

  /**
   * Gets the number of list items in the given content block.
   *
   * @param {ContentBlock} block
   * @returns {number}
   */
  getListItemCount(block) {
    let blockType = block.getType();
    let blockDepth = block.getDepth();

    // To decide if we need to start over we need to backtrack (skipping list items that are of greater depth).
    let index = this.currentBlock - 1;
    let prevBlock = this.blocks[index];
    while (
      prevBlock &&
      canHaveDepth(prevBlock.getType()) &&
      prevBlock.getDepth() > blockDepth
    ) {
      index -= 1;
      prevBlock = this.blocks[index];
    }
    if (
      !prevBlock ||
      prevBlock.getType() !== blockType ||
      prevBlock.getDepth() !== blockDepth
    ) {
      this.listItemCounts[blockDepth] = 0;
    }
    return (this.listItemCounts[blockDepth] = this.listItemCounts[blockDepth] + 1);
  }

  /**
   * Inserts a line break (newline) into the Markdown.
   */
  insertLineBreaks() {
    if (this.currentBlock > 0) {
      this.output.push('\n');
    }
  }

  /**
   * Parses styles into markdown in some content text.
   *
   * @param {string} blockType
   * @returns {string}
   */
  parseEntityStyle(blockType) {
    return ([contentText, style]) => {
      // Don't allow empty inline elements.
      if (!contentText) {
        return '';
      }
      let markdownContent = encodeContent(contentText);

      if (!markdownContent.trim()) {
        // If the content is just whitespace, then don't decorate it as per the CommonMark standard.
        // Just whitespace will not be parsed correctly in markdown-it because of its state.scanDelims function.

        // Except for UNDERLINE, we want to allow whitespace to be underlined.
        if (style.has(UNDERLINE)) {
          markdownContent = `++${markdownContent}++`;
        }

        return markdownContent;
      }

      const startWhitespace = markdownContent.match(/^ +/);
      const endWhitespace = markdownContent.match(/ +$/);

      markdownContent = markdownContent.trim();

      if (style.has(BOLD)) {
        markdownContent = `**${markdownContent}**`;
      }
      if (style.has(UNDERLINE)) {
        markdownContent = `++${markdownContent}++`;
      }
      if (style.has(ITALIC)) {
        markdownContent = `_${markdownContent}_`;
      }
      if (style.has(STRIKETHROUGH)) {
        markdownContent = `~~${markdownContent}~~`;
      }
      if (style.has(CODE)) {
        markdownContent = (blockType === BLOCK_TYPE.CODE) ? markdownContent : '`' + markdownContent + '`';
      }

      lodash.forEach(styleToMarkdownMap, (markdownSymbols, styleName) => {
        if (styleName.substr(0, 5) !== 'FONT_') {
          return;
        }

        if (style.has(styleName)) {
          markdownContent = `${markdownSymbols[0]}${markdownContent}${markdownSymbols[1]}`;
        }
      });

      const checkFont = 'FONT_';
      const styleArray = lodash.sortBy(style.toArray(), (item) => {
        if (item.substring(0, checkFont.length) === checkFont) {
          return 'FONT';
        }
        const lastIndex = String(item).lastIndexOf('-');
        return String(item).substring(0, lastIndex);
      });

      // Process the dynamic color classes.
      styleArray.forEach((activeStyle) => {
        if (activeStyle.substr(0, COLOR_PREFIX.length) === COLOR_PREFIX) {
          const markdownSymbols = styleToMarkdownMap[COLOR_PREFIX];
          const color = activeStyle.substr(COLOR_PREFIX.length);

          const openSymbol = markdownSymbols[0].replace('#', '#' + color);
          const closeSymbol = markdownSymbols[1].replace('#', '#' + color);
          markdownContent = `${openSymbol}${markdownContent}${closeSymbol}`;
        } else if (activeStyle.substr(0, FONT_SIZE_PREFIX.length) === FONT_SIZE_PREFIX) {
          const markdownSymbols = styleToMarkdownMap[FONT_SIZE_PREFIX];
          const fontSize = activeStyle.substr(FONT_SIZE_PREFIX.length);

          const openSymbol = markdownSymbols[0].replace('0', fontSize);
          const closeSymbol = markdownSymbols[1].replace('0', fontSize);
          markdownContent = `${openSymbol}${markdownContent}${closeSymbol}`;
        } else if (activeStyle.substr(0, LINE_HEIGHT_PREFIX.length) === LINE_HEIGHT_PREFIX) {
          const markdownSymbols = styleToMarkdownMap[LINE_HEIGHT_PREFIX];
          const lineHeight = Number.parseFloat(
            activeStyle.substr(LINE_HEIGHT_PREFIX.length)
          ).toFixed(LINE_HEIGHT_FIXED);

          const openSymbol = markdownSymbols[0].replace('$', '$' + lineHeight);
          const closeSymbol = markdownSymbols[1].replace('$', '$' + lineHeight);
          markdownContent = `${openSymbol}${markdownContent}${closeSymbol}`;
        } else if (activeStyle.substr(0, LETTER_SPACING_PREFIX.length) === LETTER_SPACING_PREFIX) {
          const markdownSymbols = styleToMarkdownMap[LETTER_SPACING_PREFIX];
          const letterSpacing = activeStyle.substr(LETTER_SPACING_PREFIX.length);

          const openSymbol = markdownSymbols[0].replace('%', '%' + letterSpacing);
          const closeSymbol = markdownSymbols[1].replace('%', '%' + letterSpacing);
          markdownContent = `${openSymbol}${markdownContent}${closeSymbol}`;
        }
      });

      if (startWhitespace) {
        markdownContent = MarkupGenerator.addMarkdownWhitespace(markdownContent, style, startWhitespace[0]);
      }
      if (endWhitespace) {
        markdownContent = MarkupGenerator.addMarkdownWhitespace(markdownContent, style, endWhitespace[0], true);
      }

      return markdownContent;
    };
  }

  /**
   * Adds the removed whitespace back on to the inline styled content.
   *
   * @param {string} markdownContent
   * @param {{has: function}} style
   * @param {string} whitespace
   * @param {boolean=} onEnd
   * @returns {string}
   */
  static addMarkdownWhitespace(markdownContent, style, whitespace, onEnd) {
    let finalSpaces = whitespace;
    if (style.has(UNDERLINE)) {
      // We still want to underline whitespace if it is called for.
      finalSpaces = `++${finalSpaces}++`;
    }

    if (onEnd) {
      return `${markdownContent}${finalSpaces}`;
    }
    return `${finalSpaces}${markdownContent}`;
  }

  /**
   * Renders a content block.
   *
   * @param {ContentBlock} block
   * @returns {string}
   */
  renderBlockContent(block) {
    let {contentState} = this;
    let blockType = block.getType();
    let text = block.getText();
    if (text === '') {
      // Prevent element collapse if completely empty.
      return '\u200B';
    }
    let charMetaList = block.getCharacterList();
    let entityPieces = getEntityRanges(text, charMetaList);

    return entityPieces.map(([entityKey, stylePieces]) => {
      let content = stylePieces.map(this.parseEntityStyle(blockType)).join('');
      let entity = entityKey ? contentState.getEntity(entityKey) : null;
      if (entity != null && entity.getType() === ENTITY_TYPE.LINK) {
        let data = entity.getData();
        let url = data.url || '';
        let title = data.title ? ` "${escapeTitle(data.title)}"` : '';
        return `[${content}](${encodeURL(url)}${title})`;
      } else if (entity != null && entity.getType() === ENTITY_TYPE.IMAGE) {
        let data = entity.getData();
        let src = data.src || '';
        let alt = data.alt ? `${escapeTitle(data.alt)}` : '';
        return `![${alt}](${encodeURL(src)})`;
      }
      return content;
    }).join('');
  }
}

/**
 * Determines whether or not the block type has depth.
 *
 * @param {*} blockType
 * @returns {boolean}
 */
function canHaveDepth(blockType) {
  switch (blockType) {
    case BLOCK_TYPE.UNORDERED_LIST_ITEM:
    case BLOCK_TYPE.ORDERED_LIST_ITEM:
      return true;
    default:
      return false;
  }
}

/**
 * Encodes content.
 *
 * @param {string} text
 * @returns {string}
 */
function encodeContent(text) {
  return String(text).replace(/[*_`]/g, '\\$&');
}

/**
 * Encodes chars that would normally be allowed in a URL but would conflict with
 * our markdown syntax: `[foo](http://foo/)`.
 *
 * @param {string} url
 * @returns {string}
 */
function encodeURL(url) {
  return String(url).replace(/\)/g, '%29');
}

/**
 * Escapes quotes using backslash.
 *
 * @param {string} text
 * @returns {string}
 */
function escapeTitle(text) {
  return String(text).replace(/"/g, '\\"');
}

/**
 * Gets the Markdown from the content state.
 *
 * @param {ContentState} content
 * @returns {string}
 */
export function stateToMarkdown(content) {
  return new MarkupGenerator(content).generate();
}

export default stateToMarkdown;
