export default {
  DAY_ALIGN: '|',
  HIGH_ALIGN: '|',
  LOW_ALIGN: '|',

  DAY_WIDTH_RATIO: 0.2374,
  DAY_HEIGHT_RATIO: 0.0918,
  DAY_ONE_POSITION_X_RATIO: 0.0761,
  DAY_ONE_POSITION_Y_RATIO: 0.084,
  DAY_TWO_POSITION_X_RATIO: 0.3886,
  DAY_TWO_POSITION_Y_RATIO: 0.084,
  DAY_THREE_POSITION_X_RATIO: 0.6995,
  DAY_THREE_POSITION_Y_RATIO: 0.084,

  ICON_WIDTH_RATIO: 0.2939,
  ICON_HEIGHT_RATIO: 0.5899,
  ICON_ONE_POSITION_X_RATIO: 0.0189,
  ICON_ONE_POSITION_Y_RATIO: 0.1443,
  ICON_TWO_POSITION_X_RATIO: 0.3314,
  ICON_TWO_POSITION_Y_RATIO: 0.1443,
  ICON_THREE_POSITION_X_RATIO: 0.6423,
  ICON_THREE_POSITION_Y_RATIO: 0.1443,

  HIGH_WIDTH_RATIO: 0.2658,
  HIGH_HEIGHT_RATIO: 0.227,
  HIGH_ONE_POSITION_X_RATIO: 0.0623,
  HIGH_ONE_POSITION_Y_RATIO: 0.5758,
  HIGH_TWO_POSITION_X_RATIO: 0.3748,
  HIGH_TWO_POSITION_Y_RATIO: 0.5758,
  HIGH_THREE_POSITION_X_RATIO: 0.6857,
  HIGH_THREE_POSITION_Y_RATIO: 0.5758,

  LOW_WIDTH_RATIO: 0.2202,
  LOW_HEIGHT_RATIO: 0.1404,
  LOW_ONE_POSITION_X_RATIO: 0.0858,
  LOW_ONE_POSITION_Y_RATIO: 0.7832,
  LOW_TWO_POSITION_X_RATIO: 0.3983,
  LOW_TWO_POSITION_Y_RATIO: 0.7832,
  LOW_THREE_POSITION_X_RATIO: 0.7092,
  LOW_THREE_POSITION_Y_RATIO: 0.7832,
};
