import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import entityActivateHoc from '../../../hoc/entityActivateHoc';
import {onClickEntityEvent} from '../../utils';

/**
 * The LineItem component.
 */
export class LineItem extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, startPoint, endPoint, onEntityClick, game} = this.props;

    const line = entity.get('line');
    const {thickness} = line;
    const lineStyle = line.style;

    const onClickEvent = onClickEntityEvent(game, entity, onEntityClick);

    return (
      <line
        className="line-item"
        x1={startPoint.x}
        y1={startPoint.y}
        x2={endPoint.x}
        y2={endPoint.y}
        strokeWidth={thickness}
        stroke={lineStyle.color}
        strokeLinecap="round"
        style={{
          cursor: onClickEvent ? 'pointer' : 'inherit',
          pointerEvents: 'visible',
        }}
        onClick={onClickEvent}
      />
    );
  }
}

LineItem.propTypes = {
  endPoint: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  entity: MobxPropTypes.observableMap.isRequired,
  game: PropTypes.object.isRequired,
  startPoint: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,

  onEntityClick: PropTypes.func,
};

export default entityActivateHoc(
  observer(LineItem)
);
