import classNames from 'classnames';
import lodash from 'lodash';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import Link from '../../../../common/link/Link';
import {adminContentRequestsListRoute, adminBannersListRoute} from '../../../../routePaths';

import './adminSidebar.scss';

const routeMap = {
  'requests': {label: 'Content Requests', route: adminContentRequestsListRoute, active: false},
  'banners': {label: 'Banners', route: adminBannersListRoute, active: false},
};

/**
 * The AdminSidebar component.
 *
 * @constructor
 */
export class AdminSidebar extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {pathname} = this.props.location;

    return (
      <aside id="admin-sidebar" className="system-sidebar">
        <h2 className="admin-side-header h4">Admin Controls</h2>

        <ul className="nav flex-column">
          {lodash.map(routeMap, ({label, route}) => {
            const active = (pathname === route);

            return (
              <li className="nav-item" key={label}>
                <Link
                  className={classNames('nav-link', {active})}
                  to={route}
                >{label}</Link>
              </li>
            );
          })}
        </ul>
      </aside>
    );
  }
}

AdminSidebar.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default observer(AdminSidebar);
