import lodash from 'lodash';
import uuid from 'uuid/v4';

/**
 * The compose component.
 *
 * @param {boolean} canEdit
 * @param {string} variableName
 * @returns {{compose: {canEdit: boolean, variableName: string}}}
 */
export function composeComponent(canEdit, variableName) {
  const uniqueId = (!variableName) ? lodash.last(uuid().split('-')) : null;

  return {
    compose: {
      canEdit,
      variableName: variableName || uniqueId,
    },
  };
}

/**
 * Gets the compose component from the source item.
 *
 * @param {{compose: {}}} item
 * @returns {{compose: {canEdit: boolean, variableName: string}}}
 */
export function getComposeFromSource(item) {
  if (!item.compose) {
    return {};
  }

  const itemCompose = item.compose || {};

  return composeComponent(
    Boolean(itemCompose.canEdit),
    itemCompose.variableName
  );
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {ObservableMap} entity
 * @returns {{}}
 */
export function getComposeForSource(entity) {
  if (!entity.has('compose')) {
    return {};
  }

  const compose = entity.get('compose');

  return {
    compose: {
      canEdit: Boolean(compose.canEdit),
      variableName: compose.variableName,
    }
  };
}
