/* eslint-disable no-magic-numbers */

import lodash from 'lodash';

import {EASINGS} from '../../ecs/easingHelper';
import wordCrissCrossInTopGif from '../../../assets/images/transitions/wordCrissCrossInTop.gif';

/**
 * The name that signals this preset.
 * @const {string}
 */
export const PRESET_WORD_CRISS_CROSS_IN_TOP = 'wordCrissCrossInTop';

/**
 * The preset details.
 *
 * @type {{
 *   name: string,
 *   flow: string,
 *   details: Object.<string, {
 *     easing: string,
 *     parse: boolean,
 *     values: *|Array.<*>,
 *     time: {start: (string|number), end: (string|number)}
 *   }>,
 *   template: {}
 * }}
 */
export const wordCrissCrossInTopPresetDetails = {
  // The name of the preset.
  name: PRESET_WORD_CRISS_CROSS_IN_TOP,

  // A human friendly name for the preset.
  humanName: 'Word - Criss Cross In - Top',

  // A preview of the transition.
  gif: wordCrissCrossInTopGif,

  // The element names that this preset can be used for or true for all elements.
  elements: ['text'],

  // The preset direction: in, out, middle. This is used in the UI logic.
  flow: 'in',

  // Each property that will change during the transition.
  details: {
    'word.opacity': {
      easing: EASINGS.easeOutExpo,
      time: {
        start: 'start.0',
        end: 'start.2005'
      },
      values: [
        0,
        1
      ],
      stagger: true,
      staggerDuration: false,
      immediateRender: true
    },
    'word.translateY': {
      easing: EASINGS.easeOutExpo,
      time: {
        start: 'start.0',
        end: 'start.2005'
      },
      values: [
        -100,
        0
      ],
      stagger: true,
      staggerDuration: false,
      immediateRender: true
    },
    'word.translateX': [
      {
        easing: EASINGS.easeOutExpo,
        time: {
          start: 'start.0',
          end: 'start.2005'
        },
        values: [
          -100,
          0
        ],
        filters: [
          'even'
        ],
        stagger: true,
        staggerDuration: false,
        immediateRender: true
      },
      {
        easing: EASINGS.easeOutExpo,
        time: {
          start: 'start.0',
          end: 'start.2005'
        },
        values: [
          100,
          0
        ],
        filters: [
          'odd'
        ],
        stagger: true,
        staggerDuration: false,
        immediateRender: true
      }
    ],
  },

  // How this preset can be used in the source (including optional parameters).
  // This will be turned into a JSON string, so no functions can be used.
  template: {
    preset: PRESET_WORD_CRISS_CROSS_IN_TOP,
    options: {},
  },
};

wordCrissCrossInTopPresetDetails.template.options = lodash.cloneDeep(wordCrissCrossInTopPresetDetails.details);

// This defines the key in the presets object.
export const presetName = PRESET_WORD_CRISS_CROSS_IN_TOP;

// This defines the value of the presets object.
export default wordCrissCrossInTopPresetDetails;

