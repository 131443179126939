import essentialPlanImage from '../assets/images/essential.svg';
import premiumPlanImage from '../assets/images/premium.svg';
import ultimatePlanImage from '../assets/images/ultimate.svg';

/**
 * Extra plan data.
 *
 * @type {{}}
 */
export const PLAN_DATA = {
  'Basic': {},
  'Essential': {
    image: essentialPlanImage,
  },
  'Premium': {
    image: premiumPlanImage,
  },
  'Ultimate': {
    image: ultimatePlanImage,
  },
};

// user's trial has expired
export const UPDATE_PLAN_REASON_TRIAL = 'trial_expired';

// user's coupon has expired
export const UPDATE_PLAN_REASON_COUPON = 'coupon_expired';

// user's subscription has been deleted through Chargebee
export const UPDATE_PLAN_REASON_SUBSCRIPTION_DELETED = 'subscription_deleted';

// user pays upfront with a 1 year contract
export const PAYMENT_PLAN_ANNUAL_ANNUAL = 'annualAnnual';

// user pays monthly with a 1 year contract
export const PAYMENT_PLAN_ANNUAL_MONTHLY = 'annualMonthly';

// user pays month to month (no contract)
export const PAYMENT_PLAN_MONTHLY = 'monthly';

// user manufacturerSignupType
export const NEXTLED_SIGNUP_TYPE = 'nextled';
