import lodash from 'lodash';

/**
 * The list of possible lock types.
 *
 * @const {string[]}
 */
const LOCK_TYPES = [
  'order',
  'time',
  'size',
  'position',
];

/**
 * The locked component.
 *
 * @param {string[]} lockedTypes
 * @returns {{locked: string[]}}
 */
export function lockedComponent(lockedTypes) {
  if (!lockedTypes || !Array.isArray(lockedTypes)) {
    throw new Error('Invalid locked type given to lockedComponent.');
  }

  const safeLockedTypes = lockedTypes.map((type) => {
    return String(type).toLowerCase();
  });

  return {
    locked: lodash.intersection(LOCK_TYPES, safeLockedTypes),
  };
}

/**
 * Gets the locked component from the source item.
 *
 * @param {{locked: string[]}} item
 * @returns {{locked: string[]}}
 */
export function getLockedFromSource(item) {
  const itemSetup = item.setup || {};
  if (!itemSetup.locked || !Array.isArray(itemSetup.locked) || !itemSetup.locked.length) {
    return {};
  }
  return lockedComponent(itemSetup.locked);
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {ObservableMap} entity
 * @returns {{}}
 */
export function getLockedForSource(entity) {
  if (!entity.has('locked')) {
    return {};
  }

  const locked = entity.get('locked');
  if (!locked || !locked.slice) {
    return {};
  }

  return {
    setup: {
      locked: locked.slice()
    },
  };
}
