import lodash from 'lodash';
import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {findDOMNode} from 'react-dom';

import {between} from '../../../utils/mathHelper';
import sliderInteractHoc from './hoc/sliderInteractHoc';

import './multiSlider.scss';

/**
 * The pixel size for the handle radius.
 * This must match the $handle-radius variable in multiSlider.scss.
 *
 * @const {number}
 */
const HANDLE_RADIUS = 6;

/**
 * The MultiSlider component.
 */
export class MultiSlider extends React.Component {
  /**
   * The element node.
   *
   * @type {?HTMLElement}
   */
  @observable domEl = null;

  /**
   * @constructor
   * @param {{}} props
   * @param {{}} componentContext
   */
  constructor(props, componentContext) {
    super(props, componentContext);
  }

  /**
   * Calculates the x position for the handle.
   *
   * @param {number} xValue
   * @returns {number}
   */
  calculateLineX = (xValue) => {
    const {minValue, maxValue} = this.props;
    const width = this.getSliderWidth();
    const totalDelta = maxValue - minValue;
    const adjustedValue = xValue - minValue;
    const normalizedX = (adjustedValue / totalDelta) * width;

    return Math.round(normalizedX);
  };

  /**
   * Gets the width of the slider.
   *
   * @returns {number}
   */
  getSliderWidth = () => {
    const placeholderWidth = 100;
    return lodash.get(this.domEl, 'clientWidth', placeholderWidth);
  };

  /**
   * Triggered right after the wrapped component is added to OR removed from the page.
   *
   * @param {{}} domEl
   */
  @action onChangeMount = (domEl) => {
    if (this.domEl) {
      return;
    }

    this.domEl = findDOMNode(domEl);
  };

  /**
   * Gets the current values.
   *
   * @returns {number[]}
   */
  getValues = () => {
    const {maxValue, minValue, startValue, endValue} = this.props;

    let safeStartValue = between(startValue, minValue, maxValue);
    const safeEndValue = between(endValue, minValue, maxValue);
    if (safeStartValue > safeEndValue) {
      safeStartValue = safeEndValue;
    }

    return [
      safeStartValue,
      safeEndValue,
    ];
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const values = this.getValues();
    const width = this.getSliderWidth();

    return (
      <div className="multi-slider" ref={this.onChangeMount}>
        <div className="multi-slider-track" />

        {values.map((value, index) => {
          const key = index;
          const handleX = this.calculateLineX(value);

          const style = (index) ? {
            left: 'auto',
            right: width - handleX - HANDLE_RADIUS,
          } : {
            left: handleX - HANDLE_RADIUS,
            right: 'auto',
          };

          return (
            <div
              key={key}
              className="multi-slider-handle"
              style={style}
            />
          );
        })}
      </div>
    );
  }
}

MultiSlider.propTypes = {
  endValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  onChangeEnd: PropTypes.func.isRequired,
  onChangeStart: PropTypes.func.isRequired,
  startValue: PropTypes.number.isRequired,

  minSeparation: PropTypes.number,
};

MultiSlider.defaultProps = {
  minSeparation: 0,
};

export default sliderInteractHoc(
  observer(MultiSlider)
);
