/* eslint-disable complexity */
import classNames from 'classnames';
import lodash from 'lodash';
import {observable, action, computed, runInAction} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Alert} from 'reactstrap';

import OrderDetails from '../onboarding/components/orderDetails/PlanOrderDetails';
import AlaCarteOrderDetails from '../onboarding/components/orderDetails/AlaCarteOrderDetails';
import ChargebeeCheckout from '../../common/chargebeeCheckout/ChargebeeCheckout';
import Link from '../../common/link/Link';
import PricingTabs from '../../common/pricingTabs/PricingTabs';
import OnboardUserSigns from '../onboarding/components/onboardUserSigns/OnboardUserSigns';
import inject from '../../hoc/injectHoc';
import {dashboardRoute} from '../../routePaths';
import {STATE_PRE, STATE_PENDING} from '../../../constants/asyncConstants';
import LoadingIcon from '../../common/loadingIcon/LoadingIcon';
import {ALA_CARTE_CREDITS} from '../../../constants/pricingConstants';

import './userPaymentPage.scss';
import UpdatePlanAlert from './components/updatePlanAlert/UpdatePlanAlert';
import CannotUpdatePlanAlert from './components/cannotUpdatePlanAlert/CannotUpdatePlanAlert';
import {UPDATE_PLAN_REASON_TRIAL, UPDATE_PLAN_REASON_COUPON, UPDATE_PLAN_REASON_SUBSCRIPTION_DELETED, PAYMENT_PLAN_MONTHLY} from '../../../constants/subscriptionPlanConstants';

const SECTION_1 = 'section1';
const SECTION_2 = 'section2';

/**
 * The UserPaymentPage component.
 */
export class UserPaymentPage extends React.Component {
  /**
   * Current section displayed in the onboarding process
   * Should only use the constants SECTION_1 or SECTION_2
   *
   * @type {string}
   */
  @observable currentSection = SECTION_1;

  /**
   * The currently selected plan.
   * This will be selected in the SubscriptionPlanTable component.
   *
   * @type {object}
   */
  @observable currentPlan = null;

  /**
   * The starting plan id.
   * This will clear when the plan is selected.
   *
   * @type {?number}
   */
  @observable startingPlanId = null;

  /**
   * The starting credit data.
   * This will clear when the plan or credits are selected.
   *
   * @type {?number}
   */
  @observable startingAlaCarteCredit = null;

  /**
   * The a la carte credits the user wants to purchase
   *
   * @type {?{
   *  creditId: string,
   *  quantity: number
   * }}
   */
  @observable alaCarteCredits = null;

  /**
   * Chargebee Checkout is Loading
   *
   * @type {boolean}
   */
  @observable isLoadingChargebeeCheckout = false

  /**
   * Chargebee Checkout details
   *
   * @type {{openCheckout: boolean, hostedPage: {}, error: boolean}}
   */
  @observable chargebeeData = {
    openCheckout: false,
    hostedPage: null
  }

  /**
   * The order details billing type selected
   *
   * @type {string}
   */
  @observable billingType = PAYMENT_PLAN_MONTHLY;

  /**
   * Flag for if there is an error while loading chargebee checkout
   *
   * @type {boolean}
   */
  @observable hasChargebeeCheckoutError = false;

  /**
   * Triggered when the component is first mounted to the page.
   */
  @action componentDidMount() {
    const {apiUserCanUpdatePlanStore, apiUserGetMeStore, apiUserOnboardStore, apiCompanySignGetAllStore} = this.props;

    // Reset the section to the first.
    this.currentSection = SECTION_1;

    apiUserCanUpdatePlanStore.refresh();
    const {canUpdatePlan} = apiUserCanUpdatePlanStore.userCanUpdatePlan;

    const startingPlanId = this.getPlanId();
    if (startingPlanId && canUpdatePlan) {
      this.startingPlanId = startingPlanId;
    }

    const startingCreditData = this.getCreditData();
    if (startingCreditData) {
      this.startingAlaCarteCredit = startingCreditData;
    }

    apiUserOnboardStore.clearAll();
    apiUserGetMeStore.refresh();
    apiCompanySignGetAllStore.refresh();

    if (!startingPlanId) {
      apiUserGetMeStore.getPromise().then(action('userPaymentPage apiUserGetMe', (user) => {
        if (user && user.projectContentCompany && user.projectContentCompany.planId) {
          this.startingPlanId = startingPlanId;
        }
      }));
    }
  }

  /**
   * Gets the plan id from the url params.
   *
   * @param {{match: {params: {pid: number}}}=} props
   * @returns {?number}
   */
  getPlanId = (props) => {
    const planId = lodash.get(props || this.props, 'match.params.planId');
    if (!planId || !Number(planId)) {
      return null;
    }

    return Number(planId);
  };

  /**
   * Gets the credit id and qty from the url params.
   * @returns {{download: number, request: number} | null}
   */
  getCreditData = () => {
    const downloadCreditQty = lodash.get(this.props, 'match.params.downloadCreditQty');
    const requestCreditQty = lodash.get(this.props, 'match.params.requestCreditQty');

    if (!Number(downloadCreditQty) && !Number(requestCreditQty)) {
      return null;
    }

    return {
      download: Number(downloadCreditQty),
      request: Number(requestCreditQty)
    };
  };

  /**
   * Determines update plan message
   *
   * @param {{}} user
   * @returns {string}
   */
  getUpdatePlanAlertMessage = (user) => {
    if (!user || !user.requiresPlanUpdate) {
      return '';
    }

    switch (user.planUpdateReason) {
      case UPDATE_PLAN_REASON_TRIAL:
        return 'Your trial has expired. Please update your plan to continue.';
      case UPDATE_PLAN_REASON_COUPON:
        return 'Your coupon has expired. Please update your plan to continue.';
      case UPDATE_PLAN_REASON_SUBSCRIPTION_DELETED:
        return 'Your subscription has ended. Please update your plan to continue.';
      default:
        return 'Your account requires a plan update. Please choose a plan to continue.';
    }
  }

  /**
   * Changes the currently displayed section
   * Should only use the constants SECTION_1 or SECTION_2
   *
   * @param {string} section
   * @param {boolean} force
   */
  @action changeSection = (section, force) => {
    if (force) {
      this.currentSection = section;
      return;
    }

    if ((section === SECTION_1)) {
      // reset currentPlan and alaCarteCredit if user backs out of plan page
      this.currentPlan = null;
      this.alaCarteCredits = null;
    }

    if ((section === SECTION_2) && (!this.currentPlan && !this.alaCarteCredits)) {
      // Don't let the user leave step 1 until they choose a plan or credits.
      return;
    }

    this.currentSection = section;
  };

  /**
   * Change the currently selected plan.
   *
   * @param {{}} plan The plan object from the database.
   */
  @action onPlanSelect = (plan) => {
    const {onboardingStore} = this.props;
    this.currentPlan = plan;
    this.startingPlanId = null;

    onboardingStore.setPlanData(plan);

    this.changeSection(SECTION_2);
  };

  /**
   * Purchase an a la carte credit
   *
   * @param {{
   *  download: number,
  *  request: number
  * }} alaCarteCreditsParam
   */
  @action onPurchaseCredits = (alaCarteCreditsParam) => {
    this.alaCarteCredits = alaCarteCreditsParam;
    this.startingAlaCarteCredit = null;

    this.changeSection(SECTION_2);
  }

  /**
   * Change the entered property
   *
   * @param {string} key
   * @param {{}} value
   */
  @action onChange = (key, value) => {
    this[key] = value;
  }

  /**
   * Control the Chargebee Checkout process
   */
  @action checkoutWithChargebee = async () => {
    const {apiUserOnboardStore} = this.props;

    const data = {
      subscription: null,
      addons: null,
    };

    if (this.currentPlan && this.currentPlan.id) {
      data.subscription = {
        planId: this.currentPlan.id,
        billingType: this.billingType,
      };
    }

    if (this.alaCarteCredits) {
      data.addons = [{
        itemId: ALA_CARTE_CREDITS.download.chargebeeId,
        quantity: this.alaCarteCredits.download
      }, {
        itemId: ALA_CARTE_CREDITS.request.chargebeeId,
        quantity: this.alaCarteCredits.request
      }];
    }

    try {
      runInAction('Set the Chargebee Checkout Loading', () => {
        this.isLoadingChargebeeCheckout = true;
      });

      // reset checkout error
      this.hasChargebeeCheckoutError = false;
      const chargebeeCheckoutResponse = await apiUserOnboardStore.chargebeeCheckout(data);

      runInAction('Set the chargebee hosted page.', () => {
        this.chargebeeData.hostedPage = chargebeeCheckoutResponse;
        this.chargebeeData.openCheckout = true;
      });
    } catch (error) {
      runInAction('Error loading chargebee hosted page.', () => {
        // eslint-disable-next-line no-console
        console.log('Chargebee Error: ', error);
        this.hasChargebeeCheckoutError = true;
      });
    } finally {
      runInAction('Set the Chargebee Checkout Loading', () => {
        this.isLoadingChargebeeCheckout = false;
      });
    }
  }

  @action onSuccessfulChargebeeCheckout = () => {
    const {routerStore, apiUserOnboardStore, onboardingStore, apiUserPollMeStore} = this.props;
    this.chargebeeData.openCheckout = false;

    this.changeSection(SECTION_1, true);

    // Call the server onboarding.
    apiUserOnboardStore.makeRequest(
      onboardingStore.getDataForServer()
    );
    apiUserPollMeStore.pollThenUpdateMyUser();

    apiUserOnboardStore.getPromise().then(() => {
      routerStore.push(dashboardRoute, {
        replaceIfTo: true,
      });
    });
  }

  /**
   * Calculate additional sign count
   */
  @computed get additionalSignCount() {
    const {apiCompanySignGetAllStore} = this.props;

    const userSigns = apiCompanySignGetAllStore.getFulfilled();
    const signCount = userSigns ? userSigns.length : 0;

    return (signCount > 0) ? (signCount - 1) : 0;
  }

  /**
   * Renders the current section of the onboarding process
   *
   * @returns {{}}
   */
  renderContent() {
    const {currentPlan, currentSection, chargebeeData, isLoadingChargebeeCheckout} = this;
    const {
      apiUserGetMeStore,
      apiUserOnboardStore,
      onboardingStore,
      apiCompanySignGetAllStore,
      apiUserCanUpdatePlanStore
    } = this.props;

    const user = apiUserGetMeStore.getFulfilled();
    onboardingStore.setUserData(user);
    const userSigns = apiCompanySignGetAllStore.getFulfilled();
    const isSaving = (apiUserOnboardStore.state !== STATE_PRE);
    const isSection1 = (!isSaving && currentSection === SECTION_1);
    const isSection2 = (!isSaving && currentSection === SECTION_2);

    const requiresPlanUpdate = user && user.requiresPlanUpdate;
    const updatePlanMessage = this.getUpdatePlanAlertMessage(user);

    // display loading gif until api to determine if user can update plan loads
    if (apiUserCanUpdatePlanStore.state === STATE_PRE || apiUserCanUpdatePlanStore.state === STATE_PENDING) {
      return (
        <LoadingIcon />
      );
    }

    // disable plan buttons if user cannot update plan
    const {canUpdatePlan: userCanUpdatePlan, canUpdateAlaCarte: userCanUpdateAlaCarte, message: cannotUpdatePlanMessage} = apiUserCanUpdatePlanStore.userCanUpdatePlan;

    // determine alert element if needed
    let alertElement;
    if (!userCanUpdatePlan && this.currentSection === SECTION_1) {
      alertElement = (<CannotUpdatePlanAlert message={cannotUpdatePlanMessage} />);
    } else if (requiresPlanUpdate) {
      alertElement = (<UpdatePlanAlert message={updatePlanMessage} />);
    }

    if (isSection1) {
      return (
        <div className="onboarding-content">
          {alertElement}
          <div className="my-3">
            <Link
              className="h6"
              to={dashboardRoute}
            >
              ← Back to Dashboard
            </Link>
          </div>
          <PricingTabs
            selectPlanId={this.startingPlanId}
            onPlanSelect={this.onPlanSelect}
            canUpdatePlan={userCanUpdatePlan}
            startingCredits={this.startingAlaCarteCredit}
            onPurchaseCredits={this.onPurchaseCredits}
            canUpdateAlaCarte={userCanUpdateAlaCarte}
            user={user}
            tabSelected={this.startingAlaCarteCredit ? 'credits' : 'subscription'}
          />
        </div>
      );
    }

    const previousSection = SECTION_1;
    const submitText = 'Confirm Purchase';
    const submitMethod = this.checkoutWithChargebee;
    const canManageSigns = currentPlan ? currentPlan.canManageSigns : true;

    return (
      <div className="onboarding-content">
        {alertElement}
        {(!isSaving) && (
          <div className="my-3">
            <a
              className="h6"
              tabIndex="0"
              onClick={() => this.changeSection(previousSection)}
            >
              ← Plan Selection
            </a>
          </div>
        )}

        <div className="row">
          {(isSection2) && (
            <OnboardUserSigns canManageSigns={canManageSigns} />
          )}

          {(currentPlan) && (
            <OrderDetails
              buttonText={submitText}
              isLoading={isLoadingChargebeeCheckout}
              plan={currentPlan}
              billingType={this.onChange}
              onSubmit={() => {
                submitMethod();
              }}
              user={user}
              userSigns={userSigns}
            />
          )}

          {(!currentPlan && this.alaCarteCredits) && (
            <AlaCarteOrderDetails
              alaCarteCredits={this.alaCarteCredits}
              buttonText={submitText}
              isLoading={isLoadingChargebeeCheckout}
              onSubmit={this.checkoutWithChargebee}
            />
          )}

          <ChargebeeCheckout
            openCheckout={chargebeeData.openCheckout}
            hostedPage={chargebeeData.hostedPage}
            onSuccessfulCheckout={this.onSuccessfulChargebeeCheckout}
          />
        </div>
      </div>
    );
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {currentSection, hasChargebeeCheckoutError} = this;

    return (
      <div id="user-payment-page" className="system-page full-height container-fluid">
        <div className={classNames('nav justify-content-center p-3', {[currentSection]: true})}>
          <div className="active-page-dot" />
          <div className="active-page-line" />
          <div className="d-none d-md-flex">
            <div
              onClick={() => this.changeSection(SECTION_1)}
              className={classNames('section-button', {active: currentSection === SECTION_1})}
            >
              Plans
            </div>
            <div
              onClick={() => this.changeSection(SECTION_2)}
              className={classNames('section-button', {active: currentSection === SECTION_2})}
            >
              Payment Method
            </div>
          </div>
        </div>
        <div className="container px-0">
          {(hasChargebeeCheckoutError) && (
            <Alert color="danger" className="signup-alert">
              There was an error loading the checkout page. Please try again. If the problem continues contact us at info@projectcontent.com
            </Alert>
          )}

          {this.renderContent()}
        </div>
      </div>
    );
  }
}

UserPaymentPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
  apiCompanySignGetAllStore: MobxPropTypes.observableObject,
  apiUserCanUpdatePlanStore: MobxPropTypes.observableObject,
  apiUserGetMeStore: MobxPropTypes.observableObject,
  apiUserOnboardStore: MobxPropTypes.observableObject,
  apiUserPollMeStore: MobxPropTypes.observableObject,
  onboardingStore: MobxPropTypes.observableObject,
  routerStore: MobxPropTypes.observableObject,
};

UserPaymentPage.wrappedComponent = {};
UserPaymentPage.wrappedComponent.propTypes = {
  apiCompanySignGetAllStore: MobxPropTypes.observableObject.isRequired,
  apiUserCanUpdatePlanStore: MobxPropTypes.observableObject.isRequired,
  apiUserGetMeStore: MobxPropTypes.observableObject.isRequired,
  apiUserOnboardStore: MobxPropTypes.observableObject.isRequired,
  apiUserPollMeStore: MobxPropTypes.observableObject.isRequired,
  onboardingStore: MobxPropTypes.observableObject.isRequired,
  routerStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(UserPaymentPage)(
  observer(UserPaymentPage)
);
