import React from 'react';
import propTypes from 'prop-types';

import ButtonAttentionWrapper from '../buttonAttentionWrapper/ButtonAttentionWrapper';

import './generateTextButton.scss';

/**
 * The GenerateTextButton component.
 */
export class GenerateTextButton extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {className, onClick, style} = this.props;

    return (
      <ButtonAttentionWrapper
        className={className}
        style={style}
      >
        <button
          className="btn btn-dark-blue btn-sm text-white w-100 d-flex align-items-center justify-content-between"
          type="button"
          onClick={onClick}
          gtm-id="ai-generate-text"
        >
          Generate Text
          <span className="badge badge-primary ml-2 text-uppercase">Beta</span>
        </button>
      </ButtonAttentionWrapper>
    );
  }
}

GenerateTextButton.propTypes = {
  className: propTypes.string,
  onClick: propTypes.func,
  style: propTypes.object,
};

export default GenerateTextButton;
