import lodash from 'lodash';
import {action, toJS} from 'mobx';

import apiContentGetOneStore from './apiContentGetOneStore';
import ApiBaseStore from '../common/apiBaseStore';
import apiContentFolderGetAllStore from '../contentFolder/apiContentFolderGetAllStore';
import apiContentFolderGetContentStore from '../contentFolder/apiContentFolderGetContentStore';
import apiUserGetMeStore from '../user/apiUserGetMeStore';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';

/**
 * ApiContentCreateStore
 */
class ApiContentCreateStore extends ApiBaseStore {
  /**
   * Clones the content record and content source.
   *
   * @param {{id: number, sources: Object.<string, {}>, variables: Object}} content
   * @param {string} newName
   * @param {number} newFolderId
   */
  cloneContent(content, newName, newFolderId) {
    if (!content || !content.id) {
      return;
    }

    const safeContent = toJS(content);

    const contentBody = {
      contentFolderId: newFolderId,
      name: newName,
      isDraft: true,
      sources: safeContent.sources,
      variables: safeContent.variables,
      duration: safeContent.duration,
      width: safeContent.width,
      height: safeContent.height,
    };

    this.makeRequest(contentBody);
  }

  /**
   * Fetches content info from the server
   *
   * @param {{
   *   contentFolderId: ?number,
   *   name: string,
   *   sources: {},
   *   variables: {},
   * }} contentData
   */
  @action makeRequest(contentData) {
    this.state = STATE_PENDING;
    this[EXPIRE_TIME] = Date.now() + EXPIRES_PENDING;

    const safeContentData = {...contentData};
    lodash.unset(safeContentData, 'id');
    lodash.unset(safeContentData, 'sources');

    let createdContent = null;

    serverApi.contentCreate(
      safeContentData
    ).then((newContent) => {
      if (!newContent) {
        return false;
      }

      createdContent = newContent;

      if (!contentData.sources) {
        return false;
      }

      return this.createContentSources(createdContent.id, contentData.sources);
    }).then(
      action('contentCreateSuccess', () => {
        this.error = null;
        this.state = STATE_FULFILLED;
        this.data = createdContent;
        this[EXPIRE_TIME] = Date.now() + EXPIRES_IN;

        // Clear the content cache.
        apiContentGetOneStore.refresh(createdContent.id, true);

        // Clear the content folder cache.
        if (createdContent.folderId) {
          apiContentFolderGetContentStore.refresh(createdContent.folderId, true);
        }

        // Clear the folder cache in case the default folder was created.
        apiContentFolderGetAllStore.refresh(true);

        // Refresh the user to get the new download credit count.
        apiUserGetMeStore.refresh(true);
      }),
      action('contentCreateError', (error) => {
        this.error = error;
        this.state = STATE_REJECTED;
        this.data = null;
      })
    );
  }

  /**
   * Creates the content sources.
   *
   * @param {number} contentId
   * @param {Object.<string, {}>} sources
   * @returns {Promise}
   */
  createContentSources(contentId, sources) {
    if (!sources) {
      return Promise.resolve(false);
    }

    const updatedSources = {};
    lodash.forEach(sources, (source, aspectRatio) => {
      updatedSources[aspectRatio] = {...source, aspectRatio};
    });

    return serverApi.contentSourceSetAll(contentId, updatedSources);
  }
}

export default new ApiContentCreateStore();
