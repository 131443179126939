import classNames from 'classnames';
import lodash from 'lodash';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import entityActivateHoc from '../../hoc/entityActivateHoc';
import {loadDebouncedFitText} from '../../../utils/fontSizeHelper';

import './displayText.scss';
import {onClickEntityEvent} from '../utils';

/**
 * The DisplayText component.
 */
export class DisplayText extends React.Component {
  /**
   * The reference to the text wrapper.
   *
   * @type {{current: {}}}
   */
  textWrapperRef = React.createRef();

  /**
   * Adjusts the text size until it fits in the entity container box.
   * This function is debounced for each instance of this component.
   */
  debouncedFitText = loadDebouncedFitText();

  /**
   * Triggered when the component is first mounted to the page.
   */
  componentDidMount() {
    this.debouncedFitText(this.textWrapperRef.current);
  }

  /**
   * Triggered whenever the component reacts to MobX updates.
   */
  componentWillReact() {
    this.debouncedFitText(this.textWrapperRef.current);
  }

  /**
   * Translates the decorations into css styles for the given word.
   *
   * @param {{isBold: boolean, isItalic: boolean, isUnderlined: boolean}} word
   * @returns {{}}
   */
  getDecorationsForWord = (word) => {
    const styles = {};
    if (word.isBold) {
      styles.fontWeight = 'bold';
    }
    if (word.isItalic) {
      styles.fontStyle = 'italic';
    }
    if (word.isUnderlined) {
      styles.textDecoration = 'underline';
    }
    if (word.stroke) {
      styles.textStroke = word.stroke;
      styles.WebkitTextStroke = word.stroke;
    }
    return styles;
  };

  /**
   * Translates the transformations into css styles for the given word.
   *
   * @param {{perspective: number, rotate: number, scale: number, skew: number, translate: number}} word
   * @returns {{transform: string}}
   */
  getTransformsForWord = (word) => {
    const validStarts = ['perspective', 'rotate', 'scale', 'skew', 'translate'];
    const properties2D = ['scale', 'skew', 'translate'];

    const transforms = [];
    lodash.forEach(word, (propertyValue, propertyName) => {
      const transformName = lodash.find(validStarts, (validStart) => {
        return lodash.startsWith(propertyName, validStart);
      });

      if (!transformName) {
        return;
      } else if (transformName !== 'scale' && !propertyValue) {
        // Every property but scale has the same value at 0 as not declaring it.
        return;
      } else if (transformName === 'scale' && String(propertyValue) === '1') {
        // A scale value of 1 is the same as no scale value.
        return;
      }

      let safeValue = String(propertyValue || 0);
      if (transformName === 'translate') {
        safeValue += 'px';
      } else if (transformName === 'skew' || transformName === 'rotate') {
        safeValue += 'deg';
      }

      if (lodash.includes(properties2D, propertyName)) {
        transforms.push(`${propertyName}(${safeValue}, ${safeValue})`);
      } else {
        transforms.push(`${propertyName}(${safeValue})`);
      }
    });

    return {transform: transforms.join(' ')};
  };

  /**
   * Translates the filters into css styles for the given word.
   *
   * @param {{blur: number, brightness: number, contrast: number, sepia: number}} word
   * @returns {{filter: string}}
   */
  getFiltersForWord = (word) => {
    const validProps = ['blur', 'brightness', 'contrast', 'drop-shadow', 'hue-rotate', 'invert', 'saturate', 'sepia'];

    const filters = [];
    lodash.forEach(word, (propertyValue, propertyName) => {
      if (!propertyName || !lodash.includes(validProps, propertyName)) {
        return;
      } else if (propertyName === 'contrast' || propertyName === 'brightness') {
        if (propertyValue === 1 || propertyValue === '100%') {
          return;
        }
      } else if (!propertyValue) {
        // Every property but contrast and brightness has the same value at 0 as not declaring it.
        return;
      }

      let safeValue = String(propertyValue || 0);
      if (propertyName === 'blur') {
        safeValue += 'px';
      } else if (propertyName === 'hue-rotate') {
        safeValue += 'deg';
      }

      filters.push(`${propertyName}(${safeValue})`);
    });

    return {filter: filters.join(' ')};
  };

  /**
   * Gets the alignment for a line.
   *
   * @param {{alignment: string}} line
   * @returns {{textAnchor: string, dx: string}}
   */
  getAlignmentForLine = (line) => {
    const alignmentStyle = {};
    if (line.alignment === 'center') {
      alignmentStyle.display = 'flex';
      alignmentStyle.justifyContent = 'unsafe center';
    } else if (line.alignment === 'right') {
      alignmentStyle.display = 'flex';
      alignmentStyle.justifyContent = 'unsafe flex-end';
    } else {
      alignmentStyle.display = 'flex';
      alignmentStyle.justifyContent = 'unsafe flex-start';
    }

    return alignmentStyle;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, style, topStyle, className, onEntityClick, game} = this.props;

    const entityId = entity.get('id');
    const text = entity.get('text');

    const lines = ((text.transition) ? text.transition.lines : text.lines) || [];

    const wrapperStyle = {
      ...style,
      overflow: 'visible',
      whiteSpace: 'nowrap',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'unsafe center',
    };

    const onClickEvent = onClickEntityEvent(game, entity, onEntityClick);

    return (
      <div
        id={entityId}
        className={classNames('display-text', className)}
        style={{
          ...topStyle,
          pointerEvents: 'none',
        }}
      >
        <div
          style={wrapperStyle}
          ref={this.textWrapperRef}
        >
          {lines.map((line) => {
            const lineStyle = {
              letterSpacing: line.letterSpacing || undefined,
              lineHeight: line.lineHeight || undefined,
              ...this.getAlignmentForLine(line),
              pointerEvents: 'auto',
              cursor: onClickEvent ? 'pointer' : 'inherit',
            };

            return (
              <div
                key={line.id}
                style={lineStyle}
                onClick={onClickEvent}
              >
                {line.words.map((word) => {
                  const wordStyle = {
                    color: word.fill,
                    fontFamily: word.font,
                    fontSize: (word.fontSize) ? `${word.fontSize}px` : '1em',
                    opacity: word.opacity,
                    whiteSpace: 'pre',
                    ...this.getDecorationsForWord(word),
                    ...this.getTransformsForWord(word),
                    ...this.getFiltersForWord(word),
                  };

                  return (
                    <span
                      key={word.id}
                      style={wordStyle}
                    >{word.text}</span>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

DisplayText.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
  style: PropTypes.object.isRequired,
  topStyle: PropTypes.object.isRequired,

  className: PropTypes.string,
  onEntityClick: PropTypes.func,
};

export default entityActivateHoc(
  observer(DisplayText)
);
