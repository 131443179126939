import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import {action, observable, runInAction} from 'mobx';
import React from 'react';
import ReactSelect from 'react-select';

import {SUPER_ADMIN_ROLE} from '../../../../../constants/userConstants';
import EditorDimensions from '../../../editorDimensions/EditorDimensions';
import EditorNewDimensions from '../../../editorNewDimensions/EditorNewDimensions';
import EditorRemoveDimension from '../../../editorRemoveDimension/EditorRemoveDimension';
import inject from '../../../../hoc/injectHoc';

import './editorTemplateNavItem.scss';

/**
 * The EditorTemplateNavItem component.
 *
 * @property {{
 *   displayEditorStore: DisplayEditorStore,
 * }} props
 */
export class EditorTemplateNavItem extends React.Component {
  /**
   * Whether or not the user has permission to view this page.
   *
   * @type {?boolean}
   */
  @observable hasPermission = null;

  /**
   * Triggered when the component is added to the page.
   */
  componentDidMount() {
    const {apiUserGetMeStore} = this.props;

    apiUserGetMeStore.refresh();
    apiUserGetMeStore.getPromise().then((user) => {
      runInAction('editComposeControlsLoaded', () => {
        this.hasPermission = user.role === SUPER_ADMIN_ROLE;
      });
    });
  }

  /**
   * Changes the plan availability of the template.
   *
   * @param {{}} changeData
   */
  @action onChangeIsFree = (changeData) => {
    const newIsFree = changeData.value;

    const {
      /** @type {DisplayEditorStore} */ displayEditorStore,
    } = this.props;

    let content = displayEditorStore.content;
    content.isFree = Boolean(newIsFree);

    displayEditorStore.setContent(content);

    return;
  };

  /**
   * Renders the Editor Dimension add and remove buttons
   *
   * @returns {{}}
   */
  renderEditorDimensionButtons() {
    const {
      /** @type {DisplayEditorStore} */ displayEditorStore,
    } = this.props;

    const {content, sources} = displayEditorStore;

    const templatePlanOptions = [
      {label: 'Free', value: true},
      {label: 'Paid Only', value: false},
    ];

    if (this.hasPermission) {
      return (
        <>
          <EditorNewDimensions />

          {(sources.size > 1) && (
            <EditorRemoveDimension />
          )}

          <div className="template-title">
            {content.name}
          </div>

          <span className="template-plan dropdown">
            <ReactSelect
              className="site-select theme-header theme-input-white"
              classNamePrefix="react-select"
              isClearable={false}
              isMulti={false}
              onChange={this.onChangeIsFree}
              options={templatePlanOptions}
              value={templatePlanOptions.find((option) => option.value === content.isFree)}
            />
          </span>
        </>
      );
    }

    return (
      <div className="template-title">
        {content.name || 'Canvas'}
      </div>
    );
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      /** @type {DisplayEditorStore} */ displayEditorStore,
    } = this.props;

    const {content, game, sources} = displayEditorStore;
    if (!content || !game || !sources.size) {
      return null;
    }

    const composeMode = game.composeMode;

    return (
      <div id="editor-template-nav-item" className="flex-fill">
        <EditorDimensions />

        {(composeMode) &&
          (<div className="template-title">
            {content.name}
          </div>)
        }

        {(!composeMode) && this.renderEditorDimensionButtons() }
      </div>
    );
  }
}

EditorTemplateNavItem.propTypes = {
  apiUserGetMeStore: MobxPropTypes.observableObject,
  displayEditorStore: MobxPropTypes.observableObject,
};

EditorTemplateNavItem.wrappedComponent = {
  propTypes: {
    apiUserGetMeStore: MobxPropTypes.observableObject.isRequired,
    displayEditorStore: MobxPropTypes.observableObject.isRequired,
  },
};

export default inject(EditorTemplateNavItem)(
  observer(EditorTemplateNavItem)
);
