/**
 * The action rename entity.
 *
 * @param {string} newName
 * @returns {{actionRenameEntity: {}}}
 */
export function actionRenameEntityComponent(newName) {
  return {
    actionRenameEntity: {
      newName,
    },
  };
}
