import lodash from 'lodash';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React, {Component} from 'react';
import ReactSelect from 'react-select';

import Link from '../../common/link/Link';
import LoadingIcon from '../../common/loadingIcon/LoadingIcon';
import inject from '../../hoc/injectHoc';
import {adminBannersListRoute} from '../../routePaths';
import SelectFileModal, {FILE_TYPE_IMAGE} from '../../modals/selectFile/SelectFileModal';
import './adminBannerEditPage.scss';

/**
 * Maps the field ids to their property name and label name.
 *
 * @type {Object<string, {propertyName: string, label: string}>}
 */
const fieldMap = {
  'banner-type': {propertyName: 'type', label: 'Type', submit: 'type'},
  'banner-file-id': {propertyName: 'fileId', label: 'Image', submit: 'fileId'},
  'banner-url': {propertyName: 'url', label: 'Link', submit: 'url'},
  'banner-published': {propertyName: 'isPublished', label: 'Published', submit: 'isPublished'},
};

/**
 * The banner publish options.
 *
 * @type {Array<{}>}
 */
const publishOptions = [
  {value: true, label: 'Yes'},
  {value: false, label: 'No'}
];

/**
 * The AdminBannerEditPage component.
 */
export class AdminBannerEditPage extends Component {
  /**
   * The current banner object.
   *
   * @type {?{}}
   */
  @observable banner = null;

  /**
   * The banner's type.
   *
   * @type {string}
   */
  @observable type = '';

  /**
   * The banner's fileId.
   *
   * @type {number}
   */
  @observable fileId = 0;

  /**
   * The banner's image URL.
   *
   * @type {string}
   */
  @observable displayPath = '';

  /**
   * The banner's URL.
   *
   * @type {string}
   */
  @observable url = '';

  /**
   * The banner's published flag.
   *
   * @type {boolean}
   */
  @observable isPublished = false;

  /**
   * Whether or not the image choose/upload modal is open.
   *
   * @type {boolean}
   */
  @observable isSelectingImage = false;

  /**
   * Triggered when the component first mounts to the page.
   */
  componentDidMount() {
    this.preloadBanner(this.props);
  }

  /**
   * Triggered when the props change.
   *
   * @param {{}} prevProps
   */
  componentDidUpdate(prevProps) {
    const previousBannerId = this.getBannerId(prevProps);
    const currentBannerId = this.getBannerId(this.props);

    if (previousBannerId !== currentBannerId) {
      this.preloadBanner(this.props);
    }
  }

  /**
   * Gets the banner id from the url params.
   *
   * @param {{}=} props
   * @returns {number|undefined}
   */
  getBannerId = (props) => {
    const {
      /** @type {RouterStore} */ routerStore
    } = (props || this.props);

    return routerStore.getParam('bannerId');
  };

  /**
   * Pre-loads the banner data based on the param.
   *
   * @param {{}} props
   */
  preloadBanner(props) {
    const bannerId = this.getBannerId(props);
    if (!bannerId) {
      return;
    }

    const {apiBannerGetOneStore} = this.props;

    apiBannerGetOneStore.refresh(bannerId);
    apiBannerGetOneStore.getPromise(bannerId).then(action('afterBannerPreloaded', (banner) => {
      this.banner = banner;

      this.type = banner.type;
      this.fileId = banner.fileId;
      this.displayPath = banner.displayPath;
      this.url = banner.url;
      this.isPublished = banner.isPublished;
    }));
  }

  /**
   * Triggered when the user confirms that the role should be changed.
   *
   * @param {boolean} newValue
   */
  @action onPublishChange = (newValue) => {
    this.onSelectUpdate('banner-published')(newValue);
  };

  /**
   * Triggered when the Select Image button is pressed.
   */
  @action onSelectImage = () => {
    this.isSelectingImage = true;
  }

  /**
   * Triggered when the new image is selected by the modal.
   *
   * @param {{id: number, displayPath: string, placeholderImage: boolean}} newContent
   */
  @action onImageSelected = (newContent) => {
    this.isSelectingImage = false;

    if (!newContent) {
      return;
    }

    if (newContent.displayPath && newContent.id) {
      this.fileId = newContent.id;
      this.displayPath = newContent.displayPath;
    } else if (newContent.placeholderImage) {
      // do nothing
    }
  };

  /**
   * Changes a form input's value.
   *
   * @param {string} elementId
   * @returns {function}
   */
  onFormInputUpdate = (elementId) => {
    const {propertyName} = fieldMap[elementId];

    return action('changeInputValue', (changeEvent) => {
      this[propertyName] = changeEvent.target.value;
    });
  };

  /**
   * Changes a form select's value.
   *
   * @param {string} elementId
   * @returns {function}
   */
  onSelectUpdate = (elementId) => {
    const {propertyName} = fieldMap[elementId];

    return action('changeSelectValue', ({value}) => {
      this[propertyName] = value;
    });
  };

  /**
   * Submits the form to the server.
   */
  onSubmitForm = () => {
    const {apiBannerUpdateAdminStore} = this.props;

    const bannerId = this.getBannerId();

    const bannerData = {};
    lodash.forEach(fieldMap, ({propertyName, submit}) => {
      const value = this[propertyName];
      if (typeof value !== 'undefined' && value !== this.banner[submit]) {
        bannerData[submit] = value;
      }
    });

    apiBannerUpdateAdminStore.makeRequest(bannerId, bannerData);
  };

  /**
   * Renders the user edit form.
   *
   * @returns {{}}
   */
  renderBannerEditForm = () => {
    const {apiBannerUpdateAdminStore} = this.props;
    const bannerId = this.getBannerId();

    const saveButton = (
      <button
        type="button"
        className="btn btn-primary text-light"
        onClick={this.onSubmitForm}
      >Save</button>
    );

    const previewImageStyle = {
      backgroundImage: `url(${this.displayPath})`,
    };

    return (
      <form className="banner-edit-form">
        {/* <div className="row">
          <div className="form-group col">
            <label htmlFor="banner-type">Type</label>
            <input
              readOnly
              disabled
              placeholder="Type"
              id="banner-type"
              className="form-control"
              value={this.type}
              onChange={this.onFormInputUpdate('banner-type')}
            />
          </div>
        </div> */}
        <div className="row">
          <div className="form-group col">
            <label htmlFor="banner-image">Image</label>
            <div className="d-flex w-100">
              <div
                className="preview-item"
              >
                <div
                  className="flex-fill img d-flex justify-content-center align-items-center align-content-center"
                  style={previewImageStyle}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="btn btn-primary text-light"
                onClick={this.onSelectImage}
              >Select Image</button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="banner-url">Link</label>
            <input
              placeholder="Link"
              id="banner-url"
              className="form-control"
              value={this.url}
              onChange={this.onFormInputUpdate('banner-url')}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="banner-published">Published</label>
            <ReactSelect
              id="banner-published"
              className="site-select state-select theme-light"
              classNamePrefix="react-select"
              placeholder="Select One"
              isClearable={false}
              isMulti={false}
              onChange={this.onPublishChange}
              options={publishOptions}
              value={publishOptions.find((option) => option.value === this.isPublished)}
            />
          </div>
        </div>

        {apiBannerUpdateAdminStore.case(bannerId, {
          'pre': () => saveButton,
          'pending': () => (<LoadingIcon />),
          'rejected': () => (
            <>
              <div className="alert alert-danger">
                The banner could not be saved.
              </div>

              {saveButton}
            </>
          ),
          'fulfilled': () => (
            <>
              <div className="alert alert-success">
                The banner was updated successfully.
              </div>

              {saveButton}
            </>
          ),
        })}
      </form>
    );
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {apiBannerGetOneStore} = this.props;

    const bannerType = lodash.startCase(this.type);
    const bannerId = this.getBannerId();

    return (
      <div id="admin-banner-edit-page" className="system-page full-height">
        <div className="container">
          <Link to={adminBannersListRoute}>
            <h4 className="h6">← Back to Banners</h4>
          </Link>

          <div className="mt-3">
            <h3 className="main-header">Edit {bannerType} Banner</h3>

            {apiBannerGetOneStore.case(bannerId, {
              pre: () => (<LoadingIcon />),
              pending: () => (<LoadingIcon />),
              rejected: () => (
                <div className="alert alert-warning">
                  The banner could not be loaded.
                </div>
              ),
              fulfilled: (banner) => (
                this.renderBannerEditForm(banner)
              ),
            })}
          </div>
        </div>

        <SelectFileModal
          isOpen={this.isSelectingImage}
          onComplete={this.onImageSelected}
          type={FILE_TYPE_IMAGE}
        />
      </div>
    );
  }
}

AdminBannerEditPage.propTypes = {
  apiAdminBannerGetAllStore: MobxPropTypes.observableObject,
  apiBannerGetOneStore: MobxPropTypes.observableObject,
  apiBannerUpdateAdminStore: MobxPropTypes.observableObject,
  routerStore: MobxPropTypes.observableObject,
};

AdminBannerEditPage.wrappedComponent = {};
AdminBannerEditPage.wrappedComponent.propTypes = {
  apiAdminBannerGetAllStore: MobxPropTypes.observableObject.isRequired,
  apiBannerGetOneStore: MobxPropTypes.observableObject.isRequired,
  apiBannerUpdateAdminStore: MobxPropTypes.observableObject.isRequired,
  routerStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(AdminBannerEditPage)(
  observer(AdminBannerEditPage)
);
