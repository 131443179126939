import {action, observable, runInAction, toJS} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';
import axios from 'axios';
import uuid from 'uuid/v4';

import FileItem from './components/fileItem/FileItem';
import UserFileFolders from './components/userFileFolders/UserFileFolders';
import SelectFileHeader from './components/selectFileHeader/SelectFileHeader';
import FileUpload from '../../common/fileUpload/FileUpload';
import LoadingIcon from '../../common/loadingIcon/LoadingIcon';
import PreloadWritableFolder from '../../common/preloadWritableFolder/PreloadWritableFolder';
import inject from '../../hoc/injectHoc';
import ConfirmModal from '../../modals/confirm/ConfirmModal';
import FileUploadingModal from '../../modals/fileUploading/FileUploadingModal';
import MessageModal from '../message/MessageModal';

import './selectMediaModal.scss';

/**
 * Indicates that images should be shown.
 * @const {string}
 */
export const FILE_TYPE_MEDIA = 'media';

/**
 * Indicates that images should be shown.
 * @const {string}
 */
export const FILE_TYPE_IMAGE = 'image';

/**
 * Indicates that videos should be shown.
 * @const {string}
 */
export const FILE_TYPE_VIDEO = 'video';

/**
 * Indicates that videos and images can be in the folder.
 * @const {string}
 */
export const FILE_TYPE_ALL = 'all';

/**
 * Image File Mimes
 */
const FILE_MIMES_IMAGE = ['image/*'];

/**
 * Video File Mimes
 */
const FILE_MIMES_VIDEO = ['video/webm', 'video/mp4', 'video/quicktime'];

/**
 * Public Folder User Id
 */
const PUBLIC_FOLDER_USER_ID = 0;

/**
 * The SelectMediaModal component.
 *
 * @param {{
 *   apiFileGetFolderFilesStore: ApiFileGetFolderFilesStore,
 *   apiFileGetFolderFilesStore: ApiFileGetFolderFilesStore,
 *   apiFileUploadStore: ApiFileUploadStore,
 * }} props
 */
class SelectMediaModal extends React.Component {
  /**
   * The active file folder id.
   *
   * @type {?string}
   */
  @observable activeFolderId = null;

  /**
   * All Media folder ids
   *
   * @type {?Array.Number}
   */
  @observable allMediaFolderIds = null;

  /**
   * Whether or not to auto select the first loaded folder.
   *
   * @type {boolean}
   */
  @observable autoLoadFolder = true;

  /**
   * When changing from false to true, this will open the file uploading dialog.
   *
   * @type {boolean}
   */
  @observable openUploadDialog = false;

  /**
   * Whether or not there was an upload error.
   * @type {?Error}
   */
  @observable uploadError = null;

  /**
   * The pending delete file object.
   *
   * @type {?{id: number, filename: string}}
   */
  @observable deleteFile = null;

  /**
   * Whether or not the uploading modal is open.
   * This should match up directly to whether or not any files are uploading.
   * @type {boolean}
   */
  @observable isUploadingModalOpen = false;

  /**
   * Whether or not the delete confirm modal is open.
   *
   * @type {boolean}
   */
  @observable isDeleteModalOpen = false;

  /**
   * Error text
   *
   * @type {string|null}
   */
  @observable errorText = null;

  /**
   * Search results
   */
  @observable searchResults = [];

  /**
   * Show search results
   */
  @observable showSearchResults = false;

  /**
   * Flag to determine if user can upload or delete to selected folder
   *
   * @type {boolean}
   */
  @observable canUploadAndDeleteFiles = false;

  /**
   * Triggered when the component just mounted onto the page.
   */
  componentDidMount() {
    if (this.props.isOpen) {
      this.initializeModal();
    }
  }

  /**
   * Triggered when the component has just updated.
   *
   * @param {{isOpen: boolean}} prevProps
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.initializeModal();
    }
  }

  /**
   * Initializes the modal by getting the valid content libraries.
   */
  @action initializeModal = () => {
    // Put any initializations here.
    this.activeFolderId = null;
    this.autoLoadFolder = true;
  };

  /**
   * Opens the file upload dialog.
   */
  @action onOpenFileUploadDialog = () => {
    this.openUploadDialog = true;

    setTimeout(action(() => {
      this.openUploadDialog = false;
    }));
  };

  /**
   * Sets the search results
   *
   * @param {Array} results
   */
  @action onSearchResults = (results) => {
    this.searchResults = results || [];
    this.showSearchResults = true;
    this.activeFolderId = null;
    this.resetSearch = false;
  }

  /**
   * Clears the search results
   *
   * @param {Array} results
   */
  @action onClearSearch = () => {
    this.showSearchResults = false;
    this.searchResults = [];
    this.resetSearch = true;
  }

  /**
   * Load selected category's contents.
   *
   * @param {number} page
   */
  loadContents = (page) => {
    if (!this.activeFolderId || !this.allMediaFolderIds) {
      return;
    }

    const {apiFileGetFolderFilesStore} = this.props;

    const safePage = (page) ? Number(page) : 1;

    this.allMediaFolderIds.reverse().map((folderId) => {
      apiFileGetFolderFilesStore.refresh(folderId, safePage);

      apiFileGetFolderFilesStore.getPromise(folderId, safePage);
    });
  };

  /**
   * Triggered when the files refresh is clicked.
   *
   */
  onRefresh = () => {
    const {apiFileGetFolderFilesStore} = this.props;

    const safePage = 1;

    // Refresh the content.
    this.allMediaFolderIds.map((mediaFolderId) => apiFileGetFolderFilesStore.refresh(mediaFolderId, safePage, true));
  };

  /**
   * Updates the active folder.
   *
   * @param {{id: number}} folder
   * @param {{}} allFolderIds
   */
  @action onFolderSelected = (folder, allFolderIds) => {
    if (!folder) {
      return;
    }

    this.canUploadAndDeleteFiles = folder.userId !== 0;

    this.onClearSearch();
    this.autoLoadFolder = false;
    this.activeFolderId = String(folder.id);
    this.allMediaFolderIds = folder.id === FILE_TYPE_MEDIA ? allFolderIds : [folder.id];

    this.loadContents(1);
  };

  /**
   * Get safe folder id accounting for All Media folder
   *
   * @returns {Promise<number>}
   */
  getSafeFolderId() {
    const {apiFileGetUserFoldersStore, apiFileFolderCreateStore} = this.props;

    if (this.activeFolderId !== FILE_TYPE_MEDIA) {
      return Promise.resolve(this.activeFolderId);
    }

    const makeNewAllFilesFolder = () => {
      const {newFolderName, folderType} = this.getFileInfo();
      apiFileFolderCreateStore.makeRequest(
        newFolderName,
        folderType,
      );
      return apiFileFolderCreateStore.getPromise().then((newFolderData) => newFolderData.id);
    };

    return apiFileGetUserFoldersStore.getPromise(FILE_TYPE_ALL).then((folders) => {
      const firstUserFolder = folders.find((folder) => folder.userId !== PUBLIC_FOLDER_USER_ID);
      if (firstUserFolder) {
        return firstUserFolder.id;
      }

      return makeNewAllFilesFolder();
    });
  }

  /**
   * Uploads the selected files.
   *
   * @param {Array<{}>} files
   * @param {Array<{name: string, message: string}>} rejectedFiles
   */
  onUploadFiles = (files, rejectedFiles) => {
    const {apiFileUploadStore} = this.props;

    this.openUploadingModal();

    if (rejectedFiles) {
      apiFileUploadStore.addRejectedFiles(rejectedFiles);
    }
    const closeAfterSuccessTimeout = 2000;

    let safeActiveFolderId = null;
    this.getSafeFolderId().then((id) => {
      safeActiveFolderId = id;
      return apiFileUploadStore.makeRequest(
        files,
        safeActiveFolderId,
        rejectedFiles
      );
    })
      .then(() => {
        this.onRefresh(safeActiveFolderId);

        setTimeout(() => {
          this.closeUploadingModal();
        }, closeAfterSuccessTimeout);
      }).catch((uploadError) => {
        runInAction('showUploadError', () => {
          this.uploadError = uploadError;
        });
      });
  };

  /**
   * Upload generated image
   *
   * @param {{}} file
   */
  @action onUploadGenereatedImage = async (file) => {
    try {
      const {apiFileUploadStore, apiFileGetUserFoldersStore} = this.props;

      const {folderType} = this.getFileInfo();
      apiFileGetUserFoldersStore.makeRequest(folderType);
      const folders = await apiFileGetUserFoldersStore.getPromise(folderType);

      const imageFolderIds = folders
        .filter((folder) => folder.userId !== 0) // filter out public folders
        .filter((folder) => folder.type === 'image' || folder.type === 'all')
        .map((folder) => String(folder.id));

      const safeActiveFolderId = imageFolderIds.find((folderId) => folderId === this.activeFolderId)
        ? this.activeFolderId
        : imageFolderIds[0];

      this.openUploadingModal();
      await apiFileUploadStore.makeRequest(
        [file],
        safeActiveFolderId,
      );

      this.onFolderSelected({id: safeActiveFolderId});

      this.onRefresh();

      const closeAfterSuccessTimeout = 2000;
      await new Promise((resolve) => setTimeout(resolve, closeAfterSuccessTimeout));

      this.closeUploadingModal();
    } catch (uploadError) {
      runInAction('showUploadError', () => {
        this.uploadError = uploadError;
      });
    }
  };

  /**
   * Dismisses the too many error alert.
   */
  @action onDismissError = () => {
    this.uploadError = null;
  };

  /**
   * Opens the uploading modal.
   */
  @action openUploadingModal = () => {
    this.isUploadingModalOpen = true;
  };

  /**
   * Closes the uploading modal.
   */
  @action closeUploadingModal = () => {
    this.isUploadingModalOpen = false;
  };

  /**
   * Triggered when the delete file event was triggered.
   *
   * @param {{id: number, filename: string}} file
   */
  @action onDeleteFile = (file) => {
    this.isDeleteModalOpen = true;
    this.deleteFile = file;
  };

  /**
   * Deletes the file after the delete was confirmed.
   *
   * @param {boolean} wasConfirmed
   */
  @action afterConfirmDelete = (wasConfirmed) => {
    this.isDeleteModalOpen = false;

    if (!wasConfirmed) {
      return;
    }

    const {apiFileDeleteStore} = this.props;

    const fileId = this.deleteFile.id;
    const folderId = this.activeFolderId;

    apiFileDeleteStore.makeRequest(fileId);

    if (folderId) {
      apiFileDeleteStore.getPromise().then(() => {
        return this.onRefresh();
      });
    }

    this.deleteFile = null;
  };

  /**
   * On generated image select
   *
   * @param {string} url
   */
  @action onGeneratedImageSelect = async (url) => {
    try {
      const res = await axios({
        url,
        method: 'GET',
        responseType: 'blob',
      });

      const fileExtension = url.split('.').pop();

      const filename = `generated-image-${uuid()}.${fileExtension}`;
      const file = new File([res.data], filename, {
        type: res.data.type
      });

      await this.onUploadGenereatedImage(file);
    } catch (error) {
      runInAction('onGeneratedImageSelectError', () => {
        this.errorText = 'Error uploading generated image';
      });
    }
  }

  /**
   * On close error message modal
   */
  @action onCloseErrorModal = () => {
    this.errorText = null;
  }

  /**
   * Triggered when the modal is closed without a chosen file.
   */
  onCancelModal = () => {
    const {onComplete} = this.props;

    onComplete(null);
  };

  /**
   * Triggered when the modal is closed after choosing file.
   *
   * @param {{}} newContent
   */
  onCompleteModal = (newContent) => {
    const {onComplete} = this.props;

    onComplete(toJS(newContent));
  };

  /**
   * Indicates that a placeholder should be added.
   *
   * @param {boolean=} isVideo
   */
  onPlaceholderSelected = (isVideo) => {
    const {onComplete} = this.props;

    if (isVideo) {
      onComplete({
        placeholderVideo: true,
      });
      return;
    }

    onComplete({
      placeholderImage: true,
    });
  };

  /**
   * Gets file type information.
   *
   * @returns {{
   *   fileMimes: string,
   *   folderType: string,
   *   writableFolderType: string,
   *   newFolderName: string,
   *   showImages: boolean,
   *   showVideos: boolean
   * }}
   */
  getFileInfo = () => {
    const showImages = true;
    const showVideos = true;

    let newFolderName = (showImages) ? 'My Images' : 'My Videos';
    if (showImages && showVideos) {
      newFolderName = 'My Files';
    }

    let folderType = (showImages) ? 'images' : 'video';
    let writableFolderType = folderType;
    if (showImages && showVideos) {
      folderType = FILE_TYPE_MEDIA;
      writableFolderType = FILE_TYPE_ALL;
    }

    let fileMimes = (showImages) ? FILE_MIMES_IMAGE : FILE_MIMES_VIDEO;
    if (showImages && showVideos) {
      fileMimes = '*';
    }

    return {
      fileMimes,
      folderType,
      writableFolderType,
      newFolderName,
      showImages,
      showVideos,
    };
  };

  /**
   * Render Media Files
   * @param {number} folderId
   *
   * @returns {{}}
   */
  renderMediaFiles(folderId) {
    const {apiFileGetFolderFilesStore} = this.props;
    const isShowingAllMediaFolders = this.allMediaFolderIds.length > 1;

    if (!this.allMediaFolderIds) {
      return <p className="site-error">Select or Create a folder</p>;
    }

    const folderKey = (folderId) ? apiFileGetFolderFilesStore.getKey(folderId, 1) : null;
    return apiFileGetFolderFilesStore.case(folderKey, {
      pre: () => (
        <p key={folderKey} className="site-error">Select or Create a folder</p>
      ),
      pending: () => (<LoadingIcon key={folderKey} />),
      rejected: () => (
        <p className="site-error">There was an error loading files for this folder.</p>
      ),
      fulfilled: ((files) => {
        if ((!files || !files.length) && !isShowingAllMediaFolders) {
          return (
            <div key={folderKey}>
              <div className="file-item-upload files-list-flex d-flex">
                <FileUpload
                  fileMimes={[...FILE_MIMES_IMAGE, ...FILE_MIMES_VIDEO]}
                  onDrop={this.onUploadFiles}
                  openUploadDialog={this.openUploadDialog}
                />
              </div>
              <span className="site-no-results">No files found.</span>
            </div>
          );
        }

        return (
          <React.Fragment key={folderKey}>
            {(!isShowingAllMediaFolders && this.canUploadAndDeleteFiles) && (
              <FileUpload
                fileMimes={[...FILE_MIMES_IMAGE, ...FILE_MIMES_VIDEO]}
                onDrop={this.onUploadFiles}
                openUploadDialog={this.openUploadDialog}
              />)}
            {files.map((file) => (
              <FileItem
                key={`${folderKey}-${file.id}`}
                file={file}
                fileId={file.id}
                onClick={this.onCompleteModal}
                onDelete={this.onDeleteFile}
                onRefresh={this.onRefresh}
                disableDelete={!this.canUploadAndDeleteFiles}
              />
            ))}
          </React.Fragment>
        );
      }),
    });
  }

  /**
   * Renders search results.
   *
   * @returns {{}}
   */
  renderSearchResults() {
    if (!this.searchResults || this.searchResults.length < 1) {
      return (<span className="site-no-results">No files found.</span>);
    }

    return (
      this.searchResults.map((file) => (
        <FileItem
          key={`${file.id}`}
          file={file}
          fileId={file.id}
          onClick={this.onCompleteModal}
          onDelete={this.onDeleteFile}
          onRefresh={this.onRefresh}
        />))
    );
  }

  /**
   * Unsplash image seleect
   * @param {object} image
   */
  onUnsplashImageSelect = (image) => {
    const {onComplete} = this.props;

    const [rawImageUrl] = image.urls.raw.split('?');
    const fullUrl = `${rawImageUrl}?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&q=80&w=1080`;

    onComplete({
      id: image.id,
      filename: image.slug,
      displayPath: fullUrl,
    });
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      isOpen,
      title,
      forceOpenAIModal,
      forceOpenUnsplashModal,
    } = this.props;

    const {folderType, newFolderName, writableFolderType} = this.getFileInfo();

    const showFileUpload = !this.showSearchResults && this.allMediaFolderIds && this.allMediaFolderIds.length > 1;

    return (
      <Modal isOpen={isOpen} toggle={this.onCancelModal} className="select-media-modal" centered>
        <ModalHeader toggle={this.onCancelModal}>{title || 'Select File'}</ModalHeader>
        <ModalBody>
          <PreloadWritableFolder newFolderName={newFolderName} folderType={writableFolderType}>
            <div className="modal-body-content-wrapper">
              <div className="select-file-main">
                <div className="left-side">
                  <UserFileFolders
                    autoLoad={this.autoLoadFolder}
                    folderType={folderType}
                    onSelect={this.onFolderSelected}
                    selected={this.activeFolderId}
                    forceOpenAIModal={forceOpenAIModal}
                    forceOpenUnsplashModal={forceOpenUnsplashModal}
                    onGeneratedImageSelect={this.onGeneratedImageSelect}
                    onUnsplashImageSelect={this.onUnsplashImageSelect}
                  />
                </div>
                <div className="right-side">
                  <SelectFileHeader
                    isWritable={this.canUploadAndDeleteFiles}
                    onPlaceholderSelected={this.onPlaceholderSelected}
                    onOpenFileUploadDialog={this.onOpenFileUploadDialog}
                    onRefresh={this.onRefresh}
                    onSearchResults={this.onSearchResults}
                    resetSearch={this.resetSearch}
                  />

                  <div className="files-list">
                    <div className="files-list-flex d-flex">
                      {showFileUpload && (<FileUpload
                        fileMimes={[...FILE_MIMES_IMAGE, ...FILE_MIMES_VIDEO]}
                        onDrop={this.onUploadFiles}
                        openUploadDialog={this.openUploadDialog}
                      />)}

                      {(this.showSearchResults)
                        ? this.renderSearchResults()
                        : this.allMediaFolderIds && this.allMediaFolderIds.map((folderId) => this.renderMediaFiles(folderId))}
                    </div>
                  </div>
                </div>
              </div>

              {(this.uploadError) && (
                <ConfirmModal
                  confirmText="An error occurred while trying to upload files. One or more may not have been uploaded."
                  isOpen={true}
                  onComplete={this.onDismissError}
                />
              )}

              {(this.isDeleteModalOpen) && (
                <ConfirmModal
                  isOpen={true}
                  onComplete={this.afterConfirmDelete}
                  confirmText={`Are you sure you want to delete '${this.deleteFile.filename}'?`}
                />
              )}

              {(this.isUploadingModalOpen) && (
                <FileUploadingModal isOpen={true} onComplete={this.closeUploadingModal} />
              )}
            </div>

            {(this.errorText) && (<MessageModal
              isOpen={true}
              bodyText={this.errorText}
              onClose={this.onCloseErrorModal}
            />)}
          </PreloadWritableFolder>
        </ModalBody>
      </Modal>
    );
  }
}

SelectMediaModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,

  apiFileDeleteStore: MobxPropTypes.observableObject,
  apiFileFolderCreateStore: MobxPropTypes.observableObject,
  apiFileGetFolderFilesStore: MobxPropTypes.observableObject,
  apiFileGetUserFoldersStore: MobxPropTypes.observableObject,
  apiFileUploadStore: MobxPropTypes.observableObject,
  forceOpenAIModal: PropTypes.bool,
  forceOpenUnsplashModal: PropTypes.bool,
  title: PropTypes.string,
};

SelectMediaModal.defaultProps = {
  forceOpenAIModal: false,
  forceOpenUnsplashModal: false,
  title: 'Select Media',
};

SelectMediaModal.wrappedComponent = {};
SelectMediaModal.wrappedComponent.propTypes = {
  apiFileDeleteStore: MobxPropTypes.observableObject.isRequired,
  apiFileFolderCreateStore: MobxPropTypes.observableObject.isRequired,
  apiFileGetFolderFilesStore: MobxPropTypes.observableObject.isRequired,
  apiFileGetUserFoldersStore: MobxPropTypes.observableObject.isRequired,
  apiFileUploadStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(SelectMediaModal)(
  observer(SelectMediaModal)
);
