import {action} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Modal, ModalBody, ModalFooter} from 'reactstrap';

import LoadingIcon from '../../common/loadingIcon/LoadingIcon';
import inject from '../../hoc/injectHoc';
import {STATE_PENDING} from '../../../constants/asyncConstants';

import './contentUnPublishModal.scss';

/**
 * The default modal title.
 * @const {string}
 */
const DEFAULT_TITLE = 'Un-Publish Template';

/**
 * The ContentUnPublishModal component.
 */
export class ContentUnPublishModal extends React.Component {
  /**
   * Triggered when the component just mounted onto the page.
   */
  componentDidMount() {
    if (this.props.isOpen) {
      this.initializeModal();
    }
  }

  /**
   * Triggered when the component has just updated.
   *
   * @param {{isOpen: boolean}} prevProps
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.initializeModal();
    }
  }

  /**
   * Initializes the modal.
   */
  @action initializeModal = () => {
    const {contentId, apiContentGetOneStore} = this.props;

    apiContentGetOneStore.refresh(contentId);
  };

  /**
   * Tears down the modal.
   */
  tearDownModal = () => {
    // Do something.
  };

  /**
   * Triggered when the modal is closed without a finishing.
   */
  onCancelModal = () => {
    const {onComplete} = this.props;

    onComplete({canceled: true});

    this.tearDownModal();
  };

  /**
   * Triggered when the modal is closed after choosing a collection.
   */
  onCompleteModal = () => {
    const {
      apiContentUnPublishStore,
      apiContentFolderGetAllStore,
      apiCollectionGetAllStore,
      collection,
      contentId,
      onComplete
    } = this.props;

    apiContentUnPublishStore.makeRequest(contentId, collection.id);

    apiContentUnPublishStore.getPromise().then(() => {
      apiContentFolderGetAllStore.refresh(true);
      apiCollectionGetAllStore.refresh(true);

      onComplete({success: true});
    }).catch((publishError) => {
      onComplete({error: publishError});
    });

    this.tearDownModal();
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      collection,
      contentId,
      apiContentGetOneStore,
      apiContentUnPublishStore,
      isOpen,
      title
    } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={this.onCancelModal} className="content-un-publish-modal" centered>
        <p className="site-label">{title}</p>
        <ModalBody>
          {apiContentGetOneStore.case(contentId, {
            'pending': () => (
              <LoadingIcon size="lg" />
            ),
            'fulfilled': (content) => (
              <div className="alert alert-secondary" role="alert">
                Are you sure you would like to un-publish <strong>{content.name}</strong> from the collection
                {' '}
                <strong>{collection.name}</strong>?
              </div>
            ),
          })}
        </ModalBody>
        <ModalFooter>
          {(apiContentUnPublishStore.getState() === STATE_PENDING) ? (
            <span>
              <LoadingIcon size="sm" />
              Un-publishing...
            </span>
          ) : (
            <span>
              <button
                type="button"
                className="btn btn-default"
                onClick={this.onCancelModal}
              >Cancel</button>
              {' '}
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.onCompleteModal}
                disabled={apiContentGetOneStore.getState(contentId) === STATE_PENDING}
              >Un-Publish</button>
            </span>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

ContentUnPublishModal.propTypes = {
  collection: PropTypes.object.isRequired,
  contentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,

  apiCollectionGetAllStore: MobxPropTypes.observableObject,
  apiContentFolderGetAllStore: MobxPropTypes.observableObject,
  apiContentGetOneStore: MobxPropTypes.observableObject,
  apiContentUnPublishStore: MobxPropTypes.observableObject,
  title: PropTypes.string,
};

ContentUnPublishModal.defaultProps = {
  title: DEFAULT_TITLE,
};

ContentUnPublishModal.wrappedComponent = {};
ContentUnPublishModal.wrappedComponent.propTypes = {
  apiCollectionGetAllStore: MobxPropTypes.observableObject.isRequired,
  apiContentFolderGetAllStore: MobxPropTypes.observableObject.isRequired,
  apiContentGetOneStore: MobxPropTypes.observableObject.isRequired,
  apiContentUnPublishStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(ContentUnPublishModal)(
  observer(ContentUnPublishModal)
);
