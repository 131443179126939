import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {Progress} from 'reactstrap';
import {action, observable} from 'mobx';
import {observer} from 'mobx-react';

import './renderProgress.scss';

/**
 * Percent recalculation rate for progress display.
 */
const PROGRESS_REFRESH_RATE_MS = 10000;

/**
 * The maximum number of milliseconds the rendering process is expected to take.
 *
 * @type {number}
 */
const EXPECTED_FINISH_MS = 1000 * 60 * 2; // eslint-disable-line no-magic-numbers

/**
 * The RenderProgress component.
 */
export class RenderProgress extends React.Component {
  /**
   * Placeholder state to force a component re-render.
   */
  @observable percent = 0;

  /**
   * ID for refresh timeout
   */
  timeoutId = null;

  /**
   * Component is mounted
   */
  componentDidMount() {
    this.updateProgress();
  }

  /**
   * Component is about to unmount
   */
  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  /**
   * Calculates the percent complete for the given content.
   *
   * @param {{}} content
   * @returns {number}
   */
  @action calculatePercent(content) {
    if (!content.updatedAt) {
      return 0;
    }

    const updatedAt = new Date(content.updatedAt);
    const dateInMS = updatedAt.valueOf(); // Not sure if this is correct...
    const nowInMS = Date.now();

    if (!dateInMS) {
      // If the date was not formatted correctly, dateInMS would be NaN.
      return 0;
    }

    const timeElapsedMS = nowInMS - dateInMS;
    if (timeElapsedMS < 0) {
      return 0;
    }

    const toPercent = 100;
    const roughPercentComplete = Math.floor(timeElapsedMS / EXPECTED_FINISH_MS * toPercent);

    const maxPercent = 90;
    if (roughPercentComplete > maxPercent) {
      return maxPercent;
    } else if (roughPercentComplete <= 0) {
      return 0;
    }

    const modFive = 1;
    return roughPercentComplete - (roughPercentComplete % modFive);
  }

  /**
   * Force progress to update
   */
  @action updateProgress() {
    const {content} = this.props;

    this.percent = this.calculatePercent(content);

    this.timeoutId = setTimeout(() => {
      this.updateProgress();
    }, PROGRESS_REFRESH_RATE_MS);
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {percent} = this;
    const {content, size} = this.props;

    if (content.displayStatus !== 'processing') {
      // Do not render this component if the content is not processing.
      return null;
    }

    const sizeClass = (size === 'small') ? 'small' : 'large';

    return (
      <div className={classNames('render-progress', sizeClass)}>
        <Progress className="render-progress-bar" color="info" value={percent}>
          <span className="percent-label">{percent}%</span>
        </Progress>
      </div>
    );
  }
}

RenderProgress.propTypes = {
  content: PropTypes.object.isRequired,

  size: PropTypes.oneOf(['small', 'large']),
};

RenderProgress.defaultProps = {
  size: 'large',
};

export default observer(RenderProgress);
