import {action} from 'mobx';

import apiContentGetOneStore from './apiContentGetOneStore';
import ApiMapBaseStore from '../common/apiMapBaseStore';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';

/**
 * ApiContentRenderAllStore
 */
class ApiContentRenderAllStore extends ApiMapBaseStore {
  /**
   * Tells the server to render all the content.
   *
   * @param {number} contentId
   * @param {string} fileType
   */
  @action makeRequest(contentId, fileType) {
    const safeContentId = String(contentId);

    this.dataMap.set(safeContentId, {
      state: STATE_PENDING,
      [EXPIRE_TIME]: Date.now() + EXPIRES_PENDING,
      data: null,
      error: null,
    });

    serverApi.contentRender(
      contentId,
      fileType
    ).then(
      action('contentRenderAllSuccess', () => {
        this.dataMap.set(safeContentId, {
          state: STATE_FULFILLED,
          [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
          data: true,
          error: null,
        });

        apiContentGetOneStore.refresh(contentId, true);
      }),
      action('contentRenderAllError', (renderError) => {
        this.dataMap.set(safeContentId, {
          state: STATE_REJECTED,
          [EXPIRE_TIME]: null,
          data: null,
          error: renderError,
        });
      })
    );
  }
}

export default new ApiContentRenderAllStore();
