import {action} from 'mobx';

import ApiBaseStore from '../common/apiBaseStore';
import {STATE_PENDING, STATE_FULFILLED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';

/**
 * Re-renders the file if its processing has stalled.
 */
class ApiFileRerenderStore extends ApiBaseStore {
  /**
   * Starts the file rerender process.
   *
   * @param {number|string} fileId
   */
  @action makeRequest(fileId) {
    const safeFileId = String(fileId);

    this.state = STATE_PENDING;
    this.data = null;
    this.error = null;
    this[EXPIRE_TIME] = Date.now() + EXPIRES_PENDING;

    serverApi.fileRerender(safeFileId).then(
      action('fileRerenderSuccess', (wasSuccessful) => {
        this.state = STATE_FULFILLED;
        this.data = wasSuccessful;
        this.error = null;
        this[EXPIRE_TIME] = Date.now() + EXPIRES_IN;
      })
    ).catch(
      action('fileRerenderError', (folderCreateError) => {
        this.state = STATE_FULFILLED;
        this.data = null;
        this.error = folderCreateError;
      })
    );
  }
}

export default new ApiFileRerenderStore();
