import {action} from 'mobx';

import ApiAdminCompanySignGetAllStore from './apiAdminCompanySignGetAllStore';
import ApiBaseStore from '../common/apiBaseStore';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';

/**
 * Creates a new sign.
 */
class ApiAdminSignCreateStore extends ApiBaseStore {
  /**
   * Creates a new sign using the api.
   *
   * @param {{}} newSignData
   * @param {string} companyId
   */
  @action makeRequest(newSignData, companyId) {
    if (!companyId) {
      this.state = STATE_REJECTED;
      this.error = new Error('Invalid company id given to adminSignCreate.');
      return;
    }

    this.state = STATE_PENDING;
    this.data = null;
    this.error = null;
    this[EXPIRE_TIME] = Date.now() + EXPIRES_PENDING;

    const safeSignData = {...newSignData};

    serverApi.adminSignCreate(safeSignData).then(
      action('adminSignCreateSuccess', (newSignId) => {
        this.state = STATE_FULFILLED;
        this.data = newSignId;
        this.error = null;
        this[EXPIRE_TIME] = Date.now() + EXPIRES_IN;

        ApiAdminCompanySignGetAllStore.refresh(companyId, true);
      })
    ).catch(
      action('adminSignCreateError', (signCreateError) => {
        this.state = STATE_REJECTED;
        this.data = null;
        this.error = signCreateError;
      })
    );
  }
}

export default new ApiAdminSignCreateStore();
