import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Modal, ModalBody, ModalFooter} from 'reactstrap';

import './inputStringModal.scss';

/**
 * The InputStringModal component.
 */
class InputStringModal extends React.Component {
  /**
   * The input value.
   *
   * @type {string}
   */
  @observable inputValue = '';

  /**
   * The reference to the input element.
   *
   * @type {{current: ?HTMLElement}}
   */
  inputElement = React.createRef();

  /**
   * Initializes the modal.
   */
  @action initializeModal = () => {
    if (this.inputElement && this.inputElement.current) {
      this.inputElement.current.focus();
    }

    if (this.props.startingValue) {
      this.inputValue = String(this.props.startingValue);
    }
  };

  /**
   * Resets the modal.
   */
  @action resetModal = () => {
    this.inputValue = '';
  };

  /**
   * Triggered when the modal is opened.
   */
  onModalOpened = () => {
    this.initializeModal();
  };

  /**
   * Triggered when the modal is cancelled.
   */
  onCancelModal = () => {
    const {onComplete} = this.props;

    onComplete(null);

    this.resetModal();
  };

  /**
   * Triggered when the modal is completed.
   */
  onCompleteModal = () => {
    const {onComplete} = this.props;

    onComplete(this.inputValue);

    this.resetModal();
  };

  /**
   * Triggered when the input changes.
   *
   * @param {{}} changeEvent
   */
  @action onInputChange = (changeEvent) => {
    this.inputValue = changeEvent.target.value;
  };

  /**
   * Prevents the submission of the form and instead submits the modal.
   *
   * @param {{preventDefault: function}} submitEvent
   */
  onSubmit = (submitEvent) => {
    submitEvent.preventDefault();

    this.onCompleteModal();
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {isOpen, helpText, placeholder, title} = this.props;

    return (
      <Modal
        className="input-string-modal"
        isOpen={isOpen}
        toggle={this.onCancelModal}
        onOpened={this.onModalOpened}
        centered
      >
        <ModalBody>
          <form onSubmit={this.onSubmit}>
            <div className="form-group">
              <label htmlFor="newName" className="site-label">{title}:</label>
              <div className="site-text-input">
                <input
                  type="text"
                  id="newName"
                  ref={this.inputElement}
                  placeholder={placeholder || null}
                  defaultValue={this.inputValue}
                  onChange={this.onInputChange}
                />
              </div>
              {(helpText) && (
                <small className="form-text text-muted">
                  {helpText}
                </small>
              )}
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-default" onClick={this.onCancelModal}>Cancel</button>
          <button type="button" className="btn btn-primary" onClick={this.onCompleteModal}>Save</button>
        </ModalFooter>
      </Modal>
    );
  }
}

InputStringModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,

  helpText: PropTypes.string,
  placeholder: PropTypes.string,
  startingValue: PropTypes.string,
};

InputStringModal.defaultProps = {
  helpText: null,
  placeholder: null,
  startingValue: '',
};

export default observer(InputStringModal);
