import {action} from 'mobx';

import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import serverApi from '../../../utils/serverApi';
import ApiMapBaseStore from '../common/apiMapBaseStore';

/**
 * ApiBannerGetOneStore
 */
class ApiBannerGetOneStore extends ApiMapBaseStore {
  /**
   * Fetches banner information from the server.
   *
   * @param {string} bannerId
   */
  @action makeRequest(bannerId) {
    const safeBannerId = String(bannerId);

    this.dataMap.set(safeBannerId, {
      state: STATE_PENDING,
      [EXPIRE_TIME]: Date.now() + EXPIRES_PENDING,
      data: null,
      error: null,
    });

    serverApi.bannerGetOne(safeBannerId).then(
      action('bannerGetOneSuccess', (foundRequest) => {
        this.dataMap.set(safeBannerId, {
          state: STATE_FULFILLED,
          [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
          data: foundRequest,
          error: null,
        });
      }),
      action('bannerGetOneError', (bannerGetOneError) => {
        this.dataMap.set(safeBannerId, {
          state: STATE_REJECTED,
          [EXPIRE_TIME]: null,
          data: null,
          error: bannerGetOneError,
        });
      })
    );
  }
}

export default new ApiBannerGetOneStore();
