import lodash from 'lodash';
import {action, observable} from 'mobx';

import {parseSearchParams, replaceRouteParams} from '../../utils/routeHelper';

/**
 * The forward to route's query name.
 *
 * @type {string}
 */
const TO_QUERY_NAME = 'to';

/**
 * The router store.
 */
class RouterStore {
  /**
   * The current location object.
   *
   * @type {?{}}
   */
  @observable location = null;

  /**
   * The route params for the current location.
   *
   * @type {Map}
   */
  @observable params = new Map();

  /**
   * The match data (except params) from react router.
   *
   * @type {{path: string, isExact: boolean, url: string}}
   */
  @observable match = {
    isExact: false,
    path: '',
    url: '',
  };

  /**
   * The query params for the current location.
   *
   * @type {Map}
   */
  @observable query = new Map();

  /**
   * The history object.
   *
   * @type {?{}}
   */
  history = null;

  /**
   * The array of listeners.
   *
   * @type {Object<name, function>}
   */
  listeners = {};

  /**
   * Sets the location, this should only be called by the sync history code, never call this directly.
   *
   * @param {{}} newLocation
   * @private
   */
  @action _setLocation(newLocation) {
    this.location = newLocation;

    this.query.clear();
    this.query.merge(parseSearchParams(this.history));

    if (this.listeners && lodash.size(this.listeners)) {
      lodash.forEach(this.listeners, (listener) => {
        listener();
      });
    }
  }

  /**
   * Sets the route match information.
   *
   * @param {{params: {}}} newMatch
   * @private
   */
  @action _setMatch(newMatch) {
    if (!newMatch) {
      this.params.clear();
      this.match.isExact = false;
      this.match.path = '';
      this.match.url = '';
      return;
    }

    this.params.clear();
    this.params.merge(newMatch.params);

    this.match.isExact = newMatch.isExact;
    this.match.path = newMatch.path;
    this.match.url = newMatch.url;
  }

  /**
   * Gets the to route from the query.
   *
   * @returns {?string}
   * @private
   */
  _getToFromQuery = () => {
    return this.query.get(TO_QUERY_NAME) || null;
  };

  /**
   * Parses the to param into the route.
   *
   * @param {{pathname: string, search: string}} route
   * @param {string} newTo
   * @returns {{pathname: string, search: string}}
   * @private
   */
  _parseToIntoRoute = (route, newTo) => {
    const safeTo = String(newTo);
    const safeRoute = {...route};

    const toWithoutPreSlash = (safeTo[0] === '/') ? safeTo.substr(1) : safeTo;

    if (!safeRoute.search) {
      safeRoute.search = '?';
    } else {
      safeRoute.search += '&';
    }

    safeRoute.search += `to=${toWithoutPreSlash}`;

    return safeRoute;
  };

  /**
   * Parses route options into a new route object.
   * Used for the push() and replace() methods.
   *
   * @param {{pathname: string}} route The starting route.
   * @param {{}} [options]
   * @param {{}} [options.params] Optional params that will be replaced in the route string.
   * @param {string} [options.addTo] If set, the to query param will be set to this unless it is already set.
   * @param {string} [options.overrideTo] If set, the to query param will be replaced with this route string.
   * @param {boolean} [options.ignoreTo] If true, the to query will NOT be allowed to intercept if this is a push.
   * @param {boolean} [options.allowTo] If true, the to query will be allowed to intercept if this is a replace.
   * @param {string} [options.replaceIfTo] If true and a to query param exists, uses replace instead of push.
   * @param {boolean} [isReplace] Whether or not we are parsing for a replace function.
   * @returns {{route: {pathname: string}, forceRedirect: boolean}}
   * @private
   */
  _parseOptions = (route, options, isReplace) => {
    if (!options) {
      return {route};
    }

    let safeRoute = {...route};

    if (options.params) {
      safeRoute.pathname = replaceRouteParams(safeRoute.pathname, options.params);
    }

    let canUseTo = false;
    if (isReplace && options.allowTo) {
      canUseTo = true;
    } else if (!isReplace && !options.ignoreTo) {
      canUseTo = true;
    }

    const toRoute = this._getToFromQuery();
    let forceRedirect = false;

    if (options.overrideTo) {
      safeRoute = this._parseToIntoRoute(safeRoute, options.overrideTo);
    } else if (options.addTo && !toRoute) {
      safeRoute = this._parseToIntoRoute(safeRoute, options.addTo);
    } else if (canUseTo) {
      if (toRoute) {
        safeRoute = {pathname: toRoute};
        forceRedirect = Boolean(options.replaceIfTo);
      }
    } else if (!canUseTo) {
      // Keep the to route around if it exists.
      if (toRoute) {
        safeRoute = this._parseToIntoRoute(safeRoute, toRoute);
      }
    }

    return {
      route: safeRoute,
      forceRedirect
    };
  };

  /**
   * Validates the route object/string and returns a safe version of the route object.
   *
   * @param {string|{}} route
   * @returns {{pathname: string}}
   * @private
   */
  _validateRoute = (route) => {
    if (!route) {
      throw new Error('RouterStore: The route argument is required.');
    }

    let safeRoute = route;
    if (typeof route === 'string') {
      safeRoute = {pathname: route};
    }

    if (!safeRoute.pathname) {
      throw new Error('RouterStore: The route argument must be either a string or an object with a pathname string.');
    }
    safeRoute.pathname = String(safeRoute.pathname);

    if (safeRoute.pathname.indexOf('?') !== -1) {
      const queryMatch = safeRoute.pathname.match(/(.+)\?(([^=?&]+=[^=?&]+&?)+)/);
      if (queryMatch) {
        throw new Error('RouterStore: Query parameters must be defined using the search key in the route object.');
      }
    }

    return safeRoute;
  };

  /**
   * Pushes a new route to the route stack which forwards the user to that route.
   *
   * @param {string|{}} newRoute
   * @param {{}} [options]
   * @param {{}} [options.params] Optional params that will be replaced in the route string.
   * @param {boolean} [options.ignoreTo] If true, the to query will NOT be allowed to intercept this replace.
   * @param {string} [options.addTo] If set, the to query param will be set to this unless it is already set.
   * @param {string} [options.overrideTo] If set, the to query param will be replaced with this route string.
   * @param {string} [options.replaceIfTo] If true and a to query param exists, uses replace instead of push.
   */
  push = (newRoute, options) => {
    const safeRoute = this._validateRoute(newRoute);
    const {route, forceRedirect} = this._parseOptions(safeRoute, options);

    if (forceRedirect) {
      this.history.replace(route);
    } else {
      this.history.push(route);
    }
  };

  /**
   * Replaces the current route which forwards the user to that route.
   *
   * @param {string|{}} newRoute
   * @param {{}} [options]
   * @param {{}} [options.params] Optional params that will be replaced in the route string.
   * @param {boolean} [options.allowTo] If true, the to query will be allowed to intercept this replace.
   * @param {string} [options.addTo] If set, the to query param will be set to this unless it is already set.
   * @param {string} [options.overrideTo] If set, the to query param will be replaced with this route string.
   */
  replace = (newRoute, options) => {
    const safeRoute = this._validateRoute(newRoute);
    const {route} = this._parseOptions(safeRoute, options, true);

    this.history.replace(route);
  };

  /**
   * Jumps a number of steps in the route stack.
   *
   * @param {number} stepsToJump
   */
  go = (stepsToJump) => {
    this.history.go(stepsToJump);
  };

  /**
   * Goes back to the previous route.
   */
  goBack = () => {
    this.history.goBack();
  };

  /**
   * Goes forward to the next route (if available).
   */
  goForward = () => {
    this.history.goForward();
  };

  /**
   * Gets the value for a param.
   *
   * @param {string} paramName
   * @param {*=} defaultValue
   * @param {boolean=} defaultOnFalsey
   * @returns {string}
   */
  getParam = (paramName, defaultValue, defaultOnFalsey) => {
    if (!this.params.has(paramName)) {
      return defaultValue;
    }

    const value = this.params.get(paramName);
    if (defaultOnFalsey) {
      return value || defaultValue;
    }

    return value;
  };

  /**
   * Gets the value for a query argument.
   *
   * @param {string} queryName
   * @param {*} defaultValue
   * @param {boolean=} defaultOnFalsey
   * @returns {string}
   */
  getQuery = (queryName, defaultValue, defaultOnFalsey) => {
    if (!this.query.has(queryName)) {
      return defaultValue;
    }

    const value = this.query.get(queryName);
    if (defaultOnFalsey) {
      return value || defaultValue;
    }

    return value;
  };

  /**
   * Blocks navigation away from the page.
   *
   * @param {function} callback
   * @returns {function} Unblocks the transition.
   */
  blockRouting = (callback) => {
    if (!this.history || !this.history.block) {
      throw new Error('The history object must be defined before you can block routing.');
    }

    return this.history.block(callback);
  };

  /**
   * Whether or not the current route matches one or more of the given routes.
   *
   * @param {string|string[]} matchRoutes
   * @returns {boolean}
   */
  isSameAs = (matchRoutes) => {
    let safeOtherRoutes = matchRoutes;
    if (!Array.isArray(matchRoutes)) {
      safeOtherRoutes = [matchRoutes];
    }

    let currentRoute = this.location.pathname;

    return lodash.some(safeOtherRoutes, (otherRoute) => {
      if (otherRoute === '/' || currentRoute === '/') {
        return (currentRoute === otherRoute);
      }

      const hasParams = (otherRoute.indexOf(':') > -1);
      if (!hasParams) {
        return lodash.startsWith(currentRoute, otherRoute);
      }

      const toRegexp = otherRoute.replace(/\/:[^/?]+\?/ig, '/?[^/]*').replace(/\/:[^/?]+/ig, '/[^/]+');
      const otherRouteRegexp = new RegExp(toRegexp);

      return currentRoute.match(otherRouteRegexp);
    });
  };

  /**
   * Registers a new history listener.
   *
   * @param {string} listenerName
   * @param {function} listener
   */
  registerListener = (listenerName, listener) => {
    if (!listener || typeof listener !== 'function') {
      throw new Error('Invalid listener registered with routerStore. Listener must be a function.');
    }

    this.listeners[listenerName] = listener;
  };

  /**
   * Removes a listener by the listener's name.
   *
   * @param {string} listenerName
   */
  removeListener = (listenerName) => {
    if (!listenerName) {
      return;
    }

    if (this.listeners[listenerName] === undefined) {
      return;
    }

    delete this.listeners[listenerName];
  };
}

export default new RouterStore();
