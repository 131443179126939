import lodash from 'lodash';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import entityCropResizeHOC from '../../../hoc/entityCropResizeHoc';
import * as resizeCorner from '../../../../constants/resizeConstants';

/**
 * Gets the path for each position.
 *
 * @param {string} resizePosition
 * @param {number} safeX
 * @param {number} safeY
 * @param {number} safeSize
 * @param {number} outlineWidth
 * @returns {string}
 */
function getPathForPosition(resizePosition, safeX, safeY, safeSize, outlineWidth) {
  const halfSize = (safeSize / 2);

  if (resizePosition === resizeCorner.TOP_LEFT) {
    return [
      `M ${safeX},${safeY}`,
      `H ${safeX + safeSize}`,
      `V ${safeY + outlineWidth}`,
      `H ${safeX + outlineWidth}`,
      `V ${safeY + safeSize}`,
      `H ${safeX}`,
      'Z',
    ].join(' ');
  } else if (resizePosition === resizeCorner.TOP_RIGHT) {
    return [
      `M ${safeX},${safeY}`,
      `H ${safeX + outlineWidth}`,
      `V ${safeY + safeSize}`,
      `H ${safeX}`,
      `V ${safeY + outlineWidth}`,
      `H ${safeX + outlineWidth - safeSize}`,
      `V ${safeY}`,
      'Z',
    ].join(' ');
  } else if (resizePosition === resizeCorner.BOTTOM_LEFT) {
    return [
      `M ${safeX},${safeY}`,
      `V ${safeY - (safeSize - outlineWidth)}`,
      `H ${safeX + outlineWidth}`,
      `V ${safeY}`,
      `H ${safeX + safeSize}`,
      `V ${safeY + outlineWidth}`,
      `H ${safeX}`,
      'Z',
    ].join(' ');
  } else if (resizePosition === resizeCorner.BOTTOM_RIGHT) {
    return [
      `M ${safeX},${safeY}`,
      `V ${safeY - (safeSize - outlineWidth)}`,
      `H ${safeX + outlineWidth}`,
      `V ${safeY + outlineWidth}`,
      `H ${safeX - (safeSize - outlineWidth)}`,
      `V ${safeY}`,
      'Z',
    ].join(' ');
  } else if (resizePosition === resizeCorner.TOP_MIDDLE || resizePosition === resizeCorner.BOTTOM_MIDDLE) {
    return [
      `M ${safeX},${safeY}`,
      `H ${safeX + halfSize}`,
      `V ${safeY + outlineWidth}`,
      `H ${safeX - halfSize}`,
      `V ${safeY}`,
      'Z',
    ].join(' ');
  }

  // `resizePosition`s of MIDDLE_LEFT and MIDDLE_RIGHT.
  return [
    `M ${safeX},${safeY}`,
    `V ${safeY + halfSize}`,
    `H ${safeX + outlineWidth}`,
    `V ${safeY - halfSize}`,
    `H ${safeX}`,
    'Z',
  ].join(' ');
}

/**
 * The CropCorner component.
 */
class CropCorner extends React.Component {
  /**
   * Gets the cursor for the corner.
   * As the item rotates, updates the cursor to point in roughly the correct direction.
   *
   * @param {string} resizePosition
   * @returns {string}
   */
  getCursor(resizePosition) {
    const entity = this.props.entity;

    const currentPosition = entity.get('position');
    const rotate = (currentPosition && currentPosition.rotate) ? currentPosition.rotate : 0;

    const step = 22.5;
    const rotationOffset = Math.ceil(Math.floor(rotate / step) / 2);
    if (!rotationOffset) {
      return (resizeCorner.CURSORS[resizePosition] || null);
    }

    const rotatedCursors = resizeCorner.ROTATED_CURSORS;
    const startIndex = lodash.findIndex(rotatedCursors, {position: resizePosition});
    if (startIndex < 0) {
      return null;
    }

    const newIndex = (startIndex + rotationOffset) % rotatedCursors.length;

    return rotatedCursors[newIndex].cursor;
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {fillColor, outlineWidth, resizePosition, size, startX, startY, strokeColor} = this.props;

    const safeX = startX - 1;
    const safeY = startY - 1;
    const style = {
      cursor: this.getCursor(resizePosition),
    };

    const defaultSize = 10;
    const sizeMultiplier = 3;
    const safeSize = (size || defaultSize) * sizeMultiplier;

    const path = getPathForPosition(resizePosition, safeX, safeY, safeSize, outlineWidth);

    return (
      <path
        className="movable-corner"
        fill={fillColor}
        fillOpacity="1"
        stroke={strokeColor}
        strokeOpacity="1"
        strokeWidth="1"
        strokeLinecap="square"
        strokeLinejoin="miter"
        strokeMiterlimit="10"
        pointerEvents="visiblePainted"
        d={path}
        style={style}
      />
    );
  }
}

CropCorner.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  fillColor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  game: MobxPropTypes.observableObject.isRequired,
  resizePosition: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  startX: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  startY: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  strokeColor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

  outlineWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

CropCorner.defaultProps = {
  outlineWidth: 3,
};

export default entityCropResizeHOC(
  observer(CropCorner)
);
