import {action} from 'mobx';

import {EXPIRES_IN, EXPIRES_PENDING, EXPIRE_TIME} from '../../../constants/storeConstants';
import {STATE_FULFILLED, STATE_PENDING, STATE_REJECTED} from '../../../constants/asyncConstants';
import serverApi from '../../../utils/serverApi';
import ApiMapBaseStore from '../common/apiMapBaseStore';

/**
 * ApiAdminCompanySignGetAllStore
 */
class ApiAdminCompanySignGetAllStore extends ApiMapBaseStore {
  /**
   * Fetches all signs for the given company.
   *
   * @param {string} companyId
   */
  @action makeRequest(companyId) {
    this.dataMap.set(companyId, {
      state: STATE_PENDING,
      [EXPIRE_TIME]: Date.now() + EXPIRES_PENDING,
      data: null,
      error: null,
    });

    serverApi.adminSignGetAllForCompany(companyId).then(
      action('companySignGetAllSuccess', (foundSigns) => {
        this.dataMap.set(companyId, {
          state: STATE_FULFILLED,
          [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
          data: foundSigns,
          error: null,
        });
      }),
      action('companySignGetAllError', (signsGetAllError) => {
        this.dataMap.set(companyId, {
          state: STATE_REJECTED,
          [EXPIRE_TIME]: null,
          data: null,
          error: signsGetAllError,
        });
      })
    );
  }
}

export default new ApiAdminCompanySignGetAllStore();
