import {action} from 'mobx';

import apiContentGetOneStore from './apiContentGetOneStore';
import ApiMapBaseStore from '../common/apiMapBaseStore';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';

/**
 * ApiContentRenderStore
 */
class ApiContentRenderStore extends ApiMapBaseStore {
  /**
   * Gets the key for the contentId and userSignId.
   *
   * @param {number} contentId
   * @param {number} userSignId
   * @returns {string}
   */
  getKey(contentId, userSignId) {
    const safeContentId = String(contentId);
    const safeUserSignId = String(userSignId);

    return `${safeContentId}-${safeUserSignId}`;
  }

  /**
   * Ensures fresh content render in the store.
   *
   * @param {number} contentId
   * @param {number} userSignId
   * @param {boolean} force Whether or not to force the refresh
   */
  refresh(contentId, userSignId, force) {
    const safeKey = this.getKey(contentId, userSignId);

    if (force || !this.isItemAvailable(safeKey)) {
      this.clear(safeKey);

      this.makeRequest(contentId, userSignId);
    }
  }

  /**
   * Tells the server to render the content.
   *
   * @param {number} contentId
   * @param {string} fileType
   * @param {number} userSignId
   */
  @action makeRequest(contentId, fileType, userSignId) {
    const safeKey = this.getKey(contentId, userSignId);

    this.dataMap.set(safeKey, {
      state: STATE_PENDING,
      [EXPIRE_TIME]: Date.now() + EXPIRES_PENDING,
      data: null,
      error: null,
    });

    serverApi.contentRender(
      contentId,
      fileType,
      userSignId
    ).then(
      action('contentRenderSuccess', () => {
        this.dataMap.set(safeKey, {
          state: STATE_FULFILLED,
          [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
          data: true,
          error: null,
        });

        apiContentGetOneStore.refresh(contentId, true);
      }),
      action('contentRenderError', (renderError) => {
        this.dataMap.set(safeKey, {
          state: STATE_REJECTED,
          [EXPIRE_TIME]: null,
          data: null,
          error: renderError,
        });
      })
    );
  }
}

export default new ApiContentRenderStore();
