import interact from 'interactjs/src/index';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {findDOMNode} from 'react-dom';

import {actionInteractionComponent} from '../../display/components/action/actionInteractionComponent';

/**
 * Moves the entity.
 *
 * @param {ObservableMap} entity
 * @param {GameStore} game
 * @param {boolean=} holdingShift If true, multiple can be selected.
 * @param {boolean=} onlyActivate If true, the entity can't be deselected.
 */
function activateEntity(entity, game, holdingShift, onlyActivate) {
  const interaction = entity.get('interaction');
  const isActive = Boolean(interaction && interaction.isActive);

  const actionParams = {
    entityId: entity.get('id'),
    skipHistory: true,
  };

  /**
   * Activates the entity.
   */
  function activate() {
    game.addAction(actionParams, actionInteractionComponent(true, holdingShift, false));
  }

  /**
   * Deactivates the entity.
   */
  function deactivate() {
    game.addAction(actionParams, actionInteractionComponent(false, holdingShift, false));
  }

  if (onlyActivate) {
    activate();
  } else if (isActive) {
    deactivate();
  } else {
    activate();
  }
}

/**
 * A higher order component wrapper that handles making an entity active.
 *
 * @param {Object} WrappedComponent
 * @returns {Object}
 */
export default function entityActivateHocWrapper(WrappedComponent) {
  /**
   * The EntityActivateHoc higher order component.
   */
  class EntityActivateHoc extends React.Component {
    /**
     * The DOM element for the element.
     *
     * @type {HTMLElement}
     */
    @observable domEl = null;

    /**
     * The DOM element for the display element.
     *
     * @type {HTMLElement}
     */
    @observable displayEl = null;

    /**
     * The interactJS object initialized on the DOM element.
     *
     * @type {{draggable: function, resizable: function}}
     */
    @observable interaction = null;

    /**
     * Triggered right after the component mounts to the page.
     */
    componentDidMount() {
      // this.initInteraction();
    }

    /**
     * Triggered when the component is about to unmount.
     */
    componentWillUnmount() {
      // this.stopInteraction();
    }

    /**
     * Triggered right after the wrapped component is added to OR removed from the page.
     *
     * @param {{}} domEl
     */
    @action onChangeMount = (domEl) => {
      if (this.domEl) {
        return;
      }

      this.domEl = findDOMNode(domEl);
      this.displayEl = document.getElementById('display-source');
    };

    /**
     * Handles the double click event.
     *
     * @param {{}} clickEvent
     */
    onClick = (clickEvent) => {
      if (!this.props.entity) {
        // We don't want to activate cropping if multiple items are selected, so ignore `this.props.entities`.
        return;
      }

      const {entity, game} = this.props;

      const currentInteraction = entity.get('interaction');
      if (currentInteraction && currentInteraction.isActive) {
        return;
      }

      const holdingShift = Boolean(clickEvent && clickEvent.shiftKey);
      activateEntity(entity, game, holdingShift);
    };

    /**
     * Starts the interactJS code.
     */
    @action initInteraction = () => {
      if (!this.domEl) {
        return;
      }

      if (this.interaction) {
        // Make sure we don't have multiple dragging interactions on this element.
        this.interaction.unset();
      }

      const interaction = interact(this.domEl);
      interaction.on('tap', this.onClick);

      this.interaction = interaction;
    };

    /**
     * Unbinds the dragging code.
     */
    @action stopInteraction = () => {
      if (!this.interaction) {
        return;
      }

      this.interaction.unset();
      this.interaction = null;
    };

    /**
     * Renders the WrappedComponent.
     *
     * @returns {Object}
     */
    render() {
      if (!WrappedComponent) {
        return null;
      }

      if (this.props.ref) {
        throw new Error('EntityActivateHoc will override ref property given to the wrapped component.');
      }

      return (
        <WrappedComponent
          {...this.props}
          ref={this.onChangeMount}
        />
      );
    }
  }

  EntityActivateHoc.propTypes = {
    game: PropTypes.shape({
      setEntityComponents: PropTypes.func,
    }).isRequired,

    entities: PropTypes.arrayOf(MobxPropTypes.observableMap),
    entity: MobxPropTypes.observableMap,
    ref: PropTypes.func,
  };

  return observer(EntityActivateHoc);
}
