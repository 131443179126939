/**
 * The length to truncate content titles.
 * @const {number}
 */
export const CONTENT_TITLE_LENGTH = 28;

/**
 * The length to truncate category titles.
 * @const {number}
 */
export const CATEGORY_TITLE_LENGTH = 28;

/**
 * The long date format used in the smart table date columns.
 * @const {string}
 */
export const MOMENT_LONG_DATE_FORMAT = 'MMM Do YYYY, h:mm:ss a';

