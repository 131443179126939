import lodash from 'lodash';
import {action, observable, runInAction, toJS} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import ErrorBoundary from '../../../common/errorBoundary/ErrorBoundary';
import TransitionEdit from './components/transitionEdit/TransitionEdit';
import TransitionList from './components/transitionList/TransitionList';
import {actionUpdateComponent} from '../../../../display/components/action/actionUpdateComponent';
import {
  getSingleTransitionFromSource,
  transitionComponent
} from '../../../../display/components/common/transitionComponent';
import {visibleComponent} from '../../../../display/components/common/visibleComponent';
import TransitionLibraryModal from '../../../modals/selectTransition/TransitionLibraryModal';
import UnsavedModal from '../../../modals/unsaved/UnsavedModal';

import './TransitionControlSection.scss';

/**
 * The new transition "blank" template.
 *
 * @const {{}}
 */
const BLANK_TRANSITION = {
  example: {
    easing: 'linear',
    values: [0, 1],
    time: {
      start: 'start.0',
      end: 'start.1000',
    },
  },
};

/**
 * The TransitionControlSection component.
 */
class TransitionControlSection extends React.Component {
  /**
   * The transition id that is currently active.
   *
   * @type {?string}
   */
  @observable activeTransitionId = null;

  /**
   * Determines whether the transition library modal is open
   *
   * @type {boolean}
   */
  @observable isTransitionLibraryModalOpen = false;

  /**
   * Determines whether the unsaved modal open
   *
   * @type {boolean}
   */
  @observable isUnsavedModalOpen = false;

  /**
   * The transition id that was prevented from becoming active
   * because of unsaved changes.
   *
   * @type {?string}
   */
  blockedTransitionId = null;

  /**
   * Whether a close action was blocked
   *
   * @type {boolean}
   */
  blockedCancelModal = false;

  /**
   * Ref to the TransitionEdit child component
   *
   * @type {{current: ?TransitionEdit}}
   */
  transitionEditRef = React.createRef();

  /**
   * Activates a single transition.
   *
   * @param {{}} transition
   * @param {boolean} shouldActivate
   */
  @action onActivateTransition = (transition, shouldActivate) => {
    if (this.transitionEditRef.current.hasChanges) {
      this.blockedTransitionId = transition.id;
      this.isUnsavedModalOpen = true;
    } else if (shouldActivate) {
      this.activeTransitionId = transition.id;
    } else {
      this.activeTransitionId = null;
    }
  };

  /**
   * Creates a new transition.
   *
   * @param {{}} newTransition
   * @returns {{}}
   */
  onCreateTransition = (newTransition) => {
    if (!newTransition) {
      return this.onTransitionLibraryModalOpen();
    }

    const {entity} = this.props;
    const entityTransitions = toJS(entity.get('transition') || []);

    let transitionToAdd;
    if (newTransition) {
      transitionToAdd = getSingleTransitionFromSource({
        name: newTransition.humanName,
        details: {
          ...newTransition.details
        }
      });
    } else {
      transitionToAdd = getSingleTransitionFromSource({
        name: 'New Transition',
        details: BLANK_TRANSITION,
      });
    }

    entityTransitions.push(transitionToAdd);
    this.onActivateTransition(transitionToAdd, true);
    return this.updateTransitions(entityTransitions);
  };

  /**
   * Delete an existing transition.
   *
   * @param {{}} transition
   */
  onDeleteTransition = (transition) => {
    const {entity} = this.props;

    const transitionId = transition.id;

    const entityTransitions = toJS(entity.get('transition') || []);
    const newTransitions = lodash.filter(entityTransitions, (entityTransition) => {
      return (entityTransition.id !== transitionId);
    });

    if (this.activeTransitionId === transitionId) {
      runInAction('transitionControlSectionOnDeleteTransition', () => {
        this.activeTransitionId = null;
      });
    }

    this.updateTransitions(newTransitions);
  };

  /**
   * Renames an existing transition.
   *
   * @param {{}} transition
   * @param {string} newName
   */
  onRenameTransition = (transition, newName) => {
    const {entity} = this.props;

    const entityTransitions = toJS(entity.get('transition') || []);
    lodash.forEach(entityTransitions, (entityTransition) => {
      if (entityTransition.id === transition.id) {
        entityTransition.name = newName;
        return false;
      }
      return true;
    });

    this.updateTransitions(entityTransitions);
  };

  /**
   * Saves an existing transition.
   *
   * @param {{}} transition
   * @param {{}} newDetails
   * @param {string=} presetName
   */
  onSaveTransition = (transition, newDetails, presetName) => {
    const {entity} = this.props;

    const entityTransitions = toJS(entity.get('transition') || []);
    lodash.forEach(entityTransitions, (entityTransition) => {
      if (entityTransition.id === transition.id) {
        entityTransition.details = newDetails;
        entityTransition.initialized = false;
        if (presetName) {
          entityTransition.name = presetName;
        }
        return false;
      }
      return true;
    });

    this.updateTransitions(entityTransitions);
    this.transitionEditRef.current.hasChanges = false;

    if (this.isUnsavedModalOpen) {
      this.onUnsavedModalDiscard();
    } else {
      this.blockedTransitionId = null;
    }
  };

  /**
   * When the unsaved modal "Discard" button is clicked
   */
  @action onUnsavedModalDiscard = () => {
    // const {onComplete} = this.props;

    this.isUnsavedModalOpen = false;
    if (this.blockedTransitionId) {
      if (this.activeTransitionId === this.blockedTransitionId) {
        this.activeTransitionId = null;
      } else {
        this.activeTransitionId = this.blockedTransitionId;
      }
      this.blockedTransitionId = null;
    } else if (this.blockedCancelModal) {
      this.blockedCancelModal = false;
    }
  };

  /**
   * When the transition library modal is opened
   */
  @action onTransitionLibraryModalOpen = () => {
    this.isTransitionLibraryModalOpen = true;
  };

  /**
   * When the transition library modal "Cancel" button is clicked
   */
  @action onTransitionLibraryModalCancel = () => {
    this.isTransitionLibraryModalOpen = false;
  };

  /**
   * When the unsaved modal "Cancel" button is clicked
   */
  @action onUnsavedModalCancel = () => {
    this.isUnsavedModalOpen = false;
  };

  /**
   * Updates the transitions in the game.
   *
   * @param {Array.<{}>} newTransitions
   */
  updateTransitions = (newTransitions) => {
    const {entity, game} = this.props;

    const actionParams = {
      entityId: entity.get('id'),
    };

    const visible = entity.get('visible');

    game.addAction(actionParams, actionUpdateComponent({
      ...transitionComponent(newTransitions),
      ...visibleComponent(visible.isVisible, 1),
    }));
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      entity,
    } = this.props;

    const transitions = toJS(entity.get('transition') || []);

    let activeTransition = null;
    if (this.activeTransitionId) {
      activeTransition = lodash.find(transitions, (transition) => {
        return (transition.id === this.activeTransitionId);
      });
    }

    const editRef = this.transitionEditRef.current || {};

    return (
      <div>
        <ErrorBoundary message="An error occurred and the transitions could not be loaded.">
          <div className="transition-edit-content">
            <TransitionList
              activeId={this.activeTransitionId}
              entity={entity}
              onActivate={this.onActivateTransition}
              onCreate={this.onCreateTransition}
              onDelete={this.onDeleteTransition}
              onRename={this.onRenameTransition}
              transitions={transitions}
            />

            <TransitionLibraryModal
              isOpen={this.isTransitionLibraryModalOpen}
              entity={entity}
              onCancelModal={this.onTransitionLibraryModalCancel}
              onComplete={this.onCreateTransition}
            />
          </div>

          <div className="transition-edit-content">
            <TransitionEdit
              activeTransition={activeTransition}
              blankTemplate={BLANK_TRANSITION}
              entity={entity}
              isUnsavedModalOpen={this.isUnsavedModalOpen}
              onSave={this.onSaveTransition}
              ref={this.transitionEditRef}
            />
          </div>

          <div className="transition-edit-content">
            <UnsavedModal
              hasError={Boolean(editRef.hasError)}
              isOpen={this.isUnsavedModalOpen}
              onCancel={this.onUnsavedModalCancel}
              onDiscard={this.onUnsavedModalDiscard}
              onSave={editRef.onSaveClick}
            />
          </div>
        </ErrorBoundary>
      </div>
    );
  }
}

TransitionControlSection.propTypes = {
  entity: PropTypes.object.isRequired,
  game: PropTypes.object.isRequired
};

export default observer(TransitionControlSection);
