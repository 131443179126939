/**
 * The action update variable component.
 *
 * @param {Object<string, {}>} entityUpdates
 * @returns {{actionUpdate: {entityUpdates: Object<string, {}>}}}
 */
export function actionUpdateVariableComponent(entityUpdates) {
  return {
    actionUpdateVariable: {
      entityUpdates,
    },
  };
}
