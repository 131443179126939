import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleXmark} from '@fortawesome/free-regular-svg-icons';

import './smartTableSearch.scss';

/**
 * The table search component.
 *
 * @constructor
 */
export class SmartTableSearch extends React.Component {
  /**
   * The search phrase.
   *
   * @type {string}
   */
  @observable search = '';

  /**
   * Sets the search phrase.
   *
   * @param {string} newSearch
   */
  @action setSearch(newSearch) {
    this.search = String(newSearch || '');
  }

  /**
   * Updates the search word(s) on input change.
   *
   * @param {{target: {value: string}}} changeEvent
   */
  onInputChange = (changeEvent) => {
    this.setSearch(changeEvent.target.value);
  };

  /**
   * Submits the search on form submit.
   *
   * @param {{preventDefault: function}} submitEvent
   */
  onSearchSubmitted = (submitEvent) => {
    submitEvent.preventDefault();

    const {onClear, onSearch} = this.props;

    if (!this.search && onClear) {
      onClear();
    } else if (onSearch) {
      onSearch(this.search.toLowerCase());
    }
  };

  /**
   * Cancels the search.
   */
  onCancelSearch = () => {
    this.setSearch('');

    const {onClear, onSearch} = this.props;

    if (onClear) {
      onClear();
    } else if (onSearch) {
      onSearch('');
    }
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {isDisabled} = this.props;

    return (
      <form className="smart-table-search form-inline" onSubmit={this.onSearchSubmitted}>
        <div className="form-group">
          <label className="sr-only" htmlFor="smart-table-search-input">Search</label>
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              id="smart-table-search-input"
              placeholder="Search"
              value={this.search}
              onChange={this.onInputChange}
              disabled={Boolean(isDisabled)}
            />

            {(this.search) && (
              <a className="cancel-search" onClick={this.onCancelSearch}>
                <FontAwesomeIcon icon={faCircleXmark} />
              </a>
            )}

            <span className="input-group-append">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={Boolean(isDisabled)}
              >Search</button>
            </span>
          </div>
        </div>
      </form>
    );
  }
}

SmartTableSearch.propTypes = {
  onSearch: PropTypes.func.isRequired,

  isDisabled: PropTypes.bool,
  onClear: PropTypes.func,
};

SmartTableSearch.defaultProps = {
  isDisabled: false,
};

export default observer(SmartTableSearch);
