/**
 * Indicates the script errored or otherwise failed to load.
 * @const {string}
 */
const LOAD_SCRIPT_ERROR = 'error';

/**
 * Indicates the script is currently loading.
 * @const {string}
 */
const LOAD_SCRIPT_PENDING = 'pending';

/**
 * Indicates the script successfully loaded.
 * @const {string}
 */
const LOAD_SCRIPT_SUCCESS = 'success';

/**
 * The loading state for each script.
 *
 * @type {Object<string, string>}
 */
const scriptLoadStates = {};

/**
 * Loads the script using the given source.
 *
 * @param {string} source The script source url.
 * @param {boolean} force Whether or not to force a previously errored script to load again.
 * @returns {Promise<boolean>}
 */
export function loadScriptFromSource(source, force) {
  const currentState = scriptLoadStates[source];
  if (currentState || (force && currentState === LOAD_SCRIPT_ERROR)) {
    return Promise.resolve(true);
  }

  scriptLoadStates[source] = LOAD_SCRIPT_PENDING;

  return createScriptInBody(source).then(
    () => {
      scriptLoadStates[source] = LOAD_SCRIPT_SUCCESS;
      return true;
    },
    () => {
      scriptLoadStates[source] = LOAD_SCRIPT_ERROR;
      return false;
    }
  );
}

/**
 * Creates the script tag in the body to load the script.
 *
 * @param {string} source
 * @returns {Promise}
 */
function createScriptInBody(source) {
  return new Promise((resolve, reject) => {
    let wasResolved = false;

    const onLoad = () => {
      wasResolved = true;
      resolve();
    };
    const onError = () => {
      wasResolved = true;
      reject();
    };

    const body = document.getElementsByTagName('body')[0];
    const tag = document.createElement('script');
    tag.type = 'text/javascript';
    tag.async = false;

    tag.onreadystatechange = () => {
      if (wasResolved) {
        return;
      }

      if (tag.readyState === 'completed') {
        onLoad();
      } else if (tag.readyState === 'error') {
        onError();
      }
    };

    tag.addEventListener('load', onLoad);
    tag.addEventListener('error', onError);
    tag.src = source;

    body.appendChild(tag);
  });
}
