import lodash from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import {action} from 'mobx';
import classNames from 'classnames';

import FolderAddButton from '../folderAddButton/FolderAddButton';
import inject from '../../../../hoc/injectHoc';
import CustomIcon from '../../../../common/customIcon/CustomIcon';
import LoadingIcon from '../../../../common/loadingIcon/LoadingIcon';

import './folderSidebarList.scss';

/**
 * The folder navigation component.
 */
class FolderSidebarList extends React.Component {
  /**
   * Loads user based on session
   */
  @action componentDidMount() {
    const {autoLoad, apiContentFolderGetAllStore} = this.props;

    apiContentFolderGetAllStore.refresh();

    if (autoLoad) {
      this.autoSelectFolder();
    }
  }

  /**
   * Triggered when the props change.
   *
   * @param {{}} prevProps
   */
  componentDidUpdate(prevProps) {
    if (this.props.autoLoad && prevProps.autoLoad !== this.props.autoLoad) {
      this.autoSelectFolder();
    }
  }

  /**
   * Auto selects a folder.
   */
  autoSelectFolder = () => {
    const {autoLoad, onSelect, apiContentFolderGetAllStore} = this.props;
    if (!autoLoad) {
      return;
    }

    apiContentFolderGetAllStore.getPromise().then((folders) => {
      if (!folders || !folders.length) {
        return;
      }

      if (!lodash.isNumber(autoLoad)) {
        onSelect(folders[0]);
        return;
      }

      const foundFolder = lodash.find(folders, {id: autoLoad});
      if (foundFolder) {
        onSelect(foundFolder);
      }
    });
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      hideIfEmpty,
      onSelect,
      selected,
      title,
      user,
      /* @type {ApiContentFolderGetAllStore} */ apiContentFolderGetAllStore
    } = this.props;

    const isEmpty = (apiContentFolderGetAllStore.isEmpty !== false);
    const canDesign = Boolean(user && user.canDesign);

    if (hideIfEmpty && isEmpty) {
      return null;
    }

    return (
      <div className="folder-sidebar-list sidebar-list">
        <div className="sidebar-list-header d-flex align-items-center justify-content-between">
          <span>{title}</span>

          {apiContentFolderGetAllStore.case({
            pending: () => <LoadingIcon size="em" />
          })}

          {(!isEmpty || canDesign) && (
            <FolderAddButton />
          )}
        </div>

        {apiContentFolderGetAllStore.case({
          fulfilled: (contentFolders) => {
            if (!contentFolders || !contentFolders.length) {
              return (<span className="help-text">Use Presto to create your first content.</span>);
            }

            return contentFolders.map((item) => {
              const isActive = String(item.id) === String(selected);

              return (<div
                key={item.id}
                className={classNames(
                  'sidebar-list-item d-flex align-items-center',
                  {active: isActive}
                )}
                onClick={() => onSelect(item)}
              >
                <CustomIcon
                  type={isActive ? 'folder-open' : 'folder'}
                  className="mr-2 sidebar-list-item-prepend"
                />
                <div>
                  {item.name}
                </div>
                <div className="sidebar-list-item-count ml-auto">
                  {item.contentCount}
                </div>
              </div>);
            });
          }
        })}
      </div>
    );
  }
}

FolderSidebarList.propTypes = {
  onSelect: PropTypes.func.isRequired,

  apiContentFolderGetAllStore: MobxPropTypes.observableObject,
  autoLoad: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  hideIfEmpty: PropTypes.bool,
  selected: PropTypes.number,
  title: PropTypes.string,
  user: PropTypes.object,
};

FolderSidebarList.defaultProps = {
  hideIfEmpty: false,
  title: 'My Folders',
};

FolderSidebarList.wrappedComponent = {};
FolderSidebarList.wrappedComponent.propTypes = {
  apiContentFolderGetAllStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(FolderSidebarList)(
  observer(FolderSidebarList)
);
