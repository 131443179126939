/* eslint-disable no-magic-numbers */

import lodash from 'lodash';

import {EASINGS} from '../../ecs/easingHelper';

/**
 * The name that signals this preset.
 * @const {string}
 */
export const PRESET_FLY_IN_TOWARD = 'flyInToward';

/**
 * The preset details.
 *
 * @type {{
 *   name: string,
 *   flow: string,
 *   details: Object.<string, {
 *     easing: string,
 *     parse: boolean,
 *     values: *|Array.<*>,
 *     time: {start: (string|number), end: (string|number)}
 *   }>,
 *   template: {}
 * }}
 */
export const flyInTowardPresetDetails = {
  // The name of the preset.
  name: PRESET_FLY_IN_TOWARD,

  // A human friendly name for the preset.
  humanName: 'Fly In Toward Screen',

  // The element names that this preset can be used for or true for all elements.
  elements: true,

  // The preset direction: in, out, middle. This is used in the UI logic.
  flow: 'in',

  // Each property that will change during the transition.
  details: {
    'transform.translate3d.x': {
      // The name of the easing this property will use.
      easing: EASINGS.easeInQuad,

      // The start and end times for the transition of this property.
      time: {
        start: 'start.0',
        end: 'start.2000',
      },

      // The value of the property that will change during the transition.
      values: [100, 0],
    },
    'transform.translate3d.y': {
      // The name of the easing this property will use.
      easing: EASINGS.easeInQuad,

      // The start and end times for the transition of this property.
      time: {
        start: 'start.0',
        end: 'start.2000',
      },

      // The value of the property that will change during the transition.
      values: [200, 0],
    },
    'transform.translate3d.z': {
      // The name of the easing this property will use.
      easing: EASINGS.easeInQuad,

      // The start and end times for the transition of this property.
      time: {
        start: 'start.0',
        end: 'start.2000',
      },

      // The value of the property that will change during the transition.
      values: [-100, 0],
    },
    'transform.perspective': {
      // The name of the easing this property will use.
      easing: EASINGS.linear,

      // The start and end times for the transition of this property.
      time: {
        start: 'start.0',
        end: 'start.2000',
      },

      // The value of the property that will change during the transition.
      values: [15, 15],
    },
  },

  // How this preset can be used in the source (including optional parameters).
  // This will be turned into a JSON string, so no functions can be used.
  template: {
    preset: PRESET_FLY_IN_TOWARD,
    options: {},
  },
};

flyInTowardPresetDetails.template.options = lodash.cloneDeep(flyInTowardPresetDetails.details);

// This defines the key in the presets object.
export const presetName = PRESET_FLY_IN_TOWARD;

// This defines the value of the presets object.
export default flyInTowardPresetDetails;
