import {toJS} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import MovableCorner from '../common/MovableCorner';
import MovablePath, {StationaryPath} from '../common/MovablePath';
import RotationIndicator from '../common/RotationIndicator';
import TransitionIndicator from '../common/TransitionIndicator';
import * as resizeCorner from '../../../constants/resizeConstants';
import {getIsEntityTransitioning, getIsRotationLocked, getIsSizeLocked} from '../../../utils/dragDropHelper';
import {getPixelFromPercentage, getPositionLeftAndTop} from '../../../display/ecs/entityHelper';

import './displayActive.scss';

/**
 * Active entities need have the highest (except for crop) zIndex in the display.
 * So this needs to be a very high number and higher than any other zIndex (except for the crop layer).
 *
 * @const {number}
 */
const ACTIVE_ENTITY_ZINDEX = 1000001;

/**
 * The size of the interaction control.
 * @const {number}
 */
const CONTROL_SIZE = 15;

/**
 * The fill color for the interaction control.
 * @const {string}
 */
const CONTROL_COLOR = '#0096fd';

/**
 * The fill outline color for the interaction control.
 * @const {string}
 */
const CONTROL_ALT_COLOR = '#ffffff';

/**
 * Multiplier for how much larger the svg viewBox will be than the display.
 * @const {number}
 */
const VIEW_BOX_MODIFIER = 3;

/**
 * The DisplayActive component.
 */
class DisplayActive extends React.Component {
  /**
   * Gets the styles from the entity.
   *
   * @param {{}} entity
   * @param {{}} game
   * @returns {{outlineStyle: {}, rotationAttributes: {}, rotationStyle: {}, svgStyle: {}}}
   */
  getStylesFromEntity = (entity, game) => {
    const safeEntity = toJS(entity);

    const currentPosition = safeEntity.position;
    const currentSize = safeEntity.size;
    const element = safeEntity.element;

    const overflowModifier = 2;
    const overflowHeight = game.resolution.height * overflowModifier;
    const overflowWidth = game.resolution.width * overflowModifier;

    const svgStyle = {
      position: 'absolute',
      zIndex: ACTIVE_ENTITY_ZINDEX,
      top: `${0 - overflowHeight}px`,
      left: `${0 - overflowWidth}px`,
      height: overflowHeight * VIEW_BOX_MODIFIER,
      width: overflowWidth * VIEW_BOX_MODIFIER,
    };

    const positionStyle = getPositionLeftAndTop(currentPosition.alignment, currentPosition, currentSize, game);

    const outlineStyle = {
      top: (positionStyle.top || 0) + overflowHeight,
      left: (positionStyle.left || 0) + overflowWidth,
      height: (safeEntity.size) ? safeEntity.size.height : null,
      width: (safeEntity.size) ? safeEntity.size.width : null,
    };

    if (safeEntity[element]) {
      const type = safeEntity[element];
      const typeStyle = getPositionLeftAndTop(currentPosition.alignment, type, type, game);

      const positionKeys = ['top', 'left'];
      positionKeys.forEach((key) => {
        if (typeStyle[key] !== undefined) {
          outlineStyle[key] = typeStyle[key];
        }
      });

      const sizeKeys = ['height', 'width'];
      sizeKeys.forEach((key) => {
        if (type[key] !== undefined) {
          outlineStyle[key] = type[key];
        }
      });
    }

    if (safeEntity.crop && safeEntity[element]) {
      const currentType = safeEntity[element];
      const currentCrop = safeEntity.crop;

      const typeStyle = getPositionLeftAndTop(currentPosition.alignment, currentType, currentType, game);

      const cropPixels = {
        y: typeStyle.top + getPixelFromPercentage(currentType.height, currentCrop.y),
        x: typeStyle.left + getPixelFromPercentage(currentType.width, currentCrop.x),
        height: getPixelFromPercentage(currentType.height, currentCrop.height),
        width: getPixelFromPercentage(currentType.width, currentCrop.width),
      };

      const typeXEnd = typeStyle.left + currentType.width;
      const typeYEnd = typeStyle.top + currentType.height;
      const cropXEnd = cropPixels.x + cropPixels.width;
      const cropYEnd = cropPixels.y + cropPixels.height;

      outlineStyle.left = Math.max(typeStyle.left, cropPixels.x) + overflowWidth;
      outlineStyle.top = Math.max(typeStyle.top, cropPixels.y) + overflowHeight;

      outlineStyle.width = Math.min(typeXEnd, cropXEnd) - outlineStyle.left + overflowWidth;
      outlineStyle.height = Math.min(typeYEnd, cropYEnd) - outlineStyle.top + overflowHeight;
    }

    const centerX = outlineStyle.left + (outlineStyle.width / 2);
    const centerY = outlineStyle.top + (outlineStyle.height / 2);

    const rotateDegrees = currentPosition.rotate || 0;
    const rotationStyle = {
      transform: `rotate(${rotateDegrees}deg)`,
      WebkitTransform: `rotate(${rotateDegrees}deg)`,
      transformOrigin: `${centerX}px ${centerY}px 0px`,
    };

    // Adding transform attribute as well as css because the css will not work in IE11...
    const rotationAttributes = {
      transform: `rotate(${rotateDegrees}, ${centerX}, ${centerY})`,
    };

    return {
      outlineStyle,
      rotationAttributes,
      rotationStyle,
      svgStyle,
    };
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      /** @type {ObservableMap} */ entity,
      /** @type {GameStore} */ game,
    } = this.props;

    if (!entity.has('visible') || !entity.get('visible').isVisible) {
      return null;
    } else if (entity.get('element') === 'line') {
      return null;
    }

    const entityIsTransitioning = getIsEntityTransitioning(
      entity,
      game.timer.elapsedTime,
      ['position']
    );
    const rotationIsLocked = getIsRotationLocked(entity) || entityIsTransitioning;
    const sizeIsLocked = getIsSizeLocked(entity) || entityIsTransitioning;
    const positionIsLocked = entityIsTransitioning;

    const {outlineStyle, rotationAttributes, rotationStyle, svgStyle} = this.getStylesFromEntity(entity, game);

    const outlineWidth = 4;
    const outlinePath = [
      `M ${outlineStyle.left},${outlineStyle.top}`,
      `H ${outlineStyle.left + outlineStyle.width}`,
      `V ${outlineStyle.top + outlineStyle.height}`,
      `H ${outlineStyle.left}`,
      'Z',
    ];

    const outlineStartX = outlineStyle.left;
    const outlineStartY = outlineStyle.top;
    const outlineMiddleX = outlineStyle.left + (outlineStyle.width / 2);
    const outlineMiddleY = outlineStyle.top + (outlineStyle.height / 2);
    const outlineFarX = outlineStyle.left + outlineStyle.width;
    const outlineFarY = outlineStyle.top + outlineStyle.height;
    const controlOffset = 0 - (CONTROL_SIZE / 2);

    /**
     * Gets an interaction control.
     *
     * @param {number} x
     * @param {number} y
     * @param {number} offset
     * @param {string} resizePosition
     * @returns {object}
     */
    function getControl(x, y, offset, resizePosition) {
      return (
        <MovableCorner
          entity={entity}
          fillColor={CONTROL_COLOR}
          game={game}
          isRotated={true}
          resizePosition={resizePosition}
          size={CONTROL_SIZE}
          startX={x + offset}
          startY={y + offset}
          strokeColor={CONTROL_ALT_COLOR}
          strokeWidth={VIEW_BOX_MODIFIER}
        />
      );
    }

    const Path = (positionIsLocked) ? StationaryPath : MovablePath;

    return (
      <svg
        id="display-active"
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        fill="none"
        stroke="none"
        strokeLinecap="square"
        strokeMiterlimit="10"
        overflow="hidden"
        preserveAspectRatio="none"
        pointerEvents="none"
        viewBox={`0 0 ${svgStyle.width} ${svgStyle.height}`}
        style={svgStyle}
      >
        <g
          className="rotation-group"
          pointerEvents="visiblePainted"
          style={rotationStyle}
          {...rotationAttributes}
        >
          {(!rotationIsLocked) && (
            <RotationIndicator
              entity={entity}
              game={game}
              fillColor={CONTROL_COLOR}
              strokeColor={CONTROL_ALT_COLOR}
              offsetX={outlineMiddleX}
              offsetY={outlineStartY}
              controlOffset={controlOffset}
            />
          )}

          {(entityIsTransitioning) && (
            <TransitionIndicator
              fillColor={CONTROL_COLOR}
              offsetX={outlineMiddleX}
              offsetY={outlineStartY}
              controlOffset={controlOffset}
            />
          )}

          {(!rotationIsLocked) && (
            <RotationIndicator
              entity={entity}
              game={game}
              fillColor={CONTROL_COLOR}
              strokeColor={CONTROL_ALT_COLOR}
              offsetX={outlineMiddleX}
              offsetY={outlineFarY}
              controlOffset={controlOffset}
              direction="down"
            />
          )}

          <g pointerEvents="visiblePainted">
            <Path
              entity={entity}
              game={game}
              path={outlinePath.join(' ')}
              strokeColor={CONTROL_COLOR}
              strokeWidth={outlineWidth}
            />
          </g>

          {(!sizeIsLocked) && (
            <g pointerEvents="visiblePainted">
              {getControl(outlineStartX, outlineStartY, controlOffset, resizeCorner.TOP_LEFT)}

              {getControl(outlineMiddleX, outlineStartY, controlOffset, resizeCorner.TOP_MIDDLE)}

              {getControl(outlineFarX, outlineStartY, controlOffset, resizeCorner.TOP_RIGHT)}

              {getControl(outlineStartX, outlineMiddleY, controlOffset, resizeCorner.MIDDLE_LEFT)}

              {getControl(outlineFarX, outlineMiddleY, controlOffset, resizeCorner.MIDDLE_RIGHT)}

              {getControl(outlineStartX, outlineFarY, controlOffset, resizeCorner.BOTTOM_LEFT)}

              {getControl(outlineMiddleX, outlineFarY, controlOffset, resizeCorner.BOTTOM_MIDDLE)}

              {getControl(outlineFarX, outlineFarY, controlOffset, resizeCorner.BOTTOM_RIGHT)}
            </g>
          )}
        </g>
      </svg>
    );
  }
}

DisplayActive.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(DisplayActive);
