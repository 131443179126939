import {toJS} from 'mobx';
import PropTypes from 'prop-types';
import React from 'react';
import NumericInput from 'react-numeric-input';

import {actionUpdateComponent} from '../../../../../display/components/action/actionUpdateComponent';
import {getComposeForSource} from '../../../../../display/components/common/composeComponent';
import {getFeedForSource, getFeedFromSource} from '../../../../../display/components/type/feedComponent';

import './opacitySelect.scss';

/**
 * Factor that expands the opacity to larger than 0-1.
 * @type {number}
 */
const MULTIPLY_FACTOR = 100;

/**
 * The minimum opacity size.
 * @const {string}
 */
const OPACITY_VALUE_MIN = 0;

/**
 * The maximum opacity size.
 * @const {string}
 */
const OPACITY_VALUE_MAX = MULTIPLY_FACTOR;

/**
 * The OpacitySelect component.
 *
 * @param {{}} props
 * @param {DisplayEditorStore} props.displayEditorStore
 * @param {ObservableMap} props.entity
 * @param {GameStore} props.game
 * @returns {React.Component}
 */
export const OpacitySelect = (props) => {
  const {
    /** @type DisplayEditorStore} */ displayEditorStore,
    /** @type {ObservableMap} */ entity,
    /** @type {GameStore} */ game,
  } = props;

  /**
   * Updates the feed entity when it is changed.
   *
   * @param {number} valueAsNumber
   */
  const onChange = (valueAsNumber) => {
    const actionParams = {
      entityId: entity.get('id'),
    };

    const composeSource = getComposeForSource(entity);
    const feedSource = getFeedForSource(entity);
    feedSource.feed.opacity = valueAsNumber / MULTIPLY_FACTOR;

    game.addAction(actionParams, actionUpdateComponent(
      getFeedFromSource(
        {...feedSource, ...composeSource},
        toJS(displayEditorStore.variables)
      )
    ));
  };

  const feed = entity.get('feed');
  const value = (feed.opacity) ? (feed.opacity * MULTIPLY_FACTOR) : OPACITY_VALUE_MAX;

  return (
    <div className="opacity-select">
      <NumericInput
        id="opacity-input"
        className="form-control form-control-sm"
        min={OPACITY_VALUE_MIN}
        max={OPACITY_VALUE_MAX}
        value={value}
        onChange={onChange}
        mobile
      />
    </div>
  );
};

OpacitySelect.propTypes = {
  displayEditorStore: PropTypes.object.isRequired,
  entity: PropTypes.object.isRequired,
  game: PropTypes.object.isRequired,
};

export default OpacitySelect;
