import PropTypes from 'prop-types';
import React from 'react';

/**
 * Create a clip path for cropping a triangle.
 *
 * @param {{}} props
 * @param {string} props.cropId
 * @param {{}} props.cropOptions
 * @returns {{}}
 */
export function CropTriangle({cropId, cropOptions}) {
  const left = cropOptions.left;
  const top = cropOptions.top; // eslint-disable-line no-shadow
  const width = cropOptions.width;
  const height = cropOptions.height;
  const halfWidth = (cropOptions.width / 2);

  const point1 = {x: (left + halfWidth), y: top};
  const point2 = {x: (left + width), y: (top + height)};
  const point3 = {x: left, y: (top + height)};

  return (
    <clipPath id={cropId}>
      <path d={`M ${point1.x},${point1.y} L ${point2.x},${point2.y} L ${point3.x},${point3.y} Z`} />
    </clipPath>
  );
}

CropTriangle.propTypes = {
  cropId: PropTypes.string.isRequired,
  cropOptions: PropTypes.shape({
    height: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    top: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
};

export default CropTriangle;
