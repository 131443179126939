import classNames from 'classnames';
import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown, faMaximize, faUpDownLeftRight} from '@fortawesome/free-solid-svg-icons';

import {actionAlignComponent} from '../../../../../display/components/action/actionAlignComponent';

import './sizeEntityButtons.scss';

import {FIT_FULL, FIT_CENTER_HALF} from '../../../../../constants/entityOptionConstants';
import {getIsPositionLocked} from '../../../../../utils/dragDropHelper';

/**
 * The list of alignment buttons.
 *
 * @type {Array.<{label: string, style: string}>}
 */
const FIT_BUTTON_TYPES = [
  {id: 10, label: 'Fit to Full Screen', style: FIT_FULL, icon: faMaximize, single: true},
  {id: 20, label: 'Fit to Half Screen', style: FIT_CENTER_HALF, icon: faUpDownLeftRight, single: true},
];

/**
 * The SizeEntityButtons component.
 */
export class SizeEntityButtons extends React.Component {
  /**
   * Whether or not the dropdown is open.
   *
   * @type {boolean}
   */
  @observable isDropdownOpen = false;

  /**
   * The last align type selected.
   *
   * @type {{}}
   */
  @observable lastItemUsed = FIT_BUTTON_TYPES[1];

  /**
   * Opens the align drop down.
   */
  @action toggleDropdown = () => {
    this.isDropdownOpen = !this.isDropdownOpen;
  };

  /**
   * Filters out items that aren't valid for single items.
   *
   * @param {boolean} isSingle
   * @returns {function}
   */
  filterForSingle = (isSingle) => {
    return (item) => {
      return Boolean(isSingle && item.single);
    };
  };

  /**
   * Aligns the entities.
   *
   * @param {{}} clicked
   * @returns {function()}
   */
  onClick = (clicked) => {
    return action(() => {
      this.lastItemUsed = clicked;

      const {game} = this.props;

      const entityIds = game.allActiveEntities.filter((activeEntity) => {
        return !getIsPositionLocked(activeEntity);
      }).map((activeEntity) => {
        return activeEntity.get('id');
      });
      const actionParams = {
        entityId: entityIds,
      };

      game.addAction(actionParams, actionAlignComponent(clicked.style));
    });
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {id, single} = this.props;

    const singleFilter = this.filterForSingle(single);

    return (
      <div id={id} className="size-entity-buttons">
        <button
          type="button"
          className={classNames('btn btn-sm btn-light form-button size-last-button')}
          onClick={this.onClick(this.lastItemUsed)}
        >
          <FontAwesomeIcon icon={this.lastItemUsed.icon} />
        </button>

        <Dropdown isOpen={this.isDropdownOpen} toggle={this.toggleDropdown}>
          <DropdownToggle
            className={classNames('btn btn-sm btn-light form-button size-dropdown')}
          >
            <FontAwesomeIcon icon={faCaretDown} />
          </DropdownToggle>
          <DropdownMenu right>
            {FIT_BUTTON_TYPES.filter(singleFilter).map((align) => {
              if (align.divider) {
                return (
                  <DropdownItem key={align.id} divider />
                );
              }

              return (
                <DropdownItem
                  key={align.id}
                  onClick={this.onClick(align)}
                  toggle={false}
                >{align.label}</DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

SizeEntityButtons.propTypes = {
  game: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  single: PropTypes.bool,
};

export default observer(SizeEntityButtons);
