import interact from 'interactjs/src/index';
import lodash from 'lodash';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {findDOMNode} from 'react-dom';

import {actionPositionComponent} from '../../display/components/action/actionPositionComponent';
import {actionUpdateComponent} from '../../display/components/action/actionUpdateComponent';
import {interactionComponent} from '../../display/components/common/interactionComponent';
import {
  getIsEntityTransitioning,
  getIsPositionLocked,
  getScaleFromElement,
  initEntitySize
} from '../../utils/dragDropHelper';

/**
 * Moves the entity.
 *
 * @param {ObservableMap} entity
 * @param {GameStore} game
 * @param {{x: number, y: number}} delta
 * @param {?number} rotate
 */
function moveEntity(entity, game, delta, rotate) {
  const safeRotate = (rotate || rotate === 0) ? rotate : null;

  const currentInteraction = entity.get('interaction');
  const isCropping = Boolean(currentInteraction && currentInteraction.isCropping);

  game.addAction({entityId: entity.get('id')}, actionPositionComponent(
    false,
    {y: delta.y, x: delta.x},
    null,
    safeRotate,
    !isCropping
  ));
}

/**
 * Gets whether or not the entity is active.
 *
 * @param {ObservableMap} entity
 * @returns {boolean}
 */
function getIsActive(entity) {
  const interaction = entity.get('interaction');

  if (getIsPositionLocked(entity)) {
    return false;
  }

  return Boolean(interaction && interaction.isActive);
}

/**
 * A higher order component wrapper that handles making an entity movable.
 *
 * @param {Object} WrappedComponent
 * @returns {Object}
 */
export default function entityMoveHocWrapper(WrappedComponent) {
  /**
   * The EntityMoveHoc higher order component.
   */
  class EntityMoveHoc extends React.Component {
    /**
     * Whether or not this entity is currently active.
     * Used to track the change.
     *
     * @type {boolean}
     */
    @observable isActive = false;

    /**
     * The DOM element for the element.
     *
     * @type {HTMLElement}
     */
    @observable domEl = null;

    /**
     * The DOM element for the display element.
     *
     * @type {HTMLElement}
     */
    @observable displayEl = null;

    /**
     * The interactJS object initialized on the DOM element.
     *
     * @type {{draggable: function, resizable: function}}
     */
    @observable interaction = null;

    /**
     * Triggered right after the component mounts to the page.
     */
    componentDidMount() {
      this.checkActiveStateChange();
    }

    /**
     * Triggered when the component is about to unmount.
     */
    componentWillUnmount() {
      this.stopDragging();
    }

    /**
     * Triggered when an observed item updates.
     */
    componentWillReact = () => {
      this.checkActiveStateChange();
    };

    /**
     * Checks whether or not the active state has changed and either allows it to be dragged or stops it from
     * dragging.
     */
    @action checkActiveStateChange = () => {
      const entities = this.props.entities || [this.props.entity];
      const isActive = lodash.some(entities, (entity) => {
        return getIsActive(entity);
      });

      if (!this.isActive && isActive) {
        this.initInteraction();
        this.isActive = true;
      } else if (this.isActive && !isActive) {
        this.stopDragging();
        this.isActive = false;
      }
    };

    /**
     * Triggered right after the wrapped component is added to OR removed from the page.
     *
     * @param {{}} domEl
     */
    @action onChangeMount = (domEl) => {
      if (this.domEl) {
        return;
      }

      this.domEl = findDOMNode(domEl);
      this.displayEl = document.getElementById('display-source');
    };

    /**
     * Handles the drag move event.
     *
     * @param {{dx: number, dy: number}} dragEvent
     */
    onMove = (dragEvent) => {
      const entities = this.props.entities || [this.props.entity];
      const game = this.props.game;
      const scale = getScaleFromElement(this.displayEl);

      entities.forEach((entity) => {
        if (getIsEntityTransitioning(entity, game.timer.elapsedTime, ['position'])) {
          // TODO: Indicate to the user why dragging isn't working.
          return;
        }

        moveEntity(entity, game, {
          x: dragEvent.dx / scale,
          y: dragEvent.dy / scale
        });
      });
    };

    /**
     * Handles the double click event.
     */
    onDoubleClick = () => {
      if (!this.props.entity) {
        // We don't want to activate cropping if multiple items are selected, so ignore `this.props.entities`.
        return;
      }

      const entity = this.props.entity;
      const game = this.props.game;

      if (entity.get('element') !== 'image') {
        return;
      }

      const currentInteraction = entity.get('interaction');
      if (!currentInteraction.isActive) {
        return;
      }

      const newIsCropping = !currentInteraction.isCropping;

      const actionParams = {
        entityId: entity.get('id'),
      };

      game.addAction(actionParams, actionUpdateComponent(
        interactionComponent(true, newIsCropping)
      ));
    };

    /**
     * Starts the interactJS code.
     */
    @action initInteraction = () => {
      const entities = this.props.entities || [this.props.entity];

      if (this.interaction) {
        // Make sure we don't have multiple dragging interactions on this element.
        this.interaction.unset();
      }

      entities.forEach((entity) => {
        initEntitySize(entity, this.props.game);
      });

      const interaction = interact(this.domEl);
      interaction.draggable({
        onmove: this.onMove,
      });
      interaction.on('doubletap', this.onDoubleClick);

      this.interaction = interaction;
    };

    /**
     * Unbinds the dragging code.
     */
    @action stopDragging = () => {
      if (!this.interaction) {
        return;
      }

      this.interaction.unset();
      this.interaction = null;
    };

    /**
     * Renders the WrappedComponent.
     *
     * @returns {Object}
     */
    render() {
      if (!WrappedComponent) {
        return null;
      }

      if (this.props.ref) {
        throw new Error('EntityMoveHoc will override ref property given to the wrapped component.');
      }

      return (
        <WrappedComponent
          {...this.props}
          ref={this.onChangeMount}
        />
      );
    }
  }

  EntityMoveHoc.propTypes = {
    game: PropTypes.shape({
      setEntityComponents: PropTypes.func,
    }).isRequired,

    entities: PropTypes.arrayOf(MobxPropTypes.observableMap),
    entity: MobxPropTypes.observableMap,
    ref: PropTypes.func,
  };

  return observer(EntityMoveHoc);
}
