import lodash from 'lodash';

/**
 * The default rectangle color.
 * @const {string}
 */
const DEFAULT_COLOR = '#000000';

/**
 * The default rectangle opacity.
 * @const {number}
 */
const DEFAULT_OPACITY = 1;

/**
 * The rectangle component.
 *
 * @param {{opacity: number}=} style
 * @param {{opacity: number, width: number}=} border
 * @returns {{rectangle: {}}}
 */
export function rectangleComponent(style, border) {
  return {
    rectangle: {
      style,
      border
    },
  };
}

/**
 * Gets the rectangle component from the source item.
 *
 * @param {{rectangle: {style: {}, border: {}}}} item
 * @param {{}=} variables
 * @returns {{rectangle: {style: {}, border: {}}}}
 */
export function getRectangleFromSource(item, variables) {
  if (!item.rectangle) {
    return {};
  }

  const rectangle = {...item.rectangle};
  if (!rectangle.style) {
    rectangle.style = {};
  }
  if (!rectangle.border) {
    rectangle.border = {};
  }

  const canEdit = lodash.get(item, 'compose.canEdit');
  const variableName = lodash.get(item, 'compose.variableName');
  if (canEdit && variableName) {
    const variableValue = lodash.get(variables, `rectangle.${variableName}`, '');
    if (variableValue) {
      let styleColor, styleOpacity, borderColor, borderOpacity;
      if (lodash.isString(variableValue)) {
        // The old deprecated variable style is a string with | delimiters.
        const partsCount = 4;
        [styleColor, styleOpacity, borderColor, borderOpacity] = lodash.split(variableValue, '|', partsCount);
      } else {
        // The ()s fix an eslint parsing error.
        ({styleColor, styleOpacity, borderColor, borderOpacity} = variableValue);
      }

      rectangle.style.color = styleColor;
      rectangle.style.opacity = styleOpacity;
      rectangle.border.color = borderColor;
      rectangle.border.opacity = borderOpacity;
    }
  }

  const style = {
    color: rectangle.style.color || DEFAULT_COLOR,
    opacity: getOpacity(rectangle.style),
    rounded: rectangle.style.rounded || 0,
  };
  const border = {
    color: rectangle.border.color || DEFAULT_COLOR,
    opacity: getOpacity(rectangle.border),
    width: rectangle.border.width || 0,
  };

  return rectangleComponent(
    style,
    border,
  );
}

/**
 * Gets the opacity or the default.
 *
 * @param {{opacity: number}} item
 * @returns {number}
 */
function getOpacity(item) {
  if (!item || item.opacity === undefined) {
    return DEFAULT_OPACITY;
  }

  const safeOpacity = Number(item.opacity);
  if (!safeOpacity && safeOpacity !== 0) {
    return DEFAULT_OPACITY;
  }

  return safeOpacity;
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {{}} entity
 * @returns {{}}
 */
export function getRectangleForSource(entity) {
  if (!entity.has('rectangle')) {
    return {};
  }

  const compose = entity.get('compose');
  const rectangle = entity.get('rectangle');

  const canEdit = lodash.get(compose, 'canEdit', false);
  const variableName = lodash.get(compose, 'variableName');

  const outRectangle = {};

  if (!canEdit || !variableName) {
    outRectangle.style = rectangle.style;
    outRectangle.border = rectangle.border;
  } else {
    outRectangle.style = {
      rounded: rectangle.style.rounded || 0,
    };
    outRectangle.border = {
      width: rectangle.border.width || 0,
    };
  }

  return {
    rectangle: lodash.cloneDeep(outRectangle),
  };
}
