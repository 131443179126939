import {action} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import CollectionSidebarList from '../../../pages/dashboard/components/collectionSidebarList/CollectionSidebarList';

/**
 * The PublishNew component.
 */
export class PublishNew extends React.Component {
  /**
   * Called when a collection is selected.
   *
   * @param {{id: number}} selectedCollection
   */
  @action onCollectionSelected = (selectedCollection) => {
    const {onCollectionSelected} = this.props;
    onCollectionSelected(selectedCollection.id);
  };

  /**
   * Triggered when the name input changes.
   *
   * @param {{target: {value: string}}} changeEvent
   */
  @action onNameChange = (changeEvent) => {
    const {onNameChange} = this.props;
    onNameChange(
      String(changeEvent.target.value)
    );
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      askForName,
      collectionId,
      contentName,
      user,
    } = this.props;

    return (
      <div className="publish-new">
        {(askForName) && (
          <div className="form-group">
            <label htmlFor="content-name" className="site-label">Name:</label>
            <div className="site-text-input">
              <input
                id="content-name"
                type="text"
                value={contentName}
                placeholder="New Content"
                name="contentName"
                onChange={this.onNameChange}
              />
            </div>
          </div>
        )}

        <CollectionSidebarList
          onSelect={this.onCollectionSelected}
          selected={collectionId}
          user={user}
        />
      </div>
    );
  }
}

PublishNew.propTypes = {
  onCollectionSelected: PropTypes.func.isRequired,
  onNameChange: PropTypes.func.isRequired,

  askForName: PropTypes.bool,
  collectionId: PropTypes.number,
  contentName: PropTypes.string,
  user: PropTypes.object,
};

PublishNew.defaultProps = {
  askForName: true,
};

export default observer(PublishNew);
