/**
 * The default navigation.
 *
 * @const {Symbol}
 */
export const NAV_DEFAULT = Symbol('navDefault');

/**
 * The presto navigation.
 *
 * @const {Symbol}
 */
export const NAV_PRESTO = Symbol('navPresto');

/**
 * The editor navigation.
 *
 * @const {Symbol}
 */
export const NAV_EDITOR = Symbol('navEditor');

/**
 * The dashboard navigation.
 *
 * @const {Symbol}
 */
export const NAV_DASHBOARD = Symbol('navDashboard');
