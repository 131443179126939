import React from 'react';
import {toJS} from 'mobx';
import lodash from 'lodash';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';

import EditorSidebarTitle from '../../common/editorSidebarTitle/EditorSidebarTitle';
import EditAlignmentControls from '../editAlignment/EditAlignmentControls';
import EditComposeControls from '../editCompose/EditComposeControls';
import EditEffectControls from '../editEffect/EditEffectControls';
import EditTimelineControls from '../editTimeline/EditTimelineControls';
import {actionUpdateComponent} from '../../../display/components/action/actionUpdateComponent';
import {actionUpdateVariableComponent} from '../../../display/components/action/actionUpdateVariableComponent';
import {getComposeForSource} from '../../../display/components/common/composeComponent';
import {getLineForSource, getLineFromSource} from '../../../display/components/type/lineComponent';

import SnapLineAngleButtons from './components/SnapLineAngleButtons';
import ColorSelector from '../../common/colorSelector/ColorSelector';
import ThicknessSelector from '../../common/thicknessSelector/ThicknessSelector';

import './editLineControls.scss';

/**
 * The EditLineControls component.
 */
export class EditLineControls extends React.Component {
  /**
   * Updates the line entity when it is changed.
   *
   * @param {{styleColor: string, styleOpacity: number, thickness: number}} changes
   */
  onChangeLine = (changes) => {
    const {
      /** @type {DisplayEditorStore} */ displayEditorStore,
      /** @type {ObservableMap} */ entity,
      /** @type {GameStore} */ game,
    } = this.props;

    const {style} = entity.get('line');
    const compose = entity.get('compose') || {};

    if (compose.variableName) {
      const element = entity.get('element');

      let currentVariable = displayEditorStore.getVariable(element, compose.variableName, {});
      if (lodash.isString(currentVariable)) {
        // Supported for deprecated string variables.
        const parts = currentVariable.split('|');
        currentVariable = {
          styleColor: parts[0],
          styleOpacity: parts[1],
        };
      }

      const variableValue = lodash.defaults(
        lodash.pick(changes, ['styleColor', 'styleOpacity']),
        currentVariable,
        {styleColor: style.color, styleOpacity: style.opacity},
      );

      displayEditorStore.setVariable(element, compose.variableName, variableValue);
    }

    const sourceVariables = toJS(displayEditorStore.variables);

    const entityUpdates = lodash.filter(game.entities, (gameEntity) => {
      if (gameEntity.get('id') === entity.get('id')) {
        return true;
      }

      if (!gameEntity.has('compose') || gameEntity.get('element') !== 'line') {
        return false;
      } else if (gameEntity.get('compose').variableName !== compose.variableName) {
        return false;
      }

      return true;
    }).reduce((final, updateEntity) => {
      const updateId = updateEntity.get('id');
      const currentLine = updateEntity.get('line');

      const lineSource = getLineForSource(updateEntity);
      const composeSource = getComposeForSource(updateEntity);

      lineSource.line.style = lodash.defaults({
        color: changes.styleColor,
        opacity: changes.styleOpacity,
      }, currentLine.style || {});

      final[updateId] = getLineFromSource(
        {...lineSource, ...composeSource},
        sourceVariables
      );
      return final;
    }, {});

    const actionParams = {
      entityId: entity.get('id'),
    };

    // This will rebuild the component for the entities using the new variable value.
    game.addAction(actionParams, actionUpdateVariableComponent(
      entityUpdates
    ));
  };

  /**
   * Updates the line direct items (non-variable) when it is changed.
   *
   * @param {number} thickness
   */
  onChangeLineDirect = (thickness) => {
    const {
      /** @type {ObservableMap} */ entity,
      /** @type {GameStore} */ game,
    } = this.props;

    const actionParams = {
      entityId: entity.get('id'),
    };

    const updates = {};
    if (thickness != null) {
      updates.thickness = thickness || 0;
    }

    // This will rebuild the component for the entities using the new variable value.
    game.addAction(actionParams, actionUpdateComponent({
      line: updates,
    }));
  };

  /**
   * Renders the line controls.
   *
   * @returns {{}}
   */
  renderLineControls() {
    const {entity, game} = this.props;

    const line = entity.get('line');
    const {style} = line;

    return (
      <div className="group-controls">
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="color-selector-input">Color</label>
              <ColorSelector
                colorValue={style.color}
                opacityValue={style.opacity}
                onChangeColor={(color, opacity) => this.onChangeLine({styleColor: color, styleOpacity: opacity})}
              />
            </div>
          </div>

          {(!game.composeMode) && (
            <div className="col">
              <div className="form-group">
                <label htmlFor="thickness-selector-input">Thickness</label>
                <ThicknessSelector
                  thicknessValue={line.thickness}
                  onChangeThickness={(thickness) => this.onChangeLineDirect(thickness)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, game} = this.props;

    return (
      <div className="edit-line-controls">
        <div className="line-group">
          <EditorSidebarTitle title="Line" />

          {(!game.composeMode) && (
            <div className="group-controls">
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="color-selector-input">Snap</label>
                    <SnapLineAngleButtons entity={entity} game={game} />
                    <p className="control-group-help">Snaps the line to the nearest half-axis.</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.renderLineControls()}
        </div>

        <EditComposeControls entity={entity} game={game} />
        <EditAlignmentControls entity={entity} game={game} />
        <EditTimelineControls entity={entity} game={game} />
        <EditEffectControls entity={entity} game={game} />
      </div>
    );
  }
}

EditLineControls.propTypes = {
  displayEditorStore: PropTypes.object.isRequired,
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(EditLineControls);
