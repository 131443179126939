import {action, extendObservable, observable, when} from 'mobx';

import apiUserGetMeStore from './apiUserGetMeStore';
import {STATE_PRE, STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import serverApi from '../../../utils/serverApi';
import {apiStore, getCase} from '../../../utils/apiStore';

/**
 * The userOnboard store.
 */
class ApiUserOnboardStore {
  /**
   * @constructor
   */
  constructor() {
    extendObservable(this, apiStore);
  }

  /**
   * Internal state of the store.
   *
   * @type {Observable<{error: ?Error}>}
   */
  @observable userOnboard = {
    error: null,
  };

  /**
   * Gets the fulfilled value of the store.
   * This is used in case().
   *
   * @returns {boolean}
   */
  getFulfilled() {
    return true;
  }

  /**
   * Gets the rejected value of the store.
   * This is used in case().
   *
   * @returns {?Error}
   */
  getRejected() {
    return this.userOnboard.error;
  }

  /**
   * Gets the state of the store
   *
   * @returns {string}
   */
  getState() {
    return this.state;
  }

  /**
   * Clears all the newUser info
   */
  @action clearAll() {
    this.userOnboard.error = null;
    this.state = STATE_PRE;
  }

  /**
   * Create a hosted page from Chargebee to allow the user to checkout
   * @param {{planId: number}} data
   */
  @action async chargebeeCheckout(data) {
    const chargebeeCheckoutResponse = await serverApi.chargebeeCheckout(data);

    return chargebeeCheckoutResponse;
  }

  /**
   * Posts userOnboard to the server.
   *
   * @param {{}} body
   * @param {string} body.planId
   * @param {number} body.quantity
   * @param {{}=} body.signData
   * @param {{}=} body.userData
   */
  @action makeRequest(body) {
    if (this.state === STATE_PENDING) {
      // Prevent double requests.
      return;
    }

    this.state = STATE_PENDING;

    serverApi.userOnboard(body).then(
      action('postUserOnboardSuccess', () => {
        this.userOnboard.error = null;
        this.state = STATE_FULFILLED;

        // Make sure the get me fetches the updated user.
        apiUserGetMeStore.refresh(true);
      }),
      action('postUserOnboardError', (userOnboardError) => {
        this.userOnboard.error = userOnboardError;
        this.state = STATE_REJECTED;
      })
    );
  }

  /**
   * Runs handlers based on changes in the state.
   *
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  case(handlers) {
    const getFulfilled = () => {
      return this.getFulfilled();
    };
    const getRejected = () => {
      return this.getRejected();
    };

    return getCase(this.state, getFulfilled, getRejected, handlers);
  }

  /**
   * Gets a promise for this store.
   *
   * @returns {Promise}
   */
  getPromise() {
    const thisStore = this;

    return new Promise((resolve, reject) => {
      when(
        () => {
          return (thisStore.state === STATE_FULFILLED || thisStore.state === STATE_REJECTED);
        },
        () => {
          if (thisStore.state === STATE_REJECTED) {
            reject(this.getRejected());
            return;
          }

          resolve(this.getFulfilled());
        },
        {name: 'apiUserOnboardStoreGetPromise'}
      );
    });
  }
}

export default new ApiUserOnboardStore();
