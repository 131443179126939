/**
 * The paused state of the item.
 * @const {string}
 */
export const STATE_PAUSED = 'paused';

/**
 * The playing state of the item.
 * @const {string}
 */
export const STATE_PLAYING = 'playing';

/**
 * The stopped state of the item.
 * @const {string}
 */
export const STATE_STOPPED = 'stopped';
