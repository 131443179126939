import {action} from 'mobx';

import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import serverApi from '../../../utils/serverApi';
import ApiMapBaseStore from '../common/apiMapBaseStore';

/**
 * ApiCompanyGetOneStore
 */
class ApiCompanyGetOneStore extends ApiMapBaseStore {
  /**
   * Fetches company information from the server.
   *
   * @param {string} companyId
   */
  @action makeRequest(companyId) {
    this.dataMap.set(companyId, {
      state: STATE_PENDING,
      [EXPIRE_TIME]: Date.now() + EXPIRES_PENDING,
      data: null,
      error: null,
    });

    serverApi.companyGetOneById(companyId).then(
      action('companyGetOneSuccess', (foundCompany) => {
        this.dataMap.set(companyId, {
          state: STATE_FULFILLED,
          [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
          data: foundCompany,
          error: null,
        });
      }),
      action('companyGetOneError', (companyGetOneError) => {
        this.dataMap.set(companyId, {
          state: STATE_REJECTED,
          [EXPIRE_TIME]: null,
          data: null,
          error: companyGetOneError,
        });
      })
    );
  }
}

export default new ApiCompanyGetOneStore();
