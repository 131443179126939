import {toJS} from 'mobx';
import PropTypes from 'prop-types';
import React from 'react';
import NumericInput from 'react-numeric-input';

import {actionUpdateComponent} from '../../../../../display/components/action/actionUpdateComponent';
import {getComposeForSource} from '../../../../../display/components/common/composeComponent';
import {
  DEFAULT_DROP_SHADOW,
  getImageForSource,
  getImageFromSource
} from '../../../../../display/components/type/imageComponent';

import './dropShadowSizeSelect.scss';

/**
 * The minimum dropShadow size.
 * @const {string}
 */
const SHADOW_POSITION_MIN = 0;

/**
 * The maximum dropShadow size.
 * @const {string}
 */
const SHADOW_POSITION_MAX = 200;

/**
 * The DropShadowSizeSelect component.
 *
 * @param {{}} props
 * @param {string} props.coordinate 'x' or 'y'
 * @param {DisplayEditorStore} props.displayEditorStore
 * @param {ObservableMap} props.entity
 * @param {GameStore} props.game
 * @returns {React.Component}
 */
export const DropShadowSizeSelect = (props) => {
  const {
    coordinate,
    /** @type DisplayEditorStore} */ displayEditorStore,
    /** @type {ObservableMap} */ entity,
    /** @type {GameStore} */ game,
  } = props;

  const safeCoordinate = (coordinate === 'x' || coordinate === 'y') ? coordinate : 'x';

  /**
   * Updates the image entity when it is changed.
   *
   * @param {number} valueAsNumber
   */
  const onChange = (valueAsNumber) => {
    const actionParams = {
      entityId: entity.get('id'),
    };

    const composeSource = getComposeForSource(entity);
    const imageSource = getImageForSource(entity, game);
    if (!imageSource.image.dropShadow) {
      imageSource.image.dropShadow = {...DEFAULT_DROP_SHADOW};
    }
    imageSource.image.dropShadow[safeCoordinate] = valueAsNumber;

    game.addAction(actionParams, actionUpdateComponent(
      getImageFromSource(
        {...imageSource, ...composeSource},
        toJS(displayEditorStore.variables)
      )
    ));
  };

  const image = entity.get('image');
  const value = (image.dropShadow) ? image.dropShadow[safeCoordinate] : 0;

  return (
    <div className="drop-shadow-size-select">
      <NumericInput
        id={`drop-shadow-selector-input-${safeCoordinate}`}
        className="form-control form-control-sm"
        min={SHADOW_POSITION_MIN}
        max={SHADOW_POSITION_MAX}
        value={value}
        onChange={onChange}
        mobile
      />
    </div>
  );
};

DropShadowSizeSelect.propTypes = {
  coordinate: PropTypes.oneOf(['x', 'y']).isRequired,
  displayEditorStore: PropTypes.object.isRequired,
  entity: PropTypes.object.isRequired,
  game: PropTypes.object.isRequired,
};

export default DropShadowSizeSelect;
