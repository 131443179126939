import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {CSSTransition as CSSTransitionComponent, TransitionGroup} from 'react-transition-group';

import ContentItem from '../../../../common/contentItem/ContentItem';
import LoadingIcon from '../../../../common/loadingIcon/LoadingIcon';
import inject from '../../../../hoc/injectHoc';

import './searchContentItems.scss';

/**
 * The searching content items component
 */
class SearchContentItems extends React.Component {
  /**
   * Loads the content.
   * Triggered when the component is mounted to the page.
   */
  componentDidMount() {
    this.loadContent();
  }

  /**
   * Reloads the content if the list id or list type changes.
   * Triggered when the props update.
   *
   * @param {{}} prevProps
   */
  componentDidUpdate(prevProps) {
    const {searchTerm} = this.props;

    if (prevProps.searchTerm !== searchTerm) {
      this.loadContent();
    }
  }

  /**
   * Loads the content from the appropriate store.
   */
  loadContent = () => {
    const {
      apiContentSearchStore,
      globalOnly,
      onContentLoaded,
      searchTerm,
    } = this.props;

    const searchKey = apiContentSearchStore.buildKey(searchTerm, globalOnly, false);
    apiContentSearchStore.refresh(searchTerm, globalOnly, false);

    if (onContentLoaded) {
      apiContentSearchStore.getPromise(searchKey).then((contentItems) => {
        onContentLoaded(contentItems);
      });
    }
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      activeContentId,
      apiContentSearchStore,
      globalOnly,
      onContentSelect,
      searchTerm,
    } = this.props;

    const searchKey = apiContentSearchStore.buildKey(searchTerm, globalOnly, false);

    return (
      <TransitionGroup className="search-content-items d-flex flex-wrap">
        {apiContentSearchStore.case(searchKey, {
          pending: () => (
            <CSSTransitionComponent
              timeout={200}
              classNames="fade"
            >
              <LoadingIcon size="lg" />
            </CSSTransitionComponent>
          ),
          rejected: (error) => (
            <CSSTransitionComponent
              timeout={200}
              classNames="fade"
            >
              {error.message}
            </CSSTransitionComponent>
          ),
          fulfilled: (contentItems) => (contentItems.map((contentItem) => (
            <CSSTransitionComponent
              key={contentItem.id}
              timeout={200}
              classNames="fade"
            >
              <ContentItem
                content={contentItem}
                contentId={contentItem.id}
                isActive={activeContentId === contentItem.id}
                onClick={onContentSelect}
              />
            </CSSTransitionComponent>
          )))
        })}
      </TransitionGroup>
    );
  }
}

SearchContentItems.propTypes = {
  globalOnly: PropTypes.bool.isRequired,
  onContentSelect: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,

  activeContentId: PropTypes.number,
  apiContentSearchStore: MobxPropTypes.observableObject,
  onContentLoaded: PropTypes.func,
  user: PropTypes.object,
};

SearchContentItems.wrappedComponent = {};
SearchContentItems.wrappedComponent.propTypes = {
  apiContentSearchStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(SearchContentItems)(
  observer(SearchContentItems)
);
