import classNames from 'classnames';
import {observer} from 'mobx-react';
import React, {Component} from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';

/**
 * The list of manufacturer label/value pairs for react-select.
 *
 * @type {Array.<{label: string, value: string}>}
 */
const MANUFACTURER = [
  {label: 'NextLED', value: 'NextLED'},
  {label: 'Watchfire', value: 'Watchfire'},
  {label: 'Daktronics', value: 'Daktronics'},
  {label: 'Optec Displays', value: 'Optec Displays'},
  {label: 'Vantage LED', value: 'Vantage LED'},
  {label: 'ThinkSign', value: 'ThinkSign'},
  {label: 'Samsung', value: 'Samsung'},
  {label: 'SureFire Signs', value: 'SureFire Signs'},
  {label: 'Yesco', value: 'Yesco'},
  {label: 'Adaptive', value: 'Adaptive'},
  {label: 'Ebsco', value: 'Ebsco'},
  {label: 'Cirrus', value: 'Cirrus'},
  {label: 'Electro - Matic', value: 'Electro - Matic'},
  {label: 'Mega', value: 'Mega'},
  {label: 'ImpactLED', value: 'ImpactLED'},
  {label: 'Other', value: 'Other'},
];

/**
 * The ManufacturerSelect component.
 */
class ManufacturerSelect extends Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {className, classNamePrefix, id, onChange, theme, value} = this.props;

    return (
      <ReactSelect
        id={id}
        className={classNames(className, 'site-select', `theme-${theme}`)}
        classNamePrefix={classNamePrefix}
        placeholder="LED Sign Manufacturer"
        isClearable={false}
        isMulti={false}
        onChange={onChange}
        options={MANUFACTURER}
        value={MANUFACTURER.find((option) => option.value === value)}
      />
    );
  }
}

ManufacturerSelect.propTypes = {
  onChange: PropTypes.func.isRequired,

  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  id: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark', 'input-white']),
  value: PropTypes.string,
};

ManufacturerSelect.defaultProps = {
  theme: 'light',
};

export default observer(ManufacturerSelect);
