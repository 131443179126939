import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {observable, action} from 'mobx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';

import StrokeColorSelect from '../strokeColorSelect/StrokeColorSelect';
import StrokeSizeSelect from '../strokeSizeSelect/StrokeSizeSelect';

import './advancedTextControls.scss';
import DropShadowSizeSelect from '../dropShadowSizeSelect/DropShadowSizeSelect';
import DropShadowBlurSelect from '../dropShadowBlurSelect/DropShadowBlurSelect';
import DropShadowColorSelect from '../dropShadowColorSelect/DropShadowColorSelect';
import OpacitySelect from '../opacitySelect/OpacitySelect';

/**
 * The AdvancedTextControls component.
 */
export class AdvancedTextControls extends React.Component {
  /**
   * Whether or not the stroke control group is open.
   *
   * @type {boolean}
   */
  @observable isStrokeControlGroupOpen = false;

  /**
   * Whether or not the shadow control group is open.
   *
   * @type {boolean}
   */
  @observable isShadowControlGroupOpen = false;

  /**
   * Whether or not the opacity control group is open.
   *
   * @type {boolean}
   */
  @observable isOpacityControlGroupOpen = false;

  @action toggleStrokeControlGroup = () => {
    this.isStrokeControlGroupOpen = !this.isStrokeControlGroupOpen;
  };

  @action toggleShadowControlGroup = () => {
    this.isShadowControlGroupOpen = !this.isShadowControlGroupOpen;
  };

  @action toggleOpacityControlGroup = () => {
    this.isOpacityControlGroupOpen = !this.isOpacityControlGroupOpen;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      /** @type {DisplayEditorStore} */ displayEditorStore,
      /** @type {ObservableMap} */ entity,
      /** @type {GameStore} */ game,
    } = this.props;

    if (game.composeMode) {
      // Compose mode disables editing these values.
      return null;
    }

    return (
      <div className="advanced-text-controls control-group">
        <div className="group-header">
          <span className="group-header-label">Stroke</span>
          <button
            type="button"
            className="btn"
            onClick={this.toggleStrokeControlGroup}
          >
            <FontAwesomeIcon icon={this.isStrokeControlGroupOpen ? faChevronUp : faChevronDown} />
          </button>
        </div>

        {this.isStrokeControlGroupOpen && (<div className="group-controls">
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="stroke-selector-input">Width</label>
                <StrokeSizeSelect
                  displayEditorStore={displayEditorStore}
                  game={game}
                  entity={entity}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="stroke-color-selector">Color</label>
                <StrokeColorSelect
                  displayEditorStore={displayEditorStore}
                  game={game}
                  entity={entity}
                />
              </div>
            </div>
          </div>
        </div>)}

        <div className="group-header">
          <span className="group-header-label">Drop Shadow</span>
          <button
            type="button"
            className="btn"
            onClick={this.toggleShadowControlGroup}
          >
            <FontAwesomeIcon icon={this.isShadowControlGroupOpen ? faChevronUp : faChevronDown} />
          </button>
        </div>

        {this.isShadowControlGroupOpen && (<div className="group-controls">
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="stroke-selector-input">X</label>
                <DropShadowSizeSelect
                  coordinate="x"
                  displayEditorStore={displayEditorStore}
                  entity={entity}
                  game={game}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="stroke-color-selector">Y</label>
                <DropShadowSizeSelect
                  coordinate="y"
                  displayEditorStore={displayEditorStore}
                  entity={entity}
                  game={game}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="stroke-selector-input">Blur</label>
                <DropShadowBlurSelect
                  displayEditorStore={displayEditorStore}
                  entity={entity}
                  game={game}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="stroke-color-selector">Color</label>
                <DropShadowColorSelect
                  displayEditorStore={displayEditorStore}
                  entity={entity}
                  game={game}
                />
              </div>
            </div>
          </div>
        </div>)}

        <div className="group-header">
          <span className="group-header-label">Opacity</span>
          <button
            type="button"
            className="btn"
            onClick={this.toggleOpacityControlGroup}
          >
            <FontAwesomeIcon icon={this.isOpacityControlGroupOpen ? faChevronUp : faChevronDown} />
          </button>
        </div>

        {this.isOpacityControlGroupOpen && (<div className="group-controls">
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="stroke-selector-input">Opacity</label>
                <OpacitySelect
                  displayEditorStore={displayEditorStore}
                  game={game}
                  entity={entity}
                />
              </div>
            </div>
          </div>
        </div>)}
      </div>
    );
  }
}

AdvancedTextControls.propTypes = {
  displayEditorStore: PropTypes.object.isRequired,
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(AdvancedTextControls);
