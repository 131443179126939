import mainConfig from '../config/main';

/**
 * The name of the user role.
 * @const {string}
 */
export const USER_ROLE = 'user';

/**
 * The name of the super admin role.
 * @const {string}
 */
export const SUPER_ADMIN_ROLE = 'super-admin';

export const ADMIN_ROLE = 'admin';

export const EMULATION_STORAGE_COOKIE_KEY = `${mainConfig.hub.emulationCookiePrefix}project_content_emulation_id`;

export const GENERAL_FOLDER_USER_ID = 0;
