import {runInAction, toJS} from 'mobx';

import {clearTransitionCache, updateEntity} from '../ecs/entityHelper';
import {between} from '../../utils/mathHelper';

/**
 * The name of the system.
 * @const {string}
 */
export const LINE_POSITIONING_SYSTEM = 'linePositioningSystem';

/**
 * Gets a new instance of the linePositioning system.
 *
 * @param {GameStore} game
 * @returns {{name: string, runActions: systemRunActions}}
 */
export function linePositioningSystem(game) {
  /**
   * Called right before the game loop updates.
   *
   * @param {ObservableArray} actions
   */
  function systemRunActions(actions) {
    actions.forEach((actionEntity) => {
      // First check for required components.
      if (!actionEntity.has('actionLinePosition')) {
        return;
      }

      const entityId = actionEntity.get('action').entityId;
      const entity = game.getEntity(entityId);
      if (!entity) {
        return;
      }

      const {startDelta, endDelta} = actionEntity.get('actionLinePosition');
      if (!startDelta && !endDelta) {
        return;
      }

      const currentLine = toJS(entity.get('line'));
      const resolution = game.resolution;

      const newStartPoint = {...currentLine.startPoint};
      const newEndPoint = {...currentLine.endPoint};

      if (startDelta) {
        newStartPoint.x = between(newStartPoint.x + startDelta.x, 0, resolution.width);
        newStartPoint.y = between(newStartPoint.y + startDelta.y, 0, resolution.height);
      }
      if (endDelta) {
        newEndPoint.x = between(newEndPoint.x + endDelta.x, 0, resolution.width);
        newEndPoint.y = between(newEndPoint.y + endDelta.y, 0, resolution.height);
      }

      // Now update the entity.
      runInAction('linePositioningSystemUpdateEntity', () => {
        updateEntity(entity, 'line', {
          startPoint: newStartPoint,
          endPoint: newEndPoint,
        });

        clearTransitionCache(entity);
      });
    });
  }

  return {
    name: LINE_POSITIONING_SYSTEM,
    runActions: systemRunActions
  };
}
