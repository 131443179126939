import lodash from 'lodash';
import {action, observable, toJS} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencil} from '@fortawesome/free-solid-svg-icons';

import EditAlignmentControls from '../editAlignment/EditAlignmentControls';
import EditComposeControls from '../editCompose/EditComposeControls';
import EditEffectControls from '../editEffect/EditEffectControls';
import EditPositionControls from '../editPosition/EditPositionControls';
import EditTimelineControls from '../editTimeline/EditTimelineControls';
import EditUnitsControls from '../editUnits/EditUnitsControls';
import SelectFileModal, {FILE_TYPE_VIDEO} from '../../modals/selectFile/SelectFileModal';
import {actionUpdateVariableComponent} from '../../../display/components/action/actionUpdateVariableComponent';
import {getComposeForSource} from '../../../display/components/common/composeComponent';
import {getVideoForSource, getVideoFromSource} from '../../../display/components/type/videoComponent';

import './editVideoControls.scss';

/**
 * The EditVideoControls component.
 */
export class EditVideoControls extends React.Component {
  /**
   * Whether or not the video choose/upload modal is open.
   *
   * @type {boolean}
   */
  @observable isModalOpen = false;

  /**
   * Updates the video entity when it is changed.
   *
   * @param {number} newFileId
   * @param {string} newUrl
   * @param {number} newDuration
   * @param {string} newType
   */
  onChangeVideo = (newFileId, newUrl, newDuration, newType) => {
    if (newFileId === undefined || newUrl === undefined) {
      return;
    }

    const {
      /** @type {DisplayEditorStore} */ displayEditorStore,
      /** @type {ObservableMap} */ entity,
      /** @type {GameStore} */ game,
    } = this.props;

    const safeDuration = newDuration || 0;
    const safeType = newType || 'video/mp4';

    const compose = entity.get('compose') || {};

    if (compose.variableName) {
      const element = entity.get('element');

      displayEditorStore.setVariable(element, compose.variableName, {
        fileId: newFileId,
        url: newUrl,
        duration: safeDuration,
        type: safeType,
      });
    }

    const sourceVariables = toJS(displayEditorStore.variables);

    const entityUpdates = lodash.filter(game.entities, (gameEntity) => {
      if (gameEntity.get('id') === entity.get('id')) {
        return true;
      }

      if (!gameEntity.has('compose') || gameEntity.get('element') !== 'video') {
        return false;
      } else if (gameEntity.get('compose').variableName !== compose.variableName) {
        return false;
      }

      return true;
    }).reduce((final, updateEntity) => {
      const updateId = updateEntity.get('id');

      const videoSource = getVideoForSource(updateEntity, game);
      const composeSource = getComposeForSource(updateEntity);

      videoSource.video.fileId = newFileId;
      videoSource.video.url = newUrl;
      videoSource.video.duration = safeDuration;
      videoSource.video.type = safeType;

      final[updateId] = getVideoFromSource(
        {...videoSource, ...composeSource},
        sourceVariables
      );
      return final;
    }, {});

    const actionParams = {
      entityId: entity.get('id'),
    };

    // This will rebuild the component for the entities using the new variable value.
    game.addAction(actionParams, actionUpdateVariableComponent(
      entityUpdates
    ));
  };

  /**
   * Triggered when the new video is selected by the modal.
   *
   * @param {{id: number, displayPath: string, placeholderVideo: boolean}} newContent
   */
  @action onVideoSelected = (newContent) => {
    this.isModalOpen = false;

    if (!newContent) {
      return;
    }

    if (newContent.displayPath) {
      this.onChangeVideo(newContent.id, newContent.displayPath, newContent.duration, newContent.mime);
    } else if (newContent.placeholderVideo) {
      this.onChangeVideo(null, null, 0);
    }
  };

  /**
   * Triggered when the video selector modal should open.
   */
  @action onOpenSelector = () => {
    this.isModalOpen = true;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      /** @type {ObservableMap} */ entity,
      /** @type {GameStore} */ game
    } = this.props;

    return (
      <div className="edit-video-controls">
        <div className="video-group">
          <h5>Video</h5>

          <div className="video-selector">
            <div className="video-wrapper">
              <button className="btn btn-link video-change-button" type="button" onClick={this.onOpenSelector}>
                <span className="video-edit-icon">
                  <FontAwesomeIcon icon={faPencil} />
                </span>
              </button>
            </div>
          </div>
        </div>

        <EditComposeControls entity={entity} game={game} />
        <EditPositionControls entity={entity} game={game} />
        <EditAlignmentControls entity={entity} game={game} />
        <EditUnitsControls entity={entity} game={game} />
        <EditTimelineControls entity={entity} game={game} />
        <EditEffectControls entity={entity} game={game} />

        <SelectFileModal
          isOpen={this.isModalOpen}
          onComplete={this.onVideoSelected}
          type={FILE_TYPE_VIDEO}
        />
      </div>
    );
  }
}

EditVideoControls.propTypes = {
  displayEditorStore: PropTypes.object.isRequired,
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(EditVideoControls);
