import interact from 'interactjs/src/index';
import {observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {findDOMNode} from 'react-dom';

import {actionLinePositionComponent} from '../../../../display/components/action/actionLinePositionComponent';
import {getScaleFromElement} from '../../../../utils/dragDropHelper';

/**
 * Moves the entity.
 *
 * @param {ObservableMap} entity
 * @param {GameStore} game
 * @param {{x: number, y: number}} delta
 * @param {{isDrag: boolean, isStart: boolean}} handleOptions
 */
function moveLineHandle(entity, game, delta, handleOptions) {
  let startDelta = null;
  if (handleOptions.isStart || handleOptions.isDrag) {
    startDelta = delta;
  }

  let endDelta = null;
  if (!handleOptions.isStart || handleOptions.isDrag) {
    endDelta = delta;
  }

  game.addAction(
    {entityId: entity.get('id')},
    actionLinePositionComponent(startDelta, endDelta)
  );
}

/**
 * Gets whether or not the entity is active.
 *
 * @param {ObservableMap} entity
 * @returns {boolean}
 */
function getIsActive(entity) {
  const interaction = entity.get('interaction');

  return Boolean(interaction && interaction.isActive);
}

/**
 * A higher order component wrapper that handles making a line entity handles draggable.
 *
 * @param {Object} WrappedComponent
 * @returns {Object}
 */
export default function lineInteractHocWrapper(WrappedComponent) {
  /**
   * The LineInteractHoc higher order component.
   */
  class LineInteractHoc extends React.Component {
    /**
     * Whether or not this entity is currently active.
     * Used to track the change.
     *
     * @type {boolean}
     */
    @observable isActive = false;

    /**
     * The DOM element for the element.
     *
     * @type {HTMLElement}
     */
    @observable domEl = null;

    /**
     * The DOM element for the display element.
     *
     * @type {HTMLElement}
     */
    @observable displayEl = null;

    /**
     * The interactJS object initialized on the DOM element.
     *
     * @type {{draggable: function, resizable: function}}
     */
    @observable interaction = null;

    /**
     * @constructor
     * @param {{}} props
     * @param {{}} componentContext
     */
    constructor(props, componentContext) {
      super(props, componentContext);
    }

    /**
     * Triggered right after the component mounts to the page.
     */
    componentDidMount() {
      this.checkActiveStateChange();
    }

    /**
     * Triggered when the component is about to unmount.
     */
    componentWillUnmount() {
      this.stopDragging();
    }

    /**
     * Triggered when an observed item updates.
     */
    componentWillReact = () => {
      this.checkActiveStateChange();
    };

    /**
     * Checks whether or not the active state has changed and either allows it to be dragged or stops it from
     * dragging.
     */
    checkActiveStateChange = () => {
      const isActive = getIsActive(this.props.entity);

      if (!this.isActive && isActive) {
        this.initInteraction();
        this.isActive = true;
      } else if (this.isActive && !isActive) {
        this.stopDragging();
        this.isActive = false;
      }
    };

    /**
     * Triggered right after the wrapped component is added to OR removed from the page.
     *
     * @param {{}} domEl
     */
    onChangeMount = (domEl) => {
      if (this.domEl) {
        return;
      }

      this.domEl = findDOMNode(domEl);
      this.displayEl = document.getElementById('display-source');
    };

    /**
     * Handles the drag move event.
     *
     * @param {{dx: number, dy: number}} dragEvent
     */
    onMove = (dragEvent) => {
      const {entity, game} = this.props;
      const isDrag = Boolean(this.props.isDrag);
      const isStart = Boolean(this.props.isStart);
      const scale = getScaleFromElement(this.displayEl);

      moveLineHandle(entity, game, {
        x: dragEvent.dx / scale,
        y: dragEvent.dy / scale
      }, {
        isStart,
        isDrag,
      });
    };

    /**
     * Starts the interactJS code.
     */
    initInteraction = () => {
      if (this.interaction) {
        // Make sure we don't have multiple dragging interactions on this element.
        this.interaction.unset();
      }

      const interaction = interact(this.domEl);
      interaction.draggable({
        onmove: this.onMove
      });

      this.interaction = interaction;
    };

    /**
     * Unbinds the dragging code.
     */
    stopDragging = () => {
      if (!this.interaction) {
        return;
      }

      this.interaction.unset();
      this.interaction = null;
    };

    /**
     * Renders the WrappedComponent.
     *
     * @returns {Object}
     */
    render() {
      if (this.props.ref) {
        throw new Error('LineInteractHoc will override ref property given to the wrapped component.');
      }

      return (
        <WrappedComponent
          {...this.props}
          ref={this.onChangeMount}
        />
      );
    }
  }

  LineInteractHoc.propTypes = {
    entity: MobxPropTypes.observableMap.isRequired,
    game: PropTypes.shape({
      setEntityComponents: PropTypes.func,
    }).isRequired,
    isDrag: PropTypes.bool,
    isStart: PropTypes.bool,
    ref: PropTypes.func,
  };

  LineInteractHoc.defaultProps = {
    isStart: false,
    isDrag: false,
  };

  return observer(LineInteractHoc);
}
