import PropTypes from 'prop-types';
import React from 'react';

/**
 * A text highlighter component.
 *
 * @constructor
 */
export class Highlighter extends React.Component {
  /**
   * The text element reference.
   *
   * @type {{current: ?HTMLElement}}
   */
  textRef = React.createRef();

  /**
   * Triggered when the component mounts to the page.
   */
  componentDidMount() {
    this.updateElement();
  }

  /**
   * Triggered when the component updates.
   */
  componentDidUpdate() {
    this.updateElement();
  }

  /**
   * Highlights the text.
   *
   * @param {string} current
   * @returns {string}
   */
  highlightText = (current) => {
    const {highlight, isCaseSensitive, text} = this.props;

    if (!text || !text.length) {
      return current;
    } else if (!highlight || !highlight.length) {
      return current;
    }

    const tag = this.props.tag || 'mark';

    const escapedHighlight = highlight.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
    const tagReplacement = `<${tag}>$&</${tag}>`;

    return text.replace(
      new RegExp(escapedHighlight, isCaseSensitive ? 'g' : 'gi'),
      tagReplacement
    );
  };

  /**
   * Updates the text element with the highlighted text.
   */
  updateElement = () => {
    const textElement = this.textRef.current;
    if (!textElement) {
      return;
    }

    textElement.innerHTML = this.highlightText(textElement.innerHTML);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    return (
      <span className="highlighter" ref={this.textRef} />
    );
  }
}

Highlighter.propTypes = {
  highlight: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,

  isCaseSensitive: PropTypes.bool,
  tag: PropTypes.string
};

Highlighter.defaultProps = {
  isCaseSensitive: false,
  tag: 'mark'
};

export default Highlighter;
