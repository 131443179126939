import {action} from 'mobx';

import ApiBaseStore from '../common/apiBaseStore';
import {STATE_PENDING, STATE_FULFILLED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';

/**
 * Creates a new file folder.
 */
class ApiFileFolderCreateStore extends ApiBaseStore {
  /**
   * Creates a new file folder using the api.
   *
   * @param {string} folderName
   * @param {string} folderType
   */
  @action makeRequest(folderName, folderType) {
    const safeFolderName = String(folderName).trim();
    const safeFolderType = String(folderType).trim().toLowerCase();

    this.state = STATE_PENDING;
    this.data = null;
    this.error = null;
    this[EXPIRE_TIME] = Date.now() + EXPIRES_PENDING;

    serverApi.fileFolderCreate({
      name: safeFolderName,
      type: safeFolderType
    }).then(
      action('fileFolderCreateSuccess', (newFolderId) => {
        this.state = STATE_FULFILLED;
        this.data = newFolderId;
        this.error = null;
        this[EXPIRE_TIME] = Date.now() + EXPIRES_IN;
      })
    ).catch(
      action('fileFolderCreateError', (folderCreateError) => {
        this.state = STATE_FULFILLED;
        this.data = null;
        this.error = folderCreateError;
      })
    );
  }
}

export default new ApiFileFolderCreateStore();
