import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';

import TransitionListItem from '../transitionListItem/TransitionListItem';

import './transitionList.scss';

/**
 * The TransitionList component.
 */
export class TransitionList extends React.Component {
  onAddNew = () => {
    const {onCreate} = this.props;

    onCreate();
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      activeId,
      transitions,
      onActivate,
      onCreate,
      onDelete,
      onRename
    } = this.props;

    return (
      <div className="transition-list">
        <div className="transition-list-group">
          {transitions.map((transition, index) => {
            const isLast = (index === (transition.length - 1));
            return (
              <TransitionListItem
                key={transition.id}
                isActive={Boolean(transition.id === activeId)}
                isLast={isLast}
                onActivate={onActivate}
                onCreate={onCreate}
                onDelete={onDelete}
                onRename={onRename}
                transition={transition}
                transitionOrder={index + 1}
              />
            );
          })}

          <a
            className="transition-list-item list-group-item list-group-item-action"
            onClick={this.onAddNew}
          >
            <span className="transition-order">
              <FontAwesomeIcon
                icon={faPlus}
                fixedWidth={true}
              />
            </span>
            <span className="transition-name">Create New</span>
          </a>
        </div>
      </div>
    );
  }
}

TransitionList.propTypes = {
  onActivate: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  transitions: PropTypes.arrayOf(PropTypes.object).isRequired,

  activeId: PropTypes.string,
};

export default observer(TransitionList);
