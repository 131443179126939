import lodash from 'lodash';

import {EASINGS} from '../../ecs/easingHelper';
import letterFadePositionInBottomGif from '../../../assets/images/transitions/letterFadePositionInBottom.gif';

/**
 * The name that signals this preset.
 * @const {string}
 */
export const PRESET_LETTER_FADE_POSITION_IN_BOTTOM = 'letterFadePositionInBottom';

/**
 * The preset details.
 *
 * @type {{
 *   name: string,
 *   flow: string,
 *   details: Object.<string, {
 *     easing: string,
 *     parse: boolean,
 *     values: *|Array.<*>,
 *     time: {start: (string|number), end: (string|number)}
 *   }>,
 *   template: {}
 * }}
 */
export const letterFadePositionInBottomPresetDetails = {
  // The name of the preset.
  name: PRESET_LETTER_FADE_POSITION_IN_BOTTOM,

  // A human friendly name for the preset.
  humanName: 'Letter - Fade & Position In From Bottom',

  // A preview of the transition.
  gif: letterFadePositionInBottomGif,

  // The element names that this preset can be used for or true for all elements.
  elements: ['text'],

  // The preset direction: in, out, middle. This is used in the UI logic.
  flow: 'in',

  // Each property that will change during the transition.
  details: {
    'letter.opacity': {
      time: {
        end: 'start.800',
        start: 'start.0'
      },
      easing: EASINGS.easeOutExpo,
      values: [
        0,
        1
      ],
      stagger: 30,
      staggerDuration: false
    },
    'letter.translateY': {
      time: {
        end: 'start.800',
        start: 'start.0'
      },
      easing: EASINGS.easeOutExpo,
      values: [
        150,
        0
      ],
      stagger: 30
    },
  },

  // How this preset can be used in the source (including optional parameters).
  // This will be turned into a JSON string, so no functions can be used.
  template: {
    preset: PRESET_LETTER_FADE_POSITION_IN_BOTTOM,
    options: {},
  },
};

letterFadePositionInBottomPresetDetails.template.options = lodash.cloneDeep(letterFadePositionInBottomPresetDetails.details);

// This defines the key in the presets object.
export const presetName = PRESET_LETTER_FADE_POSITION_IN_BOTTOM;

// This defines the value of the presets object.
export default letterFadePositionInBottomPresetDetails;
