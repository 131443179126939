import {action} from 'mobx';

import ApiBaseStore from '../common/apiBaseStore';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';

/**
 * ApiSignupStore
 */
class ApiSignupStore extends ApiBaseStore {
  /**
   * Posts the user signup to the server.
   *
   * @param {{}} body
   * @param {string} body.name
   * @param {string} body.email
   * @param {string} body.password
   */
  @action makeRequest(body) {
    this.state = STATE_PENDING;
    this[EXPIRE_TIME] = Date.now() + EXPIRES_PENDING;

    serverApi.userCreate(body).then(
      action('postSignupSuccess', (response) => {
        this.state = STATE_FULFILLED;
        this.data = (response) ? response.id : null;
        this.error = null;
        this[EXPIRE_TIME] = Date.now() + EXPIRES_IN;
      }),
      action('postSignupError', (signupError) => {
        this.state = STATE_REJECTED;
        this.data = null;
        this.error = signupError;
      })
    );
  }
}

export default new ApiSignupStore();
