import lodash from 'lodash';
import {toJS} from 'mobx';

/**
 * The default blank drop shadow object.
 * @type {{blur: number, color: string, x: number, y: number}}
 */
export const DEFAULT_DROP_SHADOW = {
  blur: 0,
  color: '#000',
  x: 0,
  y: 0,
};

/**
 * The feedIcon component.
 *
 * @param {string} url
 * @param {{}} feedIconData
 * @param {number} feedIconData.opacity
 * @param {{}=} feedIconData.dropShadow
 * @returns {{feedicon: {url: string}}}
 */
export function feedIconComponent(url, feedIconData = {}) {
  let safeUrl = (!url || url === 'null') ? null : String(url);

  const opacity = (feedIconData.opacity === undefined) ? 1 : feedIconData.opacity;
  const dropShadow = lodash.defaults(
    lodash.pick(feedIconData.dropShadow, lodash.keys(DEFAULT_DROP_SHADOW)),
    DEFAULT_DROP_SHADOW
  );

  return {
    feedicon: {
      type: feedIconData.type,
      url: safeUrl,
      dropShadow,
      opacity,
      default: {
        dropShadow,
        opacity,
      },
      iconPack: feedIconData.iconPack,
      iconPackUrl: feedIconData.iconPackUrl,
    },
  };
}

/**
 * Gets the feedIcon component from the source item.
 *
 * @param {{feedicon: {url: string}}} item
 * @param {{}=} variables
 * @returns {{feedicon: {url: string}}}
 */
export function getFeedIconFromSource(item, variables) {
  if (!item.feedicon) {
    return {};
  }

  const feedicon = item.feedicon;

  let url = feedicon.url;

  const canEdit = lodash.get(item, 'compose.canEdit');
  const variableName = lodash.get(item, 'compose.variableName');
  if (canEdit && variableName) {
    const variableValue = lodash.get(variables, `feedicon.${variableName}`, '');

    if (variableValue && lodash.isString(variableValue)) {
      // The old deprecated variable style is a string with | delimiters.
      [url] = lodash.split(variableValue, '|', 2);
    } else if (variableValue) {
      // The ()s fix an eslint parsing error.
      ({url} = variableValue);
    }
  }

  return feedIconComponent(
    url,
    {
      type: feedicon.type,
      dropShadow: feedicon.dropShadow || undefined,
      opacity: feedicon.opacity,
      iconPack: feedicon.iconPack,
      iconPackUrl: feedicon.iconPackUrl,
    }
  );
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {{}} entity
 * @returns {{}}
 */
export function getFeedIconForSource(entity) {
  if (!entity.has('feedicon')) {
    return {};
  }

  const compose = entity.get('compose');
  const feedicon = entity.get('feedicon');

  const outFeedIcon = {
  };
  if (feedicon.type) {
    outFeedIcon.type = feedicon.type;
  }
  if (feedicon.dropShadow) {
    outFeedIcon.dropShadow = toJS(feedicon.dropShadow);
  }
  if (feedicon.opacity !== undefined) {
    outFeedIcon.opacity = feedicon.opacity;
  }
  if (feedicon.iconPack !== undefined) {
    outFeedIcon.iconPack = feedicon.iconPack;
  }
  if (feedicon.iconPackUrl !== undefined) {
    outFeedIcon.iconPackUrl = feedicon.iconPackUrl;
  }

  const canEdit = lodash.get(compose, 'canEdit', false);
  const variableName = lodash.get(compose, 'variableName');
  if (!canEdit || !variableName) {
    outFeedIcon.url = feedicon.url;
  }

  return {
    feedicon: outFeedIcon,
  };
}

