import lodash from 'lodash';
import React, {Component} from 'react';
import Slider from 'react-slick';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';

import inject from '../../../../hoc/injectHoc';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

/**
 * The BannerCarousel component.
 */
class BannerCarousel extends Component {
  /**
   * Renders the carousel slides.
   *
   * @param {Array<{}>} banners
   * @returns {{}}
   */
  renderSlides = (banners) => {
    return banners.map((item) => {
      let img = (
        <img className="banner-image" src={item.displayPath} />
      );

      let slide = !item.url ? img : (
        <a href={item.url} target="_blank" rel="noopener noreferrer">
          {img}
        </a>
      );

      return (
        <div className="banner" key={item.id}>
          {slide}
        </div>
      );
    });
  }

  /**
   * Renders the component.
   *
   * @param {Array<{}>} banners
   * @returns {{}}
   */
  renderBannerCarousel = (banners) => {
    const {apiUserGetMeStore} = this.props;

    // filter for published banners
    const publishedBanners = banners.filter((item) => item.isPublished);
    const groupedBanners = lodash.groupBy(publishedBanners, 'type');

    const settings = {
      dots: false,
      autoplay: true,
      autoplaySpeed: 12000,
      infinite: true,
      arrows: false,
    };

    return apiUserGetMeStore.case({
      pre: () => null,
      pending: () => null,
      rejected: () => (
        <Slider {...settings}>
          {this.renderSlides(groupedBanners.public || [])}
        </Slider>
      ),
      fulfilled: () => (
        <Slider {...settings}>
          {this.renderSlides(groupedBanners.user || [])}
        </Slider>
      )
    });
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {apiBannerGetAllStore} = this.props;

    return apiBannerGetAllStore.case({
      pre: () => null,
      pending: () => null,
      fulfilled: this.renderBannerCarousel,
      rejected: () => null,
    });
  }
}

BannerCarousel.propTypes = {
  apiBannerGetAllStore: MobxPropTypes.observableObject,
  apiUserGetMeStore: MobxPropTypes.observableObject,
};

BannerCarousel.wrappedComponent = {};
BannerCarousel.wrappedComponent.propTypes = {
  apiBannerGetAllStore: MobxPropTypes.observableObject.isRequired,
  apiUserGetMeStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(BannerCarousel)(
  observer(BannerCarousel)
);
