import uuid from 'uuid/v4';

/**
 * The group component.
 *
 * @param {number} groupId
 * @returns {{group: {id: number}}}
 */
export function groupComponent(groupId) {
  return {
    group: {
      id: groupId || uuid(),
    },
  };
}

/**
 * Gets the group component from the source item.
 *
 * @param {{group: {id: number}}} item
 * @returns {{}}
 */
export function getGroupFromSource(item) {
  if (item.group && item.group.id) {
    return groupComponent(item.group.id);
  }
  return {};
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {ObservableMap} entity
 * @returns {{}}
 */
export function getGroupForSource(entity) {
  if (!entity.has('group')) {
    return {};
  }

  const group = entity.get('group');
  if (!group || !group.id) {
    return {};
  }

  return groupComponent(
    group.id
  );
}
