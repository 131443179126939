/* eslint-disable no-magic-numbers */

import lodash from 'lodash';

import {EASINGS} from '../../ecs/easingHelper';
import wordFadeInGif from '../../../assets/images/transitions/wordFadeIn.gif';

/**
 * The name that signals this preset.
 * @const {string}
 */
export const PRESET_WORD_FADE_IN = 'wordFadeIn';

/**
 * The preset details.
 *
 * @type {{
 *   name: string,
 *   flow: string,
 *   details: Object.<string, {
 *     easing: string,
 *     parse: boolean,
 *     values: *|Array.<*>,
 *     time: {start: (string|number), end: (string|number)}
 *   }>,
 *   template: {}
 * }}
 */
export const wordFadeInPresetDetails = {
  // The name of the preset.
  name: PRESET_WORD_FADE_IN,

  // A human friendly name for the preset.
  humanName: 'Word - Fade In',

  // A preview of the transition.
  gif: wordFadeInGif,

  // The element names that this preset can be used for or true for all elements.
  elements: ['text'],

  // The preset direction: in, out, middle. This is used in the UI logic.
  flow: 'in',

  // Each property that will change during the transition.
  details: {
    'word.opacity': {
      time: {
        end: 'start.596',
        start: 'start.0'
      },
      easing: EASINGS.easeInPower4,
      values: [
        0,
        1
      ],
      stagger: 600,
      staggerDuration: false,
      immediateRender: true
    },
  },

  // How this preset can be used in the source (including optional parameters).
  // This will be turned into a JSON string, so no functions can be used.
  template: {
    preset: PRESET_WORD_FADE_IN,
    options: {},
  },
};

wordFadeInPresetDetails.template.options = lodash.cloneDeep(wordFadeInPresetDetails.details);

// This defines the key in the presets object.
export const presetName = PRESET_WORD_FADE_IN;

// This defines the value of the presets object.
export default wordFadeInPresetDetails;

