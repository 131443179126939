import React from 'react';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import lodash from 'lodash';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLock, faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';

import EditTimeControl from '../editTime/EditTimeControl';
import EditTransitionControl from '../editTransition/EditTransitionControl';

import './editTimelineControls.scss';

/**
 * The EditTimelineControls component.
 */
export class EditTimelineControls extends React.Component {
  /**
   * Whether or not the timeline control section is open.
   *
   * @type {boolean}
   */
  @observable isControlGroupOpen = false;

  /**
   * @constructor
   * @param {{}} props
   * @param {{}} componentContext
   */
  constructor(props, componentContext) {
    super(props, componentContext);
  }

  @action onToggleControlGroup = () => {
    this.isControlGroupOpen = !this.isControlGroupOpen;
  }

  /**
   * Render the timeline controls if the section is expanded
   * @returns {{}}
   */
  renderTimelineControls() {
    const {
      /** @type {ObservableMap} */ entity,
      /** @type {GameStore} */ game
    } = this.props;

    if (!this.isControlGroupOpen) {
      return null;
    }

    let isLocked = false;
    if (entity.has('locked')) {
      const entityLock = entity.get('locked');
      if (lodash.includes(entityLock, 'time')) {
        // If the entity has time locked, then do not allow them to adjust anything.
        isLocked = true;
      }
    }
    return ((isLocked) ? (
      <div className="can-not-edit">
        <span className="locked-text">
          <FontAwesomeIcon
            className="mr-2"
            icon={faLock}
          />
          <span>Time Locked</span>
        </span>
      </div>
    ) : (
      <div className="group-controls">
        <div className="row">
          <div className="col">
            <EditTimeControl entity={entity} game={game} />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <EditTransitionControl entity={entity} game={game} />
          </div>
        </div>
      </div>
    ));
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      /** @type {GameStore} */ game
    } = this.props;

    if (!game.hasTimeLine()) {
      // If the game does not have a timeline, then always hide these control.
      return null;
    } else if (game.composeMode) {
      // Compose mode disables most editing abilities.
      return null;
    }

    return (
      <div className="edit-timeline-controls control-group">
        <div className="group-header">
          <span className="group-header-label">Transitions</span>
          <button
            type="button"
            className="btn"
            onClick={this.onToggleControlGroup}
          >
            <FontAwesomeIcon icon={this.isControlGroupOpen ? faChevronUp : faChevronDown} />
          </button>
        </div>
        {this.renderTimelineControls()}
      </div>
    );
  }
}

EditTimelineControls.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(EditTimelineControls);
