import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader, Alert} from 'reactstrap';
import {observable, computed, action} from 'mobx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleNotch} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import inject from '../../hoc/injectHoc';
import {STATE_FULFILLED, STATE_PENDING, STATE_REJECTED} from '../../../constants/asyncConstants';
import {GENERATE_IMAGE_HOLIDAY_THEMES, GENERATE_IMAGE_INDUSTRY_THEMES, GENERATE_IMAGE_RELIGIOUS_THEMES, GENERATE_IMAGE_STYLES} from './options';
import PreviewImageModal from '../previewImageModal/PreviewImageModal';

import './generateImageModal.scss';

/**
 * The GenerateImageModal component.
 */
class GenerateImageModal extends React.Component {
  /**
   * form data
   *
   * @type {Object}
   */
  @observable form = {
    subject: '',
    style: '',
    holidayTheme: '',
    industryTheme: '',
    religiousTheme: '',
  };

  /**
   * url to preview
   *
   * @type {string|null}
   */
  @observable selectedUrl = null;

  /**
   * Tracks if the form is disabled
   *
   * @returns {boolean}
   */
  @computed get isSubmitDisabled() {
    const {apiGenerateImageStore} = this.props;

    return Boolean(this.form.subject === '' || apiGenerateImageStore.getState() === STATE_PENDING);
  }

  /**
   * Tracks if the cancel button should be disabled
   *
   * @returns {boolean}
   */
  @computed get isCancelDisabled() {
    const {apiGenerateImageStore} = this.props;

    return Boolean(apiGenerateImageStore.getState() === STATE_PENDING);
  }

  /**
   * input & select onChange handler
   *
   * @param {{}} changeEvent
   */
  @action onChange = (changeEvent) => {
    const target = changeEvent.target;
    const value = target.value;

    if (typeof this.form[target.name] !== 'undefined') {
      this.form[target.name] = value;
    }
  };

  /**
   * Submit form event
   *
   * @param {{}} submitEvent
   */
  @action onSubmit = (submitEvent) => {
    const {apiGenerateImageStore} = this.props;

    submitEvent.preventDefault();

    const themes = [
      this.form.holidayTheme,
      this.form.industryTheme,
      this.form.religiousTheme,
    ].filter((theme) => !!theme);

    apiGenerateImageStore.makeRequest(this.form.subject, this.form.style, themes);
  }

  /**
   * On preview image click
   *
   * @param {string} url
   */
  @action onImagePreviewClick = (url) => {
    this.selectedUrl = url;
  }

  /**
   * On hide preview modal
   */
  @action onHidePreviewImageModal = () => {
    this.selectedUrl = null;
  }

  /**
   * On use image from preview modal
   *
   * @param {string} url
   */
  @action onUseImage = (url) => {
    const {onImageSelect} = this.props;
    this.onHidePreviewImageModal();
    onImageSelect(url);
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {isOpen, onCancel, apiGenerateImageStore} = this.props;

    const areResultsOrErrorAvailable = apiGenerateImageStore.getState() === STATE_FULFILLED || apiGenerateImageStore.getState() === STATE_REJECTED;

    return (
      <Modal
        className="generate-image-modal"
        isOpen={isOpen}
        backdrop={true}
        toggle={onCancel}
        size={'lg'}
      >
        <ModalHeader className="text-dark-blue">
          Generate content for your sign
        </ModalHeader>
        <ModalBody>
          <h5 className="mb-3">Image Generation</h5>
          <form
            id="generate-image-form"
            onSubmit={this.onSubmit}
          >
            <div className="row">
              <div
                className={classNames(
                  'col-12',
                  {
                    'col-md-8': areResultsOrErrorAvailable,
                  }
                )}
              >
                <div className="form-group">
                  <label htmlFor="generate-image-subject">Subject</label>
                  <input
                    id="generate-image-subject"
                    name="subject"
                    className="form-control form-control-sm"
                    aria-describedby="generate-image-subject-help"
                    value={this.form.subject}
                    onChange={this.onChange}
                  />
                  <small
                    id="generate-image-subject-help"
                    className="form-text text-muted"
                  >
                    The subject is what you want to see in the image.
                  </small>
                </div>

                <div className="form-group">
                  <label htmlFor="generate-image-style">Style</label>
                  <select
                    id="generate-image-style"
                    name="style"
                    className="form-control form-control-sm"
                    aria-describedby="generate-image-style-help"
                    value={this.form.style}
                    onChange={this.onChange}
                  >
                    <option value="">-</option>
                    {GENERATE_IMAGE_STYLES.map((value) => (
                      <option
                        key={value}
                        value={value}
                      >
                        {value}
                      </option>
                    ))}
                  </select>
                  <small
                    id="generate-image-style-help"
                    className="form-text text-muted"
                  >
                    The style refers to the artistic style of the image.
                  </small>
                </div>

                <div className="form-group">
                  <label htmlFor="generate-image-holiday-theme">Holiday Themes</label>
                  <select
                    id="generate-image-holiday-theme"
                    name="holidayTheme"
                    className="form-control form-control-sm"
                    value={this.form.holidayTheme}
                    onChange={this.onChange}
                  >
                    <option value="">-</option>
                    {GENERATE_IMAGE_HOLIDAY_THEMES.map((value) => (
                      <option
                        key={value}
                        value={value}
                      >
                        {value}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="generate-image-industry-theme">Industry Themes</label>
                  <select
                    id="generate-image-industry-theme"
                    name="industryTheme"
                    className="form-control form-control-sm"
                    value={this.form.industryTheme}
                    onChange={this.onChange}
                  >
                    <option value="">-</option>
                    {GENERATE_IMAGE_INDUSTRY_THEMES.map((value) => (
                      <option
                        key={value}
                        value={value}
                      >
                        {value}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="generate-image-religious-theme">Religious Themes</label>
                  <select
                    id="generate-image-religious-theme"
                    name="religiousTheme"
                    className="form-control form-control-sm"
                    value={this.form.religiousTheme}
                    onChange={this.onChange}
                  >
                    <option value="">-</option>
                    {GENERATE_IMAGE_RELIGIOUS_THEMES.map((value) => (
                      <option
                        key={value}
                        value={value}
                      >
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {apiGenerateImageStore.case({
                fulfilled: (data) => {
                  const results = data && data.result && data.result.generatedPictures
                    ? data.result.generatedPictures
                    : [];
                  return (
                    <div className="col-12 col-md-4">
                      <div className="mb-1">
                        <h6 className="mb-0">Results</h6>
                        <small>(Click image to see preview)</small>
                      </div>
                      {(results.length !== 0)
                        ? (
                          <div className="row image-preview-row">
                            {results.map((imageUrl) => (
                              <div
                                key={imageUrl}
                                className="col col-6 col-sm-12"
                              >
                                <img
                                  className="cursor-pointer"
                                  src={imageUrl}
                                  onClick={() => this.onImagePreviewClick(imageUrl)}
                                />
                              </div>
                            ))}
                          </div>
                        )
                        : (
                          <span className="text-muted">No results found. Please try different parameters.</span>
                        )}
                    </div>
                  );
                },
                rejected: () => (
                  <div className="col-12 col-md-4">
                    <Alert
                      className="mb-0"
                      color="danger"
                    >
                      There was an error generating the image. Please try again.
                    </Alert>
                  </div>
                ),
              })}

              {(this.selectedUrl) && (
                <PreviewImageModal
                  isOpen={true}
                  onCancel={this.onHidePreviewImageModal}
                  onSubmitClick={this.onUseImage}
                  url={this.selectedUrl}
                />
              )}
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-light"
            type="button"
            onClick={onCancel}
            disabled={this.isCancelDisabled}
          >
            Cancel
          </button>
          <button
            className="btn btn-dark-blue"
            type="submit"
            form="generate-image-form"
            disabled={this.isSubmitDisabled}
          >
            {(apiGenerateImageStore.getState() === STATE_PENDING)
              ? (<>
                <FontAwesomeIcon
                  className="mr-2"
                  spin
                  icon={faCircleNotch}
                />
                Generating
              </>)
              : (<>Generate</>)
            }
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

GenerateImageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onImageSelect: PropTypes.func.isRequired,
  apiGenerateImageStore: MobxPropTypes.observableObject,
};

GenerateImageModal.wrappedComponent = {};
GenerateImageModal.wrappedComponent.propTypes = {
  apiGenerateImageStore: MobxPropTypes.observableObject,
};

export default inject(GenerateImageModal)(
  observer(GenerateImageModal)
);
