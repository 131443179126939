import lodash from 'lodash';

/**
 * The percent keyword.
 * @const {string}
 */
export const PERCENT = 'percent';

/**
 * The pixel keyword.
 * @const {string}
 */
export const PIXEL = 'pixel';

/**
 * The action align component.
 *
 * @param {?{width: ?string, height: ?string}} sizeUnits
 * @param {?{x: ?string, y: ?string}} positionUnits
 * @returns {{actionUnit: {size: ?{width: ?string, height: ?string}, position: ?{x: ?string, y: ?string}}}}
 */
export function actionUnitComponent(sizeUnits, positionUnits) {
  let safeSizeUnits = lodash.pick(sizeUnits || {}, ['height', 'width']);
  let safePositionUnits = lodash.pick(positionUnits || {}, ['x', 'y']);

  if (!lodash.size(sizeUnits)) {
    safeSizeUnits = null;
  }
  if (!lodash.size(safePositionUnits)) {
    safePositionUnits = null;
  }

  return {
    actionUnit: {
      size: safeSizeUnits,
      position: safePositionUnits,
    },
  };
}
