import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

import CustomIcon from '../customIcon/CustomIcon';

import './unsplashButton.scss';

/**
 * The UnsplashButton component.
 */
export class UnsplashButton extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {className, onClick, style} = this.props;

    return (
      <button
        className={classNames('btn btn-sm btn-outline-dark font-weight-bold d-flex align-items-center justify-content-between', className)}
        type="button"
        onClick={onClick}
        style={style}
        gtm-id="search-unsplash-images"
      >
        <span>
          <CustomIcon
            type="unsplash"
            style={{
              width: '24px',
              height: '24px',
            }}
            className="mr-2"
          />
          Unsplash
        </span>
        {/* marginRight so Beta button lines up with AI button beta tags */}
        <span
          style={{
            marginRight: '4px',
          }}
          className="badge badge-dark ml-2 text-uppercase"
        >
          Beta
        </span>
      </button>
    );
  }
}

UnsplashButton.propTypes = {
  className: propTypes.string,
  onClick: propTypes.func,
  style: propTypes.object,
};

export default UnsplashButton;
