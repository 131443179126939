/* eslint-disable no-magic-numbers */

import lodash from 'lodash';

import {EASINGS} from '../../ecs/easingHelper';

/**
 * The name that signals this preset.
 * @const {string}
 */
export const PRESET_WORD_METEOR_IN = 'wordMeteorIn';

/**
 * The preset details.
 *
 * @type {{
 *   name: string,
 *   flow: string,
 *   details: Object.<string, {
 *     easing: string,
 *     parse: boolean,
 *     values: *|Array.<*>,
 *     time: {start: (string|number), end: (string|number)}
 *   }>,
 *   template: {}
 * }}
 */
export const wordMeteorInPresetDetails = {
  // The name of the preset.
  name: PRESET_WORD_METEOR_IN,

  // A human friendly name for the preset.
  humanName: 'Word - Meteor In',

  // The element names that this preset can be used for or true for all elements.
  elements: ['text'],

  // The preset direction: in, out, middle. This is used in the UI logic.
  flow: 'in',

  // Each property that will change during the transition.
  details: {
    'word.opacity': {
      // The name of the easing this property will use.
      easing: EASINGS.easeOutExpo,

      // The start and end times for the transition of this property.
      time: {
        start: 'start.0',
        end: 'start.2000',
      },

      // The value of the property that will change during the transition.
      values: [0, 1],

      // Whether or not to stagger the transition for each text or the stagger delay in milliseconds.
      stagger: true,

      // The amount of time in milliseconds that each text will animate, or false to auto-calculate.
      staggerDuration: false,
    },
    'word.translateY': {
      // The name of the easing this property will use.
      easing: EASINGS.easeOutExpo,

      // The start and end times for the transition of this property.
      time: {
        start: 'start.0',
        end: 'start.2000',
      },

      // The value of the property that will change during the transition.
      values: [-100, 0],

      // Whether or not to stagger the transition for each text or the stagger delay in milliseconds.
      stagger: true,

      // The amount of time in milliseconds that each text will animate, or false to auto-calculate.
      staggerDuration: false,
    },
    'word.translateX': [{
      // The name of the easing this property will use.
      easing: EASINGS.easeOutExpo,

      // The start and end times for the transition of this property.
      time: {
        start: 'start.0',
        end: 'start.2000',
      },

      // The value of the property that will change during the transition.
      values: [-100, 0],

      // Optional filters such as even, odd, etc.
      filters: ['even'],

      // Whether or not to stagger the transition for each text or the stagger delay in milliseconds.
      stagger: true,

      // The amount of time in milliseconds that each text will animate, or false to auto-calculate.
      staggerDuration: false,
    }, {
      // The name of the easing this property will use.
      easing: EASINGS.easeOutExpo,

      // The start and end times for the transition of this property.
      time: {
        start: 'start.0',
        end: 'start.2000',
      },

      // The value of the property that will change during the transition.
      values: [100, 0],

      // Optional filters such as even, odd, etc.
      filters: ['odd'],

      // Whether or not to stagger the transition for each text or the stagger delay in milliseconds.
      stagger: true,

      // The amount of time in milliseconds that each text will animate, or false to auto-calculate.
      staggerDuration: false,
    }],
  },

  // How this preset can be used in the source (including optional parameters).
  // This will be turned into a JSON string, so no functions can be used.
  template: {
    preset: PRESET_WORD_METEOR_IN,
    options: {},
  },
};

wordMeteorInPresetDetails.template.options = lodash.cloneDeep(wordMeteorInPresetDetails.details);

// This defines the key in the presets object.
export const presetName = PRESET_WORD_METEOR_IN;

// This defines the value of the presets object.
export default wordMeteorInPresetDetails;
