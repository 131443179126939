import {Editor, EditorState, Modifier, SelectionState} from 'draft-js';
import keyCommandPlainBackspace from 'draft-js/lib/keyCommandPlainBackspace';
import keyCommandBackspaceWord from 'draft-js/lib/keyCommandBackspaceWord';
import keyCommandBackspaceToStartOfLine from 'draft-js/lib/keyCommandBackspaceToStartOfLine';
import keyCommandPlainDelete from 'draft-js/lib/keyCommandPlainDelete';
import keyCommandDeleteWord from 'draft-js/lib/keyCommandDeleteWord';
import lodash from 'lodash';
import {action, observable, toJS} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import 'draft-js/dist/Draft.css';

import EditorSidebarTitle from '../../common/editorSidebarTitle/EditorSidebarTitle';
import AdvancedTextControls from './components/advancedTextControls/AdvancedTextControls';
import AlignButtons from './components/alignButtons/AlignButtons';
import ColorSelect from './components/colorSelect/ColorSelect';
import FontSelect from './components/fontSelect/FontSelect';
import LetterSpacingSelect from './components/letterSpacingSelect/LetterSpacingSelect';
import LineHeightSelect from './components/lineHeightSelect/LineHeightSelect';
import StyleButtons from './components/styleButtons/StyleButtons';
import {customStylesMap, styleToClassMap} from './constants/styleConstants';
import {stateToMarkdown} from './utils/stateToMarkdown';
import {stateFromHtml} from './utils/stateFromHtml';
import EditAlignmentControls from '../editAlignment/EditAlignmentControls';
import EditComposeControls from '../editCompose/EditComposeControls';
import EditEffectControls from '../editEffect/EditEffectControls';
import EditPositionControls from '../editPosition/EditPositionControls';
import EditTimelineControls from '../editTimeline/EditTimelineControls';
import EditUnitsControls from '../editUnits/EditUnitsControls';
import {actionUpdateVariableComponent} from '../../../display/components/action/actionUpdateVariableComponent';
import {getComposeForSource} from '../../../display/components/common/composeComponent';
import GenerateTextButton from '../../common/generateTextButton/GenerateTextButton';
import {
  getTextForSource,
  getTextFromSource,
  parseMarkdownToHtml
} from '../../../display/components/type/textComponent';
import GenerateTextModal from '../../modals/generateTextModal/GenerateTextModal';

import './editTextControls.scss';

/**
 * The EditTextControls component.
 */
export class EditTextControls extends React.Component {
  /**
   * The rich text editor state.
   *
   * @type {?EditorState}
   */
  @observable textState = null;

  /**
   * The variable name when the text state was built.
   *
   * @type {?string}
   */
  @observable activeVariableName = null;

  /**
   * flag if generate text modal is open
   *
   * @type {boolean}
   */
  @observable isGenerateTextModalOpen = false;

  /**
   * @constructor
   * @param {{}} props
   * @param {{}} componentContext
   */
  constructor(props, componentContext) {
    super(props, componentContext);

    let text = {};
    if (this.props.entity && this.props.entity.has('text')) {
      text = this.props.entity.get('text');
    }

    this.buildTextState(text);
  }

  /**
   * Triggered when the component has just updated.
   *
   * @param {{}} prevProps
   */
  componentDidUpdate(prevProps) {
    let variableNameChanged = false;
    if (this.props.entity && this.props.entity.get('text').variableName !== this.activeVariableName) {
      variableNameChanged = true;
    }

    let idChanged = false;
    if (prevProps.entity && this.props.entity && prevProps.entity.get('id') !== this.props.entity.get('id')) {
      idChanged = true;
    }

    if (!idChanged && !variableNameChanged) {
      return;
    }

    let text = {};
    if (this.props.entity && this.props.entity.has('text')) {
      text = this.props.entity.get('text');
    }

    this.buildTextState(text);
  }

  /**
   * Builds a new text state.
   *
   * @param {{isHtml: boolean, value: string, rawValue: string}} text
   */
  @action buildTextState = (text) => {
    const textMarkdown = String(text.markdown || '');
    const startingContent = stateFromHtml(parseMarkdownToHtml(textMarkdown));

    this.textState = EditorState.createWithContent(startingContent);

    const initialStyles = this.props.entity.get('initialEditTextControlStyles');
    if (!initialStyles) {
      // save initial styles for later use
      const currentInlineStyles = this.textState.getCurrentInlineStyle();
      this.props.entity.set('initialEditTextControlStyles', currentInlineStyles);
    }

    this.activeVariableName = text.variableName;

    // This can cause issues with undo/redo if the markdown items change order.
    // I have added new code to sort the items, but any older text will cause instant redo purge.
    this.selectAll();
  };

  /**
   * Selects all text in the text editor.
   */
  @action selectAll = () => {
    const currentSelection = this.textState.getSelection();
    const currentContent = this.textState.getCurrentContent();

    const firstBlock = currentContent.getFirstBlock();
    const lastBlock = currentContent.getLastBlock();
    const newSelection = currentSelection.merge({
      anchorKey: firstBlock.get('key'),
      anchorOffset: 0,
      focusKey: lastBlock.get('key'),
      focusOffset: lastBlock.get('text').length,
      hasFocus: true,
    });

    let newEditorState = EditorState.acceptSelection(this.textState, newSelection);
    newEditorState = EditorState.forceSelection(newEditorState, newEditorState.getSelection());
    this.textState = newEditorState;
  };

  /**
   * Whether or not the editor has any text selected.
   *
   * @returns {boolean}
   */
  @action hasSelection = () => {
    const currentSelection = this.textState.getSelection();

    if (!currentSelection) {
      return false;
    }

    if (currentSelection.get('anchorKey') !== currentSelection.get('focusKey')) {
      return true;
    } else if (currentSelection.get('anchorOffset') !== currentSelection.get('focusOffset')) {
      return true;
    }

    return false;
  };

  /**
   * Forces focus on the currently selected item.
   *
   * @returns {EditorState}
   */
  @action forceSelectionFocus = () => {
    const currentSelection = this.textState.getSelection();

    if (!currentSelection) {
      return this.textState;
    } else if (currentSelection.get('hasFocus')) {
      return this.textState;
    }

    const newSelection = currentSelection.merge({
      hasFocus: true,
    });

    let newEditorState = EditorState.acceptSelection(this.textState, newSelection);
    newEditorState = EditorState.forceSelection(newEditorState, newEditorState.getSelection());
    this.textState = newEditorState;

    return this.textState;
  };

  /**
   * Selects all the text if there is no selection.
   *
   * @param {boolean=} doNotForce
   * @returns {EditorState}
   */
  selectAllIfNoSelection = (doNotForce) => {
    if (!this.hasSelection()) {
      this.selectAll();
    } else if (!doNotForce) {
      this.forceSelectionFocus();
    }
    return this.textState;
  };

  /**
   * Updates the text state when it is changed.
   *
   * @param {EditorState} editorState
   */
  @action onChangeTextState = (editorState) => {
    const {
      /** @type {DisplayEditorStore} */ displayEditorStore,
      /** @type {ObservableMap} */ entity,
      /** @type {GameStore} */ game,
    } = this.props;

    // set new editor state
    this.textState = editorState;

    const currentMarkdown = entity.get('text').markdown;
    const newMarkdown = stateToMarkdown(editorState);

    if (currentMarkdown === newMarkdown) {
      return;
    }

    const compose = entity.get('compose') || {};
    if (compose.variableName) {
      const element = entity.get('element');

      displayEditorStore.setVariable(element, compose.variableName, {markdown: newMarkdown});
    }

    const sourceVariables = toJS(displayEditorStore.variables);

    const entityUpdates = lodash.filter(game.entities, (gameEntity) => {
      if (gameEntity.get('id') === entity.get('id')) {
        return true;
      }

      if (!gameEntity.has('compose') || gameEntity.get('element') !== 'text') {
        return false;
      } else if (gameEntity.get('compose').variableName !== compose.variableName) {
        return false;
      }

      return true;
    }).reduce((final, updateEntity) => {
      const updateId = updateEntity.get('id');

      const composeSource = getComposeForSource(updateEntity);
      const textSource = getTextForSource(updateEntity);
      textSource.text.markdown = newMarkdown;

      final[updateId] = getTextFromSource(
        {...textSource, ...composeSource},
        sourceVariables
      );
      return final;
    }, {});

    const actionParams = {
      entityId: entity.get('id'),
    };

    // This will rebuild the textComponent for the entities using the new variable value.
    game.addAction(actionParams, actionUpdateVariableComponent(
      entityUpdates
    ));
  };

  /**
   * Triggered when a block style changes.
   *
   * @param {{}} contentBlock
   * @returns {?string}
   */
  onChangeBlockStyle = (contentBlock) => {
    const type = contentBlock.getType();

    let newClassName = null;
    lodash.forEach(styleToClassMap, (className, style) => {
      if (type === style) {
        newClassName = className;
        return false;
      }
      return true;
    });

    return newClassName;
  };

  /**
   * Performs a delete/backspace key command while preserving inline styles.
   * This is to be used with handleKeyCommand().
   *
   * @param {{}} editorState
   * @param {function} commandFunction
   * @returns {string}
   */
  @action deleteAndPreserveStyle = (editorState, commandFunction) => {
    const currentStyle = editorState.getCurrentInlineStyle();

    // commandFunction needs to be the same logic the editor would have done (one of 'draft-js/lib/keyCommand*').
    const newEditorState = commandFunction(editorState);

    // Set the editorState to add the inline styles back in upon next insertion.
    // This prevents the styles from being erased when the text box is emptied.
    this.textState = EditorState.setInlineStyleOverride(newEditorState, currentStyle);

    this.onChangeTextState(this.textState);

    // Tells the function to do nothing, everything was handled here.
    return 'handled';
  };

  /**
   * Catches key commands from the editor allowing custom flows.
   * Looks for backspace/delete and forces inline styles to be preserved after removing character/word/line.
   *
   * @param {string} command
   * @param {{}} editorState
   * @returns {string}
   */
  handleKeyCommand = (command, editorState) => {
    if (command === 'backspace') {
      return this.deleteAndPreserveStyle(editorState, keyCommandPlainBackspace);
    } else if (command === 'backspace-word') {
      return this.deleteAndPreserveStyle(editorState, keyCommandBackspaceWord);
    } else if (command === 'backspace-to-start-of-line') {
      return this.deleteAndPreserveStyle(editorState, keyCommandBackspaceToStartOfLine);
    } else if (command === 'delete') {
      return this.deleteAndPreserveStyle(editorState, keyCommandPlainDelete);
    } else if (command === 'delete-word') {
      return this.deleteAndPreserveStyle(editorState, keyCommandDeleteWord);
    }

    return 'not-handled';
  };

  /**
   * Renders the text controls.
   *
   * @returns {{}}
   */
  renderTextControls() {
    const {
      /** @type {ObservableMap} */ entity,
    } = this.props;

    // This is required for the text to update when the variable name changes.
    entity.get('text').variableName; // eslint-disable-line no-unused-expressions

    return (<div className="group-controls">
      <div className="row">
        <div className="col">
          <div className="form-group">
            {/* <label htmlFor="font-selector">Typeface</label> */}
            <div className="type-color-controls">
              <FontSelect
                editorState={this.textState}
                onChangeTextState={this.onChangeTextState}
                beforeChange={this.selectAllIfNoSelection}
              />
              <ColorSelect
                editorState={this.textState}
                onChangeTextState={this.onChangeTextState}
                beforeChange={this.selectAllIfNoSelection}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            {/* <div><label>Setting</label></div> */}
            <StyleButtons
              editorState={this.textState}
              onChangeTextState={this.onChangeTextState}
              beforeChange={this.selectAllIfNoSelection}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            {/* <div><label>Alignment</label></div> */}
            <AlignButtons
              editorState={this.textState}
              onChangeTextState={this.onChangeTextState}
              beforeChange={this.forceSelectionFocus}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="form-group">
            {/* <label htmlFor="letter-spacing-selector">Spacing</label> */}
            <LetterSpacingSelect
              editorState={this.textState}
              onChangeTextState={this.onChangeTextState}
              beforeChange={this.selectAllIfNoSelection}
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            {/* <label htmlFor="line-height-selector">Line Height</label> */}
            <LineHeightSelect
              editorState={this.textState}
              onChangeTextState={this.onChangeTextState}
              beforeChange={this.selectAllIfNoSelection}
            />
          </div>
        </div>
      </div>
    </div>);
  }

  /**
   * Opens generate text modal
   */
  @action onGenerateTextClick = () => {
    this.isGenerateTextModalOpen = true;
  };

  /**
   * Closes generate text modal
   */
  @action onHideGenerateTextModal = () => {
    this.isGenerateTextModalOpen = false;
  }

  /**
   * Sets text when text is selected from GenerateTextModal
   *
   * @param {string} text
   */
  @action onGenerateTextResultSelect = (text) => {
    const currentContent = this.textState.getCurrentContent();
    const firstBlock = currentContent.getBlockMap().first();
    const lastBlock = currentContent.getBlockMap().last();
    const firstBlockKey = firstBlock.getKey();
    const lastBlockKey = lastBlock.getKey();
    const lengthOfLastBlock = lastBlock.getLength();

    // select all text. use this instead of selectAll() method since that method
    // is doing a .merge on current selection
    const newSelection = new SelectionState({
      anchorKey: firstBlockKey,
      anchorOffset: 0,
      focusKey: lastBlockKey,
      focusOffset: lengthOfLastBlock,
    });

    const stateAfterSelection = EditorState.acceptSelection(this.textState, newSelection);

    const newContent = Modifier.replaceText(
      stateAfterSelection.getCurrentContent(),
      newSelection,
      text,
      stateAfterSelection.getCurrentInlineStyle(),
    );

    const stateAfterTextReplace = EditorState.push(stateAfterSelection, newContent, 'insert-characters');

    this.onChangeTextState(stateAfterTextReplace);

    this.onHideGenerateTextModal();
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      /** @type {DisplayEditorStore} */ displayEditorStore,
      /** @type {ObservableMap} */ entity,
      /** @type {GameStore} */ game,
    } = this.props;

    return (
      <div className="edit-text-controls">
        <div className="text-group">
          <EditorSidebarTitle
            title="Text"
            rightSlot={(
              <div>
                <span className="ai-tool-text">Explore our latest AI Tool.</span>
                <GenerateTextButton
                  onClick={this.onGenerateTextClick}
                />
              </div>
            )}
          />
          <div className="RichEditor-root">
            <div className="RichEditor-editor text-control-editor">
              <Editor
                blockStyleFn={this.onChangeBlockStyle}
                customStyleMap={customStylesMap}
                editorState={this.textState}
                handleKeyCommand={this.handleKeyCommand}
                onChange={this.onChangeTextState}
                spellCheck={true}
                stripPastedStyles={true}
              />
            </div>
            <GenerateTextModal
              isOpen={this.isGenerateTextModalOpen}
              onCancel={this.onHideGenerateTextModal}
              onResultClick={this.onGenerateTextResultSelect}
            />
          </div>
        </div>

        <div className="text-style control-group">
          {this.renderTextControls()}
        </div>

        <AdvancedTextControls displayEditorStore={displayEditorStore} entity={entity} game={game} />

        <EditComposeControls entity={entity} game={game} />
        <EditPositionControls entity={entity} game={game} />
        <EditAlignmentControls entity={entity} game={game} />
        <EditUnitsControls entity={entity} game={game} />
        <EditTimelineControls entity={entity} game={game} />
        <EditEffectControls entity={entity} game={game} />
      </div>
    );
  }
}

EditTextControls.propTypes = {
  displayEditorStore: PropTypes.object.isRequired,
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(EditTextControls);
