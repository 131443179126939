import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPause, faPlay, faStepBackward} from '@fortawesome/free-solid-svg-icons';

import {STATE_PAUSED, STATE_PLAYING, STATE_STOPPED} from '../../../constants/displayItemConstants';

import './playerControls.scss';

/**
 * The PlayerControls component.
 */
export class PlayerControls extends React.Component {
  /**
   * Plays the video.
   */
  onPlay = () => {
    const {timer} = this.props;

    timer.start();
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {timer} = this.props;
    const timerState = timer.state;

    return (
      <div className="player-controls">
        {(timerState === STATE_PAUSED || timerState === STATE_STOPPED) && (
          <a
            className="control-button button-play"
            onClick={this.onPlay}
          >
            <FontAwesomeIcon icon={faPlay} />
          </a>
        )}

        {(timerState === STATE_PLAYING) && (
          <a
            className="control-button button-pause"
            onClick={() => {
              timer.pause();
            }}
          >
            <FontAwesomeIcon icon={faPause} />
          </a>
        )}

        <a
          className={classNames({
            'control-button button-back': true,
            'hidden': timerState === STATE_PLAYING
          })}
          onClick={() => {
            timer.stopAndReset();
          }}
        >
          <FontAwesomeIcon icon={faStepBackward} />
        </a>
      </div>
    );
  }
}

PlayerControls.propTypes = {
  game: MobxPropTypes.observableObject,
  timer: MobxPropTypes.observableObject,
};

export default observer(PlayerControls);
