import {action} from 'mobx';

import apiAdminBannerGetAllStore from './apiAdminBannerGetAllStore';
import ApiMapBaseStore from '../common/apiMapBaseStore';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';

/**
 * ApiBannerUpdateAdminStore
 */
class ApiBannerUpdateAdminStore extends ApiMapBaseStore {
  /**
   * Updates a banner through the api.
   *
   * @param {number} bannerId
   * @param {string} newBannerData
   */
  @action makeRequest(bannerId, newBannerData) {
    if (!bannerId) {
      this.state = STATE_REJECTED;
      this.error = new Error('Invalid banner id given to bannerUpdate.');
      return;
    }

    const safeBannerId = String(bannerId);

    this.dataMap.set(safeBannerId, {
      state: STATE_PENDING,
      [EXPIRE_TIME]: Date.now() + EXPIRES_PENDING,
      data: null,
      error: null,
    });

    serverApi.bannerUpdate(bannerId, newBannerData).then(
      action('bannerUpdateSuccess', (wasSuccessful) => {
        this.dataMap.set(safeBannerId, {
          state: STATE_FULFILLED,
          [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
          data: wasSuccessful,
          error: null,
        });

        apiAdminBannerGetAllStore.refresh();
      }),
      action('bannerUpdateError', (bannerUpdateError) => {
        this.dataMap.set(safeBannerId, {
          state: STATE_REJECTED,
          [EXPIRE_TIME]: null,
          data: null,
          error: bannerUpdateError,
        });
      })
    );
  }
}

export default new ApiBannerUpdateAdminStore();
