import {positionComponent} from '../../../../../../display/components/common/positionComponent';
import {sizeComponent} from '../../../../../../display/components/common/sizeComponent';
import {feedIconComponent} from '../../../../../../display/components/type/feedIconComponent';

/**
 * Gets the data for a placeholder feed icon.
 *
 * @param {string} feedName
 * @param {string} type
 * @param {string} url
 * @param {string} iconPack
 * @param {string} iconPackUrl
 * @param {?number} opacity
 * @returns {function}
 */
export function getPlaceHolderFeedIconData(feedName, type, url, iconPack, iconPackUrl, opacity = 1) {
  return ({position, size}) => {
    const defaultSize = 200;
    const positionY = position.y || 0;
    const positionX = position.x || 0;
    const width = size.width || defaultSize;
    const height = size.height || defaultSize;

    return {
      name: feedName,
      setup: {
        ...sizeComponent(width, height),
        ...positionComponent(positionY, positionX),
      },
      ...feedIconComponent(url, {
        type,
        iconPack,
        iconPackUrl,
        opacity,
      }),
    };
  };
}
