import lodash from 'lodash';
import {runInAction, toJS} from 'mobx';

import {ALIGNMENTS} from '../components/common/positionComponent';
import {adjustParamsDefaults, clearTransitionCache, updateEntity} from '../ecs/entityHelper';

/**
 * The name of the system.
 * @const {string}
 */
export const POSITIONING_SYSTEM = 'positioningSystem';

/**
 * Gets a new instance of the position system.
 *
 * @param {GameStore} game
 * @returns {{name: string, runActions: systemRunActions}}
 */
export function positioningSystem(game) {
  /**
   * Called when the game loop updates.
   *
   * @param {Array.<{}>} actions
   */
  function systemRunActions(actions) {
    actions.forEach((actionEntity) => {
      // First check for required components.
      if (!actionEntity.has('actionPosition')) {
        return;
      }

      const entityId = actionEntity.get('action').entityId;
      const entity = game.getEntity(entityId);
      if (!entity) {
        return;
      }

      const actionPosition = actionEntity.get('actionPosition');
      const moveCrop = Boolean(actionPosition.moveCrop);

      let checked = {};
      if (entity.get('element') === 'image') {
        checked = checkImageBoundaries(actionEntity, entity, moveCrop);
      } else {
        checked = checkBoundaries(actionEntity, entity);
      }

      const {safePosition, safeSize, safeCrop, safeImage} = checked;

      // Now update the entity.
      runInAction('positioningSystemUpdateEntity', () => {
        if (safePosition) {
          // Make sure the defaults were also updated.
          safePosition.default.rotate = safePosition.rotate;

          updateEntity(entity, 'position', adjustParamsDefaults(safePosition, game.resolution));
        }
        if (safeSize) {
          updateEntity(entity, 'size', adjustParamsDefaults(safeSize, game.resolution));
        }
        if (safeImage) {
          updateEntity(entity, 'image', adjustParamsDefaults(safeImage, game.resolution));
        }
        if (safeCrop) {
          updateEntity(entity, 'crop', adjustParamsDefaults(safeCrop, game.resolution));
        }

        clearTransitionCache(entity);
      });
    });
  }

  /**
   * Gets the deltas.
   *
   * @param {{}} actionEntity
   * @param {{}} entity
   * @returns {{positionDelta: {}, sizeDelta: {}, newRotate: ?number}}
   */
  function getDeltas(actionEntity, entity) {
    const position = entity.get('position');
    const {isResize, positionDelta, sizeDelta, newRotate} = actionEntity.get('actionPosition');

    const safePositionDelta = (positionDelta) ? toJS(positionDelta) : null;

    if (safePositionDelta) {
      if (position.alignment.y === ALIGNMENTS.y.bottom) {
        safePositionDelta.y = 0 - safePositionDelta.y;
      }
      if (position.alignment.x === ALIGNMENTS.x.right) {
        safePositionDelta.x = 0 - safePositionDelta.x;
      }
    }

    return {
      isResize,
      positionDelta: safePositionDelta,
      sizeDelta: (sizeDelta) ? toJS(sizeDelta) : null,
      newRotate: (newRotate || newRotate === 0) ? newRotate : null,
    };
  }

  /**
   * Checks to see if the size or position changes takes the item over the boundary.
   *
   * @param {ObservableMap} actionEntity
   * @param {ObservableMap} entity
   * @returns {{safePosition: ?{y: number, x: number}, safeSize: ?{width: number, height: number}}}
   */
  function checkBoundaries(actionEntity, entity) {
    const {isResize, positionDelta, sizeDelta} = getDeltas(actionEntity, entity);
    const {safePosition, safeSize} = getNewPositionAndSize(actionEntity, entity);

    const gameResolution = game.resolution;
    const sizePadding = 10;

    let truncateHeight = 0;
    let truncateWidth = 0;

    if (getDeltaHasChanges(positionDelta)) {
      const boundaryChanges = checkCroppingBoundaries(
        safeSize,
        safePosition,
        safePosition.alignment,
        gameResolution,
        sizePadding
      );

      safePosition.y = boundaryChanges.y;
      safePosition.x = boundaryChanges.x;

      if (isResize) {
        truncateHeight = boundaryChanges.truncateHeight;
        truncateWidth = boundaryChanges.truncateWidth;
      }
    } else if (getDeltaHasChanges(sizeDelta)) {
      // Since changing size to the left or up requires a position change too, this section is only for right and down.

      let limitTop = sizePadding;
      let limitLeft = sizePadding;
      let limitBottom = gameResolution.height - sizePadding;
      let limitRight = gameResolution.width - sizePadding;

      const alignment = safePosition.alignment;
      if (alignment.y === ALIGNMENTS.y.middle) {
        const adjustForMiddle = ((gameResolution.height - safeSize.height) / 2);
        limitTop -= adjustForMiddle;
        limitBottom -= adjustForMiddle;
      }
      if (alignment.x === ALIGNMENTS.x.center) {
        const adjustForCenter = ((gameResolution.width - safeSize.width) / 2);
        limitLeft -= adjustForCenter;
        limitRight -= adjustForCenter;
      }

      const fullWidth = safePosition.x + safeSize.width;
      const fullHeight = safePosition.y + safeSize.height;
      if (fullWidth < limitLeft) {
        // This prevents the entity's right side from leaving the left side of the display.
        truncateWidth = fullWidth - limitLeft;
      } else if (safeSize.width < sizePadding) {
        // This prevents the entity's width from dropping below the sizePadding when resizing from the right.
        truncateWidth = safeSize.width - sizePadding;
      } else if (fullWidth > limitRight) {
        // This will prevent the entity's edge from going off the right side of the display.
        // truncateWidth = fullWidth - limitRight;
      }

      if (fullHeight < limitTop) {
        // This prevents the entity's bottom from leaving the top of the display.
        truncateHeight = fullHeight - limitTop;
      } else if (safeSize.height < sizePadding) {
        // This prevents the entity's height from dropping below the sizePadding when resizing from the bottom.
        truncateHeight = safeSize.height - sizePadding;
      } else if (fullHeight > limitBottom) {
        // This will prevent the entity's edge from going off the bottom of the display.
        // truncateHeight = fullHeight - limitBottom;
      }
    }

    if (safeSize && (truncateWidth || truncateHeight)) {
      safeSize.width -= truncateWidth;
      safeSize.height -= truncateHeight;
    }

    return {
      safePosition,
      safeSize,
    };
  }

  /**
   * Checks to see if the size or position changes takes the item over the boundary.
   *
   * @param {ObservableMap} actionEntity
   * @param {ObservableMap} entity
   * @param {boolean} moveCrop
   * @returns {{safeCrop: ?{}, safeImage: ?{}, safePosition: ?{}, safeSize: ?{}}}
   */
  function checkImageBoundaries(actionEntity, entity, moveCrop) {
    let {safeCrop, safeImage, safePosition, safeSize} = getNewImageAndPositionAndSize(actionEntity, entity, moveCrop);

    const {boundaryCrop, boundaryImage} = checkImageCroppingBoundaries(
      actionEntity,
      entity,
      safeImage,
      safePosition,
      safeCrop
    );

    // We don't need this since we aren't rotating.
    // const {isResize, newRotate} = getDeltas(actionEntity, entity);
    // if (isResize) {
    //   // Keep the image center in the safe position.
    //   const centerSafeImage = enforceImageCenterOnResize(entity, safeImage);
    //   safeCrop.x += (centerSafeImage.x - boundaryImage.x);
    //   safeCrop.y += (centerSafeImage.y - boundaryImage.y);
    //
    //   safeImage = centerSafeImage;
    // }

    // if (!isResize && !moveCrop && !newRotate && newRotate !== 0) {
    //   safeCrop = maintainCropPosition(entity, safeImage, safeCrop, safePosition);
    // }

    return {
      safeCrop: boundaryCrop,
      safeImage: boundaryImage,
      safePosition,
      safeSize,
    };
  }

  /**
   * Checks to see if the size or position extend over a boundary.
   *
   * @param {ObservableMap} actionEntity
   * @param {ObservableMap} entity
   * @param {{}} safeImage
   * @param {{}} safePosition
   * @param {{}} safeCrop
   * @returns {{boundaryCrop: ?{}, boundaryImage: ?{}}}
   */
  function checkImageCroppingBoundaries(actionEntity, entity, safeImage, safePosition, safeCrop) {
    const {isResize, positionDelta, sizeDelta} = getDeltas(actionEntity, entity);

    const gameResolution = game.resolution;
    const sizePadding = 10;

    const boundaryImage = lodash.cloneDeep(safeImage);
    const boundaryCrop = lodash.cloneDeep(safeCrop);

    let truncateHeight = 0;
    let truncateWidth = 0;
    if (getDeltaHasChanges(positionDelta)) {
      const boundaryChanges = checkCroppingBoundaries(
        boundaryImage,
        boundaryImage,
        safePosition.alignment,
        gameResolution,
        sizePadding
      );

      boundaryImage.y = boundaryChanges.y;
      boundaryImage.x = boundaryChanges.x;

      if (isResize) {
        truncateHeight = boundaryChanges.truncateHeight;
        truncateWidth = boundaryChanges.truncateWidth;
      }
    } else if (getDeltaHasChanges(sizeDelta)) {
      // Since changing size to the left or up requires a position change too,
      // this section is only for right and down.

      let limitTop = sizePadding;
      let limitLeft = sizePadding;
      let limitBottom = gameResolution.height - sizePadding;
      let limitRight = gameResolution.width - sizePadding;

      const alignment = safePosition.alignment;
      if (alignment.y === ALIGNMENTS.y.middle) {
        const adjustForMiddle = ((gameResolution.height - boundaryImage.height) / 2);
        limitTop -= adjustForMiddle;
        limitBottom -= adjustForMiddle;
      }
      if (alignment.x === ALIGNMENTS.x.center) {
        const adjustForCenter = ((gameResolution.width - boundaryImage.width) / 2);
        limitLeft -= adjustForCenter;
        limitRight -= adjustForCenter;
      }

      const fullWidth = boundaryImage.x + boundaryImage.width;
      const fullHeight = boundaryImage.y + boundaryImage.height;
      if (fullWidth < limitLeft) {
        // This prevents the entity's right side from leaving the left side of the display.
        truncateWidth = fullWidth - limitLeft;
      } else if (boundaryImage.width < sizePadding) {
        // This prevents the entity's width from dropping below the sizePadding when resizing from the right.
        truncateWidth = boundaryImage.width - sizePadding;
      } else if (fullWidth > limitRight) {
        // This will prevent the entity's edge from going off the right side of the display.
        // truncateWidth = limitRight - fullWidth;
      }

      if (fullHeight < limitTop) {
        // This prevents the entity's bottom from leaving the top of the display.
        truncateHeight = fullHeight - limitTop;
      } else if (boundaryImage.height < sizePadding) {
        // This prevents the entity's height from dropping below the sizePadding when resizing from the bottom.
        truncateHeight = boundaryImage.height - sizePadding;
      } else if (fullHeight > limitBottom) {
        // This will prevent the entity's edge from going off the bottom of the display.
        // truncateHeight = limitBottom - fullHeight;
      }
    }

    if (boundaryImage && (truncateWidth || truncateHeight)) {
      boundaryImage.width -= truncateWidth;
      boundaryImage.height -= truncateHeight;
    }

    return {boundaryImage, boundaryCrop};
  }

  /**
   * Keeps the image's center coordinates the same as they were before to prevent weird resizing.
   *
   * @param {{}} entity
   * @param {{x: number, y: number, width: number, height: number}} safeImage
   * @returns {{x: number, y: number}}
   */
  // function enforceImageCenterOnResize(entity, safeImage) {
  //   const newItem = {...safeImage};
  //
  //   const currentImage = entity.get('image');
  //   const currentImageCenter = {
  //     x: currentImage.x + (currentImage.width / 2),
  //     y: currentImage.y + (currentImage.height / 2),
  //   };
  //   const newImageCenter = {
  //     x: safeImage.x + (safeImage.width / 2),
  //     y: safeImage.y + (safeImage.height / 2),
  //   };
  //
  //   // Keep the image center in the safe position.
  //   newItem.x = safeImage.x + (currentImageCenter.x - newImageCenter.x);
  //   newItem.y = safeImage.y + (currentImageCenter.y - newImageCenter.y);
  //
  //   return newItem;
  // }

  /**
   * Maintains the position of the crop window
   *
   * @param {{}} entity
   * @param {{x: number, y: number, width: number, height: number}} safeImage
   * @param {{x: number, y: number, width: number, height: number}} safeCrop
   * @param {{rotate: number}} safePosition
   * @returns {{x: number, y: number}}
   */
  // function maintainCropPosition(entity, safeImage, safeCrop, safePosition) {
  //   if (!safeCrop) {
  //     return safeCrop;
  //   }
  //
  //   const currentCrop = toJS(entity.get('crop'));
  //   const currentImage = entity.get('image');
  //   const currentPosition = entity.get('position');
  //
  //   const currentOrigin = {
  //     x: currentImage.x,
  //     y: currentImage.y,
  //   };
  //   const newOrigin = {
  //     x: safeImage.x,
  //     y: safeImage.y,
  //   };
  //
  //   const originRotated = mapShapeToRotatedFrame(currentPosition.rotate, currentCrop, currentOrigin);
  //   const newRotated = mapShapeToRotatedFrame(0 - safePosition.rotate, originRotated, newOrigin);
  //
  //   return newRotated;
  // }

  /**
   * Parses the position and size components based on the changes/deltas.
   *
   * @param {ObservableMap} actionEntity
   * @param {ObservableMap} entity
   * @returns {{safePosition: {}, safeSize: {}}}
   */
  function getNewPositionAndSize(actionEntity, entity) {
    const {positionDelta, sizeDelta, newRotate} = getDeltas(actionEntity, entity);

    const currentPosition = entity.get('position');
    const currentSize = entity.get('size');

    const safePosition = lodash.cloneDeep(currentPosition);
    const safeSize = lodash.cloneDeep(currentSize);

    if (positionDelta && positionDelta.y) {
      safePosition.y += positionDelta.y;
    }
    if (positionDelta && positionDelta.x) {
      safePosition.x += positionDelta.x;
    }
    if (sizeDelta && sizeDelta.width) {
      safeSize.width += sizeDelta.width;
    }
    if (sizeDelta && sizeDelta.height) {
      safeSize.height += sizeDelta.height;
    }
    if (newRotate !== null && newRotate !== undefined) {
      safePosition.rotate = newRotate;
    }

    return {
      safePosition,
      safeSize,
    };
  }

  /**
   * Parses the image and position and size components based on the changes/deltas.
   *
   * @param {ObservableMap} actionEntity
   * @param {ObservableMap} entity
   * @param {boolean} moveCrop
   * @returns {{safeCrop: {}, safeImage: {}, safePosition: {}, safeSize: {}}}
   */
  function getNewImageAndPositionAndSize(actionEntity, entity, moveCrop) {
    const {positionDelta, sizeDelta, newRotate} = getDeltas(actionEntity, entity);

    const currentCrop = entity.get('crop');
    const currentImage = entity.get('image');
    const currentPosition = entity.get('position');
    const currentSize = entity.get('size');

    const safeCrop = lodash.cloneDeep(currentCrop);
    const safeImage = lodash.cloneDeep(currentImage);
    const safePosition = lodash.cloneDeep(currentPosition);
    const safeSize = lodash.cloneDeep(currentSize);

    if (sizeDelta && sizeDelta.width) {
      safeImage.width += sizeDelta.width;
      safeSize.width += sizeDelta.width;

      if (moveCrop) {
        // safeCrop.width = sizeDelta.width;
      }
    }
    if (sizeDelta && sizeDelta.height) {
      safeImage.height += sizeDelta.height;
      safeSize.height += sizeDelta.height;

      if (moveCrop) {
        // safeCrop.height += sizeDelta.height;
      }
    }

    if (positionDelta && positionDelta.y) {
      safeImage.y += positionDelta.y;
      safePosition.y += positionDelta.y;

      if (moveCrop) {
        // safeCrop.y += positionDelta.y;
      }
    }
    if (positionDelta && positionDelta.x) {
      safeImage.x += positionDelta.x;
      safePosition.x += positionDelta.x;

      if (moveCrop) {
        // safeCrop.x += positionDelta.x;
      }
    }

    if (newRotate !== null && newRotate !== undefined) {
      safePosition.rotate = newRotate;
    }

    if (safeSize.width && !Number.isNaN(Number(safeSize.width))) {
      safeSize.width = Math.abs(safeSize.width);
    }
    if (safeSize.height && !Number.isNaN(Number(safeSize.height))) {
      safeSize.height = Math.abs(safeSize.height);
    }

    return {
      safeCrop,
      safeImage,
      safePosition,
      safeSize,
    };
  }

  /**
   * Enforces that items can't be moved in any way outside of the game boundaries.
   *
   * @param {{height: number, width: number}} size
   * @param {{x: number, y: number}} position
   * @param {{height: number, width: number}} gameResolution
   * @returns {{x: number, y: number, truncateHeight: number, truncateWidth: number}}
   */
  function enforceInsideBoundaries(size, position, gameResolution) { // eslint-disable-line no-unused-vars
    // Make sure the new position won't push the item off the display.
    let newY = position.y;
    if (newY < 0) {
      newY = 0;
    } else if (newY + size.height > gameResolution.height) {
      newY = gameResolution.height - size.height;
    }

    let newX = position.x;
    if (newX < 0) {
      newX = 0;
    } else if (newX + size.width > gameResolution.width) {
      newX = gameResolution.width - size.width;
    }

    return {
      y: newY,
      x: newX,
      truncateHeight: newY - position.y,
      truncateWidth: newX - position.x,
    };
  }

  return {
    name: POSITIONING_SYSTEM,
    runActions: systemRunActions
  };
}

/**
 * Checks the cropping boundaries if the position change.
 * This makes sure the item doesn't entirely leave the viewing area, but allows it to drift outside a bit.
 *
 * @param {{height: number, width: number}} size
 * @param {{x: number, y: number}} position
 * @param {{x: string, y: string}} alignment
 * @param {{height: number, width: number}} gameResolution
 * @param {number=} sizePadding
 * @returns {{x: number, y: number, truncateHeight: number, truncateWidth: number}}
 */
function checkCroppingBoundaries(size, position, alignment, gameResolution, sizePadding) {
  const defaultPadding = 10;
  const padding = (sizePadding != null) ? sizePadding : defaultPadding;

  let limitTop = 0 - size.height + padding;
  let limitLeft = 0 - size.width + padding;
  let limitBottom = gameResolution.height - padding;
  let limitRight = gameResolution.width - padding;

  if (alignment.y === ALIGNMENTS.y.middle) {
    const adjustForMiddle = ((gameResolution.height - size.height) / 2);
    limitTop -= adjustForMiddle;
    limitBottom -= adjustForMiddle;
  }
  if (alignment.x === ALIGNMENTS.x.center) {
    const adjustForCenter = ((gameResolution.width - size.width) / 2);
    limitLeft -= adjustForCenter;
    limitRight -= adjustForCenter;
  }

  // Make sure the new position won't push the item fully off the display.
  let newY = position.y;
  if (newY < limitTop) {
    // This prevents the entity's bottom from leaving the top of the display.
    newY = limitTop;
  } else if (newY > limitBottom) {
    // This prevents the entity's top from leaving the bottom of the display.
    newY = limitBottom;
  } else if (size.height < padding) {
    // This prevents the entity's height from dropping below the padding when resizing from the top.
    newY = newY - (padding - size.height);
  }

  let newX = position.x;
  if (newX < limitLeft) {
    // This prevents the entity's right side from leaving the left side of the display.
    newX = limitLeft;
  } else if (newX > limitRight) {
    // This prevents the entity's left side from leaving the right side of the display.
    newX = limitRight;
  } else if (size.width < padding) {
    // This prevents the entity's width from dropping below the padding when resizing from the left.
    newX = newX - (padding - size.width);
  }

  return {
    y: newY,
    x: newX,
    truncateHeight: newY - position.y,
    truncateWidth: newX - position.x,
  };
}

/**
 * Gets whether or not the delta has any non-zero values.
 *
 * @param {Object.<string, number>} delta
 * @returns {boolean}
 */
function getDeltaHasChanges(delta) {
  return lodash.some(delta, (value) => {
    return Boolean(value);
  });
}
