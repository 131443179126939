import lodash from 'lodash';
import markdownIt from 'markdown-it';
import {toJS} from 'mobx';

import {updateEntity} from '../../ecs/entityHelper';
import {fontStylesPlugin} from '../../../utils/markdown/markdownStylesPlugin';
import {underlinePlugin} from '../../../utils/markdown/markdownUnderlinePlugin';
import {parseMarkdownToWords} from '../../../utils/markdown/markdownWordParser';

/**
 * The default blank drop shadow object.
 * @type {{blur: number, color: string, x: number, y: number}}
 */
export const DEFAULT_DROP_SHADOW = {
  blur: 0,
  color: '#000',
  x: 0,
  y: 0,
};

/**
 * The default blank stroke.
 * @type {{width: number, color: string}}
 */
export const DEFAULT_STROKE = {
  width: 0,
  color: '#000',
};

/**
 * The conversion factor for going from milliseconds to seconds.
 *
 * @const {number}
 */
const MS_TO_SECONDS = 1000;

const markdownParser = markdownIt({
  html: false,
  xhtmlOut: true,
  breaks: true,
});

// Add the markdown-it plugins.
markdownParser.use(fontStylesPlugin);
markdownParser.use(underlinePlugin);

/**
 * The feed component.
 *
 * @param {Array.<Array.<{}>>} lines
 * @param {{}} feedData
 * @param {string} feedData.markdown
 * @param {string=} feedData.variableName
 * @param {number=} feedData.dropShadowBlur
 * @param {string=} feedData.dropShadowColor
 * @param {number=} feedData.dropShadowX
 * @param {number=} feedData.dropShadowY
 * @param {string=} feedData.strokeColor
 * @param {number=} feedData.strokeWidth
 * @param {number=} feedData.opacity
 * @returns {{feed: {lines: Array.<Array.<{}>>, markdown: string, variableName: string}}}
 */
export function feedComponent(lines, feedData) {
  const {markdown, variableName, type} = feedData;

  const opacity = (feedData.opacity === undefined) ? 1 : feedData.opacity;
  const stroke = lodash.defaults(
    lodash.pick(feedData.stroke, lodash.keys(DEFAULT_STROKE)),
    DEFAULT_STROKE
  );
  const dropShadow = lodash.defaults(
    lodash.pick(feedData.dropShadow, lodash.keys(DEFAULT_DROP_SHADOW)),
    DEFAULT_DROP_SHADOW
  );

  return {
    feed: {
      lines,
      markdown,
      type,
      dropShadow,
      opacity,
      stroke,
      variableName: variableName || null,
      default: {
        lines: lodash.cloneDeep(lines),
        markdown,
        dropShadow,
        opacity,
        stroke,
      },
      transition: null,
    },
  };
}

/**
 * Gets the feed component from the source item.
 *
 * @param {{feed: {plaintext: ?string, markdown: ?string}}} item
 * @param {{}=} variables
 * @returns {{feed: {isHtml: boolean, value: string}}}
 */
export function getFeedFromSource(item, variables) {
  if (!item.feed) {
    return {};
  }

  const feed = item.feed;

  let markdown = feed.markdown || feed.plaintext || '';

  const canEdit = lodash.get(item, 'compose.canEdit');
  const variableName = lodash.get(item, 'compose.variableName');
  if (canEdit && variableName) {
    const variableValue = lodash.get(variables, `feed.${variableName}`, '');
    if (variableValue && lodash.isString(variableValue)) {
      // The old deprecated variable style is a string that is just the markdown.
      markdown = variableValue;
    } else if (variableValue) {
      // The ()s fix an eslint parsing error.
      ({markdown} = variableValue);
    }
  }

  const lines = parseMarkdownToWords(markdown, null, feed);

  const feedData = {
    markdown,
    variableName,
    type: feed.type || undefined,
    dropShadow: feed.dropShadow || undefined,
    opacity: feed.opacity,
    stroke: feed.stroke || undefined,
  };

  return feedComponent(lines, feedData);
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {{}} entity
 * @returns {{}}
 */
export function getFeedForSource(entity) {
  if (!entity.has('feed')) {
    return {};
  }

  const compose = entity.get('compose');
  const feed = entity.get('feed');

  const output = {};

  const canEdit = lodash.get(compose, 'canEdit', false);
  const variableName = lodash.get(compose, 'variableName');
  if (!canEdit || !variableName) {
    output.markdown = feed.markdown || '';
  }

  if (feed.type) {
    output.type = feed.type;
  }

  if (feed.stroke) {
    output.stroke = toJS(feed.stroke);
  }
  if (feed.dropShadow) {
    output.dropShadow = toJS(feed.dropShadow);
  }
  if (feed.opacity !== undefined) {
    output.opacity = feed.opacity;
  }

  return {
    feed: lodash.cloneDeep(output),
  };
}

/**
 * Parses the given feed from markdown to HTML.
 *
 * @param {string} markdownText
 * @returns {string}
 */
export function parseMarkdownToHtml(markdownText) {
  const html = markdownParser.render(markdownText);
  return lodash.trim(html).replace(/<p/g, '<div').replace(/<\/p/g, '</div');
}

/**
 * Updates variables in this component.
 *
 * @param {{}} feed
 * @param {{}} variables
 * @returns {{}}
 */
export function updateFeedVariables(feed, variables) {
  const newFeed = lodash.cloneDeep(toJS(feed));

  const markdown = feed.default.markdown || '';

  let safeVariables = variables;
  if (feed.variableName && !safeVariables[feed.variableName]) {
    safeVariables = {...variables, [feed.variableName]: ''};
  }

  const compiledValue = String(markdown).replace(/\|\|=([^|]+?)\|\|/g, (matched, variableName) => {
    if (safeVariables[variableName] === undefined) {
      return '';
    }
    return safeVariables[variableName];
  });

  newFeed.markdown = compiledValue;
  newFeed.lines = parseMarkdownToWords(compiledValue, null, newFeed);

  return newFeed;
}

/**
 * Creates the feed transition object for the entity.
 *
 * @param {{}} entity
 * @param {string} splitOn
 * @returns {Array.<{}>}
 */
export function setupTransition(entity, splitOn) {
  const currentFeed = entity.get('feed');
  if (currentFeed.transition && currentFeed.transition.splitOn === splitOn) {
    return currentFeed.transition.default;
  }

  // Initialize the feed words into letters, words, or word blocks for the transition.
  const transitionLines = parseMarkdownToWords(currentFeed.markdown, splitOn, toJS(currentFeed));
  const feedTransitionData = {
    lines: transitionLines,
    default: lodash.clone(transitionLines),
    splitOn,
  };

  updateEntity(entity, 'feed', {transition: feedTransitionData}, 'feedComponentSetupTransition');

  return feedTransitionData.default;
}

/**
 * Removes the transition object from the entity.
 * This should happen after the transitions for the entity are finished.
 *
 * @param {{}} entity
 */
export function removeTransition(entity) {
  const currentFeed = entity.get('feed');
  if (!currentFeed.transition) {
    return;
  }

  updateEntity(entity, 'feed', {transition: null}, 'feedComponentRemoveTransition');
}

/**
 * Parse the transitionDetails into individual transitions for each feed word.
 *
 * @param {{}} transitionDetails
 * @param {Array.<{}>} textLines
 * @param {string} propertyPrefix Only parse properties with this prefix.
 * @returns {{}}
 */
export function parseLinesIntoWordTransitions(transitionDetails, textLines, propertyPrefix) {
  const wordPaths = [];
  lodash.forEach(textLines, (lineDetails, lineIndex) => {
    lodash.forEach(lineDetails.words, (wordDetails, wordIndex) => {
      wordPaths.push({
        path: `[${lineIndex}].words[${wordIndex}]`,
        feed: wordDetails.feed,
      });
    });
  });

  const wordCount = wordPaths.length;

  const wordDetails = [];
  lodash.forEach(transitionDetails, (tweenDetails) => {
    const {propertyName} = tweenDetails;

    if (!lodash.startsWith(propertyName, propertyPrefix)) {
      // Only do word parsing for word (letter, word, line) transitions.
      wordDetails.push(tweenDetails);
      return;
    }

    const isStaggered = (tweenDetails.stagger !== false);

    const tweenDuration = tweenDetails.duration;

    const smallestDuration = 0.05;

    const isStaggerDefined = !isNaN(parseInt(tweenDetails.stagger, 10));

    let staggerDelay = 0;
    if (isStaggered) {
      if (!isStaggerDefined) {
        staggerDelay = (tweenDuration / wordCount);
      } else {
        staggerDelay = Number(tweenDetails.stagger) / MS_TO_SECONDS;

        if (Math.abs(staggerDelay * wordCount) > tweenDuration - smallestDuration) {
          // Make sure the stagger delay doesn't reduce this items duration below the smallest duration.
          staggerDelay = staggerDelay < 0
            ? -1 * (tweenDuration - smallestDuration) / wordCount
            : (tweenDuration - smallestDuration) / wordCount;
        }
      }
    }

    let duration = tweenDuration;
    if (isStaggered) {
      const staggerDuration = parseInt(tweenDetails.staggerDuration, 10);
      const isStaggerDurationDefined = !isNaN(staggerDuration);

      if (isStaggerDurationDefined) {
        duration = staggerDuration / MS_TO_SECONDS;
      } else if (!isStaggerDefined) {
        duration = staggerDelay;
      } else {
        duration = tweenDuration - (Math.abs(staggerDelay) * (wordCount - 1));
      }
    }

    /**
     * Notes: Move this to documentation somewhere!
     * Stagger
     * If === false, there will be no staggering.
     * If not a number or === true, it will stagger evenly for each item
     *   (i.e. 1 second - 2 items [0, .5], 3 items [0, .33, .66], etc).
     * If a number, it will delay each number by that number of milliseconds
     *   (i.e. 500 ms - 2 items [0, .5], 3 items [0, .5, 1], etc).
     *
     * Stagger Duration
     * If stagger === false, duration = the whole tween duration
     * if a number, it will run for that number of milliseconds
     * if not a number and stagger is not a number, duration = stagger, it will run until the next item starts
     *   (items will animate one after the other).
     * if not a number and stagger is a number, after it starts, it will run until the end of the tween duration
     */

    lodash.forEach(wordPaths, (pathData, wordIndex) => {
      if (shouldFilterOutTween(tweenDetails, pathData, wordIndex)) {
        return;
      }

      wordDetails.push({
        ...tweenDetails,
        path: pathData.path,
        delay: staggerDelay < 0
          ? (tweenDetails.delay || 0) + (Math.abs(staggerDelay) * (wordCount - 1 - wordIndex))
          : (tweenDetails.delay || 0) + (staggerDelay * wordIndex),
        duration,
      });
    });
  });

  return wordDetails;
}

/**
 * Determines whether or not the tween should be filtered out.
 *
 * @param {{}} tweenDetails
 * @param {{path: string, feed: string}} pathData
 * @param {number} wordIndex
 * @returns {boolean} True to filter out, false to leave in.
 */
function shouldFilterOutTween(tweenDetails, pathData, wordIndex) {
  if (!tweenDetails.filters || !Array.isArray(tweenDetails.filters)) {
    return false;
  }

  return lodash.reduce(tweenDetails.filters, (shouldFilter, filterName) => {
    if (filterName === 'odd') {
      if (!wordIndex || wordIndex % 2 === 0) {
        return true;
      }
    } else if (filterName === 'even') {
      if (wordIndex % 2 === 1) {
        return true;
      }
    }

    const numberMatch = filterName.match(/^number\(([0-9,]+)\)$/);
    if (numberMatch) {
      const allowedNumbers = numberMatch[1].split(',').map((item) => Number(item));
      if (!lodash.includes(allowedNumbers, wordIndex + 1)) {
        return true;
      }
    }

    return shouldFilter;
  }, false);
}
