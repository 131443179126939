/* eslint-disable no-magic-numbers */

/**
 * The default transition duration (in milliseconds).
 * @const {string}
 */
export const TRANSITION_TIME_MS = 1000;

/**
 * The amount of time (in milliseconds) to delay the active time (after the start time) for an entity
 * @const {string}
 */
export const ACTIVE_DELAY_MS = TRANSITION_TIME_MS + 100;
