import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import ReactSelect from 'react-select';

import inject from '../../hoc/injectHoc';
import {STATE_PENDING, STATE_PRE} from '../../../constants/asyncConstants';
import {SIGN_SELECT_OPTION_RENDERER, SIGN_SELECT_VALUE_RENDERER} from '../shared/utils';

import '../shared/sign-select.scss';

/**
 * The SelectSignModal component.
 */
export class SelectSignModal extends React.Component {
  /**
   * The selected sign id.
   *
   * @type {?number}
   */
  @observable signId = null;

  /**
   * Triggered when the component just mounted onto the page.
   */
  @action componentDidMount() {
    const {isOpen, apiCompanySignGetAllStore, preselectedSignId} = this.props;

    if (isOpen) {
      apiCompanySignGetAllStore.refresh();
    }

    if (preselectedSignId) {
      this.signId = preselectedSignId;
    }
  }

  /**
   * Triggered when the component has just updated.
   *
   * @param {{isOpen: boolean}} prevProps
   */
  @action componentDidUpdate(prevProps) {
    if (!prevProps.preselectedSignId && this.props.preselectedSignId) {
      this.signId = this.props.preselectedSignId;
    }
  }

  /**
   * Tears down the modal.
   */
  tearDownModal = () => {
    // Do something.
  };

  /**
   * Triggered when the modal is closed without a finishing.
   */
  onCancelModal = () => {
    const {onComplete} = this.props;

    onComplete({
      signId: null,
      confirmAction: null
    });

    this.tearDownModal();
  };

  /**
   * Triggered when the user changes sign selection
   */
  @action onSignChange = ({value}) => {
    this.signId = value;
  }

  /**
   * Triggered when the modal is closed after choosing a folder.
   */
  @action onCompleteModal = async () => {
    const {onComplete, confirmButtonText} = this.props;

    onComplete({
      signId: this.signId,
      confirmAction: confirmButtonText
    });

    this.tearDownModal();
  };

  /**
   * Renders the sign dropdown
   *
   * @param {boolean} signsAreLoading
   * @param {array} signOptions
   * @param {number} signId
   * @param {boolean} showNoSignsWarning
   * @returns {ReactElement}
   */
  @action renderSignDropdown = (
    signsAreLoading,
    signOptions,
    signId,
    showNoSignsWarning
  ) => {
    return (<>
      <ReactSelect
        id="user-sign-select"
        className="theme-light sign-select"
        classNamePrefix="react-select"
        isClearable={false}
        isMulti={false}
        isSearchable={false}
        isDisabled={showNoSignsWarning}
        isLoading={signsAreLoading}
        onChange={this.onSignChange}
        options={signOptions}
        value={signOptions.find((sign) => sign.id === signId)}
        components={{
          Option: SIGN_SELECT_OPTION_RENDERER,
          SingleValue: SIGN_SELECT_VALUE_RENDERER,
        }}
      />
      {(showNoSignsWarning) && (<p className="text-dark">
          Please add a sign in your user menu.
      </p>)}
    </> );
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      isOpen,
      modalBodyUpperSlot,
      modalHeaderText,
      apiCompanySignGetAllStore,
    } = this.props;

    if (!isOpen) {
      return null;
    }

    const getSignsState = apiCompanySignGetAllStore.getState();
    const signsAreLoading = getSignsState === STATE_PENDING || getSignsState === STATE_PRE;

    let signOptions = [];
    let showNoSignsWarning = false;
    let signId = this.signId;

    if (!signsAreLoading) {
      const userSigns = apiCompanySignGetAllStore.getFulfilled() || [];

      // transform data for sign select
      signOptions = userSigns.map((item) => ({
        value: item.id,
        label: `${item.name} (${item.width}w x ${item.height}h)`,
        sign: item,
      }));

      if (signOptions.length === 0) {
        // if no signs available, show warning
        showNoSignsWarning = true;
      }
    }

    return (
      <Modal
        isOpen={isOpen}
        toggle={this.onCancelModal}
        centered
      >
        <ModalHeader>
          {modalHeaderText}
        </ModalHeader>
        <ModalBody>
          <div className="form-group">
            {(modalBodyUpperSlot) && (
              modalBodyUpperSlot
            )}
            <label htmlFor="user-sign-select">Sign</label>
            {
              this.renderSignDropdown(
                signsAreLoading,
                signOptions,
                signId,
                showNoSignsWarning
              )
            }
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-default"
            onClick={this.onCancelModal}
          >Cancel</button>
          {' '}
          <button
            data-cy="select-file-type-button"
            type="button"
            className="btn btn-primary"
            onClick={this.onCompleteModal}
          >{this.props.confirmButtonText}</button>
        </ModalFooter>
      </Modal>
    );
  }
}

SelectSignModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  apiCompanySignGetAllStore: MobxPropTypes.observableObject,

  confirmButtonText: PropTypes.string,
  modalBodyUpperSlot: PropTypes.element,
  modalHeaderText: PropTypes.string,
  preselectedSignId: PropTypes.number,
};

SelectSignModal.defaultProps = {
  confirmButtonText: 'Submit',
};

SelectSignModal.wrappedComponent = {};
SelectSignModal.wrappedComponent.propTypes = {
  apiCompanySignGetAllStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(SelectSignModal)(
  observer(SelectSignModal)
);
