import lodash from 'lodash';
import {action, observable, runInAction, toJS} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import Toggle from 'react-toggle';
import uuid from 'uuid/v4';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';

import inject from '../../hoc/injectHoc';
import {SUPER_ADMIN_ROLE} from '../../../constants/userConstants';
import {actionUpdateComponent} from '../../../display/components/action/actionUpdateComponent';
import {composeComponent} from '../../../display/components/common/composeComponent';
import {getCircleForSource, getCircleFromSource} from '../../../display/components/type/circleComponent';
import {getImageForSource, getImageFromSource} from '../../../display/components/type/imageComponent';
import {getLineForSource, getLineFromSource} from '../../../display/components/type/lineComponent';
import {getRectangleForSource, getRectangleFromSource} from '../../../display/components/type/rectangleComponent';
import {getTriangleForSource, getTriangleFromSource} from '../../../display/components/type/triangleComponent';
import {getTextForSource, getTextFromSource} from '../../../display/components/type/textComponent';
import {getFeedForSource, getFeedFromSource} from '../../../display/components/type/feedComponent';
import {getFeedIconForSource, getFeedIconFromSource} from '../../../display/components/type/feedIconComponent';
import {getVideoForSource, getVideoFromSource} from '../../../display/components/type/videoComponent';

import './editComposeControls.scss';

/**
 * When this value is set as the variable name, it will activate the edit flow.
 * @const {string}
 */
const ADD_NEW_VALUE = 'add-new';

/**
 * The EditComposeControls component.
 */
export class EditComposeControls extends React.Component {
  /**
   * Whether or not to show the edit input for the variable name.
   *
   * @type {?string}
   */
  @observable editVariableName = null;

  /**
   * Whether or not the user has permission to view this page.
   *
   * @type {?boolean}
   */
  @observable hasPermission = null;

  /**
   * Whether or not the compose control group is open.
   *
   * @type {boolean}
   */
  @observable isControlGroupOpen = false;

  /**
   * Triggered when the component is added to the page.
   */
  componentDidMount() {
    const {apiUserGetMeStore} = this.props;

    apiUserGetMeStore.refresh();
    apiUserGetMeStore.getPromise().then((user) => {
      runInAction('editComposeControlsLoaded', () => {
        this.hasPermission = user.role === SUPER_ADMIN_ROLE;
      });
    });
  }

  /**
   * Sets the variable name for the entity's compose data.
   *
   * @param {{}} changeEvent
   */
  onSelectVariableName = (changeEvent) => {
    const {
      /** @type {DisplayEditorStore} */ displayEditorStore,
      /** @type {ObservableMap} */ entity,
      /** @type {GameStore} */ game,
    } = this.props;

    const actionParams = {
      entityId: entity.get('id'),
    };

    const element = entity.get('element');

    if (changeEvent.target.value === ADD_NEW_VALUE) {
      this.showHideEditVariableName(true);

      const currentValue = this.getVariableValueForEntity();
      displayEditorStore.setVariable(element, this.editVariableName, currentValue);

      game.addAction(actionParams, actionUpdateComponent(
        composeComponent(true, this.editVariableName)
      ));
      return;
    }

    this.showHideEditVariableName(false);

    const newVariableName = changeEvent.target.value;
    const newVariableValue = displayEditorStore.getVariable(element, newVariableName, '');

    game.addAction(actionParams, actionUpdateComponent({
      ...composeComponent(true, newVariableName),
      ...this.getUpdatedComponentWithVariable(newVariableName, newVariableValue),
    }));
  };

  /**
   * Gets the value for a variable for the current entity.
   *
   * @returns {?{}}
   */
  getVariableValueForEntity = () => {
    const {
      /** @type {ObservableMap} */ entity,
    } = this.props;

    const element = entity.get('element');

    if (element === 'text') {
      const text = entity.get('text');
      return {markdown: text.markdown};
    } else if (element === 'feed') {
      const feed = entity.get('feed');
      return {markdown: feed.markdown};
    } else if (element === 'feedicon') {
      const feedIcon = entity.get('feedicon');
      return {url: feedIcon.url};
    } else if (element === 'image') {
      const image = entity.get('image');
      return {
        fileId: image.fileId,
        url: image.url,
      };
    } else if (element === 'video') {
      const video = entity.get('video');
      return {
        fileId: video.fileId,
        duration: video.duration,
        type: video.type,
        url: video.url,
      };
    } else if (element === 'circle') {
      const {style, border} = entity.get('circle') || {};
      return {
        styleColor: style.color,
        styleOpacity: style.opacity,
        borderColor: border.color,
        borderOpacity: border.opacity,
      };
    } else if (element === 'rectangle') {
      const {style, border} = entity.get('rectangle') || {};
      return {
        styleColor: style.color,
        styleOpacity: style.opacity,
        borderColor: border.color,
        borderOpacity: border.opacity,
      };
    } else if (element === 'triangle') {
      const {style, border} = entity.get('triangle') || {};
      return {
        styleColor: style.color,
        styleOpacity: style.opacity,
        borderColor: border.color,
        borderOpacity: border.opacity,
      };
    } else if (element === 'line') {
      const {style} = entity.get('line') || {};
      return {
        styleColor: style.color,
        styleOpacity: style.opacity,
      };
    }

    return null;
  };

  /**
   * Gets the component after it has been updated with the new variable value.
   *
   * @param {string} newVariableName
   * @param {*} newVariableValue
   * @returns {{}}
   */
  getUpdatedComponentWithVariable = (newVariableName, newVariableValue) => {
    const {
      /** @type {DisplayEditorStore} */ displayEditorStore,
      /** @type {ObservableMap} */ entity,
      /** @type {GameStore} */ game,
    } = this.props;

    const element = entity.get('element');
    const sourceVariables = toJS(displayEditorStore.variables);
    const composeSource = composeComponent(true, newVariableName);

    if (element === 'text') {
      const textSource = getTextForSource(entity);
      textSource.text.markdown = newVariableValue;

      return getTextFromSource(
        {...textSource, ...composeSource},
        sourceVariables
      );
    } else if (element === 'feed') {
      const feedSource = getFeedForSource(entity);
      feedSource.feed.markdown = newVariableValue;

      return getFeedFromSource(
        {...feedSource, ...composeSource},
        sourceVariables
      );
    } else if (element === 'feedicon') {
      const feedSource = getFeedIconForSource(entity);

      return getFeedIconFromSource(
        {...feedSource, ...composeSource},
        sourceVariables
      );
    } else if (element === 'image') {
      const imageSource = getImageForSource(entity, game);

      return getImageFromSource(
        {...imageSource, ...composeSource},
        sourceVariables
      );
    } else if (element === 'video') {
      const videoSource = getVideoForSource(entity);

      return getVideoFromSource(
        {...videoSource, ...composeSource},
        sourceVariables
      );
    } else if (element === 'circle') {
      const circleSource = getCircleForSource(entity);

      return getCircleFromSource(
        {...circleSource, ...composeSource},
        sourceVariables
      );
    } else if (element === 'rectangle') {
      const rectangleSource = getRectangleForSource(entity);

      return getRectangleFromSource(
        {...rectangleSource, ...composeSource},
        sourceVariables
      );
    } else if (element === 'triangle') {
      const triangleSource = getTriangleForSource(entity);

      return getTriangleFromSource(
        {...triangleSource, ...composeSource},
        sourceVariables
      );
    } else if (element === 'line') {
      const lineSource = getLineForSource(entity);

      return getLineFromSource(
        {...lineSource, ...composeSource},
        sourceVariables
      );
    }

    return {};
  };

  @action onToggleControlGroup = () => {
    this.isControlGroupOpen = !this.isControlGroupOpen;
  }

  /**
   * Starts or ends the edit variable name flow.
   *
   * @param {boolean} show
   */
  @action showHideEditVariableName = (show) => {
    if (!show) {
      this.editVariableName = null;
      return;
    }

    this.editVariableName = lodash.last(uuid().split('-'));
  };

  /**
   * Edits the variable name.
   *
   * @param {{}} changeEvent
   */
  @action onChangeVariableName = (changeEvent) => {
    this.editVariableName = String(changeEvent.target.value).replace(/['"]/g, '');
  };

  /**
   * Triggered when the variable name input is blurred.
   */
  onBlurVariableName = () => {
    const newVariableName = this.editVariableName;
    if (!newVariableName) {
      return;
    }

    const {
      /** @type {DisplayEditorStore} */ displayEditorStore,
      /** @type {ObservableMap} */ entity,
      /** @type {GameStore} */ game,
    } = this.props;

    const actionParams = {
      entityId: entity.get('id'),
    };

    const element = entity.get('element');
    const compose = entity.get('compose');

    const currentValue = displayEditorStore.getVariable(element, compose.variableName, '');
    displayEditorStore.setVariable(element, compose.variableName, undefined);
    displayEditorStore.setVariable(element, newVariableName, currentValue);

    game.addAction(actionParams, actionUpdateComponent(
      composeComponent(true, newVariableName)
    ));

    this.showHideEditVariableName(false);
  };

  /**
   * Sets the can edit flag for the entity's compose data.
   *
   * @param {{}} clickEvent
   */
  onToggleCanEdit = (clickEvent) => {
    clickEvent.preventDefault();

    const {
      /** @type {DisplayEditorStore} */ displayEditorStore,
      /** @type {ObservableMap} */ entity,
      /** @type {GameStore} */ game
    } = this.props;

    const actionParams = {
      entityId: entity.get('id'),
    };

    const compose = entity.get('compose') || {canEdit: false};
    const element = entity.get('element');

    const newCanEdit = !compose.canEdit;

    if (newCanEdit && !compose.variableName) {
      this.showHideEditVariableName(true);

      const currentValue = this.getVariableValueForEntity();
      displayEditorStore.setVariable(element, this.editVariableName, currentValue);

      game.addAction(actionParams, actionUpdateComponent(
        composeComponent(true, this.editVariableName)
      ));
      return;
    }

    game.addAction(actionParams, actionUpdateComponent(
      composeComponent(newCanEdit, compose.variableName || null)
    ));
  };

  /**
   * Renders the compose controls.
   *
   * @returns {{}}
   */
  renderComposeControls() {
    const {
      /** @type {DisplayEditorStore} */ displayEditorStore,
      /** @type {ObservableMap} */ entity,
    } = this.props;

    if (!this.isControlGroupOpen) {
      return null;
    }

    const element = entity.get('element');

    let currentVariables = {};
    if (displayEditorStore.variables.has(element)) {
      currentVariables = displayEditorStore.variables.get(element).toJSON();
    }

    const compose = entity.get('compose') || {canEdit: false};

    return (<div className="group-controls">
      <div className="row">
        <div className="col">
          <div className="form-group">
            <div><label>Can Edit In Presto</label></div>
            <div className="align-buttons form-buttons">
              <Toggle
                id="show-editable-check"
                checked={compose.canEdit}
                onChange={this.onToggleCanEdit}
                icons={false}
              />
            </div>
          </div>
        </div>
      </div>

      {(compose.canEdit) && (
        <div className="row">
          <div className="col">
            <div className="form-group">
              <div><label>Variable Name</label></div>
              <div className="form-select">
                <select
                  id="variable-name-select"
                  className="form-control"
                  value={compose.variableName}
                  onChange={this.onSelectVariableName}
                >
                  {(!compose.variableName) && (
                    <option value="">None (Can Not Edit)</option>
                  )}

                  {lodash.map(currentVariables, (variableValue, variableName) => {
                    return (
                      <option key={variableName} value={variableName}>{variableName}</option>
                    );
                  })}

                  <option value={ADD_NEW_VALUE}>Add New</option>
                </select>
              </div>

              {(this.editVariableName !== null) && (
                <div className="form-input">
                  <input
                    type="text"
                    id="variable-name-input"
                    className="form-control form-control-sm"
                    value={this.editVariableName}
                    onChange={this.onChangeVariableName}
                    onBlur={this.onBlurVariableName}
                  />
                </div>
              )}

              <small className="form-text text-muted">
              This name is shared across all aspect ratios. To link this item with another aspect ratio, make
              sure this variable name is the same.
              </small>
            </div>
          </div>
        </div>
      )}
    </div>);
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      /** @type {GameStore} */ game,
    } = this.props;

    if (game.composeMode || !this.hasPermission) {
      // Compose mode disables most editing abilities.
      return null;
    }

    return (
      <div className="edit-compose-controls control-group">
        <div className="group-header">
          <span className="group-header-label">Presto Settings</span>
          <button
            type="button"
            className="btn"
            onClick={this.onToggleControlGroup}
          >
            <FontAwesomeIcon icon={this.isControlGroupOpen ? faChevronUp : faChevronDown} />
          </button>
        </div>

        {this.renderComposeControls()}
      </div>
    );
  }
}

EditComposeControls.propTypes = {
  displayEditorStore: PropTypes.object.isRequired,
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
  apiUserGetMeStore: MobxPropTypes.observableObject,
};

EditComposeControls.wrappedComponent = {};
EditComposeControls.wrappedComponent.propTypes = {
  apiUserGetMeStore: MobxPropTypes.observableObject.isRequired,
  displayEditorStore: MobxPropTypes.observableMap,
};

export default inject(EditComposeControls)(
  observer(EditComposeControls)
);
