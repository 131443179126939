import classNames from 'classnames';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import entityActivateHoc from '../../hoc/entityActivateHoc';
import {onClickEntityEvent} from '../utils';

/**
 * The DisplayIcon component.
 */
export class DisplayIcon extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, style, className, onEntityClick, game} = this.props;

    const entityId = entity.get('id');
    const icon = entity.get('icon');

    const onClickEvent = onClickEntityEvent(game, entity, onEntityClick);

    /*
      Note: at the time of adding the "click to activate layer" feature (PS-192)
      the icon layer is currently not implemented. So this feature is untested for the icon layer.
      If we do re-add the icon layer back into the editor we need to test the "click to activate" feature
    */
    return (
      <div
        id={entityId}
        className={classNames('display-icon', className)}
        style={{
          ...style,
          pointerEvents: 'none',
        }}
      >
        <i
          style={{
            cursor: onClickEvent ? 'pointer' : 'inherit',
            pointerEvents: 'auto',
          }}

          // TODO - this needs to be updated if icon entity is ever re-added back in
          // (since we updated from fontawesome v4 to v6)
          className={classNames('fa fa-2x', `fa-${icon.type}`)}
          onClick={onClickEvent}
        />
      </div>
    );
  }
}

DisplayIcon.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
  style: PropTypes.object.isRequired,

  className: PropTypes.string,
  onEntityClick: PropTypes.func,
};

export default entityActivateHoc(
  observer(DisplayIcon)
);
