import classNames from 'classnames';
import lodash from 'lodash';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ReactSelect from 'react-select';

import statesUS from './statesUS.json';

/**
 * The list of state label/value pairs for react-select.
 *
 * @type {Array.<{label: string, value: string}>}
 */
const STATE_US_VALUES = lodash.map(statesUS, (stateName, stateAbbreviation) => {
  return {label: stateName, value: stateAbbreviation};
});

/**
 * The StateSelect component.
 */
class StateSelect extends Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {className, classNamePrefix, id, onChange, theme, value} = this.props;

    return (
      <ReactSelect
        id={id}
        className={classNames(className, 'site-select', `theme-${theme}`)}
        classNamePrefix={classNamePrefix}
        placeholder="State"
        isClearable={false}
        isMulti={false}
        onChange={onChange}
        options={STATE_US_VALUES}
        value={STATE_US_VALUES.find((option) => option.value === value)}
      />
    );
  }
}

StateSelect.propTypes = {
  onChange: PropTypes.func.isRequired,

  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  id: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark', 'input-white']),
  value: PropTypes.string,
};

StateSelect.defaultProps = {
  theme: 'light',
};

export default observer(StateSelect);
