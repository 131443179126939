import classNames from 'classnames';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import {withAuth0} from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import React from 'react';
import {faUser} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import ChargebeePortal from '../../../../common/chargebeePortal/ChargebeePortal';
import ReportBugButton from '../reportBugButton/ReportBugButton';
import Link from '../../../../common/link/Link';
import inject from '../../../../hoc/injectHoc';
import {adminContentRequestsListRoute, dashboardRoute, signsRoute, userPaymentRouteNoPlanId} from '../../../../routePaths';
import {ADMIN_ROLE, SUPER_ADMIN_ROLE} from '../../../../../constants/userConstants';
import mainConfig from '../../../../../config/main';

import './userNavItem.scss';

/**
 * The UserNavItem component.
 */
export class UserNavItem extends React.Component {
  /**
   * Triggered when the component is first mounted to the page.
   * This will make sure the logged in user information has been loaded.
   */
  componentDidMount() {
    const {apiUserGetMeStore} = this.props;

    apiUserGetMeStore.refresh();
  }

  login = async () => {
    const {loginWithRedirect} = this.props.auth0;

    try {
      loginWithRedirect();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('ERROR LOGGING IN: ', err);
    }
  }

  signup = async () => {
    const {loginWithRedirect} = this.props.auth0;

    try {
      loginWithRedirect({
        authorizationParams: {
          'screen_hint': 'signup',
        }});
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('ERROR SIGNING UP: ', err);
    }
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {apiUserGetMeStore, isResponsive, showDashboardButton, auth0} = this.props;

    return apiUserGetMeStore.case({
      pre: () => null,
      pending: () => null,
      rejected: () => (
        <>
          <li className="nav-item user-nav-item">
            <button type="button" className="nav-btn btn btn-light" onClick={this.login}>Log In</button>
          </li>
          <li className="nav-item user-nav-item">
            <button type="button" className="nav-btn btn btn-outline-light" onClick={this.signup}>Create Account</button>
          </li>
        </>
      ),
      fulfilled: (user) => {
        const companyName = user && user.company ? user.company.name : '-';

        return (<>
          {(showDashboardButton) && (
            <li className="nav-item user-nav-item">
              <Link className="nav-btn btn btn-primary" to={dashboardRoute}>Dashboard</Link>
            </li>
          )}

          <li className="nav-item user-nav-item position-relative">
            <button
              className={classNames('nav-btn btn btn-outline-light dropdown-toggle color-inherit-caret user-name', {
                'mt-3 mt-lg-0': isResponsive,
              })}
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {companyName}
            </button>

            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
              <div className="px-3 py-2">
                <FontAwesomeIcon
                  className="mr-2 text-black"
                  icon={faUser}
                />
                <span className="text-black">
                  {user.fullName || '-'}
                </span><br />
                <small className="text-muted">{user.email}</small>
              </div>
              <hr className="mt-0 mb-2" />

              <Link className="dropdown-item" to={signsRoute}>
                Signs
              </Link>

              <Link className="dropdown-item" to={userPaymentRouteNoPlanId}>
                Update Plan / Renew
              </Link>

              <ChargebeePortal user={user} />

              {(user.role === SUPER_ADMIN_ROLE || user.role === ADMIN_ROLE) && (
                <a className="dropdown-item" href={`${mainConfig.hub.appUrl}/admin/my-company`}>
                  Admin Dashboard
                </a>
              )}

              {(user.role === SUPER_ADMIN_ROLE) && (
                <Link className="dropdown-item" to={adminContentRequestsListRoute}>
                  Admin Controls
                </Link>
              )}

              <ReportBugButton user={user} />
              <button
                type="button"
                className="dropdown-item"
                onClick={() => auth0.logout({
                  logoutParams: {
                    returnTo: `${mainConfig.app.url}/dashboard`,
                  },
                })}
              >
                Sign Out
              </button>
            </div>
          </li>
        </>);
      },
    });
  }
}

UserNavItem.propTypes = {
  apiUserGetMeStore: MobxPropTypes.observableObject,
  auth0: PropTypes.object,
  isResponsive: PropTypes.bool,
  showDashboardButton: PropTypes.bool,
};

UserNavItem.wrappedComponent = {};
UserNavItem.wrappedComponent.propTypes = {
  apiUserGetMeStore: MobxPropTypes.observableObject.isRequired,
  auth0: MobxPropTypes.observableObject.isRequired,
};

export default withAuth0(inject(UserNavItem)(
  observer(UserNavItem)
));
