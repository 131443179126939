import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import moment from 'moment';
import React, {Component} from 'react';

import {MOMENT_LONG_DATE_FORMAT} from '../../../constants/uiConstants';
import Link from '../../common/link/Link';
import SmartTable from '../../common/smartTable/SmartTable';
import inject from '../../hoc/injectHoc';
import {adminBannerEditRoute, dashboardRoute} from '../../routePaths';

import './adminBannersListPage.scss';

/**
 * The AdminBannersListPage component.
 */
export class AdminBannersListPage extends Component {
  /**
   * Triggered when the component first mounts to the page.
   */
  componentDidMount() {
    const {apiAdminBannerGetAllStore} = this.props;

    apiAdminBannerGetAllStore.refresh();
  }

  /**
   * Renders the table when items are loading.
   *
   * @param {Array<{}>} columns
   * @returns {{}}
   */
  renderLoadingTable = (columns) => {
    return (
      <SmartTable
        className="table-dark table-striped table-hover"
        columns={columns}
        rows={[]}
        loading={true}
      />
    );
  };

  /**
   * Parses the content requests into the table rows.
   *
   * @param {Array<{}>} banners
   * @returns {Array<{}>}
   */
  parseBannersToRow = (banners) => {
    return banners.map((banner) => {
      return {
        id: banner.id,
        name: (
          <Link
            className="btn btn-sm btn-primary"
            to={adminBannerEditRoute}
            params={{bannerId: banner.id}}
          >{banner.id}</Link>
        ),
        type: banner.type,
        url: banner.url,
        published: banner.isPublished ? 'YES' : 'NO',
        dateUpdated: moment(banner.updatedAt || banner.createdAt).format(MOMENT_LONG_DATE_FORMAT),
      };
    });
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {apiAdminBannerGetAllStore} = this.props;

    const columns = [
      {key: 'name', label: 'BANNER ID'},
      {key: 'type', label: 'TYPE'},
      {key: 'url', label: 'LINK'},
      {key: 'published', label: 'PUBLISHED'},
      {key: 'dateUpdated', label: 'DATE UPDATED'},
    ];

    return (
      <div id="admin-banner-list-page" className="system-page full-height">
        <div className="container">
          <Link to={dashboardRoute}>
            <h4 className="h6">← Back to Dashboard</h4>
          </Link>
          {apiAdminBannerGetAllStore.case({
            pre: () => this.renderLoadingTable(columns),
            pending: () => this.renderLoadingTable(columns),
            rejected: () => (
              <div className="alert alert-warning">
                The list of banners could not be loaded.
              </div>
            ),
            fulfilled: (banners) => (
              <SmartTable
                className="table-dark table-striped table-hover"
                columns={columns}
                rows={this.parseBannersToRow(banners)}
              />
            ),
          })}
        </div>
      </div>
    );
  }
}

AdminBannersListPage.propTypes = {
  apiAdminBannerGetAllStore: MobxPropTypes.observableObject,
  routerStore: MobxPropTypes.observableObject,
};

AdminBannersListPage.wrappedComponent = {};
AdminBannersListPage.wrappedComponent.propTypes = {
  apiAdminBannerGetAllStore: MobxPropTypes.observableObject.isRequired,
  routerStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(AdminBannersListPage)(
  observer(AdminBannersListPage)
);
