/**
 * The name of the system.
 * @const {string}
 */
export const ACTION_PURGE_SYSTEM = 'actionPurgeSystem';

/**
 * Gets a new instance of the actionPurge system.
 *
 * @param {GameStore} game
 * @returns {{name: string, runActions: systemRunActions}}
 */
export function actionPurgeSystem(game) {
  /**
   * Called right before the game loop updates.
   */
  function systemRunActions() {
    // All actions must finish their work on their first loop, because they will always be purged at the end
    // of their first loop.
    game.removeAllActions();
  }

  return {
    name: ACTION_PURGE_SYSTEM,
    runActions: systemRunActions
  };
}
