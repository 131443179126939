import presets from '../../../../../display/transitionPresets';

// Group the transitions
export const TRANSITION_LIBRARY_ITEMS = {
  /** All Element Transformations **/
  // 2D Transitions
  fade: {
    key: 'fade',
    label: 'Fade',
    presets: {
      default: {
        in: presets.fadeIn,
        out: presets.fadeOut,
      }
    },
    elements: true,
  },
  easeEdge: {
    key: 'easeEdge',
    label: 'Ease From Edge',
    presets: {
      bottom: {
        in: presets.easeInEdgeBottom,
        out: presets.easeOutEdgeBottom,
      },
      left: {
        in: presets.easeInEdgeLeft,
        out: presets.easeOutEdgeLeft,
      },
      right: {
        in: presets.easeInEdgeRight,
        out: presets.easeOutEdgeRight,
      },
      top: {
        in: presets.easeInEdgeTop,
        out: presets.easeOutEdgeTop,
      },
    },
    elements: true,
  },
  overshoot: {
    key: 'overshoot',
    label: 'Overshoot',
    presets: {
      bottom: {
        in: presets.overshootInBottom,
        out: presets.overshootOutBottom,
      },
      left: {
        in: presets.overshootInLeft,
        out: presets.overshootOutLeft,
      },
      right: {
        in: presets.overshootInRight,
        out: presets.overshootOutRight,
      },
      top: {
        in: presets.overshootInTop,
        out: presets.overshootOutTop,
      },
    },
    elements: true,
  },
  bounce: {
    key: 'bounce',
    label: 'Bounce',
    presets: {
      bottom: {
        in: presets.bounceInBottom,
        out: presets.bounceOutBottom,
      },
      left: {
        in: presets.bounceInLeft,
        out: presets.bounceOutLeft,
      },
      right: {
        in: presets.bounceInRight,
        out: presets.bounceOutRight,
      },
      top: {
        in: presets.bounceInTop,
        out: presets.bounceOutTop,
      },
    },
    elements: true,
  },
  easeRotate: {
    key: 'easeRotate',
    label: 'Ease & Rotate',
    presets: {
      bottom: {
        in: presets.easeInBottom,
        out: presets.easeOutBottom,
      },
      left: {
        in: presets.easeInLeft,
        out: presets.easeOutLeft,
      },
      right: {
        in: presets.easeInRight,
        out: presets.easeOutRight,
      },
      top: {
        in: presets.easeInTop,
        out: presets.easeOutTop,
      },
    },
    elements: true,
  },
  easeScale: {
    key: 'easeScale',
    label: 'Scale',
    presets: {
      default: {
        in: presets.easeScaleIn,
        out: presets.easeScaleOut,
      }
    },
    elements: true,
  },

  // Text Transitions
  wordFade: {
    key: 'wordFade',
    label: 'Word Fade',
    presets: {
      default: {
        in: presets.wordFadeIn,
        out: presets.wordFadeOut,
      }
    },
    elements: ['text'],
  },
  wordCrissCross: {
    key: 'wordCrissCross',
    label: 'Word Criss Cross',
    presets: {
      bottom: {
        in: presets.wordCrissCrossInBottom,
        out: presets.wordCrissCrossOutBottom,
      },
      top: {
        in: presets.wordCrissCrossInTop,
        out: presets.wordCrissCrossOutTop,
      }
    },
    elements: ['text'],
  },
  wordOvershootScale: {
    key: 'wordOvershootScale',
    label: 'Word Overshoot Scale',
    presets: {
      default: {
        in: presets.wordOvershootScaleIn,
        out: presets.wordOvershootScaleOut,
      }
    }
  },
  wordScale: {
    key: 'wordScale',
    label: 'Word Scale',
    presets: {
      default: {
        in: presets.wordScaleIn,
        out: presets.wordScaleOut,
      }
    },
    elements: ['text'],
  },
  wordScaleSnap: {
    key: 'wordScaleSnap',
    label: 'Word Scale & Snap',
    presets: {
      bottom: {
        in: presets.wordScaleSnapInBottom,
        out: presets.wordScaleSnapOutBottom,
      },
      left: {
        in: presets.wordScaleSnapInLeft,
        out: presets.wordScaleSnapOutLeft,
      },
      right: {
        in: presets.wordScaleSnapInRight,
        out: presets.wordScaleSnapOutRight,
      },
      top: {
        in: presets.wordScaleSnapInTop,
        out: presets.wordScaleSnapOutTop,
      },
    },
    elements: ['text'],
  },

  // Character / Letter Transitions
  letterFadePosition: {
    key: 'letterFadePosition',
    label: 'Letter Fade & Position',
    presets: {
      bottom: {
        in: presets.letterFadePositionInBottom,
        out: presets.letterFadePositionOutBottom,
      },
      left: {
        in: presets.letterFadePositionInLeft,
        out: presets.letterFadePositionOutLeft,
      },
      right: {
        in: presets.letterFadePositionInRight,
        out: presets.letterFadePositionOutRight,
      },
      top: {
        in: presets.letterFadePositionInTop,
        out: presets.letterFadePositionOutTop,
      },
    },
    elements: ['text'],
  },
  letterFadeScaleStart: {
    key: 'letterFadeScaleStart',
    label: 'Letter Fade & Scale & Track From Start',
    presets: {
      default: {
        in: presets.letterFadeScaleStartIn,
        out: presets.letterFadeScaleStartOut,
      }
    },
    elements: ['text'],
  },
  letterFade: {
    key: 'letterFade',
    label: 'Letter Fade',
    presets: {
      start: {
        in: presets.letterFadeInStart,
        out: presets.letterFadeOutStart,
      },
      end: {
        in: presets.letterFadeInEnd,
        out: presets.letterFadeOutEnd,
      }
    },
    elements: ['text'],
  },
  letterFadeUnroll: {
    key: 'letterFadeUnroll',
    label: 'Letter Fade & Unroll',
    presets: {
      bottom: {
        in: presets.letterFadeUnrollInBottom,
        out: presets.letterFadeUnrollOutBottom,
      },
      left: {
        in: presets.letterFadeUnrollInLeft,
        out: presets.letterFadeUnrollOutLeft,
      },
      right: {
        in: presets.letterFadeUnrollInRight,
        out: presets.letterFadeUnrollOutRight,
      },
      top: {
        in: presets.letterFadeUnrollInTop,
        out: presets.letterFadeUnrollOutTop,
      },
    },
    elements: ['text'],
  },
  charOvershootRotate: {
    key: 'charOvershootRotate',
    label: 'Character Overshoot & Rotate',
    presets: {
      default: {
        in: presets.charOvershootRotateIn,
        out: presets.charOvershootRotateOut,
      }
    },
    elements: ['text'],
  }
};

// Deprecated Transitions

// fadeFlyOut: {
//   label: 'Fade & Fly Out',
//   options: ['right'],
//   right: presets.fadeFlyOutRight,
//   elements: true,
// },
// letterRise: {
//   label: 'Letter Rise',
//   options: ['in', 'out'],
//   in: presets.letterRiseIn,
//   out: presets.letterRiseOut,
//   elements: ['text'],
// },
// letterDrop: {
//   label: 'Letter Drop',
//   options: ['in', 'out'],
//   in: presets.letterDropIn,
//   out: presets.letterDropOut,
//   elements: ['text'],
// },
// letterTranslate: {
//   label: 'Letter Translate',
//   options: ['in'],
//   in: presets.letterTranslateIn,
//   elements: ['text'],
// },
// flyIn: {
//   label: 'Fly In',
//   options: ['bottom', 'left', 'right', 'top', 'toward'],
//   bottom: presets.flyInBottom,
//   left: presets.flyInLeft,
//   right: presets.flyInRight,
//   top: presets.flyInTop,
//   toward: presets.flyInToward,
//   elements: true,
//   flow: 'in',
// },
// flyOut: {
//   label: 'Fly Out',
//   options: ['bottom', 'left', 'right', 'top'],
//   bottom: presets.flyOutBottom,
//   left: presets.flyOutLeft,
//   right: presets.flyOutRight,
//   top: presets.flyOutTop,
//   elements: true,
//   flow: 'out',
// },
// letterMeteor: {
//   label: 'Letter Meteor',
//   options: ['in'],
//   in: presets.letterMeteorIn,
//   elements: ['text'],
// },
// rotate: {
//   label: 'Rotate',
//   options: ['default'],
//   default: presets.rotate,
//   elements: true,
// },
// zIndexRiseIntoPlace: {
//   label: 'Z Index - Rise Into Place',
//   options: ['default'],
//   default: presets.zIndexRiseIntoPlace,
//   elements: true,
// },
// letterRealityIsBroken: {
//   label: 'Letter Reality Is Broken',
//   options: ['default'],
//   default: presets.letterRealityIsBroken,
//   elements: ['text'],
// },
// wordMeteorIn: {
//   label: 'Word Meteor',
//   options: ['in'],
//   in: presets.wordMeteorIn,
//   elements: ['text'],
//   flow: 'in',
// },
// wordMeteorOut: {
//   label: 'Word Meteor',
//   options: ['out'],
//   out: presets.wordMeteorOut,
//   elements: ['text'],
//   flow: 'out',
// },
// letterDropIn: {
//   label: 'Letter Drop',
//   options: ['in'],
//   in: presets.letterDropIn,
//   elements: ['text'],
//   flow: 'in',
// },
// letterDropOut: {
//   label: 'Letter Drop',
//   options: ['out'],
//   out: presets.letterDropOut,
//   elements: ['text'],
//   flow: 'out',
// },
// letterRiseIn: {
//   label: 'Letter Rise',
//   options: ['in'],
//   in: presets.letterRiseIn,
//   elements: ['text'],
//   flow: 'in',
// },
// letterRiseOut: {
//   label: 'Letter Rise',
//   options: ['out'],
//   out: presets.letterRiseOut,
//   elements: ['text'],
//   flow: 'out',
// },
