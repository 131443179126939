import {action} from 'mobx';

import apiCollectionGetContentStore from '../collection/apiCollectionGetContentStore';
import ApiBaseStore from '../common/apiBaseStore';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';

/**
 * ApiContentPublishStore
 */
class ApiContentPublishStore extends ApiBaseStore {
  /**
   * Fetches content info from the server
   *
   * @param {number|string} contentId
   * @param {{new: {collectionId: number, contentName: string}, replace: {contentId: number}}} publishData
   */
  @action makeRequest(contentId, publishData) {
    if (!contentId) {
      this.state = STATE_REJECTED;
      this.error = new Error('Invalid content id given to contentPublish.');
      return;
    } else if (!publishData.new && !publishData.replace) {
      this.state = STATE_REJECTED;
      this.error = new Error('Invalid publish data given to contentPublish.');
      return;
    }

    const safeContentId = String(contentId);

    this.state = STATE_PENDING;
    this[EXPIRE_TIME] = Date.now() + EXPIRES_PENDING;

    let collectionId = null;
    let safePublishContent = {
      isFree: publishData.isFree,
    };
    if (publishData.new) {
      collectionId = publishData.new.collectionId;
      safePublishContent.new = publishData.new;
    } else if (publishData.replace) {
      collectionId = publishData.replace.collectionId;
      safePublishContent.replace = publishData.replace;
    }

    serverApi.contentPublish(
      safeContentId,
      safePublishContent
    ).then(
      action('contentPublishSuccess', (response) => {
        this.error = null;
        this.state = STATE_FULFILLED;
        this.data = response;
        this[EXPIRE_TIME] = Date.now() + EXPIRES_IN;

        // Clear the collection cache.
        apiCollectionGetContentStore.refresh(collectionId, true);
      }),
      action('contentPublishError', (error) => {
        this.error = error;
        this.state = STATE_REJECTED;
        this.data = false;
      })
    );
  }
}

export default new ApiContentPublishStore();
