import lodash from 'lodash';
import {runInAction} from 'mobx';
import uuid from 'uuid/v4';

import {groupComponent} from '../components/common/groupComponent';
import {setEntityComponents, updateEntity} from '../ecs/entityHelper';

/**
 * The name of the system.
 * @const {string}
 */
export const GROUPING_SYSTEM = 'groupingSystem';

/**
 * Gets a new instance of the grouping system.
 *
 * @returns {{name: string, runActions: systemRunActions}}
 */
export function groupingSystem() {
  /**
   * Called right before the game loop updates.
   *
   * @param {Array.<{}>} actions
   * @param {Array.<{}>} entities
   */
  function systemRunActions(actions, entities) {
    actions.forEach((actionEntity) => {
      if (!actionEntity.has('actionGroup')) {
        return;
      }

      const action = actionEntity.get('action');
      const activeEntityIds = action.entityId;

      if (!activeEntityIds || typeof activeEntityIds === 'string') {
        return;
      } else if (activeEntityIds.length < 2) {
        return;
      }

      const activeEntities = lodash.intersectionBy(entities, activeEntityIds, (item) => {
        if (lodash.isString(item)) {
          return item;
        }
        return item.get('id');
      });

      if (!activeEntities || activeEntities.length < 2) {
        return;
      }

      const actionGroup = actionEntity.get('actionGroup');
      let groupId = actionGroup.id;
      if (!groupId) {
        groupId = uuid();
      }

      const isUnGroup = actionGroup.isUnGroup;

      activeEntities.forEach((entity) => {
        // Now update the entity.
        runInAction('groupingSystemUpdateEntity', () => {
          if (isUnGroup) {
            updateEntity(entity, 'group', null);
          } else if (entity.has('group')) {
            updateEntity(entity, 'group', {id: groupId});
          } else {
            setEntityComponents(entity, groupComponent(groupId));
          }
        });
      });
    });
  }

  return {
    name: GROUPING_SYSTEM,
    runActions: systemRunActions
  };
}
