import {action, computed} from 'mobx';

import {EXPIRES_IN, EXPIRES_PENDING, EXPIRE_TIME} from '../../../constants/storeConstants';
import {STATE_FULFILLED, STATE_PENDING, STATE_REJECTED} from '../../../constants/asyncConstants';
import serverApi from '../../../utils/serverApi';
import ApiBaseStore from '../common/apiBaseStore';

/**
 * ApiCollectionGetAllStore
 */
class ApiCollectionGetAllStore extends ApiBaseStore {
  /**
   * Whether or not the collections list is empty.
   *
   * @returns {?boolean}
   */
  @computed get isEmpty() {
    if (this.state !== STATE_FULFILLED) {
      return null;
    }

    const fulfilled = this.getFulfilled();
    return Boolean(!fulfilled || !fulfilled.length);
  }

  /**
   * Fetches collections from the server.
   */
  @action makeRequest() {
    this.state = STATE_PENDING;
    this[EXPIRE_TIME] = Date.now() + EXPIRES_PENDING;

    serverApi.collectionGetAll()
      .then(action('collectionGetAllSuccess', (collections) => {
        if (!Array.isArray(collections)) {
          throw new Error('Server did not return an array for .collectionGetAll request');
        }

        this.state = STATE_FULFILLED;
        this[EXPIRE_TIME] = Date.now() + EXPIRES_IN;
        this.data = collections;
        this.error = null;
      })).catch(action('collectionGetAllError', (collectionGetAllError) => {
        this.state = STATE_REJECTED;
        this[EXPIRE_TIME] = null;
        this.data = null;
        this.error = collectionGetAllError;
      }));
  }
}

export default new ApiCollectionGetAllStore();
