import PropTypes from 'prop-types';
import React from 'react';
import {Modal, ModalBody, ModalFooter} from 'reactstrap';

import './confirmModal.scss';

/**
 * The ConfirmModal component.
 */
class ConfirmModal extends React.Component {
  /**
   * Triggered when the modal is cancelled.
   */
  onCancelModal = () => {
    const {onComplete} = this.props;

    onComplete(false);
  };

  /**
   * Triggered when the modal is completed.
   */
  onCompleteModal = () => {
    const {onComplete} = this.props;

    onComplete(true);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {isOpen, bodyText, helpText, isYesNo, confirmText} = this.props;

    if (!isOpen) {
      return null;
    }

    let body = bodyText;
    if (bodyText && typeof bodyText === 'function') {
      body = bodyText();
    }

    let help = helpText;
    if (helpText && typeof helpText === 'function') {
      help = helpText();
    }

    return (
      <Modal className="confirm-modal" isOpen={isOpen} toggle={this.onCancelModal} centered>
        <ModalBody>
          <div className="site-label">{confirmText}</div>

          {(body) && (
            <div className="confirm-body">
              {body}
            </div>
          )}

          {(help) && (
            <small className="text-muted">
              {help}
            </small>
          )}
        </ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-default" onClick={this.onCancelModal}>
            {isYesNo ? 'No' : 'Cancel'}
          </button>
          <button type="button" className="btn btn-dark" onClick={this.onCompleteModal} data-cy="confirm-modal-confirm-button">
            {isYesNo ? 'Yes' : 'Ok'}
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

ConfirmModal.propTypes = {
  confirmText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,

  bodyText: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.element]),
  helpText: PropTypes.string,
  isYesNo: PropTypes.bool,
};

ConfirmModal.defaultProps = {
  helpText: null,
  isYesNo: false,
};

export default ConfirmModal;
