import lodash from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {observer} from 'mobx-react';

import {getAssetUrl} from '../../../../../utils/assetHelper';

import './contentPreview.scss';

/**
 * The URL to the placeholder template image.
 * @type {string}
 */
const placeholderTemplateUrl = getAssetUrl('placeholder-template.png');

/**
 * The ContentPreview component.
 */
class ContentPreview extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {content} = this.props;
    if (!content) {
      return null;
    }

    // Determine if item is being rendered or had a render error.
    const isProcessing = (content.displayStatus === 'processing');
    const hasRenderingError = (content.displayStatus === 'error');

    let thumbnailImageUrl = lodash.get(content, 'thumbnailFile.displayPath');
    if (!thumbnailImageUrl) {
      thumbnailImageUrl = placeholderTemplateUrl;
    }

    const videoPreviewUrl = lodash.get(content, 'videoFile.displayPath');
    const previewIsMp4 = (String(videoPreviewUrl).indexOf('.mp4') !== -1);
    const showVideoPreview = Boolean(videoPreviewUrl && !isProcessing && !hasRenderingError);
    const isDraft = Boolean(lodash.get(content, 'isDraft', false));
    const showPlaceholder = Boolean(!showVideoPreview && !isDraft && !isProcessing && !hasRenderingError);

    return (
      <div className="content-preview ratio ratio-16x9">
        {(showVideoPreview) && (
          <video
            className="modal-preview"
            muted
            playsInline
            controls
            controlsList="nodownload"
            onContextMenu={(e) => e.preventDefault()}
            autoPlay={true}
          >
            {(previewIsMp4) ? (
              <source
                src={videoPreviewUrl}
                type="video/mp4"
              />
            ) : (
              <>
                <source
                  src={videoPreviewUrl}
                  type="video/webm"
                />
                <source
                  src={videoPreviewUrl.replace('.webm', '.mov')}
                  type="video/quicktime"
                />
              </>
            )}

            Your browser is not supported for playing this video. Please use the latest Chrome or Firefox browser.
          </video>
        )}

        {(showPlaceholder) && (
          <div className="modal-image" style={{backgroundImage: `url('${thumbnailImageUrl}')`, paddingTop: '56.25%'}} />
        )}
      </div>
    );
  }
}

ContentPreview.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
};

export default observer(ContentPreview);
