export const ALA_CARTE_CREDITS = {
  download: {
    id: 'download',
    chargebeeId: 'download-credit-USD',
    name: 'Download',
    header: 'Presto Sign Downloads',
    title: 'Access over 1,000 professional templates',
    description: 'Our designs, your message',
    pricePerCredit: 2500,
    features: [
      'One-time purchase',
      'Credits expire one month from purchase',
      'Easily edit, render & download'
    ],
    style: {
      header: 'js-primary',
      background: 'js-gray-100',
    }
  },
  request: {
    id: 'request',
    chargebeeId: 'request-credit-USD',
    name: 'Custom Request',
    header: 'Custom Sign Designs',
    title: 'Utilize our professional designers',
    description: 'With years of experience, our designers are sure to wow you!',
    pricePerCredit: 9900,
    features: [
      'One-time purchase',
      'Credits expire one month from purchase',
      'Up to 3 revisions'
    ],
    style: {
      header: 'js-secondary',
      background: 'js-gray-200',
    }
  }
};

export const ALA_CARTE_CREDIT_LIST = [
  ALA_CARTE_CREDITS.download,
  ALA_CARTE_CREDITS.request
];
