import PropTypes from 'prop-types';
import React from 'react';
import {observable} from 'mobx';
import {Modal, ModalBody} from 'reactstrap';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';

import inject from '../../hoc/injectHoc';
import ContentPreview from './components/contentPreview/ContentPreview';
import ContentRequestButton from '../../common/mainNavBar/components/contentRequestButton/ContentRequestButton';

/**
 * The CollectionExampleInfoModal component.
 */
class CollectionExampleInfoModal extends React.Component {
  /**
   * The collection data.
   *
   * @type {string}
   */
  @observable collection = {};

  /**
   * Refreshes Collection Example Information
   */
  componentDidMount() {
    const {apiCollectionGetOneStore, listId} = this.props;

    apiCollectionGetOneStore.refresh(listId, true);
  }

  /**
   * Triggered when Learn More button is clicked. Opens custom content request form.
   * @param {string} url URL Associated with the Collection Example
   */
  onLearnMoreClick = (url) => {
    window.open(url, '_blank');
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {apiCollectionGetOneStore, listId, onClose} = this.props;

    return apiCollectionGetOneStore.case(listId, {
      pre: () => null,
      pending: () => null,
      // eslint-disable-next-line complexity
      fulfilled: (collection) => {
        return (
          <Modal className="content-info-modal" isOpen={true} toggle={onClose} centered>
            <ContentPreview content={collection.collectionExample} />

            <ModalBody>
              <div className="modal-body-content">
                <div className="row align-items-center">
                  <h4 className="col-sm-12 col-md-5 mb-0 font-weight-light">
                    Custom Content Examples
                  </h4>

                  <div className="col-sm-12 col-md-7 text-md-right">
                    <ContentRequestButton
                      isBadge={false}
                    />
                  </div>
                </div>
              </div>

              <div className="content-admin-bar d-flex align-items-center justify-content-end">
                <a
                  className="btn btn-light info-btn-responsive"
                  href={collection.collectionExample.url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Learn More
                </a>
              </div>
            </ModalBody>
          </Modal>
        );
      }
    });
  }
}

CollectionExampleInfoModal.propTypes = {
  apiCollectionGetOneStore: MobxPropTypes.observableObject,
  listId: PropTypes.number,
  onClose: PropTypes.func,
  routerStore: MobxPropTypes.observableObject,
};

CollectionExampleInfoModal.wrappedComponent = {};
CollectionExampleInfoModal.wrappedComponent.propTypes = {
  apiCollectionGetOneStore: MobxPropTypes.observableObject.isRequired,
  routerStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(CollectionExampleInfoModal)(
  observer(CollectionExampleInfoModal)
);
