import lodash from 'lodash';
import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {findDOMNode} from 'react-dom';

import {between} from '../../../utils/mathHelper';
import sliderInteractHoc from './hoc/sliderInteractHoc';

import './timelineSlider.scss';

/**
 * The pixel size for the handle radius.
 * This must match the $handle-radius variable in multiSlider.scss.
 *
 * @const {number}
 */
const HANDLE_RADIUS = 6;

/**
 * The TimelineSlider component.
 */
export class TimelineSlider extends React.Component {
  /**
   * The element node.
   *
   * @type {?HTMLElement}
   */
  @observable domEl = null;

  /**
   * @constructor
   * @param {{}} props
   * @param {{}} componentContext
   */
  constructor(props, componentContext) {
    super(props, componentContext);
  }

  /**
   * Calculates the x position for the handle.
   *
   * @param {number} xValue
   * @returns {number}
   */
  calculateLineXFromLeft = (xValue) => {
    const {maxValue, minValue} = this.props;
    const width = this.getSliderWidth();

    const totalDelta = maxValue - minValue;
    const adjustedValue = xValue - minValue;
    const normalizedX = (adjustedValue / totalDelta) * width;

    return Math.round(normalizedX);
  };

  /**
   * Gets the width of the slider.
   *
   * @returns {number}
   */
  getSliderWidth = () => {
    const placeholderWidth = 100;
    return lodash.get(this.domEl, 'clientWidth', placeholderWidth);
  };

  /**
   * Triggered right after the wrapped component is added to OR removed from the page.
   *
   * @param {{}} domEl
   */
  @action onChangeMount = (domEl) => {
    if (this.domEl) {
      return;
    }

    this.domEl = findDOMNode(domEl);
  };

  /**
   * Gets the current values.
   *
   * @returns {number[]}
   */
  getValues = () => {
    const {maxValue, minValue, startValue, endValue} = this.props;
    let safeStartValue = between(startValue, minValue, maxValue);
    const safeEndValue = between(endValue, minValue, maxValue);
    if (safeStartValue > safeEndValue) {
      safeStartValue = safeEndValue;
    }

    return [
      safeStartValue,
      safeEndValue,
    ];
  };

  /**
   * Gets the current transitions.
   *
   * @returns {number[]}
   */
  getTransitions = () => {
    const {maxValue, minValue, inValue, outValue} = this.props;
    let safeTransitionValues = [];
    if (inValue || outValue) {
      safeTransitionValues = [
        between(inValue, minValue, maxValue),
        between(outValue, minValue, maxValue)
      ];
    }

    return [
      ...safeTransitionValues,
    ];
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {isActive} = this.props;
    const [start, end] = this.getValues();
    const [inValue, outValue] = this.getTransitions();

    const timelineSliderClasses = {
      'active': isActive,
    };

    const trackStyle = {
      width: this.calculateLineXFromLeft(end - start),
      left: this.calculateLineXFromLeft(start),
    };

    const transitionInValueRelativeToTrack = inValue
      ? this.calculateLineXFromLeft(inValue) - HANDLE_RADIUS - trackStyle.left
      : 0;

    // determine how many pixels from the right of the track the transitionOut should exist
    const transitionOutValueRelativeToTrack = outValue
      ? trackStyle.left + trackStyle.width - this.calculateLineXFromLeft(outValue)
      : 0;

    return (
      <div className={classNames('timeline-slider', timelineSliderClasses)} ref={this.onChangeMount}>
        {/* track */}
        <div className="timeline-slider-track" style={trackStyle}>
          {/* track start handler */}
          <div className="timeline-slider-handle start-handle"
            style={{
              left: 0 - HANDLE_RADIUS,
              right: 'auto',
            }}
          />
          {/* track end handler */}
          <div className="timeline-slider-handle end-handle"
            style={{
              left: 'auto',
              right: 0 - HANDLE_RADIUS,
            }}
          />

          {/* transition in handler */}
          {(!!transitionInValueRelativeToTrack) && (
            <div
              className="timeline-transition-handle in-handle"
              style={{
                left: transitionInValueRelativeToTrack,
                right: 'auto',
              }}
            >
              <div className="timeline-transition-handle-text">
                TR in
              </div>
              <div className="timeline-transition-handle-marker" />
            </div>
          )}

          {/* transition out handler */}
          {(!!outValue) && (
            <div
              className="timeline-transition-handle out-handle"
              style={{
                left: 'auto',
                right: transitionOutValueRelativeToTrack,
              }}
            >
              <div className="timeline-transition-handle-text right-text">
                TR out
              </div>
              <div className="timeline-transition-handle-marker" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

TimelineSlider.propTypes = {
  endValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  onChangeEnd: PropTypes.func.isRequired,
  onChangeIn: PropTypes.func.isRequired,
  onChangeOut: PropTypes.func.isRequired,
  onChangeStart: PropTypes.func.isRequired,
  startValue: PropTypes.number.isRequired,

  inValue: PropTypes.number,
  isActive: PropTypes.bool,
  minSeparation: PropTypes.number,
  outValue: PropTypes.number,
};

TimelineSlider.defaultProps = {
  minSeparation: 0,
};

export default sliderInteractHoc(
  observer(TimelineSlider)
);
