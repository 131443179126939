import lodash from 'lodash';

/**
 * Uses webpack's require.context to get all thunk files.
 *
 * @returns {Object}
 */
function getThunkContext() {
  return require.context('./', true, /^\.\/([^/]+\/)*.+Thunk\.js$/);
}

/**
 * Inject all the thunks from thunks/* folders using Webpack magic (require.context).
 * We are doing this in lieu of importing each thunk and manually doing an Object.assign().
 *
 * @param {Object} thunkContext
 * @returns {Object.<string, {
 *   dispose: ?function,
 *   init: ?function,
 *   reloadData: ?function
 * }>} A object map of all the thunks.
 */
function getThunks(thunkContext) {
  const thunkModules = thunkContext || getThunkContext();

  const allThunks = {};

  thunkModules.keys().forEach(function parseModulesIntoThunks(modulePath) {
    const moduleData = thunkModules(modulePath);

    let thunkName = moduleData.thunkName;
    if (!thunkName) {
      const nameParts = String(modulePath).split('/');
      thunkName = nameParts.pop().split('.')[0];
    }

    allThunks[thunkName] = moduleData;
  });

  return allThunks;
}

// Inject all the thunks from thunks/* folders using Webpack magic (require.context).
// We are doing this in lieu of importing each thunk and manually doing an Object.assign().
const thunkModules = getThunkContext();
const providedThunks = getThunks(thunkModules);

/**
 * Hot reloads the thunks.
 */
export function hotReloadThunks() {
  if (!module.hot) {
    return;
  }

  // Enable Webpack hot module replacement for thunks
  module.hot.accept(thunkModules.id, () => {
    lodash.forEach(getThunks(), (newThunk, newThunkId) => {
      const oldThunk = providedThunks[newThunkId];
      providedThunks[newThunkId] = newThunk;

      if (!oldThunk.dispose || !newThunk.init) {
        return;
      }
      oldThunk.dispose();
      newThunk.init();
    });
  });
}

/**
 * Initializes the thunks.
 */
export function initThunks() {
  lodash.forEach(providedThunks, (thunk) => {
    if (thunk.doNotAutoInit || !thunk.init) {
      return;
    }

    thunk.init();
  });
}

export default providedThunks;
