import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';

import prestoLogo from '../../../assets/images/presto.svg';

import inject from '../../hoc/injectHoc';

import './collectionExample.scss';

/**
 * CollectionExample component
 */
class CollectionExample extends Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {collectionExample, onClick} = this.props;

    const thumbnailUrl = collectionExample
      && collectionExample.thumbnailFile
      && collectionExample.thumbnailFile.displayPath
      ? collectionExample.thumbnailFile.displayPath
      : prestoLogo;

    return (<>
      <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-one-fifth">
        <div
          className="d-flex flex-column collection-example"
          onClick={() => onClick('example')}
        >
          <div className="container-16-9">
            <div
              className="img collection-example-thumbnail"
              style={{backgroundImage: `url('${thumbnailUrl}')`}}
            />
          </div>
          <div className="collection-example-info p-3">
            <div className="collection-example-name">
              Custom Content
            </div>
          </div>
        </div>
      </div>
    </>);
  }
}

CollectionExample.propTypes = {
  collectionExample: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

CollectionExample.wrappedComponent = {};
CollectionExample.wrappedComponent.propTypes = {};

export default inject(CollectionExample)(
  observer(CollectionExample)
);
