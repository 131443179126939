import {action, observable, runInAction} from 'mobx';
import {observer} from 'mobx-react';
import React from 'react';

import Display from '../../display/display/Display';
import {loadGameFromSource} from '../../../display/game';

import './renderPage.scss';

/**
 * If display height adjustment fails, this will be the default height.
 * @const {number}
 */
const DEFAULT_DISPLAY_HEIGHT = 360;

/**
 * The height of the display will be set to (#main-page height * x) where x is this value.
 * @const {number}
 */
const DISPLAY_SHRINK_PERCENTAGE = 0.7;

/**
 * The update source function that takes in the video source and updates the render component.
 *
 * @type {?function}
 */
let updateSource = null;

/**
 * Stores the game/video timer.
 *
 * @type {?GameTimerStore}
 */
let videoTimer = null;

/**
 * Loads the video source.
 * This will be called by phantomJS.
 *
 * @param {{}} loadVideoSource
 * @param {{}} loadVideoVariables
 */
window.loadVideo = function loadVideo(loadVideoSource, loadVideoVariables) {
  updateSource(loadVideoSource, loadVideoVariables);
};

/**
 * Initializes the video steps.
 * This will be called by phantomJS.
 */
window.initSteps = function initSteps() {
  videoTimer.initSteps();
};

/**
 * Steps through the video, 1/60th of a second at a time.
 * This will be called by phantomJS.
 *
 * @param {number} time Increases the time value by this much (in milliseconds).
 * @param {boolean=} setTime Set the time to the given value instead of stepping it by that much.
 * @returns {boolean}
 */
window.step = function step(time, setTime) {
  return videoTimer.step(Number.parseFloat(time), setTime);
};

/**
 * The RenderPage component.
 */
export class RenderPage extends React.Component {
  /**
   * The current height of the display.
   * This will change as the window resizes.
   *
   * @type {number}
   */
  @observable displayHeight = DEFAULT_DISPLAY_HEIGHT;

  /**
   * The loaded game.
   *
   * @type {{}}
   */
  @observable game = null;

  /**
   * The loaded timer.
   *
   * @type {{}}
   */
  @observable timer = null;

  /**
   * @constructor
   * @param {{}} props
   * @param {{}} componentContext
   */
  constructor(props, componentContext) {
    super(props, componentContext);

    this.defineUpdateSource();
  }

  /**
   * Triggered when the component is added to the page.
   */
  componentDidMount() {
    this.updateDisplaySize();
  }

  /**
   * Triggered when the component is removed from the page.
   */
  componentWillUnmount() {
    runInAction('Unmount Render Page', () => {
      this.game = null;
      this.timer = null;
    });
  }

  /**
   * Defines the updateSource window function for use by phantomJS.
   */
  @action defineUpdateSource = () => {
    updateSource = (newSource, variables) => {
      const {game, timer} = loadGameFromSource(newSource, variables || {}, false);

      timer.pause();

      // Indicate that the game is running in render mode.
      game.setIsRender(true);
      game.setComposeMode(false);

      runInAction('defineRenderPageSettings', () => {
        this.displayHeight = game.resolution.height;
        this.game = game;
        this.timer = timer;
      });

      videoTimer = timer;
    };
  };

  /**
   * Updates the zoom display size.
   */
  updateDisplaySize = () => {
    const editorPage = document.getElementById('main-page');
    if (!editorPage) {
      return;
    }

    runInAction('renderPageUpdateDisplaySize', () => {
      this.displayHeight = editorPage.clientHeight * DISPLAY_SHRINK_PERCENTAGE;
    });
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {game} = this;

    return (
      <div id="render-page" className="system-page">
        <div className="render-display">
          {(game) && (
            <Display game={game} height={this.displayHeight} allowScaling={true} />
          )}
        </div>
      </div>
    );
  }
}

RenderPage.propTypes = {};

export default observer(RenderPage);
