import lodash from 'lodash';

import {EASINGS} from '../../ecs/easingHelper';
import letterFadeScaleStartInGif from '../../../assets/images/transitions/letterFadeScaleStartIn.gif';

/**
 * The name that signals this preset.
 * @const {string}
 */
export const PRESET_LETTER_FADE_SCALE_START_IN = 'letterFadeScaleStartIn';

/**
 * The preset details.
 *
 * @type {{
 *   name: string,
 *   flow: string,
 *   details: Object.<string, {
 *     easing: string,
 *     parse: boolean,
 *     values: *|Array.<*>,
 *     time: {start: (string|number), end: (string|number)}
 *   }>,
 *   template: {}
 * }}
 */
export const letterFadeScaleStartInPresetDetails = {
  // The name of the preset.
  name: PRESET_LETTER_FADE_SCALE_START_IN,

  // A human friendly name for the preset.
  humanName: 'Letter - (Fade & Scale & Track) In From Start',

  // A preview of the transition.
  gif: letterFadeScaleStartInGif,

  // The element names that this preset can be used for or true for all elements.
  elements: ['text'],

  // The preset direction: in, out, middle. This is used in the UI logic.
  flow: 'in',

  // Each property that will change during the transition.
  details: {
    'letter.scaleX': {
      time: {
        end: 'start.947',
        start: 'start.0'
      },
      easing: EASINGS.linear,
      values: [
        0.7,
        1
      ],
      immediateRender: true,
      stagger: false
    },
    'letter.opacity': {
      time: {
        end: 'start.947',
        start: 'start.0'
      },
      easing: EASINGS.linear,
      values: [
        0,
        1
      ],
      immediateRender: true,
      stagger: 100
    },
    'transform.scaleY': {
      time: {
        end: 'start.947',
        start: 'start.0'
      },
      easing: EASINGS.linear,
      values: [
        0.7,
        1
      ],
      immediateRender: true
    },
    'transform.perspective': {
      time: {
        end: 'start.947',
        start: 'start.0'
      },
      easing: EASINGS.linear,
      values: [
        15,
        50
      ],
      immediateRender: true
    },
    'transform.translate3d.z': {
      time: {
        end: 'start.947',
        start: 'start.0'
      },
      easing: EASINGS.linear,
      values: [
        9,
        0
      ],
      immediateRender: true
    },
  },

  // How this preset can be used in the source (including optional parameters).
  // This will be turned into a JSON string, so no functions can be used.
  template: {
    preset: PRESET_LETTER_FADE_SCALE_START_IN,
    options: {},
  },
};

letterFadeScaleStartInPresetDetails.template.options = lodash.cloneDeep(letterFadeScaleStartInPresetDetails.details);

// This defines the key in the presets object.
export const presetName = PRESET_LETTER_FADE_SCALE_START_IN;

// This defines the value of the presets object.
export default letterFadeScaleStartInPresetDetails;
