import lodash from 'lodash';

/**
 * The name of the rectangle crop shape.
 * @const {string}
 */
export const RECTANGLE = 'rectangle';

/**
 * The name of the circle crop shape.
 * @const {string}
 */
export const CIRCLE = 'circle';

/**
 * The name of the ellipse crop shape.
 * @const {string}
 */
export const ELLIPSE = 'ellipse';

/**
 * The name of the triangle crop shape.
 * @const {string}
 */
export const TRIANGLE = 'triangle';

/**
 * The available crop shapes.
 * @const {string[]}
 */
const CROP_SHAPES = [CIRCLE, ELLIPSE, RECTANGLE, TRIANGLE];

/**
 * The crop shape component.
 *
 * @param {string} shapeName The name of the shape (circle, triangle, etc).
 * @returns {{cropShape: {name: string}}}
 */
export function cropShapeComponent(shapeName) {
  const safeShapeName = String(shapeName).trim();

  if (!lodash.includes(CROP_SHAPES, safeShapeName)) {
    return {};
  }

  return {
    cropShape: {
      name: safeShapeName,
    },
  };
}

/**
 * Gets the crop shape component from the source item.
 *
 * @param {{setup: {cropShape: {}}}} item
 * @returns {{cropShape: {name: string}}}
 */
export function getCropShapeFromSource(item) {
  const itemSetup = item.setup || {};
  if (!itemSetup.cropShape) {
    return {};
  }

  const cropShape = itemSetup.cropShape;

  return cropShapeComponent(
    cropShape.name,
  );
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {ObservableMap} entity
 * @returns {{}}
 */
export function getCropShapeForSource(entity) {
  if (!entity.has('cropShape')) {
    return {};
  }

  const cropShape = entity.get('cropShape');

  const safeShapeName = String(cropShape.name).trim();
  if (!lodash.includes(CROP_SHAPES, safeShapeName)) {
    return {};
  }

  return {
    setup: {
      cropShape: {
        name: safeShapeName,
      }
    },
  };
}
