import lodash from 'lodash';

import {EASINGS} from '../../ecs/easingHelper';
import {PARSE_OFF, PARSE_POSITION} from '../../ecs/transitionHelper';
import flyInRightGif from '../../../assets/images/transitions/flyInRight.gif';

/**
 * The name that signals this preset.
 * @const {string}
 */
export const PRESET_FLY_IN_RIGHT = 'flyInRight';

/**
 * The preset details.
 *
 * @type {{
 *   name: string,
 *   flow: string,
 *   details: Object.<string, {
 *     easing: string,
 *     parse: boolean,
 *     values: *|Array.<*>,
 *     time: {start: (string|number), end: (string|number)}
 *   }>,
 *   template: {}
 * }}
 */
export const flyInRightPresetDetails = {
  // The name of the preset.
  name: PRESET_FLY_IN_RIGHT,

  // A human friendly name for the preset.
  humanName: 'Fly In From Right',

  // A preview of the transition.
  gif: flyInRightGif,

  // The element names that this preset can be used for or true for all elements.
  elements: true,

  // The preset direction: in, out, middle. This is used in the UI logic.
  flow: 'in',

  // Each property that will change during the transition.
  details: {
    'position.x': {
      // The name of the easing this property will use.
      easing: EASINGS.easeInQuad,

      // The start and end times for the transition of this property.
      time: {
        start: 'start.0',
        end: 'start.1000',
      },

      // The value of the property that will change during the transition.
      values: [`${PARSE_OFF}.right`, `${PARSE_POSITION}.right`],
    },
  },

  // How this preset can be used in the source (including optional parameters).
  // This will be turned into a JSON string, so no functions can be used.
  template: {
    preset: PRESET_FLY_IN_RIGHT,
    options: {},
  },
};

flyInRightPresetDetails.template.options = lodash.cloneDeep(flyInRightPresetDetails.details);

// This defines the key in the presets object.
export const presetName = PRESET_FLY_IN_RIGHT;

// This defines the value of the presets object.
export default flyInRightPresetDetails;
