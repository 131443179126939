import lodash from 'lodash';
import {toJS} from 'mobx';

/**
 * The default blank drop shadow object.
 * @type {{blur: number, color: string, x: number, y: number}}
 */
export const DEFAULT_DROP_SHADOW = {
  blur: 0,
  color: '#000',
  x: 0,
  y: 0,
};

/**
 * The number to divide by to convert to a percentage.
 *
 * @const {number}
 */
const TO_PERCENT = 100;

/**
 * The image component.
 *
 * @param {number} fileId
 * @param {string} url
 * @param {{}} imageData
 * @param {number} imageData.imageY
 * @param {number} imageData.imageX
 * @param {number} imageData.imageWidth
 * @param {number} imageData.imageHeight
 * @param {number} imageData.opacity
 * @param {{}=} imageData.dropShadow
 * @returns {{image: {fileId: number, url: string, y: number, x: number, width: number, height: number}}}
 */
export function imageComponent(fileId, url, imageData) {
  let safeFileId = null;
  if (!fileId || fileId === 'null') {
    safeFileId = null;
  } else if (!Number.isNaN(Number(fileId))) {
    safeFileId = parseInt(fileId, 10);
  } else if (typeof fileId === 'string') {
    safeFileId = fileId;
  }
  let safeUrl = (!url || url === 'null') ? null : String(url);

  const {imageY, imageX, imageWidth, imageHeight} = imageData;

  const opacity = (imageData.opacity === undefined) ? 1 : imageData.opacity;
  const dropShadow = lodash.defaults(
    lodash.pick(imageData.dropShadow, lodash.keys(DEFAULT_DROP_SHADOW)),
    DEFAULT_DROP_SHADOW
  );

  return {
    image: {
      fileId: safeFileId,
      url: safeUrl,
      y: imageY,
      x: imageX,
      yIsPercent: Boolean(String(imageY).substr(-1) === '%'),
      xIsPercent: Boolean(String(imageX).substr(-1) === '%'),
      width: imageWidth,
      height: imageHeight,
      widthIsPercent: Boolean(String(imageWidth).substr(-1) === '%'),
      heightIsPercent: Boolean(String(imageHeight).substr(-1) === '%'),
      dropShadow,
      opacity,
      default: {
        y: imageY,
        x: imageX,
        yIsPercent: Boolean(String(imageY).substr(-1) === '%'),
        xIsPercent: Boolean(String(imageX).substr(-1) === '%'),
        width: imageWidth,
        height: imageHeight,
        widthIsPercent: Boolean(String(imageWidth).substr(-1) === '%'),
        heightIsPercent: Boolean(String(imageHeight).substr(-1) === '%'),
        dropShadow,
        opacity,
      }
    },
  };
}

/**
 * Gets the image component from the source item.
 *
 * @param {{image: {fileId: number, y: number, x: number, width: number, height: number}}} item
 * @param {{}=} variables
 * @returns {{image: {fileId: number, y: number, x: number, width: number, height: number}}}
 */
export function getImageFromSource(item, variables) {
  if (!item.image) {
    return {};
  }

  const image = item.image;

  let fileId = image.fileId;
  let url = image.url;

  const canEdit = lodash.get(item, 'compose.canEdit');
  const variableName = lodash.get(item, 'compose.variableName');
  if (canEdit && variableName) {
    const variableValue = lodash.get(variables, `image.${variableName}`, '');

    if (variableValue && lodash.isString(variableValue)) {
      // The old deprecated variable style is a string with | delimiters.
      [fileId, url] = lodash.split(variableValue, '|', 2);
    } else if (variableValue) {
      // The ()s fix an eslint parsing error.
      ({fileId, url} = variableValue);
    }
  }

  return imageComponent(
    fileId,
    url,
    {
      imageY: image.y,
      imageX: image.x,
      imageWidth: image.width,
      imageHeight: image.height,
      dropShadow: image.dropShadow || undefined,
      opacity: image.opacity,
    }
  );
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {{}} entity
 * @param {GameStore} game
 * @returns {{}}
 */
export function getImageForSource(entity, game) {
  if (!entity.has('image')) {
    return {};
  }

  const compose = entity.get('compose');
  const image = entity.get('image');
  const gameResolution = game.resolution;
  const decimalLimit = 4;

  const outImage = {
    x: image.default.x,
    y: image.default.y,
    width: image.default.width,
    height: image.default.height,
  };
  if (image.default.widthIsPercent) {
    outImage.width = Number.parseFloat(image.width * TO_PERCENT / gameResolution.width).toFixed(decimalLimit) + '%';
  }
  if (image.default.heightIsPercent) {
    outImage.height = Number.parseFloat(image.height * TO_PERCENT / gameResolution.height).toFixed(decimalLimit) + '%';
  }
  if (image.default.xIsPercent) {
    outImage.x = Number.parseFloat(image.x * TO_PERCENT / gameResolution.width).toFixed(decimalLimit) + '%';
  }
  if (image.default.yIsPercent) {
    outImage.y = Number.parseFloat(image.y * TO_PERCENT / gameResolution.height).toFixed(decimalLimit) + '%';
  }
  if (image.dropShadow) {
    outImage.dropShadow = toJS(image.dropShadow);
  }
  if (image.opacity !== undefined) {
    outImage.opacity = image.opacity;
  }

  const canEdit = lodash.get(compose, 'canEdit', false);
  const variableName = lodash.get(compose, 'variableName');
  if (!canEdit || !variableName) {
    outImage.fileId = image.fileId;
    outImage.url = image.url;
  }

  return {
    image: outImage,
  };
}
