import lodash from 'lodash';
import {isObservable, toJS} from 'mobx';

/**
 * The mask component.
 *
 * @param {{rotate: number}} maskProperties
 * @returns {{mask: {default: {}}}}
 */
export function maskComponent(maskProperties) {
  const masks = addMasksToComponent(maskProperties, {});

  const defaults = {
    blur: 0,
    brightness: 1,
    contrast: 1,
    'hue-rotate': 0,
    invert: 0,
    opacity: 1,
    saturate: 0,
    sepia: 0,
  };

  return {
    mask: {
      ...defaults,
      ...masks,
      default: {
        ...defaults,
        ...masks,
      },
    },
  };
}

/**
 * Adds masks into the given simple component object.
 * This is not for directly updating an entity!
 *
 * @param {{}} masks
 * @param {{}} component
 * @returns {{}}
 */
export function addMasksToComponent(masks, component) {
  const safeComponent = (isObservable(component)) ? toJS(component) : component;

  const validProperties = lodash.pick(masks, [
    'blur', 'brightness', 'contrast', 'hue-rotate', 'invert', 'opacity', 'saturate', 'sepia'
  ]);

  const safeProperties = {};
  lodash.forEach(validProperties, (propertyValue, propertyName) => {
    safeProperties[propertyName] = propertyValue;
  });

  return {
    ...safeComponent,
    ...safeProperties,
  };
}

/**
 * Gets the mask component from the source item.
 *
 * @param {{mask: {}}} item
 * @returns {{mask: {}}}
 */
export function getMaskFromSource(item) {
  if (!item.mask) {
    return {};
  }

  return maskComponent(item.mask);
}

/**
 * Parses an entity back into source JSON.
 *
 * @returns {{}}
 */
export function getMaskForSource() {
  return {};
}
