import lodash from 'lodash';

import {EASINGS} from '../../ecs/easingHelper';
import letterFadePositionInLeftGif from '../../../assets/images/transitions/letterFadePositionInLeft.gif';

/**
 * The name that signals this preset.
 * @const {string}
 */
export const PRESET_LETTER_FADE_POSITION_IN_LEFT = 'letterFadePositionInLeft';

/**
 * The preset details.
 *
 * @type {{
 *   name: string,
 *   flow: string,
 *   details: Object.<string, {
 *     easing: string,
 *     parse: boolean,
 *     values: *|Array.<*>,
 *     time: {start: (string|number), end: (string|number)}
 *   }>,
 *   template: {}
 * }}
 */
export const letterFadePositionInLeftPresetDetails = {
  // The name of the preset.
  name: PRESET_LETTER_FADE_POSITION_IN_LEFT,

  // A human friendly name for the preset.
  humanName: 'Letter - Fade & Position In From Left',

  // A preview of the transition.
  gif: letterFadePositionInLeftGif,

  // The element names that this preset can be used for or true for all elements.
  elements: ['text'],

  // The preset direction: in, out, middle. This is used in the UI logic.
  flow: 'in',

  // Each property that will change during the transition.
  details: {
    'letter.opacity': {
      time: {
        end: 'start.800',
        start: 'start.0'
      },
      easing: EASINGS.easeOutExpo,
      values: [
        0,
        1
      ],
      stagger: 30,
      staggerDuration: false
    },
    'letter.translateX': {
      time: {
        end: 'start.800',
        start: 'start.0'
      },
      easing: EASINGS.easeOutExpo,
      values: [
        -150,
        0
      ],
      stagger: 30
    },
  },

  // How this preset can be used in the source (including optional parameters).
  // This will be turned into a JSON string, so no functions can be used.
  template: {
    preset: PRESET_LETTER_FADE_POSITION_IN_LEFT,
    options: {},
  },
};

letterFadePositionInLeftPresetDetails.template.options = lodash.cloneDeep(letterFadePositionInLeftPresetDetails.details);

// This defines the key in the presets object.
export const presetName = PRESET_LETTER_FADE_POSITION_IN_LEFT;

// This defines the value of the presets object.
export default letterFadePositionInLeftPresetDetails;
