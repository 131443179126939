import lodash from 'lodash';
import {runInAction, toJS} from 'mobx';

import {cropComponent} from '../components/common/cropComponent';
import {
  clearTransitionCache,
  getPercentageFromPixel,
  getPixelFromPercentage,
  updateEntity
} from '../ecs/entityHelper';
import {mapToRotatedFrame} from '../../utils/mathHelper';

/**
 * The name of the system.
 * @const {string}
 */
export const CROPPING_SYSTEM = 'croppingSystem';

/**
 * The smallest the crop window can get in each dimension.
 * @const {number}
 */
export const MINIMUM_CROP_SIZE = 10;

/**
 * Gets a new instance of the cropping system.
 *
 * @param {GameStore} game
 * @returns {{name: string, runActions: systemRunActions}}
 */
export function croppingSystem(game) {
  /**
   * Called when the game loop updates.
   *
   * @param {Array.<{}>} actions
   */
  function systemRunActions(actions) {
    actions.forEach((actionEntity) => {
      // First check for required components.
      if (!actionEntity.has('actionCrop')) {
        return;
      }

      const entityId = actionEntity.get('action').entityId;
      const entity = game.getEntity(entityId);
      if (!entity) {
        return;
      }

      const {safeCrop} = checkCropBoundaries(actionEntity, entity);

      // Now update the entity.
      runInAction('croppingSystemUpdateEntity', () => {
        if (safeCrop) {
          if (!entity.has('crop')) {
            entity.set('crop', safeCrop);
          } else {
            updateEntity(entity, 'crop', safeCrop);
          }
        }

        // if (safePosition) {
        //   if (!entity.has('position')) {
        //     entity.set('position', safePosition);
        //   } else {
        //     updateEntity(entity, 'position', safePosition);
        //   }
        // }
        //
        // if (safeSize) {
        //   if (!entity.has('size')) {
        //     entity.set('size', safeSize);
        //   } else {
        //     updateEntity(entity, 'size', safeSize);
        //   }
        // }

        clearTransitionCache(entity);
      });
    });
  }

  /**
   * Checks to see if the size or position changes takes the item over the boundary.
   *
   * @param {ObservableMap} actionEntity
   * @param {ObservableMap} entity
   * @returns {{safeCrop: ?{y: number, x: number, width: number, height: number}}}
   */
  function checkCropBoundaries(actionEntity, entity) {
    const safeEntity = toJS(entity);
    const actionCrop = actionEntity.get('actionCrop');

    const currentPosition = safeEntity.position;
    const currentType = safeEntity[safeEntity.element];
    const currentCrop = safeEntity.crop || {
      y: 0,
      x: 0,
      height: 100,
      width: 100,
    };

    let rotatedCropDelta = lodash.defaultsDeep(actionCrop.cropDelta, {y: 0, x: 0, width: 0, height: 0});

    // See if we need to adjust the delta to the rotated reference frame
    // (except for resizing where it was already done in the resizeHOC).
    if (!actionCrop.isResize && currentPosition.rotate) {
      rotatedCropDelta = mapToRotatedFrame(currentPosition.rotate, rotatedCropDelta);
    }

    const cropPixels = {
      y: getPixelFromPercentage(currentType.height, currentCrop.y),
      x: getPixelFromPercentage(currentType.width, currentCrop.x),
      height: getPixelFromPercentage(currentType.height, currentCrop.height),
      width: getPixelFromPercentage(currentType.width, currentCrop.width),
    };

    const newHeight = Math.max(cropPixels.height + rotatedCropDelta.height, MINIMUM_CROP_SIZE);
    const newWidth = Math.max(cropPixels.width + rotatedCropDelta.width, MINIMUM_CROP_SIZE);

    // The new cropping position and size values.
    const newCrop = ({
      y: getPercentageFromPixel(currentType.height, cropPixels.y + rotatedCropDelta.y),
      x: getPercentageFromPixel(currentType.width, cropPixels.x + rotatedCropDelta.x),
      height: getPercentageFromPixel(currentType.height, newHeight),
      width: getPercentageFromPixel(currentType.width, newWidth),
    });

    // Prevents the cropping window from going fully off the viewing window.
    // const cropLimits = checkCroppingBoundaries(newCrop, newCrop, game.resolution);
    // newCrop.y = cropLimits.y;
    // newCrop.x = cropLimits.x;

    // if (actionCrop.isResize) {
    //   if (rotatedCropDelta.width || rotatedCropDelta.height) {
    //     newCrop.x -= cropLimits.truncateWidth;
    //     newCrop.y -= cropLimits.truncateHeight;
    //     newCrop.width += cropLimits.truncateWidth;
    //     newCrop.height += cropLimits.truncateHeight;
    //   } else {
    //     newCrop.width -= cropLimits.truncateWidth;
    //     newCrop.height -= cropLimits.truncateHeight;
    //   }
    // }

    return {
      safeCrop: cropComponent(newCrop.y, newCrop.x, newCrop.width, newCrop.height).crop,
    };
  }

  return {
    name: CROPPING_SYSTEM,
    runActions: systemRunActions
  };
}

/**
 * Checks the cropping boundaries if the position change.
 *
 * @param {{height: number, width: number}} size
 * @param {{x: number, y: number}} position
 * @param {{height: number, width: number}} gameResolution
 * @returns {{x: number, y: number, truncateHeight: number, truncateWidth: number}}
 */
// function checkCroppingBoundaries(size, position, gameResolution) {
//   const padding = 10;
//   const limitTop = 0 - size.height + padding;
//   const limitLeft = 0 - size.width + padding;
//   const limitBottom = gameResolution.height - padding;
//   const limitRight = gameResolution.width - padding;
//
//   // Make sure the new position won't push the item fully off the display.
//   let newY = position.y;
//   if (newY < limitTop) {
//     newY = limitTop;
//   } else if (newY > limitBottom) {
//     newY = limitBottom;
//   }
//
//   let newX = position.x;
//   if (newX < limitLeft) {
//     newX = limitLeft;
//   } else if (newX > limitRight) {
//     newX = limitRight;
//   }
//
//   return {
//     y: newY,
//     x: newX,
//     truncateHeight: newY - position.y,
//     truncateWidth: newX - position.x,
//   };
// }
