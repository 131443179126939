import React, {Component} from 'react';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import jQuery from 'jquery';
import {observable, action} from 'mobx';

/**
 * The ReportBugButton component.
 */
class ReportBugButton extends Component {
  /**
   * Opens the issue collector
   *
   * @type {?function}
   */
  @observable showIssueCollector = null;

  /**
   * Runs when the component is mounted
   */
  componentDidMount() {
    const {user} = this.props;
    const JIRA_URL = 'https://projectcontent.atlassian.net';
    const COLLECTOR_ID = 'dd296f05';

    window.jQuery = jQuery;
    jQuery.ajax({
      url: `${JIRA_URL}/s/d41d8cd98f00b204e9800998ecf8427e-T/1jmxwi/b/8/b0105d975e9e59f24a3230a22972a71a/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs.js?locale=en-US&collectorId=${COLLECTOR_ID}`, // eslint-disable-line max-len
      type: 'get',
      cache: true,
      dataType: 'script'
    });

    // Runs when the above script is loaded
    window.ATL_JQ_PAGE_PROPS = {
      triggerFunction: action((showCollectorDialog) => {
        delete window.jQuery;
        this.showIssueCollector = showCollectorDialog;
      }),
      fieldValues: {
        email: user.email,
        fullname: user.name,
      }
    };
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    return this.showIssueCollector && (
      <button
        type="button"
        className="dropdown-item report-bug-button"
        onClick={this.showIssueCollector}
      >
        Report A Bug
      </button>
    );
  }
}

ReportBugButton.propTypes = {
  user: MobxPropTypes.observableObject,
};

export default observer(ReportBugButton);
