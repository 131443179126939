export default {
  DAY_ALIGN: '\\',
  HIGH_ALIGN: '\\',
  LOW_ALIGN: '\\',

  DAY_WIDTH_RATIO: 0.2022,
  DAY_HEIGHT_RATIO: 0.1205,
  DAY_ONE_POSITION_X_RATIO: 0.0561,
  DAY_ONE_POSITION_Y_RATIO: 0.1279,
  DAY_TWO_POSITION_X_RATIO: 0.3699,
  DAY_TWO_POSITION_Y_RATIO: 0.1279,
  DAY_THREE_POSITION_X_RATIO: 0.6992,
  DAY_THREE_POSITION_Y_RATIO: 0.1279,

  ICON_WIDTH_RATIO: 0.2654,
  ICON_HEIGHT_RATIO: 1.0224,
  ICON_ONE_POSITION_X_RATIO: -0.0572,
  ICON_ONE_POSITION_Y_RATIO: 0.1813,
  ICON_TWO_POSITION_X_RATIO: 0.2566,
  ICON_TWO_POSITION_Y_RATIO: 0.1813,
  ICON_THREE_POSITION_X_RATIO: 0.5859,
  ICON_THREE_POSITION_Y_RATIO: 0.1813,

  HIGH_WIDTH_RATIO: 0.1341,
  HIGH_HEIGHT_RATIO: 0.3835,
  HIGH_ONE_POSITION_X_RATIO: 0.179,
  HIGH_ONE_POSITION_Y_RATIO: 0.281,
  HIGH_TWO_POSITION_X_RATIO: 0.4928,
  HIGH_TWO_POSITION_Y_RATIO: 0.281,
  HIGH_THREE_POSITION_X_RATIO: 0.8221,
  HIGH_THREE_POSITION_Y_RATIO: 0.281,

  LOW_WIDTH_RATIO: 0.1394,
  LOW_HEIGHT_RATIO: 0.1728,
  LOW_ONE_POSITION_X_RATIO: 0.183,
  LOW_ONE_POSITION_Y_RATIO: 0.6204,
  LOW_TWO_POSITION_X_RATIO: 0.4968,
  LOW_TWO_POSITION_Y_RATIO: 0.6204,
  LOW_THREE_POSITION_X_RATIO: 0.8261,
  LOW_THREE_POSITION_Y_RATIO: 0.6204,
};
