import React from 'react';
import {action, observable} from 'mobx';
import classNames from 'classnames';
import lodash from 'lodash';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowDown, faArrowLeft, faArrowRight, faArrowUp, faArrowsH, faArrowsV, faLock, faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';

import {actionPositionAlignComponent} from '../../../display/components/action/actionPositionAlignComponent';
import {ALIGNMENTS} from '../../../display/components/common/positionComponent';

import './editAlignmentControls.scss';

/**
 * The EditAlignmentControls component.
 */
export class EditAlignmentControls extends React.Component {
  /**
   * Whether or not the alignment control group is open.
   *
   * @type {boolean}
   */
  @observable isControlGroupOpen = false;

  @action onToggleControlGroup = () => {
    this.isControlGroupOpen = !this.isControlGroupOpen;
  }

  /**
   * Align the position of the item.
   *
   * @param {string} axis
   * @param {string} alignment
   * @returns {function}
   */
  onClickAlign = (axis, alignment) => {
    return (clickEvent) => {
      clickEvent.preventDefault();

      const {entity, game} = this.props;

      const actionParams = {
        entityId: entity.get('id')
      };

      game.addAction(actionParams, actionPositionAlignComponent(
        axis,
        alignment,
      ));
    };
  };

  /**
   * Renders the Alignment Controls.
   *
   * @returns {{}}
   */
  renderAlignmentControls() {
    const {
      /** @type {ObservableMap} */ entity,
    } = this.props;

    if (!this.isControlGroupOpen) {
      return null;
    }

    let isLocked = false;
    if (entity.has('locked')) {
      const entityLock = entity.get('locked');
      if (lodash.includes(entityLock, 'position')) {
        // If the entity has position locked, then do not allow them to adjust anything.
        isLocked = true;
      }
    }

    const alignment = entity.get('position').alignment;
    const alignXClasses = {
      left: {active: (alignment.x === ALIGNMENTS.x.left)},
      center: {active: (alignment.x === ALIGNMENTS.x.center)},
      right: {active: (alignment.x === ALIGNMENTS.x.right)},
    };
    const alignYClasses = {
      top: {active: (alignment.y === ALIGNMENTS.y.top)},
      middle: {active: (alignment.y === ALIGNMENTS.y.middle)},
      bottom: {active: (alignment.y === ALIGNMENTS.y.bottom)},
    };

    return (
      (isLocked) ? (
        <div className="can-not-edit">
          <span className="locked-text">
            <FontAwesomeIcon
              className="mr-2"
              icon={faLock}
            />
            <span>Position Locked</span>
          </span>
        </div>
      ) : (
        <div className="group-controls">
          <div className="row">
            <div className="col">
              <div className="form-group">
                <div><label>X-Axis Align</label></div>
                <div className="align-buttons form-buttons">
                  <div className="btn-group">
                    <button
                      type="button"
                      className={classNames('btn btn-sm btn-light form-button', alignXClasses.left)}
                      onClick={this.onClickAlign('x', ALIGNMENTS.x.left)}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </button>

                    <button
                      type="button"
                      className={classNames('btn btn-sm btn-light form-button', alignXClasses.center)}
                      onClick={this.onClickAlign('x', ALIGNMENTS.x.center)}
                    >
                      <FontAwesomeIcon icon={faArrowsV} />
                    </button>

                    <button
                      type="button"
                      className={classNames('btn btn-sm btn-light form-button', alignXClasses.right)}
                      onClick={this.onClickAlign('x', ALIGNMENTS.x.right)}
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <div><label>Y-Axis Align</label></div>
                <div className="align-buttons form-buttons">
                  <div className="btn-group-vertical">
                    <button
                      type="button"
                      className={classNames('btn btn-sm btn-light form-button', alignYClasses.top)}
                      onClick={this.onClickAlign('y', ALIGNMENTS.y.top)}
                    >
                      <FontAwesomeIcon icon={faArrowUp} />
                    </button>

                    <button
                      type="button"
                      className={classNames('btn btn-sm btn-light form-button', alignYClasses.middle)}
                      onClick={this.onClickAlign('y', ALIGNMENTS.y.middle)}
                    >
                      <FontAwesomeIcon icon={faArrowsH} />
                    </button>

                    <button
                      type="button"
                      className={classNames('btn btn-sm btn-light form-button', alignYClasses.bottom)}
                      onClick={this.onClickAlign('y', ALIGNMENTS.y.bottom)}
                    >
                      <FontAwesomeIcon icon={faArrowDown} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      /** @type {GameStore} */ game,
    } = this.props;

    if (game.composeMode) {
      // Compose mode disables most editing abilities.
      return null;
    }

    return (
      <div className="edit-alignment-controls control-group">
        <div className="group-header">
          <span className="group-header-label">Alignment</span>
          <button
            type="button"
            className="btn"
            onClick={this.onToggleControlGroup}
          >
            <FontAwesomeIcon icon={this.isControlGroupOpen ? faChevronUp : faChevronDown} />
          </button>
        </div>
        {this.renderAlignmentControls()}
      </div>
    );
  }
}

EditAlignmentControls.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(EditAlignmentControls);
