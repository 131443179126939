import {action, computed} from 'mobx';

import {EXPIRES_IN, EXPIRES_PENDING, EXPIRE_TIME} from '../../../constants/storeConstants';
import {STATE_FULFILLED, STATE_PENDING, STATE_REJECTED} from '../../../constants/asyncConstants';
import serverApi from '../../../utils/serverApi';
import ApiBaseStore from '../common/apiBaseStore';

/**
 * ApiPlanGetAllStore
 */
class ApiPlanGetAllStore extends ApiBaseStore {
  /**
   * Whether or not the plans list is empty.
   *
   * @returns {?boolean}
   */
  @computed get isEmpty() {
    if (this.state !== STATE_FULFILLED) {
      return null;
    }

    const fulfilled = this.getFulfilled();
    return Boolean(!fulfilled || !fulfilled.length);
  }

  /**
   * Fetches plans from the server.
   */
  @action makeRequest() {
    this.state = STATE_PENDING;
    this[EXPIRE_TIME] = Date.now() + EXPIRES_PENDING;

    serverApi.planGetAll(true).then(
      action('planGetAllSuccess', (plans) => {
        if (!Array.isArray(plans)) {
          throw new Error('Server did not return an array for planGetAll() request');
        }

        this.state = STATE_FULFILLED;
        this[EXPIRE_TIME] = Date.now() + EXPIRES_IN;
        this.data = plans;
        this.error = null;
      }),
      action('planGetAllError', (planGetAllError) => {
        this.state = STATE_REJECTED;
        this[EXPIRE_TIME] = null;
        this.data = null;
        this.error = planGetAllError;
      })
    );
  }
}

export default new ApiPlanGetAllStore();
