/**
 * The centered alignment (center or middle).
 * @const {symbol}
 */
export const ALIGN_CENTERED = Symbol('alignCentered');

/**
 * The near alignment (left or top).
 * @const {symbol}
 */
export const ALIGN_NEAR = Symbol('alignNear');

/**
 * The far alignment (right or bottom).
 * @const {symbol}
 */
export const ALIGN_FAR = Symbol('alignFar');
