import {action} from 'mobx';

import apiContentGetOneStore from './apiContentGetOneStore';
import ApiBaseStore from '../common/apiBaseStore';
import apiContentFolderGetAllStore from '../contentFolder/apiContentFolderGetAllStore';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';
import apiContentFolderGetContentStore from '../contentFolder/apiContentFolderGetContentStore';

/**
 * ApiContentMoveStore
 */
class ApiContentMoveStore extends ApiBaseStore {
  /**
   * Fetches content info from the server
   *
   * @param {number} contentId
   * @param {number} folderId
   * @param {number=} oldFolderId
   */
  @action makeRequest(contentId, folderId, oldFolderId) {
    this.state = STATE_PENDING;
    this[EXPIRE_TIME] = Date.now() + EXPIRES_PENDING;

    serverApi.contentMove(
      contentId,
      folderId
    ).then(
      action('contentMoveSuccess', () => {
        this.error = null;
        this.state = STATE_FULFILLED;
        this.data = true;
        this[EXPIRE_TIME] = Date.now() + EXPIRES_IN;

        // Clear the content cache.
        apiContentGetOneStore.refresh(contentId, true);

        // Clear the content folder cache.
        apiContentFolderGetContentStore.refresh(folderId, true);
        if (oldFolderId) {
          apiContentFolderGetContentStore.refresh(oldFolderId, true);
        }

        // Clear the folder cache in case the default folder was created.
        apiContentFolderGetAllStore.refresh(true);
      }),
      action('contentMoveError', (error) => {
        this.error = error;
        this.state = STATE_REJECTED;
        this.data = null;
      })
    );
  }
}

export default new ApiContentMoveStore();
