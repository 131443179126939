import lodash from 'lodash';
import {action, observable, toJS} from 'mobx';

/**
 * The onboarding store.
 */
class OnboardingStore {
  /**
   * The plan data.
   *
   * @type {?{}}
   */
  @observable planData = null;

  /**
   * The optional first sign data.
   *
   * @type {?{}}
   */
  @observable signData = null;

  /**
   * The optional user data.
   *
   * @type {?{}}
   */
  @observable userData = null;

  /**
   * The number of plans (signs) for subscription.
   *
   * @type {?number}
   */
  @observable quantity = null;

  /**
   * Clears all the data for this store.
   * This should be used after the data is submitted to the server.
   */
  @action clearAll() {
    this.planData = null;
    this.signData = null;
    this.userData = null;
    this.quantity = null;
  }

  /**
   * Gets the onboarding data for the server.
   *
   * @returns {{}}
   */
  getDataForServer() {
    return lodash.pickBy(
      toJS({
        planId: this.planData && this.planData.id,
        signData: this.signData
      })
    );
  }

  /**
   * Get the plan id
   * @returns {number|null}
   */
  getPlanId() {
    return lodash.get(this.planData, 'id', null);
  }

  /**
   * Sets the plan data.
   *
   * @param {?{}} newPlanData
   */
  @action setPlanData(newPlanData) {
    this.planData = newPlanData || null;
  }

  /**
   * Sets the sign data.
   *
   * @param {?{}} newSignData
   */
  @action setSignData(newSignData) {
    this.signData = lodash.pickBy(newSignData || {}, (item) => {
      return Boolean(item);
    });
  }

  /**
   * Sets the user data.
   *
   * @param {?{}} newUserData
   */
  @action setUserData(newUserData) {
    this.userData = newUserData || null;
  }

  /**
   * Sets the plan quantity.
   *
   * @param {?number} newQuantity
   */
  @action setQuantity(newQuantity) {
    this.quantity = newQuantity || null;
  }
}

export default new OnboardingStore();
