import classNames from 'classnames';
import lodash from 'lodash';
import moment from 'moment';
import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import {MOMENT_LONG_DATE_FORMAT} from '../../../constants/uiConstants';
import Highlighter from '../highlighter/Highlighter';
import SmartTableSearch from './SmartTableSearch';
import SmartTBody from './SmartTBody';
import SmartTHead, {SORT_DIRECTION_ASC, SORT_DIRECTION_DESC} from './SmartTHead';

import './smartTable.scss';

/**
 * The searchable and sortable table component.
 *
 * @constructor
 */
export class SmartTable extends React.Component {
  /**
   * The search keyword/phrase.
   *
   * @type {?string}
   */
  @observable searchKeyword = null;

  /**
   * The search keyword/phrase.
   *
   * @type {?Array}
   */
  @observable searchedRows = null;

  /**
   * The sorting direction.
   *
   * @type {string}
   */
  @observable sortDirection = SORT_DIRECTION_ASC;

  /**
   * The sorting column.
   *
   * @type {?string}
   */
  @observable sortOn = null;

  /**
   * Sets the search keyword.
   *
   * @param {?string} newKeyword
   */
  @action setSearchKeyword = (newKeyword) => {
    this.searchKeyword = newKeyword;
  };

  /**
   * Sets the searched rows.
   *
   * @param {?Array} newRows
   */
  @action setSearchedRows = (newRows) => {
    this.searchedRows = newRows;
  };

  /**
   * Triggers when the search is submitted.
   *
   * @param {string} searchKeyword
   */
  onSearch = (searchKeyword) => {
    if (!searchKeyword) {
      this.onSearchClear();
      return;
    }

    const visibleColumns = (this.props.columns || []).reduce((visibles, column) => {
      visibles[column.key] = !column.hide;
      return visibles;
    }, {});

    const searchedRows = (this.props.rows || []).reduce((filteredRows, row) => {
      let hasMatch = false;

      const highlightedRow = lodash.reduce(row, (highlighted, fieldValue, fieldName) => {
        if (!fieldValue || !visibleColumns[fieldName]) {
          return highlighted;
        }
        if (typeof fieldValue === 'string') {
          if (fieldValue.toLowerCase().match(searchKeyword)) {
            hasMatch = true;
            highlighted[fieldName] = <Highlighter highlight={searchKeyword} text={fieldValue} />;
          }
          return highlighted;
        }
        if (fieldValue.props && fieldValue.props.children && typeof fieldValue.props.children === 'string') {
          if (fieldValue.props.children.toLowerCase().match(searchKeyword)) {
            hasMatch = true;
            highlighted[fieldName] = React.cloneElement(fieldValue, {
              children: <Highlighter highlight={searchKeyword} text={fieldValue.props.children} />
            });
          }
          return highlighted;
        }

        // Add other situation checks here.

        return highlighted;
      }, Object.assign({}, row));

      if (hasMatch) {
        filteredRows.push(highlightedRow);
      }

      return filteredRows;
    }, []);

    this.setSearchKeyword(searchKeyword);
    this.setSearchedRows(searchedRows);
  };

  /**
   * Triggers when the search box is cleared.
   */
  onSearchClear = () => {
    this.setSearchKeyword('');
    this.setSearchedRows(null);
  };

  /**
   * Triggers when the table sorts.
   *
   * @param {{sortOn: ?string, sortDirection: ?string}} sortData
   */
  @action onSort = ({sortOn, sortDirection}) => {
    this.sortOn = sortOn || null;
    this.sortDirection = sortDirection || SORT_DIRECTION_ASC;
  };

  /**
   * Parses the text value from a row field/key.
   *
   * @param {{}} row
   * @param {string} key
   * @returns {string}
   */
  parseValueFromRow = (row, key) => {
    const value = row[key];
    const valueAsDate = moment(value, MOMENT_LONG_DATE_FORMAT);

    if (!value) {
      return '';
    } else if (!isNaN(valueAsDate.unix())) {
      return valueAsDate.unix();
    } else if (typeof value === 'string') {
      return value;
    } else if (value.props && value.props.children && typeof value.props.children === 'string') {
      return value.props.children;
    }

    // Add other situation checks here.

    return '';
  };

  /**
   * Sorts the rows if applicable.
   *
   * @param {Array.<{}>} rows
   * @returns {Array.<{}>}
   */
  sortRows = (rows) => {
    if (!rows || !rows.length) {
      return [];
    } else if (!this.sortOn) {
      return rows;
    }

    const field = this.sortOn;
    const direction = (this.sortDirection === SORT_DIRECTION_DESC) ? SORT_DIRECTION_DESC : SORT_DIRECTION_ASC;

    const safeRows = rows.slice();
    safeRows.sort((a, b) => {
      const aValue = this.parseValueFromRow(a, field);
      const bValue = this.parseValueFromRow(b, field);

      let sortValue = 0;
      if (aValue > bValue) {
        sortValue = 1;
      } else if (aValue < bValue) {
        sortValue = -1;
      }

      // Account for direction.
      if (direction === SORT_DIRECTION_DESC) {
        sortValue = sortValue * -1;
      }
      return sortValue;
    });

    return safeRows;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {className, error, isSortable, loading} = this.props;

    let columns = this.props.columns;
    let rows = this.searchedRows || this.props.rows;
    const tableClasses = classNames('table', {'table-sortable': isSortable}, className);

    rows = this.sortRows(rows);

    if (loading) {
      const columnKey = columns[0].key;

      rows = [{
        id: 1,
        colSpanKey: columnKey,
        [columnKey]: (typeof loading === 'string') ? loading : 'Loading...',
      }];
    } else if (error) {
      const columnKey = columns[0].key;

      rows = [{
        id: 1,
        colSpanKey: columnKey,
        [columnKey]: (typeof error === 'string')
          ? <span className="help-block">{error}</span>
          : error,
      }];
    }

    let searchDisabled = false;
    if (!this.searchKeyword) {
      searchDisabled = Boolean(loading || error || !rows.length);
    }

    return (
      <div className="smart-table">
        <header className="smart-table-header">
          {(this.props.isSearchable) && (
            <div className="row">
              <div className="col-sm-6 col-sm-offset-6">
                <SmartTableSearch
                  isDisabled={searchDisabled}
                  onSearch={this.onSearch}
                  onClear={this.onSearchClear}
                />
              </div>
            </div>
          )}
        </header>
        <table id={this.props.id} className={tableClasses}>
          <SmartTHead
            columns={columns}
            onSort={(this.props.isSortable) ? this.onSort : null}
          />

          <SmartTBody columns={columns} rows={rows} />
        </table>
      </div>
    );
  }
}

SmartTable.propTypes = {
  columns: PropTypes.array.isRequired,

  className: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  id: PropTypes.string,
  isSearchable: PropTypes.bool,
  isSortable: PropTypes.bool,
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  rows: PropTypes.array,
};

SmartTable.defaultProps = {
  className: null,
  error: null,
  id: null,
  loading: false,
  isSearchable: true,
  isSortable: true,
};

export default observer(SmartTable);
