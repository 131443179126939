import lodash from 'lodash';
import {action, observable} from 'mobx';

import ApiBaseStore from '../common/apiBaseStore';
import sessionStore from '../../active/sessionStore';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import serverApi from '../../../utils/serverApi';
import apiUserGetMeStore from './apiUserGetMeStore';

/**
 * The amount of time between polling calls.
 * @const {number[]}
 */
const POLLING_INTERVALS = [
  // eslint-disable-next-line no-magic-numbers
  5000
];

/**
 * The maximum number of times polling will occur.
 * @const {number}
 */
const MAX_POLL_COUNT = 24;

/**
 * The maximum number of times an error can occur.
 * @const {number}
 */
const CANCEL_ERROR_COUNT = 4;

/**
 * Polls for user updates from the api.
 */
class ApiUserPollMeStore extends ApiBaseStore {
  /**
   * Id that can be used to cancel the active polling.
   *
   * @type {Object.<number, number>}
   */
  cancelTimeout = null;

  /**
   * Number of times the user has been polled
   *
   * @type {any}
   */
  @observable pollCount = 0;

  /**
   * Number of times the request has errored
   *
   * @type {any}
   */
  @observable errorCount = 0;

  /**
   * Clears all the resource info
   */
  @action clearAll() {
    this.clear();
    this.cancelPoll();
  }

  /**
   * Cancels any active polling for the given file id.
   */
  cancelPoll() {
    clearTimeout(this.cancelTimeout);
  }

  /**
   * Gets the next poll interval.
   *
   * @param {number} currentCount
   * @returns {number}
   */
  getNextPollInterval = (currentCount) => {
    const safeCount = Number(currentCount);

    if (!currentCount || !safeCount) {
      return POLLING_INTERVALS[0];
    } else if (POLLING_INTERVALS[safeCount]) {
      return POLLING_INTERVALS[safeCount];
    }

    return lodash.last(POLLING_INTERVALS);
  };

  /**
   * Checks if file is already being polled.
   *
   * @returns {boolean}
   */
  isPollingInProgress() {
    const currentResource = this.dataMap.get();
    return (currentResource && currentResource.state === STATE_PENDING);
  }

  /**
   * Polls file from the server until the enters a good file state.
   */
  @action pollMyUser() {
    if (this.state === STATE_PENDING) {
      return;
    }

    this.state = STATE_PENDING;

    this.cancelTimeout = setTimeout(() => {
      this.pollingLoop();
    }, this.getNextPollInterval(0));
  }

  /**
   * Starts or continues a polling loop.
   * Should not be called directly.
   */
  @action pollingLoop() {
    serverApi.userGetMe()
      .then(action('userGetMeSuccess', (user) => {
        const newCount = this.pollCount + 1;

        const hasUpdated = !lodash.isEqual(apiUserGetMeStore.getFulfilled(), user) && this.pollCount > 0;
        if (hasUpdated) {
          clearTimeout(this.cancelTimeout);

          this.endPollingSuccess(user, newCount);
          return;
        }

        if (newCount >= MAX_POLL_COUNT) {
          clearTimeout(this.cancelTimeout);

          this.state = STATE_REJECTED;
          this.pollCount = newCount;
          this.error = new Error('Polling max count reached');
          return;
        }

        this.pollCount = newCount;

        this.cancelTimeout = setTimeout(() => {
          this.pollingLoop();
        }, this.getNextPollInterval(newCount));
      })).catch(action('userGetMeError', (userGetMeError) => {
        const newCount = this.errorCount + 1;

        if (newCount >= CANCEL_ERROR_COUNT) {
          clearTimeout(this.cancelTimeout);

          this.errorCount += 1;
          this.state = STATE_REJECTED;
          this.error = userGetMeError;
          return;
        }
        this.state = STATE_REJECTED;
        this.errorCount += 1;

        this.cancelTimeout = setTimeout(() => {
          this.pollingLoop();
        }, this.getNextPollInterval(this.pollCount));
      }));
  }

  /**
   * Finishes a polling as a success.
   *
   * @param {{}} user
   * @param {number} newCount
   */
  @action endPollingSuccess(user, newCount) {
    this.data = user;
    this.pollCount = newCount;
    this.state = STATE_FULFILLED;

    apiUserGetMeStore.refresh(true);
    apiUserGetMeStore.getPromise().then((me) => {
      sessionStore.setCurrentUser(me);
    });
    clearTimeout(this.cancelTimeout);
  }

  /**
   * Polls user and updates it in the other stores when it is finished processing.
   */
  pollThenUpdateMyUser() {
    this.pollMyUser();
  }
}

export default new ApiUserPollMeStore();
