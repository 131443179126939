import {action, extendObservable, observable, when} from 'mobx';

import apiUserGetMeStore from './apiUserGetMeStore';
import {STATE_PRE, STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import serverApi from '../../../utils/serverApi';
import {apiStore, getCase} from '../../../utils/apiStore';

/**
 * The userUpdateSubscription store.
 */
class ApiUserUpdateSubscriptionStore {
  /**
   * @constructor
   */
  constructor() {
    extendObservable(this, apiStore);
  }

  /**
   * Internal state of the store.
   *
   * @type {Observable<{error: ?Error}>}
   */
  @observable userUpdateSubscription = {
    data: null,
    error: null,
  };

  /**
   * Gets the fulfilled value of the store.
   * This is used in case().
   *
   * @returns {boolean}
   */
  getFulfilled() {
    return this.userUpdateSubscription.data;
  }

  /**
   * Gets the rejected value of the store.
   * This is used in case().
   *
   * @returns {?Error}
   */
  getRejected() {
    return this.userUpdateSubscription.error;
  }

  /**
   * Gets the state of the store
   *
   * @returns {string}
   */
  getState() {
    return this.state;
  }

  /**
   * Clears all the newUser info
   */
  @action clearAll() {
    this.userUpdateSubscription.error = null;
    this.state = STATE_PRE;
  }

  /**
   * Posts userUpdateSubscription to the server.
   *
   * @param {{}} body
   * @param {string} body.user_id
   */
  @action makeRequest(body) {
    if (this.state === STATE_PENDING) {
      // Prevent double requests.
      return;
    }

    this.state = STATE_PENDING;

    serverApi.userUpdateSubscription(body).then(
      action('postUserUpdateSubscription', (response) => {
        this.userUpdateSubscription.error = null;
        this.userUpdateSubscription.data = (response) ? response : null;
        this.state = STATE_FULFILLED;

        // Make sure the get me fetches the updated user.
        apiUserGetMeStore.refresh(true);
      }),
      action('postUserUpdateSubscriptionError', (userUpdateSubscriptionError) => {
        this.userUpdateSubscription.error = userUpdateSubscriptionError;
        this.userUpdateSubscription.data = null;
        this.state = STATE_REJECTED;
      })
    );
  }

  /**
   * Runs handlers based on changes in the state.
   *
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  case(handlers) {
    const getFulfilled = () => {
      return this.getFulfilled();
    };
    const getRejected = () => {
      return this.getRejected();
    };

    return getCase(this.state, getFulfilled, getRejected, handlers);
  }

  /**
   * Gets a promise for this store.
   *
   * @returns {Promise}
   */
  getPromise() {
    const thisStore = this;

    return new Promise((resolve, reject) => {
      when(
        () => {
          return (thisStore.state === STATE_FULFILLED || thisStore.state === STATE_REJECTED);
        },
        () => {
          if (thisStore.state === STATE_REJECTED) {
            reject(this.getRejected());
            return;
          }

          resolve(this.getFulfilled());
        },
        {name: 'apiUserUpdateSubscriptionStoreGetPromise'}
      );
    });
  }
}

export default new ApiUserUpdateSubscriptionStore();
