import React from 'react';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import ReactSelect from 'react-select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';

import {actionUpdateComponent} from '../../../display/components/action/actionUpdateComponent';
import {BLEND_MODES, addEffectsToComponent, effectComponent} from '../../../display/components/common/effectComponent';

import './editEffectControls.scss';

/**
 * The EditEffectControls component.
 */
export class EditEffectControls extends React.Component {
  /**
   * Whether or not the effect control group is open.
   *
   * @type {boolean}
   */
  @observable isControlGroupOpen = false;

  @action onToggleControlGroup = () => {
    this.isControlGroupOpen = !this.isControlGroupOpen;
  }

  /**
   * Sets the blend mode for the entity's mask data.
   *
   * @param {{value: string, label: string}} selected
   */
  onSelectBlendMode = (selected) => {
    const {
      /** @type {ObservableMap} */ entity,
      /** @type {GameStore} */ game,
    } = this.props;

    const currentEffect = entity.get('effect');

    const newBlendMode = selected.value;

    const actionParams = {
      entityId: entity.get('id'),
    };

    const newEffects = addEffectsToComponent({blendMode: newBlendMode}, currentEffect);

    game.addAction(actionParams, actionUpdateComponent({
      ...effectComponent(newEffects),
    }));
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      /** @type {ObservableMap} */ entity,
      /** @type {GameStore} */ game,
    } = this.props;

    if (game.composeMode) {
      // Compose mode disables most editing abilities.
      return null;
    }

    const effect = entity.get('effect');
    const currentBlendMode = (effect && effect.blendMode) ? effect.blendMode : BLEND_MODES.normal.value;

    return (
      <div className="edit-effect-controls control-group">
        <div className="group-header">
          <span className="group-header-label">Effects</span>
          <button
            type="button"
            className="btn"
            onClick={this.onToggleControlGroup}
          >
            <FontAwesomeIcon icon={this.isControlGroupOpen ? faChevronUp : faChevronDown} />
          </button>
        </div>

        {this.isControlGroupOpen && (<div className="group-controls">
          <div className="row">
            <div className="col">
              <div className="form-group">
                <div><label>Blend Mode</label></div>
                <div className="blend-mode-select form-select">
                  <ReactSelect
                    id="blend-mode-select"
                    className="site-select theme-input-white"
                    classNamePrefix="react-select"
                    isClearable={false}
                    isMulti={false}
                    onChange={this.onSelectBlendMode}
                    options={Object.values(BLEND_MODES)}
                    value={Object.values(BLEND_MODES).find((option) => option.value === currentBlendMode)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>)}
      </div>
    );
  }
}

EditEffectControls.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(EditEffectControls);
