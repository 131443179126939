import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * The ErrorBoundary component.
 *
 * @constructor
 */
export class ErrorBoundary extends React.Component {
  /**
   * The error that was caught.
   *
   * @type {{error: ?Error, info: ?{}}}}
   */
  @observable caughtError = {
    error: null,
    info: null,
  };

  /**
   * Triggered when an error is thrown while rendering a component in this component's component tree.
   *
   * @param {Error} someError
   * @param {{}} info
   */
  @action componentDidCatch(someError, info) {
    this.caughtError.error = someError;
    this.caughtError.info = info;
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    if (this.caughtError.error) {
      const {message} = this.props;

      return (<span>{message}</span>);
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,

  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

ErrorBoundary.defaultProps = {
  message: 'This could not be displayed.',
};

export default observer(ErrorBoundary);
