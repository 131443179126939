/* eslint-disable import/default */

import {createBrowserHistory as createHistory} from 'history';
import {configure} from 'mobx';
import {Provider} from 'mobx-react';
import {Auth0Provider} from '@auth0/auth0-react';
import React from 'react';
import {render} from 'react-dom';
import {Router} from 'react-router-dom';
import {AppContainer} from 'react-hot-loader';

// Polyfills
import 'url-search-params-polyfill';

import App from './components/app/App';
import config from './config/main';
import preloadData from './preloads/index';
import stores, {hotReloadStores} from './stores/index';
import {initThunks, hotReloadThunks} from './thunks/index';
import {enableMobXLogger} from './utils/debugHelper';
import * as googleAnalytics from './utils/googleAnalytics';
import {attachListenersToHistory, registerHistory} from './utils/history';
import {sentryInit} from './utils/sentryHelper';
import auth0 from './utils/auth0';

const initAuth0 = async () => {
  // set auth0 logged in state if auth0 query params exist
  if (
    window.location.search.includes('code=') &&
    window.location.search.includes('state=')
  ) {
    try {
      await auth0.handleRedirectCallback();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error handling auth0 load', error);
    }
  } else {
    await auth0.checkSession();
  }
};

initAuth0().then(() => {
  sentryInit();
  googleAnalytics.init();

  const routerHistory = createHistory();
  registerHistory(routerHistory);

  googleAnalytics.listen(routerHistory);

  if (config.env === 'development') {
    configure({enforceActions: 'observed'});
    preloadData();
  }

  attachListenersToHistory(stores);

  enableMobXLogger(true);

  initThunks();

  // checkStartState();

  hotReloadStores(stores);
  hotReloadThunks();

  const renderApplication = (Component) => {
    render(
      <Auth0Provider
        domain={config.auth0.domain}
        clientId={config.auth0.clientId}
        authorizationParams={{
          audience: config.auth0.audience,
          'redirect_uri': config.auth0.redirectUri
        }}
        useRefreshTokens={true}
        useRefreshTokensFallback={true}
        cookieDomain={config.auth0.cookieDomain}
      >
        <AppContainer>
          <Provider {...stores}>
            <Router history={routerHistory}>
              <Component />
            </Router>
          </Provider>
        </AppContainer>
      </Auth0Provider>,
      document.getElementById('app')
    );
  };

  renderApplication(App);
});
