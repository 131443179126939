import classNames from 'classnames';
import lodash from 'lodash';
import {action, computed, observable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Alert, Popover, PopoverBody, PopoverHeader} from 'reactstrap';

import ManufacturerSelect from './components/ManufacturerSelect';
import StateSelect from '../stateSelect/StateSelect';
import CountrySelect from '../countrySelect/CountrySelect';
import helpPixelImage from '../../../assets/images/help-one-pixel.png';
import helpPitchImage from '../../../assets/images/help-pitch.png';
import {getAssetUrl} from '../../../utils/assetHelper';

import './signForm.scss';

/**
 * The URL to the help pixel matrix image.
 * @type {string}
 */
const helpMatrixImage = getAssetUrl('help-pixel-matrix.png');

/**
 * Maps the field ids to their property name and label name.
 *
 * @type {Object<string, {propertyName: string, label: string}>}
 */
const fieldMap = {
  'sign-name': {propertyName: 'signName', label: 'Sign Name', from: 'name'},
  'sign-manufacturer': {propertyName: 'signManufacturer', label: 'Sign Manufacturer', from: 'manufacturer'},
  'sign-pitch': {propertyName: 'signPitch', label: 'Sign Resolution Pitch', from: 'pitch'},
  'sign-width': {propertyName: 'signWidth', label: 'Sign Width', from: 'width'},
  'sign-height': {propertyName: 'signHeight', label: 'Sign Height', from: 'height'},
  'sign-address': {propertyName: 'signAddress', label: 'Sign Address', from: 'address1'},
  'sign-city': {propertyName: 'signAddressCity', label: 'Sign City', from: 'city'},
  'sign-state': {propertyName: 'signAddressState', label: 'Sign State', from: 'stateProvince'},
  'sign-zip': {propertyName: 'signAddressZip', label: 'Sign Zip', from: 'zip'},
  'sign-country': {propertyName: 'signAddressCountry', label: 'Sign Country', from: 'country'},
};

/**
 * The SignForm component.
 */
class SignForm extends Component {
  /**
   * The sign name.
   *
   * @type {string}
   */
  @observable signName = '';

  /**
   * The sign manufacturer name.
   *
   * @type {string}
   */
  @observable signManufacturer = '';

  /**
   * The sign resolution pitch.
   *
   * @type {string}
   */
  @observable signPitch = '';

  /**
   * The sign width.
   *
   * @type {string}
   */
  @observable signWidth = '';

  /**
   * The sign height.
   *
   * @type {string}
   */
  @observable signHeight = '';

  /**
   * The sign's first line of address.
   *
   * @type {string}
   */
  @observable signAddress = '';

  /**
   * The sign's address city.
   *
   * @type {string}
   */
  @observable signAddressCity = '';

  /**
   * The sign's address state.
   *
   * @type {string}
   */
  @observable signAddressState = '';

  /**
   * The sign's address zip.
   *
   * @type {number}
   */
  @observable signAddressZip = '';

  /**
   * The sign's address country.
   * This must be the 2 character country code (i.e. US).
   *
   * @type {?string}
   */
  @observable signAddressCountry = 'US';

  /**
   * The validation errors for the form.
   *
   * @type {Map}
   */
  @observable validationErrors = new Map();

  /**
   * Popover state of each popover in this component
   *
   * @type {Object<name, boolean>}
   */
  @observable popovers = {
    optional: false,
    manufacturer: false,
    resolution: false,
    matrix: false,
  };

  /**
   * Triggered when this component first mounts to the page.
   */
  componentDidMount() {
    const {registerSubmit, sign} = this.props;

    if (registerSubmit) {
      registerSubmit(this.onSubmitForm);
    }

    if (sign) {
      this.preloadSign(sign);
    }
  }

  /**
   * Tracks if the form is disabled.
   *
   * @returns {boolean}
   */
  @computed get isSubmitDisabled() {
    return Boolean(
      this.signWidth === null
      || this.signWidth === ''
      || this.signHeight === null
      || this.signHeight === ''
    );
  }

  /**
   * Loads the sign information into the form.
   *
   * @param {{}} sign
   */
  @action preloadSign = (sign) => {
    lodash.forEach(fieldMap, ({from, propertyName}) => {
      if (!from) {
        return;
      }

      const value = sign[from];
      if (value) {
        this[propertyName] = value;
      }
    });
  };

  /**
   * Submits the form.
   */
  onSubmitForm = () => {
    const {onSubmit} = this.props;

    const signData = {};
    lodash.forEach(fieldMap, ({propertyName}) => {
      signData[propertyName] = this[propertyName];
    });

    onSubmit(signData);
  };

  /**
   * Toggles a popover state.
   *
   * @param {string} label
   * @param {boolean=} forceState
   */
  @action toggle = (label, forceState) => {
    if (forceState === true || forceState === false) {
      this.popovers[label] = forceState;
      return;
    }

    this.popovers[label] = !this.popovers[label];
  };

  /**
   * Changes a form input's value.
   *
   * @param {string} elementId
   * @returns {function}
   */
  onFormInputUpdate = (elementId) => {
    const {propertyName} = fieldMap[elementId];

    return action('changeInputValue', (changeEvent) => {
      this[propertyName] = changeEvent.target.value;

      if (elementId) {
        this.validationErrors.delete(elementId);
      }

      if (
        fieldMap[elementId].propertyName === 'signWidth'
        && (this.signWidth === null
          || this.signWidth === '')
      ) {
        this.validationErrors.set(elementId, 'Pixel Width is Required');
      } else if (
        fieldMap[elementId].propertyName === 'signHeight'
        && (this.signHeight === null
          || this.signHeight === '')
      ) {
        this.validationErrors.set(elementId, 'Pixel Height is Required');
      }
    });
  };

  /**
   * Changes a form select's value.
   *
   * @param {string} elementId
   * @returns {function}
   */
  onSelectUpdate = (elementId) => {
    const {propertyName} = fieldMap[elementId];

    return action('changeSelectValue', ({value}) => {
      this[propertyName] = value;

      if (elementId) {
        this.validationErrors.delete(elementId);
      }
    });
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {allowSizes, className, errorMessage, onCancel, showName, showTitle, showSubmit, sign} = this.props;
    const errorsMap = this.validationErrors;

    return (
      <form className={classNames('sign-form', className)}>
        {(showTitle) && (
          <div>
            Fill out information about the sign (optional)
            <div
              id="popover-optional"
              className="help-popover"
              onMouseEnter={() => this.toggle('optional', true)}
              onMouseLeave={() => this.toggle('optional', false)}
            >
              ?
            </div>
            <Popover placement="top" isOpen={this.popovers.optional} target="popover-optional">
              <PopoverBody>
                Once registered, you will be able to add additional signs to your account.
              </PopoverBody>
            </Popover>
          </div>
        )}

        {(showName) && (
          <div className="row">
            <div className="form-group col-12 col-md-6">
              <label className="sr-only" htmlFor="sign-name">Name</label>
              <input
                id="sign-name"
                className="form-control form-control-bottom-border"
                placeholder="Name Your Sign"
                value={this.signName}
                onChange={this.onFormInputUpdate('sign-name')}
              />
            </div>
          </div>
        )}

        <div className="row">
          <div className="form-group col">
            <label className="sr-only" htmlFor="sign-manufacturer">LED Sign Manufacturer</label>
            <ManufacturerSelect
              id="sign-manufacturer"
              className="react-select-bottom-border my-4"
              classNamePrefix="react-select-bottom-border"
              theme="input-white"
              value={this.signManufacturer}
              onChange={this.onSelectUpdate('sign-manufacturer')}
            />
            <div
              id="popover-manufacturer"
              className="help-popover"
              onMouseEnter={() => this.toggle('manufacturer', true)}
              onMouseLeave={() => this.toggle('manufacturer', false)}
            >
              ?
            </div>
            <Popover placement="bottom" isOpen={this.popovers.manufacturer} target="popover-manufacturer">
              <PopoverBody>
                A manufacturer&#39;s logo typically is somewhere on the front or side of the sign.
                If you don&#39;t see a logo, a lot of times the sign software program that manages/controls
                the sign content will be the sameas the manufacturer. You can also check your original sign
                purchaser agreement.
              </PopoverBody>
            </Popover>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-12 col-md-6">
            <label className="sr-only" htmlFor="sign-pitch">Resolution Pitch (mm)</label>
            <input
              type="number"
              id="sign-pitch"
              className="form-control form-control-bottom-border"
              placeholder="Resolution Pitch (mm)"
              value={this.signPitch}
              min={0}
              max={Number.MAX_SAFE_INTEGER}
              onChange={this.onFormInputUpdate('sign-pitch')}
            />
            <div
              id="popover-resolution"
              className="help-popover"
              onMouseEnter={() => this.toggle('resolution', true)}
              onMouseLeave={() => this.toggle('resolution', false)}
            >
              ?
            </div>
            <Popover
              className="sign-form-popover help-popover-resolution"
              placement="right"
              isOpen={this.popovers.resolution}
              target="popover-resolution"
            >
              <PopoverHeader>What is &quot;Pitch&quot;?</PopoverHeader>
              <PopoverBody className="d-flex">
                <p>
                  Full color, outdoor LED displays use a cluster of three LEDs to create a pixel. The distance
                  between these pixels, or pitch, is what determines the resolution of the sign. The closer the
                  pixels are together, the higher the resolution and clarity of the image display.
                </p>
                <img className="help-popover-image" src={helpPitchImage} />
              </PopoverBody>
            </Popover>
          </div>

          {(allowSizes || (sign && !sign.width)) && (
            <div className="form-group col-6 col-md-3">
              <label className="sr-only" htmlFor="sign-width">Pixel Width</label>
              <label className="upper-label">
                Pixel Matrix
                <div
                  id="popover-matrix"
                  className="help-popover"
                  onMouseEnter={() => this.toggle('matrix', true)}
                  onMouseLeave={() => this.toggle('matrix', false)}
                >
                  ?
                </div>
                <Popover
                  className="sign-form-popover help-popover-matrix"
                  placement="bottom"
                  isOpen={this.popovers.matrix}
                  target="popover-matrix"
                >
                  <PopoverHeader>What is my &quot;Pixel Matrix&quot;?</PopoverHeader>
                  <PopoverBody className="d-flex">
                    <div>
                      <p>
                        The pixel matrix is the amount of pixels wide, by the amount of pixels tall on your LED sign.
                        Each cluster of Red, Green, and Blue LEDs equals one pixel.
                      </p>
                      <p>
                        An example pixel matrix is 1920x1080.
                      </p>
                      <img src={helpPixelImage} />
                    </div>
                    <img className="help-popover-image" src={helpMatrixImage} />
                  </PopoverBody>
                </Popover>
              </label>
              <input
                type="number"
                id="sign-width"
                className="form-control form-control-bottom-border"
                placeholder="Pixel Width"
                value={this.signWidth}
                min={0}
                max={Number.MAX_SAFE_INTEGER}
                step={10}
                onChange={this.onFormInputUpdate('sign-width')}
                data-cy="sign-width-input"
              />

              {(errorsMap.has('sign-width')) && (
                <div className="invalid-feedback">{errorsMap.get('sign-width')}</div>
              )}
            </div>
          )}

          {(allowSizes || (sign && !sign.height)) && (
            <div className="form-group col-6 col-md-3">
              <label className="sr-only" htmlFor="sign-height">Pixel Height</label>
              <input
                type="number"
                id="sign-height"
                className="form-control form-control-bottom-border"
                placeholder="Pixel Height"
                value={this.signHeight}
                min={0}
                max={Number.MAX_SAFE_INTEGER}
                step={10}
                onChange={this.onFormInputUpdate('sign-height')}
                data-cy="sign-height-input"
              />

              {(errorsMap.has('sign-height')) && (
                <div className="invalid-feedback">{errorsMap.get('sign-height')}</div>
              )}
            </div>
          )}
        </div>
        <div className="row">
          <div className="form-group col">
            <label className="sr-only" htmlFor="sign-address">Sign Address</label>
            <input
              id="sign-address"
              className="form-control form-control-bottom-border"
              placeholder="Sign Address"
              value={this.signAddress}
              onChange={this.onFormInputUpdate('sign-address')}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6 col-md-5">
            <label className="sr-only" htmlFor="sign-city">City</label>
            <input
              id="sign-city"
              className="form-control form-control-bottom-border"
              placeholder="City"
              value={this.signAddressCity}
              onChange={this.onFormInputUpdate('sign-city')}
            />
          </div>
          <div className="form-group col-6 col-md-4">
            <label className="sr-only" htmlFor="sign-state">State</label>
            <StateSelect
              id="sign-state"
              className="react-select-bottom-border my-4"
              classNamePrefix="react-select-bottom-border"
              theme="input-white"
              value={this.signAddressState}
              onChange={this.onSelectUpdate('sign-state')}
            />
          </div>
          <div className="form-group col-6 col-md-3">
            <label className="sr-only" htmlFor="sign-zip">Zip</label>
            <input
              id="sign-zip"
              className="form-control form-control-bottom-border"
              placeholder="Zip"
              value={this.signAddressZip}
              onChange={this.onFormInputUpdate('sign-zip')}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-7">
            <label className="sr-only" htmlFor="sign-country">Country</label>
            <CountrySelect
              id="sign-country"
              className="react-select-bottom-border my-4"
              classNamePrefix="react-select-bottom-border"
              theme="input-white"
              value={this.signAddressCountry}
              onChange={this.onSelectUpdate('sign-country')}
            />
          </div>
        </div>

        {(errorMessage) && (
          <div className="row">
            <div className="col-12">
              <Alert color="danger">
                {errorMessage}
              </Alert>
            </div>
          </div>
        )}

        {(showSubmit) && (
          <div className="row">
            <div className="col">
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.onSubmitForm}
                disabled={this.isSubmitDisabled}
              >Submit</button>

              {(onCancel) && (
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => onCancel()}
                >Cancel</button>
              )}
            </div>
          </div>
        )}
      </form>
    );
  }
}

SignForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,

  allowSizes: PropTypes.bool,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  onCancel: PropTypes.func,
  registerSubmit: PropTypes.func,
  showName: PropTypes.bool,
  showSubmit: PropTypes.bool,
  showTitle: PropTypes.bool,
  sign: PropTypes.object,
};

SignForm.defaultProps = {
  allowSizes: true,
  showName: true,
  showSubmit: true,
  showTitle: false,
};

export default observer(SignForm);
