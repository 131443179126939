import {markdownToClassMap} from '../../components/controls/editText/constants/styleConstants';

/**
 * The plus character code.
 * @const {number}
 */
const PLUS_CHAR_CODE = 0x2B; // '+'

/**
 * The character representation of the marker.
 * @const {string}
 */
const CHAR = String.fromCharCode(PLUS_CHAR_CODE);

/**
 * The class name that represents the underline.
 * @type {string}
 */
const underlineClassName = markdownToClassMap[CHAR + CHAR];

/**
 * Global tracking of open and closing.
 *
 * @type {boolean}
 */
let isOpen = false;

/**
 * Inserts each ++ as a separate text token, and adds it to delimiter list.
 *
 * @param {{}} state
 * @param {boolean} silent
 * @returns {boolean}
 */
function tokenize(state, silent) {
  if (silent) {
    return false;
  }

  const start = state.pos;

  if (state.src.charCodeAt(start) !== PLUS_CHAR_CODE) {
    return false;
  } else if (state.src.charCodeAt(start + 1) !== PLUS_CHAR_CODE) {
    // Underline requires 2 pluses (++).
    return false;
  }

  isOpen = !isOpen;

  const token = state.push('text', '', 0);
  token.content = CHAR + CHAR;

  state.delimiters.push({
    marker: PLUS_CHAR_CODE,
    jump: 0,
    token: state.tokens.length - 1,
    level: state.level,
    end: -1,
    open: isOpen,
    close: !isOpen
  });

  state.pos += 2;

  return true;
}

/**
 * Walks through delimiter list and replace text tokens with tags.
 *
 * @param {{}} state
 */
function postProcess(state) {
  const loneMarkers = [];
  const delimiters = state.delimiters;
  const maxDelimLength = state.delimiters.length;

  for (let i = 0; i < maxDelimLength; i += 1) {
    const startDelim = delimiters[i];

    if (startDelim.marker !== PLUS_CHAR_CODE) {
      continue;
    } else if (startDelim.end === -1) {
      continue;
    }

    const endDelim = delimiters[startDelim.end];

    const openToken = state.tokens[startDelim.token];
    openToken.type = 'underline_open';
    openToken.tag = 'span';
    openToken.attrs = [['class', underlineClassName]];
    openToken.nesting = 1;
    openToken.markup = CHAR + CHAR;
    openToken.content = '';

    const closeToken = state.tokens[endDelim.token];
    closeToken.type = 'underline_close';
    closeToken.tag = 'span';
    closeToken.nesting = -1;
    closeToken.markup = CHAR + CHAR;
    closeToken.content = '';

    const prevToken = state.tokens[endDelim.token - 1];
    if (prevToken.type === 'text' && prevToken.content === CHAR) {
      loneMarkers.push(endDelim.token - 1);
    }
  }

  /**
   * If a marker sequence has an odd number of characters, it's split
   * like this: `+++++` -> `+` + `++` + `++`, leaving one marker at the
   * start of the sequence.
   *
   * So, we have to move all those markers after subsequent underline_close tags.
   */
  while (loneMarkers.length) {
    let i = loneMarkers.pop();
    let j = i + 1;

    while (j < state.tokens.length && state.tokens[j].type === 'underline_close') {
      j += 1;
    }

    j -= 1;

    if (i !== j) {
      const token = state.tokens[j];
      state.tokens[j] = state.tokens[i];
      state.tokens[i] = token;
    }
  }
}

/**
 * Parses the font styles as a markdown-it plugin.
 *
 * @param {{}} md
 */
export function underlinePlugin(md) {
  isOpen = false;

  md.inline.ruler.after('emphasis', 'underline', tokenize);
  md.inline.ruler2.after('emphasis', 'underline', postProcess);
}
