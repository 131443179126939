import {action, computed, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Modal, ModalBody, ModalFooter} from 'reactstrap';

import PublishNew from './components/PublishNew';
import PublishReplace from './components/PublishReplace';
import LoadingIcon from '../../common/loadingIcon/LoadingIcon';
import inject from '../../hoc/injectHoc';
import {STATE_PENDING} from '../../../constants/asyncConstants';

import './publishContentModal.scss';

/**
 * The default modal title.
 * @const {string}
 */
const DEFAULT_TITLE = 'Publish Template';

/**
 * Indicates the content will be published as new content.
 * @const {string}
 */
const PUBLISH_NEW = 'publishNew';

/**
 * Indicates the content will be published as a replacement of other content.
 * @const {string}
 */
const PUBLISH_REPLACE = 'publishReplace';

/**
 * The PublishContentModal component.
 */
export class PublishContentModal extends React.Component {
  /**
   * The content id for the content that is to be replaced.
   *
   * @type {?number}
   */
  @observable replaceContentId = null;

  /**
   * The selected collection id.
   *
   * @type {?number}
   */
  @observable collectionId = null;

  /**
   * The given content name.
   *
   * @type {string}
   */
  @observable newContentName = '';

  /**
   * Whether or not to publish as new content or to replace existing content.
   *
   * @type {?string}
   */
  @observable newOrReplace = null;

  /**
   * Triggered when the component just mounted onto the page.
   */
  componentDidMount() {
    if (this.props.isOpen) {
      this.initializeModal();
    }
  }

  /**
   * Triggered when the component has just updated.
   *
   * @param {{isOpen: boolean}} prevProps
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.initializeModal();
    }
  }

  /**
   * Whether or not to disable the 'Ok' button
   */
  @computed get isDisabled() {
    const {askForName} = this.props;

    if (!this.newOrReplace) {
      return true;
    }

    if (this.newOrReplace === PUBLISH_NEW) {
      if (askForName && !this.newContentName) {
        return true;
      } else if (!this.collectionId) {
        return true;
      }
    } else if (this.newOrReplace === PUBLISH_REPLACE) {
      if (!this.replaceContentId) {
        return true;
      }
    }

    return false;
  }

  /**
   * Initializes the modal.
   */
  @action initializeModal = () => {
    if (this.props.defaultName) {
      this.newContentName = this.props.defaultName;
    }
  };

  /**
   * Tears down the modal.
   */
  tearDownModal = () => {
    // Do something.
  };

  /**
   * Triggered when the modal is closed without a finishing.
   */
  onCancelModal = () => {
    const {onComplete} = this.props;

    onComplete({
      new: false,
      replace: false,
    });

    this.tearDownModal();
  };

  /**
   * Triggered when the modal is closed after choosing a collection.
   */
  onCompleteModal = () => {
    const {
      apiContentPublishStore,
      apiContentFolderGetAllStore,
      apiCollectionGetAllStore,
      contentId,
      onComplete
    } = this.props;

    if (this.newOrReplace === PUBLISH_REPLACE) {
      apiContentPublishStore.makeRequest(contentId, {
        replace: {
          collectionId: this.collectionId,
          contentId: this.replaceContentId,
        },
      });
    } else if (this.newOrReplace === PUBLISH_NEW) {
      apiContentPublishStore.makeRequest(contentId, {
        new: {
          collectionId: this.collectionId,
          contentName: this.newContentName,
        },
      });
    }

    apiContentPublishStore.getPromise().then((response) => {
      apiContentFolderGetAllStore.refresh(true);
      apiCollectionGetAllStore.refresh(true);

      onComplete(response);
    }).catch((publishError) => {
      onComplete({error: publishError});
    });

    this.tearDownModal();
  };

  /**
   * Called when a collection is selected.
   *
   * @param {number} collectionId
   */
  @action onCollectionSelected = (collectionId) => {
    this.collectionId = collectionId;
  };

  /**
   * Triggered when the name input changes.
   *
   * @param {string} newName
   */
  @action onNameChange = (newName) => {
    this.newContentName = newName;
  };

  /**
   * Triggered when the type input changes.
   *
   * @param {{target: {value: string}}} changeEvent
   */
  @action onTypeChange = (changeEvent) => {
    this.newOrReplace = String(changeEvent.target.value);
  };

  /**
   * Triggered when the content input changes.
   *
   * @param {number} newContentId
   * @param {number} contentCollectionId
   */
  @action onContentSelected = (newContentId, contentCollectionId) => {
    this.replaceContentId = newContentId;

    if (contentCollectionId) {
      this.collectionId = contentCollectionId;
    }
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      apiContentPublishStore,
      askForName,
      isOpen,
      title,
      user,
    } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={this.onCancelModal} className="publish-content-modal" size="xl" centered>
        <p className="site-label">{title}</p>
        <ModalBody>
          <div className="publish-type">
            <div className="form-group">
              <label className="control-label">Publish as New or Replace Existing</label>
              <div>
                <label className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="newOrReplace"
                    value={PUBLISH_NEW}
                    checked={this.newOrReplace === PUBLISH_NEW}
                    onChange={this.onTypeChange}
                  />
                  <span>New</span>
                </label>
                <label className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="newOrReplace"
                    value={PUBLISH_REPLACE}
                    checked={this.newOrReplace === PUBLISH_REPLACE}
                    onChange={this.onTypeChange}
                  />
                  <span>Replace</span>
                </label>
              </div>
            </div>
          </div>

          {(this.newOrReplace === PUBLISH_NEW) && (
            <PublishNew
              askForName={askForName}
              collectionId={this.collectionId}
              contentName={this.newContentName}
              onCollectionSelected={this.onCollectionSelected}
              onNameChange={this.onNameChange}
              user={user}
            />
          )}

          {(this.newOrReplace === PUBLISH_REPLACE) && (
            <PublishReplace
              contentId={this.replaceContentId}
              onContentSelected={this.onContentSelected}
              user={user}
            />
          )}
        </ModalBody>
        <ModalFooter>
          {(apiContentPublishStore.getState() === STATE_PENDING) ? (
            <span>
              <LoadingIcon size="sm" />
              Publishing...
            </span>
          ) : (
            <span>
              <button
                type="button"
                className="btn btn-default"
                onClick={this.onCancelModal}
              >Cancel</button>
              {' '}
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.onCompleteModal}
                disabled={this.isDisabled}
              >Publish</button>
            </span>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

PublishContentModal.propTypes = {
  contentId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,

  apiCollectionGetAllStore: MobxPropTypes.observableObject,
  apiContentFolderGetAllStore: MobxPropTypes.observableObject,
  apiContentPublishStore: MobxPropTypes.observableObject,
  askForName: PropTypes.bool,
  defaultName: PropTypes.string,
  title: PropTypes.string,
  user: PropTypes.object,
};

PublishContentModal.defaultProps = {
  askForName: true,
  defaultName: '',
  title: DEFAULT_TITLE,
};

PublishContentModal.wrappedComponent = {};
PublishContentModal.wrappedComponent.propTypes = {
  apiCollectionGetAllStore: MobxPropTypes.observableObject.isRequired,
  apiContentFolderGetAllStore: MobxPropTypes.observableObject.isRequired,
  apiContentPublishStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(PublishContentModal)(
  observer(PublishContentModal)
);
