import lodash from 'lodash';
import {action, observable, toJS} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import {PropTypes} from 'prop-types';
import React from 'react';

import ConfirmModal from '../../../../modals/confirm/ConfirmModal';
import SelectFolderModal from '../../../../modals/selectFolder/SelectFolderModal';
import SelectSignAndFileTypeModal from '../../../../modals/selectSignAndFileType/SelectSignAndFileTypeModal';
import inject from '../../../../hoc/injectHoc';
import {CONTENT_LIST_FOLDER} from '../../../../../constants/contentConstants';
import {OUT_OF_CREDITS_TEXT} from '../../../../../constants/messageConstants';
import {parseContentIntoSignSizes} from '../../../../../utils/contentsHelper';

import './downloadButton.scss';

/**
 * The DownloadButton component.
 */
class DownloadButton extends React.Component {
  /**
   * Whether or not the download dropdown is open.
   *
   * @type {boolean}
   */
  @observable isDownloadDropdownOpen = false;

  /**
   * Whether or not the confirm modal before saving is open.
   *
   * @type {boolean}
   */
  @observable isBeforeSaveConfirmOpen = false;

  /**
   * Whether or not the folder modal is open.
   *
   * @type {boolean}
   */
  @observable isFolderModalOpen = false;

  /**
   * Whether or not the select file is open.
   *
   * @type {boolean}
   */
  @observable isSelectSignModalOpen = false;

  /**
   * Whether or not the dropdown for the save options is open.
   *
   * @type {boolean}
   */
  @observable isSaveAlertOpen = false;

  /**
   * The save alert message.
   *
   * @type {string}
   */
  @observable saveAlertMessage = '';

  /**
   * The sign id to render
   *
   * @type {number}
   */
  @observable signIdToRender = null;

  /**
   * The file type to render
   *
   * @type {string}
   */
  @observable fileTypeToRender = null;

  /**
   * Closes the save alert.
   */
  @action closeAlertModal = () => {
    this.isSaveAlertOpen = false;
  };

  /**
   * Toggles the save alert.
   */
  @action openSaveAlert = () => {
    this.isSaveAlertOpen = true;
  };

  /**
   * Opens the folder modal if the save was confirmed.
   *
   * @param {boolean} wasConfirmed
   */
  @action onSaveConfirmComplete = (wasConfirmed) => {
    this.isBeforeSaveConfirmOpen = false;

    if (!wasConfirmed) {
      return;
    }

    this.isSelectSignModalOpen = true;
  }

  /**
   * Closes the folder data and saves the content.
   *
   * @param {{}} param
   * @param {?number} param.folderId
   * @param {string} param.contentName
   */
  @action closeFolderModal = ({folderId, contentName}) => {
    this.isFolderModalOpen = false;

    if (!contentName) {
      return;
    }

    const {apiContentCreateStore, navigateContentStore, content, apiCompanySignGetAllStore, apiContentFolderGetContentStore} = this.props;

    apiCompanySignGetAllStore.refresh();
    let signs = null;
    const signsPromise = apiCompanySignGetAllStore.getPromise().then((foundSigns) => {
      if (foundSigns && foundSigns.length) {
        signs = foundSigns;
      }
    }).catch(() => {
      signs = null;
    });

    const variables = toJS(content.variables);

    let contentId = null;

    signsPromise.then(() => {
      const fittedSources = parseContentIntoSignSizes(signs, content, []);

      apiContentCreateStore.makeRequest({
        contentFolderId: folderId,
        fromContentId: content.id,
        duration: content.duration,
        height: content.height,
        name: contentName,
        sources: fittedSources,
        variables,
        width: content.width,
        isFree: content.isFree,
        isDraft: false
      });

      return apiContentCreateStore.getPromise();
    })
      .catch(
        action('saveTemplateErrorToAlert', (saveError) => {
          if (lodash.get(saveError, 'type') === 'OutOfCreditsError') {
            this.saveAlertMessage = 'You do not have enough download credits remaining to save this content.';
          } else {
            this.saveAlertMessage = 'An error occurred while trying to save this template.'
              + ' Please wait a few minutes and try again.';
          }

          this.openSaveAlert();

          return null;
        })
      ).then((newContent) => {
        if (!newContent || !newContent.id) {
          return null;
        }

        contentId = newContent.id;

        return this.startRenderProcess(contentId);
      }).then(() => {
        if (contentId) {
          // refresh content folder so template statuses are properly updated
          apiContentFolderGetContentStore.refresh(folderId, true);

          navigateContentStore.navigateToDashboard(null, {
            contentId,
            listType: CONTENT_LIST_FOLDER,
            listId: folderId,
          });
        }
      });
  };

  /**
   * Closes the select sign modal
   *
   * @param {{}} param
   * @param {?number} param.signId
   * @param {?string} param.fileType
   * @returns {null}
   */
  @action closeSelectSignModal = ({signId, fileType}) => {
    this.isSelectSignModalOpen = false;

    if (!signId || !fileType) {
      return null;
    }

    this.signIdToRender = signId;
    this.fileTypeToRender = fileType;

    this.isFolderModalOpen = true;
    return null;
  }

  /**
   * Starts the rendering process.
   *
   * @param {number} contentId
   * @returns {Promise}
   */
  startRenderProcess = (contentId) => {
    const {apiContentRenderStore} = this.props;

    apiContentRenderStore.makeRequest(contentId, this.fileTypeToRender, this.signIdToRender);

    return apiContentRenderStore.getPromise(
      apiContentRenderStore.getKey(contentId, this.signIdToRender)
    ).catch(() => {
      // Ignore render errors.
    });
  };

  /**
   * Starts the render process
   * Triggered when the download as is button is clicked.
   *
   * @returns {function}
   */
  onClickDownloadAsIs = () => {
    const {showCreditMessage} = this.props;
    return action('onClickDownloadAsIs collection', () => {
      if (showCreditMessage) {
        this.isBeforeSaveConfirmOpen = true;
      } else {
        this.onSaveConfirmComplete(true);
      }
    });
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {content, showCreditMessage} = this.props;

    const isRendering = (content.displayStatus === 'processing');
    const isDraft = Boolean(content.isDraft);

    return (
      <>
        <button
          data-cy="download-button"
          className="btn btn-light info-btn-responsive"
          type="button"
          disabled={isRendering || isDraft}
          onClick={this.onClickDownloadAsIs()}
        >
          {(isRendering) ? 'Creating...' : 'Download'}
        </button>

        {

          // message alert modal
          (this.isSaveAlertOpen) && (
            <ConfirmModal
              isOpen={true}
              confirmText={this.saveAlertMessage}
              isYesNo={false}
              title="After Saved"
              onComplete={this.closeAlertModal}
            />
          )
        }

        {

          // confirm credit use modal
          (this.isBeforeSaveConfirmOpen && showCreditMessage) && (<ConfirmModal
            isOpen={true}
            bodyText={(
              <>
                <div>
                  {OUT_OF_CREDITS_TEXT}
                </div>
                <br />
                <div>Are you sure you are ready to save?</div>
              </>
            )}
            confirmText=""
            isYesNo={true}
            title="Are you sure you are ready to save?"
            onComplete={this.onSaveConfirmComplete}
          />)
        }

        {

          // select sign and file type modal
          (this.isSelectSignModalOpen) && (
            <SelectSignAndFileTypeModal
              isOpen={true}
              content={content}
              onComplete={this.closeSelectSignModal}
            />
          )
        }

        {

          // select folder modal
          (this.isFolderModalOpen) && (
            <SelectFolderModal
              defaultName={(content && content.name) ? content.name : ''}
              isOpen={true}
              onComplete={this.closeFolderModal}
            />
          )
        }
      </>
    );
  }
}

DownloadButton.propTypes = {
  content: PropTypes.object.isRequired,
  apiCompanySignGetAllStore: MobxPropTypes.observableObject,
  apiContentCreateStore: MobxPropTypes.observableObject,
  apiContentFolderGetContentStore: MobxPropTypes.observableObject,
  apiContentRenderGetDownloadableStore: MobxPropTypes.observableObject,
  apiContentRenderStore: MobxPropTypes.observableObject,
  navigateContentStore: MobxPropTypes.observableObject,
  showCreditMessage: PropTypes.bool,
};

DownloadButton.defaultProps = {
  showCreditMessage: true,
};

DownloadButton.wrappedComponent = {};
DownloadButton.wrappedComponent.propTypes = {
  apiCompanySignGetAllStore: MobxPropTypes.observableObject.isRequired,
  apiContentCreateStore: MobxPropTypes.observableObject.isRequired,
  apiContentFolderGetContentStore: MobxPropTypes.observableObject.isRequired,
  apiContentRenderGetDownloadableStore: MobxPropTypes.observableObject.isRequired,
  apiContentRenderStore: MobxPropTypes.observableObject.isRequired,
  navigateContentStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(DownloadButton)(
  observer(DownloadButton)
);
