import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Modal, ModalBody} from 'reactstrap';

import FileUploadingProgress from '../../common/fileUploadingProgress/FileUploadingProgress';
import LoadingIcon from '../../common/loadingIcon/LoadingIcon';
import inject from '../../hoc/injectHoc';
import {STATE_PENDING} from '../../../constants/asyncConstants';
import {blockTransition} from '../../../utils/history';

import './fileUploadingModal.scss';

/**
 * The FileUploadingModal component.
 *
 * @param {{
 *   apiFileUploadStore: ApiFileUploadStore,
 *   isOpen: boolean,
 *   onComplete: function,
 * }} props
 */
class FileUploadingModal extends React.Component {
  /**
   * The function used to remove the block on router transitions.
   *
   * @type {?function}
   */
  unblockRouterTransitions = null;

  /**
   * Triggered when the component is added to the page.
   */
  componentDidMount() {
    window.addEventListener('beforeunload', this.onPageUnload);

    this.unblockRouterTransitions = blockTransition(() => {
      if (!this.isUploadingInProgress()) {
        return undefined;
      }
      return 'You have uploads in progress that will be canceled. Are you sure you want to navigate away?';
    });
  }

  /**
   * Triggered when the component is about to be removed from the page.
   */
  componentWillUnmount() {
    // We want to cancel all the uploading files.
    const {apiFileUploadStore} = this.props;

    window.removeEventListener('beforeunload', this.onPageUnload);

    if (this.unblockRouterTransitions) {
      this.unblockRouterTransitions();
    }

    apiFileUploadStore.cancelAll();
    apiFileUploadStore.clearAll();
  }

  /**
   * Whether or not there are changes to the game/video/image.
   *
   * @returns {boolean}
   */
  isUploadingInProgress = () => {
    const {apiFileUploadStore} = this.props;
    return (apiFileUploadStore.uploadingState === STATE_PENDING);
  };

  /**
   * Triggered when the page attempts to unload if uploads are in progress.
   * This will open a confirm dialog using the returned string.
   *
   * @param {{}} unloadEvent
   */
  onPageUnload = (unloadEvent) => {
    if (this.isUploadingInProgress()) {
      unloadEvent.returnValue = 'You have uploads in progress that will be canceled.'
        + ' Are you sure you want to navigate away?';
    }
  };

  /**
   * Triggered when the modal is cancelled.
   */
  onCancelModal = () => {
    const {apiFileUploadStore, onComplete} = this.props;

    apiFileUploadStore.clearAll();

    onComplete();
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {apiFileUploadStore, isOpen} = this.props;

    const canClose = (apiFileUploadStore.uploadingState !== STATE_PENDING);

    return (
      <Modal className="file-uploading-modal" isOpen={isOpen} centered>
        <ModalBody>
          <div className="modal-body-content">
            <h3>{(!canClose) ? 'Uploading...' : 'Upload Finished'}</h3>

            <div className="file-progress-wrapper">
              <FileUploadingProgress />
            </div>

            <div className="close-modal">
              {(canClose) ? (
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.onCancelModal}
                >Close</button>
              ) : (
                <LoadingIcon size="sm" />
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

FileUploadingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,

  apiFileUploadStore: MobxPropTypes.observableObject,
};

FileUploadingModal.wrappedComponent = {
  propTypes: {
    apiFileUploadStore: MobxPropTypes.observableObject.isRequired,
  }
};

export default inject(FileUploadingModal)(
  observer(FileUploadingModal)
);
