import {action} from 'mobx';

import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';
import ApiMapBaseStore from '../common/apiMapBaseStore';

/**
 * ApiContentRenderGetDownloadableStore
 */
class ApiContentRenderGetDownloadableStore extends ApiMapBaseStore {
  /**
   * Fetches content info from the server
   *
   * @param {string} contentId
   */
  @action makeRequest(contentId) {
    const safeContentId = String(contentId);

    this.dataMap.set(safeContentId, {
      state: STATE_PENDING,
      [EXPIRE_TIME]: Date.now() + EXPIRES_PENDING,
      data: null,
      error: null,
    });

    serverApi.contentRenderGetDownloadable(
      safeContentId
    ).then(action('contentRenderGetDownloadableSuccess', (foundDownloadable) => {
      this.dataMap.set(safeContentId, {
        state: STATE_FULFILLED,
        [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
        data: foundDownloadable,
        error: null,
      });
    })).catch(action('contentRenderGetDownloadableError', (fetchError) => {
      this.dataMap.set(safeContentId, {
        state: STATE_REJECTED,
        [EXPIRE_TIME]: null,
        data: null,
        error: fetchError,
      });
    }));
  }
}

export default new ApiContentRenderGetDownloadableStore();
