import mainConfig from '../../../../config/main';
import {findClosestAspectRatioByResolution} from '../../../../utils/mathHelper';
import fourByOne from './layouts/fourByOne';
import oneByFour from './layouts/oneByFour';
import oneByOne from './layouts/oneByOne';
import oneByThree from './layouts/oneByThree';
import oneByTwo from './layouts/oneByTwo';
import sixteenByNine from './layouts/sixteenByNine';
import threeByOne from './layouts/threeByOne';
import threeByTwo from './layouts/threeByTwo';
import twoByOne from './layouts/twoByOne';

const ICON_PACK_NAME_FORECAST_ONE = 'forecast-1';
const ICON_PACK_URL_FORECAST_ONE = `${mainConfig.assets.iconUrl}/forecast-1/icon-pack.zip`;
const ICON_PACK_PLACEHOLDER_FORECAST_ONE = `${mainConfig.assets.iconUrl}/forecast-1/placeholder.png`;

const layoutConfigurationDictionary = {
  '16:9': sixteenByNine,
  '1:1': oneByOne,
  '1:2': oneByTwo,
  '1:3': oneByThree,
  '1:4': oneByFour,
  '4:1': fourByOne,
  '3:1': threeByOne,
  '2:1': twoByOne,
  '3:2': threeByTwo,
};

const availableAspectRatios = Object.keys(layoutConfigurationDictionary);

/**
 * Generate extended weather layers by resolution and configuration variables
 *
 * @param {Object} resolution
 * @param {number} resolution.width
 * @param {number} resolution.height
 * @param {Object} configuration
 * @returns {Array.<Object>}
 */
const generateExtendedWeatherLayers = (resolution, {
  DAY_ALIGN,
  HIGH_ALIGN,
  LOW_ALIGN,
  DAY_WIDTH_RATIO,
  DAY_HEIGHT_RATIO,
  DAY_ONE_POSITION_X_RATIO,
  DAY_ONE_POSITION_Y_RATIO,
  DAY_TWO_POSITION_X_RATIO,
  DAY_TWO_POSITION_Y_RATIO,
  DAY_THREE_POSITION_X_RATIO,
  DAY_THREE_POSITION_Y_RATIO,
  ICON_WIDTH_RATIO,
  ICON_HEIGHT_RATIO,
  ICON_ONE_POSITION_X_RATIO,
  ICON_ONE_POSITION_Y_RATIO,
  ICON_TWO_POSITION_X_RATIO,
  ICON_TWO_POSITION_Y_RATIO,
  ICON_THREE_POSITION_X_RATIO,
  ICON_THREE_POSITION_Y_RATIO,
  HIGH_WIDTH_RATIO,
  HIGH_HEIGHT_RATIO,
  HIGH_ONE_POSITION_X_RATIO,
  HIGH_ONE_POSITION_Y_RATIO,
  HIGH_TWO_POSITION_X_RATIO,
  HIGH_TWO_POSITION_Y_RATIO,
  HIGH_THREE_POSITION_X_RATIO,
  HIGH_THREE_POSITION_Y_RATIO,
  LOW_WIDTH_RATIO,
  LOW_HEIGHT_RATIO,
  LOW_ONE_POSITION_X_RATIO,
  LOW_ONE_POSITION_Y_RATIO,
  LOW_TWO_POSITION_X_RATIO,
  LOW_TWO_POSITION_Y_RATIO,
  LOW_THREE_POSITION_X_RATIO,
  LOW_THREE_POSITION_Y_RATIO,
}) => {
  const {width, height} = resolution;

  const dayOnePosition = {
    x: DAY_ONE_POSITION_X_RATIO * width,
    y: DAY_ONE_POSITION_Y_RATIO * height,
  };
  const dayTwoPosition = {
    x: DAY_TWO_POSITION_X_RATIO * width,
    y: DAY_TWO_POSITION_Y_RATIO * height,
  };
  const dayThreePosition = {
    x: DAY_THREE_POSITION_X_RATIO * width,
    y: DAY_THREE_POSITION_Y_RATIO * height,
  };
  const daySize = {
    width: DAY_WIDTH_RATIO * width,
    height: DAY_HEIGHT_RATIO * height,
  };

  const iconOnePosition = {
    x: ICON_ONE_POSITION_X_RATIO * width,
    y: ICON_ONE_POSITION_Y_RATIO * height,
  };
  const iconTwoPosition = {
    x: ICON_TWO_POSITION_X_RATIO * width,
    y: ICON_TWO_POSITION_Y_RATIO * height,
  };
  const iconThreePosition = {
    x: ICON_THREE_POSITION_X_RATIO * width,
    y: ICON_THREE_POSITION_Y_RATIO * height,
  };
  const iconSize = {
    width: ICON_WIDTH_RATIO * width,
    height: ICON_HEIGHT_RATIO * height,
  };

  const highOnePosition = {
    x: HIGH_ONE_POSITION_X_RATIO * width,
    y: HIGH_ONE_POSITION_Y_RATIO * height,
  };
  const highTwoPosition = {
    x: HIGH_TWO_POSITION_X_RATIO * width,
    y: HIGH_TWO_POSITION_Y_RATIO * height,
  };
  const highThreePosition = {
    x: HIGH_THREE_POSITION_X_RATIO * width,
    y: HIGH_THREE_POSITION_Y_RATIO * height,
  };
  const highSize = {
    width: HIGH_WIDTH_RATIO * width,
    height: HIGH_HEIGHT_RATIO * height,
  };

  const lowOnePosition = {
    x: LOW_ONE_POSITION_X_RATIO * width,
    y: LOW_ONE_POSITION_Y_RATIO * height,
  };
  const lowTwoPosition = {
    x: LOW_TWO_POSITION_X_RATIO * width,
    y: LOW_TWO_POSITION_Y_RATIO * height,
  };
  const lowThreePosition = {
    x: LOW_THREE_POSITION_X_RATIO * width,
    y: LOW_THREE_POSITION_Y_RATIO * height,
  };
  const lowSize = {
    width: LOW_WIDTH_RATIO * width,
    height: LOW_HEIGHT_RATIO * height,
  };

  return [
    // day 1
    {
      type: 'ForecastHigh1',
      entityType: 'feed',
      markdown: `{${HIGH_ALIGN}}{$1.00}{%1}{100}{#ffffff}{poppinsblack}90°{!poppinsblack}{!#ffffff}{!100}{!%1}{!$1.00}{!${HIGH_ALIGN}}`,
      position: highOnePosition,
      size: highSize,
    },
    {
      type: 'ForecastLow1',
      entityType: 'feed',
      markdown: `{${LOW_ALIGN}}{$1.00}{%1}{100}{#ffffff}{poppinsbold}80°{!poppinsbold}{!#ffffff}{!100}{!%1}{!$1.00}{!${LOW_ALIGN}}`,
      position: lowOnePosition,
      size: lowSize,
      opacity: 0.6,
    },
    {
      type: 'ForecastDay1',
      entityType: 'feed',
      markdown: `{${DAY_ALIGN}}{$1.00}{%1}{100}{#ffffff}{poppinsbold}TODAY{!poppinsbold}{!#ffffff}{!100}{!%1}{!$1.00}{!${DAY_ALIGN}}`,
      position: dayOnePosition,
      size: daySize,
      opacity: 0.6,
    },
    {
      type: 'ForecastIcon1',
      entityType: 'feedicon',
      position: iconOnePosition,
      size: iconSize,
      iconPack: ICON_PACK_NAME_FORECAST_ONE,
      iconPackUrl: ICON_PACK_URL_FORECAST_ONE,
      url: ICON_PACK_PLACEHOLDER_FORECAST_ONE,
    },

    // day 2
    {
      type: 'ForecastHigh2',
      entityType: 'feed',
      markdown: `{${HIGH_ALIGN}}{$1.00}{%1}{100}{#ffffff}{poppinsblack}90°{!poppinsblack}{!#ffffff}{!100}{!%1}{!$1.00}{!${HIGH_ALIGN}}`,
      position: highTwoPosition,
      size: highSize,
    },
    {
      type: 'ForecastLow2',
      entityType: 'feed',
      markdown: `{${LOW_ALIGN}}{$1.00}{%1}{100}{#ffffff}{poppinsbold}80°{!poppinsbold}{!#ffffff}{!100}{!%1}{!$1.00}{!${LOW_ALIGN}}`,
      position: lowTwoPosition,
      size: lowSize,
      opacity: 0.6,
    },
    {
      type: 'ForecastDay2',
      entityType: 'feed',
      markdown: `{${DAY_ALIGN}}{$1.00}{%1}{100}{#ffffff}{poppinsbold}TUE{!poppinsbold}{!#ffffff}{!100}{!%1}{!$1.00}{!${DAY_ALIGN}}`,
      position: dayTwoPosition,
      size: daySize,
      opacity: 0.6,
    },
    {
      type: 'ForecastIcon2',
      entityType: 'feedicon',
      position: iconTwoPosition,
      size: iconSize,
      iconPack: ICON_PACK_NAME_FORECAST_ONE,
      iconPackUrl: ICON_PACK_URL_FORECAST_ONE,
      url: ICON_PACK_PLACEHOLDER_FORECAST_ONE,
    },

    // day 3
    {
      type: 'ForecastHigh3',
      entityType: 'feed',
      markdown: `{${HIGH_ALIGN}}{$1.00}{%1}{100}{#ffffff}{poppinsblack}90°{!poppinsblack}{!#ffffff}{!100}{!%1}{!$1.00}{!${HIGH_ALIGN}}`,
      position: highThreePosition,
      size: highSize,
    },
    {
      type: 'ForecastLow3',
      entityType: 'feed',
      markdown: `{${LOW_ALIGN}}{$1.00}{%1}{100}{#ffffff}{poppinsbold}80°{!poppinsbold}{!#ffffff}{!100}{!%1}{!$1.00}{!${LOW_ALIGN}}`,
      position: lowThreePosition,
      size: lowSize,
      opacity: 0.6,
    },
    {
      type: 'ForecastDay3',
      entityType: 'feed',
      markdown: `{${DAY_ALIGN}}{$1.00}{%1}{100}{#ffffff}{poppinsbold}WED{!poppinsbold}{!#ffffff}{!100}{!%1}{!$1.00}{!${DAY_ALIGN}}`,
      position: dayThreePosition,
      size: daySize,
      opacity: 0.6,
    },
    {
      type: 'ForecastIcon3',
      entityType: 'feedicon',
      position: iconThreePosition,
      size: iconSize,
      iconPack: ICON_PACK_NAME_FORECAST_ONE,
      iconPackUrl: ICON_PACK_URL_FORECAST_ONE,
      url: ICON_PACK_PLACEHOLDER_FORECAST_ONE,
    },
  ];
};

/**
 * Get sized extended weather layers
 *
 * @param {Object} resolution
 * @param {number} resolution.width
 * @param {number} resolution.height
 * @returns {Array.<Object>}
 */
export const getExtendedWeatherLayers = (resolution) => {
  const closestAspectRatio = findClosestAspectRatioByResolution(resolution, availableAspectRatios);

  return generateExtendedWeatherLayers(resolution, layoutConfigurationDictionary[closestAspectRatio]);
};
