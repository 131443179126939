import lodash from 'lodash';

import {EASINGS} from '../../ecs/easingHelper';
import overshootInLeftGif from '../../../assets/images/transitions/overshootInLeft.gif';

/**
 * The name that signals this preset.
 * @const {string}
 */
export const PRESET_OVERSHOOT_OUT_LEFT = 'overshootOutLeft';

/**
 * The preset details.
 *
 * @type {{
 *   name: string,
 *   humanName: string,
 *   flow: string,
 *   details: Object.<string, {
 *     easing: string,
 *     parse: boolean,
 *     values: *|Array.<*>,
 *     time: {start: (string|number), end: (string|number)}
 *   }>,
 *   template: {}
 * }}
 */
export const overshootOutLeftPresetDetails = {
  // The name of the preset.
  name: PRESET_OVERSHOOT_OUT_LEFT,

  // A human friendly name for the preset.
  humanName: 'Overshoot Out to the Left',

  // The element names that this preset can be used for or true for all elements.
  elements: true,

  // A preview of the transition.
  gif: overshootInLeftGif,

  // The preset direction: in, out, middle. This is used in the UI logic.
  flow: 'out',

  // Each property that will change during the transition.
  details: {
    'position.x': {
      // The name of the easing this property will use.
      easing: EASINGS.easeInBack,

      // The start and end times for the transition of this property.
      time: {
        end: 'end.0',
        start: 'end.-1000'
      },

      // The value of the property that will change during the transition.
      values: [
        'position.left',
        'off.left'
      ],
      immediateRender: false
    },
  },

  // How this preset can be used in the source (including optional parameters).
  // This will be turned into a JSON string, so no functions can be used.
  template: {
    preset: PRESET_OVERSHOOT_OUT_LEFT,
    options: {},
  },
};

overshootOutLeftPresetDetails.template.options = lodash.cloneDeep(overshootOutLeftPresetDetails.details);

// This defines the key in the presets object.
export const presetName = PRESET_OVERSHOOT_OUT_LEFT;

// This defines the value of the presets object.
export default overshootOutLeftPresetDetails;
