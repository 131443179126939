import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Modal, ModalBody} from 'reactstrap';
import lodash from 'lodash';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit} from '@fortawesome/free-regular-svg-icons';

import ContentName from './components/contentName/ContentName';
import ContentBadges from './components/contentBadges/ContentBadges';
import ContentPreview from './components/contentPreview/ContentPreview';
import DownloadButton from './components/downloadButton/DownloadButton';
import DropdownActions from './components/dropdownActions/DropdownActions';
import inject from '../../hoc/injectHoc';
import {editorRoute, prestoRoute} from '../../routePaths';
import {redirect} from '../../../utils/history';
import {replaceRouteParams} from '../../../utils/routeHelper';
import {SUPER_ADMIN_ROLE} from '../../../constants/userConstants';

import './contentInfoModal.scss';
import DownloadDropdown from './components/downloadDropdown/DownloadDropdown';
import {CONTENT_LIST_FOLDER, CONTENT_LIST_COLLECTION, CONTENT_LIST_SEARCH} from '../../../constants/contentConstants';
import {FREE_CONTENT_COLLECTION_ID} from '../../../constants/freeContentConstants';

/**
 * The ContentInfoModal component.
 */
class ContentInfoModal extends React.Component {
  /**
   * Refreshes content information
   */
  componentDidMount() {
    const {contentId, apiContentGetOneStore} = this.props;

    apiContentGetOneStore.refresh(contentId, true);
    apiContentGetOneStore.getPromise(contentId).then((content) => {
      this.checkForProcessing(content);
    });
  }

  /**
   * Attempt to refresh on update.
   *
   * @param {{}} prevProps
   */
  componentDidUpdate(prevProps) {
    const {contentId, apiContentGetOneStore, apiContentRenderPollStatusStore} = this.props;
    if (!apiContentRenderPollStatusStore || !apiContentGetOneStore) {
      return;
    }

    if (prevProps.contentId !== contentId) {
      apiContentGetOneStore.refresh(contentId);
    }

    apiContentGetOneStore.getPromise(contentId).then((content) => {
      this.checkForProcessing(content);
    });
  }

  /**
   * Triggered when the component is about to be removed from the page.
   */
  componentWillUnmount() {
    const {apiContentRenderPollStatusStore, contentId} = this.props;

    apiContentRenderPollStatusStore.cancelPollForContent(contentId);
  }

  /**
   * Checks to see if the content rendering is processing, and starts polling it if true.
   *
   * @param {{}} content
   */
  checkForProcessing = (content) => {
    const {apiContentRenderPollStatusStore} = this.props;

    // Make sure any active polling is disabled first.
    apiContentRenderPollStatusStore.cancelPollForContent(content.id);

    const isProcessing = apiContentRenderPollStatusStore.isContentProcessing(content);
    if (!isProcessing) {
      return;
    }

    const updated = moment(content.displayUpdatedAt);
    const limit = moment().subtract(2, 'hours');

    // Only poll if the file has been updated recently, otherwise we don't expect it to stop processing soon.
    if (!updated.isAfter(limit)) {
      return;
    }

    apiContentRenderPollStatusStore.pollThenUpdateContent(content.id);
  };

  /**
   * Redirects the user back to the dashboard.
   * Triggered after the content is deleted.
   */
  afterDelete = () => {
    const {onClose} = this.props;
    onClose();
  };

  /**
   * Redirects the user to the content info modal for the cloned content.
   * Triggered after the content is cloned.
   *
   * @param {number} newContentId
   * @param {number} newFolderId
   */
  afterClone = (newContentId, newFolderId) => {
    if (!newContentId) {
      return;
    }

    const {onRedirect} = this.props;

    onRedirect({contentId: newContentId, listId: newFolderId});
  };

  /**
   * Redirects the user to the content info modal for the moved content.
   * Triggered after the content is moved.
   *
   * @param {number} newFolderId
   */
  afterMove = (newFolderId) => {
    if (!newFolderId) {
      return;
    }

    const {contentId, onRedirect} = this.props;

    onRedirect({contentId, listId: newFolderId});
  };

  /**
   * Triggered when edit in presto button is clicked
   */
  onEditInPrestoClick = () => {
    const {contentId} = this.props;
    const newRoute = replaceRouteParams(prestoRoute, {contentId});

    redirect(newRoute);
  }

  /**
   * Triggered when edit in editor button is clicked
   */
  onEditInEditorClick = () => {
    const {contentId} = this.props;
    const newRoute = replaceRouteParams(editorRoute, {contentId});

    redirect(newRoute);
  }

  /**
   * Triggers when publish button is clicked.
   */
  onPublishClick = () => {
    const {onPublish} = this.props;
    onPublish();
  };

  /**
   * Triggers when un-publish button is clicked.
   */
  onUnPublishClick = () => {
    const {onUnPublish} = this.props;
    onUnPublish();
  };

  /**
   * Triggered when download button is clicked
   */
  onDownloadClick = () => {

  }

  /**
   * Renders the editing buttons as they should be shown.
   *
   * @param {boolean} shouldShowCreatorButton
   * @param {boolean} shouldShowEditInPrestoButton
   * @returns {{}}
   */
  renderEditingButtons = (shouldShowCreatorButton, shouldShowEditInPrestoButton) => {
    // If the user can design then only show the edit in template editor button, otherwise the presto button.
    if (shouldShowCreatorButton) {
      return (
        <button
          className="btn btn-light info-btn-responsive"
          type="button"
          onClick={this.onEditInEditorClick}
        >
          <FontAwesomeIcon
            className="pr-2"
            icon={faEdit}
          />
          Edit in Creator
        </button>
      );
    }

    if (shouldShowEditInPrestoButton) {
      return (
        <button
          className="btn btn-light info-btn-responsive"
          type="button"
          onClick={this.onEditInPrestoClick}
          data-cy="edit-in-presto-button"
        >
          <FontAwesomeIcon
            className="pr-2"
            icon={faEdit}
          />
          Edit in Presto
        </button>
      );
    }

    return <></>;
  };

  /**
   * Renders the publishing buttons if they should be shown.
   *
   * @param {boolean} shouldShowPublishButton
   * @param {boolean} shouldShowUnPublishButton
   * @returns {{}}
   */
  renderPublishingButtons = (shouldShowPublishButton, shouldShowUnPublishButton) => {
    if (!shouldShowPublishButton && !shouldShowUnPublishButton) {
      return null;
    }

    return (
      <>
        {shouldShowPublishButton && (
          <button
            className="btn btn-light info-btn-responsive"
            type="button"
            onClick={this.onPublishClick}
          >Publish</button>
        )}

        {shouldShowUnPublishButton && (
          <button
            className="btn btn-light info-btn-responsive"
            type="button"
            onClick={this.onUnPublishClick}
          >Un-publish</button>
        )}
      </>
    );
  };

  /**
   * Renders the admin bar if it should be visible.
   *
   * @param {{isGlobal: boolean}} content
   * @param {boolean} userCanDesign
   * @returns {?Object}
   */
  renderAdminBar = (content, userCanDesign) => {
    const {canUnPublish} = this.props;

    // Can publish to collections.
    const shouldShowPublishButton = Boolean(!content.isGlobal && userCanDesign);

    // Can unpublish from a collection.
    const shouldShowUnPublishButton = Boolean(canUnPublish && content.isGlobal && userCanDesign);

    if (!shouldShowUnPublishButton && !shouldShowPublishButton) {
      return null;
    }

    return (
      <div className="content-admin-bar d-flex align-items-center justify-content-end">
        {this.renderPublishingButtons(shouldShowPublishButton, shouldShowUnPublishButton)}
      </div>
    );
  };

  /**
   * Gets the list id and type from the params.
   *
   * @param {string=} defaultListType
   * @param {number=} defaultListId
   * @returns {{listId: number, listType: string}}
   */
  getListData = (defaultListType, defaultListId) => {
    const {routerStore} = this.props;

    const listId = routerStore.getParam('listId');

    let safeListId = !Number.isNaN(Number(listId))
      ? Number(listId)
      : listId;

    let listType = routerStore.getParam('listType');
    if (listType === 'undefined') {
      listType = undefined;
    }

    return {
      listId: safeListId || defaultListId,
      listType: listType || defaultListType,
    };
  };

  /**
   * Determine if the user will spend a credit for the content
   *
   * @param {{}} content
   * @returns {boolean}
   */
  useCreditsForDownload = (content) => {
    const {user} = this.props;

    if (!user || !content) {
      return false;
    }
    const costsZeroCredits = content.isFree && lodash.get(user, 'company.signManufacturer.includeFreeTemplates', false);

    return !costsZeroCredits;
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {contentId, isOpen, apiContentGetOneStore, onClose, user, contentListType} = this.props;

    const {listId, listType} = this.getListData();

    const userCanDesign = Boolean(user && user.canDesign);

    // Is owner
    const shouldShowMoveButton = false;

    return apiContentGetOneStore.case(contentId, {
      pre: () => null,
      pending: () => null,
      // eslint-disable-next-line complexity
      fulfilled: (content) => {
        const hasUser = Boolean(user);
        const hasPlan = hasUser && Boolean(user.plan);
        const superUser = hasUser && (user.role === SUPER_ADMIN_ROLE);
        const isBasicUser = hasPlan && user.plan.name === 'Basic';
        const isDraft = content.isDraft;
        const isUserMade = content.isUserMade;

        const shouldShowCreatorButton = userCanDesign || isUserMade;
        const shouldShowEditInPrestoButton = listId !== FREE_CONTENT_COLLECTION_ID;

        // (!in progress && is owner)
        const shouldShowDownloadButton = (
          contentListType === CONTENT_LIST_COLLECTION
          || contentListType === CONTENT_LIST_SEARCH
        )
          && hasUser
          && (superUser || (!isBasicUser || content.isFree));

        const shouldShowDownloadCreditMessage = this.useCreditsForDownload(content); // design is not free || user is not Next customer

        const shouldShowDownloadDropdown = contentListType === CONTENT_LIST_FOLDER
          && hasUser
          && (superUser || (!isBasicUser || content.isFree))
          && !isDraft;

        // Is owner
        let shouldShowDropdownActions = !content.isGlobal;
        if (content.isGlobal && user && user.canDesign) {
          shouldShowDropdownActions = true;
        }

        return (
          <Modal className="content-info-modal" isOpen={isOpen} toggle={onClose} centered>
            <ContentPreview content={content} />

            <ModalBody>
              <div className="modal-body-content">
                <ContentBadges content={content} />
                <div className="row align-items-center">
                  <div className="col-sm-12 col-md-5">
                    <ContentName content={content} user={user} folderId={listId} folderType={listType} />

                    {(shouldShowDropdownActions) && (
                      <DropdownActions
                        canBeDeleted={true}
                        content={content}
                        folderId={content.contentFolderId}
                        isGlobal={content.isGlobal}
                        onMove={this.afterMove}
                        onClone={this.afterClone}
                        onDelete={this.afterDelete}
                        user={user}
                      />
                    )}
                  </div>

                  <div className="col-sm-12 col-md-7 text-md-right">
                    {this.renderEditingButtons(shouldShowCreatorButton, shouldShowEditInPrestoButton)}

                    {

                      // download button. this should only be available on the collection list items
                      (shouldShowDownloadButton) && (
                        <DownloadButton
                          content={content}
                          showCreditMessage={shouldShowDownloadCreditMessage}
                        />
                      )
                    }

                    {

                      // download dropdown. this should only be available on the user's folder items
                      (shouldShowDownloadDropdown) && (
                        <DownloadDropdown
                          content={content}
                        />
                      )
                    }
                  </div>
                </div>

                {(shouldShowMoveButton) && (
                  <button
                    className="btn btn-light"
                    type="button"
                    onClick={this.onDownloadClick}
                  >
                    Move
                  </button>
                )}
              </div>

              {this.renderAdminBar(content, userCanDesign)}
            </ModalBody>
          </Modal>
        );
      },
      rejected: () => null,
    });
  }
}

ContentInfoModal.propTypes = {
  contentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPublish: PropTypes.func.isRequired,
  onRedirect: PropTypes.func.isRequired,
  onUnPublish: PropTypes.func.isRequired,

  apiContentGetOneStore: MobxPropTypes.observableObject,
  apiContentRenderPollStatusStore: MobxPropTypes.observableObject,
  canUnPublish: PropTypes.bool,
  contentListType: PropTypes.string,
  routerStore: MobxPropTypes.observableObject,
  user: PropTypes.object,
};

ContentInfoModal.wrappedComponent = {};
ContentInfoModal.wrappedComponent.propTypes = {
  apiContentGetOneStore: MobxPropTypes.observableObject.isRequired,
  apiContentRenderPollStatusStore: MobxPropTypes.observableObject.isRequired,
  routerStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(ContentInfoModal)(
  observer(ContentInfoModal)
);
