import React, {Component} from 'react';
import {computed, observable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';

import inject from '../../../../hoc/injectHoc';
import {centsToDollars} from '../../../../../utils/mathHelper';
import LoadingIcon from '../../../../common/loadingIcon/LoadingIcon';
import {ALA_CARTE_CREDITS} from '../../../../../constants/pricingConstants';

import './orderDetails.scss';

/**
 * The AlaCarteOrderDetails component.
 */
class AlaCarteOrderDetails extends Component {
  /**
   * The validation errors for the form.
   *
   * @type {Map}
   */
  @observable validationErrors = new Map();

  /**
   * Calculate download credit purchase amount
   * @returns {number}
   */
  downloadPriceCents = () => {
    const {alaCarteCredits} = this.props;
    return (alaCarteCredits.download * ALA_CARTE_CREDITS.download.pricePerCredit);
  }

  /**
   * Calculate request credit purchase amount
   * @returns {number}
   */
  requestPriceCents = () => {
    const {alaCarteCredits} = this.props;
    return (alaCarteCredits.request * ALA_CARTE_CREDITS.request.pricePerCredit);
  }

  /**
   * Calculate and format total purchase amount
   */
  @computed get totalPurchases() {
    const total = this.downloadPriceCents() + this.requestPriceCents();

    return centsToDollars(total);
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      alaCarteCredits,
      buttonText,
      isLoading,
      onSubmit,
    } = this.props;

    if (!alaCarteCredits && typeof this.totalPurchases === 'undefined') {
      return <div>Invalid Credit Purchase</div>;
    }

    return (
      <div
        className="order-details col-12 col-md-3 ml-md-5"
        data-cy="order-details-page"
      >
        <div className="order-details-top">
          <div className="font-weight-bold text-uppercase">Order details</div>
          <div className="d-flex justify-content-between">
            <div>
              <div className="selected-plan">Download Credit{alaCarteCredits.download > 1 ? 's' : ''} x {alaCarteCredits.download}</div>
            </div>
            <div>
              {centsToDollars(this.downloadPriceCents())}
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <div className="selected-plan">Custom Credit{alaCarteCredits.request > 1 ? 's' : ''} x {alaCarteCredits.request}</div>
            </div>
            <div>
              {centsToDollars(this.requestPriceCents())}
            </div>
          </div>
        </div>
        <div className="order-details-middle">
          <div className="d-flex justify-content-between">
            <div>Total Purchases</div>
            <div>{this.totalPurchases}</div>
          </div>
          <div className="d-flex justify-content-between">
            <div>Estimated Tax</div>
            <div>$ 0</div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <div className="font-weight-bold">Total Today</div>
            <div className="price">{this.totalPurchases}</div>
          </div>
        </div>
        <div className="order-details-bottom">
          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-primary text-light"
              onClick={onSubmit}
              disabled={isLoading}
              data-cy="confirm-purchase-button"
            >
              {isLoading ? <span className="d-flex"><LoadingIcon size="em" /> {buttonText}</span> : buttonText}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

AlaCarteOrderDetails.propTypes = {
  alaCarteCredits: PropTypes.object.isRequired,
  buttonText: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.object,
};

AlaCarteOrderDetails.wrappedComponent = {};
AlaCarteOrderDetails.wrappedComponent.propTypes = {
};

export default inject(AlaCarteOrderDetails)(
  observer(AlaCarteOrderDetails)
);
