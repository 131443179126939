import {action, computed, observable, runInAction} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Modal, ModalBody, ModalFooter} from 'reactstrap';

import FolderSidebarList from '../../pages/dashboard/components/folderSidebarList/FolderSidebarList';
import inject from '../../hoc/injectHoc';

import './selectFolderModal.scss';

/**
 * The default modal title.
 * @const {string}
 */
const DEFAULT_TITLE = 'Save Content To';

/**
 * The SelectFolderModal component.
 */
export class SelectFolderModal extends React.Component {
  /**
   * The selected folder id.
   *
   * @type {?number}
   */
  @observable folderId = null;

  /**
   * The given content name.
   *
   * @type {string}
   */
  @observable contentName = '';

  /**
   * Triggered when the component just mounted onto the page.
   */
  @action componentDidMount() {
    if (this.props.isOpen) {
      this.initializeModal();
    }

    if (this.props.defaultName) {
      this.contentName = this.props.defaultName;
    }
  }

  /**
   * Triggered when the component has just updated.
   *
   * @param {{isOpen: boolean}} prevProps
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.initializeModal();
    }
  }

  /**
   * Whether or not to disable the 'Ok' button
   */
  @computed get isDisabled() {
    const {askForName} = this.props;

    return (askForName && !this.contentName);
  }

  /**
   * Initializes the modal.
   */
  @action initializeModal = () => {
    // Do something.
  };

  /**
   * Tears down the modal.
   */
  tearDownModal = () => {
    // Do something.
  };

  /**
   * Triggered when the modal is closed without a finishing.
   */
  onCancelModal = () => {
    const {onComplete} = this.props;

    onComplete({
      folderId: null,
      contentName: null,
    });

    this.tearDownModal();
  };

  /**
   * Triggered when the modal is closed after choosing a folder.
   */
  @action onCompleteModal = async () => {
    const {onComplete, apiContentFolderGetAllStore} = this.props;

    // if no folder is chosen, try and use first available folder
    if (!this.folderId) {
      const folders = await apiContentFolderGetAllStore.getPromise();

      // if a folder is available, use it
      if (folders.length !== 0) {
        runInAction('set default folder id', () => {
          this.folderId = folders[0].id;
        });
      }
    }

    onComplete({
      folderId: this.folderId,
      contentName: this.contentName,
    });

    this.tearDownModal();
  };

  /**
   * Called when a folder is selected.
   *
   * @param {{id: number}} selectedFolder
   */
  @action onFolderSelected = (selectedFolder) => {
    this.folderId = selectedFolder.id;
  };

  /**
   * Triggered when the name input changes.
   *
   * @param {{target: {value: string}}} changeEvent
   */
  @action onNameChange = (changeEvent) => {
    this.contentName = String(changeEvent.target.value);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      askForName,
      isOpen,
      title
    } = this.props;

    if (!isOpen) {
      return null;
    }

    return (
      <Modal isOpen={isOpen} toggle={this.onCancelModal} className="select-folder-modal" centered>
        <ModalBody>
          {(askForName) && (
            <div className="form-group">
              <label htmlFor="content-name" className="site-label">Name:</label>
              <div className="site-text-input">
                <input
                  id="content-name"
                  type="text"
                  value={this.contentName}
                  placeholder="New Content"
                  name="contentName"
                  onChange={this.onNameChange}
                />
              </div>
            </div>
          )}

          <FolderSidebarList
            hideIfEmpty={true}
            onSelect={this.onFolderSelected}
            selected={this.folderId}
            title={title}
          />
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-default"
            onClick={this.onCancelModal}
          >Cancel</button>
          {' '}
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.onCompleteModal}
            disabled={this.isDisabled}
            data-cy="select-folder-modal-save-button"
          >Save</button>
        </ModalFooter>
      </Modal>
    );
  }
}

SelectFolderModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,

  apiContentFolderGetAllStore: MobxPropTypes.observableObject,
  askForName: PropTypes.bool,
  defaultName: PropTypes.string,
  title: PropTypes.string,
};

SelectFolderModal.defaultProps = {
  askForName: true,
  defaultName: '',
  title: DEFAULT_TITLE,
};

SelectFolderModal.wrappedComponent = {};
SelectFolderModal.wrappedComponent.propTypes = {
  apiContentFolderGetAllStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(SelectFolderModal)(
  observer(SelectFolderModal)
);
