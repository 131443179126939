import assignDeep from 'assign-deep';
import lodash from 'lodash';

import {getPixelFromPercentage} from './entityHelper';
import {ALIGNMENTS} from '../components/common/positionComponent';
import * as textComponent from '../components/type/textComponent';
import {EASINGS, getEasingFromName, isEasingValid} from '../ecs/easingHelper';
import {SPLIT_ON_LETTERS, SPLIT_ON_WORDS} from '../../utils/markdown/markdownWordParser';

/**
 * The name of the parameter that will be replaced with the off screen position.
 * @const {string}
 */
export const PARSE_OFF = 'off';

/**
 * The name of the parameter that will be replaced with the entity position.
 * @const {string}
 */
export const PARSE_POSITION = 'position';

/**
 * Converts milliseconds to seconds when multiplied or seconds to milliseconds when divided.
 * @const {number}
 */
const MS_TO_SECONDS = 1000;

/**
 * All flow directions by name
 */
export const FLOWS = {
  in: 'in',
  out: 'out',
  middle: 'middle'
};

/**
 * Throws an error if the flow direction is an invalid direction.
 *
 * @param {string} flowDirection
 */
function validateFlow(flowDirection) {
  const isValid = Boolean(FLOWS[flowDirection]);

  if (!isValid) {
    const allFlowDirections = lodash.values(FLOWS).join(', ');
    throw new Error(`The property 'flow' must be one of: ${allFlowDirections}.`);
  }
}

/**
 * Find the longest transitions for each flow type
 *
 * @param {[]} transitions
 * @returns {{in: {}, out: {}, middle: {}, n/a: {}}}
 */
export function findLongestTransitionsByFlow(transitions) {
  /**
   * A reducer function that finds the longest in and out transitions
   *
   * @param {*} previous
   * @param {*} current
   * @returns {{}}
   */
  function reducer(previous, current) {
    const flowDirection = lodash.get(current, 'details.flow', 'n/a');
    const {start, end} = lodash.get(current, 'time', {start: 0, end: 0});
    const transitionLength = end - start;
    const existingTransitionLength = lodash.get(previous[flowDirection], 'transitionLength', 0);

    if (transitionLength > existingTransitionLength) {
      previous[flowDirection] = {
        ...current,
        transitionLength,
      };
    }

    return previous;
  }

  return transitions.reduce(reducer, {});
}

/**
 * Calculate the transition times based on the flow direction
 *
 * @param {String} absoluteTransitionTime
 * @param {{}} entityTime
 * @param {{}} transitionDetail
 *
 * @returns {String|number}
 */
export function calculateNewTransitionTime(absoluteTransitionTime, entityTime, transitionDetail) {
  const isRelativeTime = typeof transitionDetail.time.end === 'string';
  const [relativePrefix] = isRelativeTime ? transitionDetail.time.end.split('.') : null;
  const relativeTransitionTime = {
    'start': Math.round(absoluteTransitionTime - entityTime.start),
    'end' : Math.round(absoluteTransitionTime - entityTime.end),
    'absolute': Math.round(absoluteTransitionTime)
  };

  if (isRelativeTime && relativePrefix) {
    return `${relativePrefix}.${relativeTransitionTime[relativePrefix]}`;
  }

  return relativeTransitionTime.absolute;
}

/**
 * Loads the preset details from the preset source.
 *
 * To override the preset details, the presetSource should look like:
 * {
 *   name: 'PRESET_NAME',
 *   details: {
 *     [PROPERTY_NAME]: (Array|{
 *       easing: string=,
 *       time: {
 *         start: (string|number)=,
 *         end: (string|number)=,
 *       }=,
 *       values: (string|number|Array|{})=
 *     })=,
 *   },
 * }
 *
 * @param {{}} presetSource
 * @param {Object.<string, {}>} allPresets
 * @returns {?Object.<string, Array.<{}>>}
 */
export function loadPresetDetailsFromSource(presetSource, allPresets) {
  const presetIsString = lodash.isString(presetSource);
  const presetName = presetIsString ? presetSource : presetSource.name;

  const presetData = allPresets[presetName];
  if (!presetData) {
    // This transition is invalid, bail out.
    return null;
  }

  // We want them to be able to override preset details, but only in the preset value is an object.
  let expandedPresetDetails = presetData.details;
  if (!presetIsString && presetSource.details) {
    expandedPresetDetails = assignDeep({}, presetData.details || {}, presetSource.details);
  }

  if (!expandedPresetDetails || !lodash.size(expandedPresetDetails)) {
    return null;
  }

  return expandedPresetDetails;
}

/**
 * Parses the details for the transition or preset (including position and time calculations).
 *
 * @param {{}} details
 * @param {{}} transitionSource
 * @param {ObservableMap} entity
 * @param {GameStore} game
 * @returns {?Array.<{}>}
 */
export function parseTransitionDetails(details, transitionSource, entity, game) {
  if (!details || !lodash.size(details)) {
    return null;
  }

  const reducedDetails = [];
  lodash.forEach(details, (propertyDetails, propertyName) => {
    const propertyDetailSet = (Array.isArray(propertyDetails) ? propertyDetails : [propertyDetails]);
    lodash.forEach(propertyDetailSet, (propertyDetail) => {
      reducedDetails.push({
        ...propertyDetail,
        propertyName,
      });
    });
  });

  const safeDetails = [];
  lodash.forEach(reducedDetails, (propertyDetails) => {
    const {propertyName} = propertyDetails;

    const parsedProperty = parseProperty(propertyDetails, propertyName, transitionSource, entity, game);
    if (!parsedProperty) {
      return;
    }

    safeDetails.push(parsedProperty);
  });

  return safeDetails;
}

/**
 * Generates the start details and end details objects for each item.
 *
 * @param {Array.<{}>} details
 * @returns {{details: Array.<{}>, time: {start: number, end: number}}}
 */
export function generateStartAndEndDetails(details) {
  const extractedDetails = [];

  // The lowest start time value and the highest end time value.
  const timeBoundaries = {
    start: 9999999999999999,
    end: 0,
  };

  lodash.forEach(details, (propertyDetails) => {
    if (!propertyDetails) {
      return;
    }

    const {propertyName} = propertyDetails;

    const spreadProperties = getStartAndEndValues(
      propertyDetails.values,
      propertyName,
      propertyDetails
    );

    lodash.forEach(spreadProperties, (spreadPropertyDetails) => {
      if (spreadPropertyDetails.time && spreadPropertyDetails.time.start < timeBoundaries.start) {
        timeBoundaries.start = spreadPropertyDetails.time.start;
      }
      if (spreadPropertyDetails.time && spreadPropertyDetails.time.end > timeBoundaries.end) {
        timeBoundaries.end = spreadPropertyDetails.time.end;
      }

      extractedDetails.push(spreadPropertyDetails);
    });
  });

  return {
    details: extractedDetails,
    time: timeBoundaries
  };
}

/**
 * Parses an individual property details object.
 *
 * @param {{}} propertyDetails
 * @param {string} propertyName
 * @param {{startTime: number, endTime: number, easing: string}} transitionSource
 * @param {{}} entity
 * @param {{}} game
 * @returns {?{}}
 */
function parseProperty(propertyDetails, propertyName, transitionSource, entity, game) {
  if (!propertyDetails || !propertyDetails.values) {
    // This property details is invalid.
    return null;
  }

  const safePropertyDetails = lodash.cloneDeep(propertyDetails);
  lodash.unset(safePropertyDetails, 'parse');

  // The transitionSource can set a default start and end time that will be used when no time is available.
  safePropertyDetails.time = lodash.defaultsDeep(safePropertyDetails.time, transitionSource.defaults.time, {
    start: 'start.0',
    end: 'start.1000',
  });

  // Turn the easing name into the easing function.
  if (!propertyDetails.easing && transitionSource.defaults.easing) {
    // The transitionSource can set a default easing that will be used when no local easing is available.
    safePropertyDetails.easing = getEasingFromName(transitionSource.defaults.easing);
  } else {
    safePropertyDetails.easing = getEasingFromName(propertyDetails.easing);
  }

  // Parse the time values first.
  const safeTime = lodash.pick(safePropertyDetails.time, ['start', 'end']);
  lodash.forEach(safeTime, (traitValue, traitName) => {
    safeTime[traitName] = parseTime(traitValue, entity);
  });

  safePropertyDetails.time = safeTime;
  safePropertyDetails.duration = (safeTime.end - safeTime.start) / MS_TO_SECONDS;

  const shouldParse = (propertyDetails.parse !== false);
  if (shouldParse) {
    safePropertyDetails.values = parseAllPropertyValues(safePropertyDetails.values, entity, game);
  }

  return safePropertyDetails;
}

/**
 * Parses all the property values.
 *
 * @param {number|string|Array|{}} propertyValues
 * @param {{}} entity
 * @param {{}} game
 * @returns {{}}
 */
function parseAllPropertyValues(propertyValues, entity, game) {
  const valuesAreArray = Array.isArray(propertyValues);
  const valueIsSingular = lodash.isString(propertyValues) || lodash.isNumber(propertyValues);

  let parsedValues = {};
  if (valuesAreArray) {
    parsedValues = [];
  } else if (valueIsSingular) {
    parsedValues = null;
  }

  if (valueIsSingular) {
    parsedValues = parsePropertyValue(propertyValues, entity, game);
  } else {
    lodash.forEach(propertyValues, (traitValue, traitName) => {
      const parsed = parsePropertyValue(traitValue, entity, game);
      if (valuesAreArray) {
        parsedValues.push(parsed);
      } else {
        parsedValues[traitName] = parsed;
      }
    });
  }

  return parsedValues;
}

/**
 * Gets the start and end values for the given detail values.
 *
 * @param {{}|Array} detailValues
 * @param {string} propertyName
 * @param {{repeat: number, yoyo: boolean, delay: number}} propertyData
 * @returns {Array.<{startDetails: null, endDetails: null}>}
 */
function getStartAndEndValues(detailValues, propertyName, propertyData) {
  const endData = lodash.pick(propertyData || {}, ['delay', 'repeat', 'yoyo', 'immediateRender']);

  const output = {
    startDetails: null,
    endDetails: {
      ...endData,
      delay: endData.delay || 0,
      ease: propertyData.easing || null,
    },
  };

  if (!Array.isArray(detailValues)) {
    lodash.set(output.endDetails, propertyName, detailValues);

    return [{
      ...propertyData,
      ...output,
    }];
  }

  output.startDetails = {};

  // An array length of 2 indicates a starting value and an end value.
  if (detailValues.length === 2) {
    const [startValue, endValue] = detailValues;
    lodash.set(output.startDetails, propertyName, startValue);
    lodash.set(output.endDetails, propertyName, endValue);

    return [{
      ...propertyData,
      ...output,
    }];
  }

  // A larger array indicates a start, 1 or more in-between values, and an end value.
  const outputs = [];
  const sectionDuration = propertyData.duration / (detailValues.length - 1);

  lodash.forEach(detailValues, (stepValue, stepIndex) => {
    if (!stepIndex) {
      lodash.set(output.startDetails, propertyName, stepValue);
      return;
    }

    const stepDetails = lodash.cloneDeep(output.endDetails);
    stepDetails.delay += (sectionDuration * (stepIndex - 1));
    lodash.set(stepDetails, propertyName, stepValue);

    if (stepIndex === 1) {
      outputs.push({
        ...propertyData,
        duration: sectionDuration,
        familyDuration: propertyData.duration,
        values: [detailValues[0], stepValue],
        startDetails: lodash.cloneDeep(output.startDetails),
        endDetails: stepDetails
      });
      return;
    }

    // Each transition after the first will tween only to the final value instead of from the initial to the final.
    // In the TweenMax sense, it will use `.to()` instead of `.fromTo()`.
    outputs.push({
      ...propertyData,
      duration: sectionDuration,
      familyDuration: propertyData.duration,
      values: stepValue,
      startDetails: null,
      endDetails: stepDetails
    });
  });

  return outputs;
}

/**
 * Parses an individual property value.
 *
 * @param {string|number|Array|{}} traitValue
 * @param {{}} entity
 * @param {{}} game
 * @returns {string|number|Array|{}}
 */
function parsePropertyValue(traitValue, entity, game) {
  if (lodash.isString(traitValue) || lodash.isNumber(traitValue)) {
    return parseTrait(traitValue, entity, game);
  }

  if (Array.isArray(traitValue)) {
    const [from, to] = traitValue;

    return [
      parseTrait(from, entity, game),
      parseTrait(to, entity, game)
    ];
  }

  const clonedValue = lodash.cloneDeep(traitValue);

  // The trait value is probably an object at this point, so recursively parse its values.
  lodash.forEach(clonedValue, (subTraitValue, subTraitName) => {
    // Recursion!
    clonedValue[subTraitName] = parsePropertyValue(subTraitValue, entity, game);
  });
  return clonedValue;
}

/**
 * Parses trait data into numbers.
 *
 * @param {string|number} traitValue
 * @param {ObservableMap} entity
 * @param {GameStore} game
 * @returns {number}
 */
function parseTrait(traitValue, entity, game) {
  const safeTraitValue = String(traitValue);

  if (lodash.startsWith(safeTraitValue, `${PARSE_OFF}.`)) {
    return parseOff(safeTraitValue, entity, game);
  }

  if (lodash.startsWith(safeTraitValue, `${PARSE_POSITION}.`)) {
    return parsePosition(safeTraitValue, entity, game);
  }

  return traitValue;
}

/**
 * Parses the off parameter.
 *
 * @param {string} value
 * @param {ObservableMap} entity
 * @param {GameStore} game
 * @returns {number}
 */
function parseOff(value, entity, game) {
  const direction = value.substr(`${PARSE_OFF}.`.length);

  const entityPosition = entity.get('position');
  const alignment = entityPosition.alignment;
  const resolution = game.resolution;

  let entitySize = entity.get('size');
  if (entity.has('image')) {
    entitySize = entity.get('image');
  }

  const buffer = 10;

  if (direction === 'top') {
    const safeHeight = getPixelFromPercentage(resolution.height, entitySize.default.height, true);
    const desiredY = 0 - safeHeight - buffer;
    return parseYAlignment(desiredY, alignment, resolution, entitySize);
  } else if (direction === 'right') {
    const desiredX = game.resolution.width + buffer;
    return parseXAlignment(desiredX, alignment, resolution, entitySize);
  } else if (direction === 'bottom') {
    const desiredY = game.resolution.height + buffer;
    return parseYAlignment(desiredY, alignment, resolution, entitySize);
  } else if (direction === 'left') {
    const safeWidth = getPixelFromPercentage(resolution.width, entitySize.default.width, true);
    const desiredX = 0 - safeWidth - buffer;
    return parseXAlignment(desiredX, alignment, resolution, entitySize);
  }
  return 0;
}

/**
 * Parses the position parameter.
 *
 * @param {string} value
 * @param {ObservableMap} entity
 * @param {GameStore} game
 * @returns {number}
 */
function parsePosition(value, entity, game) {
  const positionTrait = value.substr(`${PARSE_POSITION}.`.length);

  const resolution = game.resolution;

  let entityPosition = entity.get('position');
  if (entity.has('image')) {
    entityPosition = entity.get('image');
  }

  if (positionTrait === 'top') {
    return getPixelFromPercentage(resolution.height, entityPosition.default.y, true);
  } else if (positionTrait === 'right') {
    return getPixelFromPercentage(resolution.width, entityPosition.default.x, true);
  } else if (positionTrait === 'bottom') {
    return getPixelFromPercentage(resolution.height, entityPosition.default.y, true);
  } else if (positionTrait === 'left') {
    return getPixelFromPercentage(resolution.width, entityPosition.default.x, true);
  }
  return 0;
}

/**
 * Parses the time parameter.
 *
 * @param {string} value
 * @param {ObservableMap} entity
 * @returns {number}
 */
function parseTime(value, entity) {
  const [timePosition, secondsDelta] = String(value).split('.');

  const entityTime = entity.get('time');

  if (timePosition === 'start') {
    return entityTime.start + Number(secondsDelta || 0);
  } else if (timePosition === 'end') {
    return entityTime.end + Number(secondsDelta || 0);
  }
  return value || 0;
}

/**
 * Parses the y-axis reverse alignment.
 *
 * @param {number} desiredY
 * @param {string} alignment
 * @param {{height: number}} resolution
 * @param {{height: number}} size
 * @returns {number}
 */
function parseYAlignment(desiredY, alignment, resolution, size) {
  if (alignment.y === ALIGNMENTS.y.bottom) {
    return (resolution.height - size.height - desiredY);
  } else if (alignment.y === ALIGNMENTS.y.middle) {
    return desiredY - (resolution.height / 2) + (size.height / 2);
  }
  return desiredY;
}

/**
 * Parses the x-axis reverse alignment.
 *
 * @param {number} desiredX
 * @param {string} alignment
 * @param {{width: number}} resolution
 * @param {{width: number}} size
 * @returns {number}
 */
function parseXAlignment(desiredX, alignment, resolution, size) {
  if (alignment.x === ALIGNMENTS.x.right) {
    return (resolution.width - size.width - desiredX);
  } else if (alignment.x === ALIGNMENTS.x.center) {
    return desiredX - (resolution.width / 2) + (size.width / 2);
  }
  return desiredX;
}

/**
 * Checks transition details to make sure they are valid.
 *
 * @param {{}} details
 * @returns {boolean}
 */
export function validateTransitionValues(details) {
  if (!details || lodash.isString(details) || lodash.isNumber(details) || Array.isArray(details)) {
    throw new Error('The template must start with a { and end with a }.');
  }

  if (!lodash.size(details)) {
    throw new Error('The template must contain at least one property within the {}s.');
  }

  lodash.forEach(details, (propertyData, propertyName) => {
    if (!Array.isArray(propertyData)) {
      if (propertyName === 'flow') {
        validateFlow(propertyData);
        return;
      }

      validatePropertyData(propertyData, propertyName);
      return;
    }

    propertyData.forEach((subPropertyData, index) => {
      validatePropertyData(subPropertyData, `${propertyName}[${index}]`);
    });
  });

  return true;
}

/**
 * Validates an individual transition property's data (values, time, easing, etc).
 *
 * @param {{}} propertyData
 * @param {string} propertyName
 */
function validatePropertyData(propertyData, propertyName) {
  if (propertyData.values === undefined) {
    throw new Error(`The property '${propertyName}' must contain a 'values' property.`);
  }

  if (!propertyData.time) {
    throw new Error(`The property '${propertyName}' must contain a 'time' property.`);
  }

  if (propertyData.time.start === undefined) {
    throw new Error(`The property '${propertyName}.time' must contain a 'start' property.`);
  }
  if (propertyData.time.end === undefined) {
    throw new Error(`The property '${propertyName}.time' must contain a 'end' property.`);
  }

  const timeErrorMessage = 'must be an integer (milliseconds) or begin with \'start.\' or \'end.\'';
  const parseMatchOffsetIndex = 2;
  if (String(propertyData.time.start).match(/^[a-zA-Z]+\./)) {
    const parseMatch = String(propertyData.time.start).match(/^(start|end)\.(.*)/);
    const offset = parseMatch[parseMatchOffsetIndex];
    if (!parseMatch) {
      throw new Error(`The property '${propertyName}.time.start' ${timeErrorMessage}.`);
    } else if (String(parseInt(offset, 10)) !== String(offset)) {
      throw new Error(`The property '${propertyName}.time.start' must end with an integer (milliseconds).`);
    }
  } else if (String(parseInt(propertyData.time.start, 10)) !== String(propertyData.time.start)) {
    throw new Error(`The property '${propertyName}.time.start' ${timeErrorMessage}.`);
  }

  if (String(propertyData.time.end).match(/^[a-zA-Z]+\./)) {
    const parseMatch = String(propertyData.time.end).match(/^(start|end)\.(.*)/);
    const offset = parseMatch[parseMatchOffsetIndex];
    if (!parseMatch) {
      throw new Error(`The property '${propertyName}.time.end' ${timeErrorMessage}.`);
    } else if (String(parseInt(offset, 10)) !== String(offset)) {
      throw new Error(`The property '${propertyName}.time.end' must end with an integer (milliseconds).`);
    }
  } else if (String(parseInt(propertyData.time.end, 10)) !== String(propertyData.time.end)) {
    throw new Error(`The property '${propertyName}.time.end' ${timeErrorMessage}.`);
  }

  if (propertyData.easing && !isEasingValid(propertyData.easing)) {
    const allEasingNames = lodash.values(EASINGS).join(', ');
    throw new Error(`The property '${propertyName}.easing' must be one of: ${allEasingNames}.`);
  }
}

/**
 * Initializes entity transition data.
 *
 * @param {{}} entity
 * @param {{}} details
 * @returns {Array.<{}>}
 */
export function initializeEntityTransition(entity, details) {
  const letter = 'letter.';
  const word = 'word.';
  const line = 'line.';

  const groups = {
    letter: 0,
    word: 0,
    line: 0,
  };

  lodash.forEach(details, (propertyDetails) => {
    const {propertyName} = propertyDetails;
    if (lodash.startsWith(propertyName, letter)) {
      groups.letter += 1;
    } else if (lodash.startsWith(propertyName, word)) {
      groups.word += 1;
    } else if (lodash.startsWith(propertyName, line)) {
      groups.line += 1;
    }
  });

  const clearedDetails = lodash.filter(details, (propertyDetails) => {
    const {propertyName} = propertyDetails;

    if (groups.letter) {
      return (!lodash.startsWith(propertyName, word) && !lodash.startsWith(propertyName, line));
    } else if (groups.word) {
      return (!lodash.startsWith(propertyName, letter) && !lodash.startsWith(propertyName, line));
    } else if (groups.line) {
      return (!lodash.startsWith(propertyName, word) && !lodash.startsWith(propertyName, letter));
    }
    return true;
  });

  let newDetails = lodash.cloneDeep(clearedDetails);

  if (groups.letter) {
    const lines = textComponent.setupTransition(entity, SPLIT_ON_LETTERS);
    newDetails = textComponent.parseLinesIntoWordTransitions(clearedDetails, lines, letter);
  } else if (groups.word) {
    const lines = textComponent.setupTransition(entity, SPLIT_ON_WORDS);
    newDetails = textComponent.parseLinesIntoWordTransitions(clearedDetails, lines, word);
  } else if (groups.line) {
    const lines = textComponent.setupTransition(entity, SPLIT_ON_WORDS);
    newDetails = textComponent.parseLinesIntoWordTransitions(clearedDetails, lines, line);
  }

  return newDetails;
}
