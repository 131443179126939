import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';
import lodash from 'lodash';

import EntityTimelineItem from './components/EntityTimelineItem/EntityTimelineItem';
import {actionInteractionComponent} from '../../../display/components/action/actionInteractionComponent';

import './entityTimeline.scss';

/**
 * The EditorEntityList component.
 *
 * @constructor
 */
export class EntityTimeline extends React.Component {
  /**
   * @constructor
   * @param {{}} props
   * @param {{}} componentContext
   */
  constructor(props, componentContext) {
    super(props, componentContext);
  }

  /**
   * Triggered when the entity is mounted to the page.
   */
  componentDidMount() {
    const {game} = this.props;

    if (!game) {
      return;
    }

    const lastEntity = lodash.findLast(game.entities, this.isEntityEditable);
    if (lastEntity) {
      this.activateEntity(lastEntity);
    }
  }

  /**
   * Returns true if the entity is editable.
   *
   * @param {ObservableMap} entity
   * @returns {boolean}
   */
   isEntityEditable = (entity) => {
     return entity.has('position');
   };

   /**
   * Activates the first entity.
   *
   * @param {ObservableMap} entity
   */
   activateEntity = (entity) => {
     const {game, timer} = this.props;

     const actionParams = {
       entityId: entity.get('id'),
       skipHistory: true,
     };

     game.addAction(actionParams, actionInteractionComponent(true, false, false));

     const activeTime = entity.get('time').active;
     timer.setTime(activeTime);
   };

   /**
   * Renders the component.
   *
   * @returns {{}}
   */
   render() {
     const {game, timer} = this.props;

     const entities = (game && game.entities) ? game.entities : [];

     const filteredEntities = entities.reverse().filter((entity) => {
       return (entity && entity.has('element') && entity.has('visible'));
     });

     return (
       <div id="entity-timeline" className="entity-timeline">
         <div className="list-group">
           {filteredEntities.map((entity, index) => {
             const isLast = (index === (filteredEntities.length - 1));
             return (
               <EntityTimelineItem
                 key={entity.get('id')}
                 entity={entity}
                 game={game}
                 timer={timer}
                 entityOrder={index + 1}
                 disableOrdering={false}
                 isLast={isLast}
               />
             );
           })}
         </div>
       </div>
     );
   }
}

EntityTimeline.propTypes = {
  game: MobxPropTypes.observableObject.isRequired,
  timer: MobxPropTypes.observableObject.isRequired,
};

export default observer(EntityTimeline);
