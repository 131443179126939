import lodash from 'lodash';
import {action} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';
import ReactSelect from 'react-select';

import inject from '../../hoc/injectHoc';
import {changeDisplayEditorStoreDimensions} from '../../../utils/displayEditorStoreHelper';
import {decimalToFraction} from '../../../utils/mathHelper';
import {SUPER_ADMIN_ROLE} from '../../../constants/userConstants';

import './editorDimensions.scss';

/**
 * The EditorDimensions component.
 *
 * @property {{
 *   displayEditorStore: DisplayEditorStore,
 * }} props
 */
export class EditorDimensions extends React.Component {
  /**
   * Triggered when the component is first mounted to the page.
   */
  componentDidMount() {
    const {apiCompanySignGetAllStore} = this.props;

    apiCompanySignGetAllStore.refresh();
  }

  /**
   * Updates the aspect ratio of the system.
   *
   * @param {{}} changeData
   */
  @action onChangeDimensions = (changeData) => {
    const newAspectRatio = changeData.value;
    const {displayEditorStore} = this.props;

    changeDisplayEditorStoreDimensions(displayEditorStore, newAspectRatio);
  };

  /**
   * Use the aspect ratio if the user doesn't have any signs
   *
   * @returns {Array.<{aspectRatios: number, exists: boolean}>}
   */
  getAspectRatios = () => {
    const {displayEditorStore} = this.props;

    const sources = displayEditorStore.sources;
    if (!sources || !sources.size) {
      return [];
    }

    const activeAspectRatios = lodash.keys(sources.toJSON());

    return lodash.sortBy(activeAspectRatios, (aspectRatio) => {
      const parts = aspectRatio.split(':');
      return (parts[0] / parts[1]);
    });
  }

  /**
   * Generates the sign options.
   *
   * @returns {Array.<{sign: Object, exists: boolean}>}
   */
  getSignOptions = () => {
    const {displayEditorStore, apiCompanySignGetAllStore} = this.props;

    const sources = displayEditorStore.sources;
    if (!sources || !sources.size) {
      return [];
    }

    const userSigns = apiCompanySignGetAllStore.getFulfilled() || [];

    const signsGroupedByAspectRatioMap = userSigns
      .filter((sign) => sign.aspectRatio)
      .reduce((acc, sign) => {
        if (!acc[sign.aspectRatio]) {
          acc[sign.aspectRatio] = {
            aspectRatio: sign.aspectRatio,
            signs: [],
          };
        }

        acc[sign.aspectRatio].signs.push(sign);

        return acc;
      }, {});
    const signsGroupedByAspectRatio = Object.values(signsGroupedByAspectRatioMap);

    return lodash.sortBy(signsGroupedByAspectRatio, (item) => {
      const parts = item.aspectRatio.split(':');
      return (parts[0] / parts[1]);
    });
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {displayEditorStore, apiUserGetMeStore} = this.props;

    const user = apiUserGetMeStore.getFulfilled();

    const sources = displayEditorStore.sources;
    if (!sources || !sources.size || !user) {
      return null;
    }

    const superAdminUser = Boolean(user && user.role === SUPER_ADMIN_ROLE);
    const userCanDesign = Boolean(user && user.canDesign);

    const currentAspectRatio = decimalToFraction(
      displayEditorStore.currentAspectRatio
    ).ratio;
    const defaultAspectRatio = decimalToFraction(
      displayEditorStore.defaultAspectRatio
    ).ratio;

    const aspectRatios = this.getAspectRatios();

    let options = [];
    let reactSelectComponents = {};

    // if user can design use aspect ratios from content
    if (userCanDesign && superAdminUser) {
      options = aspectRatios.map((aspectRatio) => {
        const label = (defaultAspectRatio === aspectRatio) ? `${aspectRatio} (Default)` : aspectRatio;
        return {label: label, value: aspectRatio};
      });

      reactSelectComponents.SingleValue = ({
        data: option,
      }) => (<span
        className="ellipsis"
        title={option.label}
      >
        {option.label}
      </span>);
    } else {
      // else use aspect ratio from user's signs
      const signOptions = this.getSignOptions();

      reactSelectComponents.Option = ({
        data: option,
        innerProps
      }) => (<div
        className="d-flex px-3 py-2 cursor-pointer"
        {...innerProps}
      >
        <div className="font-weight-bold">
          {option.value}
        </div>
        <ul className="aspect-ratio-sign-list">
          {option.signs.map((sign) => (
            <li
              className="aspect-ratio-sign-list-item"
              key={sign.id}
            >
              <span
                className="aspect-ratio-sign-list-item-sign-name"
                title={sign.name}
              >
                {sign.name}
              </span><br />
              <span className="aspect-ratio-sign-list-item-sign-dimensions">
                {sign.width}w x {sign.height}h
              </span>
            </li>
          ))}
        </ul>
      </div>);

      reactSelectComponents.SingleValue = ({
        data: option,
      }) => {
        let signLabel = `(${option.value})`;
        let title = `(${option.value})`;

        if (option.signs && option.signs.length === 1) {
          signLabel += ` ${option.signs[0].name}`;
          title += ` ${option.signs[0].name}`;
        } else if (option.signs) {
          const signNames = option.signs.filter((sign) => !!sign.name)
            .map((sign) => sign.name.split(' ')[0])
            .join('/');
          signLabel += ` ${signNames}`;
          title += ` ${option.signs.filter((sign) => !!sign.name)
            .map((sign) => sign.name)
            .join(' / ')}`;
        }

        return (<span
          className="ellipsis"
          title={title}
        >
          {signLabel}
        </span>);
      };

      options = signOptions.map((option) => {
        let labelText;

        if (option.signs && option.signs.length > 0) {
          const sign = option.signs[0];
          labelText = `${sign.width} x ${sign.height} (${sign.aspectRatio})`;
        } else {
          labelText = option.aspectRatio;
        }
        const label = (defaultAspectRatio === option.aspectRatio) ? `${labelText} (Default)` : labelText;

        return {
          label: label,
          value: option.aspectRatio,
          signs: option.signs || [],
        };
      });
    }

    return (
      <span className="entity-dimensions dropdown">
        <ReactSelect
          className="site-select theme-header editor-dimension-select"
          classNamePrefix="react-select"
          isClearable={false}
          isMulti={false}
          onChange={this.onChangeDimensions}
          options={options}
          value={options.find((option) => option.value === currentAspectRatio)}
          components={reactSelectComponents}
        />
      </span>
    );
  }
}

EditorDimensions.propTypes = {
  apiCompanySignGetAllStore: MobxPropTypes.observableObject,
  apiUserGetMeStore: MobxPropTypes.observableObject,
  displayEditorStore: MobxPropTypes.observableObject,
};

EditorDimensions.wrappedComponent = {};
EditorDimensions.wrappedComponent.propTypes = {
  apiCompanySignGetAllStore: MobxPropTypes.observableObject.isRequired,
  apiUserGetMeStore: MobxPropTypes.observableObject.isRequired,
  displayEditorStore: MobxPropTypes.observableObject.isRequired
};

export default inject(EditorDimensions)(
  observer(EditorDimensions)
);
