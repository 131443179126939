import classNames from 'classnames';
import {RichUtils} from 'draft-js';
import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAlignCenter, faAlignLeft, faAlignRight} from '@fortawesome/free-solid-svg-icons';

import './alignButtons.scss';

import {ALIGN_LEFT, ALIGN_CENTER, ALIGN_RIGHT} from '../../constants/styleConstants';

/**
 * The list of alignment buttons.
 *
 * @type {Array.<{label: string, style: import('@fortawesome/fontawesome-svg-core').IconProp}>}
 */
const ALIGN_BUTTON_TYPES = [
  {label: 'Left', style: ALIGN_LEFT, icon: faAlignLeft},
  {label: 'Center', style: ALIGN_CENTER, icon: faAlignCenter},
  {label: 'Right', style: ALIGN_RIGHT, icon: faAlignRight},
];

/**
 * The AlignButtons component.
 *
 * @param {{}} params
 * @param {{}} params.editorState
 * @param {function(EditorState)} params.onChangeTextState
 * @param {function()=} params.beforeChange
 * @returns {React.Component}
 */
export const AlignButtons = ({editorState, onChangeTextState, beforeChange}) => {
  const currentBlockType = RichUtils.getCurrentBlockType(editorState);

  /**
   * Updates the editor state with a new style when a button is clicked.
   *
   * @param {string} clickedStyle
   * @returns {function()}
   */
  const onClick = (clickedStyle) => {
    return () => {
      let changeState = editorState;
      if (beforeChange) {
        changeState = beforeChange();
      }

      const beforeChangeBlockType = RichUtils.getCurrentBlockType(changeState);

      // First remove any alignments currently set.
      let newEditorState = changeState;
      ALIGN_BUTTON_TYPES.forEach((alignOption) => {
        if (alignOption.style === clickedStyle) {
          return;
        }
        if (beforeChangeBlockType === alignOption.style) {
          newEditorState = RichUtils.toggleBlockType(newEditorState, alignOption.style);
        }
      });

      newEditorState = RichUtils.toggleBlockType(newEditorState, clickedStyle);
      onChangeTextState(newEditorState);
    };
  };

  return (
    <div className="align-buttons form-buttons">
      {ALIGN_BUTTON_TYPES.map((alignButton) => {
        const classes = {
          'active': (currentBlockType === alignButton.style)
        };

        return (
          <button
            className={classNames('btn btn-sm btn-light form-button', classes)}
            type="button"
            key={alignButton.label}
            onClick={onClick(alignButton.style)}
          >
            <FontAwesomeIcon icon={alignButton.icon} />
          </button>
        );
      })}
    </div>
  );
};

AlignButtons.propTypes = {
  editorState: PropTypes.object.isRequired,
  onChangeTextState: PropTypes.func.isRequired,

  beforeChange: PropTypes.func,
};

export default AlignButtons;
