import {action, observable, runInAction} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRefresh} from '@fortawesome/free-solid-svg-icons';

import inject from '../../../../hoc/injectHoc';

import SearchForm from '../../../../common/searchForm/SearchForm';

import './selectFileHeader.scss';

/**
 * The length of time (milliseconds) until a search request is made.
 *
 * @const {number}
 */
const SEARCH_TIMEOUT_LENGTH = 500;

/**
 * The SelectFileHeader component.
 */
export class SelectFileHeader extends React.Component {
  /**
   * The string to search for in files
   *
   * @type {string}
   */
  @observable searchText = '';

  /**
   * Calls the content search request using Lodash's debounce.
   * Waits SEARCH_TIMEOUT_LENGTH milliseconds since the last request to fire.
   *
   * @type {function}
   */
  debouncedOnSearch = lodash.debounce(action('debouncedOnSearch', (searchValue) => {
    this.callSearch(searchValue);
  }), SEARCH_TIMEOUT_LENGTH);

  /**
   * Triggered when the component has just updated.
   *
   * @param {{resetSearch: boolean}} prevProps
   */
  componentDidUpdate(prevProps) {
    const {resetSearch} = this.props;
    if (resetSearch && !prevProps.resetSearch) {
      this.setSearchText('');
    }
  }

  /**
   * Event handler when the search form changes
   *
   * @param {{}} changeEvent
   */
  searchFormChange = (changeEvent) => {
    runInAction('Set the Search Filter to the new Search String', () => {
      this.searchText = changeEvent.target.value;

      this.debouncedOnSearch(this.searchText);
    });
  };

  /**
   * Set Search String
   *
   * @param {String} searchText
   */
  @action setSearchText = (searchText) => {
    this.searchText = searchText;
  }

  /**
   * Gets new content based on the search term.
   *
   * @param {string} searchValue
   */
  @action callSearch = (searchValue) => {
    const {apiFileSearchStore, onSearchResults} = this.props;

    this.setSearchText(searchValue.replace(/[^a-zA-Z0-9_ -']/g, ''));

    const searchKey = apiFileSearchStore.buildKey(this.searchText);
    apiFileSearchStore.refresh(this.searchText);
    apiFileSearchStore.getPromise(searchKey).then((foundFiles) => {
      onSearchResults(foundFiles || []);
    });
  };

  /**
   * Event handler when the search form is submitted
   *
   * @param {{}} submitEvent
   */
  searchFormSubmit = (submitEvent) => {
    submitEvent.preventDefault();
  };

  /**
   * The add placeholder image click handler.
   */
  onAddPlaceholderImageClick = () => {
    const {onPlaceholderSelected} = this.props;

    if (onPlaceholderSelected) {
      onPlaceholderSelected();
    }
  };

  /**
   * The add placeholder video click handler.
   */
  onAddPlaceholderVideoClick = () => {
    const {onPlaceholderSelected} = this.props;

    if (onPlaceholderSelected) {
      onPlaceholderSelected(true);
    }
  };

  /**
   * The upload button click handler.
   */
  onClickUploadButton = () => {
    const {onOpenFileUploadDialog} = this.props;

    if (onOpenFileUploadDialog) {
      onOpenFileUploadDialog();
    }
  };

  /**
   * When the refresh button is clicked.
   */
  onRefreshClick = () => {
    const {onRefresh} = this.props;

    if (onRefresh) {
      onRefresh();
    }
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {isWritable, onPlaceholderSelected, onRefresh, showImages, showVideos} = this.props;

    return (
      <aside className="select-file-header">
        <div className="header-buttons">

          <div className="search-form-wrapper">
            <SearchForm
              placeholder="Search Files"
              onChange={this.searchFormChange}
              onSubmit={this.searchFormSubmit}
              searchText={this.searchText}
              disabled={false}
            />
          </div>

          {(onRefresh) && (
            <button
              type="button"
              className="w24-refresh-button btn btn-outline-dark"
              onClick={this.onRefreshClick}
            >
              <FontAwesomeIcon icon={faRefresh} />
            </button>
          )}

          {showImages && (Boolean(onPlaceholderSelected)) && (
            <span>
              <button
                type="button"
                onClick={this.onAddPlaceholderImageClick}
                className="control-btn btn btn-primary"
              >Placeholder Image</button>
            </span>
          )}

          {showVideos && (Boolean(onPlaceholderSelected)) && (
            <span>
              <button
                type="button"
                onClick={this.onAddPlaceholderVideoClick}
                className="control-btn btn btn-primary"
              >Placeholder Video</button>
            </span>
          )}

          {(isWritable) && (
            <span>
              <button
                type="button"
                onClick={this.onClickUploadButton}
                className="control-btn btn btn-primary"
              >Upload File</button>
            </span>
          )}
        </div>
      </aside>
    );
  }
}

SelectFileHeader.propTypes = {
  apiFileSearchStore: MobxPropTypes.observableObject,
  isWritable: PropTypes.bool,
  onOpenFileUploadDialog: PropTypes.func,
  onPlaceholderSelected: PropTypes.func,
  onRefresh: PropTypes.func,
  onSearchResults: PropTypes.func,
  resetSearch: PropTypes.bool,
  showImages: PropTypes.bool,
  showVideos: PropTypes.bool,
};

SelectFileHeader.defaultProps = {
  isWritable: false,
  showImages: false,
  showVideos: false,
};

SelectFileHeader.wrappedComponent = {};
SelectFileHeader.wrappedComponent.propTypes = {
  apiFileSearchStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(SelectFileHeader)(
  observer(SelectFileHeader)
);
