import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './buttonAttentionWrapper.scss';

/**
 * The ButtonAttentionWrapper component.
 */
export class ButtonAttentionWrapper extends React.Component {
  /**
   * Renders the component.
   *
   * @param {*} props
   * @returns {{}}
   */
  render() {
    return (
      <div
        className={classNames('btn-with-spiral', this.props.className)}
      >
        {this.props.children}
      </div>
    );
  }
}

ButtonAttentionWrapper.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
};

export default ButtonAttentionWrapper;
