import {action} from 'mobx';

import {EXPIRES_IN, EXPIRES_PENDING, EXPIRE_TIME} from '../../../constants/storeConstants';
import {STATE_FULFILLED, STATE_PENDING, STATE_REJECTED} from '../../../constants/asyncConstants';
import serverApi from '../../../utils/serverApi';
import ApiBaseStore from '../common/apiBaseStore';

/**
 * ApiGenerateTextStore
 */
class ApiGenerateTextStore extends ApiBaseStore {
  /**
   * Generate text
   *
   * @param {string} subject
   * @param {string} contentType
   */
  @action makeRequest(subject, contentType) {
    this.state = STATE_PENDING;
    this[EXPIRE_TIME] = Date.now() + EXPIRES_PENDING;

    serverApi.generateText(subject, contentType)
      .then(action('generateTextSuccess', (res) => {
        this.state = STATE_FULFILLED;
        this[EXPIRE_TIME] = Date.now() + EXPIRES_IN;
        this.data = res;
        this.error = null;
      })).catch(action('generateTextError', (generateTextError) => {
        this.state = STATE_REJECTED;
        this[EXPIRE_TIME] = null;
        this.data = null;
        this.error = generateTextError;
      }));
  }
}

export default new ApiGenerateTextStore();
