/**
 * Triggers a window resize event
 */
export function triggerResize() {
  const waitForDomInMS = 100;
  setTimeout(() => {
    if (typeof (Event) === 'function') {
      // modern browsers
      window.dispatchEvent(new Event('resize'));
    } else {
      // for IE and other old browsers
      // causes deprecation warning on modern browsers
      const evt = window.document.createEvent('UIEvents');
      evt.initUIEvent('resize', true, false, window, 0);
      window.dispatchEvent(evt);
    }
  }, waitForDomInMS);
}
