import lodash from 'lodash';
import {runInAction} from 'mobx';

import {clearTransitionCache, setEntityComponents, updateEntity} from '../ecs/entityHelper';

/**
 * The name of the system.
 * @const {string}
 */
export const ACTION_UPDATING_SYSTEM = 'actionUpdatingSystem';

/**
 * Gets a new instance of the updating system.
 *
 * @param {GameStore} game
 * @returns {{name: string, runActions: systemRunActions}}
 */
export function actionUpdatingSystem(game) {
  /**
   * Called right before the game loop updates.
   *
   * @param {ObservableArray} actions
   */
  function systemRunActions(actions) {
    actions.forEach((actionEntity) => {
      // First check for required components.
      if (!actionEntity.has('actionUpdate')) {
        return;
      }

      const action = actionEntity.get('action');
      const updates = actionEntity.get('actionUpdate').updatedComponents;
      const entity = game.getEntity(action.entityId);
      if (!entity) {
        return;
      }

      lodash.forEach(updates, (componentData, keyName) => {
        updateEntityWithParams(entity, keyName, componentData);
      });

      clearTransitionCache(entity);
    });
  }

  /**
   * Updates the given entity with the differences given.
   *
   * @param {{}} entity
   * @param {string} componentName
   * @param {{}} params
   */
  function updateEntityWithParams(entity, componentName, params) {
    runInAction('actionUpdatingSystemUpdateEntity', () => {
      if (entity.has(componentName)) {
        updateEntity(entity, componentName, params);
      } else if (params !== null) {
        setEntityComponents(entity, {[componentName]: params});
      }
    });
  }

  return {
    name: ACTION_UPDATING_SYSTEM,
    runActions: systemRunActions
  };
}
