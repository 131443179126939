import {runInAction} from 'mobx';

import {nameComponent} from '../components/common/nameComponent';

/**
 * The name of the system.
 * @const {string}
 */
export const ACTION_ENTITY_SYSTEM = 'actionEntitySystem';

/**
 * Gets a new instance of the entity system.
 *
 * @param {GameStore} game
 * @returns {{name: string, runActions: systemRunActions}}
 */
export function actionEntitySystem(game) {
  /**
   * Called right before the game loop updates.
   *
   * @param {ObservableArray} actions
   */
  function systemRunActions(actions) {
    actions.forEach((actionEntity) => {
      addEntity(actionEntity);
      removeEntity(actionEntity);
      duplicateEntity(actionEntity);
      renameEntity(actionEntity);
    });
  }

  /**
   * Adds a new entity.
   *
   * @param {ObservableMap} actionEntity
   */
  function addEntity(actionEntity) {
    if (!actionEntity.has('actionAddEntity')) {
      return;
    }

    const action = actionEntity.get('action');
    const {newEntity, isSourceEntity, makeObservable} = actionEntity.get('actionAddEntity');

    runInAction('actionEntitySystemNewEntity', () => {
      if (isSourceEntity) {
        const entityMap = game.addSourceEntity(newEntity);
        if (action.entityId === true) {
          action.entityId = entityMap.get('id');
        }
      } else {
        game.addEntity(newEntity, makeObservable);
      }
    });
  }

  /**
   * Removes an entity.
   *
   * @param {ObservableMap} actionEntity
   */
  function removeEntity(actionEntity) {
    if (!actionEntity.has('actionRemoveEntity')) {
      return;
    }

    const {entityId} = actionEntity.get('action');

    runInAction('actionEntitySystemRemoveEntity', () => {
      game.removeEntity(entityId);
    });
  }

  /**
   * Duplicates an entity.
   *
   * @param {ObservableMap} actionEntity
   */
  function duplicateEntity(actionEntity) {
    if (!actionEntity.has('actionDuplicateEntity')) {
      return;
    }

    const action = actionEntity.get('action');

    runInAction('actionEntitySystemDuplicateEntity', () => {
      const entityMap = game.duplicateEntity(action.entityId);
      if (action.entityId === true) {
        action.entityId = entityMap.get('id');
      }
    });
  }

  /**
   * Renames an entity.
   *
   * @param {ObservableMap} actionEntity
   */
  function renameEntity(actionEntity) {
    if (!actionEntity.has('actionRenameEntity')) {
      return;
    }

    const action = actionEntity.get('action');
    const {newName} = actionEntity.get('actionRenameEntity');
    if (!newName) {
      return;
    }

    runInAction('actionEntitySystemRenameEntity', () => {
      const entity = game.getEntity(action.entityId);
      if (!entity) {
        return;
      }

      const newNameComponent = nameComponent(newName);
      entity.set('name', newNameComponent.name);
    });
  }

  return {
    name: ACTION_ENTITY_SYSTEM,
    runActions: systemRunActions
  };
}
