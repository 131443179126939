import lodash from 'lodash';

/**
 * The default triangle color.
 * @const {string}
 */
const DEFAULT_COLOR = '#000000';

/**
 * The default triangle opacity.
 * @const {number}
 */
const DEFAULT_OPACITY = 1;

/**
 * The triangle component.
 *
 * @param {{opacity: number}=} style
 * @param {{opacity: number, width: number}=} border
 * @returns {{triangle: {}}}
 */
export function triangleComponent(style, border) {
  return {
    triangle: {
      style,
      border
    },
  };
}

/**
 * Gets the triangle component from the source item.
 *
 * @param {{triangle: {style: {}, border: {}}}} item
 * @param {{}=} variables
 * @returns {{triangle: {style: {}, border: {}}}}
 */
export function getTriangleFromSource(item, variables) {
  if (!item.triangle) {
    return {};
  }

  const triangle = {...item.triangle};
  if (!triangle.style) {
    triangle.style = {};
  }
  if (!triangle.border) {
    triangle.border = {};
  }

  const canEdit = lodash.get(item, 'compose.canEdit');
  const variableName = lodash.get(item, 'compose.variableName');
  if (canEdit && variableName) {
    const variableValue = lodash.get(variables, `triangle.${variableName}`, '');
    if (variableValue) {
      let styleColor, styleOpacity, borderColor, borderOpacity;
      if (lodash.isString(variableValue)) {
        // The old deprecated variable style is a string with | delimiters.
        const partsCount = 4;
        [styleColor, styleOpacity, borderColor, borderOpacity] = lodash.split(variableValue, '|', partsCount);
      } else {
        // The ()s fix an eslint parsing error.
        ({styleColor, styleOpacity, borderColor, borderOpacity} = variableValue);
      }

      triangle.style.color = styleColor;
      triangle.style.opacity = styleOpacity;
      triangle.border.color = borderColor;
      triangle.border.opacity = borderOpacity;
    }
  }

  const style = {
    color: triangle.style.color || DEFAULT_COLOR,
    opacity: getOpacity(triangle.style),
    rounded: triangle.style.rounded || 0,
  };
  const border = {
    color: triangle.border.color || DEFAULT_COLOR,
    opacity: getOpacity(triangle.border),
    width: triangle.border.width || 0,
  };

  return triangleComponent(
    style,
    border,
  );
}

/**
 * Gets the opacity or the default.
 *
 * @param {{opacity: number}} item
 * @returns {number}
 */
function getOpacity(item) {
  if (!item || item.opacity === undefined) {
    return DEFAULT_OPACITY;
  }

  const safeOpacity = Number(item.opacity);
  if (!safeOpacity && safeOpacity !== 0) {
    return DEFAULT_OPACITY;
  }

  return safeOpacity;
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {{}} entity
 * @returns {{}}
 */
export function getTriangleForSource(entity) {
  if (!entity.has('triangle')) {
    return {};
  }

  const compose = entity.get('compose');
  const triangle = entity.get('triangle');

  const canEdit = lodash.get(compose, 'canEdit', false);
  const variableName = lodash.get(compose, 'variableName');

  const outTriangle = {};

  if (!canEdit || !variableName) {
    outTriangle.style = triangle.style;
    outTriangle.border = triangle.border;
  } else {
    outTriangle.style = {
      rounded: triangle.style.rounded || 0,
    };
    outTriangle.border = {
      width: triangle.border.width || 0,
    };
  }

  return {
    triangle: lodash.cloneDeep(outTriangle),
  };
}
