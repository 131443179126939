/**
 * The action add entity.
 *
 * @param {{}} newEntity
 * @param {boolean=} isSourceEntity
 * @param {boolean=} makeObservable
 * @returns {{actionAddEntity: {newEntity: {}, isSourceEntity: boolean, makeObservable: boolean}}}
 */
export function actionAddEntityComponent(newEntity, isSourceEntity, makeObservable) {
  return {
    actionAddEntity: {
      newEntity,
      isSourceEntity: Boolean(isSourceEntity),
      makeObservable: Boolean(makeObservable),
    },
  };
}
