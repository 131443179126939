/**
 * The action duplicate entity.
 *
 * @returns {{actionDuplicateEntity: {}}}
 */
export function actionDuplicateEntityComponent() {
  return {
    actionDuplicateEntity: {},
  };
}
