import lodash from 'lodash';
import {action, observable, toJS} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';

import NewAspectRatioModal from '../../modals/newAspectRatio/NewAspectRatioModal';
import inject from '../../hoc/injectHoc';
import {actionGameResizeComponent} from '../../../display/components/action/actionGameResizeComponent';
import {getSourceFromGame} from '../../../display/game';
import {decimalToFraction, ratioToNumber} from '../../../utils/mathHelper';

import './editorNewDimensions.scss';

/**
 * The list of aspect ratios that can be added.
 *
 * @type {string[]}
 */
const placeholderAspectRatios = ['16:9', '4:1', '3:1', '3:2', '2:1', '1:1', '1:2', '1:3', '1:4'];

/**
 * The EditorNewDimensions component.
 *
 * @property {{
 *   displayEditorStore: DisplayEditorStore,
 * }} props
 */
export class EditorNewDimensions extends React.Component {
  /**
   * Whether or not the create modal is open.
   *
   * @type {boolean}
   */
  @observable isCreateModalOpen = false;

  /**
   * Handles when the add button has been clicked.
   */
  @action onCreateStart = () => {
    this.isCreateModalOpen = true;
  };

  /**
   * Updates the aspect ratio of the system.
   *
   * @param {string} newAspectRatio
   * @param {string} copyFrom
   */
  @action onCreateNewDimension = (newAspectRatio, copyFrom) => {
    this.isCreateModalOpen = false;

    if (!newAspectRatio) {
      // The user said they did not want to delete the content or cancelled the modal.
      return;
    }

    const {displayEditorStore} = this.props;
    const {currentAspectRatio, content, game, sources} = displayEditorStore;

    const actionParams = {
      entityId: true,
    };

    // Store the current source in the displayEditorStore, otherwise we would lose all updates.
    const oldSource = getSourceFromGame(game);
    displayEditorStore.setSource(currentAspectRatio, oldSource);

    const currentResolution = {...game.resolution};

    const aspectMultiplier = ratioToNumber(newAspectRatio);

    const minHeight = content.width / aspectMultiplier;
    const finalHeight = minHeight + ((content.height - minHeight) / 2);
    const finalWidth = finalHeight * aspectMultiplier;

    const simpleSources = toJS(sources) || {};

    let fromSource = simpleSources[String(copyFrom)];
    if (!copyFrom || !fromSource) {
      // First find the closest matching existing aspect ratio.
      const existingRatios = lodash.map(sources, (source, sourceAspectRatio) => {
        return {aspectRatio: ratioToNumber(sourceAspectRatio), source};
      });

      const sortedRatios = lodash.sortBy(existingRatios, ({aspectRatio}) => {
        return Math.abs(aspectRatio - aspectMultiplier);
      });

      fromSource = lodash.first(sortedRatios).source;
    }

    const newSource = lodash.cloneDeep(fromSource);
    newSource.resolution.height = finalHeight;
    newSource.resolution.width = finalWidth;
    newSource.aspectRatio = newAspectRatio;

    displayEditorStore.setSource(newAspectRatio, newSource);

    game.addAction(actionParams, actionGameResizeComponent(
      newAspectRatio,
      finalWidth,
      finalHeight,
      currentResolution.width,
      currentResolution.height,
    ));
  };

  /**
   * Generates the aspect ratio options.
   *
   * @param {boolean} active
   * @returns {{}}
   */
  getAspectRatios = (active) => {
    const {displayEditorStore} = this.props;

    const {sources} = displayEditorStore;
    if (!sources || !sources.size) {
      return [];
    }

    const activeAspectRatios = lodash.keys(sources.toJSON());

    let allowedAspectRatios;
    if (active) {
      allowedAspectRatios = activeAspectRatios;
    } else {
      allowedAspectRatios = placeholderAspectRatios.reduce((finalPlaceholders, placeholder) => {
        if (!lodash.includes(activeAspectRatios, placeholder)) {
          finalPlaceholders.push(placeholder);
        }
        return finalPlaceholders;
      }, []);
    }

    const defaultAspectRatio = decimalToFraction(
      displayEditorStore.defaultAspectRatio
    ).ratio;

    return lodash.sortBy(allowedAspectRatios, (aspectRatio) => {
      const parts = aspectRatio.split(':');
      return (parts[0] / parts[1]);
    }).map((aspectRatio) => {
      return {
        'aspectRatio': aspectRatio,
        'label': (defaultAspectRatio === aspectRatio) ? `${aspectRatio} (Default)` : aspectRatio
      };
    });
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {displayEditorStore} = this.props;

    const {sources} = displayEditorStore;
    if (!sources || !sources.size) {
      return null;
    }

    const newAspectRatios = this.getAspectRatios(false);
    const currentAspectRatios = this.getAspectRatios(true);

    const {currentAspectRatio} = displayEditorStore;

    return (
      <span className="editor-new-dimensions dropdown">
        <button
          type="button"
          id="add-dimension"
          className="btn btn-sm btn-light btn-default"
          data-offset="-70,0"
          onClick={this.onCreateStart}
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>

        <NewAspectRatioModal
          isOpen={this.isCreateModalOpen}
          onComplete={this.onCreateNewDimension}
          newAspectRatios={newAspectRatios}
          currentAspectRatios={currentAspectRatios}
          startingCopyFrom={currentAspectRatio}
        />
      </span>
    );
  }
}

EditorNewDimensions.propTypes = {
  displayEditorStore: MobxPropTypes.observableObject,
};

EditorNewDimensions.wrappedComponent = {};
EditorNewDimensions.wrappedComponent.propTypes = {
  displayEditorStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(EditorNewDimensions)(
  observer(EditorNewDimensions)
);
