/**
 * The action reorder component.
 *
 * @param {number} orderDelta
 * @returns {{actionReorder: {orderDelta: number}}}
 */
export function actionReorderComponent(orderDelta) {
  return {
    actionReorder: {
      orderDelta,
    },
  };
}
