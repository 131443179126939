import lodash from 'lodash';
import {action, runInAction} from 'mobx';

import apiContentGetOneStore from './apiContentGetOneStore';
import ApiMapBaseStore from '../common/apiMapBaseStore';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import serverApi from '../../../utils/serverApi';

/**
 * The amount of time between polling calls.
 * @const {number[]}
 */
const POLLING_INTERVALS = [
  // eslint-disable-next-line no-magic-numbers
  15000
];

/**
 * The number of errors that will cancel a poll.
 * @const {number}
 */
const CANCEL_ERROR_COUNT = 4;

/**
 * The maximum number of times polling will occur.
 * @const {number}
 */
const MAX_POLL_COUNT = 1000;

/**
 * Polls for content render status updates from the api.
 */
class ApiContentRenderPollStatusStore extends ApiMapBaseStore {
  /**
   * The map of ids that can be used to cancel the active polling.
   *
   * @type {Object.<number, number>}
   */
  cancelTimeoutIds = {};

  /**
   * Whether or not the given content is processing.
   *
   * @param {{status: string}} content
   * @returns {boolean}
   */
  isContentProcessing = (content) => {
    if (!content || (!content.status && !content.displayStatus)) {
      return false;
    }

    const contentStatus = content.status || content.displayStatus;

    return (contentStatus === 'processing' || contentStatus === 'pending');
  };

  /**
   * Clears all the polling.
   */
  @action clearAll() {
    lodash.forEach(this.cancelTimeoutIds, (timeoutId) => {
      clearTimeout(timeoutId);
    });

    this.dataMap.clear();
  }

  /**
   * Cancels any active polling for the given content id.
   *
   * @param {number} contentId
   */
  cancelPollForContent(contentId) {
    const safeContentId = String(contentId);

    const timeoutId = this.cancelTimeoutIds[safeContentId];
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    this.dataMap.delete(safeContentId);
  }

  /**
   * Gets the next poll interval.
   *
   * @param {number} currentCount
   * @returns {number}
   */
  getNextPollInterval = (currentCount) => {
    const safeCount = Number(currentCount);

    if (!currentCount || !safeCount) {
      return POLLING_INTERVALS[0];
    } else if (POLLING_INTERVALS[safeCount]) {
      return POLLING_INTERVALS[safeCount];
    }

    return lodash.last(POLLING_INTERVALS);
  };

  /**
   * Checks if content is already being polled.
   *
   * @param {number} contentId
   * @returns {boolean}
   */
  isPollingInProgress(contentId) {
    const safeContentId = String(contentId);

    const currentResource = this.dataMap.get(safeContentId);
    return (currentResource && currentResource.state === STATE_PENDING);
  }

  /**
   * Polls content from the server until the enters a good content state.
   *
   * @param {number} contentId
   */
  @action pollContentById(contentId) {
    const safeContentId = String(contentId);

    if (this.isPollingInProgress(safeContentId)) {
      return;
    }

    this.dataMap.set(safeContentId, {
      state: STATE_PENDING,
      count: 0,
      data: null,
      errorCount: 0,
      error: null,
      successError: false,
    });

    this.cancelTimeoutIds[safeContentId] = setTimeout(() => {
      this.pollingLoop(safeContentId);
    }, this.getNextPollInterval(0));
  }

  /**
   * Starts or continues a polling loop.
   * Should not be called directly.
   *
   * @param {number} contentId
   */
  @action pollingLoop(contentId) {
    const safeContentId = String(contentId);

    // Get the simple content status info.
    serverApi.contentRenderGetStatus(safeContentId).then(
      action('getContentSuccess', (foundContent) => {
        const currentPoll = this.dataMap.get(safeContentId);
        const newCount = currentPoll.count + 1;

        const isPending = this.isContentProcessing(foundContent);
        if (!isPending) {
          delete this.cancelTimeoutIds[safeContentId];

          this.endPollingSuccess(safeContentId, foundContent, newCount);
          return;
        }

        if (newCount >= MAX_POLL_COUNT) {
          delete this.cancelTimeoutIds[safeContentId];

          this.dataMap.set(safeContentId, {
            ...currentPoll,
            state: STATE_REJECTED,
            count: newCount,
            error: new Error('Polling max count reached.'),
          });
          return;
        }

        this.dataMap.set(safeContentId, {
          ...currentPoll,
          count: newCount,
        });

        this.cancelTimeoutIds[safeContentId] = setTimeout(() => {
          this.pollingLoop(safeContentId);
        }, this.getNextPollInterval(newCount));
      }),
      action('getContentError', (error) => {
        const currentPoll = this.dataMap.get(safeContentId);
        const newCount = currentPoll.errorCount + 1;

        if (newCount >= CANCEL_ERROR_COUNT) {
          delete this.cancelTimeoutIds[safeContentId];

          this.dataMap.set(safeContentId, {
            ...currentPoll,
            state: STATE_REJECTED,
            errorCount: newCount,
            error,
          });

          return;
        }

        this.dataMap.set(safeContentId, {
          ...currentPoll,
          state: STATE_REJECTED,
          errorCount: newCount,
          error,
        });

        this.cancelTimeoutIds[safeContentId] = setTimeout(() => {
          this.pollingLoop(safeContentId);
        }, this.getNextPollInterval(currentPoll.count));
      })
    );
  }

  /**
   * Finishes a polling as a success.
   *
   * @param {number} contentId
   * @param {{id: number, status: string}} content
   * @param {number} newCount
   */
  @action endPollingSuccess(contentId, content, newCount) {
    const safeContentId = String(contentId);
    const currentPoll = this.dataMap.get(safeContentId);

    runInAction('getContentSuccess', () => {
      this.dataMap.set(safeContentId, {
        ...currentPoll,
        state: STATE_FULFILLED,
        count: newCount,
        data: content,
        error: null,
      });
    });
  }

  /**
   * Polls content and updates it in the other stores when it is finished processing.
   *
   * @param {number} contentId
   */
  pollThenUpdateContent(contentId) {
    const safeContentId = String(contentId);

    this.pollContentById(safeContentId);

    this.getPromise(safeContentId).then((content) => {
      if (!content || !content.id) {
        return;
      }

      apiContentGetOneStore.refresh(safeContentId, true);
    }).catch(() => {
      // We will just ignore polling errors.
    });
  }
}

export default new ApiContentRenderPollStatusStore();
