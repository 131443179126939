export default {
  DAY_ALIGN: '|',
  HIGH_ALIGN: '|',
  LOW_ALIGN: '|',

  DAY_WIDTH_RATIO: 0.8904,
  DAY_HEIGHT_RATIO: 0.0356,
  DAY_ONE_POSITION_X_RATIO: 0.0627,
  DAY_ONE_POSITION_Y_RATIO: 0.0218,
  DAY_TWO_POSITION_X_RATIO: 0.0627,
  DAY_TWO_POSITION_Y_RATIO: 0.3431,
  DAY_THREE_POSITION_X_RATIO: 0.0627,
  DAY_THREE_POSITION_Y_RATIO: 0.6676,

  ICON_WIDTH_RATIO: 0.9667,
  ICON_HEIGHT_RATIO: 0.2168,
  ICON_ONE_POSITION_X_RATIO: -0.0872,
  ICON_ONE_POSITION_Y_RATIO: 0.0414,
  ICON_TWO_POSITION_X_RATIO: -0.0872,
  ICON_TWO_POSITION_Y_RATIO: 0.3627,
  ICON_THREE_POSITION_X_RATIO: -0.0872,
  ICON_THREE_POSITION_Y_RATIO: 0.6872,

  HIGH_WIDTH_RATIO: 0.7211,
  HIGH_HEIGHT_RATIO: 0.0836,
  HIGH_ONE_POSITION_X_RATIO: 0.1462,
  HIGH_ONE_POSITION_Y_RATIO: 0.1916,
  HIGH_TWO_POSITION_X_RATIO: 0.1462,
  HIGH_TWO_POSITION_Y_RATIO: 0.5129,
  HIGH_THREE_POSITION_X_RATIO: 0.1462,
  HIGH_THREE_POSITION_Y_RATIO: 0.8374,

  LOW_WIDTH_RATIO: 0.4121,
  LOW_HEIGHT_RATIO: 0.0478,
  LOW_ONE_POSITION_X_RATIO: 0.3007,
  LOW_ONE_POSITION_Y_RATIO: 0.268,
  LOW_TWO_POSITION_X_RATIO: 0.3007,
  LOW_TWO_POSITION_Y_RATIO: 0.5893,
  LOW_THREE_POSITION_X_RATIO: 0.3007,
  LOW_THREE_POSITION_Y_RATIO: 0.9138,
};
