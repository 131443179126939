import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import prestoLogo from '../../../../../assets/images/presto.svg';
import {getAssetUrl} from '../../../../../utils/assetHelper';

import './renderingPreview.scss';

/**
 * The URL to the video that plays while rendering is in process.
 * @type {string}
 */
const processingVideoUrl = getAssetUrl('presto-pending-3.mp4');

/**
 * The RenderingPreview component.
 */
class RenderingPreview extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {hasError} = this.props;

    if (hasError) {
      return (
        <div className={classNames('rendering-preview', {'rendering-error': hasError})}>
          <div className="container-fluid">
            <div className="row align-items-sm-center">
              <div className="col-12 col-sm-3">
                <div className="mb-3 mb-sm-0">
                  <img className="presto-icon" alt="Presto Icon" src={prestoLogo} />
                </div>
              </div>
              <div className="col-12 col-sm-9">
                <div className="rendering-text text-center text-sm-left">
                  {(hasError) ? 'Presto Ran Into A Problem' : 'Presto Is Working Its Magic...'}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="rendering-preview">
        <video className="render-preview-video" loop={true} autoPlay={true} muted={true}>
          <source src={processingVideoUrl} type="video/mp4" />

          <div className="presto-icon" style={{backgroundImage: `url(${prestoLogo})`}} />
          <div className="rendering-text">
            Presto Is Working Its Magic...
          </div>
        </video>
      </div>
    );
  }
}

RenderingPreview.propTypes = {
  hasError: PropTypes.bool,
};

RenderingPreview.defaultProps = {
  hasError: false,
};

export default RenderingPreview;
