import {action, observable, runInAction} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import lodash from 'lodash';

import Link from '../../common/link/Link';
import LoadingIcon from '../../common/loadingIcon/LoadingIcon';
import inject from '../../hoc/injectHoc';
import ConfirmModal from '../../modals/confirm/ConfirmModal';
import SignAddEditModal from '../../modals/signAddEdit/SignAddEditModal';
import {dashboardRoute} from '../../routePaths';
import {STATE_PENDING} from '../../../constants/asyncConstants';
import {CANNOT_ADD_SIGN_TEXT} from '../../../constants/messageConstants';

import './companySignsPage.scss';

const signFieldMap = [
  {label: 'Manufacturer', field: 'manufacturer'},
  {label: 'AspectRatio', field: 'aspectRatio'},
  {label: 'Pitch', field: 'pitch', postfix: 'mm'},
];

/**
 * The CompanySignsPage component.
 */
export class CompanySignsPage extends Component {
  /**
   * Whether or not the add sign modal should be open.
   *
   * @type {boolean}
   */
  @observable shouldAddSign = false;

  /**
   * The sign object for the sign to be edited.
   * This will open the edit sign modal.
   *
   * @type {?{}}
   */
  @observable editSign = null;

  /**
   * The sign object for the sign to be deleted.
   * This will open the delete sign confirm modal.
   *
   * @type {?{}}
   */
  @observable deleteSign = null;

  /**
   * Triggered when the component is first mounted to the page.
   */
  componentDidMount() {
    const {apiUserGetMeStore, apiCompanySignGetAllStore, routerStore} = this.props;

    apiUserGetMeStore.refresh();
    apiCompanySignGetAllStore.refresh();

    const addSignQueryParam = routerStore.getQuery('addSign');
    if (addSignQueryParam) {
      runInAction('presetAddSign', () => {
        this.shouldAddSign = true;
      });
    }

    apiCompanySignGetAllStore.getPromise().then((signs) => {
      const editSignIdQueryParam = routerStore.getQuery('editSignId');
      if (signs && !Number.isNaN(editSignIdQueryParam) && signs.some((sign) => sign.id === Number(editSignIdQueryParam))) {
        runInAction('presetEditSign', () => {
          const matchingSign = signs.find((sign) => sign.id === Number(editSignIdQueryParam));
          this.editSign = matchingSign;
        });
      }
    });
  }

  /**
   * Triggered when the add sign button is clicked.
   */
  @action onAddSign = () => {
    this.shouldAddSign = true;
    this.editSign = null;
    this.deleteSign = null;
  };

  /**
   * Triggered when the add sign modal is closed.
   */
  @action afterAddSign = () => {
    const {routerStore} = this.props;

    this.shouldAddSign = false;

    const redirectUrlQueryParam = routerStore.getQuery('redirectUrl');
    if (redirectUrlQueryParam) {
      window.location.href = redirectUrlQueryParam;
    }
  };

  /**
   * Triggered when the edit sign button is clicked.
   *
   * @param {{id: number}} sign
   * @returns {function}
   */
  onEditSign = (sign) => {
    return action('openEditSignModal', () => {
      this.editSign = sign;
      this.shouldAddSign = false;
      this.deleteSign = null;
    });
  };

  /**
   * Triggered when the edit sign modal is closed.
   */
  @action afterEditSign = () => {
    const {routerStore} = this.props;

    this.editSign = null;

    const redirectUrlQueryParam = routerStore.getQuery('redirectUrl');
    if (redirectUrlQueryParam) {
      window.location.href = redirectUrlQueryParam;
    }
  };

  /**
   * Triggered when the delete sign button is clicked.
   *
   * @param {{id: number}} sign
   * @returns {function}
   */
  onDeleteSign = (sign) => {
    return action('openDeleteSignConfirm', () => {
      this.deleteSign = sign;
      this.shouldAddSign = false;
      this.editSign = null;
    });
  };

  /**
   * Triggered when the user confirms the sign should be deleted.
   */
  @action afterConfirmDelete = () => {
    this.deleteSign = null;
  };

  /**
   * Parses the signs into the cards.
   *
   * @param {Array<{}>} signs
   * @param {boolean} canManageSigns
   * @returns {Array<{}>}
   */
  parseSignsToCard = (signs, canManageSigns) => {
    const {apiSignDeleteStore} = this.props;

    return signs.map((sign) => {
      const deleteState = apiSignDeleteStore.getState(sign.id);
      const deleteIsPending = (deleteState === STATE_PENDING);

      let dimensions = null;
      if (sign.width && sign.height) {
        dimensions = `${sign.width} x ${sign.height}`;
      } else if (sign.width) {
        dimensions = `Width: ${sign.width}px`;
      } else if (sign.height) {
        dimensions = `Height: ${sign.height}px`;
      }

      return (
        <div className="card" key={sign.id}>
          <div className="card-body">
            <h5 className="card-title">{sign.name}</h5>

            {(dimensions) && (
              <h6 className="card-subtitle mb-2 text-muted">{dimensions}</h6>
            )}

            <ul className="card-text">
              {signFieldMap.map(({label, field, postfix}) => (
                <li key={field}>{label}: {sign[field]}{postfix || ''}</li>
              ))}
            </ul>

            {canManageSigns && (<div>
              <button
                type="button"
                className="btn btn-sm btn-primary card-link"
                onClick={this.onEditSign(sign)}
              >Edit</button>

              <button
                type="button"
                className="btn btn-sm btn-danger card-link"
                onClick={this.onDeleteSign(sign)}
                disabled={deleteIsPending}
              >{(deleteIsPending) ? 'Deleting...' : 'Delete'}</button>
            </div>)}
          </div>
        </div>
      );
    });
  };

  /**
   * Renders the signs.
   * @param {boolean} canManageSigns
   *
   * @returns {{}}
   */
  renderSigns = (canManageSigns) => {
    const {apiCompanySignGetAllStore} = this.props;

    return apiCompanySignGetAllStore.case({
      pre: () => <LoadingIcon />,
      pending: () => <LoadingIcon />,
      rejected: () => (
        <div className="alert alert-warning">
          The list of signs could not be loaded.
        </div>
      ),
      fulfilled: (signs) => (
        <div className="card-deck">
          {this.parseSignsToCard(signs)}

          {canManageSigns && (<div className="card add-new-card">
            <div className="card-body">
              <button
                type="button"
                className="btn add-new-button"
                onClick={this.onAddSign}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  size="3x"
                />
              </button>
            </div>
          </div>)}
        </div>
      ),
    });
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {apiUserGetMeStore} = this.props;

    const user = apiUserGetMeStore.getFulfilled();
    const canManageSigns = lodash.get(user, 'plan.canManageSigns');

    return (
      <div id="company-signs-page" className="system-page full-height container-fluid">
        <div className="container">
          <Link to={dashboardRoute}>
            <h4 className="h6">← Back to Dashboard</h4>
          </Link>

          <h2 className="signs-header">Your Signs</h2>
          {apiUserGetMeStore.case({
            pre: () => <LoadingIcon />,
            pending: () => <LoadingIcon />,
            rejected: () => (
              <div className="alert alert-warning">
                The list of signs could not be loaded.
              </div>
            ),
            fulfilled: () => this.renderSigns(canManageSigns),
          })}

          {!canManageSigns && (<div className="signs-header-subtext">
            {CANNOT_ADD_SIGN_TEXT}
          </div>)}
        </div>

        {(this.deleteSign) && (
          <ConfirmModal
            isOpen={true}
            confirmText="Please contact Project Content in order to delete a sign."
            onComplete={this.afterConfirmDelete}
            isYesNo={false}
          />
        )}

        {(this.shouldAddSign) && (
          <SignAddEditModal
            isOpen={true}
            isAdmin={false}
            companyId={(user) ? user.companyId : null}
            onComplete={this.afterAddSign}
          />
        )}

        {(this.editSign) && (
          <SignAddEditModal
            isOpen={true}
            isAdmin={false}
            sign={this.editSign}
            companyId={(user) ? user.companyId : null}
            onComplete={this.afterEditSign}
          />
        )}
      </div>
    );
  }
}

CompanySignsPage.propTypes = {
  apiCompanySignGetAllStore: MobxPropTypes.observableObject,
  apiSignDeleteStore: MobxPropTypes.observableObject,
  apiUserGetMeStore: MobxPropTypes.observableObject,
  routerStore: MobxPropTypes.observableObject,
};

CompanySignsPage.wrappedComponent = {};
CompanySignsPage.wrappedComponent.propTypes = {
  apiCompanySignGetAllStore: MobxPropTypes.observableObject.isRequired,
  apiSignDeleteStore: MobxPropTypes.observableObject.isRequired,
  apiUserGetMeStore: MobxPropTypes.observableObject.isRequired,
  routerStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(CompanySignsPage)(
  observer(CompanySignsPage)
);
