import lodash from 'lodash';

import {EASINGS} from '../../ecs/easingHelper';

/**
 * The name that signals this preset.
 * @const {string}
 */
export const PRESET_ROTATE = 'rotate';

/**
 * The number of degrees to rotate for full circle.
 * @const {number}
 */
const fullCircle = 360;

/**
 * The preset details.
 *
 * @type {{
 *   name: string,
 *   humanName: string,
 *   flow: string,
 *   details: Object.<string, {
 *     easing: string,
 *     parse: boolean,
 *     values: *|Array.<*>,
 *     time: {start: (string|number), end: (string|number)}
 *   }>,
 *   template: {}
 * }}
 */
export const rotatePresetDetails = {
  // The name of the preset.
  name: PRESET_ROTATE,

  // A human friendly name for the preset.
  humanName: 'Rotate',

  // The element names that this preset can be used for or true for all elements.
  elements: true,

  // The preset direction: in, out, middle. This is used in the UI logic.
  flow: 'middle',

  // Each property that will change during the transition.
  details: {
    'transform.rotate': {
      // The name of the easing this property will use.
      easing: EASINGS.linear,

      // Parse false turns off parsing on-the-fly variables from the value items.
      parse: false,

      // The start and end times for the transition of this property.
      time: {
        start: 'start.0',
        end: 'start.1000',
      },

      // The value of the property that will change during the transition.
      values: [0, fullCircle],
    },
  },

  // How this preset can be used in the source (including optional parameters).
  // This will be turned into a JSON string, so no functions can be used.
  template: {
    preset: PRESET_ROTATE,
    options: {},
  },
};

rotatePresetDetails.template.options = lodash.cloneDeep(rotatePresetDetails.details);

// This defines the key in the presets object.
export const presetName = PRESET_ROTATE;

// This defines the value of the presets object.
export default rotatePresetDetails;
