/**
 * The action component.
 *
 * @param {{entityId: (string|string[])}} params
 * @returns {{action: {entityId: (string|string[]), time: number, skipHistory: boolean}}}
 */
export function actionComponent(params) {
  if (!params.entityId) {
    throw new Error('Actions require an entityId parameter.');
  }

  return {
    action: {
      entityId: params.entityId,
      time: Date.now(),
      skipHistory: Boolean(params.skipHistory),
    },
  };
}
