import {observer} from 'mobx-react';
import React, {Component} from 'react';

import {statements, updatedDate} from './policyStatements';

import './privacyPolicyPage.scss';

/**
 * The PrivacyPolicyPage component.
 */
export class PrivacyPolicyPage extends Component {
  /**
   * Renders a single statement.
   *
   * @param {{statement: (string|{}), answer: (string|{})}} statement
   * @returns {{}}
   */
  renderStatement = (statement) => {
    if (!statement) {
      return null;
    }

    const {title, text, type} = statement;

    return (
      <div className="statement-wrapper row">
        <div className="col">
          <div className="statement-label">
            {title}
          </div>
          <div className="statement-answer">
            {(type === 'list') && (
              <ul>
                {text.map((listItemText) => (
                  <li key={listItemText}>{listItemText}</li>
                ))}
              </ul>
            )}

            {(type === 'paragraphs') && (
              text.map((paragraphText) => (
                <p key={paragraphText}>{paragraphText}</p>
              ))
            )}

            {(!type) && (
              <p>{text}</p>
            )}
          </div>
        </div>
      </div>
    );
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    return (
      <div id="privacy-policy-page">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="page-title">Privacy Policy</h2>
            </div>
          </div>

          {statements.map((statement) => (
            this.renderStatement(statement)
          ))}

          <div className="last-updated row">
            <div className="col">
              Last Updated {updatedDate}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PrivacyPolicyPage.propTypes = {};

export default observer(PrivacyPolicyPage);
