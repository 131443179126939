/**
 * The icon component.
 *
 * @param {string} type
 * @param {?string} library
 * @returns {{icon: {library: ?string, type: string}}}
 */
export function iconComponent(type, library) {
  return {
    icon: {
      library,
      type,
    },
  };
}

/**
 * Gets the icon component from the source item.
 *
 * @param {{icon: {library: ?string, type: string}}} item
 * @returns {{icon: {library: ?string, type: string}}}
 */
export function getIconFromSource(item) {
  if (!item.icon) {
    return {};
  } else if (!item.icon.type) {
    throw new Error('Invalid icon type for iconComponent.');
  }

  const icon = item.icon;

  return iconComponent(
    icon.type,
    icon.library || 'fa'
  );
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {{}} entity
 * @returns {{}}
 */
export function getIconForSource(entity) {
  if (!entity.has('icon')) {
    return {};
  }

  const icon = entity.get('icon');
  return {
    icon: {
      library: icon.library,
      type: icon.type,
    },
  };
}
