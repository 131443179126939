import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCogs, faEllipsisH, faTurnDown} from '@fortawesome/free-solid-svg-icons';
import {faClone, faFolder, faTrashCan} from '@fortawesome/free-regular-svg-icons';

import {SelectFolderModal} from '../../../selectFolder/SelectFolderModal';
import inject from '../../../../hoc/injectHoc';
import ConfirmModal from '../../../../modals/confirm/ConfirmModal';

import './dropdownActions.scss';
import {FILE_TYPE_MP4} from '../../../../../constants/fileTypeConstants';

/**
 * The DropdownActions component.
 */
export class DropdownActions extends React.Component {
  /**
   * Whether or not the delete modal is open.
   *
   * @type {boolean}
   */
  @observable isDeleteModalOpen = false;

  /**
   * Whether or not the clone modal is open.
   *
   * @type {boolean}
   */
  @observable isCloneModalOpen = false;

  /**
   * Whether or not the move modal is open.
   *
   * @type {boolean}
   */
  @observable isMoveModalOpen = false;

  /**
   * Whether or not the tooltip is open.
   *
   * @type {boolean}
   */
  @observable isDropdownOpen = false;

  /**
   * Toggles the dropdown.
   */
  @action onDropDownToggle = () => {
    this.isDropdownOpen = !this.isDropdownOpen;
  };

  /**
   * Closes the delete modal.
   */
  @action closeDeleteModal = () => {
    this.isDeleteModalOpen = false;
  };

  /**
   * Opens the delete confirm modal.
   * Triggered when the delete button is clicked.
   */
  @action onDelete = () => {
    this.isDeleteModalOpen = true;
  };

  /**
   * Deletes the item on the server.
   * Triggered when the delete modal is confirmed.
   *
   * @param {boolean} wasConfirmed
   */
  onDeleteCompleted = (wasConfirmed) => {
    this.closeDeleteModal();

    if (!wasConfirmed) {
      return;
    }

    const {
      collectionId,
      content,
      folderId,
      onDelete,
      /** @type {ApiContentUnPublishStore} */ apiContentUnPublishStore,
      /** @type {ApiContentDeleteStore} */ apiContentDeleteStore,
    } = this.props;

    const afterDelete = () => {
      this.closeDeleteModal();

      if (onDelete) {
        onDelete();
      }
    };

    if (content.isGlobal) {
      apiContentUnPublishStore.makeRequest(content.id, collectionId);

      apiContentUnPublishStore.getPromise().then(afterDelete);
    } else {
      apiContentDeleteStore.makeRequest(content.id, folderId);

      apiContentDeleteStore.getPromise().then(afterDelete);
    }
  };

  /**
   * Starts the render process.
   * Triggered when the render button is clicked.
   */
  @action onRender = () => {
    const {
      content,
      /** @type {ApiContentRenderStore} */ apiContentRenderStore
    } = this.props;

    // default preview to mp4
    apiContentRenderStore.makeRequest(content.id, FILE_TYPE_MP4, 0);
  };

  /**
   * Open the move content modal.
   * Triggered when the move button is clicked.
   */
  @action onMove = () => {
    this.isMoveModalOpen = true;
  };

  /**
   * Moves the template into a new template.
   * Triggered when the move modal is moved.
   *
   * @param {{}} params
   * @param {?number} params.folderId
   */
  @action onMoveCompleted = ({folderId}) => {
    this.isMoveModalOpen = false;

    if (!folderId) {
      return;
    }

    const {
      content,
      onMove,
      /** @type {ApiContentMoveStore} */ apiContentMoveStore,
    } = this.props;

    apiContentMoveStore.makeRequest(content.id, folderId, content.contentFolderId);

    if (onMove) {
      apiContentMoveStore.getPromise().then(() => {
        onMove(folderId);
      });
    }
  };

  /**
   * Open the clone content modal.
   * Triggered when the clone button is clicked.
   */
  @action onClone = () => {
    this.isCloneModalOpen = true;
  };

  /**
   * Clones the template into a new template.
   * Triggered when the clone modal is cloned.
   *
   * @param {{}} params
   * @param {?number} params.folderId
   * @param {?string} params.contentName
   */
  @action onCloneCompleted = ({folderId, contentName}) => {
    this.isCloneModalOpen = false;

    if (!contentName || !folderId) {
      return;
    }

    const {
      content,
      onClone,
      /** @type {ApiContentCreateStore} */ apiContentCreateStore,
    } = this.props;

    apiContentCreateStore.cloneContent(content, contentName, folderId);

    if (onClone) {
      apiContentCreateStore.getPromise().then((newContentData) => {
        onClone(newContentData.id, folderId);
      });
    }
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {canBeDeleted, collectionId, content, user} = this.props;

    const canDesign = Boolean(user && user.canDesign);
    const isGlobal = content.isGlobal;

    if (isGlobal && !canDesign) {
      return null;
    }

    const isRenderDisabled = (content.displayStatus === 'processing');

    return (
      <div className="dropdown-actions d-inline-block ml-2">
        <Dropdown isOpen={this.isDropdownOpen} toggle={this.onDropDownToggle}>
          <DropdownToggle className="btn btn-link btn-icon dropdown-actions-open" tag="button">
            <FontAwesomeIcon
              className="icon-bold"
              icon={faEllipsisH}
            />
          </DropdownToggle>

          <span className="dropdown-filler" />

          <DropdownMenu right>
            {(!isGlobal) && (
              <>
                {(canBeDeleted) && (
                  <DropdownItem onClick={this.onDelete}>
                    <FontAwesomeIcon
                      className="mr-2"
                      icon={faTrashCan}
                    />
                    Delete
                  </DropdownItem>
                )}

                <DropdownItem onClick={this.onMove}>
                  <FontAwesomeIcon
                    className="mr-2"
                    icon={faFolder}
                  />
                  Move
                </DropdownItem>

                <DropdownItem onClick={this.onClone}>
                  <FontAwesomeIcon
                    className="mr-2"
                    icon={faClone}
                  />
                  Clone
                </DropdownItem>
              </>
            )}

            {(canBeDeleted && collectionId && isGlobal) && (
              <DropdownItem onClick={this.onDelete}>
                <FontAwesomeIcon
                  className="mr-2"
                  icon={faTurnDown}
                />
                Un-Publish
              </DropdownItem>
            )}

            {(!content.isUserMade) && (
              <DropdownItem disabled={isRenderDisabled} onClick={this.onRender}>
                <FontAwesomeIcon
                  className="mr-2"
                  icon={faCogs}
                />
                Render Preview {(isRenderDisabled) ? '(In Progress)' : ''}
              </DropdownItem>
            )}
          </DropdownMenu>
        </Dropdown>

        {(canBeDeleted && this.isDeleteModalOpen) && (
          <ConfirmModal
            isOpen={true}
            onComplete={this.onDeleteCompleted}
            title="Delete this Content"
            confirmText={
              `Are you sure you want to ${(isGlobal) ? 'un-publish' : 'delete'} the '${content.name}' content?`
            }
            isYesNo={true}
          />
        )}

        <SelectFolderModal
          isOpen={Boolean(!isGlobal && this.isCloneModalOpen)}
          onComplete={this.onCloneCompleted}
        />

        <SelectFolderModal
          isOpen={Boolean(!isGlobal && this.isMoveModalOpen)}
          onComplete={this.onMoveCompleted}
          askForName={false}
        />
      </div>
    );
  }
}

DropdownActions.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isGlobal: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,

  apiContentCreateStore: MobxPropTypes.observableObject,
  apiContentDeleteStore: MobxPropTypes.observableObject,
  apiContentFolderGetContentStore: MobxPropTypes.observableObject,
  apiContentMoveStore: MobxPropTypes.observableObject,
  apiContentRenderStore: MobxPropTypes.observableObject,
  apiContentUnPublishStore: MobxPropTypes.observableObject,
  canBeDeleted: PropTypes.bool,
  collectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  folderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClone: PropTypes.func,
  onDelete: PropTypes.func,
  onMove: PropTypes.func,
  onRename: PropTypes.func,
  user: PropTypes.object,
};

DropdownActions.defaultProps = {
  canBeDeleted: false,
};

DropdownActions.wrappedComponent = {};
DropdownActions.wrappedComponent.propTypes = {
  apiContentCreateStore: MobxPropTypes.observableObject.isRequired,
  apiContentDeleteStore: MobxPropTypes.observableObject.isRequired,
  apiContentFolderGetContentStore: MobxPropTypes.observableObject.isRequired,
  apiContentMoveStore: MobxPropTypes.observableObject.isRequired,
  apiContentRenderStore: MobxPropTypes.observableObject.isRequired,
  apiContentUnPublishStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(DropdownActions)(
  observer(DropdownActions)
);
