import React from 'react';

import './maintenanceModeBar.scss';

/**
 * The MaintenanceModeBar component.
 */
class MaintenanceModeBar extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    return (
      <div className="maintenance-message-bar m-0 row no-gutters">
        <div className="col-12 col-md-3 col-sm d-flex justify-content-between align-items-center px-3 py-2 bg-warning">
          <span className="text-black-50 font-weight-bold">Maintenance Mode</span>
        </div>
        <div className="col d-flex flex-wrap px-3 py-2 bg-warning">
          <div className="d-flex align-items-center mr-3">
            Project Content is currently undergoing maintenance. We apologize for the inconvenience and expect to be back up in 2 hours.
          </div>
        </div>
      </div>
    );
  }
}

export default MaintenanceModeBar;
