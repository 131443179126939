import {action, observable, runInAction} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import inject from '../../hoc/injectHoc';

import './durationControls.scss';

/**
 * Converts milliseconds to seconds.
 * @const {number}
 */
const TO_SECONDS_DIVISOR = 1000;

/**
 * The maximum value allowed for the time (in milli-seconds).
 * @const {number}
 */
const MAX_ALLOWED_TIME = 15000;

/**
 * The DurationControls component.
 */
export class DurationControls extends React.Component {
  /**
   * The duration of the video.
   *
   * @type {number}
   */
  @observable duration = 0;

  /**
   * The pending duration.
   *
   * @type {?number}
   */
  @observable pendingDuration = null;

  /**
   * @constructor
   * @param {{}} props
   * @param {{}} componentContext
   */
  constructor(props, componentContext) {
    super(props, componentContext);

    runInAction(() => {
      this.duration = Math.ceil(this.props.timer.maxTime / TO_SECONDS_DIVISOR);
    });
  }

  /**
   * Triggered when the component reacts to MobX changes.
   */
  @action componentWillReact() {
    const {
      /** @type {GameTimerStore} */ timer,
    } = this.props;

    const gameTimeSeconds = Math.round(timer.maxTime / TO_SECONDS_DIVISOR);

    if (!this.pendingDuration && this.duration !== gameTimeSeconds) {
      this.duration = gameTimeSeconds;
    }
  }

  /**
   * Updates the video duration.
   */
  @action onFinishChange = () => {
    const {
      /** @type {GameStore} */ game,
      /** @type {GameTimerStore} */ timer,
    } = this.props;

    const newTime = this.pendingDuration * TO_SECONDS_DIVISOR;

    this.pendingDuration = null;

    if (newTime < 1) {
      // The new time in invalid, so set it back to the previous time.
      return;
    }

    // Make sure the time does not exceed the max allowed time.
    const safeNewTime = (newTime <= MAX_ALLOWED_TIME) ? newTime : MAX_ALLOWED_TIME;
    if (safeNewTime === timer.maxTime) {
      return;
    }

    timer.setMaxTime(safeNewTime);
    game.setEndTime(safeNewTime);

    this.updateAllSources(safeNewTime);
  };

  /**
   * Updates the duration for all the current sources.
   *
   * @param {number} newEndTime
   */
  updateAllSources(newEndTime) {
    const {
      /** @type {DisplayEditorStore} */ displayEditorStore,
    } = this.props;

    if (!displayEditorStore.sources || !displayEditorStore.sources.size) {
      return;
    }

    displayEditorStore.sources.forEach((sourceData) => {
      if (sourceData.endTime === 'undefined') {
        return;
      }

      sourceData.endTime = newEndTime;
    });
  }

  /**
   * Updates the duration value.
   *
   * @param {{}} changeEvent
   */
  @action onChangeDuration = (changeEvent) => {
    this.pendingDuration = changeEvent.target.value;
  };

  /**
   * Prevents the input from responding to scrolling.
   *
   * @param {{preventDefault: function}} scrollEvent
   */
  onInputScroll = (scrollEvent) => {
    scrollEvent.preventDefault();
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      /** @type {GameTimerStore} */ timer,
    } = this.props;

    timer.maxTime; // eslint-disable-line no-unused-expressions

    const duration = (this.pendingDuration != null) ? this.pendingDuration : this.duration;

    return (
      <div className="duration-controls">
        <input
          className="duration-input"
          min="0"
          type="number"
          value={duration}
          onChange={this.onChangeDuration}
          onBlur={this.onFinishChange}
          onWheel={this.onInputScroll}
        />
      </div>
    );
  }
}

DurationControls.propTypes = {
  displayEditorStore: MobxPropTypes.observableObject,
  game: MobxPropTypes.observableObject,
  timer: MobxPropTypes.observableObject,
};

DurationControls.wrappedComponent = {};
DurationControls.wrappedComponent.propTypes = {
  displayEditorStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(DurationControls)(
  observer(DurationControls)
);
