import lodash from 'lodash';
import {action, toJS} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import PrestoDraftButton from '../prestoDraftButton/PrestoDraftButton';
import PrestoSaveButton from '../prestoSaveButton/PrestoSaveButton';
import PrestoEntity from '../prestoEntity/PrestoEntity';
import inject from '../../../../hoc/injectHoc';
import {DEMO_STORAGE_NAME} from '../../../../../constants/demoConstants';
import {actionInteractionComponent} from '../../../../../display/components/action/actionInteractionComponent';
import {getSourceFromGame} from '../../../../../display/game';

import './prestoBottomBar.scss';

/**
 * The PrestoBottomBar component.
 */
export class PrestoBottomBar extends React.Component {
  /**
   * Triggered when the entity is first mounted to the page.
   */
  componentDidMount() {
    const {game, skipToEnd} = this.props;
    if (!game) {
      return;
    }

    const reversedEntities = this.getReversedEntities();

    let activateEntity;
    if (skipToEnd) {
      activateEntity = lodash.findLast(reversedEntities, this.isEntityEditable);
    } else {
      activateEntity = lodash.find(reversedEntities, this.isEntityEditable);
    }

    if (activateEntity) {
      this.activateEntity(activateEntity);
    }
  }

  /**
   * Triggered when the entity reacts to mobX changes.
   */
  componentWillReact() {
    const {game} = this.props;
    if (!game) {
      return;
    }

    const reversedEntities = this.getReversedEntities();

    const activeEntity = lodash.find(reversedEntities, this.isEntityActive);
    if (activeEntity) {
      return;
    }

    const firstEntity = lodash.find(reversedEntities, this.isEntityEditable);
    if (firstEntity) {
      this.activateEntity(firstEntity);
    }
  }

  /**
   * Gets the reversed entities.
   *
   * @returns {Array}
   */
  getReversedEntities = () => {
    const {game} = this.props;
    if (!game) {
      return [];
    }

    const entities = game.entities.peek();
    if (!entities || !Array.isArray(entities)) {
      return [];
    }

    return entities.slice(0).reverse();
  };

  /**
   * Returns true if the entity is editable.
   *
   * @param {ObservableMap} entity
   * @returns {boolean}
   */
  isEntityEditable = (entity) => {
    if (!entity.has('compose')) {
      return false;
    }

    const compose = entity.get('compose');
    return Boolean(compose.canEdit && compose.variableName);
  };

  /**
   * Returns true if the entity is active.
   *
   * @param {ObservableMap} entity
   * @returns {boolean}
   */
  isEntityActive = (entity) => {
    if (!entity.has('interaction')) {
      return false;
    }

    return Boolean(entity.get('interaction').isActive);
  };

  /**
   * Activates the first entity.
   *
   * @param {ObservableMap} entity
   */
  activateEntity = (entity) => {
    const {game, timer} = this.props.displayEditorStore;

    const actionParams = {
      entityId: entity.get('id'),
      skipHistory: true,
    };

    game.addAction(actionParams, actionInteractionComponent(true, false, false));

    const activeTime = entity.get('time').active;
    timer.setTime(activeTime);
  };

  /**
   * Saves a new content to the database including variables and source.
   *
   * @param {?number} folderId
   * @param {string} contentName
   * @param {boolean} isDraft
   * @returns {Promise}
   */
  saveNewTemplate = (folderId, contentName, isDraft) => {
    const {content, apiContentCreateStore, displayEditorStore} = this.props;

    const {game} = displayEditorStore;

    // Update the current source before saving all the sources.
    displayEditorStore.setSource(displayEditorStore.currentAspectRatio, getSourceFromGame(game));

    const sources = toJS(displayEditorStore.sources);
    const variables = toJS(displayEditorStore.variables);

    apiContentCreateStore.makeRequest({
      contentFolderId: folderId,
      fromContentId: content.id,
      duration: game.endTime,
      height: content.height,
      name: contentName,
      sources,
      variables,
      width: content.width,
      isDraft: Boolean(isDraft),
      isUserMade: content.isUserMade,
    });
    return apiContentCreateStore.getPromise();
  };

  /**
   * Removes any items from the variables that are not used in any sources.
   *
   * @param {Object.<string, {}>} sources
   * @param {Object.<string, {}>} variables
   * @returns {Object.<string, {}>}
   */
  cleanUpVariables = (sources, variables) => {
    const activeVariables = {};
    lodash.forEach(sources || [], (sourceData) => {
      lodash.forEach(sourceData.entities || [], (entity) => {
        const compose = entity.compose;
        const element = entity.type;

        if (!compose || !compose.variableName) {
          return;
        }

        const path = `${element}.${compose.variableName}`;
        activeVariables[path] = true;
      });
    });

    const cleanVariables = {};
    lodash.forEach(variables, (groupItems, groupName) => {
      lodash.forEach(groupItems, (variableValue, variableName) => {
        const path = `${groupName}.${variableName}`;
        if (!lodash.get(activeVariables, path, false)) {
          return;
        }

        // must use setWith and Object customizer
        // otherwise if variableName is a number lodash incorrectly guesses array structure
        lodash.setWith(cleanVariables, path, variableValue, Object);
      });
    });

    return cleanVariables;
  };

  /**
   * Updates content including variables and source.
   *
   * @param {boolean} isDraft
   * @returns {Promise}
   */
  updateTemplate = (isDraft) => {
    const {content, apiContentUpdateStore, displayEditorStore} = this.props;

    const {game} = displayEditorStore;

    // Update the current source before saving all the sources.
    displayEditorStore.setSource(displayEditorStore.currentAspectRatio, getSourceFromGame(game));

    const sources = toJS(displayEditorStore.sources);
    const variables = toJS(displayEditorStore.variables);

    const cleanVariables = this.cleanUpVariables(sources, variables);

    apiContentUpdateStore.updateActiveContentSource(
      content.id,
      sources,
      cleanVariables,
      false, // set template to not free, since this is a user saving their own copy of the template
      Boolean(isDraft),
      content.isUserMade,
    );

    return apiContentUpdateStore.getPromise();
  };

  /**
   * Triggered when the user clicks to sign up.
   *
   * @param {{}} clickEvent
   */
  @action onSignUpClick = (clickEvent) => {
    clickEvent.preventDefault();

    const {content, onSignUp, displayEditorStore} = this.props;

    const variables = toJS(displayEditorStore.variables);

    localStorage.setItem(DEMO_STORAGE_NAME, JSON.stringify(
      {id: content.id, variables}
    ));

    onSignUp();
  };

  /**
   * Triggered when the user clicks to upgrade.
   *
   * @param {{}} clickEvent
   */
  @action onUpgradeClick = (clickEvent) => {
    clickEvent.preventDefault();

    const {content, onUpgrade, displayEditorStore} = this.props;

    const variables = toJS(displayEditorStore.variables);

    localStorage.setItem(DEMO_STORAGE_NAME, JSON.stringify(
      {id: content.id, variables}
    ));

    onUpgrade();
  };

  /**
   * Triggered when the user clicks on the next button.
   *
   * @param {{}} clickEvent
   */
  onNextClick = (clickEvent) => {
    clickEvent.preventDefault();

    const reversedEntities = this.getReversedEntities();

    const activeIndex = lodash.findIndex(reversedEntities, this.isEntityActive);
    const newIndex = activeIndex + 1;

    if (newIndex + 1 > reversedEntities.length) {
      return;
    }

    const newEntity = lodash.find(reversedEntities, this.isEntityEditable, newIndex);

    this.activateEntity(newEntity);
  };

  /**
   * Triggered when the user clicks on the back button.
   */
  onBackClick = () => {
    const reversedEntities = this.getReversedEntities();

    const activeIndex = lodash.findIndex(reversedEntities, this.isEntityActive);
    const newIndex = activeIndex - 1;

    if (newIndex < 0) {
      return;
    }

    const newEntity = lodash.findLast(reversedEntities, this.isEntityEditable, newIndex);

    this.activateEntity(newEntity);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {content, game, onCreated, user, apiContentCreateStore} = this.props;

    const reversedEntities = this.getReversedEntities();
    const editableEntities = reversedEntities.filter(this.isEntityEditable);
    const activeIndex = lodash.findIndex(editableEntities, this.isEntityActive);
    const isActiveLastEntity = (activeIndex + 1 === editableEntities.length);
    const isActiveFirstEntity = (activeIndex === 0);
    const isBasicUser = user.plan && user.plan.name === 'Basic';
    const userHasCredits = user.projectContentCompany && user.projectContentCompany.downloadCredits > 0;

    const signManufacturerIncludesFreeTemplates = lodash.get(user, 'company.signManufacturer.includeFreeTemplates', false);

    let saveButton = null;
    if (isActiveLastEntity) {
      if (!user) {
        saveButton = (
          <button
            className="btn btn-secondary action-button save-button"
            type="button"
            onClick={this.onSignUpClick}
          >Sign Up To Download</button>
        );
      } else if (isBasicUser || (signManufacturerIncludesFreeTemplates && !userHasCredits)) {
        saveButton = (
          <button
            className="btn btn-secondary action-button save-button"
            type="button"
            onClick={this.onUpgradeClick}
          >Upgrade Plan To Download</button>
        );
      } else {
        saveButton = (
          <>
            <PrestoDraftButton
              content={content}
              onCreated={onCreated}
              saveNewTemplate={this.saveNewTemplate}
              updateTemplate={this.updateTemplate}
            />

            <PrestoSaveButton
              disabled={!userHasCredits}
              content={content}
              onCreated={onCreated}
              saveNewTemplate={this.saveNewTemplate}
              updateTemplate={this.updateTemplate}
              data-cy="presto-bottom-bar-save-button"
            />
          </>
        );
      }
    } else {
      saveButton = (
        <button
          className="btn btn-secondary action-button next-button"
          type="button"
          onClick={this.onNextClick}
          data-cy="presto-bottom-bar-next-button"
        >Next</button>
      );
    }

    return (
      <div
        id="presto-bottom-bar"
        className="system-bottom-bar"
        data-cy="presto-bottom-bar"
      >
        <div className="bottom-bar-entities">
          {(editableEntities.length) ? (
            <div className="entity-list">
              {editableEntities.map((entity, index) => {
                const isFirst = (index === 0);
                const isLast = (index === (editableEntities.length - 1));

                return (
                  <PrestoEntity
                    key={entity.get('id')}
                    entity={entity}
                    entityOrder={index + 1}
                    game={game}
                    isFirst={isFirst}
                    isLast={isLast}
                    isPrev={index < activeIndex}
                  />
                );
              })}
            </div>
          ) : (
            <div>No editable items.</div>
          )}
        </div>

        <div className="bottom-bar-actions">
          {(!isActiveFirstEntity && editableEntities.length > 1) && (
            <button
              className="btn btn-outline-secondary action-button back-button"
              type="button"
              onClick={this.onBackClick}
            >Back</button>
          )}

          {apiContentCreateStore.case({
            pre: () => saveButton,
            fulfilled: () => saveButton,
            rejected: () => saveButton,
            pending: () => (
              <button
                className="btn btn-primary btn-lg btn-block action-button save-button"
                type="button"
                disabled={true}
              >Saving...</button>
            )
          })}
        </div>
      </div>
    );
  }
}

PrestoBottomBar.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  game: MobxPropTypes.observableObject.isRequired,
  onCreated: PropTypes.func.isRequired,
  onSignUp: PropTypes.func.isRequired,
  onUpgrade: PropTypes.func.isRequired,

  apiContentCreateStore: MobxPropTypes.observableObject,
  apiContentUpdateStore: MobxPropTypes.observableObject,
  displayEditorStore: MobxPropTypes.observableObject,
  skipToEnd: PropTypes.bool,
  user: MobxPropTypes.observableObject,
};

PrestoBottomBar.wrappedComponent = {};
PrestoBottomBar.wrappedComponent.propTypes = {
  apiContentCreateStore: MobxPropTypes.observableObject.isRequired,
  apiContentUpdateStore: MobxPropTypes.observableObject.isRequired,
  displayEditorStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(PrestoBottomBar)(
  observer(PrestoBottomBar)
);
