import {toJS} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import CropCircle from './CropCircle';
import CropEllipse from './CropEllipse';
import CropRectangle from './CropRectangle';
import CropTriangle from './CropTriangle';
import entityActivateHoc from '../../../hoc/entityActivateHoc';
import {CIRCLE, ELLIPSE, RECTANGLE, TRIANGLE} from '../../../../display/components/common/cropShapeComponent';
import {getPixelFromPercentage, getPositionLeftAndTop} from '../../../../display/ecs/entityHelper';
import {onClickEntityEvent} from '../../utils';

/**
 * Maps the crop shape names to their components.
 * @const {object.<string, Object>}
 */
const CROP_SHAPE_MAP = {
  [CIRCLE]: CropCircle,
  [ELLIPSE]: CropEllipse,
  [RECTANGLE]: CropRectangle,
  [TRIANGLE]: CropTriangle,
};

/**
 * The ImageGroup component.
 */
export class ImageGroup extends React.Component {
  cropLocation = {
    height: 0,
    width: 0,
    top: 0,
    left: 0
  }
  isFirstRun = true

  /**
   * Gets the cropping data for the image.
   *
   * @param {number} imageX
   * @param {number} imageY
   * @returns {{clipPathProperty: {}, cropId: ?number, cropOptions: ?{}, CropShape: ?Object}}
   */
  getCropData = (imageX, imageY) => {
    const {entity} = this.props;
    const cropComponent = entity.get('crop');

    const cropData = {
      clipPathProperty: {},
      cropId: null,
    };

    if (!cropComponent) {
      return cropData;
    }

    const cropShapeComponent = entity.get('cropShape');
    const image = entity.get('image');

    const cropId = `crop-image-${entity.get('id')}`;
    const cropShapeName = (cropShapeComponent) ? cropShapeComponent.name : 'rectangle';

    // bypass static crop (alternate)
    this.cropLocation.height = getPixelFromPercentage(image.height, cropComponent.height);
    this.cropLocation.width = getPixelFromPercentage(image.width, cropComponent.width);
    this.cropLocation.top = imageY + getPixelFromPercentage(image.height, cropComponent.y);
    this.cropLocation.left = imageX + getPixelFromPercentage(image.width, cropComponent.x);

    const cropOptions = {
      ...toJS(cropShapeComponent),
      height: this.cropLocation.height,
      width: this.cropLocation.width,
      top: this.cropLocation.top,
      left: this.cropLocation.left
    };

    return {
      cropId,
      CropShape: CROP_SHAPE_MAP[cropShapeName],
      cropOptions,
      clipPathProperty: {
        clipPath: `url(#${cropId})`,
      },
    };
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, game, offsetLeft, offsetTop, style, onEntityClick} = this.props;

    const image = entity.get('image');
    if (!image.url) {
      return null;
    } else if (image.heightIsPercent || image.widthIsPercent || image.xIsPercent || image.yIsPercent) {
      // After undo/redo this can run before the image dimensions are converted to pixels, which causes bugs here.
      return null;
    }

    const imageSource = image.url;

    const gameResolution = game.resolution;
    const alignment = entity.get('position').alignment;
    const imageStyle = getPositionLeftAndTop(alignment, image, image, game);

    const centerX = offsetLeft + imageStyle.left + (image.width / 2);
    const centerY = offsetTop + imageStyle.top + (image.height / 2);

    const rotationStyle = {
      transform: (style.transform) ? String(style.transform) : '',
      WebkitTransform: (style.transform) ? String(style.transform) : '',
      transformOrigin: `${centerX}px ${centerY}px 0px`,
    };

    const imageX = (imageStyle.left || 0) + gameResolution.width;
    const imageY = (imageStyle.top || 0) + gameResolution.height;

    const {clipPathProperty, cropId, cropOptions, CropShape} = this.getCropData(imageX, imageY);

    const onClickEvent = onClickEntityEvent(game, entity, onEntityClick);

    return (
      <g
        className="image-group rotation-group"
        {...clipPathProperty}
        style={rotationStyle}
        pointerEvents="visible"
      >
        {(cropId && CropShape) && (
          <CropShape cropId={cropId} cropOptions={cropOptions} />
        )}

        <image
          x={imageX}
          y={imageY}
          width={image.width}
          height={image.height}
          xlinkHref={imageSource}
          preserveAspectRatio="xMidYMid meet"
          style={{
            cursor: onClickEvent ? 'pointer' : 'inherit',
            pointerEvents: 'auto',
          }}
          onClick={onClickEvent}
        />
      </g>
    );
  }
}

ImageGroup.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
  style: PropTypes.object.isRequired,

  offsetLeft: PropTypes.number,
  offsetTop: PropTypes.number,
  onEntityClick: PropTypes.func,
};

ImageGroup.defaultProps = {
  offsetLeft: 0,
  offsetTop: 0,
};

export default entityActivateHoc(
  observer(ImageGroup)
);
