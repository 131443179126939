import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {observable, action} from 'mobx';
import React from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import inject from '../../hoc/injectHoc';

import TransitionItemList from './components/transitionLibraryList/TransitionLibraryItemList';
import {TRANSITION_LIBRARY_ITEMS} from './components/transitionLibraryList/transitionItems';

import './transitionLibraryModal.scss';

const FLOW_DIRECTIONS = ['in', 'out', 'both'];

const DELAY_MS = 500;

/**
 * The TransitionLibraryModal component.
 */
class TransitionLibraryModal extends React.Component {
  /**
   * The selected transition key references.
   *
   * @type {{
   *  transition: string,
   *  direction: string,
   * } | null}
   */
  @observable selectedTransitionKeys = null;

  // {
  //   transition: 'easeEdge',
  //   direction: 'bottom',
  // }

  // TRANSITION_LIBRARY_ITEMS['easeEdge']['bottom']['in']

  /**
   * Set the selected transition.
   *
   * @param {{}} transitionKeys
   */
  @action onSelectTransitionKeys = (transitionKeys) => {
    this.selectedTransitionKeys = transitionKeys;
  }

  /**
   * Save the selected transition
   * @param {string} flowDirection
   */
  onSaveSelectedTransition = (flowDirection) => {
    const {onComplete, onCancelModal} = this.props;
    const transition = TRANSITION_LIBRARY_ITEMS[this.selectedTransitionKeys.transition].presets[this.selectedTransitionKeys.direction];

    const inTransition = {
      ...transition.in,
      details: {
        ...transition.in.details,
        flow: 'in'
      }
    };

    const outTransition = {
      ...transition.out,
      details: {
        ...transition.out.details,
        flow: 'out'
      }
    };

    switch (flowDirection) {
      case 'in':
        onComplete(inTransition);
        break;
      case 'out':
        onComplete(outTransition);
        break;
      case 'both':
        onComplete(inTransition);

        // Create a small delay to give time for the first animation to apply.
        setTimeout(() => {
          onComplete(outTransition);
        }, DELAY_MS);
        break;
      default:
        break;
    }

    onCancelModal();
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {isOpen, entity, onCancelModal} = this.props;

    if (!isOpen) {
      return null;
    }

    return (
      <>
        <Modal
          isOpen={true}
          toggle={onCancelModal}
          className="transition-library-modal"
          centered
        >
          <ModalHeader toggle={onCancelModal}>Animation Effects</ModalHeader>
          <ModalBody>
            <div className="modal-body-content-wrapper">
              <TransitionItemList
                entity={entity}
                flow={this.flowDirection}
                onSelect={this.onSelectTransitionKeys}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            {FLOW_DIRECTIONS.map((flow) => {
              return (<Button
                key={flow}
                className="flow-direction-button"
                onClick={() => this.onSaveSelectedTransition(flow)}
                disabled={!this.selectedTransitionKeys}
              >
                {flow}
              </Button>);
            })}
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

TransitionLibraryModal.propTypes = {
  entity: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCancelModal: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
};

TransitionLibraryModal.defaultProps = {
};

TransitionLibraryModal.wrappedComponent = {};
TransitionLibraryModal.wrappedComponent.propTypes = {
};

export default inject(TransitionLibraryModal)(
  observer(TransitionLibraryModal)
);
