import {runInAction} from 'mobx';

import {updateEntity} from '../ecs/entityHelper';

/**
 * The name of the system.
 * @const {string}
 */
export const VISIBILITY_SYSTEM = 'visibilitySystem';

/**
 * Gets a new instance of the visibility system.
 *
 * @returns {{name: string, update: systemUpdate}}
 */
export function visibilitySystem() {
  /**
   * Called when the game loop updates.
   *
   * @param {Array.<{}>} entities
   * @param {number} time
   */
  function systemUpdate(entities, time) {
    entities.forEach((entity) => {
      // First check for required components.
      if (!entity.has('visible')) {
        return;
      }

      // Visibility System.
      let isVisible = false;
      if (entity.has('time')) {
        const timeComponent = entity.get('time');
        if (!timeComponent.end && timeComponent.end !== 0) {
          isVisible = Boolean(timeComponent.start <= time);
        } else {
          isVisible = Boolean(timeComponent.start <= time && timeComponent.end >= time);
        }
      }

      if (isVisible === entity.get('visible').isVisible) {
        return;
      }

      // Now update the entity.
      runInAction('visibilitySystemUpdateEntity', () => {
        updateEntity(entity, 'visible', {isVisible});
      });
    });
  }

  return {
    name: VISIBILITY_SYSTEM,
    update: systemUpdate
  };
}
