/* eslint-disable no-magic-numbers */

import lodash from 'lodash';

import {EASINGS} from '../../ecs/easingHelper';
import wordCrissCrossInTopGif from '../../../assets/images/transitions/wordCrissCrossInTop.gif';

/**
 * The name that signals this preset.
 * @const {string}
 */
export const PRESET_WORD_CRISS_CROSS_OUT_TOP = 'wordCrissCrossOutTop';

/**
 * The preset details.
 *
 * @type {{
 *   name: string,
 *   flow: string,
 *   details: Object.<string, {
 *     easing: string,
 *     parse: boolean,
 *     values: *|Array.<*>,
 *     time: {start: (string|number), end: (string|number)}
 *   }>,
 *   template: {}
 * }}
 */
export const wordCrissCrossOutTopPresetDetails = {
  // The name of the preset.
  name: PRESET_WORD_CRISS_CROSS_OUT_TOP,

  // A human friendly name for the preset.
  humanName: 'Word - Criss Cross Out - Top',

  // A preview of the transition.
  gif: wordCrissCrossInTopGif,

  // The element names that this preset can be used for or true for all elements.
  elements: ['text'],

  // The preset direction: in, out, middle. This is used in the UI logic.
  flow: 'out',

  // Each property that will change during the transition.
  details: {
    'word.opacity': {
      easing: EASINGS.easeInExpo,
      time: {
        start: 'end.-2000',
        end: 'end.0'
      },
      values: [
        1,
        0
      ],
      stagger: true,
      staggerDuration: false,
      immediateRender: false
    },
    'word.translateY': {
      easing: EASINGS.easeInExpo,
      time: {
        start: 'end.-2000',
        end: 'end.0'
      },
      values: [
        0,
        -100
      ],
      stagger: true,
      staggerDuration: false,
      immediateRender: false
    },
    'word.translateX': [
      {
        easing: EASINGS.easeInExpo,
        time: {
          start: 'end.-2000',
          end: 'end.0'
        },
        values: [
          0,
          -100
        ],
        filters: [
          'even'
        ],
        stagger: true,
        staggerDuration: false,
        immediateRender: false
      },
      {
        easing: EASINGS.easeInExpo,
        time: {
          start: 'end.-2000',
          end: 'end.0'
        },
        values: [
          0,
          100
        ],
        filters: [
          'odd'
        ],
        stagger: true,
        staggerDuration: false,
        immediateRender: false
      }
    ],
  },

  // How this preset can be used in the source (including optional parameters).
  // This will be turned into a JSON string, so no functions can be used.
  template: {
    preset: PRESET_WORD_CRISS_CROSS_OUT_TOP,
    options: {},
  },
};

wordCrissCrossOutTopPresetDetails.template.options = lodash.cloneDeep(wordCrissCrossOutTopPresetDetails.details);

// This defines the key in the presets object.
export const presetName = PRESET_WORD_CRISS_CROSS_OUT_TOP;

// This defines the value of the presets object.
export default wordCrissCrossOutTopPresetDetails;

