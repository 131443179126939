import {action} from 'mobx';

import apiCollectionGetAllStore from './apiCollectionGetAllStore';
import ApiBaseStore from '../common/apiBaseStore';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';

/**
 * ApiCollectionDeleteStore
 */
class ApiCollectionDeleteStore extends ApiBaseStore {
  /**
   * Makes the api request.
   *
   * @param {number|string} collectionId
   */
  @action makeRequest(collectionId) {
    if (!collectionId) {
      this.state = STATE_REJECTED;
      this.error = new Error('Invalid collection id given to collectionDelete.');
      return;
    }

    this.state = STATE_PENDING;
    this[EXPIRE_TIME] = Date.now() + EXPIRES_PENDING;

    serverApi.collectionDelete(
      collectionId
    ).then(
      action('collectionDeleteSuccess', () => {
        this.error = null;
        this.state = STATE_FULFILLED;
        this.data = true;
        this[EXPIRE_TIME] = Date.now() + EXPIRES_IN;

        // Clear the collection get all cache.
        apiCollectionGetAllStore.refresh(true);
      }),
      action('collectionDeleteError', (error) => {
        this.error = error;
        this.state = STATE_REJECTED;
        this.data = false;
      })
    );
  }
}

export default new ApiCollectionDeleteStore();
