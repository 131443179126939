export default {
  DAY_ALIGN: '\\',
  HIGH_ALIGN: '\\',
  LOW_ALIGN: '\\',

  DAY_WIDTH_RATIO: 0.31954,
  DAY_HEIGHT_RATIO: 0.06408,
  DAY_ONE_POSITION_X_RATIO: 0.4,
  DAY_ONE_POSITION_Y_RATIO: 0.0648,
  DAY_TWO_POSITION_X_RATIO: 0.4,
  DAY_TWO_POSITION_Y_RATIO: 0.3819,
  DAY_THREE_POSITION_X_RATIO: 0.4,
  DAY_THREE_POSITION_Y_RATIO: 0.7049,

  ICON_WIDTH_RATIO: 0.4667,
  ICON_HEIGHT_RATIO: 0.4438,
  ICON_ONE_POSITION_X_RATIO: -0.06,
  ICON_ONE_POSITION_Y_RATIO: 0.0086,
  ICON_TWO_POSITION_X_RATIO: -0.06,
  ICON_TWO_POSITION_Y_RATIO: 0.3257,
  ICON_THREE_POSITION_X_RATIO: -0.06,
  ICON_THREE_POSITION_Y_RATIO: 0.6487,

  HIGH_WIDTH_RATIO: 0.3226,
  HIGH_HEIGHT_RATIO: 0.1763,
  HIGH_ONE_POSITION_X_RATIO: 0.3959,
  HIGH_ONE_POSITION_Y_RATIO: 0.1205,
  HIGH_TWO_POSITION_X_RATIO: 0.3959,
  HIGH_TWO_POSITION_Y_RATIO: 0.4376,
  HIGH_THREE_POSITION_X_RATIO: 0.3959,
  HIGH_THREE_POSITION_Y_RATIO: 0.7606,

  LOW_WIDTH_RATIO: 0.1814,
  LOW_HEIGHT_RATIO: 0.0855,
  LOW_ONE_POSITION_X_RATIO: 0.7333,
  LOW_ONE_POSITION_Y_RATIO: 0.1928,
  LOW_TWO_POSITION_X_RATIO: 0.7333,
  LOW_TWO_POSITION_Y_RATIO: 0.5099,
  LOW_THREE_POSITION_X_RATIO: 0.7333,
  LOW_THREE_POSITION_Y_RATIO: 0.8329,
};
