/* eslint-disable no-magic-numbers */

import lodash from 'lodash';

import {EASINGS} from '../../ecs/easingHelper';
import wordScaleSnapInBottomGif from '../../../assets/images/transitions/wordScaleSnapInBottom.gif';

/**
 * The name that signals this preset.
 * @const {string}
 */
export const PRESET_WORD_SCALE_SNAP_IN_BOTTOM = 'wordScaleSnapInBottom';

/**
 * The preset details.
 *
 * @type {{
 *   name: string,
 *   flow: string,
 *   details: Object.<string, {
 *     easing: string,
 *     parse: boolean,
 *     values: *|Array.<*>,
 *     time: {start: (string|number), end: (string|number)}
 *   }>,
 *   template: {}
 * }}
 */
export const wordScaleSnapInBottomPresetDetails = {
  // The name of the preset.
  name: PRESET_WORD_SCALE_SNAP_IN_BOTTOM,

  // A human friendly name for the preset.
  humanName: 'Word Scale & Snap In - Bottom',

  // A preview of the transition.
  gif: wordScaleSnapInBottomGif,

  // The element names that this preset can be used for or true for all elements.
  elements: ['text'],

  // The preset direction: in, out, middle. This is used in the UI logic.
  flow: 'in',

  // Each property that will change during the transition.
  details: {
    'word.scaleY': {
      time: {
        end: 'start.2000',
        start: 'start.0'
      },
      easing: EASINGS.easeOutExpo,
      values: [
        0,
        1
      ],
      stagger: 50,
      staggerDuration: false,
      immediateRender: true
    },
    'word.translateY': {
      time: {
        end: 'start.1200',
        start: 'start.0'
      },
      easing: EASINGS.easeInOutExpo,
      values: [
        200,
        0
      ],
      stagger: 50,
      staggerDuration: false,
      immediateRender: true
    },
  },

  // How this preset can be used in the source (including optional parameters).
  // This will be turned into a JSON string, so no functions can be used.
  template: {
    preset: PRESET_WORD_SCALE_SNAP_IN_BOTTOM,
    options: {},
  },
};

wordScaleSnapInBottomPresetDetails.template.options = lodash.cloneDeep(wordScaleSnapInBottomPresetDetails.details);

// This defines the key in the presets object.
export const presetName = PRESET_WORD_SCALE_SNAP_IN_BOTTOM;

// This defines the value of the presets object.
export default wordScaleSnapInBottomPresetDetails;

