import lodash from 'lodash';

import {EASINGS} from '../../ecs/easingHelper';
import letterFadeInEndGif from '../../../assets/images/transitions/letterFadeInEnd.gif';

/**
 * The name that signals this preset.
 * @const {string}
 */
export const PRESET_LETTER_FADE_OUT_START = 'letterFadeOutStart';

/**
 * The preset details.
 *
 * @type {{
 *   name: string,
 *   flow: string,
 *   details: Object.<string, {
 *     easing: string,
 *     parse: boolean,
 *     values: *|Array.<*>,
 *     time: {start: (string|number), end: (string|number)}
 *   }>,
 *   template: {}
 * }}
 */
export const letterFadeOutStartPresetDetails = {
  // The name of the preset.
  name: PRESET_LETTER_FADE_OUT_START,

  // A human friendly name for the preset.
  humanName: 'Letter - Fade Out From Start',

  // A preview of the transition.
  gif: letterFadeInEndGif,

  // The element names that this preset can be used for or true for all elements.
  elements: ['text'],

  // The preset direction: in, out, middle. This is used in the UI logic.
  flow: 'out',

  // Each property that will change during the transition.
  details: {
    'letter.opacity': {
      time: {
        end: 'end.0',
        start: 'end.-1000'
      },
      easing: EASINGS.easeOutPower3,
      values: [
        1,
        0
      ],
      stagger: 50,
      staggerDuration: false,
      immediateRender: false
    },
  },

  // How this preset can be used in the source (including optional parameters).
  // This will be turned into a JSON string, so no functions can be used.
  template: {
    preset: PRESET_LETTER_FADE_OUT_START,
    options: {},
  },
};

letterFadeOutStartPresetDetails.template.options = lodash.cloneDeep(letterFadeOutStartPresetDetails.details);

// This defines the key in the presets object.
export const presetName = PRESET_LETTER_FADE_OUT_START;

// This defines the value of the presets object.
export default letterFadeOutStartPresetDetails;
