import classNames from 'classnames';
import lodash from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faCircle} from '@fortawesome/free-regular-svg-icons';

import RenderProgress from '../renderProgress/RenderProgress';
import inject from '../../hoc/injectHoc';
import LoadingIcon from '../loadingIcon/LoadingIcon';
import prestoLogo from '../../../assets/images/presto.svg';
import {getAssetUrl} from '../../../utils/assetHelper';
import {SUPER_ADMIN_ROLE} from '../../../constants/userConstants';

import './contentItem.scss';

/**
 * The URL to the placeholder template image.
 * @type {string}
 */
const placeholderTemplateUrl = getAssetUrl('placeholder-template.png');

/**
 * Content Item component
 */
class ContentItem extends Component {
  /**
   * Attempt to refresh on mount
   */
  componentDidMount() {
    this.refreshIfNecessary();
  }

  /**
   * Attempt to refresh on update
   *
   * @param {{}} prevProps
   */
  componentDidUpdate(prevProps) {
    const {contentId} = this.props;

    if (prevProps.contentId !== contentId) {
      this.refreshIfNecessary();
    }
  }

  /**
   * Call refresh on the content if it wasn't passed in as a prop
   */
  refreshIfNecessary = () => {
    const {
      content,
      contentId,
      apiContentGetOneStore,
    } = this.props;

    if (!content) {
      apiContentGetOneStore.refresh(contentId);
    }
  };

  /**
   * Renders the item thumbnail for a content item.
   *
   * @param {{thumbnailPath: string}} content
   * @returns {{}}
   */
  renderContentItemThumbnail = (content) => {
    let thumbnailImageUrl = lodash.get(content, 'thumbnailPath');
    if (!thumbnailImageUrl) {
      thumbnailImageUrl = placeholderTemplateUrl;
    }

    return (
      <div className="container-16-9">
        <div
          className="img content-item-thumbnail"
          style={{backgroundImage: `url('${thumbnailImageUrl}')`}}
        />
      </div>
    );
  };

  /**
   * Renders the item draft display for a content item.
   *
   * @returns {{}}
   */
  renderContentItemDraft = () => {
    return (
      <div className="container-16-9">
        <div className="content-item-draft">
          <div className="presto-icon" style={{backgroundImage: `url(${prestoLogo})`}} />
          <div className="draft-text">
            Draft
          </div>
        </div>
      </div>
    );
  };

  /**
   * Renders the item processing display for a content item.
   *
   * @param {boolean=} hasError
   * @returns {{}}
   */
  renderContentItemProcessing = (hasError) => {
    return (
      <div className="container-16-9">
        <div className={classNames('content-item-processing', {'has-error': hasError})}>
          <div className="presto-icon" style={{backgroundImage: `url(${prestoLogo})`}} />
          <div className="draft-text">
            {(hasError) ? 'Presto Ran Into A Problem' : 'Presto Is Working Its Magic...'}
          </div>
        </div>
      </div>
    );
  };

  /**
   * Renders the component.
   *
   * @param {{}} content
   * @returns {{}}
   */
  renderContentItem = (content) => {
    const {
      isActive,
      onClick,
      onSelected,
      apiUserGetMeStore,
      multiSelectAllowed,
      inMultiSelectMode,
      isSelected,
    } = this.props;

    const contentUser = apiUserGetMeStore.getFulfilled();

    // Determine if item is being rendered or had a render error.
    const isProcessing = (content.displayStatus === 'processing');
    const hasRenderingError = (content.displayStatus === 'error');
    const isDraft = Boolean(lodash.get(content, 'isDraft', false));

    // content is free if
    //   content is tagged as free and user not logged in
    //   or content is tagged as free and user has basic plan
    const isFree = (content.isFree && !contentUser)
      || (content.isFree
        && contentUser
        && contentUser.plan
        && (contentUser.plan.name === 'Basic' || contentUser.role === SUPER_ADMIN_ROLE));

    let previewType = 'thumbnail';
    if (isDraft) {
      previewType = 'draft';
    } else if (isProcessing || hasRenderingError) {
      previewType = 'processing';
    }

    const classes = {
      active: isActive,
      free: isFree,
      processing: isProcessing
    };

    return (
      <div className={classNames('col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-one-fifth', {selected: isSelected})}>
        <div
          className={classNames('d-flex flex-column content-item', classes)}
          onClick={() => onClick(content.id)}
          data-cy="content-item"
        >
          {(multiSelectAllowed && onSelected) && (
            <FontAwesomeIcon
              className={classNames('fa bg-white multiselect-icon rounded-circle text-secondary', {
                'hidden': !inMultiSelectMode
              })}
              icon={isSelected ? faCheckCircle : faCircle}
              onClick={(e) => {
                e.stopPropagation();
                onSelected(content.id);
              }}
            />
          )}
          {(previewType === 'thumbnail') && (
            this.renderContentItemThumbnail(content)
          )}

          {(previewType === 'draft') && (
            this.renderContentItemDraft(content)
          )}

          {(previewType === 'processing') && (
            this.renderContentItemProcessing(hasRenderingError)
          )}

          <div className="content-item-info p-3">
            <div className="content-item-name">
              {content.name}
            </div>

            {(isProcessing) && (
              <RenderProgress content={content} size="small" />
            )}

            {(isFree) && (
              <div className="free-label px-2">
                Free
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      content,
      contentId,
      apiContentGetOneStore,
    } = this.props;

    if (content) {
      return this.renderContentItem(content);
    }

    return apiContentGetOneStore.case(contentId, {
      pre: () => null,
      pending: () => (
        <div className="d-flex flex-column content-item">
          <LoadingIcon />
        </div>
      ),
      fulfilled: this.renderContentItem,
      rejected: () => null,
    });
  }
}

ContentItem.propTypes = {
  contentId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,

  apiContentGetOneStore: MobxPropTypes.observableObject,
  apiUserGetMeStore: MobxPropTypes.observableObject,
  content: PropTypes.object,
  inMultiSelectMode: PropTypes.bool,
  isActive: PropTypes.bool,
  isSelected: PropTypes.bool,
  multiSelectAllowed: PropTypes.bool,
  onSelected: PropTypes.func
};

ContentItem.wrappedComponent = {};
ContentItem.wrappedComponent.propTypes = {
  apiContentGetOneStore: MobxPropTypes.observableObject.isRequired,
  apiUserGetMeStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(ContentItem)(
  observer(ContentItem)
);
