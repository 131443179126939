import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import logo from '../../../../../assets/images/horizontal-darkblue.png';
import {buildWordpressUrl} from '../../../../../utils/routeHelper';

import './onboardStepper.scss';

/**
 * The OnboardStepper component.
 */
class OnboardStepper extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {steps, currentStep, changeStep} = this.props;
    const homeLink = buildWordpressUrl();
    const previousStep = steps.find((step) => currentStep - 1 === step.key);

    return (
      <>
      <div className={classNames('row nav p-3', {[`section${currentStep}`]: true})}>
        <div className="active-page-dot" />
        <div className="active-page-line" />
        <div className="col-md-3 col-12 d-flex align-items-center">
          <div>
            <a href={homeLink}>
              <img className="logo ml-3 p-2" src={logo} alt="logo" />
            </a>
          </div>
        </div>
        <div className="col-6 d-none d-md-flex align-items-center justify-content-center">
          {steps.map((step) => (<div
            key={step.key}
            onClick={() => changeStep(step.key)}
            className={classNames('section-button', {active: currentStep === step.key})}
          >
            {step.label}
          </div>))}
        </div>
      </div>
      <div className="my-3 container">
        <a
          className="h6"
          tabIndex="0"
          onClick={() => changeStep(previousStep.key)}
        >
          {previousStep && `← ${previousStep.label}`}
        </a>
      </div>
      </>
    );
  }
}

OnboardStepper.propTypes = {
  changeStep: PropTypes.func,
  currentStep: PropTypes.number,
  steps: PropTypes.array,
};

export default OnboardStepper;

