import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import {action, observable} from 'mobx';
import PropTypes from 'prop-types';
import React from 'react';
import {CSSTransition as CSSTransitionComponent, TransitionGroup} from 'react-transition-group';

import ContentItem from '../contentItem/ContentItem';
import ContentNewItem from '../contentNewItem/ContentNewItem';
import CollectionExample from '../collectionExample/CollectionExample';
import LoadingIcon from '../loadingIcon/LoadingIcon';
import inject from '../../hoc/injectHoc';
import {CONTENT_LIST_COLLECTION, CONTENT_LIST_FOLDER} from '../../../constants/contentConstants';
import MultiSelectActions from './components/MultiSelectActions';

import './contentItems.scss';

/**
 * The content items component
 */
class ContentItems extends React.Component {
  /**
   * Whether or not the contents can be multi-selected
   *
   * @type {boolean}
   */
  @observable inMultiSelectMode = false;

  /**
   * List of selected contentIds
   *
   * @type {string[]}
   */
  @observable selectedContents = [];

  /**
   * Loads the content.
   * Triggered when the component is mounted to the page.
   */
  componentDidMount() {
    this.loadContent();
  }

  /**
   * Reloads the content if the list id or list type changes.
   * Triggered when the props update.
   *
   * @param {{}} prevProps
   */
  componentDidUpdate(prevProps) {
    const {contentListId, contentListType} = this.props;

    if (prevProps.contentListId !== contentListId || prevProps.contentListType !== contentListType) {
      this.loadContent();
    }
  }

  /**
   * Triggered when the component is about to be removed from the page.
   */
  componentWillUnmount() {
    const {apiContentFolderPollContentStore, contentListId} = this.props;

    apiContentFolderPollContentStore.cancelPollForContentFolder(contentListId);
  }

  /**
   * Close the multi select mode and clear selectedContents
   */
  @action inMultiSelectModeClose = () => {
    this.selectedContents = [];
    this.inMultiSelectMode = false;
  }

  /**
   * Toggles the multi select mode
   */
  @action inMultiSelectModeToggle = () => {
    if (this.selectedContents && this.selectedContents.length > 0) {
      this.inMultiSelectMode = true;
    } else {
      this.inMultiSelectMode = !this.inMultiSelectMode;
    }
  }

  /**
   * Updates the selectedContent array with new contentIds
   *
   * @param {string} contentId
   */
  @action setSelectedContent = (contentId) => {
    const contentIndex = this.selectedContents.indexOf(contentId);
    if (contentIndex !== -1) {
      this.selectedContents.splice(contentIndex, 1);
    } else {
      this.selectedContents = [contentId, ...this.selectedContents];
    }

    if (this.selectedContents.length === 0) {
      this.inMultiSelectMode = false;
    } else {
      this.inMultiSelectMode = true;
    }
  }

  /**
   * Deletes selected contents
   */
  @action onDeleteContents = () => {
    const {
      apiContentDeleteManyStore,
      apiContentUnPublishManyStore,
      contentListId,
      contentListType,
    } = this.props;

    if (contentListType === CONTENT_LIST_COLLECTION) {
      apiContentUnPublishManyStore.makeRequest(this.selectedContents, contentListId);
    } else if (contentListType === CONTENT_LIST_FOLDER) {
      apiContentDeleteManyStore.makeRequest(this.selectedContents, contentListId);
    }
    this.inMultiSelectModeClose();
  }

  /**
   * Loads the content from the appropriate store.
   */
  loadContent = () => {
    const {
      apiCollectionGetContentStore,
      apiContentFolderGetContentStore,
      apiContentFolderPollContentStore,
      contentListId,
      contentListType,
      onContentLoaded,
    } = this.props;

    // Make sure any active polling is disabled first.
    apiContentFolderPollContentStore.clearAll(contentListId);

    if (contentListType === CONTENT_LIST_COLLECTION) {
      apiCollectionGetContentStore.refresh(contentListId);

      if (onContentLoaded) {
        apiCollectionGetContentStore.getPromise(contentListId).then((contentItems) => {
          onContentLoaded(contentItems);
        });
      }
    } else {
      apiContentFolderGetContentStore.refresh(contentListId, true);
      apiContentFolderGetContentStore.getPromise(contentListId).then(() => {
        const contentFolder = apiContentFolderGetContentStore.getFulfilled(contentListId);
        const isAnyPending = apiContentFolderPollContentStore.isAnyContentProcessing(contentFolder);

        if (isAnyPending) {
          apiContentFolderPollContentStore.pollContentFolderById(contentListId);
        }
      });

      if (onContentLoaded) {
        apiContentFolderGetContentStore.getPromise(contentListId).then((contentItems) => {
          onContentLoaded(contentItems);
        });
      }
    }
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      activeContentId,
      apiCollectionGetContentStore,
      apiContentFolderGetContentStore,
      contentListId,
      contentListType,
      onContentSelect,
      multiSelectAllowed,
      user,
      collectionExample,
    } = this.props;

    const activeContentStore = (
      contentListType === CONTENT_LIST_COLLECTION
    ) ? apiCollectionGetContentStore : apiContentFolderGetContentStore;

    const onContentMultiSelect = (contentId) => {
      this.setSelectedContent(contentId);
    };

    const onContentClick = (contentId) => {
      if (multiSelectAllowed && this.inMultiSelectMode) {
        this.setSelectedContent(contentId);
      } else {
        onContentSelect(contentId);
      }
    };

    const showNewTemplateButton = Boolean(contentListType === CONTENT_LIST_FOLDER && user && user.canDesign);
    const showCollectionExample = Boolean(collectionExample) && collectionExample.enabled === true;
    const showNewCanvasButton = !showNewTemplateButton;

    return (
      <>
        {multiSelectAllowed && (<div className="d-flex px-4 mb-2">
          {(this.inMultiSelectMode) ? (
            <MultiSelectActions
              contentListType={contentListType}
              onDelete={this.onDeleteContents}
              onCancel={this.inMultiSelectModeClose}
            />
          ) : (<button
            type="button"
            className="btn btn-sm btn-outline-secondary"
            onClick={this.inMultiSelectModeToggle}
          >
            Select Multiple
          </button>)}
        </div>)}

        <TransitionGroup className="content-items d-flex flex-wrap container-fluid row no-gutters">
          {(showCollectionExample) && (
            <CSSTransitionComponent
              timeout={200}
              classNames="fade"
            >
              <CollectionExample
                collectionExample={collectionExample}
                onClick={onContentSelect}
              />
            </CSSTransitionComponent>
          )}

          {(showNewTemplateButton) && (
            <CSSTransitionComponent
              timeout={200}
              classNames="fade"
            >
              <ContentNewItem />
            </CSSTransitionComponent>
          )}

          {activeContentStore.case(contentListId, {
            pending: () => (
              <CSSTransitionComponent
                timeout={200}
                classNames="fade"
              >
                <LoadingIcon size="lg" />
              </CSSTransitionComponent>
            ),
            rejected: (error) => (
              <CSSTransitionComponent
                timeout={200}
                classNames="fade"
              >
                {error.message}
              </CSSTransitionComponent>
            ),
            fulfilled: (contentItems) => (contentItems.map((contentItem) => (
              <CSSTransitionComponent
                key={contentItem.id}
                timeout={200}
                classNames="fade"
              >
                <ContentItem
                  content={contentItem}
                  contentId={contentItem.id}
                  isActive={activeContentId === contentItem.id}
                  isSelected={this.selectedContents.includes(contentItem.id)}
                  multiSelectAllowed={multiSelectAllowed}
                  inMultiSelectMode={this.inMultiSelectMode}
                  onSelected={onContentMultiSelect}
                  onClick={onContentClick}
                />
              </CSSTransitionComponent>
            )))
          })}

          {(showNewCanvasButton) && (
            <CSSTransitionComponent
              timeout={200}
              classNames="fade"
            >
              <ContentNewItem
                newContentText="Canvas"
              />
            </CSSTransitionComponent>
          )}
        </TransitionGroup>
      </>
    );
  }
}

ContentItems.propTypes = {
  contentListId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  contentListType: PropTypes.string.isRequired,
  onContentSelect: PropTypes.func.isRequired,

  activeContentId: PropTypes.number,
  apiCollectionGetContentStore: MobxPropTypes.observableObject,
  apiContentDeleteManyStore: MobxPropTypes.observableObject,
  apiContentFolderGetContentStore: MobxPropTypes.observableObject,
  apiContentFolderPollContentStore: MobxPropTypes.observableObject,
  apiContentUnPublishManyStore: MobxPropTypes.observableObject,
  collectionExample: PropTypes.object,
  multiSelectAllowed: PropTypes.bool,
  onContentLoaded: PropTypes.func,
  user: PropTypes.object,
};

ContentItems.defaultProps = {
  multiSelectAllowed: false
};

ContentItems.wrappedComponent = {};
ContentItems.wrappedComponent.propTypes = {
  apiCollectionGetContentStore: MobxPropTypes.observableObject.isRequired,
  apiContentDeleteManyStore: MobxPropTypes.observableObject.isRequired,
  apiContentFolderGetContentStore: MobxPropTypes.observableObject.isRequired,
  apiContentFolderPollContentStore: MobxPropTypes.observableObject.isRequired,
  apiContentUnPublishManyStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(ContentItems)(
  observer(ContentItems)
);
