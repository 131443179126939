import classNames from 'classnames';
import lodash from 'lodash';
import {action, observable, runInAction, toJS} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClone, faTrashCan} from '@fortawesome/free-regular-svg-icons';
import {faEllipsisV, faFont} from '@fortawesome/free-solid-svg-icons';

import inject from '../../../../../hoc/injectHoc';
import InputStringModal from '../../../../../modals/inputString/InputStringModal';
import {
  getSingleTransitionFromSource,
  getSingleTransitionForSource
} from '../../../../../../display/components/common/transitionComponent';
import {SUPER_ADMIN_ROLE} from '../../../../../../constants/userConstants';

import './transitionListItem.scss';

/**
 * The EditorEntityListItem component.
 *
 * @constructor
 */
export class TransitionListItem extends React.Component {
  /**
   * Whether or not the menu is open.
   *
   * @type {boolean}
   */
  @observable isMenuOpen = false;

  /**
   * Whether or not the rename modal is open.
   *
   * @type {boolean}
   */
  @observable isRenameModalOpen = false;

  /**
   * Whether the user is an Admin.
   *
   * @type {boolean}
   */
  @observable isSuperAdmin = false;

  /**
   * Triggered when the component is mounted to the page.
   */
  componentDidMount() {
    const {apiUserGetMeStore} = this.props;

    const editorUser = apiUserGetMeStore.getFulfilled();
    runInAction('transitionListItemLoaded', () => {
      this.isSuperAdmin = editorUser.role === SUPER_ADMIN_ROLE;
    });
  }

  /**
   * Activates the entity.
   *
   * @param {boolean} onlyActivate If true, the entity can't be deselected.
   */
  activateTransition = (onlyActivate) => {
    const {isActive, transition, onActivate} = this.props;

    if (onlyActivate) {
      onActivate(transition, true);
    } else if (isActive) {
      onActivate(transition, false);
    } else {
      onActivate(transition, true);
    }
  };

  /**
   * Action when user clicks the transition. Activates the transition for admin users. Opens library modal for non-admin users.
   *
   */
  onClickItem = () => {
    const {onCreate} = this.props;

    if (this.isSuperAdmin) {
      this.activateTransition(false);
    } else {
      // Open the library modal
      onCreate();
    }
  }

  /**
   * Triggered when the dropdown open button is clicked.
   *
   * @param {{stopPropagation: function}} clickEvent
   */
  @action onClickDropdown = (clickEvent) => {
    // We want to prevent the click on the list-item, so stop propagation.
    clickEvent.stopPropagation();

    this.isMenuOpen = !this.isMenuOpen;
    if (this.isMenuOpen) {
      // If the menu is now open, make sure to activate this entity to avoid zIndex stacking context issues.
      this.activateTransition(true);
    }
  };

  /**
   * Triggered when the the transition delete option is clicked.
   *
   * @param {{}} clickEvent
   */
  onTransitionDelete = (clickEvent) => {
    clickEvent.stopPropagation();

    const {transition, onDelete} = this.props;

    onDelete(transition);
  };

  /**
   * Triggered when the the transition duplicate option is clicked.
   *
   * @param {{}} clickEvent
   */
  onTransitionDuplicate = (clickEvent) => {
    clickEvent.stopPropagation();

    const {transition, onCreate} = this.props;

    const safeTransition = toJS(transition);

    const newSource = getSingleTransitionForSource(safeTransition);
    newSource.name = `Copy of ${safeTransition.name}`;
    lodash.unset(newSource, 'id');

    const newTransition = getSingleTransitionFromSource(newSource);

    onCreate(newTransition);
  };

  /**
   * Triggered when the the transition rename option is clicked.
   */
  @action onOpenRenameModal = () => {
    this.isRenameModalOpen = true;
  };

  /**
   * Triggered when the transition rename option is clicked.
   *
   * @param {string} newName
   */
  @action onTransitionRename = (newName) => {
    this.isRenameModalOpen = false;

    if (!newName) {
      // The modal must have been cancelled.
      return;
    }

    // The maximum character length for transition names.
    const maxLength = 39;

    let safeName = String(newName);
    if (safeName.length > maxLength) {
      safeName = safeName.substr(0, maxLength) + '...';
    }

    const {transition, onRename} = this.props;

    onRename(transition, safeName);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {isActive, transition, transitionOrder} = this.props;

    const topClasses = {
      active: Boolean(isActive),
    };

    const transitionName = lodash.upperFirst(transition.name || transition.id);

    return (
      <a
        className={classNames('transition-list-item list-group-item list-group-item-action', topClasses)}
        onClick={() => this.activateTransition(false)}
        title={transitionName}
      >
        <span className="transition-order">
          <span className="badge badge-pill badge-outline-dark">{transitionOrder}</span>
        </span>
        <span className="transition-name">
          {transitionName}
        </span>
        <div className="transition-actions-wrapper">
          {!this.isSuperAdmin && (<button
            type="button"
            className="btn transition-delete p-0"
            onClick={this.onTransitionDelete}
          >
            <FontAwesomeIcon icon={faTrashCan} />
          </button>)}

          <span className="transition-actions">
            <Dropdown isOpen={this.isMenuOpen} toggle={this.onClickDropdown}>
              <DropdownToggle className="btn btn-sm btn-link btn-icon" tag="button">
                <FontAwesomeIcon
                  className="icon-bold"
                  icon={faEllipsisV}
                />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={this.onOpenRenameModal}
                >
                  <FontAwesomeIcon icon={faFont} />
                  Change Name
                </DropdownItem>

                {this.isSuperAdmin && (<>
                  <DropdownItem
                    onClick={this.onTransitionDuplicate}
                  >
                    <FontAwesomeIcon icon={faClone} />
                      Duplicate
                  </DropdownItem>

                  <DropdownItem onClick={this.onTransitionDelete}>
                    <FontAwesomeIcon icon={faTrashCan} />
                    Delete
                  </DropdownItem>
                </>)}

              </DropdownMenu>
            </Dropdown>
          </span>

          {(this.isRenameModalOpen) && (
            <InputStringModal
              isOpen={true}
              title="Rename Transition"
              onComplete={this.onTransitionRename}
              startingValue={transitionName}
            />
          )}
        </div>
      </a>
    );
  }
}

TransitionListItem.propTypes = {
  onActivate: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  transition: PropTypes.object.isRequired,
  transitionOrder: PropTypes.number.isRequired,

  apiUserGetMeStore: MobxPropTypes.observableObject,
  isActive: PropTypes.bool,
  isLast: PropTypes.bool,
};

TransitionListItem.wrappedComponent = {};
TransitionListItem.wrappedComponent.propTypes = {
  apiUserGetMeStore: MobxPropTypes.observableObject.isRequired,
};

TransitionListItem.defaultProps = {
  isActive: false,
  isLast: false,
};

export default inject(TransitionListItem)(
  observer(TransitionListItem)
);
