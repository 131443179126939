/**
 * The interaction component.
 *
 * @param {boolean} isActive
 * @param {boolean} isCropping
 * @returns {{interaction: {isActive: boolean, isCropping: boolean}}}
 */
export function interactionComponent(isActive, isCropping) {
  return {
    interaction: {
      isActive: Boolean(isActive),
      isCropping: Boolean(isCropping),
    },
  };
}

/**
 * Gets the interaction component from the source item.
 *
 * @param {{interaction: {isActive: boolean, isCropping: boolean}}} item
 * @returns {{}}
 */
export function getInteractionFromSource(item) {
  if (item.interaction && item.interaction.isActive) {
    const isCropping = Boolean(item.interaction.isCropping);

    return interactionComponent(true, isCropping);
  }
  return {};
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {ObservableMap} entity
 * @returns {{}}
 */
export function getInteractionForSource(entity) {
  if (!entity.has('interaction')) {
    return {};
  }

  const interaction = entity.get('interaction');
  if (!interaction || !interaction.isActive) {
    return {};
  }

  return interactionComponent(
    true,
    Boolean(interaction.isCropping),
  );
}
