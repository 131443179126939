import {action, observable} from 'mobx';

import routerStore from '../active/routerStore';
import {dashboardListRoute, dashboardRoute} from '../../components/routePaths';

/**
 * The navigate content store.
 *
 * Helps with navigation back to the dashboard using the last dashboard state.
 */
class NavigateContentStore {
  /**
   * Whether the Dashboard Sidebar is open
   *
   * @type {boolean}
   */
  @observable isDashboardSidebarOpen = false;

  /**
   * The last list type loaded (folder or collection).
   *
   * @type {?string}
   */
  @observable listType = null;

  /**
   * The last list id loaded.
   *
   * @type {?number}
   */
  @observable listId = null;

  /**
   * The last content id loaded.
   *
   * @type {?number}
   */
  @observable contentId = null;

  /**
   * Clears all the data for this store.
   */
  @action clearAll() {
    this.listType = null;
    this.listId = null;
    this.contentId = null;
  }

  /**
   * Sets the list type (folder or collection).
   *
   * @param {?string} newListType
   */
  @action setListType(newListType) {
    this.listType = newListType || null;
  }

  /**
   * Sets the list id.
   *
   * @param {string|number} newListId
   */
  @action setListId(newListId) {
    this.listId = newListId || null;
  }

  /**
   * Sets the content id.
   *
   * @param {?{}} newContentId
   */
  @action setContentId(newContentId) {
    this.contentId = newContentId || null;
  }

  /**
   * Toggle the Dashboard Sidebar.
   *
   */
  @action toggleDashboardSidebar() {
    this.isDashboardSidebarOpen = !this.isDashboardSidebarOpen;
  }

  /**
   * Closes the Dashboard Sidebar.
   */
  @action closeDashboardSidebar() {
    this.isDashboardSidebarOpen = false;
  }

  /**
   * Navigates to the dashboard using the state set in this store.
   *
   * @param {{}=} routeOptions
   * @param {{}=} overrideParams
   */
  navigateToDashboard = (routeOptions, overrideParams) => {
    const safeOptions = routeOptions || {};
    const safeParams = overrideParams || {};

    let toRoute = dashboardRoute;
    if (
      (this.contentId && this.listId && this.listType)
      || (safeParams.contentId && safeParams.listId && safeParams.listType)
    ) {
      toRoute = dashboardListRoute;

      safeOptions.params = {
        listType: this.listType,
        listId: this.listId,
        contentId: this.contentId,
        ...safeParams,
      };
    }

    routerStore.push(toRoute, safeOptions);
  };
}

export default new NavigateContentStore();
