import lodash from 'lodash';
import {action, observable, toJS} from 'mobx';

/**
 * The display editor store.
 */
class DisplayEditorStore {
  /**
   * The game object for the editor.
   *
   * @type {?{}}
   */
  @observable game = null;

  /**
   * The game timer for the editor.
   *
   * @type {?{}}}
   */
  @observable timer = null;

  /**
   * The game current aspect ratio.
   *
   * @type {?number}
   */
  @observable currentAspectRatio = null;

  /**
   * The game default aspect ratio.
   *
   * @type {?number}
   */
  @observable defaultAspectRatio = null;

  /**
   * The game current sign dimensions.
   *
   * @type {?number}
   */
  @observable currentSignDimensions = null;

  /**
   * The game default sign dimensions.
   *
   * @type {?number}
   */
  @observable defaultSignDimensions = null;

  /**
   * The content object that goes with the sources.
   *
   * @type {?{id: ?number, height: number, width: number}}
   */
  @observable content = null;

  /**
   * The game sources for the editor.
   *
   * @type {ObservableMap.<string, {}>}
   */
  @observable sources = observable.map({});

  /**
   * The game variables for the editor.
   *
   * @type {ObservableMap.<string, {}>}
   */
  @observable variables = observable.map({});

  /**
   * Sets the game.
   *
   * @param {{}} newGame
   */
  @action setGame(newGame) {
    this.game = newGame || null;
  }

  /**
   * Sets the timer.
   *
   * @param {{}} newTimer
   */
  @action setTimer(newTimer) {
    this.timer = newTimer || null;
  }

  /**
   * Sets the current game aspect ratio.
   *
   * @param {number} newAspectRatio
   */
  @action setCurrentAspectRatio(newAspectRatio) {
    this.currentAspectRatio = newAspectRatio || null;
  }

  /**
   * Sets the default game aspect ratio.
   *
   * @param {number} newAspectRatio
   */
  @action setDefaultAspectRatio(newAspectRatio) {
    this.defaultAspectRatio = newAspectRatio || null;
  }

  /**
   * Sets the current game sign dimensions.
   *
   * @param {number} newSignDimensions
   */
  @action setCurrentSignDimensions(newSignDimensions) {
    this.currentSignDimensions = newSignDimensions || null;
  }

  /**
   * Sets the default game sign dimensions.
   *
   * @param {number} newSignDimensions
   */
  @action setDefaultSignDimensions(newSignDimensions) {
    this.defaultSignDimensions = newSignDimensions || null;
  }

  /**
   * Sets the content object that goes with the sources.
   *
   * @param {{id: ?number, height: number, width: number}|Error} newContent
   */
  @action setContent(newContent) {
    this.content = newContent || null;
  }

  /**
   * Sets all the game sources.
   *
   * @param {{}} newSources
   */
  @action setAllSources(newSources) {
    this.sources.clear();

    lodash.forEach(newSources, (source, aspectRatio) => {
      this.sources.set(String(aspectRatio), source);
    });
  }

  /**
   * Adds or updates a single game source.
   *
   * @param {number} aspectRatio
   * @param {{}} newSource
   */
  @action setSource(aspectRatio, newSource) {
    this.sources.set(String(aspectRatio), newSource);
  }

  /**
   * Removes a single game source.
   *
   * @param {number} aspectRatio
   */
  @action removeSource(aspectRatio) {
    this.sources.delete(String(aspectRatio));
  }

  /**
   * Gets a variable from the given group.
   *
   * @param {string} groupName
   * @param {string} variableName
   * @param {*} defaultValue
   * @returns {*}
   */
  getVariable(groupName, variableName, defaultValue) {
    const group = this.variables.get(groupName);
    if (!group || !group.has(variableName)) {
      return (defaultValue !== undefined) ? defaultValue : null;
    }

    return toJS(group.get(variableName));
  }

  /**
   * Sets all the game variables.
   *
   * @param {{}} newVariables
   */
  @action setAllVariables(newVariables) {
    this.variables.clear();

    lodash.forEach(newVariables, (groupVariables, groupName) => {
      this.variables.set(String(groupName), observable.map(groupVariables));
    });
  }

  /**
   * Adds or updates a single game variable.
   *
   * @param {string} groupName
   * @param {string} variableName
   * @param {{}} newVariable
   */
  @action setVariable(groupName, variableName, newVariable) {
    const safeGroupName = String(groupName);
    const safeVariableName = String(variableName);

    let group = this.variables.get(safeGroupName);
    if (!group) {
      this.variables.set(safeGroupName, observable.map());
      group = this.variables.get(safeGroupName);
    }

    if (newVariable === undefined) {
      group.delete(safeVariableName);
      return;
    }

    group.set(safeVariableName, newVariable);
  }
}

export default new DisplayEditorStore();
