import React from 'react';
import PropTypes from 'prop-types';

import './editorSidebarTitle.scss';

/**
 * The EditorSidebarTitle component.
 */
export class EditorSidebarTitle extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    return (
      <div>
        <div className="sidebar-title-container">
          <h5 className="sidebar-title-text">
            {this.props.title}
          </h5>
          {this.props.rightSlot}
        </div>
        <hr className="mx-3" />
      </div>
    );
  }
}

EditorSidebarTitle.propTypes = {
  title: PropTypes.string.isRequired,
  rightSlot: PropTypes.element,
};

export default EditorSidebarTitle;
