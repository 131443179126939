import React from 'react';

import prestoLogo from '../../../../../assets/images/presto.svg';

import './draftPreview.scss';

/**
 * The DraftPreview component.
 */
class DraftPreview extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    return (
      <div className="draft-preview">
        <div className="container-fluid">
          <div className="row align-items-sm-center">
            <div className="col-12 col-sm-6">
              <div className="mb-3 mb-sm-0 d-flex justify-content-center">
                <img className="presto-icon" alt="Presto Icon" src={prestoLogo} />
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="draft-text text-center text-sm-left">
                Draft
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DraftPreview.propTypes = {};

export default DraftPreview;
