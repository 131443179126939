import {action} from 'mobx';

import {EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import {STATE_FULFILLED, STATE_PENDING, STATE_REJECTED} from '../../../constants/asyncConstants';
import serverApi from '../../../utils/serverApi';
import ApiBaseStore from '../common/apiBaseStore';

/**
 * ApiAdminContentRequestGetAllStore
 */
class ApiAdminContentRequestGetAllStore extends ApiBaseStore {
  /**
   * Fetches all content requests.
   * In the future we will want to put some pagination or other limitation on how many it gets.
   */
  @action makeRequest() {
    this.status = STATE_PENDING;
    this.expireTime = Date.now() + EXPIRES_PENDING;
    this.data = null;
    this.error = null;

    serverApi.adminContentRequestGetAll().then(
      action('adminContentRequestGetAllSuccess', (foundRequests) => {
        this.state = STATE_FULFILLED;
        this.expireTime = Date.now() + EXPIRES_IN;
        this.data = foundRequests;
        this.error = null;
      }),
      action('adminContentRequestGetAllError', (requestsGetAllError) => {
        this.state = STATE_REJECTED;
        this.expireTime = null;
        this.data = null;
        this.error = requestsGetAllError;
      })
    );
  }
}

export default new ApiAdminContentRequestGetAllStore();
