import PropTypes from 'prop-types';
import {action, observable, runInAction} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';

import ConfirmModal from '../confirm/ConfirmModal';
import inject from '../../hoc/injectHoc';
import SignForm from '../../common/signForm/SignForm';

import './signAddEditModal.scss';

/**
 * The SignAddEditModal component.
 */
class SignAddEditModal extends React.Component {
  /**
   * Whether or not the confirm modal is open.
   *
   * @type {boolean}
   */
  @observable confirmModalIsOpen = false;

  /**
   * The pending sign data.
   *
   * @type {?{}}
   */
  @observable signData = null;

  /**
   * Error message when creating or updating sign
   *
   * @type{string}
   */
  @observable errorMessage = '';

  /**
   * Submit content request form.
   *
   * @param {{}} newSignData
   */
  @action onSubmit = (newSignData) => {
    this.signData = newSignData;

    const {isAdmin, sign} = this.props;
    const isEdit = Boolean(sign && sign.id);

    let hasSizeChanges = false;
    if (sign && newSignData.signWidth !== sign.width) {
      hasSizeChanges = true;
    }
    if (sign && newSignData.signHeight !== sign.height) {
      hasSizeChanges = true;
    }

    if (isAdmin) {
      this.onConfirm(true);
    } else if (isEdit && !hasSizeChanges) {
      this.onConfirm(true);
    } else {
      this.confirmModalIsOpen = true;
    }
  };

  /**
   * After the create sign is confirmed.
   *
   * @param {boolean} wasConfirmed
   */
  @action onConfirm = (wasConfirmed) => {
    const newSignData = this.signData;

    this.errorMessage = '';
    this.signData = null;
    this.confirmModalIsOpen = false;

    if (!wasConfirmed) {
      return;
    }

    const {
      isAdmin,
      onComplete,
      sign,
      companyId,
      apiAdminSignCreateStore,
      apiAdminSignUpdateStore,
      apiSignCreateStore,
      apiSignUpdateStore
    } = this.props;

    const isEdit = Boolean(sign && sign.id);

    if (isAdmin && isEdit) {
      apiAdminSignUpdateStore.makeRequest(sign.id, newSignData, companyId);
      apiAdminSignUpdateStore.getPromise(sign.id).then(() => onComplete())
        .catch((error) => {
          runInAction('adminSignUpdateError', () => {
            const errorMessage = error && error.message
              ? error.message
              : 'Error updating sign';

            this.errorMessage = errorMessage;
          });
        });
    } else if (isAdmin) {
      apiAdminSignCreateStore.makeRequest({
        ...newSignData,
        companyId,
      }, companyId);
      apiAdminSignCreateStore.getPromise().then(() => onComplete())
        .catch((error) => {
          runInAction('adminSignCreateError', () => {
            const errorMessage = error && error.message
              ? error.message
              : 'Error creating sign';

            this.errorMessage = errorMessage;
          });
        });
    } else if (isEdit) {
      apiSignUpdateStore.makeRequest(sign.id, newSignData);
      apiSignUpdateStore.getPromise(sign.id).then(() => onComplete())
        .catch((error) => {
          runInAction('signUpdateError', () => {
            const errorMessage = error && error.message
              ? error.message
              : 'Error updating sign';

            this.errorMessage = errorMessage;
          });
        });
    } else {
      apiSignCreateStore.makeRequest(newSignData);
      apiSignCreateStore.getPromise().then(() => onComplete())
        .catch((error) => {
          runInAction('signCreateError', () => {
            const errorMessage = error && error.message
              ? error.message
              : 'Error creating sign';

            this.errorMessage = errorMessage;
          });
        });
    }
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {isAdmin, isOpen, onComplete, sign, confirmBodyText, allowSizeEdit} = this.props;

    if (!isOpen) {
      return null;
    }

    const title = (sign) ? 'Update Sign' : 'Create New Sign';
    const allowSizes = isAdmin || !sign || allowSizeEdit;

    return (
      <>
        <Modal
          className="sign-add-edit-modal"
          backdrop="static"
          size="lg"
          isOpen={true}
          toggle={onComplete}
          centered
        >
          <ModalHeader toggle={onComplete}>{title}</ModalHeader>
          <ModalBody>
            <SignForm
              showName={true}
              showTitle={false}
              allowSizes={allowSizes}
              sign={sign}
              onSubmit={this.onSubmit}
              onCancel={onComplete}
              errorMessage={this.errorMessage}
            />
          </ModalBody>
        </Modal>

        {(this.confirmModalIsOpen) && (
          <ConfirmModal
            isOpen={true}
            confirmText="Are you sure you want to create this sign?"
            bodyText={() => (
              <>
                <p>{(confirmBodyText)}</p>
                <div>
                  {(this.signData.signWidth) ? (<div>Width: {this.signData.signWidth}px</div>) : ''}
                  {(this.signData.signHeight) ? (<div>Height: {this.signData.signHeight}px</div>) : ''}
                </div>
              </>
            )}
            onComplete={this.onConfirm}
            isYesNo={true}
          />
        )}
      </>
    );
  }
}

SignAddEditModal.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  allowSizeEdit: PropTypes.bool,

  apiAdminSignCreateStore: MobxPropTypes.observableObject,
  apiAdminSignUpdateStore: MobxPropTypes.observableObject,
  apiSignCreateStore: MobxPropTypes.observableObject,
  apiSignUpdateStore: MobxPropTypes.observableObject,
  companyId: PropTypes.string,
  confirmBodyText: PropTypes.string,
  sign: PropTypes.object,
};

SignAddEditModal.defaultProps = {
  allowSizeEdit: false,
  confirmBodyText: 'Once created, you will not be able to delete the sign or edit the sign width or height.',
};

SignAddEditModal.wrappedComponent = {};
SignAddEditModal.wrappedComponent.propTypes = {
  apiAdminSignCreateStore: MobxPropTypes.observableObject.isRequired,
  apiAdminSignUpdateStore: MobxPropTypes.observableObject.isRequired,
  apiSignCreateStore: MobxPropTypes.observableObject.isRequired,
  apiSignUpdateStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(SignAddEditModal)(
  observer(SignAddEditModal)
);
