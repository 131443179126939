import React from 'react';

/**
 * Render react select option component
 *
 * @param {object} input
 * @property {object} input.data
 * @property {object} input.innerProps
 * @returns {JSX.Element}
 */
export const SIGN_SELECT_OPTION_RENDERER = (input) => {
  const {
    data: option,
    innerProps,
  } = input;
  return (<div
    className="d-flex px-3 py-2 cursor-pointer"
    {...innerProps}
  >
    <div className="font-weight-bold">
      {option.sign.aspectRatio}
    </div>
    <div className="aspect-ratio-sign">
      <span
        className="aspect-ratio-sign-name"
        title={option.sign.name}
      >
        {option.sign.name}
      </span><br />
      <span className="aspect-ratio-sign-dimensions">
        {option.sign.width}w x {option.sign.height}h
      </span>
    </div>
  </div>
  );
};

export const SIGN_SELECT_VALUE_RENDERER = (input) => {
  const {
    data: option,
  } = input;
  return (<>
    <span>{option.sign.name}</span> <small>({option.sign.width}w x {option.sign.height}h)</small>
  </>);
};
