import {RichUtils} from 'draft-js';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import ReactSelect from 'react-select';

import {customFontStyles} from '../../constants/styleConstants';

import './fontSelect.scss';

const UP_ARROW_KEY_CODE = 38;
const DOWN_ARROW_KEY_CODE = 40;

/**
 * The list of style buttons before sorting.
 *
 * @type {Array.<{label: string, value: string}>}
 */
const RAW_FONT_VALUES = lodash.map(customFontStyles, (fontData, styleName) => {
  const fontName = fontData.name || styleName.replace('FONT_', '');
  return {label: fontName, value: styleName};
});

/**
 * The list of style buttons.
 *
 * @type {Array.<{label: string, value: string}>}
 */
const FONT_VALUES = lodash.sortBy(RAW_FONT_VALUES, (fontValue) => {
  return fontValue.label;
});

/**
 * The FontSelect component.
 *
 * @param {{}} params
 * @param {{}} params.editorState
 * @param {function(EditorState)} params.onChangeTextState
 * @param {function()=} params.beforeChange
 * @returns {React.Component}
 */
export const FontSelect = ({editorState, onChangeTextState, beforeChange}) => {
  let currentValue = '';

  /**
   * The font select element reference.
   *
   * @type {{current: ?HTMLElement}}
   */
  const selectRef = useRef(null);

  /**
   * Updates the editor state with a new style when a button is clicked.
   *
   * @param {{value: string}} selected
   */
  const onChange = (selected) => {
    currentValue = selected;
    let changeState = editorState;
    if (beforeChange) {
      changeState = beforeChange(true);
    }

    const changeStyle = (selected) ? selected.value : '';

    const beforeChangeStyle = changeState.getCurrentInlineStyle();

    // First remove any fonts currently set.
    let newEditorState = changeState;
    FONT_VALUES.forEach((fontOption) => {
      if (beforeChangeStyle.has(fontOption.value)) {
        newEditorState = RichUtils.toggleInlineStyle(newEditorState, fontOption.value);
      } else {
        /*
         * Because of a bug in detecting active styles in mixed blocks, we need to turn on this font everywhere,
         * and then off again. This will make sure all the currently set fonts are removed.
         */
        newEditorState = RichUtils.toggleInlineStyle(newEditorState, fontOption.value);
        newEditorState = RichUtils.toggleInlineStyle(newEditorState, fontOption.value);
      }
    });

    // Now add the new font.
    newEditorState = RichUtils.toggleInlineStyle(newEditorState, changeStyle);
    onChangeTextState(newEditorState);
  };

  /**
   * Selects the font when the arrow keys are used on the font list.
   *
   * @param {*} e
   */
  const onInputKeyUp = (e) => {
    const selectMenuOpen = lodash.get(selectRef, 'current.state.menuIsOpen', null);
    if (!selectMenuOpen) {
      return;
    }

    const focusedOption = lodash.get(selectRef, 'current.select.state.focusedOption', null);
    if (!focusedOption) {
      return;
    }

    switch (e.keyCode) {
      case UP_ARROW_KEY_CODE:
      case DOWN_ARROW_KEY_CODE:

        onChange(focusedOption);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', onInputKeyUp);

    return () => {
      window.removeEventListener('keyup', onInputKeyUp);
    };
  }, []);

  const currentStyle = editorState.getCurrentInlineStyle();
  FONT_VALUES.forEach((fontOption) => {
    if (currentStyle.has(fontOption.value)) {
      currentValue = fontOption;
    }
  });

  const fontSelectStyles = {
    option: (provided, state) => ({...provided, fontFamily: state.label}),
    input: (provided) => ({...provided, fontFamily: currentValue.label}),
    singleValue: (provided) => ({...provided, fontFamily: currentValue.label}),
    placeholder: (provided) => ({...provided, fontFamily: currentValue.label}),
  };

  return (
    <div className="font-select">
      <ReactSelect
        id="font-select"
        ref={selectRef}
        className="site-select theme-input-white"
        classNamePrefix="react-select"
        closeMenuOnSelect={false}
        styles={fontSelectStyles}
        placeholder="Select Font..."
        onChange={onChange}
        options={FONT_VALUES}
        value={currentValue}
      />
    </div>
  );
};

FontSelect.propTypes = {
  editorState: PropTypes.object.isRequired,
  onChangeTextState: PropTypes.func.isRequired,

  beforeChange: PropTypes.func,
};

export default FontSelect;
