import PropTypes from 'prop-types';
import React from 'react';
import uuid from 'uuid/v4';

/**
 * Draws an svg arrow.
 *
 * @param {object} props
 * @returns {React.Element}
 */
const ArrowMarker = (props) => {
  const color = props.color || '#000000';

  const id = uuid();

  return (
    <svg className="arrow-marker" width="54px" height="100px">
      <defs>
        <marker
          id={id}
          markerWidth="10"
          markerHeight="10"
          refX="0"
          refY="3"
          orient="auto"
          markerUnits="strokeWidth"
          viewBox="0 0 10 10"
        >
          <path
            className="arrow-marker-head"
            d="M0,0 L0,6 L6,3 z"
            fill={color}
          />
        </marker>
      </defs>
      <line
        className="arrow-marker-line"
        x1="0"
        y1="50"
        x2="30"
        y2="50"
        stroke={color}
        strokeWidth="2"
        markerEnd={`url(#${id})`}
        strokeDasharray="5"
      />
    </svg>
  );
};

ArrowMarker.defaultProps = {};

ArrowMarker.propTypes = {
  color: PropTypes.string,
};

export default ArrowMarker;
