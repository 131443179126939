import {action} from 'mobx';

import {EXPIRES_IN, EXPIRES_PENDING, EXPIRE_TIME} from '../../../constants/storeConstants';
import {STATE_FULFILLED, STATE_PENDING, STATE_REJECTED} from '../../../constants/asyncConstants';
import serverApi from '../../../utils/serverApi';
import ApiBaseStore from '../common/apiBaseStore';

/**
 * ApiUserClearEmulateStore
 */
class ApiUserClearEmulateStore extends ApiBaseStore {
  /**
   * Clear user emulation from the hub server.
   */
  @action makeRequest() {
    this.state = STATE_PENDING;
    this[EXPIRE_TIME] = Date.now() + EXPIRES_PENDING;

    serverApi.clearEmulateUser().then(
      action('userClearEmulateSuccess', () => {
        this.state = STATE_FULFILLED;
        this[EXPIRE_TIME] = Date.now() + EXPIRES_IN;
        this.data = null;
        this.error = null;
      }),
      action('userClearEmulateError', (clearEmulateError) => {
        this.state = STATE_REJECTED;
        this[EXPIRE_TIME] = null;
        this.data = null;
        this.error = clearEmulateError;
      })
    );
  }
}

export default new ApiUserClearEmulateStore();
