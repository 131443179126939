import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import TransitionControlSection from './transition/TransitionControlSection';

import './editTransitionControl.scss';

/**
 * The EditTransitionControl component.
 */
export class EditTransitionControl extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      /** @type {?ObservableMap} */ entity,
      /** @type {GameStore} */ game
    } = this.props;

    if (game.composeMode) {
      // Compose mode disables most editing abilities.
      return null;
    }

    return (
      <div className="edit-transition-control">
        {(
          <TransitionControlSection
            entity={entity}
            game={game}
          />
        )}
      </div>
    );
  }
}

EditTransitionControl.propTypes = {
  game: MobxPropTypes.observableObject.isRequired,

  entities: PropTypes.arrayOf(MobxPropTypes.observableMap),
  entity: MobxPropTypes.observableMap,
};

export default observer(EditTransitionControl);
