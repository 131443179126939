import classNames from 'classnames';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import entityActivateHoc from '../../hoc/entityActivateHoc';
import {onClickEntityEvent} from '../utils';

import './displayTriangle.scss';

/**
 * The DisplayTriangle component.
 */
export class DisplayTriangle extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, style, topStyle, className, onEntityClick, game} = this.props;

    const entityId = entity.get('id');

    const triangle = entity.get('triangle');
    const triangleStyle = triangle.style;
    const triangleBorder = triangle.border;

    const width = parseInt(style.width, 10);
    const height = parseInt(style.height, 10);
    const halfWidth = width / 2;

    const borderSize = parseInt(triangleBorder.width, 10) || 0;

    const topPoint = `0 ${width}`;
    const rightPoint = `${halfWidth} 0`;
    const leftPoint = `${width} ${height}`;

    const onClickEvent = onClickEntityEvent(game, entity, onEntityClick);

    return (
      <div
        id={entityId}
        className={classNames('display-triangle', className)}
        style={{
          ...topStyle,
          pointerEvents: 'none',
        }}
      >
        <div style={style}>
          <svg viewBox={`0 0 ${width} ${height}`} className="display-triangle-svg">
            <polygon
              points={`${leftPoint}, ${topPoint}, ${rightPoint}`}
              width={width - borderSize}
              height={height - borderSize}
              fill={triangleStyle.color}
              fillOpacity={triangleStyle.opacity}
              stroke={triangleBorder.color}
              strokeOpacity={triangleBorder.opacity}
              strokeWidth={borderSize}
              style={{
                cursor: onClickEvent ? 'pointer' : 'inherit',
                pointerEvents: 'visible',
              }}
              onClick={onClickEvent}
            />
          </svg>
        </div>
      </div>
    );
  }
}

DisplayTriangle.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
  style: PropTypes.object.isRequired,
  topStyle: PropTypes.object.isRequired,

  className: PropTypes.string,
  onEntityClick: PropTypes.func,
};

export default entityActivateHoc(
  observer(DisplayTriangle)
);
