import lodash from 'lodash';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinus} from '@fortawesome/free-solid-svg-icons';

import ConfirmModal from '../../modals/confirm/ConfirmModal';

import inject from '../../hoc/injectHoc';
import {actionGameResizeComponent} from '../../../display/components/action/actionGameResizeComponent';
import {ratioToNumber} from '../../../utils/mathHelper';

import './editorRemoveDimension.scss';

/**
 * The EditorNewDimensions component.
 *
 * @property {{
 *   displayEditorStore: DisplayEditorStore,
 * }} props
 */
export class EditorRemoveDimension extends React.Component {
  /**
   * Whether or not the delete modal is open.
   *
   * @type {boolean}
   */
  @observable isDeleteModalOpen = false;

  /**
   * Handles when a piece of content is selected for being deleted.
   */
  @action onDeleteStart = () => {
    this.isDeleteModalOpen = true;
  };

  /**
   * Updates the aspect ratio of the system.
   *
   * @param {boolean} wasConfirmed
   */
  @action onRemoveDimension = (wasConfirmed) => {
    this.isDeleteModalOpen = false;

    if (!wasConfirmed) {
      // The user said they did not want to delete the content or cancelled the modal.
      return;
    }

    const {displayEditorStore} = this.props;
    const {currentAspectRatio, game, sources} = displayEditorStore;

    const actionParams = {
      entityId: true,
    };

    const currentResolution = {...game.resolution};

    const currentRatioNumber = ratioToNumber(currentAspectRatio);

    // First find the closest matching existing aspect ratio.
    const existingRatios = lodash.map(sources.toJSON(), (source, sourceAspectRatio) => {
      return {
        aspectRatio: sourceAspectRatio,
        aspectRatioNumber: ratioToNumber(sourceAspectRatio),
        source
      };
    });

    const sortedRatios = lodash.sortBy(existingRatios, ({aspectRatioNumber}) => {
      return Math.abs(aspectRatioNumber - currentRatioNumber);
    });
    sortedRatios.shift();

    const closestSource = lodash.first(sortedRatios);

    displayEditorStore.setSource(closestSource.aspectRatio, closestSource.source);
    displayEditorStore.removeSource(currentAspectRatio);

    game.addAction(actionParams, actionGameResizeComponent(
      closestSource.aspectRatio,
      closestSource.source.resolution.width,
      closestSource.source.resolution.height,
      currentResolution.width,
      currentResolution.height,
    ));
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {currentAspectRatio} = this.props.displayEditorStore;

    return (
      <span className="editor-remove-dimension">
        <button
          type="button"
          id="remove-dimension"
          className="btn btn-sm btn-light btn-default"
          data-offset="-70,0"
          aria-haspopup="true"
          onClick={this.onDeleteStart}
        >
          <FontAwesomeIcon icon={faMinus} />
        </button>

        <div className="content-delete">
          <ConfirmModal
            isOpen={this.isDeleteModalOpen}
            onComplete={this.onRemoveDimension}
            title="Delete Aspect Ratio?"
            confirmText={`Are you sure you want to remove the current aspect ratio of ${currentAspectRatio}?`}
            isYesNo={true}
          />
        </div>
      </span>
    );
  }
}

EditorRemoveDimension.propTypes = {
  displayEditorStore: MobxPropTypes.observableObject,
};

EditorRemoveDimension.wrappedComponent = {};
EditorRemoveDimension.wrappedComponent.propTypes = {
  displayEditorStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(EditorRemoveDimension)(
  observer(EditorRemoveDimension)
);
