import classNames from 'classnames';
import lodash from 'lodash';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import SingleSlider from '../singleSlider/SingleSlider';

import './scrollbars.scss';

/**
 * The Scrollbars component.
 */
export class Scrollbars extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      className,
      children,
      maxHorizontal,
      maxVertical,
      minHorizontal,
      minVertical,
      onChangeHorizontal,
      onChangeVertical,
      stepHorizontal,
      stepVertical,
      valueHorizontal,
      valueVertical,
    } = this.props;

    const extraClasses = {};
    if (className) {
      extraClasses[className] = true;
    }

    return (
      <div className={classNames('scrollbars', extraClasses)}>
        {(valueVertical !== null) && (
          <SingleSlider
            className="scrollbar-vertical"
            elongateHandle={100}
            minValue={minVertical}
            maxValue={maxVertical}
            onChange={onChangeVertical}
            step={stepVertical}
            value={valueVertical}
            vertical={true}
          />
        )}

        {children}

        {(valueHorizontal !== null) && (
          <SingleSlider
            className="scrollbar-horizontal"
            elongateHandle={100}
            minValue={minHorizontal}
            maxValue={maxHorizontal}
            onChange={onChangeHorizontal}
            step={stepHorizontal}
            value={valueHorizontal}
            vertical={false}
          />
        )}
      </div>
    );
  }
}

Scrollbars.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,

  className: PropTypes.string,
  maxHorizontal: PropTypes.number,
  maxVertical: PropTypes.number,
  minHorizontal: PropTypes.number,
  minVertical: PropTypes.number,
  onChangeHorizontal: PropTypes.func,
  onChangeVertical: PropTypes.func,
  stepHorizontal: PropTypes.number,
  stepVertical: PropTypes.number,
  valueHorizontal: PropTypes.number,
  valueVertical: PropTypes.number,
};

Scrollbars.defaultProps = {
  maxHorizontal: 100,
  maxVertical: 100,
  minHorizontal: 0,
  minVertical: 0,
  onChangeHorizontal: lodash.noop,
  onChangeVertical: lodash.noop,
  stepHorizontal: 1,
  stepVertical: 1,
  valueHorizontal: null,
  valueVertical: null,
};

export default observer(Scrollbars);
