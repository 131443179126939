import * as Sentry from '@sentry/browser';
import lodash from 'lodash';

import {getResponseHeaders} from './serverRequest';
import mainConfig from '../config/main';

/**
 * Initializes sentry for this application.
 *
 * @returns {boolean}
 */
export function sentryInit() {
  if (!mainConfig.sentry.dsn) {
    return false;
  }

  Sentry.init({
    dsn: mainConfig.sentry.dsn,
    environment: mainConfig.env,
  });

  return true;
}

/**
 * Reports an API error.
 *
 * @param {Error} apiError
 * @param {{}} requestOptions
 * @param {Response|{data: {}, status: number, headers: {}}} response
 */
export function reportApiError(apiError, requestOptions, response) {
  if (!mainConfig.sentry.dsn) {
    return;
  }

  const safeOptions = lodash.cloneDeep(requestOptions);
  if (safeOptions.data) {
    delete safeOptions.data.password;
  }
  if (requestOptions.headers) {
    safeOptions.headers = getResponseHeaders(requestOptions.headers);
  }

  const responseStatus = (response) ? (response.status || '') : null;

  Sentry.setExtra('requestOptions', safeOptions);
  Sentry.setExtra('apiErrorStatus', responseStatus);

  if (response) {
    Sentry.setExtra('apiResponse', {
      ok: response.ok,
      redirected: response.redirected,
      status: response.status,
      statusText: response.statusText,
      type: response.type,
      url: response.url,
      headers: getResponseHeaders(response.headers),
    });
  }

  if (apiError instanceof Error) {
    Sentry.captureException(apiError);
  } else {
    Sentry.setExtra('apiError', apiError);

    const errorForSentry = new Error(apiError.message || `An api error occurred with status ${responseStatus}.`);
    Sentry.captureException(errorForSentry);
  }
}

/**
 * Reports an issue to sentry.
 *
 * @param {Error} error
 * @param {{}=} extras
 */
export function reportIssue(error, extras) {
  if (!mainConfig.sentry.dsn) {
    return;
  }

  if (!(error instanceof Error)) {
    return;
  }

  if (extras) {
    Sentry.setExtras(lodash.cloneDeep(extras));
  }

  Sentry.captureException(error);
}
