import React from 'react';
import {Alert} from 'reactstrap';
import PropTypes from 'prop-types';

/**
 * The CannotUpdatePlanAlert component.
 *
 * @param {{}} props
 * @returns {{}}
 */
function CannotUpdatePlanAlert(props) {
  const {message} = props;
  return (
    <Alert color="primary" className="signup-alert">
      <p>{message || 'You are not eligible to change plans at this time.'}</p>
      If you think this is a mistake please contact info@projectcontent.com.
    </Alert>
  );
}

CannotUpdatePlanAlert.propTypes = {
  message: PropTypes.string.isRequired
};

export default CannotUpdatePlanAlert;
