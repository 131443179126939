import {Ease, Power0} from 'gsap';

/**
 * All the Greensock Easings by name.
 * These keys were imported from Ease.map.
 *
 * @type {Object.<string, string>}
 */
export const EASINGS = {
  easeInBack: 'easeInBack',
  easeInBounce: 'easeInBounce',
  easeInCirc: 'easeInCirc',
  easeInCubic: 'easeInCubic',
  easeInElastic: 'easeInElastic',
  easeInExpo: 'easeInExpo',
  easeInLinear: 'easeInLinear',
  easeInOutBack: 'easeInOutBack',
  easeInOutBounce: 'easeInOutBounce',
  easeInOutCirc: 'easeInOutCirc',
  easeInOutCubic: 'easeInOutCubic',
  easeInOutElastic: 'easeInOutElastic',
  easeInOutExpo: 'easeInOutExpo',
  easeInOutLinear: 'easeInOutLinear',
  easeInOutPower0: 'easeInOutPower0',
  easeInOutPower1: 'easeInOutPower1',
  easeInOutPower2: 'easeInOutPower2',
  easeInOutPower3: 'easeInOutPower3',
  easeInOutPower4: 'easeInOutPower4',
  easeInOutQuad: 'easeInOutQuad',
  easeInOutQuart: 'easeInOutQuart',
  easeInOutQuint: 'easeInOutQuint',
  easeInOutSine: 'easeInOutSine',
  easeInOutStrong: 'easeInOutStrong',
  easeInPower0: 'easeInPower0',
  easeInPower1: 'easeInPower1',
  easeInPower2: 'easeInPower2',
  easeInPower3: 'easeInPower3',
  easeInPower4: 'easeInPower4',
  easeInQuad: 'easeInQuad',
  easeInQuart: 'easeInQuart',
  easeInQuint: 'easeInQuint',
  easeInSine: 'easeInSine',
  easeInStrong: 'easeInStrong',
  easeNoneLinear: 'easeNoneLinear',
  easeNonePower0: 'easeNonePower0',
  easeOutBack: 'easeOutBack',
  easeOutBounce: 'easeOutBounce',
  easeOutCirc: 'easeOutCirc',
  easeOutCubic: 'easeOutCubic',
  easeOutElastic: 'easeOutElastic',
  easeOutExpo: 'easeOutExpo',
  easeOutLinear: 'easeOutLinear',
  easeOutPower0: 'easeOutPower0',
  easeOutPower1: 'easeOutPower1',
  easeOutPower2: 'easeOutPower2',
  easeOutPower3: 'easeOutPower3',
  easeOutPower4: 'easeOutPower4',
  easeOutQuad: 'easeOutQuad',
  easeOutQuart: 'easeOutQuart',
  easeOutQuint: 'easeOutQuint',
  easeOutSine: 'easeOutSine',
  easeOutStrong: 'easeOutStrong',
  easeRoughEase: 'easeRoughEase',
  easeSlowMo: 'easeSlowMo',
  easeSteppedEase: 'easeSteppedEase',
  linear: 'linear',
  swing: 'swing',
};

/**
 * Returns whether or not the given easing name is a valid easing.
 *
 * @param {string} easingName
 * @returns {boolean}
 */
export function isEasingValid(easingName) {
  return Boolean(EASINGS[easingName]);
}

/**
 * Gets the valid Greensock easing object for the given name.
 * Defaults to linear easing if the easingName is invalid or not found.
 *
 * @param {string} easingName
 * @returns {{}}
 */
export function getEasingFromName(easingName) {
  if (!easingName || !isEasingValid(easingName)) {
    return Power0.easeNone;
  }

  const easeFromMap = Ease.map[easingName];
  if (easeFromMap) {
    return easeFromMap;
  }

  return Power0.easeNone;
}
