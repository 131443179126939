import lodash from 'lodash';
import {isObservable, toJS} from 'mobx';

/**
 * The transform component.
 *
 * @param {{rotate: number}} transformProperties
 * @returns {{transform: {default: {}}}}
 */
export function transformComponent(transformProperties) {
  const transforms = addTransformsToComponent(transformProperties, {});

  const defaults = {
    opacity: 1,
    perspective: 0,
    perspectiveOrigin: {x: 0, y: 0},
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    rotateZ: 0,
    rotate3d: {x: 0, y: 0, z: 0, angle: 0},
    scale: 1,
    scaleX: 1,
    scaleY: 1,
    scaleZ: 1,
    scale3d: {x: 1, y: 1, z: 1},
    skew: 0,
    skewX: 0,
    skewY: 0,
    translate: 0,
    translateX: 0,
    translateY: 0,
    translateZ: 0,
    translate3d: {x: 0, y: 0, z: 0},
  };

  return {
    transform: {
      ...defaults,
      ...transforms,
      default: {
        ...defaults,
        ...transforms,
      },
    },
  };
}

/**
 * Adds transforms into the given simple component object.
 * This is not for directly updating an entity!
 *
 * @param {{}} transforms
 * @param {{}} component
 * @returns {{}}
 */
export function addTransformsToComponent(transforms, component) {
  const safeComponent = (isObservable(component)) ? toJS(component) : component;

  const validProperties = lodash.pick(transforms, [
    'perspective',
    'rotate', 'rotateX', 'rotateY', 'rotateZ', 'rotate3d',
    'scale', 'scaleX', 'scaleY', 'scaleZ', 'scale3d',
    'skew', 'skewX', 'skewY',
    'translate', 'translateX', 'translateY', 'translateZ', 'translate3d',
  ]);

  const safeProperties = {};
  lodash.forEach(validProperties, (propertyValue, propertyName) => {
    safeProperties[propertyName] = propertyValue;
  });

  return {
    ...safeComponent,
    ...safeProperties,
  };
}

/**
 * Gets the transform component from the source item.
 *
 * @param {{transform: {}}} item
 * @returns {{transform: {y: number, x: number, zIndex: number}}}
 */
export function getTransformFromSource(item) {
  if (!item.transform) {
    return {};
  }

  return transformComponent(item.transform);
}

/**
 * Parses an entity back into source JSON.
 *
 * @returns {{}}
 */
export function getTransformForSource() {
  return {};
}
