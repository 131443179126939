import classNames from 'classnames';
import lodash from 'lodash';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import ArrowMarker from '../arrowMarker/ArrowMarker';
import inject from '../../../../hoc/injectHoc';
import {actionInteractionComponent} from '../../../../../display/components/action/actionInteractionComponent';

import './prestoEntity.scss';

/**
 * The PrestoEntity component.
 */
export class PrestoEntity extends React.Component {
  /**
   * Activates the entity.
   */
  activateEntity = () => {
    const {entity} = this.props;
    const {game, timer} = this.props.displayEditorStore;

    const actionParams = {
      entityId: entity.get('id'),
      skipHistory: true,
    };

    game.addAction(actionParams, actionInteractionComponent(true, false, false));

    const activeTime = entity.get('time').active;
    timer.setTime(activeTime);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, entityOrder, isFirst, isPrev} = this.props;
    const interaction = entity.get('interaction');
    const active = Boolean(interaction && interaction.isActive);

    const topClasses = {
      active,
      'is-previous': isPrev,
    };

    let entityName = lodash.upperFirst(entity.get('element'));
    if (entity.has('name')) {
      entityName = entity.get('name');
    }

    return (
      <a
        className={classNames('presto-entity', topClasses)}
        onClick={(clickEvent) => this.activateEntity(false, clickEvent)}
        title={entityName}
      >
        {(!isFirst) && (
          <span className="step-arrow">
            <ArrowMarker color="#ffffff" />
          </span>
        )}

        <span className="entity-name-wrapper clearfix">
          <span className="entity-order">
            <span className="badge badge-pill">{entityOrder}</span>
          </span>
          <span className="entity-name">
            {entityName}
          </span>
        </span>
      </a>
    );
  }
}

PrestoEntity.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  entityOrder: PropTypes.number.isRequired,

  displayEditorStore: MobxPropTypes.observableObject,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  isPrev: PropTypes.bool,
};

PrestoEntity.defaultProps = {
  isFirst: false,
  isLast: false,
  isPrev: false,
};

PrestoEntity.wrappedComponent = {};
PrestoEntity.wrappedComponent.propTypes = {
  displayEditorStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(PrestoEntity)(
  observer(PrestoEntity)
);
