import lodash from 'lodash';
import {action, computed, runInAction, toJS, when} from 'mobx';

import ApiMapBaseStore from '../common/apiMapBaseStore';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED, STATE_PRE} from '../../../constants/asyncConstants';
import {getCase} from '../../../utils/apiStore';
import serverApi from '../../../utils/serverApi';

/**
 * Conversion factor to a percentage.
 * @const {number}
 */
const TO_PERCENT = 100;

/**
 * Manages File upload flow
 */
class ApiFileUploadStore extends ApiMapBaseStore {
  /**
   * Gets the state value for uploading as a whole.
   *
   * @returns {Symbol}
   */
  @computed get uploadingState() {
    let state = STATE_PRE;
    this.dataMap.forEach((uploadData) => {
      if (!uploadData || !uploadData.state) {
        return;
      } else if (state === STATE_PENDING) {
        // If anything is pending, then all are pending.
        return;
      }

      if (state === STATE_PRE) {
        state = uploadData.state;
        return;
      } else if (uploadData.state === STATE_PENDING) {
        state = STATE_PENDING;
        return;
      }

      if (state === STATE_FULFILLED) {
        return;
      } else if (uploadData.state === STATE_FULFILLED) {
        state = STATE_FULFILLED;
        return;
      }

      state = STATE_REJECTED;
    });

    return state;
  }

  /**
   * Clears a single file uploading data.
   *
   * @param {string} fileKey
   */
  @action clearUpload(fileKey) {
    if (!fileKey) {
      return;
    }

    const safeFileKey = String(fileKey);
    if (!this.dataMap.has(safeFileKey)) {
      return;
    }

    this.dataMap.delete(safeFileKey);
  }

  /**
   * Cancels all files currently being uploaded.
   */
  @action cancelAll() {
    this.dataMap.forEach((uploadingData) => {
      const cancel = uploadingData.cancel;
      if (!cancel || typeof cancel !== 'function') {
        return;
      }

      cancel();
    });
  }

  /**
   * Cancels an ongoing upload.
   *
   * @param {string} fileKey
   */
  @action cancelUpload(fileKey) {
    if (!fileKey) {
      return;
    }

    const safeFileKey = String(fileKey);
    if (!this.dataMap.has(safeFileKey)) {
      return;
    }

    const cancel = this.dataMap.get(safeFileKey).cancel;
    if (!cancel || typeof cancel !== 'function') {
      return;
    }

    cancel();
  }

  /**
   * Adds rejected files to the file upload map.
   *
   * @param {Array<{name: string, message: string}>=} rejectedFiles
   */
  @action addRejectedFiles(rejectedFiles) {
    if (!rejectedFiles || !Array.isArray(rejectedFiles)) {
      return;
    }

    rejectedFiles.forEach((file) => {
      const fileKey = String(file.name);

      runInAction('addNewRejectedFile', () => {
        this.dataMap.set(fileKey, {
          data: file,
          state: STATE_REJECTED,
          progress: 0,
          error: file.error,
          cancel: null,
        });
      });
    });
  }

  /**
   * Uploads each of the passed in files.
   *
   * @param {Array.<{}>} files
   * @param {number} folderId
   * @returns {Promise}
   */
  @action makeRequest(files, folderId) {
    const title = '';

    this.dataMap.clear();

    const fileUploadRequests = files.map((file) => {
      const fileKey = String(file.name);
      file.title = fileKey.split('.')[0];

      runInAction('addNewUploadingFile', () => {
        this.dataMap.set(fileKey, {
          data: file,
          fileId: null,
          state: STATE_PENDING,
          progress: 0,
          error: null,
          cancel: null,
        });
      });

      const onProgress = action((progressEvent) => {
        this.dataMap.get(fileKey).progress = Math.floor(
          (progressEvent.loaded * TO_PERCENT) / progressEvent.total
        );
      });

      const cancelable = action((cancelUpload) => {
        // Saves the cancel function that will stop the uploading request.
        this.dataMap.get(fileKey).cancel = cancelUpload;
      });

      return serverApi.fileUpload(
        file,
        folderId,
        title,
        onProgress,
        cancelable
      ).then(
        action('fileUploadedSuccess', (newFileIds) => {
          this.dataMap.get(fileKey).state = STATE_FULFILLED;
          this.dataMap.get(fileKey).fileId = (Array.isArray(newFileIds) ? newFileIds[0] : newFileIds);
        }),
        action('fileUploadedError', (uploadError) => {
          const uploadingData = this.dataMap.get(fileKey);
          if (!uploadingData) {
            // It is possible that after canceling everything, then clearing the keys, this could happen.
            return;
          }

          uploadingData.state = STATE_REJECTED;
          uploadingData.error = uploadError;
          throw new Error(uploadError.message);
        })
      );
    });

    return Promise.all(fileUploadRequests);
  }

  /**
   * Runs handlers for uploading as a whole based on changes to the individual files' states.
   *
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  uploadCase(handlers) {
    const getFulfilled = () => {
      return true;
    };
    const getRejected = () => {
      return new Error('One or more uploads had an error.');
    };

    const state = this.uploadingState;
    return getCase(state, getFulfilled, getRejected, handlers);
  }

  /**
   * Gets a promise for all uploading files.
   *
   * @param {boolean} onlyFulfilled
   * @returns {Promise}
   */
  getUploadPromise(onlyFulfilled) {
    const thisStore = this;

    return new Promise((resolve, reject) => {
      when(
        () => {
          const state = thisStore.uploadingState;
          return (state === STATE_FULFILLED || state === STATE_REJECTED);
        },
        () => {
          const state = thisStore.state || null;
          if (state === STATE_REJECTED) {
            reject(new Error('All the file uploads had an error.'));
            return;
          }

          let files = lodash.values(toJS(this.dataMap));
          if (onlyFulfilled) {
            files = files.filter((item) => {
              return (item.state === STATE_FULFILLED);
            });
          }

          resolve(files);
        },
        {name: 'apiFileUploadStoreGetUploadPromise'}
      );
    });
  }
}

export default new ApiFileUploadStore();
