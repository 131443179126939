import classNames from 'classnames';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';
import {Tooltip} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDownLeftAndUpRightToCenter, faRepeat, faUndo} from '@fortawesome/free-solid-svg-icons';
import {faCircleDot} from '@fortawesome/free-regular-svg-icons';

import './displayControl.scss';

/**
 * The default zoom if no start is defined.
 * @const {number}
 */
const DEFAULT_ZOOM = 100;

/**
 * The DisplayControl component.
 *
 * @constructor
 */
export class DisplayControl extends React.Component {
  /**
   * Whether or not the tooltip for each button is showing.
   *
   * @type {{undo: boolean, redo: boolean, removeZoom: boolean, removePan: boolean}}
   */
  @observable isTooltipOpen = {
    undo: false,
    redo: false,
    removeZoom: false,
    removePan: false,
  };

  /**
   * Toggles the tooltip open or closed.
   *
   * @param {string} type
   * @returns {function}
   */
  onTooltipToggle = (type) => {
    return action(() => {
      this.isTooltipOpen[type] = !this.isTooltipOpen[type];
    });
  };

  /**
   * Triggered when the undo button is clicked.
   */
  onClickUndo = () => {
    const {game} = this.props;

    game.undoToLastPoint();
  };

  /**
   * Triggered when the redo button is clicked.
   */
  onClickRedo = () => {
    const {game} = this.props;

    game.redoLastUndo();
  };

  /**
   * Triggered when the un-zoom button is clicked.
   */
  onClickUnZoom = () => {
    const {zoomStore} = this.props;
    const {controlData} = zoomStore;
    const startZoom = (controlData) ? controlData.start : DEFAULT_ZOOM;

    zoomStore.setZoomLevel(startZoom);
  };

  /**
   * Triggered when the un-pan button is clicked.
   */
  onClickUnPan = () => {
    const {zoomStore} = this.props;

    zoomStore.setPanValues(0, 0);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {game, zoomStore} = this.props;
    const {hasUndo, hasRedo} = game.history;
    const {zoomLevel, controlData} = zoomStore;
    const startZoom = (controlData) ? controlData.start : DEFAULT_ZOOM;

    const isZoomed = (zoomLevel !== startZoom);
    const isPanned = Boolean(zoomStore.panX || zoomStore.panY);

    return (
      <div className="display-control">
        <div id="control-item-undo">
          <button
            type="button"
            id="control-btn-undo"
            className={classNames('btn btn-link btn-icon control-btn', {disabled: !hasUndo})}
            onClick={this.onClickUndo}
          >
            <FontAwesomeIcon icon={faUndo} />
          </button>
        </div>
        <Tooltip
          placement="left"
          isOpen={this.isTooltipOpen.undo}
          target="control-item-undo"
          toggle={this.onTooltipToggle('undo')}
        >
          Undo Changes
        </Tooltip>

        <div id="control-item-redo">
          <button
            type="button"
            id="control-btn-redo"
            className={classNames('btn btn-link btn-icon control-btn', {disabled: !hasRedo})}
            onClick={this.onClickRedo}
          >
            <FontAwesomeIcon icon={faRepeat} />
          </button>
        </div>
        <Tooltip
          placement="left"
          isOpen={this.isTooltipOpen.redo}
          target="control-item-redo"
          toggle={this.onTooltipToggle('redo')}
        >
          Redo Changes
        </Tooltip>

        <div id="control-item-remove-zoom">
          <button
            type="button"
            id="control-btn-remove-zoom"
            className={classNames('btn btn-link btn-icon control-btn', {disabled: !isZoomed})}
            onClick={this.onClickUnZoom}
          >
            <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} />
          </button>
        </div>
        <Tooltip
          placement="left"
          isOpen={this.isTooltipOpen.removeZoom}
          target="control-item-remove-zoom"
          toggle={this.onTooltipToggle('removeZoom')}
        >
          Reset Zooming
        </Tooltip>

        <div id="control-item-remove-pan">
          <button
            type="button"
            id="control-btn-remove-pan"
            className={classNames('btn btn-link btn-icon control-btn', {disabled: !isPanned})}
            onClick={this.onClickUnPan}
          >
            <FontAwesomeIcon icon={faCircleDot} />
          </button>
        </div>
        <Tooltip
          placement="left"
          isOpen={this.isTooltipOpen.removePan}
          target="control-item-remove-pan"
          toggle={this.onTooltipToggle('removePan')}
        >
          Reset Panning
        </Tooltip>
      </div>
    );
  }
}

DisplayControl.propTypes = {
  game: MobxPropTypes.observableObject.isRequired,
  zoomStore: MobxPropTypes.observableObject.isRequired,
};

export default observer(DisplayControl);
