import classNames from 'classnames';
import lodash from 'lodash';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faObjectGroup, faObjectUngroup} from '@fortawesome/free-regular-svg-icons';

import {actionGroupComponent} from '../../../../../display/components/action/actionGroupComponent';

import './groupEntitiesButtons.scss';

/**
 * The GroupEntitiesButtons component.
 */
export class GroupEntitiesButtons extends React.Component {
  /**
   * Groups the entities.
   */
  onGroupClick = () => {
    const {game} = this.props;

    const entityIds = game.allActiveEntities.map((activeEntity) => {
      return activeEntity.get('id');
    });
    const actionParams = {
      entityId: entityIds,
    };

    game.addAction(actionParams, actionGroupComponent(null));
  };

  /**
   * Un-groups the entities.
   */
  onUnGroupClick = () => {
    const {game} = this.props;

    const entityIds = game.allActiveEntities.map((activeEntity) => {
      return activeEntity.get('id');
    });
    const actionParams = {
      entityId: entityIds,
    };

    game.addAction(actionParams, actionGroupComponent(null, true));
  };

  /**
   * Gets the grouped states of the active content.
   *
   * @returns {{areSomeGrouped: boolean, areAllGrouped: boolean}}
   */
  getGroupedStates = () => {
    const {game} = this.props;

    const checkIsGrouped = (activeEntity) => {
      const group = activeEntity.get('group');
      return (group && group.id);
    };

    return {
      areSomeGrouped: lodash.some(game.allActiveEntities, checkIsGrouped),
      areAllGrouped: lodash.every(game.allActiveEntities, checkIsGrouped),
    };
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {areSomeGrouped, areAllGrouped} = this.getGroupedStates();

    return (
      <div className="group-entities-buttons">
        <div className="group-header">
          <span className="group-header-label">Grouping</span>
        </div>
        <div className="group-controls">
          <div className="row">
            <div className="col">
              <label htmlFor="group-entities">Group</label>
              <div>
                <button
                  type="button"
                  id="group-entities"
                  className={classNames('btn btn-sm btn-light form-button')}
                  onClick={this.onGroupClick}
                  disabled={areAllGrouped}
                >
                  <FontAwesomeIcon icon={faObjectGroup} />
                </button>
              </div>
            </div>
            <div className="col">
              <label htmlFor="ungroup-entities">Ungroup</label>
              <div>
                <button
                  type="button"
                  id="ungroup-entities"
                  className={classNames('btn btn-sm btn-light form-button')}
                  onClick={this.onUnGroupClick}
                  disabled={!areSomeGrouped}
                >
                  <FontAwesomeIcon icon={faObjectUngroup} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

GroupEntitiesButtons.propTypes = {
  game: PropTypes.object.isRequired,
};

export default observer(GroupEntitiesButtons);
