import interact from 'interactjs/src/index';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {findDOMNode} from 'react-dom';

import {actionReorderComponent} from '../../display/components/action/actionReorderComponent';

/**
 * The class that indicates that an entity is locked.
 * @const {string}
 */
export const LOCKED_CLASS = 'locked';

/**
 * Changes the entity order.
 *
 * @param {ObservableMap} entity
 * @param {GameStore} game
 * @param {number} slotsToMove
 */
function reorderEntity(entity, game, slotsToMove) {
  if (!slotsToMove) {
    return;
  }

  const params = {entityId: entity.get('id')};
  game.addAction(params, actionReorderComponent(-slotsToMove));
}

/**
 * A higher order component wrapper that handles entity drag reordering.
 *
 * @param {Object} WrappedComponent
 * @returns {Object}
 */
export default function entityReorderHocWrapper(WrappedComponent) {
  /**
   * The EntityReorderHoc higher order component.
   */
  class EntityReorderHoc extends React.Component {
    /**
     * The DOM element for the element.
     *
     * @type {HTMLElement}
     */
    @observable domEl = null;

    /**
     * The interactJS object initialized on the DOM element.
     *
     * @type {{draggable: function, resizable: function}}
     */
    @observable interaction = null;

    /**
     * @constructor
     * @param {{}} props
     * @param {{}} componentContext
     */
    constructor(props, componentContext) {
      super(props, componentContext);
    }

    /**
     * Triggered when the component is about to unmount.
     */
    componentWillUnmount() {
      this.stopDragging();
    }

    /**
     * Triggered right after the wrapped component is added to OR removed from the page.
     *
     * @param {{}} domEl
     */
    @action onChangeMount = (domEl) => {
      if (this.domEl) {
        return;
      }

      this.domEl = findDOMNode(domEl);
      this.initInteraction();
    };

    /**
     * Handles the drag move event.
     *
     * @param {{dx: number, dy: number}} dragEvent
     */
    onMove = (dragEvent) => {
      if (!dragEvent.dy) {
        return;
      }

      const target = dragEvent.target;
      const newY = (Number.parseFloat(target.getAttribute('data-y')) || 0) + dragEvent.dy;

      target.style.transform = `translate(0, ${newY}px)`;
      target.setAttribute('data-y', newY);

      const slotSize = target.offsetHeight;
      const slotsShifted = newY / slotSize;
      const slotsMoved = (slotsShifted < 0) ? Math.ceil(slotsShifted) : Math.floor(slotsShifted);

      const parentEl = target.parentElement;
      const children = parentEl.children;

      let targetIndex = null;
      for (let iteration = 0; iteration < children.length; iteration += 1) {
        if (children[iteration] === target) {
          targetIndex = iteration;
          break;
        }
      }

      for (let iteration = 0; iteration < children.length; iteration += 1) {
        const childEl = children[iteration];
        if (childEl.classList.contains(LOCKED_CLASS)) {
          continue;
        } else if (targetIndex === iteration) {
          continue;
        }

        let transformY = '0';
        if (slotsMoved < 0 && iteration < targetIndex && iteration >= targetIndex + slotsMoved) {
          transformY = `${slotSize}px`;
        } else if (slotsMoved > 0 && iteration > targetIndex && iteration <= targetIndex + slotsMoved) {
          transformY = `${-slotSize}px`;
        }

        childEl.style.transform = `translate(0, ${transformY})`;
      }
    };

    /**
     * Handles the drag end event.
     *
     * @param {{dx: number, dy: number}} dragEndEvent
     */
    onEndMove = (dragEndEvent) => {
      const target = dragEndEvent.target;
      const newY = dragEndEvent.pageY - dragEndEvent.y0;

      target.setAttribute('data-y', 0);

      const parentEl = target.parentElement;
      for (let iter = 0; iter < parentEl.children.length; iter += 1) {
        const elementStyle = parentEl.children[iter].style;
        if (elementStyle.removeProperty) {
          elementStyle.removeProperty('transform');
        } else {
          elementStyle.transform = '';
        }
      }

      const slotsShifted = newY / target.offsetHeight;
      let slotsMoved = (slotsShifted < 0) ? Math.ceil(slotsShifted) : Math.floor(slotsShifted);

      const {entity, game} = this.props;

      reorderEntity(entity, game, slotsMoved);
    };

    /**
     * Starts the interactJS code.
     */
    @action initInteraction = () => {
      if (this.interaction) {
        // Make sure we don't have multiple dragging interactions on this element.
        this.interaction.unset();
      }

      const interaction = interact(this.domEl);
      interaction.draggable({
        allowFrom: '.entity-reorder-action',
        onmove: this.onMove,
        onend: this.onEndMove,
      });

      this.interaction = interaction;
    };

    /**
     * Unbinds the dragging code.
     */
    @action stopDragging = () => {
      if (!this.interaction) {
        return;
      }

      this.interaction.unset();
      this.interaction = null;
    };

    /**
     * Renders the WrappedComponent.
     *
     * @returns {Object}
     */
    render() {
      if (!WrappedComponent) {
        return null;
      }

      if (this.props.ref) {
        throw new Error('EntityReorderHoc will override ref property given to the wrapped component.');
      }

      return (
        <WrappedComponent
          {...this.props}
          ref={this.onChangeMount}
        />
      );
    }
  }

  EntityReorderHoc.propTypes = {
    entity: MobxPropTypes.observableMap.isRequired,
    game: PropTypes.shape({
      setEntityComponents: PropTypes.func,
    }).isRequired,
    ref: PropTypes.func,
  };

  return observer(EntityReorderHoc);
}
