import lodash from 'lodash';

import {EASINGS} from '../../ecs/easingHelper';
import fadeInGif from '../../../assets/images/transitions/fadeIn.gif';

/**
 * The name that signals this preset.
 * @const {string}
 */
export const PRESET_FADE_OUT = 'fadeOut';

/**
 * The preset details.
 *
 * @type {{
 *   name: string,
 *   flow: string,
 *   details: Object.<string, {
 *     easing: string,
 *     parse: boolean,
 *     values: *|Array.<*>,
 *     time: {start: (string|number), end: (string|number)}
 *   }>,
 *   template: {}
 * }}
 */
export const fadeOutPresetDetails = {
  // The name of the preset.
  name: PRESET_FADE_OUT,

  // A human friendly name for the preset.
  humanName: 'Fade Out',

  // A preview of the transition.
  gif: fadeInGif,

  // The element names that this preset can be used for or true for all elements.
  elements: true,

  // The preset direction: in, out, middle. This is used in the UI logic.
  flow: 'out',

  // Each property that will change during the transition.
  details: {
    opacity: {
      // The name of the easing this property will use.
      easing: EASINGS.linear,

      // Parse false turns off parsing on-the-fly variables from the value items.
      parse: false,

      // The start and end times for the transition of this property.
      time: {
        start: 'end.-1000',
        end: 'end.0',
      },

      // The value of the property that will change during the transition.
      values: [1, 0],

      // Do not immediately set properties. wait until animation starts
      immediateRender: false
    },
  },

  // How this preset can be used in the source (including optional parameters).
  // This will be turned into a JSON string, so no functions can be used.
  template: {
    preset: PRESET_FADE_OUT,
    options: {},
  },
};

fadeOutPresetDetails.template.options = lodash.cloneDeep(fadeOutPresetDetails.details);

// This defines the key in the presets object.
export const presetName = PRESET_FADE_OUT;

// This defines the value of the presets object.
export default fadeOutPresetDetails;
