import {action, observable} from 'mobx';

/**
 * The session store.
 */
class SessionStore {
  /**
   * The id of the active user
   *
   * @type {?number}
   */
  @observable userId = null;

  /**
   * Sets the user id.
   *
   * @param {?number} newUserId
   */
  @action setUserId(newUserId) {
    this.userId = (newUserId) ? Number(newUserId) : null;
  }

  /**
   * Sets the user id
   *
   * @param {?{}} currentUser
   */
  @action setCurrentUser(currentUser) {
    if (!currentUser) {
      this.setUserId(null);
    } else {
      this.setUserId(currentUser.projectContentUserId);
    }
  }

  /**
   * Clears the store.
   */
  @action clearAll() {
    this.userId = null;
  }
}

export default new SessionStore();
