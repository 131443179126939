import lodash from 'lodash';
import {action, computed, observable, runInAction} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';
import {Alert} from 'reactstrap';

import LoadingIcon from '../../../../common/loadingIcon/LoadingIcon';
import inject from '../../../../hoc/injectHoc';
import {onboardingRoute} from '../../../../routePaths';
import auth0 from '../../../../../utils/auth0';
import mainConfig from '../../../../../config/main';
import cookieHelper from '../../../../../utils/cookieHelper';

import './signupForm.scss';

/**
 * The SignupForm component.
 */
export class SignupForm extends React.Component {
  /**
   * First Name form value
   *
   * @type {string}
   */
  @observable firstName = '';

  /**
   * Last Name form value
   *
   * @type {string}
   */
  @observable lastName = '';

  /**
   * Email form value
   *
   * @type {string}
   */
  @observable email = '';

  /**
   * Company Name form value
   *
   * @type {string}
   */
  @observable companyName = '';

  /**
   * Allow Company Name edit
   *
   * @type {boolean}
   */
  @observable canEditCompanyName = true;

  /**
   * The error that occurred during the signup process.
   *
   * @type {?Error}
   */
  @observable signupError = null;

  /**
   * The validation errors for the form.
   *
   * @type {Map}
   */
  @observable validationErrors = new Map();

  /**
   * Runs when component mounts.
   */
  async componentDidMount() {
    const {apiUserGetAuth0EmailStore, apiUserGetMeStore} = this.props;

    const isEmulating = !!cookieHelper.getEmulationAuthId();

    let email = null;
    let companyName = '';
    if (isEmulating) {
      const emulationAuthId = cookieHelper.getEmulationAuthId();

      apiUserGetAuth0EmailStore.makeRequest(emulationAuthId);
      const emulationEmail = await apiUserGetAuth0EmailStore.getPromise(emulationAuthId);
      const user = await apiUserGetMeStore.getFulfilled();
      companyName = user && user.company ? user.company.name : '';
      email = emulationEmail;
    } else {
      const auth0User = await auth0.getUser();
      const user = await apiUserGetMeStore.getFulfilled();
      companyName = user && user.company ? user.company.name : '';
      email = auth0User.email || user.email;
    }

    runInAction('initialize email and companyName', () => {
      this.email = email;
      this.companyName = companyName;

      if (companyName !== '') {
        this.canEditCompanyName = false;
      }
    });
  }

  /**
   * Combined name
   *
   * @type {string}
   */
  @computed get name() {
    return `${this.firstName} ${this.lastName}`;
  }

  /**
   * Tracks if the form is disabled.
   *
   * @returns {boolean}
   */
  @computed get isSubmitDisabled() {
    return Boolean(
      !this.email ||
      !this.name
    );
  }

  /**
   * Signup click handler
   *
   * @param {{}} clickEvent
   */
  signupClick = (clickEvent) => {
    clickEvent.preventDefault();

    const {apiSignupStore} = this.props;

    const signupBody = {
      firstName: this.firstName.trim(),
      lastName: this.lastName.trim(),
      email: this.email.trim(),
      companyName: this.companyName.trim(),
      password: this.password,
    };

    apiSignupStore.makeRequest(signupBody);

    apiSignupStore.getPromise().then(
      action('signupFormAfterSuccess', () => {
        this.signupError = null;
        this.validationErrors.clear();

        this.signupSuccess();
      }),
      action('signupFormAfterError', (signupError) => {
        let fieldErrors = lodash.get(signupError, 'fieldErrors', {});
        if (fieldErrors.body) {
          fieldErrors = fieldErrors.body;
        }

        if (fieldErrors) {
          this.validationErrors.clear();

          lodash.forEach(fieldErrors, (fieldError, fieldName) => {
            let safeFieldName = String(fieldName);
            let safeFieldError = String(fieldError);

            if (safeFieldName.indexOf(',') !== -1) {
              const [subName, subIndex] = safeFieldName.split(',');
              safeFieldName = subName;
              safeFieldError = safeFieldError.replace(`"${subIndex}"`, `"${subName}"`);
            }

            this.validationErrors.set(safeFieldName, safeFieldError);
          });
        }

        this.signupError = signupError;
      })
    );
  };

  /**
   * Update property [key] to be [value]
   * @param {string} key
   * @param {*} value
   */
  @action updateProperty = (key, value) => {
    this[key] = value;

    this.validationErrors.delete(key);

    if (key === 'firstName' || key === 'lastName') {
      this.validationErrors.delete('name');
    }
  };

  /**
   * Signup input onChange handler
   *
   * @param {{}} changeEvent
   */
  onChange = (changeEvent) => {
    const target = changeEvent.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.updateProperty(target.name, value);
  };

  /**
   * Signup input onBlur handler
   * These values will be trimmed before being sent in the request, but we're
   * also trimming them on blur so that this is more apparent to the user.
   *
   * @param {{}} blurEvent
   */
  onBlur = (blurEvent) => {
    const target = blurEvent.target;

    this.updateProperty(target.name, target.value.trim());
  };

  /**
   * Callback after a successful signup
   */
  signupSuccess = async () => {
    const {routerStore, apiUserGetMeStore} = this.props;

    apiUserGetMeStore.refresh(true);
    const toRoute = {pathname: onboardingRoute, search: 'signup=true'};

    routerStore.push(toRoute, {
      params: {
        planId: '',
      },
      replaceIfTo: true,
    });
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {apiSignupStore} = this.props;
    const errorsMap = this.validationErrors;

    return (
      <form id="signup-form" onSubmit={this.signupClick}>
        <div className="form-group">
          <label className="sr-only" htmlFor="email">Email</label>
          <input
            type="text"
            value={this.email}
            name="email"
            onBlur={this.onBlur}
            onChange={this.onChange}
            className="form-control form-control-bottom-border"
            id="email"
            placeholder="Email Address"
            data-cy="email-input"
            readOnly
          />

          {(errorsMap.has('email')) && (
            <div className="invalid-feedback">{errorsMap.get('email')}</div>
          )}
        </div>
        <div className="form-group">
          <label className="sr-only" htmlFor="companyName">Company Name</label>
          <input
            type="text"
            value={this.companyName}
            name="companyName"
            onBlur={this.onBlur}
            onChange={this.onChange}
            className="form-control form-control-bottom-border"
            id="companyName"
            placeholder="Company Name"
            data-cy="company-input"
            readOnly={!this.canEditCompanyName}
          />

          {(errorsMap.has('companyName')) && (
            <div className="invalid-feedback">{errorsMap.get('companyName')}</div>
          )}
        </div>
        <div className="row">
          <div className="form-group col">
            <label className="sr-only" htmlFor="firstName">First Name</label>
            <input
              type="text"
              value={this.firstName}
              name="firstName"
              onBlur={this.onBlur}
              onChange={this.onChange}
              className="form-control form-control-bottom-border"
              id="firstName"
              placeholder="First Name"
              data-cy="first-name-input"
            />

            {(errorsMap.has('name')) && (
              <div className="invalid-feedback">{errorsMap.get('name')}</div>
            )}
          </div>
          <div className="form-group col">
            <label className="sr-only" htmlFor="lastName">Last Name</label>
            <input
              type="text"
              value={this.lastName}
              name="lastName"
              onBlur={this.onBlur}
              onChange={this.onChange}
              className="form-control form-control-bottom-border"
              id="lastName"
              placeholder="Last Name"
              data-cy="last-name-input"
            />

            {(errorsMap.has('name')) && (
              <div className="invalid-feedback">{errorsMap.get('name')}</div>
            )}
          </div>
        </div>

        {apiSignupStore.case({
          pending: () => (<LoadingIcon size="sm" />),
        })}

        {(this.signupError) && (
          <Alert color="danger" isOpen={true}>
            {this.signupError.message}
          </Alert>
        )}

        <div className="d-flex align-items-center justify-content-end mt-4">
          <button type="button" className="btn btn-outline signup-button mr-2" onClick={() => auth0.logout({
            logoutParams: {
              returnTo: `${mainConfig.app.url}/dashboard`,
            },
          })}
          >
            Cancel
          </button>
          <input
            type="submit"
            disabled={this.isSubmitDisabled}
            className="btn btn-submit btn-primary text-light"
            value="Next"
            data-cy="signup-submit-button"
          />
        </div>
      </form>
    );
  }
}

SignupForm.propTypes = {
  apiSignupStore: MobxPropTypes.observableObject,
  apiUserGetAuth0EmailStore: MobxPropTypes.observableObject,
  apiUserGetMeStore: MobxPropTypes.observableObject,
  routerStore: MobxPropTypes.observableObject,
};

SignupForm.wrappedComponent = {};
SignupForm.wrappedComponent.propTypes = {
  apiSignupStore: MobxPropTypes.observableObject.isRequired,
  apiUserGetAuth0EmailStore: MobxPropTypes.observableObject.isRequired,
  apiUserGetMeStore: MobxPropTypes.observableObject.isRequired,
  routerStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(SignupForm)(
  observer(SignupForm)
);
