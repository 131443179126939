import lodash from 'lodash';
import {action, observable, runInAction, toJS} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Alert, Modal, ModalHeader, ModalBody, FormGroup, Input, Label} from 'reactstrap';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

import ConfirmModal from '../confirm/ConfirmModal';
import FileUploadingModal from '../fileUploading/FileUploadingModal';
import FileUpload from '../../common/fileUpload/FileUpload';
import LoadingIcon from '../../common/loadingIcon/LoadingIcon';
import PreloadWritableFolder from '../../common/preloadWritableFolder/PreloadWritableFolder';
import TextField from '../../common/textField/TextField';
import inject from '../../hoc/injectHoc';

import './contentRequestModal.scss';

const emptyFormData = {
  animationSpeed: 'slow',
  animationStyle: 'simple',
  colorScheme: '',
  purpose: '',
  emails: [],
  companyName: '',
  setDefaultCompanyName: null,
  message: '',
  phoneNumber: '',
  setDefaultPhoneNumber: null,
  signIds: [],

  // schedulingFinishDate: '',
  // schedulingStartDate: '',
};

/**
 * Content Request Modal component.
 */
class ContentRequestModal extends React.Component {
  /**
   * Content request form data.
   *
   * @type {{}}
   */
  @observable contentRequestForm = lodash.clone(emptyFormData);

  /**
   * The folder id where content request files can be uploaded.
   *
   * @type {?number}
   */
  @observable uploadFolderId = null;

  /**
   * The error that occurred during the save process.
   *
   * @type {?Error}
   */
  @observable saveError = null;

  /**
   * The validation errors for the form.
   *
   * @type {Map}
   */
  @observable validationErrors = new Map();

  /**
   * Whether or not the uploading modal is open.
   * This should match up directly to whether or not any files are uploading.
   *
   * @type {boolean}
   */
  @observable isUploadingModalOpen = false;

  /**
   * Whether or not there was an upload error.
   *
   * @type {?Error}
   */
  @observable uploadError = null;

  /**
   * The list of pending uploaded files.
   *
   * @type {Array}
   */
  @observable pendingFiles = [];

  /**
   * Disable submit button if the content request is currently submitting
   *
   * @type {Boolean}
   */
  @observable isSubmitting = false;

  /**
   * Triggered when the component just mounted onto the page.
   */
  componentDidMount() {
    if (this.props.isOpen) {
      this.initializeModal();
    }
  }

  /**
   * Triggered when the component has just updated.
   *
   * @param {{isOpen: boolean}} prevProps
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.initializeModal();
    }
  }

  /**
   * Initializes the modal.
   */
  @action initializeModal = () => {
    const {apiUserGetMeStore, apiCompanySignGetAllStore} = this.props;

    apiCompanySignGetAllStore.refresh();
    const user = apiUserGetMeStore.getFulfilled();

    this.contentRequestForm = lodash.clone(emptyFormData);
    this.contentRequestForm.companyName = user.company.name || '';
    this.contentRequestForm.phoneNumber = user.phoneNumber || '';
    this.contentRequestForm.emails = [user.email];
    this.saveError = null;
    this.validationErrors.clear();
  };

  /**
   * Tears down the modal.
   */
   @action tearDownModal = () => {
     this.isSubmitting = false;
   };

  /**
   * Update contentRequestForm property
   *
   * @param {string} key
   * @param {object} value
   */
  @action updateProperty = (key, value) => {
    const currentValue = this.contentRequestForm[key];

    if (key) {
      this.validationErrors.delete(key);
    }

    if (currentValue && currentValue.forEach) {
      const newValues = (Array.isArray(value)) ? value : [value];

      currentValue.replace(newValues);
      return;
    }

    this.contentRequestForm[key] = typeof value === 'boolean' ? Boolean(value) : String(value);
  };

  /**
   * Input onChange handler
   *
   * @param {{target: {}}} changeEvent
   */
  onChange = (changeEvent) => {
    const target = changeEvent.target;
    const value = (target.type === 'checkbox') ? target.checked : target.value;
    this.updateProperty(target.name, value);
  };

  /**
   * Changes a form select's value.
   *
   * @param {string} elementName
   * @returns {function}
   */
  onSelectUpdate = (elementName) => {
    return (selected) => {
      if (!Array.isArray(selected)) {
        this.updateProperty(elementName, selected.value);
        return;
      }

      let safeValues = selected.map(({value}) => {
        return value;
      }).filter((value) => (Boolean(value)));

      if (elementName === 'emails') {
        safeValues = safeValues.map((value) => {
          // Strip quotes, spaces, and <>s from email addresses.
          return String(value).replace(/[<>'" ]/g, '');
        }).filter((value) => {
          // Make sure the email has a basic structure of *@*.*
          return (value && Boolean(value.match(/.+@.+\..+/)));
        });
      }

      this.updateProperty(elementName, safeValues);
    };
  };

  /**
   * Stores a folder id when create request upload folders are found.
   *
   * @param {Array<{id: number, name: string}>} foundFolders
   */
  @action onUploadFolderFound = (foundFolders) => {
    if (!foundFolders || !foundFolders.length) {
      return;
    }

    this.uploadFolderId = foundFolders[0].id;
  };

  /**
   * Uploads the selected files.
   *
   * @param {Array<{}>} files
   * @param {Array<{name: string, message: string}>} rejectedFiles
   */
  onUploadFiles = (files, rejectedFiles) => {
    const {apiFileUploadStore} = this.props;

    this.openUploadingModal();

    if (rejectedFiles) {
      apiFileUploadStore.addRejectedFiles(rejectedFiles);
    }

    const closeAfterSuccessTimeout = 2000;

    apiFileUploadStore.makeRequest(
      files,
      this.uploadFolderId,
      rejectedFiles
    );

    apiFileUploadStore.getUploadPromise(true).then((newFiles) => {
      runInAction('afterUploadedPushPendingFiles', () => {
        newFiles.forEach((newFile) => {
          this.pendingFiles.push({
            id: newFile.fileId,
            name: newFile.data.name,
          });
        });
      });

      setTimeout(() => {
        this.closeUploadingModal();
      }, closeAfterSuccessTimeout);
    }).catch((uploadError) => {
      runInAction('showUploadError', () => {
        this.uploadError = uploadError;
      });
    });
  };

  /**
   * Dismisses the too many error alert.
   */
  @action onDismissError = () => {
    this.uploadError = null;
  };

  /**
   * Opens the uploading modal.
   */
  @action openUploadingModal = () => {
    this.isUploadingModalOpen = true;
  };

  /**
   * Closes the uploading modal.
   */
  @action closeUploadingModal = () => {
    this.isUploadingModalOpen = false;
  };

  /**
   * Removes a file from the array of pending files.
   *
   * @param {{}} pendingFile
   * @returns {function}
   */
  removePendingFile = (pendingFile) => {
    return action('removePendingFile', () => {
      this.pendingFiles.remove(pendingFile);
    });
  };

  /**
   * Submit content request form.
   *
   * @param {{preventDefault: function}} submitEvent
   */
  onSubmit = (submitEvent) => {
    submitEvent.preventDefault();

    const {apiContentRequestCreateStore, apiUserUpdateMeStore} = this.props;

    if (this.isSubmitting) {
      return;
    }

    runInAction('setIsSubmitting', () => {
      this.isSubmitting = true;
    });

    // Remove any previous validation errors.
    this.validationErrors.clear();

    const newDefaultUserValues = {
      ...(this.contentRequestForm.setDefaultCompanyName && {companyName: this.contentRequestForm.companyName}),
      ...(this.contentRequestForm.setDefaultPhoneNumber && {phoneNumber: this.contentRequestForm.phoneNumber})
    };

    if (this.contentRequestForm.setDefaultCompanyName || this.contentRequestForm.setDefaultPhoneNumber) {
      apiUserUpdateMeStore.makeRequest(newDefaultUserValues);
    }

    // delete setDefault fields before sending to the create method
    delete this.contentRequestForm.setDefaultCompanyName;
    delete this.contentRequestForm.setDefaultPhoneNumber;
    apiContentRequestCreateStore.makeRequest(
      toJS(this.contentRequestForm),
      this.pendingFiles
    );

    apiContentRequestCreateStore.getPromise().then(
      action('contentRequestModalSaveSuccess', () => {
        this.contentRequestForm = null;
        this.saveError = null;
        this.validationErrors.clear();

        this.onCompleteModal();
      }),
      action('contentRequestModalSaveError', (saveError) => {
        if (lodash.get(saveError, 'type') === 'OutOfCreditsError') {
          this.saveError = new Error('Could not submit request because you are out of Content Request credits.');
          return;
        }

        this.isSubmitting = false;

        const fieldErrors = lodash.get(saveError, 'fieldErrors.body');
        if (fieldErrors) {
          this.validationErrors.clear();

          lodash.forEach(fieldErrors, (fieldError, fieldName) => {
            let safeFieldName = String(fieldName);
            let safeFieldError = String(fieldError);

            if (safeFieldName.indexOf(',') !== -1) {
              const [subName, subIndex] = safeFieldName.split(',');
              safeFieldName = subName;
              safeFieldError = safeFieldError.replace(`"${subIndex}"`, `"${subName}"`);
            }

            this.validationErrors.set(safeFieldName, safeFieldError);
          });
        }

        this.saveError = saveError;
      })
    );
  };

  /**
   * Triggered when the modal is closed without a chosen image.
   */
  onCancelModal = () => {
    const {onComplete} = this.props;

    onComplete();

    this.tearDownModal();
  };

  /**
   * Triggered when the modal is closed after choosing a category.
   */
  onCompleteModal = () => {
    const {onComplete} = this.props;

    onComplete();

    this.tearDownModal();
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {hasCredits, planHasCredits, isOpen, apiCompanySignGetAllStore, apiUserGetMeStore} = this.props;

    const user = apiUserGetMeStore.getFulfilled();

    if (!isOpen) {
      return null;
    }

    const errorsMap = this.validationErrors;

    const emailOptions = this.contentRequestForm.emails.map((email) => {
      return {label: email, value: email};
    });

    const alertMessage = !planHasCredits ? `Custom Content Request Credits are not part of your current plan.
    Please visit our pricing page to upgrade to a plan that includes this level of service.`
      : 'You do not have enough Content Request Credits.';

    return (
      <>
        <Modal
          className="content-request-modal"
          size="lg"
          isOpen={true}
          toggle={this.onCancelModal}
          centered
        >
          <ModalHeader toggle={this.onCancelModal}>Content Request</ModalHeader>
          <ModalBody>
            {(!hasCredits) && (
              <Alert color="warning">
                {alertMessage}
              </Alert>
            )}

            <form onSubmit={this.onSubmit}>
              <div className="row">
                <div className="col-sm-7">
                  <TextField
                    name="purpose"
                    label="Content Purpose"
                    value={this.contentRequestForm.purpose}
                    errorMessage={errorsMap.get('purpose')}
                    onChange={this.onChange}
                  />

                  <TextField
                    name="colorScheme"
                    label="Color Scheme"
                    value={this.contentRequestForm.colorScheme}
                    errorMessage={errorsMap.get('colorScheme')}
                    onChange={this.onChange}
                  />

                  <TextField
                    name="companyName"
                    label="Company Name"
                    value={this.contentRequestForm.companyName}
                    errorMessage={errorsMap.get('companyName')}
                    onChange={this.onChange}
                  />
                  {/* If the company name is different than the user's company name, allow the user to set it as default */}
                  {(user.company.name !== this.contentRequestForm.companyName) && (<FormGroup
                    check
                    inline
                  >
                    <Input
                      className="mb-2"
                      type="checkbox"
                      name="setDefaultCompanyName"
                      id="setDefaultCompanyName"
                      checked={this.contentRequestForm.setDefaultCompanyName}
                      onChange={this.onChange}
                    />
                    <Label for="setDefaultCompanyName">Set as Default</Label>
                  </FormGroup>)}

                  <div className="form-group">
                    <label className="control-label">
                      Email(s)
                    </label>

                    <CreatableSelect
                      className="site-select theme-light"
                      classNamePrefix="react-select"
                      isClearable={false}
                      isMulti={true}
                      placeholder="Hit enter after each typed email"
                      value={emailOptions.filter((option) => toJS(this.contentRequestForm.emails).includes(option.value))}
                      onChange={this.onSelectUpdate('emails')}
                      options={emailOptions}
                    />

                    {(errorsMap.has('emails')) && (
                      <div className="invalid-feedback">{errorsMap.get('emails')}</div>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="control-label">
                      Message - Ad Copy And/Or Description (1000 character limit)
                    </label>

                    <textarea
                      className="form-control"
                      name="message"
                      placeholder="Add Sample Text"
                      rows={4}
                      value={this.contentRequestForm.message}
                      onChange={this.onChange}
                    />

                    {(errorsMap.has('message')) && (
                      <div className="invalid-feedback">{errorsMap.get('message')}</div>
                    )}
                  </div>
                </div>

                <div className="col-sm-5">
                  <div className="sign-location-field">
                    <label className="signs-label control-label">Sign Location(s)</label>

                    {apiCompanySignGetAllStore.case({
                      pre: () => (<LoadingIcon />),
                      pending: () => (<LoadingIcon />),
                      rejected: () => (
                        <span>Could not load signs.</span>
                      ),
                      fulfilled: (userSigns) => {
                        const options = userSigns.map((userSign) => {
                          return {value: userSign.id, label: userSign.name};
                        });

                        return (
                          <ReactSelect
                            className="site-select theme-light"
                            classNamePrefix="react-select"
                            isClearable={false}
                            isMulti={true}
                            value={options.filter((option) =>
                              toJS(this.contentRequestForm.signIds).includes(option.value))}
                            onChange={this.onSelectUpdate('signIds')}
                            options={options}
                            placeholder="Select Sign(s)..."
                          />
                        );
                      },
                    })}

                    {(errorsMap.has('signIds')) && (
                      <div className="invalid-feedback">{errorsMap.get('signIds')}</div>
                    )}
                  </div>

                  <div className="scheduling-fields">
                    {/* <label className="control-label mb-0">Scheduling</label>*/}
                    {/* <div className="row">*/}
                    {/*  <div className="col">*/}
                    {/*    <TextField*/}
                    {/*      name="schedulingStartDate"*/}
                    {/*      placeholder="Start Date"*/}
                    {/*      value={this.contentRequestForm.schedulingStartDate}*/}
                    {/*      errorMessage={errorsMap.get('schedulingStartDate')}*/}
                    {/*      onChange={this.onChange}*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*  <div className="col">*/}
                    {/*    <TextField*/}
                    {/*      name="schedulingFinishDate"*/}
                    {/*      placeholder="Finish Date"*/}
                    {/*      value={this.contentRequestForm.schedulingFinishDate}*/}
                    {/*      errorMessage={errorsMap.get('schedulingFinishDate')}*/}
                    {/*      onChange={this.onChange}*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/* </div>*/}
                  </div>

                  <TextField
                    name="phoneNumber"
                    label="Phone Number"
                    value={this.contentRequestForm.phoneNumber}
                    errorMessage={errorsMap.get('phoneNumber')}
                    onChange={this.onChange}
                  />
                  {/* If the company name is different than the user's company name, allow the user to set it as default */}
                  {user.phoneNumber !== this.contentRequestForm.phoneNumber && (<FormGroup
                    check
                    inline
                  >
                    <Input
                      className="mb-2"
                      type="checkbox"
                      name="setDefaultPhoneNumber"
                      id="setDefaultPhoneNumber"
                      checked={this.contentRequestForm.setDefaultPhoneNumber}
                      onChange={this.onChange}
                    />
                    <Label for="setDefaultPhoneNumber">Set as Default</Label>
                  </FormGroup>)}

                  <div className="form-group">
                    <label className="control-label">Animation Speed</label>
                    <div>
                      <label className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="animationSpeed"
                          value="slow"
                          checked={this.contentRequestForm.animationSpeed === 'slow'}
                          onChange={this.onChange}
                        />
                        <span>Slow</span>
                      </label>
                      <label className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="animationSpeed"
                          value="fast"
                          checked={this.contentRequestForm.animationSpeed === 'fast'}
                          onChange={this.onChange}
                        />
                        <span>Fast</span>
                      </label>
                      <label className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="animationSpeed"
                          value="static"
                          checked={this.contentRequestForm.animationSpeed === 'static'}
                          onChange={this.onChange}
                        />
                        <span>Static</span>
                      </label>
                    </div>

                    {(errorsMap.has('animationSpeed')) && (
                      <div className="invalid-feedback">{errorsMap.get('animationSpeed')}</div>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="control-label">Animation Style</label>
                    <div>
                      <label className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="animationStyle"
                          value="simple"
                          checked={this.contentRequestForm.animationStyle === 'simple'}
                          onChange={this.onChange}
                        />
                        <span>Simple</span>
                      </label>

                      <label className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="animationStyle"
                          value="flashy"
                          checked={this.contentRequestForm.animationStyle === 'flashy'}
                          onChange={this.onChange}
                        />
                        <span>Flashy</span>
                      </label>
                    </div>

                    {(errorsMap.has('animationStyle')) && (
                      <div className="invalid-feedback">{errorsMap.get('animationStyle')}</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-7">
                  <div className="form-group">
                    <label className="control-label">Upload Files</label>

                    <PreloadWritableFolder
                      folderType="content-request"
                      newFolderName="My Content Request Files"
                      onFoldersFound={this.onUploadFolderFound}
                    >
                      <FileUpload onDrop={this.onUploadFiles} openUploadDialog={false} />
                    </PreloadWritableFolder>
                  </div>
                </div>

                <div className="col-sm-5">
                  <div className="form-group">
                    <label className="control-label">Pending Files</label>

                    <ul className="pending-files">
                      {this.pendingFiles.map((pendingFile) => (
                        <li className="pending-file" key={pendingFile.id}>
                          <a className="pending-file-remove" onClick={this.removePendingFile(pendingFile)}>
                            <FontAwesomeIcon icon={faTimes} />
                          </a>

                          <span className="pending-file-name">{pendingFile.name}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                {(this.saveError) && (
                  <div className="alert alert-danger">
                    {this.saveError.message}
                  </div>
                )}

                <button type="submit" className="btn btn-primary mr-4" disabled={!hasCredits || this.isSubmitting}>Submit</button>
              </div>
            </form>
          </ModalBody>
        </Modal>

        {(this.uploadError) && (
          <ConfirmModal
            confirmText="An error occurred while trying to upload files. One or more may not have been uploaded."
            isOpen={true}
            onComplete={this.onDismissError}
          />
        )}

        {(this.isUploadingModalOpen) && (
          <FileUploadingModal isOpen={true} onComplete={this.closeUploadingModal} />
        )}
      </>
    );
  }
}

ContentRequestModal.propTypes = {
  hasCredits: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  planHasCredits: PropTypes.bool.isRequired,

  apiCompanySignGetAllStore: MobxPropTypes.observableObject,
  apiContentRequestCreateStore: MobxPropTypes.observableObject,
  apiFileUploadStore: MobxPropTypes.observableObject,
  apiUserGetMeStore: MobxPropTypes.observableObject,
  apiUserUpdateMeStore: MobxPropTypes.observableObject,
};

ContentRequestModal.wrappedComponent = {};
ContentRequestModal.wrappedComponent.propTypes = {
  apiCompanySignGetAllStore: MobxPropTypes.observableObject.isRequired,
  apiContentRequestCreateStore: MobxPropTypes.observableObject.isRequired,
  apiFileUploadStore: MobxPropTypes.observableObject.isRequired,
  apiUserGetMeStore: MobxPropTypes.observableObject.isRequired,
  apiUserUpdateMeStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(ContentRequestModal)(
  observer(ContentRequestModal)
);
