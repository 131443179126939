import lodash from 'lodash';

import {sizeComponent} from '../display/components/common/sizeComponent';

/**
 * Gets the current scale of the given element.
 * This checks the transform css style for the scale property.
 *
 * @param {HTMLElement} element
 * @returns {number}
 */
export function getScaleFromElement(element) {
  const transform = lodash.get(element, 'style.transform');
  if (!transform) {
    return 1;
  }

  let scale = 1;
  const scaleMatch = String(transform).match(/scale\(([0-9.]+)\)/i);
  if (scaleMatch && scaleMatch[1]) {
    scale = scaleMatch[1];
  }
  if (scale <= 0) {
    scale = 1;
  }

  return parseFloat(scale);
}

/**
 * Gets the current rotate degrees of the given element.
 * This checks the transform css style for the rotate property.
 *
 * @param {HTMLElement} element
 * @returns {number}
 */
export function getRotationFromElement(element) {
  const transform = lodash.get(element, 'style.transform');
  if (!transform) {
    return 0;
  }

  let rotation = 0;
  const rotateMatch = String(transform).match(/rotate\(([0-9.-]+)deg\)/i);
  if (rotateMatch && rotateMatch[1]) {
    rotation = rotateMatch[1];
  }

  return Number(rotation);
}

/**
 * Gets the current width and height of the given element (before transformations).
 * This checks the transform css style for the rotate property.
 *
 * @param {HTMLElement} element
 * @param {number=} scale
 * @returns {{width: number, height: number}}
 */
export function getDimensionsFromElement(element, scale) {
  let width = parseFloat(lodash.get(element, 'style.width')) || 0;
  let height = parseFloat(lodash.get(element, 'style.height')) || 0;

  if (scale) {
    width = width * scale;
    height = height * scale;
  }

  return {
    width,
    height,
  };
}

/**
 * Updates the entity size to a pixel value if it was set to 'auto' before.
 *
 * @param {ObservableMap} entity
 * @param {GameStore} game
 */
export function initEntitySize(entity, game) {
  const entityId = entity.get('id');
  const entityEl = document.getElementById(entityId);

  const currentSize = entity.get('size');
  if (!currentSize) {
    return;
  }

  let newWidth = null;
  let newHeight = null;
  if (currentSize.width === 'auto') {
    newWidth = entityEl.clientWidth;
  }
  if (currentSize.height === 'auto') {
    newHeight = entityEl.clientHeight;
  }

  const actionParams = {
    entityId,
    skipHistory: true,
  };

  if (newWidth || newHeight) {
    game.addAction(actionParams, sizeComponent(
      newWidth || currentSize.width,
      newHeight || currentSize.height
    ));
  }
}

/**
 * Gets whether or not the position of the entity is locked.
 *
 * @param {ObservableMap} entity
 * @returns {boolean}
 */
export function getIsPositionLocked(entity) {
  const locked = entity.get('locked');
  return Boolean(locked && locked.indexOf('position') !== -1);
}

/**
 * Gets whether or not the size of the entity is locked.
 *
 * @param {ObservableMap} entity
 * @returns {boolean}
 */
export function getIsSizeLocked(entity) {
  const locked = entity.get('locked');
  return Boolean(locked && locked.indexOf('size') !== -1);
}

/**
 * Gets whether or not the rotation of the entity is locked.
 *
 * @param {ObservableMap} entity
 * @returns {boolean}
 */
export function getIsRotationLocked(entity) {
  const locked = entity.get('locked');
  if (!locked) {
    return false;
  }

  if (locked.indexOf('rotation') !== -1) {
    return true;
  } else if (locked.indexOf('position') !== -1) {
    return true;
  }
  return false;
}

/**
 * Gets whether or not the cropping of the entity is locked.
 *
 * @param {ObservableMap} entity
 * @returns {boolean}
 */
export function getIsCroppingLocked(entity) {
  const locked = entity.get('locked');
  if (!locked) {
    return false;
  }

  if (locked.indexOf('size') !== -1) {
    return true;
  } else if (locked.indexOf('position') !== -1) {
    return true;
  }
  return false;
}

/**
 * Gets whether or not the entity has an ongoing preset transitioning.
 *
 * @param {ObservableMap} entity
 * @param {number} currentTime
 * @param {string|string[]} transitionTypes
 * @returns {boolean}
 */
export function getIsEntityTransitioning(entity, currentTime, transitionTypes) {
  if (!entity.has('transition')) {
    return false;
  }

  let isTransitioning = false;
  lodash.forEach(entity.get('transition'), (transition) => {
    if (!transition || !transition.initialized) {
      return true;
    } else if (!transition.time.start && !transition.time.end) {
      return true;
    }

    if (transitionTypes) {
      const safeTransitionTypes = Array.isArray(transitionTypes) ? transitionTypes : [transitionTypes];

      // If a transition type is defined, then only consider transitions for properties that start with the type.
      const hasType = lodash.some(transition.details || {}, (unused, propertyName) => {
        return lodash.some(safeTransitionTypes, (transitionType) => {
          return lodash.startsWith(propertyName, transitionType);
        });
      });

      if (!hasType) {
        return true;
      }
    }

    const {start, end} = transition.time;

    if (currentTime >= start && currentTime <= end) {
      isTransitioning = true;
    }

    return !isTransitioning;
  });

  return isTransitioning;
}
