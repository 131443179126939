import {observer} from 'mobx-react';
import React from 'react';

import {buildWordpressUrl} from '../../../../../utils/routeHelper';
import darkblueLogo from '../../../../../assets/images/horizontal-darkblue.png';

import './logoNavItem.scss';

/**
 * The LogoNavItem component.
 */
export class LogoNavItem extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const homeLink = buildWordpressUrl();

    return (
      <div className="navbar-left-logo">
        <a href={homeLink}>
          <img
            className="d-xl-inline-block"
            src={darkblueLogo}
            alt="Project Content Logo"
          />
        </a>
      </div>
    );
  }
}

export default observer(LogoNavItem);
