/* eslint-disable react/no-multi-comp, react/display-name */
import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';

import AdminLayout from './layouts/adminLayout/AdminLayout';
import MainLayout from './layouts/mainLayout/MainLayout';
import SimpleLayout from './layouts/simpleLayout/SimpleLayout';

import AdminContentRequestPage from './pages/adminContentRequest/AdminContentRequestPage';
import AdminContentRequestsListPage from './pages/adminContentRequestsList/AdminContentRequestsListPage';
import AdminCompanySignsPage from './pages/adminCompanySigns/AdminCompanySignsPage';
import AdminBannersListPage from './pages/adminBannersList/AdminBannersListPage';
import AdminBannerEditPage from './pages/adminBanner/AdminBannerEditPage';
import DashboardPage from './pages/dashboard/DashboardPage';
import EditorPage from './pages/editor/EditorPage';
import PrivacyPolicyPage from './pages/privacyPolicy/PrivacyPolicyPage';
import SignupPage from './pages/signup/SignupPage';
import SignupLinkPage from './pages/signupLink/SignupLinkPage';
import OnboardingPage from './pages/onboarding/OnboardingPage';
import UserPaymentPage from './pages/userPayment/UserPaymentPage';
import PricingPage from './pages/pricing/PricingPage';
import LoginPage from './pages/login/LoginPage';
import LogoutPage from './pages/logout/LogoutPage';
import NotFoundPage from './pages/notFound/NotFoundPage';
import PrestoPage from './pages/presto/PrestoPage';
import RenderPage from './pages/render/RenderPage';
import CompanySignsPage from './pages/companySigns/CompanySignsPage';
import TransitionWrapper from './layouts/transitionWrapper/TransitionWrapper';

import * as routePaths from './routePaths';
import {NAV_DASHBOARD, NAV_DEFAULT, NAV_PRESTO} from '../constants/navConstants';
import routerStore from '../stores/active/routerStore';

/**
 * Builds a page using the simple layout.
 *
 * @param {{}} PageComponent
 * @returns {function}
 */
function simpleLayoutPage(PageComponent) {
  return (props) => {
    const {match} = props; // eslint-disable-line react/prop-types
    routerStore._setMatch(match);

    return (
      <SimpleLayout
        page={<PageComponent {...props} />}
        {...props}
      />
    );
  };
}

/**
 * Builds a page using the main layout.
 *
 * @param {{}} PageComponent
 * @param {string} navType
 * @returns {function}
 */
function layoutPage(PageComponent, navType) {
  return (props) => {
    const {match} = props; // eslint-disable-line react/prop-types
    routerStore._setMatch(match);

    return (
      <MainLayout
        page={<PageComponent {...props} />}
        {...props}
        navType={navType || NAV_DEFAULT}
      />
    );
  };
}

/**
 * Builds a page using the admin layout.
 *
 * @param {{}} PageComponent
 * @returns {function}
 */
function adminLayoutPage(PageComponent) {
  return (props) => {
    const {match} = props; // eslint-disable-line react/prop-types
    routerStore._setMatch(match);

    return (
      <AdminLayout
        page={<PageComponent {...props} />}
        {...props}
      />
    );
  };
}

/**
 * Allows smooth transitions on components.
 *
 * @param {{}} PageComponent
 * @returns {function}
 */
function transition(PageComponent) { // eslint-disable-line no-unused-vars
  return (props) => (
    <TransitionWrapper>
      <PageComponent {...props} />
    </TransitionWrapper>
  );
}

/**
 * Builds the routes for the app.
 *
 * @returns {*}
 */
export function RoutesSwitch() {
  return (
    <Switch>
      {/* redirect users that land on base url to dahboard route */}
      <Redirect exact path={routePaths.homeRoute} to={routePaths.dashboardRoute} />
      <Route path={routePaths.dashboardSearchRoute} render={layoutPage(DashboardPage, NAV_DASHBOARD)} />
      <Route path={routePaths.dashboardListRoute} render={layoutPage(DashboardPage, NAV_DASHBOARD)} />
      <Route path={routePaths.editorRoute} render={layoutPage(EditorPage, NAV_PRESTO)} />
      <Route path={routePaths.privacyPolicyRoute} render={layoutPage(PrivacyPolicyPage)} />
      <Route path={routePaths.signupRoute} render={simpleLayoutPage(SignupPage)} />
      <Route path={routePaths.signupLinkRoute} render={simpleLayoutPage(SignupLinkPage)} />
      <Route path={routePaths.onboardingRoute} render={simpleLayoutPage(OnboardingPage)} />
      <Route path={routePaths.userPaymentRoute} render={layoutPage(UserPaymentPage)} />
      <Route path={routePaths.pricingRoute} render={layoutPage(PricingPage)} />
      <Route path={routePaths.loginRoute} render={simpleLayoutPage(LoginPage)} />
      <Route path={routePaths.logoutRoute} render={simpleLayoutPage(LogoutPage)} />
      <Route path={routePaths.prestoRoute} render={layoutPage(PrestoPage, NAV_PRESTO)} />
      <Route path={routePaths.renderRoute} render={simpleLayoutPage(RenderPage)} />
      <Route path={routePaths.signsRoute} render={layoutPage(CompanySignsPage)} />
      <Route path={routePaths.adminContentRequestRoute} render={adminLayoutPage(AdminContentRequestPage)} />
      <Route path={routePaths.adminContentRequestsListRoute} render={adminLayoutPage(AdminContentRequestsListPage)} />
      <Route path={routePaths.adminCompanyEditSignsRoute} render={layoutPage(AdminCompanySignsPage)} />
      <Route path={routePaths.adminBannersListRoute} render={adminLayoutPage(AdminBannersListPage)} />
      <Route path={routePaths.adminBannerEditRoute} render={adminLayoutPage(AdminBannerEditPage)} />
      <Route path="*" render={layoutPage(NotFoundPage)} />
    </Switch>
  );
}

export default RoutesSwitch;
