/* eslint-disable no-magic-numbers */

/**
 * Gets a valid frames per second.
 *
 * @param {number} fps
 * @returns {number}
 */
export function parseFramesPerSecond(fps) {
  return Math.min(60, Math.max(
    10,
    parseInt(fps, 10) || 60
  ));
}

/**
 * Turns a given frame into a time (in milliseconds).
 *
 * @param {number} fps The frames per second to render. Minimum 10, maximum 60.
 * @param {number} frame
 * @returns {number}
 */
export function parseFrameToTime(fps, frame) {
  return ((frame / fps) * 1000);
}

/**
 * Turns a given time (in milliseconds) into a frame number.
 *
 * @param {number} fps The frames per second to render. Minimum 10, maximum 60.
 * @param {number} time Millisecond intervals.
 * @returns {number}
 */
export function parseTimeToFrame(fps, time) {
  return (fps * (time / 1000));
}
