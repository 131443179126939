/**
 * The action crop component.
 *
 * @param {{x: number, y: number, width: number, height: number}} cropDelta
 * @param {boolean} isResize
 * @param {string} resizePosition
 * @returns {{actionCrop: {cropDelta: {}, isResize: boolean}}}
 */
export function actionCropComponent(cropDelta, isResize, resizePosition) {
  return {
    actionCrop: {
      cropDelta,
      isResize: Boolean(isResize),
      resizePosition,
    },
  };
}
