/**
 * The number to divide by to convert to a percentage.
 *
 * @const {number}
 */
const TO_PERCENT = 100;

/**
 * Parses a size attribute.
 *
 * @param {string|number} attribute
 * @returns {number|string}
 */
export function parseSize(attribute) {
  if (attribute === 'auto') {
    return 'auto';
  }
  if (String(attribute).substr(-1) === '%') {
    return attribute;
  }
  return Number.parseFloat(attribute) || 0;
}

/**
 * The size component.
 *
 * @param {number} width
 * @param {number} height
 * @returns {{size: {width: number, height: number, widthIsPercent: boolean, heightIsPercent: boolean}}}
 */
export function sizeComponent(width, height) {
  const parsedWidth = parseSize(width);
  const parsedHeight = parseSize(height);

  return {
    size: {
      width: parsedWidth,
      height: parsedHeight,
      widthIsPercent: Boolean(String(width).substr(-1) === '%'),
      heightIsPercent: Boolean(String(height).substr(-1) === '%'),
      default: {
        width: parsedWidth,
        height: parsedHeight,
        widthIsPercent: Boolean(String(width).substr(-1) === '%'),
        heightIsPercent: Boolean(String(height).substr(-1) === '%'),
      }
    },
  };
}

/**
 * Gets the size component from the source item.
 *
 * @param {{setup: {size: {width: number, height: number}}}} item
 * @returns {{size: {width: number, height: number}}}
 */
export function getSizeFromSource(item) {
  if (!item.setup || !item.setup.size) {
    return {};
  }
  const itemSetup = item.setup || {};
  const safeSize = Object.assign({}, itemSetup.size || {});

  return sizeComponent(
    safeSize.width || 'auto',
    safeSize.height || 'auto'
  );
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {ObservableMap} entity
 * @param {GameStore} game
 * @returns {{}}
 */
export function getSizeForSource(entity, game) {
  if (!entity.has('size')) {
    return {};
  }

  const size = entity.get('size');

  if (!size.default.widthIsPercent && !size.default.heightIsPercent) {
    return {
      setup: {
        size: {
          width: size.default.width,
          height: size.default.height,
        },
      },
    };
  }

  const decimalLimit = 4;

  const gameResolution = game.resolution;
  const outSize = {};
  if (size.default.widthIsPercent) {
    outSize.width = Number.parseFloat(size.width * TO_PERCENT / gameResolution.width).toFixed(decimalLimit) + '%';
  }
  if (size.default.heightIsPercent) {
    outSize.height = Number.parseFloat(size.height * TO_PERCENT / gameResolution.height).toFixed(decimalLimit) + '%';
  }

  return {
    setup: {
      size: outSize,
    },
  };
}
