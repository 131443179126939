/**
 * Aligns all selected items on the left.
 * @const {string}
 */
export const ALIGN_LEFT = 'ALIGN_LEFT';

/**
 * Aligns all selected items on the center.
 * @const {string}
 */
export const ALIGN_CENTER = 'ALIGN_CENTER';

/**
 * Aligns all selected items on the right.
 * @const {string}
 */
export const ALIGN_RIGHT = 'ALIGN_RIGHT';

/**
 * Aligns all selected items on the top.
 * @const {string}
 */
export const ALIGN_TOP = 'ALIGN_TOP';

/**
 * Aligns all selected items on the middle.
 * @const {string}
 */
export const ALIGN_MIDDLE = 'ALIGN_MIDDLE';

/**
 * Aligns all selected items on the bottom.
 * @const {string}
 */
export const ALIGN_BOTTOM = 'ALIGN_BOTTOM';

/**
 * Centers all selected items horizontally on the page.
 * @const {string}
 */
export const CENTER_HORIZONTALLY = 'CENTER_HORIZONTALLY';

/**
 * Centers all selected items vertically on the page.
 * @const {string}
 */
export const CENTER_VERTICALLY = 'CENTER_VERTICALLY';

/**
 * Evenly distributes all selected items horizontally.
 * @const {string}
 */
export const DISTRIBUTE_HORIZONTALLY = 'DISTRIBUTE_HORIZONTALLY';

/**
 * Evenly distributes all selected items vertically.
 * @const {string}
 */
export const DISTRIBUTE_VERTICALLY = 'DISTRIBUTE_VERTICALLY';

/**
 * Fills the screen with the item.
 * @const {string}
 */
export const FIT_FULL = 'FIT_FULL';

/**
 * The item will take up half the center of the screen.
 * @const {string}
 */
export const FIT_CENTER_HALF = 'FIT_CENTER_HALF';
