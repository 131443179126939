import lodash from 'lodash';

/**
 * Makes sure the given value is an number, even if it has to use the default value.
 *
 * @param {*} value
 * @param {number=} defaultValue
 * @returns {number}
 */
export function toNumber(value, defaultValue) {
  const safeDefault = defaultValue || 0;
  return Number(value) || safeDefault;
}

/**
 * Flattens an object down to its '.' delimited keys.
 * Does not flatten arrays within objects.
 *
 * @param {{}} target
 * @param {string=} prefix
 * @returns {string[]}
 */
export function flattenObjectKeys(target, prefix) {
  const safePrefix = prefix || '';

  if (!lodash.isPlainObject(target)) {
    return [];
  }

  const flattened = [];
  lodash.forEach(target, (childValue, childKey) => {
    const prefixedKey = (safePrefix) ? `${safePrefix}.${childKey}` : childKey;

    if (!lodash.isPlainObject(childValue)) {
      flattened.push(prefixedKey);
      return;
    }

    const grandChildrenKeys = flattenObjectKeys(childValue, prefixedKey);
    if (grandChildrenKeys.length) {
      lodash.forEach(grandChildrenKeys, (grandChildKey) => {
        const prefixedGrandChildKey = (safePrefix) ? `${safePrefix}.${grandChildKey}` : grandChildKey;
        flattened.push(prefixedGrandChildKey);
      });
    }
  });

  return flattened;
}
