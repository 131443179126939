import {STATE_STOPPED} from '../../../constants/displayItemConstants';

/**
 * The audio component.
 *
 * @param {string} source
 * @param {string} type
 * @param {boolean} loop
 * @param {string} state
 * @param {number} time
 * @returns {{audio: {source: string, type: ?string, time: number, state: string, loop: boolean}}}
 */
export function audioComponent(source, type, loop, state, time) {
  return {
    audio: {
      source,
      type,
      time: time || 0,
      state: state || STATE_STOPPED,
      loop: Boolean(loop)
    },
  };
}

/**
 * Gets the audio component from the source item.
 *
 * @param {{audio: {source: string, type: ?string, loop: ?boolean}}} item
 * @returns {{audio: {source: string, type: ?string, time: number, state: string, loop: boolean}}}
 */
export function getAudioFromSource(item) {
  if (!item.audio) {
    return {};
  } else if (!item.audio.source) {
    throw new Error('Invalid audio source for audioComponent.');
  }

  const audio = item.audio;

  return audioComponent(
    String(audio.source),
    audio.type || null,
    audio.loop || false
  );
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {{}} entity
 * @returns {{}}
 */
export function getAudioForSource(entity) {
  if (!entity.has('audio')) {
    return {};
  }

  const audio = entity.get('audio');
  return {
    audio: {
      source: audio.source,
      type: audio.type,
      loop: audio.loop,
    },
  };
}
