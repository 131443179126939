import lodash from 'lodash';

import requester from './serverRequest';
import {FILE_TYPE_MP4} from '../constants/fileTypeConstants';
import {FREE_CONTENT_COLLECTION_ID} from '../constants/freeContentConstants';
import {NEW_CONTENT_COLLECTION_ID} from '../constants/newContentConstants';

const serverApi = {
  /**
   * userCreate request.
   *
   * @param {{firstName: string, email: string, password: string}} body
   * @returns {Promise<number>}
   */
  userCreate(body) {
    return requester.fetch({
      url: '/user',
      method: 'post',
      data: body,
    });
  },

  /**
   * userGetMe request.
   *
   * @returns {Promise}
   */
  userGetMe() {
    return requester.fetch({
      url: '/user/me',
      method: 'get',
    });
  },

  /**
   * userUpdateMe request.
   *
   * @param {{}} userData
   * @returns {Promise}
   */
  userUpdateMe(userData) {
    return requester.fetch({
      url: '/user/me',
      method: 'patch',
      data: userData
    });
  },

  /**
   * userGetOneById request.
   *
   * @param {number} userId
   * @returns {Promise}
   */
  userGetOneById(userId) {
    const safeUserId = parseInt(userId, 10);

    return requester.fetch({
      url: `/user/${safeUserId}`,
      method: 'get',
    });
  },

  /**
   * userOnboard request.
   *
   * @param {{}} onboardData
   * @returns {Promise}
   */
  userOnboard(onboardData) {
    return requester.fetch({
      url: '/user/onboard',
      method: 'post',
      data: onboardData,
    });
  },

  /**
   * userUpdateSubscription request.
   *
   * @param {{}} subscriptionData
   * @returns {Promise}
   */
  userUpdateSubscription(subscriptionData) {
    return requester.fetch({
      url: '/user/update-subscription',
      method: 'post',
      data: subscriptionData,
    });
  },

  /**
   * bannerGetAll request.
   *
   * @returns {Promise}
   */
  bannerGetAll() {
    return requester.fetch({
      url: '/banners',
      method: 'get',
    });
  },

  /**
   * adminBannerGetAll request.
   *
   * @returns {Promise}
   */
  adminBannerGetAll() {
    return requester.fetch({
      url: '/admin/banner',
      method: 'get',
    });
  },

  /**
   * bannerGetOne request.
   *
   * @param {number} bannerId
   * @returns {Promise}
   */
  bannerGetOne(bannerId) {
    const safeBannerId = parseInt(bannerId, 10);

    return requester.fetch({
      url: `/banner/${safeBannerId}`,
      method: 'get',
    });
  },

  /**
   * bannerUpdate request.
   *
   * @param {number} bannerId
   * @param {{}} bannerData
   * @returns {Promise}
   */
  bannerUpdate(bannerId, bannerData) {
    const safeBannerId = parseInt(bannerId, 10);

    const safeBannerData = {
      ...bannerData,
    };

    return requester.fetch({
      url: `/banner/${safeBannerId}`,
      method: 'patch',
      data: safeBannerData,
    });
  },

  /**
   * collectionCreate request.
   *
   * @param {string} newCollectionName
   * @returns {Promise<{id: number}>}
   */
  collectionCreate(newCollectionName) {
    return requester.fetch({
      url: '/collection',
      method: 'post',
      data: {
        name: newCollectionName,
      },
    });
  },

  /**
   * collectionDelete request.
   *
   * @param {number|string} collectionId
   * @returns {Promise<boolean>}
   */
  collectionDelete(collectionId) {
    const safeCollectionId = parseInt(collectionId, 10);

    return requester.fetch({
      url: `/collection/${safeCollectionId}`,
      method: 'delete',
    });
  },

  /**
   * collectionGetAll request.
   *
   * @returns {Promise}
   */
  collectionGetAll() {
    return requester.fetch({
      url: '/collection',
      method: 'get'
    });
  },

  /**
   * collectionGetOne request.
   *
   * @param {String|Number} requestId
   * @returns {Promise}
   */
  collectionGetOne(requestId) {
    const safeCollectionId = parseInt(requestId, 10);

    return requester.fetch({
      url: `/collection/${safeCollectionId}`,
      method: 'get'
    });
  },

  /**
   * collectionRename request.
   *
   * @param {number} collectionId
   * @param {{}} collectionData
   * @returns {Promise}
   */
  collectionRename(collectionId, collectionData) {
    if (!lodash.size(collectionData)) {
      return Promise.resolve(true);
    }

    const safeId = parseInt(collectionId, 10);

    const safeData = {...collectionData};
    lodash.unset(safeData, 'id');
    lodash.unset(safeData, 'sources');

    return requester.fetch({
      url: `/collection/${safeId}/rename`,
      method: 'patch',
      data: safeData,
    });
  },

  /**
   * collectionGetContent request.
   *
   * @param {string} collectionId
   * @returns {Promise}
   */
  collectionGetContent(collectionId) {
    const isCustomCollectionId = collectionId === NEW_CONTENT_COLLECTION_ID || collectionId === FREE_CONTENT_COLLECTION_ID;
    const safeCollectionId = isCustomCollectionId
      ? collectionId
      : parseInt(collectionId, 10);

    return requester.fetch({
      url: `/collection/${safeCollectionId}/content`,
      method: 'get'
    });
  },

  /**
   * collectionGetContentCount request.
   *
   * @param {string} collectionId
   * @returns {Promise}
   */
  collectionGetContentCount(collectionId) {
    const isCustomCollectionId = collectionId === NEW_CONTENT_COLLECTION_ID || collectionId === FREE_CONTENT_COLLECTION_ID;
    if (!isCustomCollectionId) {
      return null;
    }

    return requester.fetch({
      url: `/collection/${collectionId}/content/count`,
      method: 'get',
    });
  },

  /**
   * collectionExampleCreate request.
   *
   * @param {number} collectionId
   * @param {{}} data
   * @returns {Promise}
   */
  collectionExampleCreate(collectionId, data) {
    return requester.fetch({
      url: `/collection/${collectionId}/collection-example`,
      method: 'post',
      data
    });
  },

  /**
   * collectionExampleUpdate request.
   *
   * @param {number} collectionExampleId
   * @param {{}} data
   * @returns {Promise}
   */
  collectionExampleUpdate(collectionExampleId, data) {
    return requester.fetch({
      url: `/collection/collection-example/${collectionExampleId}`,
      method: 'patch',
      data
    });
  },

  /**
   * contentFolderCreate request.
   *
   * @param {string} newFolderName
   * @returns {Promise<{id: number}>}
   */
  contentFolderCreate(newFolderName) {
    return requester.fetch({
      url: '/content-folder',
      method: 'post',
      data: {
        name: newFolderName,
      },
    });
  },

  /**
   * contentFolderDelete request.
   *
   * @param {number|string} contentFolderId
   * @returns {Promise<boolean>}
   */
  contentFolderDelete(contentFolderId) {
    const safeFolderId = parseInt(contentFolderId, 10);

    return requester.fetch({
      url: `/content-folder/${safeFolderId}`,
      method: 'delete',
    });
  },

  /**
   * contentFolderGetAll request.
   *
   * @param {boolean=} withCount
   * @returns {Promise}
   */
  contentFolderGetAll(withCount) {
    const params = {};
    if (withCount) {
      params.withCount = true;
    }

    return requester.fetch({
      url: '/content-folder',
      method: 'get',
      params,
    });
  },

  /**
   * collectionFolderGetContent request.
   *
   * @param {string} folderId
   * @returns {Promise}
   */
  contentFolderGetContent(folderId) {
    const safeFolderId = parseInt(folderId, 10);

    return requester.fetch({
      url: `/content-folder/${safeFolderId}/content`,
      method: 'get'
    });
  },

  /**
   * contentFolderUpdate request.
   *
   * @param {number} contentFolderId
   * @param {{}} contentFolderData
   * @returns {Promise}
   */
  contentFolderUpdate(contentFolderId, contentFolderData) {
    if (!lodash.size(contentFolderData)) {
      return Promise.resolve(true);
    }

    const safeId = parseInt(contentFolderId, 10);

    const safeData = {...contentFolderData};
    lodash.unset(safeData, 'id');

    return requester.fetch({
      url: `/content-folder/${safeId}/rename`,
      method: 'patch',
      data: safeData,
    });
  },

  /**
   * contentCreate request.
   *
   * @param {{}} contentData
   * @returns {Promise}
   */
  contentCreate(contentData) {
    if (!contentData || !lodash.size(contentData)) {
      return Promise.resolve(null);
    }

    const safeData = {...contentData};
    lodash.unset(safeData, 'id');

    return requester.fetch({
      url: '/content',
      method: 'post',
      data: safeData,
    });
  },

  /**
   * contentDelete request.
   *
   * @param {number|string} contentId
   * @returns {Promise<boolean>}
   */
  contentDelete(contentId) {
    const safeContentId = parseInt(contentId, 10);

    return requester.fetch({
      url: `/content/${safeContentId}`,
      method: 'delete',
    });
  },

  /**
   * contentDeleteMany request.
   *
   * @param {Array.<number|string>} contentIds
   * @returns {Promise<boolean>}
   */
  contentDeleteMany(contentIds) {
    const safeContentIds = contentIds.map((contentId) => parseInt(contentId, 10));

    return requester.fetch({
      url: '/content',
      method: 'delete',
      data: {
        contentIds: safeContentIds
      }
    });
  },

  /**
   * contentGetOne request.
   *
   * @param {string} contentId
   * @param {boolean=} withDisplayInfo
   * @returns {Promise}
   */
  contentGetOne(contentId, withDisplayInfo) {
    const safeContentId = parseInt(contentId, 10);
    const safeWithDisplayInfo = (withDisplayInfo) ? true : undefined;

    return requester.fetch({
      url: `/content/${safeContentId}`,
      method: 'get',
      params: {
        withDisplayInfo: safeWithDisplayInfo,
      },
    });
  },

  /**
   * contentMove request.
   *
   * @param {number|string} contentId
   * @param {number|string} newFolderId
   * @returns {Promise<boolean>}
   */
  contentMove(contentId, newFolderId) {
    const safeContentId = parseInt(contentId, 10);
    const safeNewFolderId = parseInt(newFolderId, 10);

    return requester.fetch({
      url: `/content/${safeContentId}/move`,
      method: 'post',
      data: {
        contentFolderId: safeNewFolderId,
      },
    });
  },

  /**
   * contentPublish request.
   *
   * @param {number} contentId
   * @param {{isFree: boolean, new: {}, replace: {}}} publishData
   * @returns {Promise}
   */
  contentPublish(contentId, publishData) {
    if (!contentId || (!publishData.new && !publishData.replace)) {
      return Promise.resolve(true);
    }

    const safeId = parseInt(contentId, 10);

    return requester.fetch({
      url: `/content/${safeId}/publish`,
      method: 'post',
      data: {...publishData},
    });
  },

  /**
   * contentRender request.
   *
   * @param {number} contentId
   * @param {string} fileType
   * @param {number=} signId
   * @returns {Promise}
   */
  contentRender(contentId, fileType, signId) {
    if (!contentId) {
      return Promise.resolve(true);
    }

    const safeContentId = parseInt(contentId, 10);
    const safeSignId = !isNaN(signId) ? parseInt(signId, 10) : undefined;
    const safeFileType = (fileType) ? fileType : FILE_TYPE_MP4;

    return requester.fetch({
      url: 'content-render',
      method: 'post',
      data: {
        contentId: safeContentId,
        fileType: safeFileType,
        signId: safeSignId
      },
    });
  },

  /**
   * contentRenderGetStatus request.
   *
   * @param {number|string} contentId
   * @returns {Promise}
   */
  contentRenderGetStatus(contentId) {
    if (!contentId) {
      return Promise.resolve(true);
    }

    const safeContentId = parseInt(contentId, 10);

    return requester.fetch({
      url: `content-render/${safeContentId}/status`,
      method: 'get',
    });
  },

  /**
   * contentRenderGetDownloadable request.
   *
   * @param {number|string} contentId
   * @returns {Promise}
   */
  contentRenderGetDownloadable(contentId) {
    const safeContentId = parseInt(contentId, 10);

    return requester.fetch({
      url: `content-render/${safeContentId}/downloadable`,
      method: 'get',
    });
  },

  /**
   * contentRequestCreate request.
   *
   * @param {{}} requestData
   * @returns {Promise<{id: number}>}
   */
  contentRequestCreate(requestData) {
    return requester.fetch({
      url: '/content-request',
      method: 'post',
      data: requestData,
    });
  },

  /**
   * adminContentRequestGetAll request.
   *
   * @returns {Promise}
   */
  adminContentRequestGetAll() {
    return requester.fetch({
      url: '/admin/content-request',
      method: 'get',
    });
  },

  /**
   * adminContentRequestGetAll request.
   *
   * @param {number} requestId
   * @returns {Promise}
   */
  contentRequestGetOne(requestId) {
    const safeRequestId = parseInt(requestId, 10);

    return requester.fetch({
      url: `/content-request/${safeRequestId}`,
      method: 'get',
    });
  },

  /**
   * Searches for content.
   *
   * @param {string} searchTerm
   * @param {boolean} globalOnly
   * @param {boolean} simple
   * @returns {Promise}
   */
  contentSearch(searchTerm, globalOnly, simple) {
    const safeSearchTerm = String(searchTerm).replace(/[^a-zA-Z0-9_ -']/g, '');

    return requester.fetch({
      url: '/content/search',
      method: 'get',
      params: {
        searchValue: safeSearchTerm,
        globalOnly: Boolean(globalOnly),
        simple: Boolean(simple),
      },
    });
  },

  /**
   * contentUnPublish request.
   *
   * @param {number} contentId
   * @param {number} collectionId
   * @returns {Promise}
   */
  contentUnPublish(contentId, collectionId) {
    if (!contentId || !collectionId) {
      return Promise.resolve(true);
    }

    const safeContentId = parseInt(contentId, 10);
    const safeCollectionId = parseInt(collectionId, 10);

    return requester.fetch({
      url: `/content/${safeContentId}/un-publish`,
      method: 'post',
      data: {
        collectionId: safeCollectionId,
      },
    });
  },

  /**
   * contentUnPublishMany request.
   *
   * @param {Array<number>} contentIds
   * @param {number} collectionId
   * @returns {Promise}
   */
  contentUnPublishMany(contentIds, collectionId) {
    if (!contentIds || !collectionId) {
      return Promise.resolve(true);
    }

    const safeContentIds = contentIds.map((contentId) => parseInt(contentId, 10));
    const safeCollectionId = parseInt(collectionId, 10);

    return requester.fetch({
      url: '/content/un-publish',
      method: 'post',
      data: {
        collectionId: safeCollectionId,
        contentIds: safeContentIds,
      },
    });
  },

  /**
   * contentUpdate request.
   *
   * @param {number} contentId
   * @param {{}} contentData
   * @returns {Promise}
   */
  contentUpdate(contentId, contentData) {
    if (!lodash.size(contentData)) {
      return Promise.resolve(true);
    }

    const safeId = parseInt(contentId, 10);

    const safeData = {...contentData};
    lodash.unset(safeData, 'id');
    lodash.unset(safeData, 'sources');

    return requester.fetch({
      url: `/content/${safeId}`,
      method: 'patch',
      data: safeData,
    });
  },

  /**
   * contentSourceCreate request.
   *
   * @param {number|string} contentId
   * @param {string} aspectRatio
   * @param {{}} source
   * @returns {Promise<number>}
   */
  contentSourceCreate: function contentSourceCreate(contentId, aspectRatio, source) {
    const safeContentId = parseInt(contentId, 10);

    return requester.fetch({
      url: `/content/${safeContentId}/source`,
      method: 'post',
      data: {
        aspectRatio,
        source,
      },
    });
  },

  /**
   * contentSourceDeleteAll request.
   *
   * @param {number|string} contentId
   * @returns {Promise}
   */
  contentSourceDeleteAll: function contentSourceDeleteAll(contentId) {
    const safeContentId = parseInt(contentId, 10);

    return requester.fetch({
      url: `/content/${safeContentId}/source/`,
      method: 'delete',
    });
  },

  /**
   * contentSourceDeleteOne request.
   *
   * @param {number|string} contentSourceId
   * @returns {Promise}
   */
  contentSourceDeleteOne: function contentSourceDeleteOne(contentSourceId) {
    const safeContentSourceId = parseInt(contentSourceId, 10);

    return requester.fetch({
      url: `/content/source/${safeContentSourceId}`,
      method: 'delete',
    });
  },

  /**
   * contentSourceGetAll request.
   *
   * @param {number|number[]} contentIdOrIds
   * @returns {Promise}
   */
  contentSourceGetAll: function contentSourceGetAll(contentIdOrIds) {
    let contentIds = [];
    if (Array.isArray(contentIdOrIds)) {
      contentIdOrIds.forEach((contentId) => {
        const safeId = parseInt(contentId, 10);
        if (safeId) {
          contentIds.push(safeId);
        }
      });
    } else {
      const safeId = parseInt(contentIdOrIds, 10);
      if (safeId) {
        contentIds.push(safeId);
      }
    }

    if (!lodash.size(contentIds)) {
      return Promise.resolve([]);
    }

    return requester.fetch({
      url: '/content/source',
      method: 'get',
      params: {
        contentIds,
      },
    });
  },

  /**
   * contentSourceSetAll request.
   *
   * @param {number|string} contentId
   * @param {Object.<string, {}>} sources
   * @returns {Promise<number[]>}
   */
  contentSourceSetAll: function contentSourceSetAll(contentId, sources) {
    const safeContentId = parseInt(contentId, 10);

    return requester.fetch({
      url: `/content/${safeContentId}/sources`,
      method: 'post',
      data: {
        sources,
      },
    });
  },

  /**
   * contentSourceUpdate request.
   *
   * @param {number|string} contentSourceId
   * @param {{}} source
   * @returns {Promise}
   */
  contentSourceUpdate: function contentSourceUpdate(contentSourceId, source) {
    const safeContentSourceId = parseInt(contentSourceId, 10);

    return requester.fetch({
      url: `/content/source/${safeContentSourceId}`,
      method: 'patch',
      data: {
        source,
      },
    });
  },

  /**
   * fileDelete request.
   *
   * @param {string|number} fileId
   * @returns {Promise}
   */
  fileDelete(fileId) {
    const safeFileId = parseInt(fileId, 10);

    return requester.fetch({
      url: `/file/${safeFileId}`,
      method: 'delete',
    });
  },

  /**
   * fileGetOne request.
   *
   * @param {string|number} fileId
   * @param {boolean} getFolders
   * @returns {Promise}
   */
  fileGetOne(fileId, getFolders) {
    const safeFileId = parseInt(fileId, 10);

    const params = {};
    if (getFolders) {
      params.getFolders = true;
    }

    return requester.fetch({
      url: `/file/${safeFileId}`,
      method: 'get',
      params,
    });
  },

  /**
   * Searches for files.
   *
   * @param {string} searchTerm
   * @param {boolean} simple
   * @returns {Promise}
   */
  fileSearch(searchTerm, simple) {
    const safeSearchTerm = String(searchTerm).replace(/[^a-zA-Z0-9_ -']/g, '');

    return requester.fetch({
      url: '/file/search',
      method: 'get',
      params: {
        searchValue: safeSearchTerm,
        simple: Boolean(simple),
      },
    });
  },

  /**
   * fileGetStatus request.
   *
   * @param {string|number} fileId
   * @returns {Promise}
   */
  fileGetStatus(fileId) {
    const safeFileId = parseInt(fileId, 10);

    return requester.fetch({
      url: `/file/${safeFileId}/status`,
      method: 'get',
    });
  },

  /**
   * fileRerender request.
   *
   * @param {string|number} fileId
   * @returns {Promise}
   */
  fileRerender(fileId) {
    const safeFileId = parseInt(fileId, 10);

    return requester.fetch({
      url: '/file/rerender',
      method: 'post',
      data: {
        fileId: safeFileId,
      },
    });
  },

  /**
   * fileUpload request.
   *
   * Uploads multipart form.
   *
   * @param {{}} file
   * @param {number} folderId
   * @param {string=} title
   * @param {function=} onProgress
   * @param {function=} cancelable
   * @returns {Promise}
   */
  fileUpload(file, folderId, title, onProgress, cancelable) {
    const data = new FormData();
    data.append('file', file);

    const params = {
      folderId: parseInt(folderId, 10) || null,
      fileSize: parseInt(file.size, 10) || null,
    };

    const requestData = {
      url: '/file',
      method: 'post',
      headers: {'Content-Type': 'multipart/form-data'},
      params,
      data,
    };

    if (onProgress) {
      requestData.onUploadProgress = onProgress;
    }
    if (cancelable) {
      requestData.cancelable = cancelable;
    }

    return requester.request(requestData);
  },

  /**
   * fileFolderCreate request.
   *
   * @param {{name: string, type: string}} folderData
   * @returns {Promise}
   */
  fileFolderCreate: function fileFolderCreate(folderData) {
    return requester.fetch({
      url: '/file-folder',
      method: 'post',
      data: folderData,
    });
  },

  /**
   * fileFolderGetFiles request.
   *
   * @param {number} folderId
   * @returns {Promise}
   */
  fileFolderGetFiles: function fileFolderGetFiles(folderId) {
    const safeFolderId = parseInt(folderId, 10);

    return requester.fetch({
      url: `/file-folder/${safeFolderId}/files`,
      method: 'get',
    });
  },

  /**
   * fileFolderGetUserFolders request.
   *
   * @param {string=} type
   * @returns {Promise}
   */
  fileFolderGetUserFolders: function fileFolderGetUserFolders(type) {
    const params = {};
    if (type) {
      params.type = type;
    }

    return requester.fetch({
      url: '/file-folder',
      method: 'get',
      params,
    });
  },

  /**
   * planGetAll request.
   *
   * @param {boolean} getFeatures
   * @returns {Promise}
   */
  planGetAll(getFeatures) {
    return requester.fetch({
      url: '/plans',
      method: 'get',
      params: {
        getFeatures: Boolean(getFeatures),
      },
    });
  },

  /**
   * signGetAllForMe request.
   *
   * @returns {Promise}
   */
  signGetAllForMe() {
    return requester.fetch({
      url: '/company-signs',
      method: 'get',
    });
  },

  /**
   * adminSignGetAllForCompany request.
   *
   * @param {string} companyId
   * @returns {Promise}
   */
  adminSignGetAllForCompany(companyId) {
    return requester.fetch({
      url: `/admin/company-signs/${companyId}`,
      method: 'get',
    });
  },

  /**
   * signCreate request.
   *
   * @param {{}} signData
   * @returns {Promise}
   */
  signCreate: function signCreate(signData) {
    return requester.fetch({
      url: '/company-sign',
      method: 'post',
      data: signData,
    });
  },

  /**
   * adminSignCreate request.
   *
   * @param {{}} signData
   * @returns {Promise}
   */
  adminSignCreate: function adminSignCreate(signData) {
    return requester.fetch({
      url: '/admin/company-sign',
      method: 'post',
      data: signData,
    });
  },

  /**
   * signDelete request.
   *
   * @param {number} signId
   * @returns {Promise}
   */
  signDelete: function signDelete(signId) {
    const safeSignId = parseInt(signId, 10);

    return requester.fetch({
      url: `/company-sign/${safeSignId}`,
      method: 'delete',
    });
  },

  /**
   * signUpdate request.
   *
   * @param {number} signId
   * @param {{}} signData
   * @returns {Promise}
   */
  signUpdate: function signUpdate(signId, signData) {
    const safeSignId = parseInt(signId, 10);

    return requester.fetch({
      url: `/company-sign/${safeSignId}`,
      method: 'patch',
      data: signData,
    });
  },

  /**
   * adminSignUpdate request.
   *
   * @param {number} signId
   * @param {{}} signData
   * @returns {Promise}
   */
  adminSignUpdate: function adminSignUpdate(signId, signData) {
    const safeSignId = parseInt(signId, 10);

    return requester.fetch({
      url: `/admin/company-sign/${safeSignId}`,
      method: 'patch',
      data: signData,
    });
  },

  /**
   * contentGetById request.
   *
   * @param {number} contentId
   * @returns {Promise}
   */
  contentGetById: function contentGetById(contentId) {
    return Promise.resolve(contentId);
  },

  /**
   * Chargebee checkout request
   * @param {{planId: number}} data
   * @returns {Promise}
   */
  chargebeeCheckout(data) {
    return requester.fetch({
      url: '/chargebee/checkout',
      method: 'post',
      data: data
    });
  },

  /**
   * Check to see if user can update plan
   * @returns {Promise}
   */
  canUserUpdatePlan() {
    return requester.fetch({
      url: '/user/can-update-plan',
      method: 'get'
    });
  },

  /**
   * Get user email based on auth0 id
   * @param {string} authId
   * @returns {Promise}
   */
  getEmailFromAuth0(authId) {
    return requester.hubFetch({
      url: `api/users/${authId}/auth0-email`,
      method: 'get',

      // omit credentials so emulation cookie is not passed in during signup
      // process (this route needs to be ran by super-admin user)
      credentials: 'omit',
    });
  },

  /**
   * Clear user emulation
   * @returns {Promise}
   */
  clearEmulateUser() {
    return requester.hubFetch({
      url: 'api/users/clear-emulate',
      method: 'post',
    });
  },

  /**
   * Generate text
   *
   * @param {string} textPrompt
   * @param {string} contentType
   * @returns {Promise}
   */
  generateText(textPrompt, contentType) {
    return requester.fetch({
      url: '/generate/text',
      method: 'post',
      data: {
        prompt: textPrompt,
        contentType,
      },
    });
  },

  /**
   * Generate image
   *
   * @param {string} subject
   * @param {string|null} style
   * @param {string[]} themes
   * @returns {Promise}
   */
  generateImage(subject, style, themes) {
    return requester.fetch({
      url: '/generate/image',
      method: 'post',
      data: {
        subject,
        style,
        themes,
      },
    });
  },

  /**
   * companyGetOneById request.
   *
   * @param {string} companyId
   * @returns {Promise}
   */
  companyGetOneById(companyId) {
    return requester.hubFetch({
      url: `api/companies/${companyId}`,
      method: 'get',
    });
  },

  /**
   * unsplashPhotoSearch request
   *
   * @param {string} searchValue
   * @param {number} page
   * @returns {Promise}
   */
  unsplashPhotoSearch(searchValue, page) {
    return requester.fetch({
      url: '/unsplash/photos/search',
      method: 'get',
      params: {
        searchValue,
        page,
      },
    });
  },

  /**
   * unsplashPhotoTrack request
   *
   * @param {string} downloadLocation
   * @returns {Promise}
   */
  unsplashPhotoTrack(downloadLocation) {
    return requester.fetch({
      url: '/unsplash/photos/download-location',
      method: 'get',
      params: {
        downloadLocation,
      },
    });
  },
};

export default serverApi;
