import classNames from 'classnames';
import {RichUtils} from 'draft-js';
import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBold, faItalic, faUnderline} from '@fortawesome/free-solid-svg-icons';

import {BOLD, ITALIC, UNDERLINE} from '../../constants/styleConstants';

import './styleButtons.scss';

/**
 * The list of style buttons.
 *
 * @type {Array.<{label: string, style: import('@fortawesome/fontawesome-svg-core').IconProp}>}
 */
const STYLE_BUTTON_TYPES = [
  {label: 'Bold', style: BOLD, icon: faBold},
  {label: 'Italic', style: ITALIC, icon: faItalic},
  {label: 'Underline', style: UNDERLINE, icon: faUnderline},
];

/**
 * The StyleButtons component.
 *
 * @param {{}} params
 * @param {{}} params.editorState
 * @param {function(EditorState)} params.onChangeTextState
 * @param {function()=} params.beforeChange
 * @returns {React.Component}
 */
export const StyleButtons = ({editorState, onChangeTextState, beforeChange}) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  /**
   * Updates the editor state with a new style when a button is clicked.
   *
   * @param {string} clickedStyle
   * @returns {function()}
   */
  const onClick = (clickedStyle) => {
    return () => {
      let changeState = editorState;
      if (beforeChange) {
        changeState = beforeChange();
      }

      const newEditorState = RichUtils.toggleInlineStyle(changeState, clickedStyle);
      onChangeTextState(newEditorState);
    };
  };

  return (
    <div className="style-buttons form-buttons">
      {STYLE_BUTTON_TYPES.map((styleButton) => {
        const classes = {
          'active': currentStyle.has(styleButton.style)
        };

        return (
          <button
            className={classNames('btn btn-sm btn-light form-button', classes)}
            type="button"
            key={styleButton.label}
            onClick={onClick(styleButton.style)}
          >
            <FontAwesomeIcon icon={styleButton.icon} />
          </button>
        );
      })}
    </div>
  );
};

StyleButtons.propTypes = {
  editorState: PropTypes.object.isRequired,
  onChangeTextState: PropTypes.func.isRequired,

  beforeChange: PropTypes.func,
};

export default StyleButtons;
