export default {
  DAY_ALIGN: '|',
  HIGH_ALIGN: '|',
  LOW_ALIGN: '|',

  DAY_WIDTH_RATIO: 0.1302,
  DAY_HEIGHT_RATIO: 0.0986,
  DAY_ONE_POSITION_X_RATIO: 0.1097,
  DAY_ONE_POSITION_Y_RATIO: 0.0481,
  DAY_TWO_POSITION_X_RATIO: 0.4435,
  DAY_TWO_POSITION_Y_RATIO: 0.0481,
  DAY_THREE_POSITION_X_RATIO: 0.7695,
  DAY_THREE_POSITION_Y_RATIO: 0.0481,

  ICON_WIDTH_RATIO: 0.2801,
  ICON_HEIGHT_RATIO: 0.7211,
  ICON_ONE_POSITION_X_RATIO: 0.0087,
  ICON_ONE_POSITION_Y_RATIO: 0.0972,
  ICON_TWO_POSITION_X_RATIO: 0.3425,
  ICON_TWO_POSITION_Y_RATIO: 0.0972,
  ICON_THREE_POSITION_X_RATIO: 0.6685,
  ICON_THREE_POSITION_Y_RATIO: 0.0972,

  HIGH_WIDTH_RATIO: 0.1446,
  HIGH_HEIGHT_RATIO: 0.2698,
  HIGH_ONE_POSITION_X_RATIO: 0.1025,
  HIGH_ONE_POSITION_Y_RATIO: 0.5864,
  HIGH_TWO_POSITION_X_RATIO: 0.4363,
  HIGH_TWO_POSITION_Y_RATIO: 0.5864,
  HIGH_THREE_POSITION_X_RATIO: 0.7623,
  HIGH_THREE_POSITION_Y_RATIO: 0.5864,

  LOW_WIDTH_RATIO: 0.0748,
  LOW_HEIGHT_RATIO: 0.1838,
  LOW_ONE_POSITION_X_RATIO: 0.1374,
  LOW_ONE_POSITION_Y_RATIO: 0.793,
  LOW_TWO_POSITION_X_RATIO: 0.4712,
  LOW_TWO_POSITION_Y_RATIO: 0.793,
  LOW_THREE_POSITION_X_RATIO: 0.7972,
  LOW_THREE_POSITION_Y_RATIO: 0.793,
};
