import React from 'react';
import propTypes from 'prop-types';

import ButtonAttentionWrapper from '../buttonAttentionWrapper/ButtonAttentionWrapper';

import './generateImageButton.scss';

/**
 * The GenerateImageButton component.
 */
export class GenerateImageButton extends React.Component {
  /**
     * Renders the component.
     *
     * @returns {{}}
     */
  render() {
    const {className, onClick, style} = this.props;

    return (
      <ButtonAttentionWrapper
        className={className}
        style={style}
      >
        <button
          className="btn btn-block btn-sm btn-dark-blue text-white d-flex align-items-center justify-content-between"
          type="button"
          onClick={onClick}
          gtm-id="ai-generate-image"
        >
          Generate Image
          <span className="badge badge-primary ml-2 text-uppercase">Beta</span>
        </button>
      </ButtonAttentionWrapper>
    );
  }
}

GenerateImageButton.propTypes = {
  className: propTypes.string,
  onClick: propTypes.func,
  style: propTypes.object,
};

export default GenerateImageButton;
