import axios from 'axios';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import moment from 'moment';
import React, {Component} from 'react';

import {MOMENT_LONG_DATE_FORMAT} from '../../../constants/uiConstants';
import Link from '../../common/link/Link';
import LoadingIcon from '../../common/loadingIcon/LoadingIcon';
import inject from '../../hoc/injectHoc';
import {adminContentRequestsListRoute} from '../../routePaths';

import './adminContentRequestPage.scss';

/**
 * The AdminContentRequestPage component.
 */
export class AdminContentRequestPage extends Component {
  /**
   * The request fields and how they should be rendered.
   *
   * @type {Array<{}>}
   */
  requestFields = [
    {key: 'name', label: 'REQUEST ID', getValue: (item) => item.id},
    {key: 'user', label: 'USER', getValue: ({user}) => (user) ? `${user.fullName} (${user.email})` : ''},
    {
      key: 'dateSubmitted',
      label: 'DATE SUBMITTED',
      getValue: ({createdAt}) => moment(createdAt).format(MOMENT_LONG_DATE_FORMAT),
    },
    {
      key: 'emails',
      label: 'EMAILS',
      getValue: ({emails}) => (
        <a href={`mailto:${emails}`}>{emails.join(', ')}</a>
      ),
    },
    {
      key: 'companyName',
      label: 'COMPANY NAME'
    },
    {key: 'phoneNumber', label: 'PHONE NUMBER'},
    {key: 'purpose', label: 'PURPOSE'},
    {key: 'colorScheme', label: 'COLOR SCHEME'},
    {key: 'message', label: 'MESSAGE'},
    {key: 'animationSpeed', label: 'ANIMATION SPEED'},
    {key: 'animationStyle', label: 'ANIMATION STYLE'},
    {
      key: 'signs',
      label: 'SIGNS',
      getValue: ({signs}) => (
        (signs) ? (
          <ul>
            { signs.map((sign) => (
              <li key={sign.id}>
                {sign.manufacturer} {sign.width}x{sign.height} (Pitch: {sign.pitch}mm) [Name: {sign.name}]
              </li>
            ))}
          </ul>
        ) : 'No signs attached'
      ),
    },
    {
      key: 'files',
      label: 'FILES',
      getValue: ({files}) => (
        (files) ? (
          <ul>
            {files.map((file) => (
              <li key={file.id}>
                <a href="#" onClick={this.onClickDownload(file)}>{file.filename}</a>
              </li>
            ))}
          </ul>
        ) : 'No files attached'
      ),
    },
  ];

  /**
   * Triggered when the component first mounts to the page.
   */
  componentDidMount() {
    this.preloadRequest();
  }

  /**
   * Triggered when the props change.
   *
   * @param {{}} prevProps
   */
  componentDidUpdate(prevProps) {
    const previousRequestId = this.getRequestId(prevProps);
    const currentRequestId = this.getRequestId(this.props);

    if (previousRequestId !== currentRequestId) {
      this.preloadRequest();
    }
  }

  /**
   * Gets the user id from the url params.
   *
   * @param {{}=} props
   * @returns {number|undefined}
   */
  getRequestId = (props) => {
    const {
      /** @type {RouterStore} */ routerStore
    } = (props || this.props);

    return routerStore.getParam('requestId');
  };

  /**
   * Pre-loads the request data based on the param.
   */
  preloadRequest() {
    const requestId = this.getRequestId();
    if (!requestId) {
      return;
    }

    const {apiContentRequestGetOneStore} = this.props;

    apiContentRequestGetOneStore.refresh(requestId);
  }

  /**
   * Downloads the chosen item.
   *
   * @param {{filename: string, displayPath: string}} file
   * @returns {function}
   */
  onClickDownload = (file) => {
    return (clickEvent) => {
      clickEvent.preventDefault();

      if (!file.displayPath) {
        return;
      }

      const fileName = `${file.filename}.${file.ext}`;

      axios({
        url: file.displayPath,
        method: 'GET',

        // use cache-busting query param to avoid browser using non cors cached image
        // this would happen if the preview modal loaded an image (w/o cors), then we tried
        // to download using xhr (w/ cors) in which case the browser uses the cached response
        // (which does not have cors attached it) and ultimately throws a CORS error
        // see https://serverfault.com/a/856948
        params: {
          download: Date.now(),
        },
        responseType: 'blob',
      }).then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data])
        );

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
      });
    };
  };

  /**
   * Renders the content request information.
   *
   * @param {{}} contentRequest
   * @returns {{}}
   */
  renderRequestInformation = (contentRequest) => {
    if (!contentRequest) {
      return null;
    }

    return (
      <table className="table table-dark table-striped content-request-information">
        <thead>
          <tr>
            <th colSpan="2">Content Request</th>
          </tr>
        </thead>
        <tbody>
          {this.requestFields.map(({getValue, key, label}) => (
            <tr key={key}>
              <th className="data-label" scope="row">{label}</th>

              <td className="data-value">
                {(getValue) ? getValue.call(this, contentRequest) : contentRequest[key]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {apiContentRequestGetOneStore} = this.props;

    const requestId = this.getRequestId();

    return (
      <div id="admin-content-request-page" className="system-page full-height">
        <div className="container">
          <Link to={adminContentRequestsListRoute}>
            <h4 className="h6">← Back to Content Requests</h4>
          </Link>

          <div className="view-wrapper">
            {apiContentRequestGetOneStore.case(requestId, {
              pre: () => (<LoadingIcon />),
              pending: () => (<LoadingIcon />),
              rejected: () => (
                <div className="alert alert-warning">
                  The content request could not be loaded.
                </div>
              ),
              fulfilled: (contentRequest) => (
                this.renderRequestInformation(contentRequest)
              ),
            })}
          </div>
        </div>
      </div>
    );
  }
}

AdminContentRequestPage.propTypes = {
  apiContentRequestGetOneStore: MobxPropTypes.observableObject,
  routerStore: MobxPropTypes.observableObject,
};

AdminContentRequestPage.wrappedComponent = {};
AdminContentRequestPage.wrappedComponent.propTypes = {
  apiContentRequestGetOneStore: MobxPropTypes.observableObject.isRequired,
  routerStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(AdminContentRequestPage)(
  observer(AdminContentRequestPage)
);
