import lodash from 'lodash';
import {action} from 'mobx';

import {EXPIRES_ERROR, EXPIRES_PENDING, EXPIRE_TIME} from '../../../constants/storeConstants';
import {STATE_FULFILLED, STATE_PENDING, STATE_REJECTED} from '../../../constants/asyncConstants';
import serverApi from '../../../utils/serverApi';
import ApiBaseStore from '../common/apiBaseStore';

/**
 * ApiUserUpdateMeStore
 */
class ApiUserUpdateMeStore extends ApiBaseStore {
  /**
   * Checks if item is available in the store
   *  True if state = pending or fulfilled, and not expired
   *
   * @returns {boolean}
   */
  isDataAvailable() {
    const isValidState = lodash.includes([STATE_PENDING, STATE_FULFILLED, STATE_REJECTED], this.state);
    const isNotExpired = (this[EXPIRE_TIME] > Date.now());

    return (isValidState && isNotExpired);
  }

  /**
   * Fetches currently authenticated user from the server.
   *
   * @param {{}} userData
   */
  @action makeRequest(userData) {
    this.state = STATE_PENDING;
    this[EXPIRE_TIME] = Date.now() + EXPIRES_PENDING;

    serverApi.userUpdateMe(userData)
      .then(action('userUpdateMeSuccess', (wasSuccessful) => {
        this.state = STATE_FULFILLED;
        this[EXPIRE_TIME] = Date.now() + EXPIRES_ERROR; // Only cache user for 2.5 minutes.
        this.data = wasSuccessful;
        this.error = null;
      })).catch(action('userUpdateMeError', (userUpdateMeError) => {
        this.state = STATE_REJECTED;
        this[EXPIRE_TIME] = Date.now() + EXPIRES_ERROR;
        this.data = null;
        this.error = userUpdateMeError;
      }));
  }
}

export default new ApiUserUpdateMeStore();
