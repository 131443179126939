import lodash from 'lodash';
import {runInAction} from 'mobx';

import {ALIGNMENTS} from '../components/common/positionComponent';
import {clearTransitionCache, updateEntity} from '../ecs/entityHelper';

/**
 * The name of the system.
 * @const {string}
 */
export const POSITIONING_ALIGN_SYSTEM = 'positioningAlignSystem';

/**
 * Gets a new instance of the position align system.
 *
 * @param {GameStore} game
 * @returns {{name: string, runActions: systemRunActions}}
 */
export function positioningAlignSystem(game) {
  /**
   * Called when the game loop updates.
   *
   * @param {Array.<{}>} actions
   */
  function systemRunActions(actions) {
    actions.forEach((actionEntity) => {
      // First check for required components.
      if (!actionEntity.has('actionPositionAlign')) {
        return;
      }

      const entityId = actionEntity.get('action').entityId;
      const entity = game.getEntity(entityId);
      if (!entity) {
        return;
      }

      const actionPositionAlign = actionEntity.get('actionPositionAlign');
      if (actionPositionAlign.axis !== 'x' && actionPositionAlign.axis !== 'y') {
        throw new Error('Invalid axis given to actionPositionAlign component.');
      }

      const newAlignment = actionPositionAlign.alignment;
      if (actionPositionAlign.axis === 'x' && !ALIGNMENTS.x[newAlignment]) {
        throw new Error('Invalid x-axis alignment given to actionPositionAlign component.');
      } else if (actionPositionAlign.axis === 'y' && !ALIGNMENTS.y[newAlignment]) {
        throw new Error('Invalid y-axis alignment given to actionPositionAlign component.');
      }

      let safePosition = lodash.cloneDeep(entity.get('position'));
      let safeImage = null;

      // Now make sure the changes won't push the entity off the display.
      safePosition = checkForOverflow(safePosition, actionPositionAlign.axis);
      if (entity.get('element') === 'image') {
        safeImage = checkForOverflow(entity.get('image'), actionPositionAlign.axis);
      }

      if (actionPositionAlign.axis === 'x') {
        safePosition.alignment.x = ALIGNMENTS.x[newAlignment];
      } else if (actionPositionAlign.axis === 'y') {
        safePosition.alignment.y = ALIGNMENTS.y[newAlignment];
      }

      // Now update the entity.
      runInAction('positioningAlignSystemUpdateEntity', () => {
        updateEntity(entity, 'position', safePosition);

        clearTransitionCache(entity);
      });

      if (safeImage) {
        runInAction('positioningAlignSystemUpdateImageEntity', () => {
          updateEntity(entity, 'image', safeImage);

          clearTransitionCache(entity);
        });
      }
    });
  }

  /**
   * Checks to make sure the change will not push the entity off the screen.
   *
   * @param {{}} position
   * @param {string} axis
   * @returns {{}}
   */
  function checkForOverflow(position, axis) {
    if (axis === 'x') {
      if (position.x < 0) {
        position.x = 0;

        if (position.default.xIsPercent) {
          position.default.x = '0%';
        } else {
          position.default.x = 0;
        }
      }
    } else if (axis === 'y') {
      if (position.y < 0) {
        position.y = 0;

        if (position.default.yIsPercent) {
          position.default.y = '0%';
        } else {
          position.default.y = 0;
        }
      }
    }

    return position;
  }

  return {
    name: POSITIONING_ALIGN_SYSTEM,
    runActions: systemRunActions
  };
}
