import classNames from 'classnames';
import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSort, faSortAsc, faSortDesc} from '@fortawesome/free-solid-svg-icons';

/**
 * Sorts in the ascending order.
 * @type {string}
 */
export const SORT_DIRECTION_ASC = 'SORT_DIRECTION_ASC';

/**
 * Sorts in the ascending order.
 * @type {string}
 */
export const SORT_DIRECTION_DESC = 'SORT_DIRECTION_DESC';

/**
 * The searchable and sortable table header component.
 */
export class SmartTHead extends React.Component {
  /**
   * The sorting direction.
   *
   * @type {string}
   */
  @observable sortDirection = SORT_DIRECTION_ASC;

  /**
   * The sorting column.
   *
   * @type {?string}
   */
  @observable sortOn = null;

  /**
   * Triggered when the component is about to receive new props.
   *
   * @param {{}} nextProps
   */
  componentWillReceiveProps(nextProps) {
    if (this.sortOn && nextProps.columns.length) {
      let cancelSort = false;
      nextProps.columns.forEach((column) => {
        if (column.hide && this.state.searchOn === column.key) {
          cancelSort = true;
        }
      });

      if (cancelSort) {
        this.clearSort();
      }
    }
  }

  /**
   * Triggers when the table sorts.
   *
   * @param {{sortOn: ?string, sortDirection: ?string}} sortData
   */
  @action setSort = ({sortOn, sortDirection}) => {
    this.sortOn = sortOn || null;
    this.sortDirection = sortDirection || SORT_DIRECTION_ASC;
  };

  /**
   * Sorts the table when a header is clicked.
   *
   * @param {{key: string, label: string}} column
   * @returns {function}
   */
  onHeaderClicked = (column) => {
    return (clickEvent) => {
      clickEvent.preventDefault();

      const {onSort} = this.props;
      if (!onSort) {
        return;
      }

      let sortDirection = SORT_DIRECTION_ASC;
      if (this.sortOn === column.key) {
        if (this.sortDirection !== SORT_DIRECTION_DESC) {
          sortDirection = SORT_DIRECTION_DESC;
        } else {
          sortDirection = null;
        }
      }

      const sortOn = (sortDirection) ? column.key : null;

      this.setSort({
        sortOn,
        sortDirection,
      });

      onSort({
        sortOn,
        sortDirection,
      });
    };
  };

  /**
   * Removes the current sorting.
   */
  clearSort = () => {
    const {onSort} = this.props;

    const sortOn = null;
    const sortDirection = null;

    this.setSort({
      sortOn,
      sortDirection,
    });

    if (onSort) {
      onSort({
        sortOn,
        sortDirection,
      });
    }
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {className, onSort} = this.props;

    const columns = this.props.columns || [];

    return (
      <thead className={classNames('table-header', className)}>
        <tr>
          {columns.map((column) => {
            if (column.hide) {
              return null;
            }

            if (!onSort) {
              return (
                <th key={column.key}>{column.label}</th>
              );
            }

            return (
              <th scope="col" key={column.key} onClick={this.onHeaderClicked(column)}>
                <span className="column-label">{column.label}</span>

                {(this.sortOn === column.key) ? (
                  <span className="pull-right ml-1">
                    <FontAwesomeIcon
                      icon={this.sortDirection === SORT_DIRECTION_ASC
                        ? faSortAsc
                        : faSortDesc
                      }
                    />
                  </span>
                ) : (
                  <span className="show-on-hover pull-right ml-1">
                    <FontAwesomeIcon icon={faSort} />
                  </span>
                )}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  }
}

SmartTHead.propTypes = {
  columns: PropTypes.array.isRequired,

  className: PropTypes.string,
  onSort: PropTypes.func,
};

export default observer(SmartTHead);
