import {runInAction, toJS} from 'mobx';

import {orderToZIndex} from '../components/common/positionComponent';
import {updateEntity} from '../ecs/entityHelper';

/**
 * The name of the system.
 * @const {string}
 */
export const ORDERING_SYSTEM = 'orderingSystem';

/**
 * Gets a new instance of the ordering system.
 *
 * @param {GameStore} game
 * @returns {{name: string, runActions: systemRunActions}}
 */
export function orderingSystem(game) {
  /**
   * Called right before the game loop updates.
   *
   * @param {ObservableArray} actions
   * @param {ObservableArray} entities
   */
  function systemRunActions(actions, entities) {
    let wasUpdated = false;

    actions.forEach((actionEntity) => {
      // First check for required components.
      if (!actionEntity.has('actionReorder')) {
        return;
      }

      const entityId = actionEntity.get('action').entityId;
      const targetEntity = game.getEntity(entityId);
      if (!targetEntity) {
        return;
      } else if (targetEntity.has('locked') && (targetEntity.get('locked').indexOf('order') > -1)) {
        return;
      }

      const orderDelta = actionEntity.get('actionReorder').orderDelta;
      if (!orderDelta) {
        return;
      }

      const isNegative = (orderDelta < 0);
      const moveSteps = Math.abs(orderDelta);

      let entityIndex = 0;
      for (let iteration = 0; iteration < entities.length; iteration += 1) {
        if (entities[iteration].get('id') === entityId) {
          entityIndex = iteration;
          break;
        }
      }

      if (isNegative && entityIndex <= 0) {
        // We can't move an entity past 0.
        return;
      } else if (!isNegative && entityIndex >= entities.length - 1) {
        // We can't move an entity past the end of the array.
        return;
      }

      const toIndex = findFinalIndexOfEntity(entities, entityIndex, isNegative, moveSteps);

      if (toIndex !== null) {
        entities.splice(toIndex, 0, entities.splice(entityIndex, 1)[0]);
        wasUpdated = true;
      }
    });

    if (wasUpdated) {
      updateEntityZIndexes(entities);
    }
  }

  /**
   * Finds the final index for the entity while checking for edges and locked conditions.
   *
   * @param {ObservableArray} entities
   * @param {number} entityIndex
   * @param {boolean} isNegative
   * @param {number} moveSteps
   * @returns {number}
   */
  function findFinalIndexOfEntity(entities, entityIndex, isNegative, moveSteps) {
    let toIndex = entityIndex;

    for (let currentStep = 1; currentStep <= moveSteps; currentStep += 1) {
      if (isNegative) {
        if (entityIndex - currentStep < 0) {
          break;
        }

        const previousEntity = entities[entityIndex - currentStep];
        if (previousEntity.has('locked') && previousEntity.get('locked').indexOf('order') > -1) {
          // If the previous entity is order locked, then we can't move this one through it.
          break;
        }

        toIndex -= 1;
      } else {
        if (entityIndex + currentStep >= entities.length) {
          break;
        }

        const nextEntity = entities[entityIndex + currentStep];
        if (nextEntity.has('locked') && nextEntity.get('locked').indexOf('order') > -1) {
          // If the next entity is order locked, then we can't move this one through it.
          break;
        }

        toIndex += 1;
      }
    }

    const firstFeedIndex = toJS(entities)
      .findIndex((item) => item.element.includes('feed'));

    // if trying to re-order to be on top of feed layer, re-order layer to right below feed
    if (firstFeedIndex !== -1 && toIndex >= firstFeedIndex) {
      return Math.max(firstFeedIndex - 1, 0);
    }

    return toIndex;
  }

  /**
   * Updates the zIndex for each entity based on the new order.
   *
   * @param {ObservableArray} entities
   */
  function updateEntityZIndexes(entities) {
    entities.forEach((entity, index) => {
      updateEntityForOrdering(entity, orderToZIndex(index));
    });
  }

  /**
   * Updates the given entity with the differences given.
   *
   * @param {{}} entity
   * @param {number} zIndex
   */
  function updateEntityForOrdering(entity, zIndex) {
    runInAction('orderingSystemUpdateEntity', () => {
      updateEntity(entity, 'position', {zIndex});
    });
  }

  return {
    name: ORDERING_SYSTEM,
    runActions: systemRunActions
  };
}
