import React from 'react';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons';

import inject from '../../../../../hoc/injectHoc';
import logo from '../../../../../../assets/images/logo_white.png';
import PlanItem from '../../../../../../components/common/subscriptionPlanTable/components/planItem/PlanItem';

import {FEATURES_TABLE_DATA} from './NextManufacturerConstants';

/**
 * The NextManufacturerPromo component.
 */
export class NextManufacturerPromo extends React.Component {
  /**
   * Render promo feature table
   *
   * @returns {{}}
   */
  renderFeatureTable = () => {
    return (
      <div className="table-wrapper">
        <div className="pc-feature-table">
          <div className="header-row">
            <div className="feature-col">Features</div>
            <div className="pc-col">
              <img
                className="pc-logo"
                src={logo}
                alt="Project Content Logo"
              />
            </div>
            <div className="others-col">Some Have</div>
          </div>
          {FEATURES_TABLE_DATA.map((feature) => {
            return (
              <div key={feature.id} className="feature-row">
                <div className="feature-col">{feature.feature}</div>
                <div className="pc-col d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon
                    style={{fontSize: '22px'}}
                    icon={faCheck}
                  />
                </div>
                <div className="others-col d-flex align-items-center justify-content-center">
                  {feature.some
                    ? (<FontAwesomeIcon
                      icon={faCheck}
                      style={{fontSize: '22px'}}
                    />)
                    : null}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  /**
   * Renders the promo page.
   *
   * @returns {{}}
   */
  renderView = () => {
    const {highlightedPlan} = this.props;

    return (
      <>
        <h1 className="next-promo-title">What&apos;s Included?</h1>
        <div className="next-promo-container">
          {highlightedPlan && (
            <div className="next-plan-item">
              <PlanItem
                plan={highlightedPlan}
                planIndex={0}
                planCount={4}
                emphasizedMessage="Lifetime Coverage at No Cost"
                planTitleClass="large"
                planPriceNumberClass="small-inline"
                planDescriptionTitleClass="font-weight-bold"
              />
            </div>)}
          {this.renderFeatureTable()}
        </div>
      </>
    );
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    return (
    <>{this.renderView()}</>
    );
  }
}

NextManufacturerPromo.propTypes = {
  highlightedPlan: PropTypes.object.isRequired,
  apiPlanGetAllStore: MobxPropTypes.observableObject,
};

NextManufacturerPromo.wrappedComponent = {};
NextManufacturerPromo.wrappedComponent.propTypes = {
  apiPlanGetAllStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(NextManufacturerPromo)(
  observer(NextManufacturerPromo)
);
