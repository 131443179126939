import {toJS} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import CropCorner from './components/CropCorner';
import CropOutline from './components/CropOutline';
import * as resizeCorner from '../../../constants/resizeConstants';
import {getPixelFromPercentage, getPositionLeftAndTop} from '../../../display/ecs/entityHelper';
import {getIsEntityTransitioning} from '../../../utils/dragDropHelper';

import './displayCrop.scss';

/**
 * Crop entities need have the highest zIndex in the display.
 * So this needs to be a very high number and higher than any other zIndex.
 *
 * @const {number}
 */
const CROP_ENTITY_ZINDEX = 1000002;

/**
 * The size of the interaction control.
 * @const {number}
 */
const CONTROL_SIZE = 10;

/**
 * The fill color for the interaction control.
 * @const {string}
 */
const CONTROL_COLOR = 'rgba(210, 210, 210, 0.85)';

/**
 * The fill outline color for the interaction control.
 * @const {string}
 */
const CONTROL_ALT_COLOR = '#000000';

/**
 * Multiplier for how much larger the svg viewBox will be than the display.
 * @const {number}
 */
const VIEW_BOX_MODIFIER = 3;

/**
 * The DisplayCrop component.
 */
class DisplayCrop extends React.Component {
  /**
   * Gets the styles from the entity.
   *
   * @param {{}} entity
   * @param {{}} game
   * @returns {{outlineStyle: {}, rotationAttributes: {}, rotationStyle: {}, svgStyle: {}}}
   */
  getStylesFromEntity = (entity, game) => {
    const safeEntity = toJS(entity);

    const overflowModifier = 2;
    const overflowHeight = game.resolution.height * overflowModifier;
    const overflowWidth = game.resolution.width * overflowModifier;

    const svgStyle = {
      position: 'absolute',
      zIndex: CROP_ENTITY_ZINDEX,
      top: `${0 - overflowHeight}px`,
      left: `${0 - overflowWidth}px`,
      height: overflowHeight * VIEW_BOX_MODIFIER,
      width: overflowWidth * VIEW_BOX_MODIFIER,
    };

    const currentPosition = safeEntity.position;
    const currentType = safeEntity[safeEntity.element];
    const currentCrop = safeEntity.crop || {y: 0, x: 0, width: 100, height: 100};

    const typeStyle = getPositionLeftAndTop(currentPosition.alignment, currentType, currentType, game);

    const typeX = typeStyle.left;
    const typeY = typeStyle.top;

    const outlineStyle = {
      top: typeY + getPixelFromPercentage(currentType.height, currentCrop.y) + overflowHeight,
      left: typeX + getPixelFromPercentage(currentType.width, currentCrop.x) + overflowWidth,
      height: getPixelFromPercentage(currentType.height, currentCrop.height),
      width: getPixelFromPercentage(currentType.width, currentCrop.width),
    };

    const centerX = typeStyle.left + (currentType.width / 2);
    const centerY = typeStyle.top + (currentType.height / 2);

    const rotateDegrees = currentPosition.rotate || 0;
    const rotationStyle = {
      transform: `rotate(${rotateDegrees}deg)`,
      WebkitTransform: `rotate(${rotateDegrees}deg)`,
      transformOrigin: `${centerX}px ${centerY}px 0px`,
    };

    // Adding transform attribute as well as css because the css will not work in IE11...
    const rotationAttributes = {
      transform: `rotate(${rotateDegrees}, ${centerX}, ${centerY})`,
    };

    return {
      outlineStyle,
      rotationAttributes,
      rotationStyle,
      svgStyle
    };
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, game} = this.props;

    if (!entity.has('visible') || !entity.get('visible').isVisible) {
      return null;
    } else if (entity.get('element') === 'line') {
      return null;
    } else if (!entity.has('size') || !entity.has('position')) {
      return null;
    }

    const entityIsTransitioning = getIsEntityTransitioning(
      entity,
      game.timer.elapsedTime,
      ['position']
    );

    const {outlineStyle, rotationAttributes, rotationStyle, svgStyle} = this.getStylesFromEntity(entity, game);

    const outlineWidth = 3;
    const outlinePath = [
      `M ${outlineStyle.left},${outlineStyle.top}`,
      `H ${outlineStyle.left + outlineStyle.width}`,
      `V ${outlineStyle.top + outlineStyle.height}`,
      `H ${outlineStyle.left}`,
      'Z',
    ];

    const outlineStartX = outlineStyle.left;
    const outlineStartY = outlineStyle.top;
    const outlineMiddleX = outlineStyle.left + (outlineStyle.width / 2);
    const outlineMiddleY = outlineStyle.top + (outlineStyle.height / 2);
    const outlineFarX = outlineStyle.left + outlineStyle.width;
    const outlineFarY = outlineStyle.top + outlineStyle.height;

    /**
     * Gets an interaction control.
     *
     * @param {number} x
     * @param {number} y
     * @param {string} resizePosition
     * @returns {object}
     */
    function getControl(x, y, resizePosition) {
      const controlPadding = 4;
      const offset = controlPadding / 2;

      return (
        <CropCorner
          entity={entity}
          fillColor={CONTROL_COLOR}
          game={game}
          outlineWidth={outlineWidth + controlPadding}
          size={CONTROL_SIZE}
          startX={x - offset}
          startY={y - offset}
          resizePosition={resizePosition}
          strokeColor={CONTROL_ALT_COLOR}
        />
      );
    }

    return (
      <svg
        id="display-crop"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        fillRule="evenodd"
        fill="none"
        stroke="none"
        strokeLinecap="square"
        strokeMiterlimit="10"
        overflow="hidden"
        preserveAspectRatio="none"
        pointerEvents="none"
        viewBox={`0 0 ${svgStyle.width} ${svgStyle.height}`}
        style={svgStyle}
      >
        <g
          className="rotation-group"
          pointerEvents="visiblePainted"
          style={rotationStyle}
          {...rotationAttributes}
        >
          <g pointerEvents="visiblePainted">
            <CropOutline
              entity={entity}
              game={game}
              path={outlinePath.join(' ')}
              strokeColor={CONTROL_COLOR}
              strokeWidth={outlineWidth}
            />
          </g>

          {(!entityIsTransitioning) && (
            <g pointerEvents="visiblePainted">
              {getControl(outlineStartX, outlineStartY, resizeCorner.TOP_LEFT)}

              {getControl(outlineMiddleX, outlineStartY, resizeCorner.TOP_MIDDLE)}

              {getControl(outlineFarX, outlineStartY, resizeCorner.TOP_RIGHT)}

              {getControl(outlineStartX, outlineMiddleY, resizeCorner.MIDDLE_LEFT)}

              {getControl(outlineFarX, outlineMiddleY, resizeCorner.MIDDLE_RIGHT)}

              {getControl(outlineStartX, outlineFarY, resizeCorner.BOTTOM_LEFT)}

              {getControl(outlineMiddleX, outlineFarY, resizeCorner.BOTTOM_MIDDLE)}

              {getControl(outlineFarX, outlineFarY, resizeCorner.BOTTOM_RIGHT)}
            </g>
          )}
        </g>
      </svg>
    );
  }
}

DisplayCrop.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(DisplayCrop);
