import lodash from 'lodash';
import {action, observable, runInAction} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import ConfirmModal from '../../../../modals/confirm/ConfirmModal';
import SelectFolderModal from '../../../../modals/selectFolder/SelectFolderModal';
import {CONTENT_LIST_FOLDER} from '../../../../../constants/contentConstants';

import './prestoDraftButton.scss';

/**
 * The PrestoDraftButton component.
 */
export class PrestoDraftButton extends React.Component {
  /**
   * Whether or not the folder modal is open.
   *
   * @type {boolean}
   */
  @observable isFolderModalOpen = false;

  /**
   * Whether or not the confirm modal before saving a draft is open.
   *
   * @type {boolean}
   */
  @observable isBeforeDraftConfirmOpen = false;

  /**
   * Whether or not the dropdown for the save options is open.
   *
   * @type {boolean}
   */
  @observable isSaveAlertOpen = false;

  /**
   * The save alert message.
   *
   * @type {string}
   */
  @observable saveAlertMessage = '';

  /**
   * Opens the folder modal if the save was confirmed.
   *
   * @param {boolean} wasConfirmed
   */
  @action onSaveConfirmComplete = (wasConfirmed) => {
    this.isBeforeDraftConfirmOpen = false;

    if (!wasConfirmed) {
      return;
    }

    const {content, onCreated, updateTemplate} = this.props;

    if (!content.isDraft) {
      // If the content is not a draft, then we need to make a copy as the draft.
      this.isFolderModalOpen = true;
      return;
    }

    // If it is already a draft, then just save it.
    updateTemplate(true).then(
      () => {
        if (onCreated) {
          onCreated(null, {contentId: content.id});
        }
      },
      action('updateDraftTemplateErrorToAlert', (updateError) => {
        if (lodash.get(updateError, 'type') === 'OutOfCreditsError') {
          this.saveAlertMessage = 'You do not have enough download credits remaining to publish this content.'
            + ' Please save it as a draft until you have more credits available.';
        } else {
          this.saveAlertMessage = 'An error occurred while trying to update this template.'
            + ' Please wait a few minutes and try again.';
        }

        this.isSaveAlertOpen = true;
      })
    );
  };

  /**
   * Closes the save alert.
   */
  @action closeAlertModal = () => {
    this.isSaveAlertOpen = false;
  };

  /**
   * Toggles the save alert.
   */
  @action toggleSaveAlert = () => {
    this.isSaveAlertOpen = !this.isSaveAlertOpen;
  };

  /**
   * Triggered when the user clicks to save the draft.
   *
   * @param {{}} clickEvent
   */
  @action onSaveDraftClick = (clickEvent) => {
    clickEvent.preventDefault();

    this.isBeforeDraftConfirmOpen = true;
  };

  /**
   * Closes the folder data and saves the content.
   *
   * @param {{}} param
   * @param {?number} param.folderId
   * @param {string} param.contentName
   */
  @action closeFolderModal = ({folderId, contentName}) => {
    this.isFolderModalOpen = false;

    if (!contentName) {
      return;
    }

    const {saveNewTemplate, onCreated} = this.props;

    saveNewTemplate(folderId, contentName, true).catch(() => {
      runInAction('saveTemplateErrorToAlert', () => {
        this.saveAlertMessage = 'An error occurred while trying to save this template.'
          + ' Please wait a few minutes and try again.';
        this.isSaveAlertOpen = true;
      });

      return null;
    }).then((newContent) => {
      if (!newContent || !newContent.id) {
        return;
      }

      const contentId = newContent.id;

      if (contentId) {
        onCreated(null, {
          contentId,
          listType: CONTENT_LIST_FOLDER,
          listId: folderId,
        });
      }
    });
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {content} = this.props;

    return (
      <div className="presto-draft-button">
        <button
          className="btn btn-secondary action-button save-button"
          type="button"
          onClick={this.onSaveDraftClick}
        >Save Draft</button>

        {(this.isSaveAlertOpen) && (
          <ConfirmModal
            isOpen={true}
            confirmText={this.saveAlertMessage}
            isYesNo={false}
            title="After Saved"
            onComplete={this.closeAlertModal}
          />
        )}

        {(this.isBeforeDraftConfirmOpen) && (
          <ConfirmModal
            isOpen={true}
            bodyText={(
              <div>
                Save this presto template as a draft for future editing?
              </div>
            )}
            confirmText=""
            isYesNo={true}
            title="Save as a draft?"
            onComplete={this.onSaveConfirmComplete}
          />
        )}

        {(this.isFolderModalOpen) && (
          <SelectFolderModal
            defaultName={(content && content.name) ? content.name : ''}
            isOpen={true}
            onComplete={this.closeFolderModal}
          />
        )}
      </div>
    );
  }
}

PrestoDraftButton.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  onCreated: PropTypes.func.isRequired,
  saveNewTemplate: PropTypes.func.isRequired,
  updateTemplate: PropTypes.func.isRequired,
};

export default observer(PrestoDraftButton);
