import React, {Component} from 'react';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import {withAuth0} from '@auth0/auth0-react';

import PricingTabs from '../../common/pricingTabs/PricingTabs';
import inject from '../../hoc/injectHoc';
import {onboardingRoute, userPaymentRoute} from '../../routePaths';
import {replaceRouteParams} from '../../../utils/routeHelper';

import './pricingPage.scss';

/**
 * Check if the user has a plan
 *
 * @param {{}} user
 * @returns {boolean}
 */
const userHasCompanyPlan = (user) => {
  if (!user || !user.projectContentCompany) {
    return false;
  }

  return user.projectContentCompany.planId || user.projectContentCompany.requiresPlanUpdate;
};

/**
 * The PricingPage component.
 */
export class PricingPage extends Component {
  /**
   * Triggered when the component is first mounted to the page.
   */
  componentDidMount() {
    const {apiUserGetMeStore} = this.props;

    apiUserGetMeStore.refresh();
  }

  /**
   * Navigates the user to a defined route
   *
   * @param {string} toRoute
   */
  goToRoute = (toRoute) => {
    const {apiUserGetMeStore, routerStore} = this.props;

    apiUserGetMeStore.getPromise().then(() => {
      routerStore.push(toRoute);
    }).catch(() => {
      const {loginWithRedirect} = this.props.auth0;

      try {
        loginWithRedirect({
          authorizationParams: {
            'screen_hint': 'signup',
          }});
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('ERROR SIGNING UP: ', err);
      }
    });
  }

  /**
   * Triggered when the user chooses a plan.
   *
   * @param {{}} plan The plan object from the database.
   */
  onPlanSelect = (plan) => {
    const {apiUserGetMeStore} = this.props;

    const user = apiUserGetMeStore.getFulfilled();
    const userHasPlan = userHasCompanyPlan(user);

    const toRoute = userHasPlan ? replaceRouteParams(userPaymentRoute, {planId: plan.id}) : replaceRouteParams(onboardingRoute, {planId: plan.id});
    this.goToRoute(toRoute);
  };

  /**
   * Triggered when the user purchases a la carte credits.
   *
   * @param {{
   *  download: number,
   *  request: number
   * }} alaCarteCredits
   */
  onPurchaseCredits = (alaCarteCredits) => {
    const {apiUserGetMeStore} = this.props;

    const routeParams = {
      planId: null,
      downloadCreditQty: alaCarteCredits.download,
      requestCreditQty: alaCarteCredits.request
    };

    const user = apiUserGetMeStore.getFulfilled();
    const userHasPlan = userHasCompanyPlan(user);
    const toRoute = userHasPlan ? replaceRouteParams(userPaymentRoute, routeParams) : replaceRouteParams(onboardingRoute, routeParams);

    this.goToRoute(toRoute);
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const user = this.props.apiUserGetMeStore.getFulfilled();

    return (
      <div id="pricing-page" className="system-page full-height container-fluid">
        <div className="container px-0">
          <PricingTabs
            onPlanSelect={this.onPlanSelect}
            onPurchaseCredits={this.onPurchaseCredits}
            user={user}
          />
        </div>
      </div>
    );
  }
}

PricingPage.propTypes = {
  apiUserGetMeStore: MobxPropTypes.observableObject,
  auth0: PropTypes.object,
  routerStore: MobxPropTypes.observableObject,
};

PricingPage.wrappedComponent = {};
PricingPage.wrappedComponent.propTypes = {
  apiUserGetMeStore: MobxPropTypes.observableObject.isRequired,
  auth0: MobxPropTypes.observableObject.isRequired,
  routerStore: MobxPropTypes.observableObject.isRequired,
};

export default withAuth0(inject(PricingPage)(
  observer(PricingPage)
));
