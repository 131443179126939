export default {
  DAY_ALIGN: '|',
  HIGH_ALIGN: '\\',
  LOW_ALIGN: '\\',

  DAY_WIDTH_RATIO: 0.7927,
  DAY_HEIGHT_RATIO: 0.0583,
  DAY_ONE_POSITION_X_RATIO: 0.1,
  DAY_ONE_POSITION_Y_RATIO: 0.0609,
  DAY_TWO_POSITION_X_RATIO: 0.1,
  DAY_TWO_POSITION_Y_RATIO: 0.378,
  DAY_THREE_POSITION_X_RATIO: 0.1,
  DAY_THREE_POSITION_Y_RATIO: 0.6912,

  ICON_WIDTH_RATIO: 0.6727,
  ICON_HEIGHT_RATIO: 0.3879,
  ICON_ONE_POSITION_X_RATIO: -0.1312,
  ICON_ONE_POSITION_Y_RATIO: 0.0715,
  ICON_TWO_POSITION_X_RATIO: -0.1312,
  ICON_TWO_POSITION_Y_RATIO: 0.3886,
  ICON_THREE_POSITION_X_RATIO: -0.1312,
  ICON_THREE_POSITION_Y_RATIO: 0.7018,

  HIGH_WIDTH_RATIO: 0.4539,
  HIGH_HEIGHT_RATIO: 0.118,
  HIGH_ONE_POSITION_X_RATIO: 0.4879,
  HIGH_ONE_POSITION_Y_RATIO: 0.1364,
  HIGH_TWO_POSITION_X_RATIO: 0.4879,
  HIGH_TWO_POSITION_Y_RATIO: 0.4535,
  HIGH_THREE_POSITION_X_RATIO: 0.4879,
  HIGH_THREE_POSITION_Y_RATIO: 0.7667,

  LOW_WIDTH_RATIO: 0.4228,
  LOW_HEIGHT_RATIO: 0.0764,
  LOW_ONE_POSITION_X_RATIO: 0.5,
  LOW_ONE_POSITION_Y_RATIO: 0.242,
  LOW_TWO_POSITION_X_RATIO: 0.5,
  LOW_TWO_POSITION_Y_RATIO: 0.5591,
  LOW_THREE_POSITION_X_RATIO: 0.5,
  LOW_THREE_POSITION_Y_RATIO: 0.8723,
};
