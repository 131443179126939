import PropTypes from 'prop-types';
import React from 'react';

/**
 * Create a clip path for cropping a rectangle.
 *
 * @param {{}} props
 * @param {string} props.cropId
 * @param {{}} props.cropOptions
 * @returns {{}}
 */
export function CropRectangle({cropId, cropOptions}) {
  const offsetX = cropOptions.left;
  const offsetY = cropOptions.top;
  const width = cropOptions.width;
  const height = cropOptions.height;

  const borderRadius = cropOptions.borderRadius || 0;

  return (
    <clipPath id={cropId}>
      <rect x={offsetX} y={offsetY} width={width} height={height} rx={borderRadius} ry={borderRadius} />
    </clipPath>
  );
}

CropRectangle.propTypes = {
  cropId: PropTypes.string.isRequired,
  cropOptions: PropTypes.shape({
    height: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    top: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,

    borderRadius: PropTypes.number,
  }).isRequired,
};

export default CropRectangle;
