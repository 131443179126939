import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import DisplayEntities from '../displayEntities/DisplayEntities';
import ErrorBoundary from '../../common/errorBoundary/ErrorBoundary';

import './displaySource.scss';

/**
 * The DisplaySource component.
 */
class RawDisplaySource extends React.Component {
  /**
   * Gets the scale from the game if it can be used, otherwise just returns one.
   *
   * @returns {number}
   */
  getGameScale = () => {
    const {
      allowScaling,
      /** @type {GameStore} */ game,
    } = this.props;

    if (!allowScaling) {
      return 1;
    }

    return game.scale || 1;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      /** @type {GameStore} */ game,
      /** @type {DisplayZoomStore} */ zoomStore
    } = this.props;

    const gameResolution = game.resolution;
    const gameScale = this.getGameScale();

    const style = {
      width: gameResolution.width,
      height: gameResolution.height,
    };

    if (zoomStore && zoomStore.transformData) {
      const data = zoomStore.transformData;
      const scale = data.scale * gameScale;

      style.transform = `translate(${data.offsetX}px, ${data.offsetY}px) scale(${scale})`;
    } else if (gameScale !== 1) {
      style.transform = `scale(${gameScale})`;
    }

    return (
      <div id="display-source" style={style}>
        <ErrorBoundary message={<h1>An error occurred while trying to render the video.</h1>}>
          <DisplayEntities game={game} />
        </ErrorBoundary>
      </div>
    );
  }
}

RawDisplaySource.propTypes = {
  game: PropTypes.shape({
    entities: PropTypes.objectOf(PropTypes.object)
  }).isRequired,

  allowScaling: PropTypes.bool,
  zoomStore: MobxPropTypes.observableObject,
};

export const DisplaySource = observer(RawDisplaySource);
