import {action} from 'mobx';

import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import serverApi from '../../../utils/serverApi';
import ApiMapBaseStore from '../common/apiMapBaseStore';

/**
 * ApiFileGetOneStore
 */
class ApiFileGetOneStore extends ApiMapBaseStore {
  /**
   * Fetches file info from the server
   *
   * @param {string} fileId
   */
  @action makeRequest(fileId) {
    const safeFileId = String(fileId);

    this.dataMap.set(safeFileId, {
      state: STATE_PENDING,
      [EXPIRE_TIME]: Date.now() + EXPIRES_PENDING,
      data: null,
      error: null,
    });

    serverApi.fileGetOne(
      safeFileId
    ).then(action('fileGetOneSuccess', (foundFile) => {
      this.dataMap.set(safeFileId, {
        state: STATE_FULFILLED,
        [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
        data: foundFile,
        error: null,
      });
    })).catch(action('fileGetOneError', (fetchError) => {
      this.dataMap.set(safeFileId, {
        state: STATE_REJECTED,
        [EXPIRE_TIME]: null,
        data: null,
        error: fetchError,
      });
    }));
  }
}

export default new ApiFileGetOneStore();
