import lodash from 'lodash';
import {runInAction} from 'mobx';

import {maskComponent} from '../components/common/maskComponent';
import {transformComponent} from '../components/common/transformComponent';
import {initializeTransition, updateTransitionWithPreset} from '../components/common/transitionComponent';
import {getPixelFromPercentage, setEntityComponents, updateEntity} from '../ecs/entityHelper';
import {loadPresetDetailsFromSource} from '../ecs/transitionHelper';

/**
 * The name of the system.
 * @const {string}
 */
export const TRANSITION_SYSTEM = 'transitionSystem';

/**
 * Allows the transitions to process for this amount of time after the end time.
 * This allows transitions to reach 100% even if the time increment doesn't hit exactly at 100%.
 * The lowest allowed fps is 10, therefore the overshoot would be [(1000 ms / 10 frame) = 100 ms/frame] 100ms.
 * We go ahead and make the overshoot 2 frames (2 * 100ms = 200ms) since in rare cases I have seen the transition
 * not properly finish with a 1 frame buffer
 * @const {number}
 */
const OVERSHOOT_BUFFER = 200;

/**
 * Converts milliseconds to seconds when multiplied.
 * @const {number}
 */
const MS_TO_SECONDS = 1000;

/**
 * Whether or not the first time entity setup has run.
 * @type {boolean}
 */
let firstTimeSetupHasRun = false;

/**
 * Gets a new instance of the transition system.
 *
 * @param {GameStore} game
 * @param {{}} presets All the loaded presets from transitionPreset/*.
 * @returns {{name: string, update: systemUpdate}}
 */
export function transitionSystem(game, presets) {
  /**
   * Gets the start and end time for the given transition.
   *
   * @param {{time: {start: number, end: number}, preset: string}} transition
   * @param {{start: number, end: number}} entityTime
   * @returns {{start: number, end: number}}
   */
  function getTransitionTimes(transition, entityTime) {
    const time = transition.time || {};

    // We need to offset the start and end by 1 to make sure the entity doesn't pop up at full opacity for 1 ms
    // (though this effect only seems to happen in phantomJS).
    const transitionStart = ((time.start) ? time.start : entityTime.start) - 1;
    const transitionEnd = ((time.end) ? time.end : entityTime.end) + 1;

    return {
      start: transitionStart,
      end: transitionEnd,
    };
  }

  /**
   * Called when the game loop updates.
   *
   * @param {Array.<{}>} entities
   * @param {number} time
   * @param {Object} extras
   * @param {boolean?} extras.forceTransition
   */
  function systemUpdate(entities, time, extras = {}) {
    entities.forEach((entity) => {
      // First check for required components.
      if (!entity.has('transition')) {
        return;
      }

      const entityTime = entity.get('time') || {};

      if (!time && !firstTimeSetupHasRun) {
        resetEntity(entity);

        // This will only ever run once.
        firstTimeSetupHasRun = true;
      }

      const timeInSeconds = time / MS_TO_SECONDS;

      const activeTransitionTypes = {};

      // TODO - this array chain can probably be cleaned up with a .reduce, however
      // I wanted to preserve the order of operations to not potentially break
      // the transition system
      const validTransitions = entity.get('transition')
        .filter((transition) => {
          if (!transition) {
            return false;
          }

          if (transition.preset && !transition.loadedPreset) {
            const presetDetails = loadPresetDetailsFromSource(transition.preset, presets);
            if (!presetDetails) {
              // This transition is invalid, bail out.
              return false;
            }

            updateTransitionWithPreset(entity, transition, presetDetails);
          }

          return true;
        })
        .map((transition) => {
          let wasInitialized = false;
          if (!transition.initialized) {
            initializeTransition(entity, transition, game);

            // The full transition needs to take place after initialization because the start values will be set
            // for the entity during initialization. If transition cache is cleared when the transition is not active,
            // then the entity would otherwise end up with the wrong values.
            wasInitialized = true;
          }

          return {
            ...transition,
            wasInitialized,
          };
        })
        .filter((transition) => {
          if (lodash.isError(transition.initialized)) {
            console.error(transition.initialized); // eslint-disable-line no-console
            return false;
          }

          return true;
        })
        .map((transition) => ({
          ...transition,
          times: getTransitionTimes(transition, entityTime),
        }));

      validTransitions
        .sort(({times: timesA}, {times: timesB}) => {
          // order transitions by start time ascending
          return timesA.start - timesB.start;
        })
        .forEach((transition, index) => {
          const {times, wasInitialized} = transition;

          const isActive = Boolean(times.start <= time && (times.end + OVERSHOOT_BUFFER) >= time);

          if (!isActive
            && !wasInitialized
            && extras.forceTransition !== true
          ) {
            return;
          }

          lodash.forEach(transition.details, (unused, propertyName) => {
            const typeName = propertyName.split('.')[0];
            activeTransitionTypes[typeName] = true;
          });

          const {tween} = transition;

          // The transition elapsed time is the current total time minus the time the transition started.
          const elapsedTime = timeInSeconds - (times.start / MS_TO_SECONDS);

          const previousTransition = validTransitions[index - 1];

          if (extras.forceTransition) {
            // only play transitions that have already started
            if (time >= times.start) {
              // pause tween at 0 to reset initial values of targets
              tween
                .pause(0)
                .seek(elapsedTime);
            } else if (!previousTransition) {
              // if no transition is set to run during given time & there is no previous transition,
              // we need to reset the target values to the beginning of the first transition
              tween.pause(0);
            }
          } else {
            // ensure minimum of 0 time is seeked.
            //
            // A bug happens when you navigate to before
            // a transition out that has a "position" property. When you drag the layer the transition
            // is run (because of wasInitialized) with a negative time. The transition system tries to reposition
            // the element in the editor WHILE the DisplayEntity component also tries to reposition. This causes
            // a discrepancy on how far the element should be dragged. By ensuring the seeked time is not negative,
            // only the DisplayEntity will drag the layer (see PC-385)
            tween.seek(Math.max(elapsedTime, 0));
          }
        });

      resetEntity(entity, activeTransitionTypes);
    });
  }

  /**
   * Resets an entity so it is no longer under the influence of any transformations.
   *
   * @param {ObservableMap} entity
   * @param {Object<string, boolean>} activeTypes
   */
  function resetEntity(entity, activeTypes) {
    const defaultPosition = entity.get('position').default;
    const defaultVisibility = entity.get('visible').default;
    const resolution = game.resolution;

    const resets = {
      opacity: defaultVisibility.opacity,
      y: getPixelFromPercentage(resolution.height, defaultPosition.y, true),
      x: getPixelFromPercentage(resolution.width, defaultPosition.x, true),
      transform: transformComponent({}).transform,
      mask: maskComponent({}).mask,
    };

    lodash.forEach(activeTypes || {}, (unused, transitionType) => {
      if (resets[transitionType]) {
        delete resets[transitionType];
      } else if (transitionType === 'position') {
        delete resets.x;
        delete resets.y;
      }
    });

    updateEntityForTransition(entity, resets);
  }

  /**
   * Updates the given entity with the differences given.
   *
   * @param {{}} entity
   * @param {{}} diffs
   */
  function updateEntityForTransition(entity, diffs) {
    runInAction('transitionSystemUpdateEntity', () => {
      const currentPosition = entity.get('position');
      const currentImage = entity.get('image');

      if (diffs.hasOwnProperty('opacity')) {
        const currentOpacity = entity.get('visible').opacity;
        if (currentOpacity !== diffs.opacity) {
          updateEntity(entity, 'visible', {opacity: diffs.opacity});
        }
      }
      if (diffs.hasOwnProperty('y')) {
        if (currentImage) {
          if (currentImage.y !== diffs.y) {
            updateEntity(entity, 'image', {y: diffs.y});
          }
        } else if (currentPosition.y !== diffs.y) {
          updateEntity(entity, 'position', {y: diffs.y});
        }
      }
      if (diffs.hasOwnProperty('x')) {
        if (currentImage) {
          if (currentImage.x !== diffs.x) {
            updateEntity(entity, 'image', {x: diffs.x});
          }
        } else if (currentPosition.x !== diffs.x) {
          updateEntity(entity, 'position', {x: diffs.x});
        }
      }
      updateTransformsAndMasks(entity, diffs);
    });
  }

  /**
   * Updates the given entity with the transform and mask differences given.
   *
   * @param {{}} entity
   * @param {{}} diffs
   */
  function updateTransformsAndMasks(entity, diffs) {
    runInAction('transitionSystemUpdateTransformsAndMasks', () => {
      if (diffs.hasOwnProperty('transform')) {
        if (!entity.has('transform')) {
          setEntityComponents(entity, {transform: diffs.transform});
        } else {
          updateEntity(entity, 'transform', diffs.transform);
        }
      }
      if (diffs.hasOwnProperty('mask')) {
        if (!entity.has('mask')) {
          setEntityComponents(entity, {mask: diffs.mask});
        } else {
          updateEntity(entity, 'mask', diffs.mask);
        }
      }
    });
  }

  return {
    name: TRANSITION_SYSTEM,
    update: systemUpdate
  };
}
