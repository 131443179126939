/* eslint-disable complexity */
import {action, observable, runInAction} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';
import _throttle from 'lodash/throttle';
import _isNumber from 'lodash/isNumber';
import _get from 'lodash/get';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

import inject from '../../hoc/injectHoc';
import CollectionSidebarList from './components/collectionSidebarList/CollectionSidebarList';
import ContentListActions from './components/contentListActions/ContentListActions';
import FolderSidebarList from './components/folderSidebarList/FolderSidebarList';
import SearchContentItems from './components/searchContentItems/SearchContentItems';
import ContentItems from '../../common/contentItems/ContentItems';
import SearchBar from '../../common/searchBar/SearchBar';
import CollectionExampleInfoModal from '../../modals/collectionExampleInfo/CollectionExampleInfoModal';
import ContentInfoModal from '../../modals/contentInfo/ContentInfoModal';
import PublishContentModal from '../../modals/publishContent/PublishContentModal';
import ContentUnPublishModal from '../../modals/contentUnPublish/ContentUnPublishModal';
import {CONTENT_LIST_COLLECTION, CONTENT_LIST_FOLDER, CONTENT_LIST_SEARCH} from '../../../constants/contentConstants';
import {DEMO_STORAGE_NAME} from '../../../constants/demoConstants';
import {SEARCH_QUERY_PARAM} from '../../../constants/searchConstants';
import {NEW_CONTENT_COLLECTION_ID} from '../../../constants/newContentConstants';
import {FREE_CONTENT_COLLECTION_ID} from '../../../constants/freeContentConstants';
import {dashboardListRoute, dashboardRoute, dashboardSearchRoute, prestoRoute} from '../../routePaths';

import './dashboardPage.scss';
import BannerCarousel from './components/bannerCarousel/BannerCarousel';
import LinksSidebarList from './components/linksSidebarList.js/LinksSidebarList';
import {SUPER_ADMIN_ROLE} from '../../../constants/userConstants';
import {SCREEN_SIZE_LARGE} from '../../../constants/bootstrapConstants';
import cookieHelper from '../../../utils/cookieHelper';

/**
 * The DashboardPage component.
 */
export class DashboardPage extends React.Component {
  /**
   * The type of content list (collection/folder).
   *
   * @type {string}
   */
  @observable contentListType = CONTENT_LIST_COLLECTION;

  /**
   * The selected content list item.
   *
   * @type {?{}}
   */
  @observable contentListItem = null;

  /**
   * The ID of content list.
   *
   * @type {?number}
   */
  @observable contentListId = null;

  /**
   * The number of contents that have been loaded.
   *
   * @type {number}
   */
  @observable contentCount = 0;

  /**
   * Whether or not to auto select either the first collection or the given collection id.
   *
   * @type {boolean|number}
   */
  @observable autoLoadCollection = false;

  /**
   * Whether or not to auto select either the first folder or the given folder id.
   *
   * @type {boolean|number}
   */
  @observable autoLoadFolder = false;

  /**
   * Whether or not the publish modal is open.
   *
   * @type {boolean}
   */
  @observable isPublishModalOpen = false;

  /**
   * Whether or not the un-publish modal is open.
   *
   * @type {boolean}
   */
  @observable isUnPublishModalOpen = false;

  /**
   * Whether or not the collection example modal is open.
   *
   * @type {boolean}
   */
   @observable isCollectionExampleModalOpen = false;

  /**
   * Whether the sidebar should be fixed (at mobile size)
   *
   * @type {boolean}
   */
  @observable isSidebarCollapsible = false;

  /**
   * Determine if sidebar should be fixed
   */
  checkWindowWidth = _throttle(() => {
    runInAction('setisSidebarCollapsible', () => {
      const windowWidth = window.innerWidth;
      this.isSidebarCollapsible = windowWidth < SCREEN_SIZE_LARGE;
    });
  // eslint-disable-next-line no-magic-numbers
  }, 500);

  /**
   * Refreshes authenticated user info
   */
  @action componentDidMount() {
    const {
      apiBannerGetAllStore,
      apiUserGetMeStore,
      navigateContentStore,
      routerStore
    } = this.props;

    apiBannerGetAllStore.refresh();
    apiUserGetMeStore.refresh();

    const listData = this.getListData();
    if (listData.listId && listData.listType === CONTENT_LIST_COLLECTION) {
      this.autoLoadCollection = listData.listId;
    } else if (listData.listId && listData.listType === CONTENT_LIST_FOLDER) {
      this.autoLoadFolder = listData.listId;
    } else {
      this.autoLoadCollection = true;
    }

    if (this.getSearchTerms()) {
      // Do not auto load the collection if this is the search page.
      this.autoLoadCollection = false;
    }

    const currentListData = this.getListData();
    navigateContentStore.setListType(currentListData.listType);
    navigateContentStore.setListId(currentListData.listId);
    navigateContentStore.setContentId(this.getContentId());

    const activeDemo = localStorage.getItem(DEMO_STORAGE_NAME);
    if (activeDemo) {
      try {
        const demoItems = JSON.parse(activeDemo);
        if (!demoItems || !demoItems.id) {
          return;
        }

        routerStore.replace(prestoRoute, {params: {
          contentId: demoItems.id
        }});
      } catch (parseError) {
        // Ignore the demo if the data is corrupted.
      }
    }

    // check window size immediately
    this.checkWindowWidth();
    window.addEventListener('resize', this.checkWindowWidth);
  }

  /**
   * Clean up window events to avoid memory leaks
   */
  @action componentWillUnmount() {
    const {apiUserPollMeStore} = this.props;

    window.removeEventListener('resize', this.checkWindowWidth);
    apiUserPollMeStore.clearAll();
  }

  /**
   * Gets the search terms from the render store query params.
   *
   * @returns {?string}
   */
  getSearchTerms = () => {
    const {routerStore} = this.props;

    return routerStore.getQuery(SEARCH_QUERY_PARAM, null, true);
  };

  /**
   * Gets the content id from the params.
   *
   * @returns {number}
   */
  getContentId = () => {
    const {routerStore} = this.props;
    const contentId = routerStore.getParam('contentId');

    return contentId === 'example' ? contentId : Number(routerStore.getParam('contentId'));
  };

  /**
   * Gets the list id and type from the params.
   *
   * @param {string=} defaultListType
   * @param {number=} defaultListId
   * @returns {{listId: number, listType: string}}
   */
  getListData = (defaultListType, defaultListId) => {
    const {routerStore} = this.props;
    const listId = routerStore.getParam('listId');
    const isCustomCollection = listId === NEW_CONTENT_COLLECTION_ID || listId === FREE_CONTENT_COLLECTION_ID;

    const safeListId = isCustomCollection
      ? listId
      : Number(listId);

    let listType = routerStore.getParam('listType');
    if (listType === 'undefined') {
      listType = undefined;
    }

    if (routerStore.location.pathname.indexOf('/dashboard/search') !== -1) {
      listType = CONTENT_LIST_SEARCH;
    }

    return {
      listId: safeListId || defaultListId,
      listType: listType || defaultListType,
    };
  };

  /**
   * Updates the route parameters.
   *
   * @param {number} [params]
   * @param {number} [params.contentId]
   * @param {number} [params.listId]
   * @param {string} [params.listType]
   * @param {boolean} ignoreSearch
   */
  redirectToSelf = (params, ignoreSearch) => {
    const {navigateContentStore, routerStore} = this.props;

    if (!routerStore.isSameAs(dashboardRoute)) {
      // Do not redirect if we are not still on the dashboard page.
      // This prevents infinite redirects on onboarding redirects.
      return;
    }

    const safeParams = params || {};

    const currentListData = this.getListData(CONTENT_LIST_COLLECTION, 0);
    const listId = safeParams.listId || currentListData.listId;
    const listType = safeParams.listType || currentListData.listType;
    const contentId = safeParams.contentId || '';

    navigateContentStore.setListType(listType);
    navigateContentStore.setListId(listId);
    navigateContentStore.setContentId(contentId);

    let selectedRoute = dashboardListRoute;

    const searchTerms = this.getSearchTerms();
    if (searchTerms && !ignoreSearch) {
      selectedRoute = {
        pathname: dashboardSearchRoute,
        search: `s=${searchTerms}`
      };
    }

    routerStore.push(selectedRoute, {
      params: {
        contentId,
        listId,
        listType,
      },
      ignoreTo: true,
    });
  };

  /**
   * Opens the content info modal.
   *
   * @param {string|number} contentId
   */
  onContentSelect = (contentId) => {
    this.redirectToSelf({contentId});
  };

  /**
   * Redirects the user to the dashboard.
   */
  onCloseInfoModal = () => {
    this.redirectToSelf();
  };

  /**
   * Clears the search term.
   */
  @action onSearchClear = () => {
    this.autoLoadCollection = true;

    this.redirectToSelf({}, true);
  };

  /**
   * Closes fixed side nav
   *
   * @param {*} e click event
   */
  @action onSideNavClose = (e) => {
    const {navigateContentStore} = this.props;

    // only close nav if main comtainer element is clicked
    // this is to prevent closing if the nav itself is clicked
    if (e.target === e.currentTarget) {
      navigateContentStore.closeDashboardSidebar();
    }
  };

  /**
   * Sets the content list type and id.
   *
   * @param {string} type
   * @param {{id: number}} item
   */
  @action setContentList = (type, item) => {
    const {navigateContentStore} = this.props;
    this.autoLoadCollection = false;
    this.autoLoadFolder = false;
    this.contentListItem = item;

    const newListId = (item) ? item.id : null;

    const {listId, listType} = this.getListData();
    if (listType === type && listId === newListId) {
      return;
    }

    this.redirectToSelf({
      contentId: this.getContentId(),
      listId: newListId,
      listType: type,
    }, true);

    // close side nav on selection
    navigateContentStore.closeDashboardSidebar();
  };

  /**
   * Opens the publish modal.
   */
  @action onPublishModalOpen = () => {
    this.isPublishModalOpen = true;
  };

  /**
   * Publishes the content.
   *
   * @param {{}} response
   */
  @action afterPublish = (response) => {
    this.isPublishModalOpen = false;
    this.autoLoadCollection = response.collectionId;

    this.redirectToSelf({
      contentId: response.contentId,
      listId: response.collectionId,
      listType: CONTENT_LIST_COLLECTION,
    }, true);
  };

  /**
   * Opens the un-publish modal.
   */
  @action onUnPublishModalOpen = () => {
    this.isUnPublishModalOpen = true;
  };

  /**
   * Opens the un-publish modal.
   */
  @action onCollectionExampleModalOpen = () => {
    this.isCollectionExampleModalOpen = true;
  };

  /**
   * Un-publishes the content.
   */
  @action afterUnPublish = () => {
    this.isUnPublishModalOpen = false;

    this.onCloseInfoModal();
  };

  /**
   * Updates the content state When the content items have loaded.
   *
   * @param {Array.<{}>} contentItems
   */
  @action onContentLoaded = (contentItems) => {
    this.contentCount = (contentItems) ? contentItems.length : 0;
  };

  /**
   * Resets the selected collection after the previously selected list was deleted.
   */
  @action afterListDeleted = () => {
    this.contentCount = 0;
    this.autoLoadCollection = true;

    this.setContentList(CONTENT_LIST_COLLECTION, null);
  };

  /**
   * Reloads the collection to show the new name
   *
   * @param {string} type
   * @param {string|number} contentId
   */
  @action afterListRenamed = (type, contentId) => {
    if (type === CONTENT_LIST_COLLECTION) {
      this.autoLoadCollection = contentId;
    } else if (type === CONTENT_LIST_FOLDER) {
      this.autoLoadFolder = contentId;
    }
  }

  /**
   * Reloads the collection to show the new collection example
   *
   * @param {string|number} collectionId
   */
  @action afterCollectionExample = (collectionId) => {
    const {apiCollectionGetOneStore} = this.props;

    apiCollectionGetOneStore.refresh(collectionId, true);
    apiCollectionGetOneStore.getPromise(collectionId).then((collection) => {
      this.setContentList(CONTENT_LIST_COLLECTION, collection);
    });
  }

  /**
   * Renders the content modals.
   *
   * @param {?number} contentId
   * @param {{}} loggedInUser
   * @param {boolean} canUnpublish
   * @param {string} listType
   * @returns {?{}}
   */
  renderModals = (contentId, loggedInUser, canUnpublish) => {
    if (!contentId) {
      return null;
    }
    const {listType, listId} = this.getListData();

    return (
      <>
        {(contentId === 'example') && (
          <CollectionExampleInfoModal
            isOpen={true}
            onClose={this.onCloseInfoModal}
            listId={listId}
          />
        )}

        {(_isNumber(contentId) && !this.isPublishModalOpen && !this.isUnPublishModalOpen) && (
          <ContentInfoModal
            isOpen={true}
            contentId={contentId}
            user={loggedInUser}
            contentListType={listType}
            onClose={this.onCloseInfoModal}
            onPublish={this.onPublishModalOpen}
            onRedirect={this.redirectToSelf}
            canUnPublish={canUnpublish}
            onUnPublish={this.onUnPublishModalOpen}
          />
        )}

        {(this.isPublishModalOpen) && (
          <PublishContentModal
            isOpen={true}
            contentId={contentId}
            onComplete={this.afterPublish}
            user={loggedInUser}
          />
        )}

        {(this.isUnPublishModalOpen && canUnpublish) && (
          <ContentUnPublishModal
            isOpen={true}
            contentId={contentId}
            collection={this.contentListItem}
            onRedirect={this.redirectToSelf}
            onComplete={this.afterUnPublish}
          />
        )}
      </>
    );
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {apiUserGetMeStore, navigateContentStore} = this.props;
    const {isDashboardSidebarOpen} = navigateContentStore;

    const safeContentId = this.getContentId();

    const loggedInUser = apiUserGetMeStore.getFulfilled();
    const isEmulating = !!cookieHelper.getEmulationAuthId();
    const isInMaintenanceMode = false;

    const {listId, listType} = this.getListData();

    const contentListName = (this.contentListItem) ? this.contentListItem.name : '';
    const collectionExample = (this.contentListItem && this.contentListItem.collectionExample) ? this.contentListItem.collectionExample : null;
    const isCollection = (listType === CONTENT_LIST_COLLECTION);
    const isFolder = (listType === CONTENT_LIST_FOLDER);
    const isSuperAdmin = loggedInUser && loggedInUser.role === SUPER_ADMIN_ROLE;
    const isCustomCollection = listId === NEW_CONTENT_COLLECTION_ID || listId === FREE_CONTENT_COLLECTION_ID;

    const canUnpublish = Boolean(isCollection && this.contentListItem) && !isCustomCollection;
    const canMultiSelectContents = isFolder || (isCollection && isSuperAdmin);

    const searchTerms = this.getSearchTerms();
    const globalSearchOnly = !isSuperAdmin;

    const isSidebarCollapsible = this.isSidebarCollapsible;
    const isSidebarOpen = !isSidebarCollapsible || isDashboardSidebarOpen;

    const showContentListActions = !isCustomCollection;
    const showFreeCollectionItem = _get(loggedInUser, 'company.signManufacturer.includeFreeTemplates', false) || isSuperAdmin;

    return (
      <div
        id="dashboard-page"
        className="system-page full-height d-flex"
        data-cy="dashboard-page"
      >
        {/* sidebar nav */}
        {(isSidebarOpen) && (
          <div
            className={classNames('dashboard-sidebar-open sidebar-fixed-container sidebar', {
              'has-message-bar': isEmulating || isInMaintenanceMode,
            })}
            onClick={this.onSideNavClose}
          >
            <div>
              <LinksSidebarList
                isSidebarCollapsible={isSidebarCollapsible}
              />
              <FolderSidebarList
                onSelect={(folder) => this.setContentList(CONTENT_LIST_FOLDER, folder)}
                selected={isFolder ? listId : null}
                autoLoad={this.autoLoadFolder}
                user={loggedInUser}
              />
              <CollectionSidebarList
                onSelect={(collection) => this.setContentList(CONTENT_LIST_COLLECTION, collection)}
                selected={isCollection ? listId : null}
                autoLoad={this.autoLoadCollection}
                user={loggedInUser}
                showNewAdditionsItem={true}
                showFreeCollectionItem={showFreeCollectionItem}
              />
            </div>
          </div>
        )}

        <div
          className={classNames('flex-fill dashboard-content',
            {'sidebar-margin' : !isSidebarCollapsible,
              'sidebar-focus': isSidebarCollapsible && isDashboardSidebarOpen}
          )}
        >

          <SearchBar globalOnly={globalSearchOnly} hideSubmit={true} theme="clear" />

          {(!searchTerms) && (
            <div>
              <BannerCarousel />

              {(listId) ? (
                <div className="dashboard-header">
                  <h3 className="px-4 font-weight-light">
                    {contentListName}
                  </h3>
                  {(showContentListActions) && (
                    <ContentListActions
                      contentListId={listId}
                      contentListName={contentListName}
                      contentListType={listType}
                      collectionExample={collectionExample}
                      isCollection={isCollection}
                      onDelete={this.afterListDeleted}
                      onRename={() => this.afterListRenamed(listType, listId)}
                      onCollectionExample={() => this.afterCollectionExample(listId)}
                      user={loggedInUser}
                    />
                  )}
                </div>
              ) : (
                <h3 className="px-4 font-weight-light choose-folder-header">
                  Select a Folder or Collection
                </h3>
              )}

              {(listId) && (
                <ContentItems
                  onContentSelect={this.onContentSelect}
                  contentListId={listId}
                  contentListType={listType}
                  onContentLoaded={this.onContentLoaded}
                  multiSelectAllowed={canMultiSelectContents}
                  user={loggedInUser}
                  collectionExample={collectionExample}
                />
              )}
            </div>
          )}

          {(searchTerms) && (
            <div>
              <h3 className="px-4 font-weight-light search-header">
                Searching for &quot;{searchTerms}&quot;
                <a className="cancel-search-anchor" onClick={this.onSearchClear}>
                  (<FontAwesomeIcon icon={faTimes} />)
                </a>
              </h3>

              <SearchContentItems
                onContentLoaded={this.onContentLoaded}
                onContentSelect={this.onContentSelect}
                globalOnly={globalSearchOnly}
                searchTerm={searchTerms}
                user={loggedInUser}
              />
            </div>
          )}

        </div>

        {this.renderModals(safeContentId, loggedInUser, canUnpublish)}
      </div>
    );
  }
}

DashboardPage.propTypes = {
  apiBannerGetAllStore: MobxPropTypes.observableObject,
  apiCollectionGetOneStore: MobxPropTypes.observableObject,
  apiUserGetMeStore: MobxPropTypes.observableObject,
  apiUserPollMeStore: MobxPropTypes.observableObject,
  navigateContentStore: MobxPropTypes.observableObject,
  routerStore: MobxPropTypes.observableObject,
};

DashboardPage.wrappedComponent = {};
DashboardPage.wrappedComponent.propTypes = {
  apiBannerGetAllStore: MobxPropTypes.observableObject.isRequired,
  apiCollectionGetOneStore: MobxPropTypes.observableObject.isRequired,
  apiUserGetMeStore: MobxPropTypes.observableObject.isRequired,
  apiUserPollMeStore: MobxPropTypes.observableObject.isRequired,
  navigateContentStore: MobxPropTypes.observableObject.isRequired,
  routerStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(DashboardPage)(
  observer(DashboardPage)
);
