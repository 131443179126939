/**
 * The crop component.
 *
 * @param {string} cropY
 * @param {string} cropX
 * @param {string} cropWidth
 * @param {string} cropHeight
 * @returns {{crop: {y: string, x: string, width: string, height: string}}}
 */
export function cropComponent(cropY, cropX, cropWidth, cropHeight) {
  const safeCropY = cropY || '0%';
  const safeCropX = cropX || '0%';
  const safeCropWidth = cropWidth || '100%';
  const safeCropHeight = cropHeight || '100%';

  return {
    crop: {
      y: parseFloat(safeCropY) + '%',
      yIsPercent: true,
      x: parseFloat(safeCropX) + '%',
      xIsPercent: true,
      width: parseFloat(safeCropWidth) + '%',
      widthIsPercent: true,
      height: parseFloat(safeCropHeight) + '%',
      heightIsPercent: true,
    },
  };
}

/**
 * Gets the crop component from the source item.
 *
 * @param {{setup: {crop: {}}}} item
 * @returns {{crop: {y: number, x: number, width: number, height: number}}}
 */
export function getCropFromSource(item) {
  const itemSetup = item.setup || {};
  if (!itemSetup.crop) {
    return {};
  }
  const itemCrop = itemSetup.crop;

  return cropComponent(
    itemCrop.y,
    itemCrop.x,
    itemCrop.width,
    itemCrop.height,
  );
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {ObservableMap} entity
 * @returns {{}}
 */
export function getCropForSource(entity) {
  if (!entity.has('crop')) {
    return {};
  }

  const crop = entity.get('crop');
  return {
    setup: {
      crop: {
        y: crop.y,
        x: crop.x,
        width: crop.width,
        height: crop.height,
      }
    },
  };
}
