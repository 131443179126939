import {action} from 'mobx';

import {EXPIRES_IN, EXPIRES_PENDING, EXPIRE_TIME} from '../../../constants/storeConstants';
import {STATE_FULFILLED, STATE_PENDING, STATE_REJECTED} from '../../../constants/asyncConstants';
import serverApi from '../../../utils/serverApi';
import ApiBaseStore from '../common/apiBaseStore';

import {NEW_CONTENT_COLLECTION_ID} from '../../../constants/newContentConstants';

/**
 * ApiCollectionGetNewContentCountStore
 */
class ApiCollectionGetNewContentCountStore extends ApiBaseStore {
  /**
   * Fetches collections from the server.
   */
  @action makeRequest() {
    this.state = STATE_PENDING;
    this[EXPIRE_TIME] = Date.now() + EXPIRES_PENDING;
    this.error = null;

    serverApi.collectionGetContentCount(NEW_CONTENT_COLLECTION_ID)
      .then(action('collectionGetNewContentCountSuccess', ({count}) => {
        this.state = STATE_FULFILLED;
        this[EXPIRE_TIME] = Date.now() + EXPIRES_IN;
        this.data = count;
        this.error = null;
      })).catch(action('collectionGetNewContentCountError', (collectionGetNewContentCountError) => {
        this.state = STATE_REJECTED;
        this[EXPIRE_TIME] = null;
        this.data = null;
        this.error = collectionGetNewContentCountError;
      }));
  }
}

export default new ApiCollectionGetNewContentCountStore();
