import React from 'react';
import {action} from 'mobx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretRight} from '@fortawesome/free-solid-svg-icons';

import mainConfig from '../../../../../config/main';
import {editorRoute, dashboardRoute} from '../../../../routePaths';
import CustomIcon from '../../../../common/customIcon/CustomIcon';
import Link from '../../../../common/link/Link';

import './linksSidebarList.scss';

/**
 * The project content ecosystem navigation component.
 */
class LinksSidebarList extends React.Component {
    /**
     * Loads user based on session
     */
    @action componentDidMount() {}

    /**
     * Renders the component.
     *
     * @returns {{}}
     */
    render() {
      return (
        <div className="header-sidebar-list sidebar-list">
          <div>
            <Link
              to={dashboardRoute}
              className="sidebar-list-item d-flex justify-content-between align-items-center"
            >
              <div>
                <CustomIcon
                  type="canvas"
                  className="mr-2"
                />
                Templates
              </div>
              <FontAwesomeIcon
                className="sidebar-list-item-append"
                icon={faCaretRight}
              />
            </Link>
            <Link
              to={editorRoute}
              params={{contentId: 'new'}}
              className="sidebar-list-item d-flex justify-content-between align-items-center"
            >
              <div>
                <CustomIcon
                  type="design-wand"
                  className="mr-3"
                />
                Canvas
              </div>
              <FontAwesomeIcon
                className="sidebar-list-item-append"
                icon={faCaretRight}
              />
            </Link>

            <a
              href={mainConfig.navBar.scheduleSite}
              className="sidebar-list-item d-flex justify-content-between align-items-center"
            >
              <div>
                <CustomIcon
                  type="schedule"
                  className="mr-3"
                />
                Scheduler
              </div>
              <FontAwesomeIcon
                className="sidebar-list-item-append"
                icon={faCaretRight}
              />
            </a>
          </div>
        </div>);
    }
}

export default LinksSidebarList;
