/**
 * The action update component.
 *
 * @param {Object.<string, {}>} updatedComponents
 * @returns {{actionUpdate: {updatedComponents: Object.<string, {}>}}}
 */
export function actionUpdateComponent(updatedComponents) {
  return {
    actionUpdate: {
      updatedComponents,
    },
  };
}
