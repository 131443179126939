import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import entityMoveHOC from '../../hoc/entityMoveHoc';

/**
 * The MovablePath component.
 */
class MovablePath extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {path, strokeColor, strokeWidth} = this.props;

    return (
      <path
        className="movable-path"
        stroke={strokeColor}
        fill="none"
        strokeOpacity="1"
        strokeWidth={strokeWidth}
        strokeLinecap="square"
        strokeLinejoin="miter"
        strokeMiterlimit="10"
        pointerEvents="all"
        d={path}
      />
    );
  }
}

MovablePath.propTypes = {
  game: MobxPropTypes.observableObject.isRequired,
  path: PropTypes.string.isRequired,
  strokeColor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  strokeWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

  entities: PropTypes.arrayOf(MobxPropTypes.observableMap),
  entity: MobxPropTypes.observableMap,
};

export const StationaryPath = observer(MovablePath);

export default entityMoveHOC(StationaryPath);
