import classNames from 'classnames';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import LineItem from './components/LineItem';
import DisplayLineHandle from './components/lineHandle/DisplayLineHandle';
import {ALIGNMENTS} from '../../../display/components/common/positionComponent';

/**
 * The radius of the handle circles.
 * @const {number}
 */
const HANDLE_RADIUS = 10;

/**
 * The DisplayLine component.
 */
export class DisplayLine extends React.Component {
  /**
   * Gets the entity position in terms of left and top.
   *
   * @param {{alignment: {x: string, y: string}, x: number, y: number}} position
   * @param {{height: number, width: number}} size
   * @param {{resolution: {height: number, width: number}}} game
   * @returns {{left: number, top: number}}
   */
  getPositionLeftAndTop = (position, size, game) => {
    if (!position) {
      return {top: undefined, left: undefined};
    } else if (!position.alignment) {
      return {
        top: position.y,
        left: position.x,
      };
    }

    const alignment = position.alignment;
    const resolution = game.resolution;
    const style = {
      top: undefined,
      left: undefined,
    };

    if (position.y !== undefined) {
      if (alignment.y === ALIGNMENTS.y.bottom) {
        style.top = (resolution.height - size.height - position.y);
      } else if (alignment.y === ALIGNMENTS.y.middle) {
        style.top = (resolution.height / 2) + position.y;
      } else {
        style.top = position.y;
      }
    }

    if (position.x !== undefined) {
      if (alignment.x === ALIGNMENTS.x.right) {
        style.left = (resolution.width - size.width - position.x);
      } else if (alignment.x === ALIGNMENTS.x.center) {
        style.left = (game.resolution.width / 2) + position.x;
      } else {
        style.left = position.x;
      }
    }

    return style;
  };

  /**
   * Gets the aligned coordinates.
   *
   * @returns {{startPoint: {}, endPoint: {}}}
   */
  getAlignedCoordinates = () => {
    const {entity, game} = this.props;

    const line = entity.get('line');
    const {startPoint, endPoint} = line;

    const alignment = entity.get('position').alignment;

    const size = {
      height: Math.abs(endPoint.y - startPoint.y),
      width: Math.abs(endPoint.x - startPoint.x),
    };

    const alignedStart = this.getPositionLeftAndTop(
      {...startPoint, alignment},
      size,
      game
    );
    const alignedEnd = this.getPositionLeftAndTop(
      {...endPoint, alignment},
      size,
      game
    );

    const alignedStartPoint = {x: alignedStart.left || startPoint.x, y: alignedStart.top || startPoint.y};
    const alignedEndPoint = {x: alignedEnd.left || endPoint.x, y: alignedEnd.top || endPoint.y};

    return {
      startPoint: alignedStartPoint,
      endPoint: alignedEndPoint,
    };
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, game, style, topStyle, className, onEntityClick} = this.props;

    // Only show the handles if the entity is active.
    const interaction = entity.get('interaction');
    const showHandles = Boolean(interaction && interaction.isActive);

    const entityId = entity.get('id');
    const line = entity.get('line');
    const {thickness} = line;
    const lineStyle = line.style;

    const gameResolution = game.resolution;

    const {startPoint, endPoint} = this.getAlignedCoordinates();

    const middlePoint = {
      x: ((endPoint.x - startPoint.x) / 2) + startPoint.x,
      y: ((endPoint.y - startPoint.y) / 2) + startPoint.y,
    };

    const newStyle = Object.assign({}, style, {
      zIndex: topStyle.zIndex,
      visibility: topStyle.visibility,
      mixBlendMode: topStyle.mixBlendMode,
      width: gameResolution.width,
      height: gameResolution.height,
      top: 0,
      left: 0,
      opacity: (lineStyle) ? lineStyle.opacity : 1,
      transformOrigin: `${middlePoint.x}px ${middlePoint.y}px`,
    });

    return (
      <svg
        id={entityId}
        className={classNames('display-line', className)}
        style={newStyle}
        viewBox={`0 0 ${gameResolution.width} ${gameResolution.height}`}
        pointerEvents="none"
      >
        <g pointerEvents="visible">
          <LineItem
            entity={entity}
            game={game}
            startPoint={startPoint}
            endPoint={endPoint}
            onEntityClick={onEntityClick}
          />

          <DisplayLineHandle
            x={startPoint.x}
            y={startPoint.y}
            game={game}
            isDrag={false}
            isStart={true}
            radius={thickness + HANDLE_RADIUS}
            show={showHandles}
            entity={entity}
          />
          <DisplayLineHandle
            x={middlePoint.x}
            y={middlePoint.y}
            game={game}
            isDrag={true}
            isStart={false}
            radius={thickness + HANDLE_RADIUS}
            show={showHandles}
            entity={entity}
          />
          <DisplayLineHandle
            x={endPoint.x}
            y={endPoint.y}
            game={game}
            isDrag={false}
            isStart={false}
            radius={thickness + HANDLE_RADIUS}
            show={showHandles}
            entity={entity}
          />
        </g>
      </svg>
    );
  }
}

DisplayLine.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  topStyle: PropTypes.object.isRequired,

  className: PropTypes.string,
  onEntityClick: PropTypes.func,
};

export default observer(DisplayLine);
