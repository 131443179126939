import lodash from 'lodash';
import {runInAction} from 'mobx';

import {setEntityComponents, updateEntity} from '../ecs/entityHelper';
import {interactionComponent} from '../components/common/interactionComponent';

/**
 * The name of the system.
 * @const {string}
 */
export const INTERACTION_SYSTEM = 'interactionSystem';

/**
 * Gets a new instance of the interaction system.
 *
 * @param {GameStore} game
 * @returns {{name: string, runActions: systemRunActions}}
 */
export function interactionSystem(game) {
  /**
   * Called right before the game loop updates.
   *
   * @param {Array.<{}>} actions
   * @param {Array.<{}>} entities
   */
  function systemRunActions(actions, entities) {
    runInAction('interactionSystemUpdateEntity', () => {
      actions.forEach((actionEntity) => {
        if (!actionEntity.has('actionInteraction')) {
          return;
        }
        const action = actionEntity.get('action');
        const activeEntityId = action.entityId;
        const actionInteraction = actionEntity.get('actionInteraction');
        const makeActive = actionInteraction.isActive;
        const multi = actionInteraction.multi;

        const activeEntity = game.getEntity(activeEntityId);

        let currentActiveCount = 0;
        entities.forEach((entity) => {
          if (entity.has('interaction') && entity.get('interaction').isActive) {
            currentActiveCount += 1;
          }
        });
        const areMultiActive = (currentActiveCount > 1);

        const groupId = (activeEntity.has('group')) ? activeEntity.get('group').id : null;

        entities.forEach((entity) => {
          const group = entity.get('group');
          const isInGroup = (group && group.id === groupId);

          const isActionEntity = isInGroup || Boolean(entity.get('id') === activeEntityId);
          const entityInteraction = entity.get('interaction');

          if (!isActionEntity) {
            if (!entityInteraction || !entityInteraction.isActive) {
              return;
            } else if (!multi && areMultiActive) {
              updateEntity(entity, 'interaction', null);
              return;
            } else if (makeActive && multi) {
              return;
            } else if (!makeActive && areMultiActive) {
              return;
            }

            updateEntity(entity, 'interaction', null);
            return;
          }

          if (!multi && areMultiActive) {
            setEntityComponents(entity, interactionComponent(true));
          } else if (makeActive) {
            setEntityComponents(entity, interactionComponent(true));
          } else {
            updateEntity(entity, 'interaction', null);
          }
        });
      });
    });
  }

  /**
   * Called when the game loop updates.
   *
   * @param {Array.<{}>} entities
   */
  function systemUpdate(entities) {
    let activeCount = 0;
    lodash.forEach(entities, (entity) => {
      if (!entity.has('interaction')) {
        return;
      }
      if (entity.get('interaction').isActive) {
        activeCount += 1;
      }
    });

    if (activeCount <= 1) {
      return;
    }

    entities.forEach((entity) => {
      // First check for required components.
      if (!entity.has('interaction')) {
        return;
      }

      const interaction = entity.get('interaction');
      if (!interaction.isCropping) {
        return;
      }

      // Now update the entity.
      runInAction('interactionSystemUpdateEntity', () => {
        // Turn off cropping on all items if multiple are active.
        updateEntity(entity, 'interaction', {isCropping: false});
      });
    });
  }

  return {
    name: INTERACTION_SYSTEM,
    runActions: systemRunActions,
    update: systemUpdate
  };
}
