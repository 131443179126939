import lodash from 'lodash';

import {STATE_STOPPED} from '../../../constants/displayItemConstants';

/**
 * The video component.
 *
 * @param {number} fileId
 * @param {string} url
 * @param {number} duration
 * @param {string} type
 * @param {boolean=} loop
 * @param {string=} state
 * @param {number=} time
 * @returns {{video: {fileId: number, url: string, type: ?string, time: number, state: string, loop: boolean}}}
 */
export function videoComponent(fileId, url, duration, type, loop, state, time) {
  let safeFileId = (!fileId || fileId === 'null') ? null : parseInt(fileId, 10);
  let safeUrl = (!url || url === 'null') ? null : String(url);
  let safeType = type || '';

  return {
    video: {
      fileId: safeFileId,
      url: safeUrl,
      duration: parseInt(duration, 10),
      type: safeType,
      time: time || 0,
      state: state || STATE_STOPPED,
      loop: Boolean(loop)
    },
  };
}

/**
 * Gets the video component from the fileId item.
 *
 * @param {{video: {fileId: number, duration: number, type: ?string, loop: ?boolean}}} item
 * @param {{}=} variables
 * @returns {{video: {fileId: number, duration: number, type: ?string, time: number, state: string, loop: boolean}}}
 */
export function getVideoFromSource(item, variables) {
  if (!item.video) {
    return {};
  }

  const video = item.video;

  let {fileId, url, duration, type} = video;

  const canEdit = lodash.get(item, 'compose.canEdit');
  const variableName = lodash.get(item, 'compose.variableName');
  if (canEdit && variableName) {
    const variableValue = lodash.get(variables, `video.${variableName}`, '');

    if (variableValue && lodash.isString(variableValue)) {
      // The old deprecated variable style is a string with | delimiters.
      const subCount = 4;
      [fileId, duration, type, url] = lodash.split(variableValue, '|', subCount);
    } else if (variableValue) {
      // The ()s fix an eslint parsing error.
      ({fileId, duration, type, url} = variableValue);
    }
  }

  return videoComponent(
    fileId,
    url,
    duration,
    type || null,
    video.loop || false
  );
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {{}} entity
 * @returns {{}}
 */
export function getVideoForSource(entity) {
  if (!entity.has('video')) {
    return {};
  }

  const compose = entity.get('compose');
  const video = entity.get('video');

  const outVideo = {
    loop: video.loop,
  };

  const canEdit = lodash.get(compose, 'canEdit', false);
  const variableName = lodash.get(compose, 'variableName');
  if (!canEdit || !variableName) {
    outVideo.fileId = video.fileId;
    outVideo.url = video.url;
    outVideo.duration = video.duration;
    outVideo.type = video.type;
  }

  return {
    video: outVideo,
  };
}
