import {inject} from 'mobx-react';

/**
 * A higher order component wrapper that wraps the mobX inject higher order component.
 *
 * @param {string[]|string|Object.<string, *>} injectNames
 * @returns {function}
 */
export default function injectWrapper(injectNames) {
  let safeInjectNames = [];
  if (Array.isArray(injectNames)) {
    safeInjectNames = injectNames;
  } else if (typeof injectNames === 'string') {
    safeInjectNames = arguments;
  } else if (injectNames.wrappedComponent && injectNames.wrappedComponent.propTypes) {
    const safeKeys = Object.keys(injectNames.wrappedComponent.propTypes || {});
    safeInjectNames = safeKeys.filter((keyName) => {
      return (keyName.indexOf('Store') !== -1);
    });
  } else {
    safeInjectNames = Object.keys(injectNames);
  }

  /**
   * The wrapper for the mobX inject hoc.
   *
   * @param {Object} WrappedComponent
   * @returns {Object}
   */
  return function injectHoc(WrappedComponent) {
    return inject.apply(null, safeInjectNames)(WrappedComponent);
  };
}
