/**
 * The action position and size change component.
 *
 * @param {boolean} isActive
 * @param {boolean} multi
 * @param {boolean} isCropping
 * @returns {{actionInteraction: {isActive: boolean, multi: boolean}}}
 */
export function actionInteractionComponent(isActive, multi, isCropping) {
  return {
    actionInteraction: {
      isActive: Boolean(isActive),
      multi: Boolean(multi),
      isCropping: Boolean(isCropping),
    },
  };
}
