import lodash from 'lodash';
import {action, observable, toJS, runInAction} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import JSONInput from 'react-json-editor-ajrm/index';
import locale from 'react-json-editor-ajrm/locale/en';
import ReactSelect from 'react-select';

import ConfirmModal from '../../../../../modals/confirm/ConfirmModal';
import {validateTransitionValues} from '../../../../../../display/ecs/transitionHelper';
import presets from '../../../../../../display/transitionPresets/index';
import {SUPER_ADMIN_ROLE} from '../../../../../../constants/userConstants';
import inject from '../../../../../hoc/injectHoc';

import './transitionEdit.scss';

/**
 * The confirm dialog type for clearing the preset JSON.
 * @const {string}
 */
const CONFIRM_CLEAR_PRESET = 'clearPreset';

/**
 * The TransitionEdit component.
 */
export class TransitionEdit extends React.Component {
  /**
   * The active preset that was last selected.
   *
   * @type {?string}
   */
  @observable activePreset = null;

  /**
   * The transition content object.
   *
   * @type {?string}
   */
  @observable content = {};

  /**
   * Whether or not the content has been changes since it was set.
   *
   * @type {boolean}
   */
  @observable hasChanges = false;

  /**
   * Whether or not the content has an active error.
   *
   * @type {boolean|Error}
   */
  @observable hasError = false;

  /**
   * An error that occurred while validating the JSON.
   *
   * @type {?Error}
   */
  @observable validationError = null;

  /**
   * The confirm dialog message.
   * Setting this to a string will immediately open the confirm dialog.
   *
   * @type {?string}
   */
  @observable confirmMessage = null;

  /**
   * Whether or not the user has permission to view this page.
   *
   * @type {?boolean}
   */
  @observable hasPermission = null;

  /**
   * The confirm dialog type.
   * Establishes what the confirm dialog was confirming.
   *
   * @type {?string}
   */
  confirmType = null;

  /**
   * Triggered when the component is first added to the page.
   */
  componentDidMount() {
    const {activeTransition, apiUserGetMeStore} = this.props;

    apiUserGetMeStore.refresh();
    apiUserGetMeStore.getPromise().then((user) => {
      runInAction('transitionEditLoaded', () => {
        this.hasPermission = user.role === SUPER_ADMIN_ROLE;
      });
    });

    if (activeTransition) {
      this.setContent(activeTransition.details, false);
    }
  }

  /**
   * Triggered when the component has just updated with new props.
   *
   * @param {{}} prevProps
   */
  componentDidUpdate(prevProps) {
    const {activeTransition, isUnsavedModalOpen} = this.props;

    const idHasChanged = (
      !activeTransition || !prevProps.activeTransition
        || activeTransition.id !== prevProps.activeTransition.id
    );

    if (idHasChanged && !isUnsavedModalOpen) {
      this.clearPreset();
    }
  }

  /**
   * Sets the content object.
   *
   * @param {{}} newContent
   * @param {boolean} isChange
   */
  @action setContent = (newContent, isChange) => {
    this.content = newContent;
    this.hasChanges = Boolean(isChange);

    if (!isChange) {
      this.hasError = false;
      this.validationError = null;
    }
  };

  /**
   * Triggered when a preset is selected from the dropdown.
   *
   * @param {{}} selected
   */
  @action onActivatePreset = (selected) => {
    if (!selected) {
      if (this.hasChanges) {
        this.confirmType = CONFIRM_CLEAR_PRESET;
        this.confirmMessage = 'All current edits to the data to be discarded.'
          + ' Are you sure you want restore the original transition data?';
      } else {
        this.clearPreset();
      }

      return;
    }

    if (this.hasChanges) {
      this.confirmType = selected.value;
      this.confirmMessage = 'All current edits to the data to be discarded.'
        + ` Are you sure you want to load the ${selected.value} preset?`;
      return;
    }

    this.setPreset(selected.value);
  };

  /**
   * Clears the existing data and sets the content to the preset's details.
   * Note: This should be done AFTER a confirmation if the content was changed.
   *
   * @param {string} presetName
   */
  @action setPreset = (presetName) => {
    this.activePreset = presetName;

    const presetData = presets[presetName];

    let presetContent = presetData.details;
    if (lodash.has(presetData, 'template.options')) {
      presetContent = presetData.template.options;

      // Clean up the template options.
      lodash.forEach(presetContent, (propertyDetails, propertyName) => {
        if (propertyDetails.parse !== undefined) {
          lodash.unset(presetContent, `${propertyName}.parse`);
        }
      });
    } else if (presetData.template) {
      presetContent = {preset: presetData.template.preset};
    }

    if (presetData && presetData.flow) {
      presetContent = {...presetContent, flow: presetData.flow};
    }

    this.setContent(presetContent, false);
    this.hasChanges = true;
  };

  /**
   * Clears the preset data and sets the content back to the active transition's details.
   * Note: This should be done AFTER a confirmation if the content was changed.
   */
  @action clearPreset = () => {
    const {activeTransition, blankTemplate} = this.props;

    this.activePreset = null;
    if (activeTransition) {
      this.setContent(activeTransition.details, false);
    } else {
      this.setContent(blankTemplate || {}, false);
    }
  };

  /**
   * Triggered when the content is updated in the editor window.
   *
   * @param {{}} newData
   */
  @action onContentUpdated = (newData) => {
    const newContent = newData.jsObject;

    const newJSON = newData.json;
    const currentJSON = JSON.stringify(this.content);
    if (currentJSON === newJSON) {
      return;
    }

    if (!newData.error) {
      try {
        validateTransitionValues(newContent);
        this.validationError = null;
      } catch (validationError) {
        this.validationError = validationError;
      }
    } else {
      this.validationError = null;
    }

    this.setContent(newContent, true);
    this.hasError = Boolean(newData.error) || this.validationError;
    this.hasChanges = true;
  };

  /**
   * Triggered when the confirm dialog is approved or denied.
   *
   * @param {boolean} isYes
   */
  @action onConfirm = (isYes) => {
    const confirmType = this.confirmType;

    this.confirmMessage = null;
    this.confirmType = null;

    if (!isYes) {
      return;
    }

    if (confirmType === CONFIRM_CLEAR_PRESET) {
      this.clearPreset();
      return;
    }

    // The confirmType is the presetName.
    this.setPreset(confirmType);
  };

  /**
   * Saves the content back to the transition.
   */
  @action onSaveClick = () => {
    if (this.hasError) {
      return;
    }

    let presetName = null;
    if (this.activePreset) {
      presetName = presets[this.activePreset].humanName;
    }

    const {activeTransition, onSave} = this.props;
    onSave(activeTransition, toJS(this.content), presetName);
  };

  /**
   * Determines if the content has anything new vs the entity.
   *
   * @returns {boolean}
   */
  isContentNew = () => {
    const {activeTransition} = this.props;
    if (!activeTransition) {
      return true;
    }

    const entityJSON = JSON.stringify(activeTransition.details);
    const contentJSON = JSON.stringify(this.content);

    return (entityJSON !== contentJSON);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {activeTransition, entity} = this.props;

    if (!this.hasPermission) {
      return null;
    }

    if (!activeTransition) {
      return (<div className="transition-edit" />);
    }

    const isNew = this.isContentNew();

    const transitionName = activeTransition.name || activeTransition.id;

    const elementName = entity.get('element');
    const presetOptions = lodash.reduce(presets, (allowed, preset, presetKey) => {
      if (preset.elements && Array.isArray(preset.elements)) {
        if (!lodash.includes(preset.elements, elementName)) {
          return allowed;
        }
      }

      allowed.push({
        value: presetKey,
        label: preset.humanName,
      });
      return allowed;
    }, []);

    return (
      <div className="transition-edit">
        <div className="justify-content-center">
          <h6 className="h4">{transitionName}</h6>
        </div>

        <div className="content">
          <ReactSelect
            id="transition-preset-select"
            className="site-select theme-input-white"
            classNamePrefix="react-select"
            isClearable={true}
            isMulti={false}
            placeholder="Load Preset"
            value={presetOptions.find((option) => option.value === this.activePreset)}
            onChange={this.onActivatePreset}
            options={presetOptions}
          />
        </div>

        <div className="content">
          <JSONInput
            id="transition-json-editor"
            placeholder={this.content}
            locale={locale}
            onChange={this.onContentUpdated}
            onKeyPressUpdate={false}
            height="100%"
            width="100%"
          />
        </div>

        {(this.validationError) && (
          <div className="row">
            <div className="col">
              <div className="alert alert-warning" role="alert">
                {this.validationError.message}
              </div>
            </div>
          </div>
        )}

        <div className="content">
          <button
            type="button"
            className="btn btn-primary btn-sm btn-block save-button"
            onClick={this.onSaveClick}
            disabled={this.hasError || !isNew}
          >Save</button>
        </div>

        {(this.confirmMessage) && (
          <ConfirmModal
            isOpen={true}
            confirmText={this.confirmMessage}
            onComplete={this.onConfirm}
            isYesNo={true}
          />
        )}
      </div>
    );
  }
}

TransitionEdit.propTypes = {
  entity: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,

  activeTransition: PropTypes.object,
  apiUserGetMeStore: MobxPropTypes.observableObject,
  blankTemplate: PropTypes.object,
  isUnsavedModalOpen: PropTypes.bool,
};

TransitionEdit.wrappedComponent = {
  propTypes: {
    apiUserGetMeStore: MobxPropTypes.observableObject.isRequired,
  },
};

export default inject(TransitionEdit)(
  observer(TransitionEdit)
);
