import lodash from 'lodash';
import {action} from 'mobx';

import ApiMapBaseStore from '../common/apiMapBaseStore';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';

/**
 * Fetches all files in a folder from the api.
 */
class ApiFileGetFolderFilesStore extends ApiMapBaseStore {
  /**
   * Gets the key from the folder id and page.
   *
   * @param {string} folderId
   * @param {string} page
   * @returns {string}
   */
  getKey(folderId, page) {
    const safeFolderId = String(folderId);
    const safePage = String(page);

    return `${safeFolderId}-${safePage}`;
  }

  /**
   * Ensures fresh content in the store.
   *
   * @param {string} folderId
   * @param {string} page
   * @param {boolean} force Whether or not to force the refresh
   */
  refresh(folderId, page, force) {
    const safeKey = this.getKey(folderId, page);

    if (force || !this.isItemAvailable(safeKey)) {
      this.clear(safeKey);

      this.makeRequest(folderId, page);
    }
  }

  /**
   * Fetches files in a folder from the server.
   *
   * @param {string} folderId
   * @param {string} page
   */
  @action makeRequest(folderId, page) {
    const safeFolderId = String(folderId);
    const safePage = String(page);

    const safeKey = this.getKey(folderId, page);

    this.dataMap.set(safeKey, {
      state: STATE_PENDING,
      [EXPIRE_TIME]: Date.now() + EXPIRES_PENDING,
      data: null,
      error: null,
    });

    serverApi.fileFolderGetFiles(
      safeFolderId,
      safePage
    ).then(action('fileGetFolderFilesSuccess', (foundFiles) => {
      const sortedFiles = lodash.reverse(
        lodash.sortBy(foundFiles || [], (file) => file.createdAt)
      );

      this.dataMap.set(safeKey, {
        state: STATE_FULFILLED,
        [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
        data: sortedFiles,
        error: null,
      });
    })).catch(action('fileGetFolderFilesError', (filesGetError) => {
      this.dataMap.set(safeKey, {
        state: STATE_REJECTED,
        [EXPIRE_TIME]: null,
        data: null,
        error: filesGetError,
      });
    }));
  }
}

export default new ApiFileGetFolderFilesStore();
