import {observable, action} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';

import {TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';

import inject from '../../hoc/injectHoc';
import SubscriptionPlanTable from '../subscriptionPlanTable/SubscriptionPlanTable';
import AlaCarteTable from './components/alaCarteTable/AlaCarteTable';

import './pricingTabs.scss';

const SUBSCRIPTION_TAB = 'subscription';
const CREDIT_TAB = 'credits';

/**
 * The PricingTabs component.
 */
export class PricingTabs extends React.Component {
  /**
   * Pricing view that is showing
   *
   * @type {boolean}
   */
  @observable activePricingTab = SUBSCRIPTION_TAB;

  /**
   * Triggered when the component is first mounted to the page.
   */
  @action componentDidMount() {
    const {tabSelected} = this.props;
    if (tabSelected) {
      this.selectActivePricingTab(tabSelected);
    }
  }

  /**
   * Selects the active pricing tab
   *
   * @param {string} pricingTabName
   */
  @action selectActivePricingTab(pricingTabName) {
    this.activePricingTab = pricingTabName;
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {user, onPlanSelect, selectPlanId, onPurchaseCredits, startingCredits, bannerSlot, canUpdatePlan, canUpdateAlaCarte} = this.props;

    return (
      <>
        <Nav tabs className="pricing-tabs">
          <NavItem>
            <NavLink
              className={classNames({active: this.activePricingTab === SUBSCRIPTION_TAB})}
              onClick={() => this.selectActivePricingTab(SUBSCRIPTION_TAB)}
            >
              Subscriptions <br />
              <span className="subtitle">Best Value</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({active: this.activePricingTab === CREDIT_TAB})}
              onClick={() => this.selectActivePricingTab(CREDIT_TAB)}
            >
              Credits A la Carte <br />
              <span className="subtitle">Pay as you Go</span>
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={this.activePricingTab} className="pt-3">
          <TabPane tabId={SUBSCRIPTION_TAB}>
            {(bannerSlot) && (bannerSlot)}
            <SubscriptionPlanTable
              onPlanSelect={onPlanSelect}
              selectPlanId={selectPlanId}
              user={user}
              disablePlanButtons={!canUpdatePlan}
            />
          </TabPane>
          <TabPane tabId={CREDIT_TAB}>
            <AlaCarteTable
              startingCredits={startingCredits}
              onPurchaseCredits={onPurchaseCredits}
              disablePlanButtons={!canUpdateAlaCarte}
            />
          </TabPane>
        </TabContent>
      </>
    );
  }
}

PricingTabs.propTypes = {
  onPlanSelect: PropTypes.func.isRequired,
  onPurchaseCredits: PropTypes.func.isRequired,
  bannerSlot: PropTypes.element,
  canUpdateAlaCarte: PropTypes.bool,
  canUpdatePlan: PropTypes.bool,
  selectPlanId: PropTypes.number,
  startingCredits: PropTypes.object,
  tabSelected: PropTypes.string,
  user: PropTypes.object,
};

PricingTabs.defaultProps = {
  canUpdateAlaCarte: true,
  canUpdatePlan: true,
};

PricingTabs.wrappedComponent = {};
PricingTabs.wrappedComponent.propTypes = {
};

export default inject(PricingTabs)(
  observer(PricingTabs)
);
