import React from 'react';
import lodash from 'lodash';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClock, faSun} from '@fortawesome/free-regular-svg-icons';
import {faTemperature3} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import {Badge, UncontrolledTooltip} from 'reactstrap';

import {REALTIME_CURRENT_TIME, REALTIME_CURRENT_WEATHER, REALTIME_FORECAST_TYPES} from '../../../../../constants/realtimeFeedConstants';

const REALTIME_TYPE_DATA_MAP = {
  [REALTIME_CURRENT_TIME]: {
    label: 'Time',
    tooltip: 'This content uses realtime time data that will be displayed over this file on your sign. A placeholder is shown in the preview.',
    icon: faClock,
  },
  [REALTIME_CURRENT_WEATHER]: {
    label: 'Temp',
    tooltip: 'This content uses realtime weather data that will be displayed over this file on your sign. A placeholder is shown in the preview.',
    icon: faTemperature3,
  },
  forecast: {
    label: 'Forecast',
    tooltip: 'This content uses realtime forecast data that will be displayed over this file on your sign. Placeholders are shown in the preview.',
    icon: faSun,
  },
};

/**
 * The ContentBadges component.
 */
export class ContentBadges extends React.Component {
  /**
   * Gets the list id and type from the params.
   *
   * @param {{}} content
   * @returns {boolean}
   */
  getContentBadges = (content) => {
    if (!content || !content.sources) {
      return false;
    }

    const allFeedContentEntities = lodash
      .flattenDeep(Object.values(content.sources).map((source) => source.entities))
      .filter((contentEntity) => contentEntity.type === 'feed');

    const uniqueRealtimeDataTypes = allFeedContentEntities.reduce((acc, curr) => {
      const realtimeType = curr.feed && curr.feed.type;

      if (REALTIME_TYPE_DATA_MAP[realtimeType]) {
        acc[realtimeType] = REALTIME_TYPE_DATA_MAP[realtimeType];
      } else if (REALTIME_FORECAST_TYPES.includes(realtimeType)) {
        acc.forecast = REALTIME_TYPE_DATA_MAP.forecast;
      }
      return acc;
    }, {});

    return Object.values(uniqueRealtimeDataTypes);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {content} = this.props;

    const contentBadges = this.getContentBadges(content);

    return (
      <div className="">
        {contentBadges.map((contentBadge) => (
          <React.Fragment key={contentBadge.label}>
            <Badge className="mr-2" color="info" id={`content-realtime-badge-${contentBadge.label}`}>
              <FontAwesomeIcon
                icon={contentBadge.icon}
                className="mr-2"
              />
              {contentBadge.label}
            </Badge>
            <UncontrolledTooltip
              placement="top"
              target={`content-realtime-badge-${contentBadge.label}`}
            >
              {contentBadge.tooltip}
            </UncontrolledTooltip>
          </React.Fragment>
        ))}
      </div>
    );
  }
}

ContentBadges.propTypes = {
  content: PropTypes.object.isRequired,
};

export default ContentBadges;
