import PropTypes from 'prop-types';
import React from 'react';

/**
 * Create a clip path for cropping a circle.
 *
 * @param {{}} props
 * @param {string} props.cropId
 * @param {{}} props.cropOptions
 * @returns {{}}
 */
export function CropCircle({cropId, cropOptions}) {
  const halfWidth = (cropOptions.width / 2);
  const halfHeight = (cropOptions.height / 2);

  const offsetX = cropOptions.left + halfWidth;
  const offsetY = cropOptions.top + halfHeight;
  const radius = cropOptions.radius || halfHeight;

  return (
    <clipPath id={cropId}>
      <circle cx={offsetX} cy={offsetY} r={radius} />
    </clipPath>
  );
}

CropCircle.propTypes = {
  cropId: PropTypes.string.isRequired,
  cropOptions: PropTypes.shape({
    height: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    top: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,

    radius: PropTypes.number,
  }).isRequired,
};

export default CropCircle;
