export const GENERATE_IMAGE_STYLES = [
  '3D',
  'Anime',
  'Cartoon',
  'Comic',
  'Cyberpunk',
  'Digital Art',
  'Gatsby',
  'Low-poly',
  'Painting',
  'Photo',
  'Pixel-art',
  'Vintage',
];

export const GENERATE_IMAGE_HOLIDAY_THEMES = [
  'April Fools Day',
  'Black Friday',
  'Christmas',
  'Cinco de Mayo',
  'Cyber Monday',
  'Earth Day',
  'Easter',
  'Father\'s Day',
  'Flag Day',
  'Good Friday',
  'Groundhog Day',
  'Halloween',
  'Independence Day',
  'Labor Day',
  'Mardi Gras',
  'Martin Luther King Day',
  'Memorial Day',
  'Mother\'s Day',
  'New Year',
  'Patriot Day 9/11',
  'Presidents Day',
  'St. Patrick\'s Day',
  'Thanksgiving',
  'Valentine\'s Day',
  'Veterans Day',
];

export const GENERATE_IMAGE_INDUSTRY_THEMES = [
  'Automotive',
  'Banking',
  'Casino',
  'Cleaning Services',
  'Construction',
  'Credit Unions',
  'Dining',
  'Education',
  'Entertainment',
  'Freight',
  'General',
  'Health',
  'Holidays',
  'Insurance',
  'Law',
  'Liquor Store',
  'Pawn',
  'Political',
  'Public Service',
  'Real Estate',
  'Retail',
  'Roofing',
  'Shipping',
  'Sports',
  'Storage',
  'Veterinary Clinics',
];

export const GENERATE_IMAGE_RELIGIOUS_THEMES = [
  'Buddhist',
  'Catholic',
  'Christianity',
  'Hinduism',
  'Islam',
  'Judaism',
];
