import classNames from 'classnames';
import lodash from 'lodash';
import {observable, runInAction, action} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLock, faChevronUp, faChevronDown} from '@fortawesome/free-solid-svg-icons';

import inject from '../../hoc/injectHoc';
import {SUPER_ADMIN_ROLE} from '../../../constants/userConstants';
import {actionUnitComponent, PERCENT, PIXEL} from '../../../display/components/action/actionUnitComponent';

import './editUnitsControls.scss';

/**
 * The EditUnitsControls component.
 */
export class EditUnitsControls extends React.Component {
  /**
   * Whether or not the user has permission to view this page.
   *
   * @type {?boolean}
   */
  @observable hasPermission = null;

  /**
   * Whether or not the units control group is open.
   *
   * @type {boolean}
   */
  @observable isControlGroupOpen = false;

  /**
   * Triggered when the component is added to the page.
   */
  componentDidMount() {
    const {apiUserGetMeStore} = this.props;

    apiUserGetMeStore.refresh();
    apiUserGetMeStore.getPromise().then((user) => {
      runInAction('editComposeControlsLoaded', () => {
        this.hasPermission = user.role === SUPER_ADMIN_ROLE;
      });
    });
  }

  /**
   * Sets the unit type for the entity's size.
   *
   * @param {boolean} isPixel If true, will set as pixels, otherwise will set as percentage.
   * @param {boolean} isWidth If true, will set as a width, otherwise will set as a height.
   * @returns {function}
   */
  onClickSizeUnit = (isPixel, isWidth) => {
    return (clickEvent) => {
      clickEvent.preventDefault();

      const {
        /** @type {ObservableMap} */ entity,
        /** @type {GameStore} */ game
      } = this.props;

      const actionParams = {
        entityId: entity.get('id'),
      };

      const sizeData = {};
      if (isWidth) {
        sizeData.width = (isPixel) ? PIXEL : PERCENT;
      } else {
        sizeData.height = (isPixel) ? PIXEL : PERCENT;
      }

      const hasAspectRatio = Boolean(entity.has('image') || entity.has('video'));
      if (hasAspectRatio && !isPixel) {
        if (isWidth) {
          sizeData.height = PIXEL;
        } else {
          sizeData.width = PIXEL;
        }
      }

      game.addAction(actionParams, actionUnitComponent(
        sizeData,
      ));
    };
  };

  /**
   * Sets the unit type for the entity's position.
   *
   * @param {boolean} isPixel If true, will set as pixels, otherwise will set as percentage.
   * @param {boolean} isX If true, will set as the x-axis unit, otherwise will set as the y-axis unit.
   * @returns {function}
   */
  onClickPositionUnit = (isPixel, isX) => {
    return (clickEvent) => {
      clickEvent.preventDefault();

      const {
        /** @type {ObservableMap} */ entity,
        /** @type {GameStore} */ game
      } = this.props;

      const actionParams = {
        entityId: entity.get('id'),
      };

      const positionData = {};
      if (isX) {
        positionData.x = (isPixel) ? PIXEL : PERCENT;
      } else {
        positionData.y = (isPixel) ? PIXEL : PERCENT;
      }

      game.addAction(actionParams, actionUnitComponent(
        null,
        positionData,
      ));
    };
  };

  @action onToggleControlGroup = () => {
    this.isControlGroupOpen = !this.isControlGroupOpen;
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  renderUnitsControls() {
    const {
      /** @type {ObservableMap} */ entity,
    } = this.props;

    if (!this.isControlGroupOpen) {
      return null;
    }

    let isSizeLocked = false;
    let isPositionLocked = false;
    if (entity.has('locked')) {
      const entityLock = entity.get('locked');
      if (lodash.includes(entityLock, 'position')) {
        // If the entity has position locked, then do not allow them to adjust anything.
        isPositionLocked = true;
      }
      if (lodash.includes(entityLock, 'size')) {
        // If the entity has size locked, then do not allow them to adjust anything.
        isSizeLocked = true;
      }
    }

    const position = entity.get('position');
    const size = entity.get('size');

    const heightUnit = (size.default.heightIsPercent) ? PERCENT : PIXEL;
    const widthUnit = (size.default.widthIsPercent) ? PERCENT : PIXEL;
    const xUnit = (position.default.xIsPercent) ? PERCENT : PIXEL;
    const yUnit = (position.default.yIsPercent) ? PERCENT : PIXEL;

    const renderSizeLocked = () => {
      return (<div className="can-not-edit">
        <span className="locked-text">
          <FontAwesomeIcon
            className="mr-2"
            icon={faLock}
          />
          <span>Size Locked</span>
        </span>
      </div>);
    };

    const renderSizeControl = () => {
      return (<div className="group-controls">
        <div className="row">
          <div className="col">
            <div className="form-group">
              <div><label>Width Unit</label></div>
              <div className="align-buttons form-buttons">
                <div className="btn-group">
                  <button
                    type="button"
                    className={classNames('btn btn-sm btn-light form-button', {active: widthUnit === PIXEL})}
                    onClick={this.onClickSizeUnit(true, true)}
                  >px</button>

                  <button
                    type="button"
                    className={classNames('btn btn-sm btn-light form-button', {active: widthUnit === PERCENT})}
                    onClick={this.onClickSizeUnit(false, true)}
                  >%</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <div><label>Height Unit</label></div>
              <div className="align-buttons form-buttons">
                <div className="btn-group">
                  <button
                    type="button"
                    className={classNames('btn btn-sm btn-light form-button', {active: heightUnit === PIXEL})}
                    onClick={this.onClickSizeUnit(true, false)}
                  >px</button>

                  <button
                    type="button"
                    className={classNames('btn btn-sm btn-light form-button', {active: heightUnit === PERCENT})}
                    onClick={this.onClickSizeUnit(false, false)}
                  >%</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>);
    };

    const renderPositionLocked = () => {
      return (
        <div className="can-not-edit">
          <span className="locked-text">
            <FontAwesomeIcon
              className="mr-2"
              icon={faLock}
            />
            <span>Position Locked</span>
          </span>
        </div>
      );
    };

    const renderPositionControl = () => {
      return (<div className="group-controls">
        <div className="row">
          <div className="col">
            <div className="form-group">
              <div><label>X-Axis Unit</label></div>
              <div className="align-buttons form-buttons">
                <div className="btn-group">
                  <button
                    type="button"
                    className={classNames('btn btn-sm btn-light form-button', {active: xUnit === PIXEL})}
                    onClick={this.onClickPositionUnit(true, true)}
                  >px</button>

                  <button
                    type="button"
                    className={classNames('btn btn-sm btn-light form-button', {active: xUnit === PERCENT})}
                    onClick={this.onClickPositionUnit(false, true)}
                  >%</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <div><label>Y-Axis Unit</label></div>
              <div className="align-buttons form-buttons">
                <div className="btn-group">
                  <button
                    type="button"
                    className={classNames('btn btn-sm btn-light form-button', {active: yUnit === PIXEL})}
                    onClick={this.onClickPositionUnit(true, false)}
                  >px</button>

                  <button
                    type="button"
                    className={classNames('btn btn-sm btn-light form-button', {active: yUnit === PERCENT})}
                    onClick={this.onClickPositionUnit(false, false)}
                  >%</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>);
    };

    return (
      <div>
        {(isSizeLocked) ? renderSizeLocked() : renderSizeControl()}
        {(isPositionLocked) ? renderPositionLocked() : renderPositionControl()}
      </div>
    );
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      /** @type {GameStore} */ game,
    } = this.props;

    if (game.composeMode || !this.hasPermission) {
      // Compose mode disables most editing abilities.
      return null;
    }
    return (
      <div className="edit-units-controls control-group">
        <div className="group-header">
          <span className="group-header-label">Units</span>
          <button
            type="button"
            className="btn"
            onClick={this.onToggleControlGroup}
          >
            <FontAwesomeIcon icon={this.isControlGroupOpen ? faChevronUp : faChevronDown} />
          </button>
        </div>
        {this.renderUnitsControls()}
      </div>
    );
  }
}

EditUnitsControls.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
  apiUserGetMeStore: MobxPropTypes.observableObject,
};

EditUnitsControls.wrappedComponent = {};
EditUnitsControls.wrappedComponent.propTypes = {
  apiUserGetMeStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(EditUnitsControls)(
  observer(EditUnitsControls)
);
