/* eslint-disable no-magic-numbers */
import interact from 'interactjs/src/index';
import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {findDOMNode} from 'react-dom';

import {between} from '../../../../utils/mathHelper';

const DRAG_EL_TRACK = 'track';
const DRAG_EL_TRACK_START_HANDLE = 'trackStartHandle';
const DRAG_EL_TRACK_END_HANDLE = 'trackEndHandle';
const DRAG_EL_TRANSITION_IN_HANDLE = 'transitionInHandle';
const DRAG_EL_TRANSITION_OUT_HANDLE = 'transitionOutHandle';

// map used to determine start & end values for each drag element.
// For example when the DRAG_EL_TRACK_START_HANDLE is dragged,
// we want to watch the "left" attribute of the DRAG_EL_TRACK
// element to determine how many pixels we have dragged
const DRAG_TRACKING_MAP = {
  [DRAG_EL_TRACK]: {
    attribute: 'left',
    element: DRAG_EL_TRACK,
    selector: '.timeline-slider-track',
  },
  [DRAG_EL_TRACK_START_HANDLE]: {
    attribute: 'left',
    element: DRAG_EL_TRACK,
    selector: '.timeline-slider-handle.start-handle',
  },
  [DRAG_EL_TRACK_END_HANDLE]: {
    attribute: 'width',
    element: DRAG_EL_TRACK,
    selector: '.timeline-slider-handle.end-handle',
  },
  [DRAG_EL_TRANSITION_IN_HANDLE]: {
    attribute: 'left',
    element: DRAG_EL_TRANSITION_IN_HANDLE,
    selector: '.timeline-transition-handle.in-handle',
  },
  [DRAG_EL_TRANSITION_OUT_HANDLE]: {
    attribute: 'right',
    element: DRAG_EL_TRANSITION_OUT_HANDLE,
    selector: '.timeline-transition-handle.out-handle',
  },
};

/**
 * Ensure a value returns as a parsed float or the default value
 *
 * @param {number} value
 * @param {number} defaultValue
 * @returns {number}
 */
const sanitizeFloat = (value, defaultValue = 0) => {
  return Number.isNaN(parseFloat(value))
    ? defaultValue
    : parseFloat(value);
};

/**
 * Calculate and update track start handler
 *
 * @param {HTMLElement} trackEl
 * @param {{
 *  dx: number
 * }} dragEvent
 * @param {number} sliderWidth
 * @param {number} minSeparationInPixels minimum separation between handler & end of track (in px)
 * @returns {boolean} flag if user should be able to continue dragging
 */
const dragTrackStartHandle = (trackEl, dragEvent, sliderWidth, minSeparationInPixels = 0) => {
  const oldTrackWidth = trackEl.getBoundingClientRect().width;
  const minimumTrackWidth = minSeparationInPixels;

  // don't allow start handler to shrink track past minimumTrackWidth
  // when dragging to the right
  if (oldTrackWidth <= minimumTrackWidth && dragEvent.dx > 0) {
    return false;
  }

  const oldTrackLeft = sanitizeFloat(trackEl.style.left);

  // determine new left position. This cannot go beyond beginning or end of track container
  const newTrackLeft = between(oldTrackLeft + dragEvent.dx, 0, sliderWidth - minimumTrackWidth);

  trackEl.style.left = `${newTrackLeft}px`;

  // if handler is left aligned no need to update the track width
  if (newTrackLeft <= 0) {
    return false;
  }

  const newTrackWidth = between(oldTrackWidth - dragEvent.dx, minimumTrackWidth, sliderWidth);
  trackEl.style.width = `${newTrackWidth}px`;

  return true;
};

/**
 * Calculate and update track end handler
 *
 * @param {HTMLElement} trackEl
 * @param {{
 *  dx: number
 * }} dragEvent
 * @param {number} sliderWidth
 * @param {number} minSeparationInPixels minimum separation between handler & end of track (in px)
 * @returns {boolean} flag if user should be able to continue dragging
 */
const dragTrackEndHandle = (trackEl, dragEvent, sliderWidth, minSeparationInPixels = 0) => {
  const oldTrackLeft = sanitizeFloat(trackEl.style.left);
  const oldTrackWidth = trackEl.getBoundingClientRect().width;
  const minimumTrackWidth = minSeparationInPixels;

  // don't allow end handler to shrink track past minimumTrackWidth
  // when dragging to the left
  if (oldTrackWidth <= minimumTrackWidth && dragEvent.dx < 0) {
    return false;
  }

  // if handler is right aligned no need to update track width
  if (oldTrackLeft + oldTrackWidth + dragEvent.dx >= sliderWidth) {
    return false;
  }

  const newTrackWidth = between(oldTrackWidth + dragEvent.dx, minimumTrackWidth, sliderWidth);

  trackEl.style.width = `${newTrackWidth}px`;

  return true;
};

/**
 * Calculate and update track
 *
 * @param {HTMLElment} trackEl
 * @param {{
 *  dx: number
 * }} dragEvent
 * @param {number} sliderWidth
 * @returns {boolean} flag if user should be able to continue dragging
 */
const dragTrack = (trackEl, dragEvent, sliderWidth) => {
  const oldTrackLeft = sanitizeFloat(trackEl.style.left);
  const trackWidth = trackEl.getBoundingClientRect().width;

  // determine new left position. This cannot go beyond beginning or end of track container
  const newTrackLeft = between(oldTrackLeft + dragEvent.dx, 0, sliderWidth - trackWidth);

  trackEl.style.left = `${newTrackLeft}px`;

  // return false if track is
  //  left aligned (newTrackLeft <= 0)
  //  or right aligned (newTrackLeft + trackWidth >= sliderWidth)
  return !(newTrackLeft <= 0 || newTrackLeft + trackWidth >= sliderWidth);
};

/**
 * Calculate and update transition in handler
 *
 * @param {HTMLElement} transitionInEl
 * @param {HTMLElement} trackEl
 * @param {{
 *  dx: number
 * }} dragEvent
 * @returns {boolean} flag if user should be able to continue dragging
 */
const dragTransitionInHandle = (transitionInEl, trackEl, dragEvent) => {
  const trackWidth = sanitizeFloat(trackEl.style.width);
  const oldTransitionInLeft = sanitizeFloat(transitionInEl.style.left);

  // determine new left position. This cannot go beyond beginning or end of track
  const newTransitionInLeft = between(oldTransitionInLeft + dragEvent.dx, 0, trackWidth);

  transitionInEl.style.left = `${newTransitionInLeft}px`;

  // return false if handler is
  //  left aligned (newTransitionInLeft <= 0)
  //  or right aligned (newTransitionInLeft >= trackWidth)
  return !(newTransitionInLeft <= 0 || newTransitionInLeft >= trackWidth);
};

/**
 * Calculate and update transition out handler
 *
 * @param {HTMLElement} transitionOutEl
 * @param {HTMLElement} trackEl
 * @param {{
 *  dx: number
 * }} dragEvent
 * @returns {boolean} flag if user should be able to continue dragging
 */
const dragTransitionOutHandle = (transitionOutEl, trackEl, dragEvent) => {
  const trackWidth = sanitizeFloat(trackEl.style.width);

  // determine new right position. This cannot go beyond beginning or end of track
  const oldTransitionOutRight = sanitizeFloat(transitionOutEl.style.right);

  const newTransitionOutRight = between(oldTransitionOutRight - dragEvent.dx, 0, trackWidth);

  transitionOutEl.style.right = `${newTransitionOutRight}px`;

  // return false if handler is
  //  left aligned (newTransitionOutRight >= trackWidth)
  //  right aligned (newTransitionOutRight <= 0)
  return !(newTransitionOutRight <= 0 || newTransitionOutRight >= trackWidth);
};

/**
 * A higher order component wrapper that handles making a slider handle's draggable.
 *
 * @param {Object} WrappedComponent
 * @returns {Object}
 */
export default function sliderInteractHocWrapper(WrappedComponent) {
  /**
   * The SliderInteractHoc higher ticket component.
   */
  class SliderInteractHoc extends React.Component {
    /**
     * The slider react component.
     *
     * @type {?{}}
     */
    @observable slider = null;

    /**
     * Map of draggable elements
     *
     * @type {{
     *  [key: string]: HTMLElement
     * }}
     */
    @observable dragEls = {};

    /**
     * The interactJS objects initialized on the handles.
     *
     * @type {Array.<{draggable: function, resizable: function}>}
     */
    @observable interactions = [];

    /**
     * Map of the start positions of drag events
     *
     * @type {{
     *  [key: string]: number
     * }}
     */
    dragStartPosition = {};

    /**
     * @constructor
     * @param {{}} props
     * @param {{}} componentContext
     */
    constructor(props, componentContext) {
      super(props, componentContext);
    }

    /**
     * Triggered when the component just mounted to the page.
     */
    componentDidMount() {
      this.initInteraction();
    }

    /**
     * Triggered when the component updated.
     *
     * @param {{}} prevProps
     */
    componentDidUpdate(prevProps) {
      const {inValue, outValue} = this.props;
      const newInTransition = !prevProps.inValue && inValue;
      const newOutTransition = !prevProps.outValue && outValue;

      if (newInTransition || newOutTransition) {
        this.onChangeMount(this.slider, true);
        this.initInteraction();
      }
    }

    /**
     * Triggered when the component is about to unmount.
     */
    componentWillUnmount() {
      this.stopInteraction();
    }

    /**
     * Triggered right after the wrapped component is added to OR removed from the page.
     *
     * @param {{}} domEl
     * @param {boolean} force
     */
    @action onChangeMount = (domEl, force) => {
      if (this.slider && !force) {
        return;
      }

      this.slider = domEl;
      const sliderEl = findDOMNode(domEl);
      if (!sliderEl) {
        return;
      }

      this.dragEls = {
        [DRAG_EL_TRACK]: sliderEl.querySelector(DRAG_TRACKING_MAP[DRAG_EL_TRACK].selector),
        [DRAG_EL_TRACK_START_HANDLE]: sliderEl.querySelector(DRAG_TRACKING_MAP[DRAG_EL_TRACK_START_HANDLE].selector),
        [DRAG_EL_TRACK_END_HANDLE]: sliderEl.querySelector(DRAG_TRACKING_MAP[DRAG_EL_TRACK_END_HANDLE].selector),
        [DRAG_EL_TRANSITION_IN_HANDLE]: sliderEl.querySelector(DRAG_TRACKING_MAP[DRAG_EL_TRANSITION_IN_HANDLE].selector),
        [DRAG_EL_TRANSITION_OUT_HANDLE]: sliderEl.querySelector(DRAG_TRACKING_MAP[DRAG_EL_TRANSITION_OUT_HANDLE].selector),
      };
    };

    /**
     * Handles the drag move event.
     *
     * @param {string} dragElKey
     * @returns {function({})}
     */
    onMove = (dragElKey) => {
      /**
       * Updates the handle and values when after the drag event triggers.
       *
       * @param {{dx: number, dy: number}} dragEvent
       */
      return (dragEvent) => {
        const {isActive, maxValue, minSeparation} = this.props;
        const sliderWidth = this.slider.getSliderWidth();
        const trackEl = this.dragEls[DRAG_EL_TRACK];

        const minSeparationInPixels = maxValue !== 0
          ? Math.round((minSeparation / maxValue) * sliderWidth)
          : 0;

        if (!trackEl) {
          return;
        }

        const elSelector = DRAG_TRACKING_MAP[dragElKey].selector;
        const activeExternalEls = Array.prototype.filter.call(
          document.querySelectorAll(`.entity-timeline-item.active ${elSelector}`),
          (el) => el !== this.dragEls[dragElKey]
        );

        switch (dragElKey) {
          case DRAG_EL_TRACK_START_HANDLE: {
            const continueDrag = dragTrackStartHandle(trackEl, dragEvent, sliderWidth, minSeparationInPixels);

            // exit if we should not continue drag
            // or if this slider is inactive (we don't want to move other active sliders)
            if (!continueDrag || !isActive) {
              return;
            }
            activeExternalEls.forEach((el) => {
              dragTrackStartHandle(el.parentNode, dragEvent, sliderWidth, minSeparationInPixels);
            });
            break;
          }
          case DRAG_EL_TRACK_END_HANDLE: {
            const continueDrag = dragTrackEndHandle(trackEl, dragEvent, sliderWidth, minSeparationInPixels);

            // exit if we should not continue drag
            // or if this slider is inactive (we don't want to move other active sliders)
            if (!continueDrag || !isActive) {
              return;
            }
            activeExternalEls.forEach((el) => {
              dragTrackEndHandle(el.parentNode, dragEvent, sliderWidth, minSeparationInPixels);
            });
            break;
          }
          case DRAG_EL_TRACK: {
            const continueDrag = dragTrack(trackEl, dragEvent, sliderWidth);

            // exit if we should not continue drag
            // or if this slider is inactive (we don't want to move other active sliders)
            if (!continueDrag || !isActive) {
              return;
            }
            activeExternalEls.forEach((el) => {
              dragTrack(el, dragEvent, sliderWidth);
            });
            break;
          }
          case DRAG_EL_TRANSITION_IN_HANDLE: {
            const continueDrag = dragTransitionInHandle(this.dragEls[DRAG_EL_TRANSITION_IN_HANDLE], trackEl, dragEvent);

            // exit if we should not continue drag
            // or if this slider is inactive (we don't want to move other active sliders)
            if (!continueDrag || !isActive) {
              return;
            }
            activeExternalEls.forEach((el) => {
              dragTransitionInHandle(el, el.parentNode, dragEvent);
            });
            break;
          }
          case DRAG_EL_TRANSITION_OUT_HANDLE: {
            const continueDrag = dragTransitionOutHandle(this.dragEls[DRAG_EL_TRANSITION_OUT_HANDLE], trackEl, dragEvent);

            // exit if we should not continue drag
            // or if this slider is inactive (we don't want to move other active sliders)
            if (!continueDrag || !isActive) {
              return;
            }
            activeExternalEls.forEach((el) => {
              dragTransitionOutHandle(el, el.parentNode, dragEvent);
            });
            break;
          }
          default:

            // do nothing
        }
      };
    };

    /**
     * Handles the drag start event.
     *
     * @param {string} dragElKey
     * @returns {function({})}
     */
    onStart = (dragElKey) => {
      /**
       * Updates the start position.
       */
      return () => {
        const attributeToTrack = typeof DRAG_TRACKING_MAP[dragElKey] !== 'undefined'
          ? DRAG_TRACKING_MAP[dragElKey].attribute
          : 'left';
        const elementToTrackKey = typeof DRAG_TRACKING_MAP[dragElKey] !== 'undefined'
          ? DRAG_TRACKING_MAP[dragElKey].element
          : DRAG_EL_TRACK;
        const elementToTrack = this.dragEls[elementToTrackKey];

        if (!elementToTrack) {
          return;
        }

        const attributeValue = sanitizeFloat(elementToTrack.style[attributeToTrack]);

        this.dragStartPosition[dragElKey] = attributeValue;
      };
    }

    /**
     * Handles the drag end event.
     *
     * @param {string} dragElKey
     * @returns {function({})}
     */
    onEnd = (dragElKey) => {
      /**
       * Updates the handle and values when after the drag event ends.
       */
      return () => {
        const {minValue, maxValue} = this.slider.props;
        const {onChangeStart, onChangeEnd, onChangeFull, onChangeIn, onChangeOut} = this.props;
        const sliderWidth = this.slider.getSliderWidth();

        const startValue = this.dragStartPosition[dragElKey]
          ? this.dragStartPosition[dragElKey]
          : 0;

        const attributeToTrack = typeof DRAG_TRACKING_MAP[dragElKey] !== 'undefined'
          ? DRAG_TRACKING_MAP[dragElKey].attribute
          : 'left';
        const elementToTrackKey = typeof DRAG_TRACKING_MAP[dragElKey] !== 'undefined'
          ? DRAG_TRACKING_MAP[dragElKey].element
          : DRAG_EL_TRACK;
        const elementToTrack = this.dragEls[elementToTrackKey];

        const endValue = sanitizeFloat(elementToTrack.style[attributeToTrack]);

        // find difference between start & end. if attribute is "right", invert
        const differenceInPixels = (endValue - startValue)
          * (attributeToTrack === 'right' ? -1 : 1);

        const percentChange = differenceInPixels / sliderWidth;
        const totalDiff = percentChange * (maxValue - minValue);

        switch (dragElKey) {
          case DRAG_EL_TRACK_START_HANDLE:
            onChangeStart(totalDiff); break;
          case DRAG_EL_TRACK_END_HANDLE:
            onChangeEnd(totalDiff); break;
          case DRAG_EL_TRACK:
            onChangeFull(totalDiff); break;
          case DRAG_EL_TRANSITION_IN_HANDLE:
            onChangeIn(totalDiff); break;
          case DRAG_EL_TRANSITION_OUT_HANDLE:
            onChangeOut(totalDiff); break;
          default:
            return;
        }
      };
    };

    /**
     * Starts the interactJS code.
     */
    @action initInteraction = () => {
      // Make sure we don't have multiple dragging interactions on this element.
      if (this.interactions.length) {
        this.interactions.forEach((interaction) => {
          interaction.unset();
        });
        this.interactions.clear();
      }

      Object.keys(this.dragEls).forEach((key) => {
        const dragEl = this.dragEls[key];

        if (dragEl) {
          const interaction = interact(dragEl);
          interaction.draggable({
            onstart: this.onStart(key),
            onmove: this.onMove(key),
            onend: this.onEnd(key),
          });

          this.interactions.push(interaction);
        }
      });
    };

    /**
     * Unbinds the dragging code.
     */
    @action stopInteraction = () => {
      if (!this.interactions.length) {
        return;
      }

      this.interactions.forEach((interaction) => {
        interaction.unset();
      });
      this.interactions.clear();
    };

    /**
     * Renders the WrappedComponent.
     *
     * @returns {Object}
     */
    render() {
      if (this.props.ref) {
        throw new Error('SliderInteractHoc will override ref property given to the wrapped component.');
      }

      return (
        <WrappedComponent
          {...this.props}
          ref={this.onChangeMount}
        />
      );
    }
  }

  SliderInteractHoc.propTypes = {
    inValue: PropTypes.number,
    isActive: PropTypes.bool,
    maxValue: PropTypes.number,
    minSeparation: PropTypes.number,
    onChangeEnd: PropTypes.func,
    onChangeFull: PropTypes.func,
    onChangeIn: PropTypes.func,
    onChangeOut: PropTypes.func,
    onChangeStart: PropTypes.func,
    outValue: PropTypes.number,
    ref: PropTypes.func,
  };

  SliderInteractHoc.defaultProps = {
    isActive: false,
    minSeparation: 0,
  };

  return observer(SliderInteractHoc);
}
