import React from 'react';
import {observable, action, computed, runInAction} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import {Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label, FormText} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

import inject from '../../hoc/injectHoc';
import PreloadWritableFolder from '../../common/preloadWritableFolder/PreloadWritableFolder';
import ConfirmModal from '../confirm/ConfirmModal';
import FileUploadingModal from '../fileUploading/FileUploadingModal';

import './collectionExampleAddEdit.scss';

/**
 * The CollectionExampleAddEditModal component.
 */
class CollectionExampleAddEditModal extends React.Component {
  /**
   * The reference to the thumbnail file input.
   *
   * @type {{current: HTMLInputElement}}
   */
  thumbnailFileInput = React.createRef();

  /**
   * The reference to the video file input.
   *
   * @type {{current: HTMLInputElement}}
   */
   videoFileInput = React.createRef();

  @observable collectionExampleForm = {
    url: '',
    videoFile: null,
    thumbnailFile: null,
    enabled: true,
  }

  /**
   * Whether or not the uploading modal is open.
   * This should match up directly to whether or not any files are uploading.
   *
   * @type {boolean}
   */
  @observable isUploadingModalOpen = false;

  /**
   * Whether or not there was an upload error.
   *
   * @type {?Error}
   */
  @observable uploadError = null;

  /**
   * The folder id where collection example files can be uploaded.
   *
   * @type {?number}
   */
  @observable uploadFolderId = null;

  /**
   * Triggered when the component is mounted to the page.
   */
  @action componentDidMount() {
    const {collectionExample} = this.props;

    if (collectionExample) {
      this.collectionExampleForm = {
        url: collectionExample.url,
        enabled: collectionExample.enabled,
        videoFile: collectionExample.videoFile || null,
        thumbnailFile: collectionExample.thumbnailFile || null,
      };
    }
  }

  /**
   * Tracks if the form is disabled
   *
   * @returns {boolean}
   */
  @computed get isSubmitDisabled() {
    return Boolean(
      this.collectionExampleForm.url === ''
      || this.collectionExampleForm.videoFile === null
      || this.collectionExampleForm.thumbnailFile === null
    );
  }

  /**
   * Input onChange handler
   *
   * @param {{target: {}}} changeEvent
   */
  @action onChange = (changeEvent) => {
    const target = changeEvent.target;
    const value = (target.type === 'checkbox') ? target.checked : target.value;
    this.collectionExampleForm[target.name] = value;
  };

  /**
   * File onChange handler
   *
   * @param {{targt: {}}} changeEvent
   */
  onChangeFile = (changeEvent) => {
    const target = changeEvent.target;

    if (!target.files || target.files.length === 0) {
      return;
    }
    this.onUploadFiles(target.files[0], target.id);
  };

  /**
   * Triggered when the modal is completed.
   */
  onCompleteModal = () => {
    const {onComplete} = this.props;

    onComplete(this.collectionExampleForm);
  };

  /**
   * Triggered when the modal is cancelled.
   */
  onCancelModal = () => {
    const {onComplete} = this.props;

    onComplete();
  };

  /**
   * Dismisses the too many error alert.
   */
  @action onDismissError = () => {
    this.uploadError = null;
  };

  /**
   * Opens the uploading modal.
   */
  @action openUploadingModal = () => {
    this.isUploadingModalOpen = true;
  };

  /**
   * Closes the uploading modal.
   */
  @action closeUploadingModal = () => {
    this.isUploadingModalOpen = false;
  };

  /**
   * Stores a folder id when create request upload folders are found.
   *
   * @param {Array<{id: number, name: string}>} foundFolders
   */
  @action onUploadFolderFound = (foundFolders) => {
    if (!foundFolders || !foundFolders.length) {
      return;
    }

    this.uploadFolderId = foundFolders[0].id;
  };

  /**
   * Upload file to server
   *
   * @param {File} file
   * @param {string} type
   */
  onUploadFiles = (file, type) => {
    const {apiFileUploadStore, apiFileGetOneStore} = this.props;

    this.openUploadingModal();

    const closeAfterSuccessTimeout = 2000;

    apiFileUploadStore.makeRequest(
      [file],
      this.uploadFolderId
    );

    apiFileUploadStore.getUploadPromise(true).then((newFiles) => {
      const fileId = newFiles[0].fileId;
      apiFileGetOneStore.makeRequest(fileId);
      return apiFileGetOneStore.getPromise(fileId);
    }).then((fileResponse) => {
      runInAction('afterUploadedPushPendingFiles', () => {
        this.collectionExampleForm[type] = fileResponse;
      });

      setTimeout(() => {
        this.closeUploadingModal();
      }, closeAfterSuccessTimeout);
    }).catch((uploadError) => {
      runInAction('showUploadError', () => {
        this.uploadError = uploadError;
      });
    });
  };

  /**
   * Remove a file from collection example
   *
   * @param {string} type
   * @returns {function}
   */
  removeFile(type) {
    return () => {
      runInAction('removeCollectionExampleFile', () => {
        switch (type) {
          case 'thumbnailFile':
            this.collectionExampleForm.thumbnailFile = null;
            this.thumbnailFileInput.current.value = '';
            break;
          case 'videoFile':
            this.collectionExampleForm.videoFile = null;
            this.videoFileInput.current.value = '';
            break;
          default:

            // do nothing
        }
      });
    };
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {collectionExample} = this.props;

    const title = collectionExample ? 'Update Collection Example' : 'Create New Collection Example';

    return (
      <>
        <Modal
          className="collection-example-add-edit-modal"
          backdrop="static"
          size="lg"
          isOpen={true}
          toggle={this.onCancelModal}
          centered
        >
          <ModalHeader
            toggle={this.onCancelModal}
          >
            {title}
          </ModalHeader>
          <ModalBody>
            <form>
              <div className="row">
                <div className="col-12">
                  <FormGroup>
                    <Label for="url">URL</Label>
                    <Input
                      type="text"
                      id="url"
                      name="url"
                      className="form-control"
                      value={this.collectionExampleForm.url}
                      onChange={this.onChange}
                    />
                  </FormGroup>
                </div>
                <PreloadWritableFolder
                  folderType="collection-example"
                  newFolderName="My Collection Examples"
                  onFoldersFound={this.onUploadFolderFound}
                >
                  <div className="col-12">
                    <FormGroup>
                      <Label for="thumbnailFile">
                        Thumbnail
                      </Label>
                      <Input
                        id="thumbnailFile"
                        name="thumbnailFile"
                        type="file"
                        accept="image/*"
                        onChange={this.onChangeFile}
                        innerRef={this.thumbnailFileInput}
                      />
                      <FormText
                        color="white"
                      >
                        Ideal thumbnail aspect ratio is 16:9
                      </FormText>
                      {(this.collectionExampleForm.thumbnailFile) && (
                        <div className="mt-1">
                          <a
                            className="pending-file-remove"
                            onClick={this.removeFile('thumbnailFile')}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </a>
                          <span>{this.collectionExampleForm.thumbnailFile.filename}.{this.collectionExampleForm.thumbnailFile.ext}</span>
                        </div>
                      )}
                    </FormGroup>
                  </div>
                  <div className="col-12">
                    <FormGroup>
                      <Label for="videoFile">
                        Video
                      </Label>
                      <Input
                        id="videoFile"
                        name="videoFile"
                        type="file"
                        accept="video/*"
                        onChange={this.onChangeFile}
                        innerRef={this.videoFileInput}
                      />
                      {(this.collectionExampleForm.videoFile) && (
                        <div className="mt-1">
                          <a
                            className="pending-file-remove"
                            onClick={this.removeFile('videoFile')}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </a>
                          <span>{this.collectionExampleForm.videoFile.filename}.{this.collectionExampleForm.videoFile.ext}</span>
                        </div>
                      )}
                    </FormGroup>
                  </div>
                </PreloadWritableFolder>
                <div className="col-12">
                  <FormGroup
                    check
                    inline
                  >
                    <Input
                      className="mb-2"
                      type="checkbox"
                      name="enabled"
                      id="enabled"
                      checked={this.collectionExampleForm.enabled}
                      onChange={this.onChange}
                    />
                    <Label for="enabled">Enabled</Label>
                  </FormGroup>
                </div>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-light"
              onClick={this.onCancelModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.onCompleteModal}
              disabled={this.isSubmitDisabled}
            >
              Submit
            </button>
          </ModalFooter>
        </Modal>

        {(this.uploadError) && (
          <ConfirmModal
            confirmText="An error occurred while trying to upload file."
            isOpen={true}
            onComplete={this.onDismissError}
          />
        )}

        {(this.isUploadingModalOpen) && (
          <FileUploadingModal isOpen={true} onComplete={this.closeUploadingModal} />
        )}
      </>
    );
  }
}

CollectionExampleAddEditModal.propTypes = {
  onComplete: PropTypes.func.isRequired,
  apiFileGetOneStore: MobxPropTypes.observableObject,
  apiFileUploadStore: MobxPropTypes.observableObject,
  collectionExample: PropTypes.object,
};

CollectionExampleAddEditModal.wrappedComponent = {};
CollectionExampleAddEditModal.wrappedComponent.propTypes = {
  apiFileGetOneStore: MobxPropTypes.observableObject.isRequired,
  apiFileUploadStore: MobxPropTypes.observableObject.isRequired
};

export default inject(CollectionExampleAddEditModal)(
  observer(CollectionExampleAddEditModal)
);
