/**
 * The action align component.
 *
 * @param {string} id
 * @param {boolean=} isUnGroup
 * @returns {{actionGroup: {id: string}}}
 */
export function actionGroupComponent(id, isUnGroup) {
  return {
    actionGroup: {
      id,
      isUnGroup: Boolean(isUnGroup),
    },
  };
}
