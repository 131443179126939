import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';

import inject from '../../hoc/injectHoc';
import {editorRoute} from '../../routePaths';
import {redirect} from '../../../utils/history';
import {replaceRouteParams} from '../../../utils/routeHelper';

import './contentNewItem.scss';

/**
 * Content New Item component
 */
class ContentNewItem extends Component {
  /**
   * Triggered when this item is clicked.
   */
  onItemClick = () => {
    const {onClick} = this.props;

    if (onClick) {
      onClick();
      return;
    }

    const toEditor = replaceRouteParams(editorRoute, {contentId: 'new'});
    redirect(toEditor, false);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {newContentText = 'Canvas'} = this.props;

    return (
      <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-one-fifth">
        <div
          className="d-flex flex-column content-new-item"
          onClick={this.onItemClick}
        >
          <div className="container-16-9">
            <div className="blank-image-container">
              <div className="blank-image flex-fill img d-flex justify-content-center align-items-center">
                <FontAwesomeIcon
                  className="blank-icon icon-bold"
                  size="2x"
                  icon={faPlus}
                />
              </div>
            </div>
          </div>
          <div className="p-3 d-flex justify-content-between align-items-center">
            <div className="content-name">
              {newContentText}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ContentNewItem.propTypes = {
  newContentText: PropTypes.string,
  onClick: PropTypes.func,
};

ContentNewItem.wrappedComponent = {};
ContentNewItem.wrappedComponent.propTypes = {};

export default inject(ContentNewItem)(
  observer(ContentNewItem)
);
