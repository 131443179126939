import {action} from 'mobx';

import apiContentFolderGetAllStore from './apiContentFolderGetAllStore';
import ApiBaseStore from '../common/apiBaseStore';
import {STATE_PENDING, STATE_FULFILLED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';

/**
 * Creates a new content folder.
 */
class ApiContentFolderCreateStore extends ApiBaseStore {
  /**
   * Creates a new content folder using the api.
   *
   * @param {string} folderName
   */
  @action makeRequest(folderName) {
    const safeFolderName = String(folderName).trim();

    this.state = STATE_PENDING;
    this.data = null;
    this.error = null;
    this[EXPIRE_TIME] = Date.now() + EXPIRES_PENDING;

    serverApi.contentFolderCreate(safeFolderName).then(
      action('contentFolderCreateSuccess', (newFolderId) => {
        this.state = STATE_FULFILLED;
        this.data = newFolderId;
        this.error = null;
        this[EXPIRE_TIME] = Date.now() + EXPIRES_IN;

        apiContentFolderGetAllStore.refresh(true);
      })
    ).catch(
      action('contentFolderCreateError', (folderCreateError) => {
        this.state = STATE_FULFILLED;
        this.data = null;
        this.error = folderCreateError;
      })
    );
  }
}

export default new ApiContentFolderCreateStore();
