export default {
  DAY_ALIGN: '|',
  HIGH_ALIGN: '|',
  LOW_ALIGN: '|',

  DAY_WIDTH_RATIO: 0.2481,
  DAY_HEIGHT_RATIO: 0.0746,
  DAY_ONE_POSITION_X_RATIO: 0.0535,
  DAY_ONE_POSITION_Y_RATIO: 0.0896,
  DAY_TWO_POSITION_X_RATIO: 0.3784,
  DAY_TWO_POSITION_Y_RATIO: 0.0896,
  DAY_THREE_POSITION_X_RATIO: 0.6976,
  DAY_THREE_POSITION_Y_RATIO: 0.0896,

  ICON_WIDTH_RATIO: 0.3686,
  ICON_HEIGHT_RATIO: 0.5791,
  ICON_ONE_POSITION_X_RATIO: -0.0505,
  ICON_ONE_POSITION_Y_RATIO: 0.1467,
  ICON_TWO_POSITION_X_RATIO: 0.2744,
  ICON_TWO_POSITION_Y_RATIO: 0.1467,
  ICON_THREE_POSITION_X_RATIO: 0.5936,
  ICON_THREE_POSITION_Y_RATIO: 0.1467,

  HIGH_WIDTH_RATIO: 0.2247,
  HIGH_HEIGHT_RATIO: 0.2305,
  HIGH_ONE_POSITION_X_RATIO: 0.0623,
  HIGH_ONE_POSITION_Y_RATIO: 0.5682,
  HIGH_TWO_POSITION_X_RATIO: 0.3872,
  HIGH_TWO_POSITION_Y_RATIO: 0.5682,
  HIGH_THREE_POSITION_X_RATIO: 0.7064,
  HIGH_THREE_POSITION_Y_RATIO: 0.5682,

  LOW_WIDTH_RATIO: 0.1358,
  LOW_HEIGHT_RATIO: 0.1079,
  LOW_ONE_POSITION_X_RATIO: 0.1096,
  LOW_ONE_POSITION_Y_RATIO: 0.7747,
  LOW_TWO_POSITION_X_RATIO: 0.4345,
  LOW_TWO_POSITION_Y_RATIO: 0.7747,
  LOW_THREE_POSITION_X_RATIO: 0.7537,
  LOW_THREE_POSITION_Y_RATIO: 0.7747,
};
