import {EMULATION_STORAGE_COOKIE_KEY} from '../constants/userConstants';

const getCookie = (cookieName) => {
  const regex = new RegExp(`(^| )${cookieName}=([^;]+)`);
  const match = document.cookie.match(regex);
  if (match) {
    return match[2];
  }

  return undefined;
};

const getEmulationAuthId = () => {
  try {
    const emulationCookieValue = getCookie(EMULATION_STORAGE_COOKIE_KEY);

    // parse cookie value and extract value
    return JSON.parse(atob(emulationCookieValue)).message;
  } catch (error) {
    return null;
  }
};

export default {
  getEmulationAuthId,
};
