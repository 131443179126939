/**
 * The action game resize component.
 *
 * @param {string} newAspectRatio
 * @param {number} newWidth
 * @param {number} newHeight
 * @param {number} oldWidth
 * @param {number} oldHeight
 * @returns {{actionGameResize: {
 *   newAspectRatio: number,
 *   newWidth: number,
 *   newHeight: number,
 *   oldWidth: number,
 *   oldHeight: number
 * }}}
 */
export function actionGameResizeComponent(newAspectRatio, newWidth, newHeight, oldWidth, oldHeight) {
  return {
    actionGameResize: {
      newAspectRatio,
      newWidth,
      newHeight,
      oldWidth,
      oldHeight,
    },
  };
}
