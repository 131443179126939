import lodash from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import {action} from 'mobx';
import classNames from 'classnames';

import CollectionAddButton from '../collectionAddButton/CollectionAddButton';
import inject from '../../../../hoc/injectHoc';
import LoadingIcon from '../../../../common/loadingIcon/LoadingIcon';
import {SUPER_ADMIN_ROLE} from '../../../../../constants/userConstants';
import {NEW_CONTENT_COLLECTION_ITEM, NEW_CONTENT_COLLECTION_ID, NEW_CONTENT_COLLECTION_NAME} from '../../../../../constants/newContentConstants';
import {FREE_CONTENT_COLLECTION_ITEM, FREE_CONTENT_COLLECTION_ID, FREE_CONTENT_COLLECTION_NAME} from '../../../../../constants/freeContentConstants';

import './collectionSidebarList.scss';

/**
 * The collection navigation component.
 */
class CollectionSidebarList extends React.Component {
  /**
   * Loads user based on session
   */
  @action componentDidMount() {
    const {autoLoad, apiCollectionGetAllStore, apiCollectionGetFreeContentCountStore, apiCollectionGetNewContentCountStore} = this.props;

    apiCollectionGetAllStore.refresh();
    apiCollectionGetNewContentCountStore.refresh(NEW_CONTENT_COLLECTION_ID);
    apiCollectionGetFreeContentCountStore.refresh(FREE_CONTENT_COLLECTION_ID);

    if (autoLoad) {
      this.autoSelectCollection();
    }
  }

  /**
   * Triggered when the props change.
   *
   * @param {{}} prevProps
   */
  componentDidUpdate(prevProps) {
    if (this.props.autoLoad && prevProps.autoLoad !== this.props.autoLoad) {
      this.autoSelectCollection();
    }
  }

  /**
   * Auto selects a collection.
   */
  autoSelectCollection = () => {
    const {autoLoad, onSelect, apiCollectionGetAllStore} = this.props;
    if (!autoLoad) {
      return;
    }

    // if autoload === 'new' then load new stuff
    if (autoLoad === NEW_CONTENT_COLLECTION_ID) {
      onSelect(NEW_CONTENT_COLLECTION_ITEM);
      return;
    }

    // if autoload === 'free' then load free stuff
    if (autoLoad === FREE_CONTENT_COLLECTION_ID) {
      onSelect(FREE_CONTENT_COLLECTION_ITEM);
      return;
    }

    apiCollectionGetAllStore.getPromise().then((collections) => {
      if (!collections || !collections.length) {
        return;
      }

      if (!lodash.isNumber(autoLoad)) {
        onSelect(collections[0]);
        return;
      }

      const foundCollection = lodash.find(collections, {id: autoLoad});
      if (foundCollection) {
        onSelect(foundCollection);
      }
    });
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      hideIfEmpty,
      onSelect,
      selected,
      showFreeCollectionItem,
      showNewAdditionsItem,
      title,
      user,
      /* @type {ApiCollectionGetAllStore} */ apiCollectionGetAllStore,
      apiCollectionGetFreeContentCountStore,
      apiCollectionGetNewContentCountStore,
    } = this.props;

    const isEmpty = (apiCollectionGetAllStore.isEmpty !== false);
    const canDesign = Boolean(user && user.canDesign);

    if (hideIfEmpty && isEmpty) {
      return null;
    }

    const canViewCollectionCount = user && user.role === SUPER_ADMIN_ROLE;

    return (
      <div className="collection-sidebar-list sidebar-list">
        <div className="sidebar-list-header d-flex align-items-center justify-content-between">
          <span>{title}</span>

          {apiCollectionGetAllStore.case({
            pending: () => <LoadingIcon size="em" />
          })}

          {(canDesign) && (
            <CollectionAddButton />
          )}
        </div>

        {showNewAdditionsItem && apiCollectionGetNewContentCountStore.case({
          fulfilled: (count) => (<div
            className={classNames('sidebar-list-item d-flex', {active: NEW_CONTENT_COLLECTION_ID === String(selected)})}
            onClick={() => onSelect(NEW_CONTENT_COLLECTION_ITEM)}
          >
            <div>
              {NEW_CONTENT_COLLECTION_NAME}
            </div>

            {(count !== null && canViewCollectionCount) && (
              <div className="sidebar-list-item-count ml-auto">
                {count}
              </div>
            )}
          </div>)
        })}

        {showFreeCollectionItem && apiCollectionGetFreeContentCountStore.case({
          fulfilled: (count) => (<div
            className={classNames('sidebar-list-item d-flex', {active: FREE_CONTENT_COLLECTION_ID === String(selected)})}
            onClick={() => onSelect(FREE_CONTENT_COLLECTION_ITEM)}
          >
            <div>
              {FREE_CONTENT_COLLECTION_NAME}
              <span className="free-label px-2">
                Next
              </span>
            </div>

            {(count !== null && canViewCollectionCount) && (
              <div className="sidebar-list-item-count ml-auto">
                {count}
              </div>
            )}
          </div>)
        })}
        {apiCollectionGetAllStore.case({
          fulfilled: (collections) => collections.map((item) => (
            <div
              key={item.name}
              className={classNames('sidebar-list-item d-flex', {active: String(item.id) === String(selected)})}
              onClick={() => onSelect(item)}
            >
              <div>
                {item.name}
              </div>
              { // hide from users
                (canViewCollectionCount) && (
                  <div className="sidebar-list-item-count ml-auto">
                    {item.contentCount || 0}
                  </div>
                )
              }
            </div>
          ))
        })}
      </div>
    );
  }
}

CollectionSidebarList.propTypes = {
  onSelect: PropTypes.func.isRequired,

  apiCollectionGetAllStore: MobxPropTypes.observableObject,
  apiCollectionGetFreeContentCountStore: MobxPropTypes.observableObject,
  apiCollectionGetNewContentCountStore: MobxPropTypes.observableObject,
  autoLoad: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
  hideIfEmpty: PropTypes.bool,
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showFreeCollectionItem: PropTypes.bool,
  showNewAdditionsItem: PropTypes.bool,
  title: PropTypes.string,
  user: PropTypes.object,
};

CollectionSidebarList.defaultProps = {
  hideIfEmpty: false,
  showNewAdditionsItem: false,
  showFreeCollectionItem: false,
  title: 'Collections',
};

CollectionSidebarList.wrappedComponent = {};
CollectionSidebarList.wrappedComponent.propTypes = {
  apiCollectionGetAllStore: MobxPropTypes.observableObject.isRequired,
  apiCollectionGetFreeContentCountStore: MobxPropTypes.observableObject.isRequired,
  apiCollectionGetNewContentCountStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(CollectionSidebarList)(
  observer(CollectionSidebarList)
);
