import {action} from 'mobx';

import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';
import ApiMapBaseStore from '../common/apiMapBaseStore';

/**
 * ApiFileSearchStore
 */
class ApiFileSearchStore extends ApiMapBaseStore {
  /**
   * Builds the data map key for search value and global state.
   *
   * @param {string} searchValue
   * @param {boolean} simple
   * @returns {string}
   */
  buildKey(searchValue, simple) {
    const simplePost = (simple) ? '-1' : '-0';

    return String(searchValue) + simplePost;
  }

  /**
   * Ensures fresh files in the store.
   *
   * @param {string} searchValue
   * @param {boolean} simple
   * @param {boolean} force Whether or not to force the refresh
   */
  refresh(searchValue, simple, force) {
    const safeKey = this.buildKey(searchValue, simple);

    if (force || !this.isItemAvailable(safeKey)) {
      this.clear(safeKey);

      this.makeRequest(searchValue, simple);
    }
  }

  /**
   * Fetches file info from the server based on a search term.
   *
   * @param {string} searchValue
   * @param {boolean} simple
   */
  @action makeRequest(searchValue, simple) {
    const safeKey = this.buildKey(searchValue, simple);

    this.dataMap.set(safeKey, {
      state: STATE_PENDING,
      [EXPIRE_TIME]: Date.now() + EXPIRES_PENDING,
      data: null,
      error: null,
    });

    serverApi.fileSearch(
      String(searchValue),
      Boolean(simple)
    ).then(action('fileSearchSuccess', (foundFile) => {
      this.dataMap.set(safeKey, {
        state: STATE_FULFILLED,
        [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
        data: foundFile,
        error: null,
      });
    })).catch(action('fileSearchError', (fetchError) => {
      this.dataMap.set(safeKey, {
        state: STATE_REJECTED,
        [EXPIRE_TIME]: null,
        data: null,
        error: fetchError,
      });
    }));
  }
}

export default new ApiFileSearchStore();
