import lodash from 'lodash';
import {runInAction} from 'mobx';

import {clearTransitionCache, setEntityComponents, updateEntity} from '../ecs/entityHelper';

/**
 * The name of the system.
 * @const {string}
 */
export const ACTION_UPDATING_VARIABLE_SYSTEM = 'actionUpdatingVariableSystem';

/**
 * Gets a new instance of the updating variable system.
 *
 * @param {GameStore} game
 * @returns {{name: string, runActions: systemRunActions}}
 */
export function actionUpdatingVariableSystem(game) {
  /**
   * Called right before the game loop updates.
   *
   * @param {ObservableArray} actions
   */
  function systemRunActions(actions) {
    actions.forEach((actionEntity) => {
      // First check for required components.
      if (!actionEntity.has('actionUpdateVariable')) {
        return;
      }

      const updates = actionEntity.get('actionUpdateVariable').entityUpdates || {};

      lodash.forEach(updates, (entityUpdates, updateEntityId) => {
        const entity = game.getEntity(updateEntityId);
        if (!entity || !entityUpdates || !lodash.size(entityUpdates)) {
          return;
        }

        lodash.forEach(entityUpdates, (componentUpdates, componentName) => {
          updateEntityWithParams(entity, componentName, componentUpdates);
        });

        clearTransitionCache(entity);
      });
    });
  }

  /**
   * Updates the given entity with the differences given.
   *
   * @param {{}} entity
   * @param {string} componentName
   * @param {{}} params
   */
  function updateEntityWithParams(entity, componentName, params) {
    runInAction('actionUpdatingVariableSystemUpdateEntity', () => {
      if (entity.has(componentName)) {
        updateEntity(entity, componentName, params);
      } else if (params !== null) {
        setEntityComponents(entity, {[componentName]: params});
      }
    });
  }

  return {
    name: ACTION_UPDATING_VARIABLE_SYSTEM,
    runActions: systemRunActions
  };
}
