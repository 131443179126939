import PropTypes from 'prop-types';
import React from 'react';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';

import {AdminSidebar} from './components/adminSidebar/AdminSidebar';
import inject from '../../hoc/injectHoc';
import LoadingIcon from '../../common/loadingIcon/LoadingIcon';
import MainNavBar from '../../common/mainNavBar/MainNavBar';
import {SUPER_ADMIN_ROLE} from '../../../constants/userConstants';

import './adminLayout.scss';

/**
 * The AdminLayout component.
 */
export class AdminLayout extends React.Component {
  /**
   * Triggered when the component first mounts to the page.
   */
  componentDidMount() {
    const {apiUserGetMeStore} = this.props;

    apiUserGetMeStore.refresh();
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {apiUserGetMeStore} = this.props;

    return (
      <div id="admin-layout">
        <MainNavBar
          location={this.props.location}
          match={this.props.match}
          navType={this.props.navType}
        />

        <div id="admin-content">
          {apiUserGetMeStore.case({
            pre: () => (<LoadingIcon />),
            pending: () => (<LoadingIcon />),
            rejected: () => (
              <main id="admin-page" role="main">
                <div className="alert alert-warning">
                  You must be logged in to access this page.
                </div>
              </main>
            ),
            fulfilled: (user) => {
              if (!user || (user.role !== SUPER_ADMIN_ROLE)) {
                return (
                  <main id="admin-page" role="main">
                    <div className="alert alert-warning">
                      You do not have permission to access this page.
                    </div>
                  </main>
                );
              }

              return (
                <>
                  <AdminSidebar location={this.props.location} />

                  <main id="admin-page" role="main">
                    {this.props.page}
                  </main>
                </>
              );
            },
          })}
        </div>
      </div>
    );
  }
}

AdminLayout.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.object,
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  page: PropTypes.element.isRequired,

  apiUserGetMeStore: MobxPropTypes.observableObject,
  navType: PropTypes.symbol,
};

AdminLayout.defaultProps = {
  navType: null,
};

AdminLayout.wrappedComponent = {};
AdminLayout.wrappedComponent.propTypes = {
  apiUserGetMeStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(AdminLayout)(
  observer(AdminLayout)
);
