/**
 * ID of Free Content Collection
 * @const {string}
 */
export const FREE_CONTENT_COLLECTION_ID = 'free';

/**
 * Name of Free Content Collection
 * @const {string}
 */
export const FREE_CONTENT_COLLECTION_NAME = 'Included';

/**
 * Free Content Collection Item. This mimics a collection returned from the api
 * @const {object}
 */
export const FREE_CONTENT_COLLECTION_ITEM = {
  id: FREE_CONTENT_COLLECTION_ID,
  name: FREE_CONTENT_COLLECTION_NAME,
};
