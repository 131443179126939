import lodash from 'lodash';

import actions from 'interactjs/src/actions/base';
import browser from 'interactjs/src/utils/browser';
import utils from 'interactjs/src/utils';

import defaultOptions from 'interactjs/src/defaultOptions';

import {calculateElementPoints, checkForEdge} from '../utils/checkerHelper';
import {getScaleFromElement} from '../../dragDropHelper';

// Less Precision with touch input
// eslint-disable-next-line no-magic-numbers
const defaultMargin = browser.supportsTouch || browser.supportsPointerEvent ? 20 : 10;

export const resize = {
  defaults: {
    enabled: false,
    mouseButtons: null,

    origin: null,
    snap: null,
    restrict: null,
    inertia: null,
    autoScroll: null,

    square: false,
    preserveAspectRatio: false,
    axis: 'xy',

    // use default margin
    margin: NaN,
    scaleEl: null,

    // object with props left, right, top, bottom which are
    // true/false values to resize when the pointer is over that edge,
    // CSS selectors to match the handles for each direction
    // or the Elements for each handle
    edges: {
      left: true,
      right: true,
      top: true,
      bottom: true,
    },

    // a value of 'none' will limit the resize rect to a minimum of 0x0
    // 'negate' will allow the rect to have negative width/height
    // 'reposition' will keep the width/height positive by swapping
    // the top and bottom edges and/or swapping the left and right edges
    invert: 'none',

    max: Infinity,
    maxPerElement: 1,
  },

  /**
   * Checks to see if rotation is viable for the pointer/event.
   *
   * @param {{}} pointer
   * @param {{}} theEvent
   * @param {{}} interactable
   * @param {{}} element
   * @param {{}} interaction
   * @param {{}} rect
   * @returns {?{name: string, edges: {left: boolean, right: boolean, top: boolean, bottom: boolean}}}
   */
  checker(pointer, theEvent, interactable, element, interaction, rect) {
    if (!rect) {
      return null;
    }

    const page = utils.extend({}, interaction.curCoords.page);
    const options = interactable.options;

    const resizeOptions = options.resize;
    if (!resizeOptions.enabled) {
      return null;
    }

    // if using resize.edges
    if (!utils.is.object(resizeOptions.edges)) {
      return null;
    }

    const scale = getScaleFromElement(resizeOptions.scaleEl);
    const corners = calculateElementPoints(rect, element, scale);

    const resizeEdges = {left: false, right: false, top: false, bottom: false};
    lodash.forEach(resizeEdges, (edgeValue, edgeName) => {
      resizeEdges[edgeName] = checkForEdge(
        edgeName,
        resizeOptions.edges[edgeName],
        page,
        interaction._eventTarget,
        element,
        rect,
        corners,
        resizeOptions.margin || defaultMargin,
        scale
      );
    });

    resizeEdges.left = resizeEdges.left && !resizeEdges.right;
    resizeEdges.top = resizeEdges.top && !resizeEdges.bottom;

    if (resizeEdges.left || resizeEdges.right || resizeEdges.top || resizeEdges.bottom) {
      return {
        name: 'resize',
        edges: resizeEdges,
      };
    }

    return null;
  },

  cursors: (browser.isIe9 ? {
    x: 'e-resize',
    y: 's-resize',
    xy: 'se-resize',

    top: 'n-resize',
    left: 'w-resize',
    bottom: 's-resize',
    right: 'e-resize',
    topleft: 'se-resize',
    bottomright: 'se-resize',
    topright: 'ne-resize',
    bottomleft: 'ne-resize',
  } : {
    x: 'ew-resize',
    y: 'ns-resize',
    xy: 'nwse-resize',

    top: 'ns-resize',
    left: 'ew-resize',
    bottom: 'ns-resize',
    right: 'ew-resize',
    topleft: 'nwse-resize',
    bottomright: 'nwse-resize',
    topright: 'nesw-resize',
    bottomleft: 'nesw-resize',
  }),

  /**
   * Gets the cursor to display.
   *
   * @param {?{name: string, edges: {left: boolean, right: boolean, top: boolean, bottom: boolean}}} action
   * @returns {string|undefined}
   */
  getCursor(action) {
    if (!action.edges) {
      return undefined;
    }

    const edgeNames = ['top', 'bottom', 'left', 'right'];

    let cursorKey = '';
    lodash.forEach(edgeNames, (edgeName) => {
      if (action.edges[edgeName]) {
        cursorKey += edgeName;
      }
    });

    return resize.cursors[cursorKey];
  },
};

actions.resize = resize;

defaultOptions.resize = resize.defaults;

export default resize;
