/**
 * The action line position change component.
 *
 * @param {?{y: number, x: number}} startDelta
 * @param {?{y: number, x: number}} endDelta
 * @returns {{actionPosition: {positionDelta: ?{}, endDelta: ?{}}}}
 */
export function actionLinePositionComponent(startDelta, endDelta) {
  return {
    actionLinePosition: {
      startDelta,
      endDelta,
    },
  };
}
