import lodash from 'lodash';

import {EASINGS} from '../../ecs/easingHelper';
import letterFadeInEndGif from '../../../assets/images/transitions/letterFadeInEnd.gif';

/**
 * The name that signals this preset.
 * @const {string}
 */
export const PRESET_LETTER_FADE_IN_END = 'letterFadeInEnd';

/**
 * The preset details.
 *
 * @type {{
 *   name: string,
 *   flow: string,
 *   details: Object.<string, {
 *     easing: string,
 *     parse: boolean,
 *     values: *|Array.<*>,
 *     time: {start: (string|number), end: (string|number)}
 *   }>,
 *   template: {}
 * }}
 */
export const letterFadeInEndPresetDetails = {
  // The name of the preset.
  name: PRESET_LETTER_FADE_IN_END,

  // A human friendly name for the preset.
  humanName: 'Letter - Fade In From End',

  // A preview of the transition.
  gif: letterFadeInEndGif,

  // The element names that this preset can be used for or true for all elements.
  elements: ['text'],

  // The preset direction: in, out, middle. This is used in the UI logic.
  flow: 'in',

  // Each property that will change during the transition.
  details: {
    'letter.opacity': {
      time: {
        end: 'start.1000',
        start: 'start.0'
      },
      easing: EASINGS.easeInPower3,
      values: [
        0,
        1
      ],
      stagger: -50,
      staggerDuration: false,
      immediateRender: true
    },
  },

  // How this preset can be used in the source (including optional parameters).
  // This will be turned into a JSON string, so no functions can be used.
  template: {
    preset: PRESET_LETTER_FADE_IN_END,
    options: {},
  },
};

letterFadeInEndPresetDetails.template.options = lodash.cloneDeep(letterFadeInEndPresetDetails.details);

// This defines the key in the presets object.
export const presetName = PRESET_LETTER_FADE_IN_END;

// This defines the value of the presets object.
export default letterFadeInEndPresetDetails;
