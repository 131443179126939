export const FEATURES_TABLE_DATA = [
  {
    id: 1,
    feature: 'Presto Canvas Editor',
    some: true,
  },
  {
    id: 2,
    feature: 'Instantly Auto Size Content to Any Size of Sign',
    some: false,
  },
  {
    id: 3,
    feature: 'Proof of Performance Reporting',
    some: true,
  },
  {
    id: 4,
    feature: 'Mobile-Optimized Scheduling',
    some: true,
  },
  {
    id: 5,
    feature: 'Weighted Content Scheduling',
    some: false,
  },
  {
    id: 6,
    feature: 'Unlimited Stock Imagery',
    some: true,
  },
  {
    id: 7,
    feature: 'Seamless Integration Between Content Creation and Scheduling',
    some: true,
  },
  {
    id: 8,
    feature: 'Accuweather Weather Data',
    some: false,
  },
  {
    id: 9,
    feature: 'Cloud-Based Software (Access from Anywhere)',
    some: true
  },
  {
    id: 10,
    feature: 'Access to over 1,000 Premade Templates',
    some: true,
  }
];

