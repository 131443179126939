/**
 * Gets the text data for a placeholder text.
 *
 * @param {string?} text
 * @returns {{text: {markdown: string}}}
 */
export function getPlaceHolderTextData(text = 'New Text') {
  return {
    text: {
      markdown: `{\\}{$1.00}{%1}{100}{#ffffff}{poppinsbold}${text}{!poppinsbold}{!#ffffff}{!100}{!%1}{!$1.00}{!\\}`,
    },
  };
}
