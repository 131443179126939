import axios from 'axios';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import {PropTypes} from 'prop-types';
import React from 'react';

import SelectSignAndFileTypeModal from '../../../../modals/selectSignAndFileType/SelectSignAndFileTypeModal';
import inject from '../../../../hoc/injectHoc';

import './downloadDropdown.scss';

/**
 * The DownloadButton component.
 */
class DownloadButton extends React.Component {
  /**
   * Whether or not the select file is open.
   *
   * @type {boolean}
   */
  @observable isSelectSignModalOpen = false;

  /**
   * Opens the Select Sign Modal so user can choose which sign to download or render
   *
   */
  @action openSelectSignModal = () => {
    this.isSelectSignModalOpen = true;
  }

  /**
   * Closes the select sign modal
   *
   * @param {{}} param
   * @param {?number} param.signId
   * @param {?string} param.fileType
   * @param {?string} param.confirmAction
   */
  @action closeSelectSignModal = ({signId, fileType, confirmAction}) => {
    this.isSelectSignModalOpen = false;

    if (!signId || !fileType) {
      return;
    }

    const {
      content,
      apiContentFolderGetContentStore,
      routerStore
    } = this.props;

    if (confirmAction === 'Create') {
      this.startRenderProcess(content.id, fileType, signId)
        .then(() => {
          const folderId = routerStore.getParam('listId');

          // refresh content folder so template statuses are properly updated
          apiContentFolderGetContentStore.refresh(folderId, true);
        });
    } else if (confirmAction === 'Download') {
      const userSigns = this.getUserSigns();
      const matchingSign = userSigns.find((sign) => sign.id === signId);

      if (fileType === 'thumbnail') {
        this.downloadThumbnail(matchingSign.file.thumbnailPath);
      } else {
        this.downloadMedia(matchingSign.file.displayPath);
      }
    }
  }

  /**
   * Downloads the chosen item.
   *
   * @param {string} path
   */
  @action downloadMedia = (path) => {
    const fileName = path.split('/').pop();

    axios({
      url: path,
      method: 'GET',

      // use cache-busting query param to avoid browser using non cors cached image
      // this would happen if the preview modal loaded an image (w/o cors), then we tried
      // to download using xhr (w/ cors) in which case the browser uses the cached response
      // (which does not have cors attached it) and ultimately throws a CORS error
      // see https://serverfault.com/a/856948
      params: {
        download: Date.now(),
      },
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data])
      );

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    });
  };

  /**
   * Downloads the thumbnail
   *
   * @param {string} path full url of thumbnail
   */
  @action downloadThumbnail = (path) => {
    if (!path) {
      return;
    }

    const fileName = path.split('/').pop();

    axios({
      // we are adding a cache buster query parameter here because for some reason
      // if this resource is loaded through html (img src) and is cached by the browser,
      // when we hit this resource with axios the CORS origin policy fails
      url: `${path}?t=${Date.now()}`,
      method: 'GET',

      // use cache-busting query param to avoid browser using non cors cached image
      // this would happen if the preview modal loaded an image (w/o cors), then we tried
      // to download using xhr (w/ cors) in which case the browser uses the cached response
      // (which does not have cors attached it) and ultimately throws a CORS error
      // see https://serverfault.com/a/856948
      params: {
        download: Date.now(),
      },
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data])
      );

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    });
  }

  /**
   * Starts the rendering process.
   *
   * @param {number} contentId
   * @param {string} fileType
   * @param {number} signId
   * @returns {Promise}
   */
  startRenderProcess = (contentId, fileType, signId) => {
    const {apiContentRenderStore} = this.props;

    apiContentRenderStore.makeRequest(contentId, fileType, signId);

    return apiContentRenderStore.getPromise(
      apiContentRenderStore.getKey(contentId, signId)
    ).catch(() => {
      // Ignore render errors.
    });
  };

  /**
   * Gets the user signs from the downloadable s .
   *
   * @returns {Array}
   */
  getUserSigns = () => {
    const {content, apiContentRenderGetDownloadableStore} = this.props;

    let userSigns = apiContentRenderGetDownloadableStore.getFulfilled(content.id);

    // user does not have any signs
    if (!userSigns || !userSigns.length) {
      return [];
    }

    return userSigns;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {content} = this.props;

    const isRendering = (content.displayStatus === 'processing');

    return (
      <>
        <button
          className="btn btn-light download-button info-btn-responsive"
          type="button"
          onClick={this.openSelectSignModal}
          disabled={isRendering}
        >
          {(isRendering) ? 'Creating...' : 'Download'}
        </button>

        {

          // select sign and file type modal
          (this.isSelectSignModalOpen) && (
            <SelectSignAndFileTypeModal
              isOpen={true}
              content={content}
              allowDownloadExistingRenders={true}
              disableSignDropdown={false}
              onComplete={this.closeSelectSignModal}
            />
          )
        }
      </>
    );
  }
}

DownloadButton.propTypes = {
  content: PropTypes.object.isRequired,

  apiCompanySignGetAllStore: MobxPropTypes.observableObject,
  apiContentCreateStore: MobxPropTypes.observableObject,
  apiContentFolderGetContentStore: MobxPropTypes.observableObject,
  apiContentRenderGetDownloadableStore: MobxPropTypes.observableObject,
  apiContentRenderStore: MobxPropTypes.observableObject,
  navigateContentStore: MobxPropTypes.observableObject,
  routerStore: MobxPropTypes.observableObject,
};

DownloadButton.wrappedComponent = {};
DownloadButton.wrappedComponent.propTypes = {
  apiCompanySignGetAllStore: MobxPropTypes.observableObject.isRequired,
  apiContentCreateStore: MobxPropTypes.observableObject.isRequired,
  apiContentFolderGetContentStore: MobxPropTypes.observableObject.isRequired,
  apiContentRenderGetDownloadableStore: MobxPropTypes.observableObject.isRequired,
  apiContentRenderStore: MobxPropTypes.observableObject.isRequired,
  navigateContentStore: MobxPropTypes.observableObject.isRequired,
  routerStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(DownloadButton)(
  observer(DownloadButton)
);
