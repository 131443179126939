import {_gsScope} from 'gsap/TweenLite';
import lodash from 'lodash';
import {isObservableMap, runInAction, toJS} from 'mobx';

import {transformComponent} from '../components/common/transformComponent';
import {setEntityComponents, updateEntity} from '../ecs/entityHelper';
import {flattenObjectKeys, toNumber} from '../ecs/pluginHelper';

/**
 * Parses the delta for a given key.
 *
 * @param {string} key
 * @param {number} finalValue
 * @param {number} currentValue
 * @param {number} defaultValue
 * @returns {number}
 */
function parseDelta(key, finalValue, currentValue, defaultValue) {
  if (lodash.startsWith(key, 'translate')) {
    // Translates are the deltas, not the final values, so we have to calculate the deltas differently.
    const endValue = toNumber(finalValue) + toNumber(defaultValue);
    const startValue = toNumber(currentValue);
    return endValue - startValue;
  } else if (lodash.startsWith(key, 'scale')) {
    // Scales are multiplier delta, not the final values, so we have to calculate the delta differently.
    const endValue = toNumber(finalValue) * toNumber(defaultValue);
    const startValue = toNumber(currentValue);
    return endValue - startValue;
  }

  return toNumber(finalValue) - toNumber(currentValue);
}

/**
 * The transform plugin for the ECS game engine.
 */
function transformPluginFactory() {
  _gsScope._gsDefine.plugin({
    /*
     * The name of the property that will get intercepted and handled by this plugin (obviously change it to whatever
     * you want, typically it is camelCase starting with lowercase).
     */
    propName: 'transform',

    /*
     * The priority in the rendering pipeline (0 by default). A priority of -1 would mean this plugin will run after
     * all those with 0 or greater. A priority of 1 would get run before 0, etc. This only matters when a plugin
     * relies on other plugins finishing their work before it runs (or visa-versa).
     */
    priority: 0,

    /*
     * The API should stay 2 - it just gives us a way to know the method/property structure so that if in the future
     * we change to a different TweenPlugin architecture, we can identify this plugin's structure.
     */
    API: 2,

    // Your plugin's version number.
    version: '1.0.0',

    /*
     * An array of property names whose tweens should be overwritten by this plugin. For example, if you
     * create a 'scale' plugin that handles both 'scaleX' and 'scaleY', the overwriteProps would
     * be ['scaleX','scaleY'] so that if there's a scaleX or scaleY tween in-progress when a new 'scale' tween
     * starts (using this plugin), it would overwrite the scaleX or scaleY tween.
     */
    overwriteProps: [
      'transform.perspective', 'transform.rotate', 'transform.scale', 'transform.skew', 'transform.translate'
    ],

    /**
     * The game entity that will be updated.
     *
     * @type {ObservableMap}
     */
    entity: null,

    /**
     * The (lodash) path to the target within the entity.
     *
     * <pre><code>
     *   const target = lodash.get(this.entity, this.path);
     * </code></pre>
     *
     * @type {?string}
     */
    path: null,

    /**
     * The starting values.
     *
     * @type {Object.<string, number>}
     */
    transformStartValues: {},

    /**
     * The total amount of change (end value - start value).
     *
     * @type {Object.<string, number>}
     */
    transformTotalDeltas: {},

    /**
     * The function to call if a mobX merge error occurs.
     *
     * @type {function}
     */
    mergeErrorDisplay: null,

    /**
     * The init function is called when the tween renders for the first time. This is where initial values should
     * be recorded and any setup routines should run.
     *
     * Example: TweenLite.to(target, 1, {transform: value});
     *
     * @param {{}} target The target of the tween. In cases where the tween's original target is an array
     *                    (or jQuery object), this target will be the individual object inside that array
     *                    (a new plugin instance is created for each target in the array).
     * @param {{}} target.entity The entity object that will be manipulated.
     * @param {{}} target.path The (lodash) path to the actual target within the entity.
     * @param {{}} finalData The value that is passed as the special property value.
     * @returns {boolean} This function should return true unless you want to have TweenLite/Max skip the plugin
     *                    altogether and instead treat the property/value like a normal tween.
     */
    init: function init({entity, path}, finalData) {
      // This plugin should only target MobX Observable Maps and valid game engine entities.
      if (!isObservableMap(entity) || !entity.get('element')) {
        // If the target is invalid, the parameter will just be ignored.
        return false;
      }

      // We record the target so that we can refer to it in the set method when doing updates.
      this.entity = entity;
      this.path = path;

      if (!entity.get('transform')) {
        setEntityComponents(entity, transformComponent({}));
      }

      const transform = toJS(entity.get('transform'));

      const defaultValue = lodash.cloneDeep(transform.default);
      const currentValue = lodash.cloneDeep(transform);

      const propertyKeys = flattenObjectKeys(finalData);

      this.transformStartValues = propertyKeys.reduce((deltas, key) => {
        deltas[key] = toNumber(lodash.get(currentValue, key));
        return deltas;
      }, {});

      this.transformTotalDeltas = propertyKeys.reduce((deltas, key) => {
        deltas[key] = parseDelta(
          key,
          lodash.get(finalData, key),
          lodash.get(currentValue, key),
          lodash.get(defaultValue, key)
        );
        return deltas;
      }, {});

      // The merge error should only fire once per init.
      this.mergeErrorDisplay = lodash.once((mergeError) => {
        console.error(mergeError); // eslint-disable-line no-console
      });

      return true;
    },

    /**
     * Called each time the values should be updated, and the ratio gets passed as the only parameter
     * (typically it's a value between 0 and 1, but it can exceed those when using an ease like
     * Elastic.easeOut, Back.easeOut, etc).
     *
     * @param {number} ratio
     */
    set: function set(ratio) {
      if (!this.entity) {
        return;
      }

      const currentTransform = this.entity.get('transform');
      const currentValues = toJS(currentTransform);

      const transformUpdates = {};

      let hasChanges = false;
      lodash.forEach(this.transformTotalDeltas, (deltaValue, propertyName) => {
        const currentValue = lodash.get(currentValues, propertyName, null);
        const startValue = this.transformStartValues[propertyName];
        const newValue = startValue + (deltaValue * ratio);

        if (newValue === currentValue) {
          // No need to update if the values did not change.
          return;
        }

        hasChanges = true;

        lodash.set(transformUpdates, propertyName, newValue);
      });

      if (!hasChanges) {
        // No need to update if the values did not change.
        return;
      }

      runInAction('transformPluginUpdateEntity', () => {
        try {
          updateEntity(this.entity, 'transform', transformUpdates);
        } catch (updateError) {
          // Do not throw an error for an invalid transform key.
          if (updateError.message.match('to add non-tracked key')) {
            this.mergeErrorDisplay(updateError);
          } else {
            throw updateError;
          }
        }
      });
    },
  });
}

if (!_gsScope._gsQueue) {
  _gsScope._gsQueue = [];
}

_gsScope._gsQueue.push(transformPluginFactory);

if (_gsScope._gsDefine) {
  _gsScope._gsQueue.pop()();
}
