import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRefresh} from '@fortawesome/free-solid-svg-icons';

import inject from '../../../../hoc/injectHoc';

import './selectFileHeader.scss';

/**
 * The SelectFileHeader component.
 */
export class SelectFileHeader extends React.Component {
  /**
   * The add placeholder image click handler.
   */
  onAddPlaceholderImageClick = () => {
    const {onPlaceholderSelected} = this.props;

    if (onPlaceholderSelected) {
      onPlaceholderSelected();
    }
  };

  /**
   * The add placeholder video click handler.
   */
  onAddPlaceholderVideoClick = () => {
    const {onPlaceholderSelected} = this.props;

    if (onPlaceholderSelected) {
      onPlaceholderSelected(true);
    }
  };

  /**
   * The upload button click handler.
   */
  onClickUploadButton = () => {
    const {onOpenFileUploadDialog} = this.props;

    if (onOpenFileUploadDialog) {
      onOpenFileUploadDialog();
    }
  };

  /**
   * When the refresh button is clicked.
   */
  onRefreshClick = () => {
    const {onRefresh} = this.props;

    if (onRefresh) {
      onRefresh();
    }
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {isWritable, onPlaceholderSelected, onRefresh, showImages, showVideos} = this.props;

    return (
      <aside className="select-file-header">
        <div className="header-buttons">
          {(onRefresh) && (
            <button
              type="button"
              className="w24-refresh-button btn btn-outline-dark"
              onClick={this.onRefreshClick}
            >
              <FontAwesomeIcon icon={faRefresh} />
            </button>
          )}

          {showImages && (Boolean(onPlaceholderSelected)) && (
            <span>
              <button
                type="button"
                onClick={this.onAddPlaceholderImageClick}
                className="control-btn btn btn-primary"
              >Placeholder Image</button>
            </span>
          )}

          {showVideos && (Boolean(onPlaceholderSelected)) && (
            <span>
              <button
                type="button"
                onClick={this.onAddPlaceholderVideoClick}
                className="control-btn btn btn-primary"
              >Placeholder Video</button>
            </span>
          )}

          {(isWritable) && (
            <span>
              <button
                type="button"
                onClick={this.onClickUploadButton}
                className="control-btn btn btn-primary"
              >Upload File</button>
            </span>
          )}
        </div>
      </aside>
    );
  }
}

SelectFileHeader.propTypes = {
  isWritable: PropTypes.bool,
  onOpenFileUploadDialog: PropTypes.func,
  onPlaceholderSelected: PropTypes.func,
  onRefresh: PropTypes.func,
  showImages: PropTypes.bool,
  showVideos: PropTypes.bool,
};

SelectFileHeader.defaultProps = {
  isWritable: false,
  showImages: false,
  showVideos: false,
};

SelectFileHeader.wrappedComponent = {};
SelectFileHeader.wrappedComponent.propTypes = {
};

export default inject(SelectFileHeader)(
  observer(SelectFileHeader)
);
