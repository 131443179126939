import {action} from 'mobx';

import apiContentFolderGetContentStore from '../contentFolder/apiContentFolderGetContentStore';
import ApiBaseStore from '../common/apiBaseStore';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';

/**
 * ApiContentDeleteStore
 */
class ApiContentDeleteStore extends ApiBaseStore {
  /**
   * Makes the api request.
   *
   * @param {number|string} contentId
   * @param {number|string} folderId
   */
  @action makeRequest(contentId, folderId) {
    if (!contentId) {
      this.state = STATE_REJECTED;
      this.error = new Error('Invalid content id given to contentDelete.');
      return;
    } else if (!folderId) {
      this.state = STATE_REJECTED;
      this.error = new Error('Invalid folder id given to contentDelete.');
      return;
    }

    this.state = STATE_PENDING;
    this[EXPIRE_TIME] = Date.now() + EXPIRES_PENDING;

    serverApi.contentDelete(
      contentId
    ).then(
      action('contentDeleteSuccess', () => {
        this.error = null;
        this.state = STATE_FULFILLED;
        this.data = true;
        this[EXPIRE_TIME] = Date.now() + EXPIRES_IN;

        // Clear the folder content cache.
        apiContentFolderGetContentStore.refresh(folderId, true);
      }),
      action('contentDeleteError', (error) => {
        this.error = error;
        this.state = STATE_REJECTED;
        this.data = false;
      })
    );
  }
}

export default new ApiContentDeleteStore();
