/**
 * The number to divide by to convert to a percentage.
 *
 * @const {number}
 */
const TO_PERCENT = 100;

/**
 * How much the starting z-index is offset from the source page.
 * @const {number}
 */
const Z_INDEX_OFFSET = 0;

/**
 * The difference in zIndex between elements.
 * @const {number}
 */
const Z_INDEX_PADDING = 100;

/**
 * The valid alignment values.
 *
 * @type {{y: {}, x: {}}}
 */
export const ALIGNMENTS = {
  y: {
    'top': 'top',
    'middle': 'middle',
    'bottom': 'bottom',
  },
  x: {
    'left': 'left',
    'center': 'center',
    'right': 'right',
  },
};

/**
 * Makes a degrees number positive and between 0 and 360.
 *
 * @param {number} degrees
 * @returns {number}
 */
export function simplifyDegrees(degrees) {
  if (!degrees) {
    return 0;
  }

  const fullCircle = 360;
  const baseDegrees = degrees % fullCircle;

  if (baseDegrees >= 0) {
    return baseDegrees;
  }

  return fullCircle + baseDegrees;
}

/**
 * Parses the entity order into the zIndex.
 *
 * @param {number} order
 * @returns {number}
 */
export function orderToZIndex(order) {
  return Z_INDEX_OFFSET + ((order + 1) * Z_INDEX_PADDING);
}

/**
 * Parses the entity zIndex back into the order.
 *
 * @param {number} zIndex
 * @returns {number}
 */
export function zIndexToOrder(zIndex) {
  return (((zIndex - Z_INDEX_OFFSET) / Z_INDEX_PADDING) - 1);
}

/**
 * The position component.
 *
 * @param {number} yPosition
 * @param {number} xPosition
 * @param {number} zIndex
 * @param {number} rotate
 * @param {{x: string, y: string}} alignment
 * @returns {{position: {
 *   y: number,
 *   x: number,
 *   rotate: number,
 *   zIndex: number,
 *   alignment: {x: string, y: string},
 * }}}
 */
export function positionComponent(yPosition, xPosition, zIndex, rotate, alignment) {
  const safeRotate = simplifyDegrees(rotate || 0);

  const safeAlignment = alignment || {};
  const alignY = ALIGNMENTS.y[safeAlignment.y] || 'top';
  const alignX = ALIGNMENTS.x[safeAlignment.x] || 'left';

  return {
    position: {
      y: yPosition || 0,
      x: xPosition || 0,
      yIsPercent: Boolean(String(yPosition).substr(-1) === '%'),
      xIsPercent: Boolean(String(xPosition).substr(-1) === '%'),
      rotate: safeRotate,
      zIndex: zIndex || Z_INDEX_OFFSET,
      alignment: {
        y: alignY,
        x: alignX,
      },
      default: {
        y: yPosition || 0,
        x: xPosition || 0,
        yIsPercent: Boolean(String(yPosition).substr(-1) === '%'),
        xIsPercent: Boolean(String(xPosition).substr(-1) === '%'),
        rotate: safeRotate,
        zIndex: zIndex || Z_INDEX_OFFSET,
      },
    }
  };
}

/**
 * Gets the position component from the source item.
 *
 * @param {{position: {}}} item
 * @param {number} order
 * @returns {{position: {y: number, x: number, zIndex: number}}}
 */
export function getPositionFromSource(item, order) {
  if (order === undefined) {
    throw new Error('positionComponent: getPositionFromSource requires an order.');
  }
  const itemSetup = item.setup || {};

  if (!itemSetup.position) {
    return {};
  }

  const position = itemSetup.position;
  const alignment = {
    y: ALIGNMENTS.y[position.alignY] || 'top',
    x: ALIGNMENTS.x[position.alignX] || 'left',
  };

  return positionComponent(
    position.y,
    position.x,
    orderToZIndex(order),
    position.rotate,
    alignment
  );
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {ObservableMap} entity
 * @param {GameStore} game
 * @returns {{}}
 */
export function getPositionForSource(entity, game) {
  if (!entity.has('position')) {
    return {};
  }

  const position = entity.get('position');
  const gameResolution = game.resolution;
  const decimalLimit = 4;

  const outPosition = {
    x: position.default.x,
    y: position.default.y,
  };
  if (position.default.xIsPercent) {
    outPosition.x = Number.parseFloat(position.x * TO_PERCENT / gameResolution.width).toFixed(decimalLimit) + '%';
  }
  if (position.default.yIsPercent) {
    outPosition.y = Number.parseFloat(position.y * TO_PERCENT / gameResolution.height).toFixed(decimalLimit) + '%';
  }

  outPosition.alignX = position.alignment.x;
  outPosition.alignY = position.alignment.y;
  outPosition.rotate = simplifyDegrees(position.rotate);

  return {
    setup: {
      position: outPosition,
    },
  };
}
