import classNames from 'classnames';
import lodash from 'lodash';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {InputGroup, InputGroupAddon, InputGroupText} from 'reactstrap';
import {AsyncTypeahead, Menu, MenuItem} from 'react-bootstrap-typeahead';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';

import inject from '../../hoc/injectHoc';
import {dashboardListRoute, dashboardSearchRoute} from '../../routePaths';
import {STATE_PENDING} from '../../../constants/asyncConstants';
import {CONTENT_LIST_COLLECTION} from '../../../constants/contentConstants';

import './searchBar.scss';

/**
 * The length of time (milliseconds) until a search request is made.
 *
 * @const {number}
 */
const SEARCH_TIMEOUT_LENGTH = 500;

/**
 * The number of results required before the see all results link is shown.
 *
 * @const {number}
 */
const SEE_ALL_IF_MORE_THAN = 0;

/**
 * The SearchBar component.
 */
export class SearchBar extends React.Component {
  /**
   * The search text.
   *
   * @type {string}
   */
  @observable searchText = '';

  /**
   * The typed in (input) search text.
   *
   * @type {string}
   */
  @observable inputValue = '';

  /**
   * The selected search text objects.
   *
   * @type {Array}
   */
  @observable selected = [];

  /**
   * Whether or not the search flow is debouncing.
   *
   * @type {boolean}
   */
  @observable isDebouncing = false;

  /**
   * Calls the content search request using Lodash's debounce.
   * Waits SEARCH_TIMEOUT_LENGTH milliseconds since the last request to fire.
   *
   * @type {function}
   */
  debouncedOnSearch = lodash.debounce(action('debouncedOnSearch', (searchValue) => {
    this.callSearch(searchValue);
    this.isDebouncing = false;
  }), SEARCH_TIMEOUT_LENGTH);

  /**
   * Triggered when an item is selected.
   *
   * @param {string} selected
   */
  @action onItemSelected = (selected) => {
    if (!selected || !selected.length) {
      return;
    }

    this.selected = selected;

    const safeSelected = selected[0];

    const {onItemSelected, routerStore} = this.props;

    if (onItemSelected) {
      onItemSelected(safeSelected);
      return;
    }

    if (safeSelected.id === 'search') {
      const safeSearchTerm = encodeURIComponent(safeSelected.searchTerm);

      // Forward to selected.name full search.
      routerStore.push({pathname: dashboardSearchRoute, search: `s=${safeSearchTerm}`}, {
        params: {
          contentId: '',
        },
      });
      return;
    }

    const firstCollection = safeSelected.collections[0];

    // Forward to selected.name full search.
    routerStore.push(dashboardListRoute, {params: {
      listType: CONTENT_LIST_COLLECTION,
      listId: firstCollection || 0,
      contentId: safeSelected.id,
    }});
  };

  /**
   * Gets new content based on the search term.
   *
   * @param {string} searchValue
   */
  @action onSearch = (searchValue) => {
    this.isDebouncing = true;

    this.inputValue = searchValue;

    this.debouncedOnSearch(searchValue);
  };

  /**
   * Triggered when the search input box changes.
   *
   * @param {string} newText
   */
  @action onInputChange = (newText) => {
    this.inputValue = newText;
  };

  /**
   * Gets new content based on the search term.
   *
   * @param {string} searchValue
   */
  @action callSearch = (searchValue) => {
    const {globalOnly, simple, apiContentSearchStore} = this.props;

    this.searchText = searchValue.replace(/[^a-zA-Z0-9_ -']/g, '');

    apiContentSearchStore.refresh(this.searchText, globalOnly, simple);
  };

  /**
   * Filters the content coming back from the server.
   *
   * @returns {boolean}
   */
  onItemsFilter = () => {
    // The server does the filtering, so show everything.
    return true;
  };

  /**
   * Triggered when the user presses a key while searching.
   *
   * @param {{}} keyDownEvent
   */
  onKeyDown = (keyDownEvent) => {
    if (String(keyDownEvent.key).toLowerCase() === 'enter') {
      this.onItemSelected([{
        id: 'search',
        searchTerm: this.inputValue,
      }]);
    }
  };

  /**
   * Submits the search term by forwarding to the dashboard.
   */
  onSearchClick = () => {
    this.onItemSelected([{
      id: 'search',
      searchTerm: this.inputValue,
    }]);
  };

  /**
   * Renders the typeahead menu using the preview data for each item.
   *
   * @param {Array} results
   * @param {{}} menuProps
   * @returns {{}}
   */
  renderPreviewMenu = (results, menuProps) => {
    return (
      <Menu {...menuProps}>
        {results.map((result, index) => (
          <MenuItem option={result} position={index} key={result.id}>
            {(result.thumbnailPath) && (
              <div className="menu-preview-image">
                <img alt="Preview" src={`'${result.thumbnailPath}'`} />
              </div>
            )}

            {result.name}
          </MenuItem>
        ))}
      </Menu>
    );
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      disabled,
      globalOnly,
      hideSubmit,
      simple,
      theme,
      apiContentSearchStore,
    } = this.props;

    const placeholder = this.props.placeholder || 'Search Templates';

    const searchKey = apiContentSearchStore.buildKey(this.searchText, globalOnly, simple);
    const isLoading = this.isDebouncing || apiContentSearchStore.getState(searchKey) === STATE_PENDING;

    const options = apiContentSearchStore.getFulfilled(searchKey) || [];

    const renderingOptions = {};
    if (!simple) {
      renderingOptions.renderMenu = this.renderPreviewMenu;
    }

    if (options.length && options.length >= SEE_ALL_IF_MORE_THAN) {
      options.push({
        id: 'search',
        searchTerm: this.searchText,
        name: 'See All Results',
      });
    }

    return (
      <div className={classNames('search-bar d-flex m-4', `theme-${theme}`)}>
        <InputGroup>
          {(hideSubmit) && (
            <InputGroupAddon addonType="prepend">
              <InputGroupText className="input-group-text search-prepend">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroupText>
            </InputGroupAddon>
          )}

          <AsyncTypeahead
            id="search-bar-input"
            className="input"
            isLoading={isLoading}
            onChange={this.onItemSelected}
            onSearch={this.onSearch}
            onInputChange={this.onInputChange}
            labelKey="name"
            disabled={disabled}
            placeholder={placeholder}
            options={options}
            flip={true}
            useCache={false}
            filterBy={this.onItemsFilter}
            onKeyDown={this.onKeyDown}
            {...renderingOptions}
          />

          {(!hideSubmit) && (
            <div className="input-group-append">
              <button
                type="button"
                className="btn btn-secondary search-btn"
                onClick={this.onSearchClick}
              >
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </div>
          )}
        </InputGroup>
      </div>
    );
  }
}

SearchBar.propTypes = {
  apiContentSearchStore: MobxPropTypes.observableObject,
  disabled: PropTypes.bool,
  globalOnly: PropTypes.bool,
  hideSubmit: PropTypes.bool,
  onItemSelected: PropTypes.func,
  placeholder: PropTypes.string,
  routerStore: MobxPropTypes.observableObject,
  simple: PropTypes.bool,
  theme: PropTypes.oneOf(['white', 'clear']),
};

SearchBar.defaultProps = {
  disabled: false,
  globalOnly: false,
  hideSubmit: false,
  simple: true,
  theme: 'clear',
};

SearchBar.wrappedComponent = {};
SearchBar.wrappedComponent.propTypes = {
  apiContentSearchStore: MobxPropTypes.observableObject.isRequired,
  routerStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(SearchBar)(
  observer(SearchBar)
);
