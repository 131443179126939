import {observer} from 'mobx-react';
import React from 'react';

import SignupForm from './components/signupForm/SignupForm';
import Link from '../../common/link/Link';
import WaveGraphic from '../../common/waveGraphic/WaveGraphic';
import TransitionWrapper from '../../layouts/transitionWrapper/TransitionWrapper';
import {privacyPolicyRoute} from '../../routePaths';
import logo from '../../../assets/images/horizontal-darkblue.png';
import {buildWordpressUrl} from '../../../utils/routeHelper';

import './signupPage.scss';

/**
 * The SignupPage component.
 */
export class SignupPage extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const homeLink = buildWordpressUrl();

    return (
      <div id="signup-page" className="system-page full-height">
        <div className="container">
          <div className="col-12 col-lg-5">
            <div className="row">
              <div className="col d-flex align-items-center justify-content-between">
                <a href={homeLink}>
                  <img className="logo" src={logo} alt="Logo" />
                </a>
              </div>
            </div>
            <TransitionWrapper>
              <div>
                <h2 className="mt-5 mb-4">Create Account.</h2>
                <SignupForm />
                <hr className="mt-5" />
                <div className="mt-3">
                  <small >By creating an account, you agree to our Terms of Service and
                  have read and understood the <Link to={privacyPolicyRoute}>Privacy Policy</Link>.</small>
                </div>
              </div>
            </TransitionWrapper>
          </div>
        </div>
        <WaveGraphic />
      </div>
    );
  }
}

export default observer(SignupPage);
