/**
 * The action position align component.
 * Sets the position alignment on a single entity.
 *
 * @param {string} axis
 * @param {string} alignment
 * @returns {{actionAlign: {alignment: string, axis: string}}}
 */
export function actionPositionAlignComponent(axis, alignment) {
  return {
    actionPositionAlign: {
      alignment,
      axis,
    },
  };
}
