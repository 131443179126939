import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import './thicknessSelector.scss';

/**
 * The default thickness to fall back to.
 * @const {string}
 */
const DEFAULT_THICKNESS = 1;

/**
 * The ThicknessSelector component.
 *
 * @param {{}} params
 * @param {boolean=} params.allowZero
 * @param {string=} params.id
 * @param {string} params.thicknessValue
 * @param {function(number)} params.onChangeThickness
 * @returns {React.Component}
 */
export const ThicknessSelector = ({allowZero, id, thicknessValue, onChangeThickness}) => {
  const values = observable({
    thickness: (thicknessValue !== undefined) ? thicknessValue : DEFAULT_THICKNESS
  });

  /**
   * Attempts to save the new value.
   *
   * @param {number} newValue
   */
  const save = (newValue) => {
    const newThickness = parseInt(newValue, 10);

    if (newThickness && newThickness > 0) {
      onChangeThickness(newThickness);
    } else if (allowZero) {
      onChangeThickness(0);
    }
  };

  /**
   * Stores the new thickness value.
   *
   * @param {{target: {value: string}}} changeEvent
   */
  const onChange = action((changeEvent) => {
    values.thickness = changeEvent.target.value;

    save(values.thickness);
  });

  /**
   * Sends updates with the new thickness value.
   *
   * @param {{target: {value: string}}} blurEvent
   */
  const onBlur = (blurEvent) => {
    save(blurEvent.target.value);
  };

  return (
    <div className="thickness-selector">
      <input
        type="number"
        id={id || 'thickness-selector-input'}
        className="form-control form-control-sm"
        value={values.thickness}
        onChange={onChange}
        onBlur={onBlur}
        min={allowZero ? '0' : '1'}
      />
    </div>
  );
};

ThicknessSelector.propTypes = {
  onChangeThickness: PropTypes.func.isRequired,

  allowZero: PropTypes.bool,
  id: PropTypes.string,
  thicknessValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ThicknessSelector.defaultProps = {
  allowZero: false
};

export default observer(ThicknessSelector);
