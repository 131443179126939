import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Modal, ModalBody, ModalFooter} from 'reactstrap';

import './previewImageModal.scss';

/**
 * The PreviewImageModal component.
 */
class PreviewImageModal extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
  */
  render() {
    const {isOpen, onCancel, onSubmitClick, url} = this.props;

    return (
      <Modal
        className="preview-image-modal"
        isOpen={isOpen}
        backdrop={true}
        toggle={onCancel}
        centered={true}
      >
        <ModalBody>
          <img
            className="w-100"
            src={url}
          />
        </ModalBody>
        <ModalFooter className="mt-0 p-3">
          <button
            type="button"
            className="btn btn-light"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-dark-blue"
            onClick={() => onSubmitClick(url)}
          >
            Use
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

PreviewImageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmitClick: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

export default observer(PreviewImageModal);
