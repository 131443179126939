import {action, observable, when, isObservable, toJS} from 'mobx';
import {includes} from 'lodash';

import {EXPIRES_IN, EXPIRE_TIME} from '../../../constants/storeConstants';
import {STATE_FULFILLED, STATE_PENDING, STATE_PRE, STATE_REJECTED} from '../../../constants/asyncConstants';
import {getCase} from '../../../utils/apiStore';

/**
 * ApiMapBaseStore
 */
class ApiMapBaseStore {
  /**
   * Map of the data in the store.
   *
   * @type {ObservableMap<string, {
   *   expireTime: ?Date,
   *   state: string,
   *   data: ?ObservableObject,
   *   error: ?Error
   * }>}
   */
  @observable dataMap = observable.map();

  /**
   * Gets the fulfilled value of an item in the store.
   * This is used in case().
   *
   * @param {string} id
   * @returns {?{}}
   */
  getFulfilled(id) {
    if (!id) {
      return null;
    }

    const safeId = String(id);

    const dataMapItem = this.dataMap.get(safeId);

    if (!dataMapItem || !dataMapItem.data) {
      return null;
    }

    return this.observableToJS(dataMapItem.data);
  }

  /**
   * Gets the rejected value of an item in the store.
   * This is used in case().
   *
   * @param {string} id
   * @returns {?Error}
   */
  getRejected(id) {
    if (!id) {
      return null;
    }

    const safeId = String(id);

    const dataMapItem = this.dataMap.get(safeId);

    if (!dataMapItem || !dataMapItem.error) {
      return null;
    }

    return this.observableToJS(dataMapItem.error);
  }

  /**
   * Gets the state of an item in the store.
   *
   * @param {string} id
   * @returns {string}
   */
  getState(id) {
    if (!id) {
      return STATE_PRE;
    }

    const safeId = String(id);

    const dataMapItem = this.dataMap.get(safeId);

    if (!dataMapItem) {
      return STATE_PRE;
    }

    return dataMapItem.state;
  }

  /**
   * Clears all data from the store
   */
  @action clearAll() {
    this.dataMap.clear();
  }

  /**
   * Clears a specific item from the store
   *
   * @param {string} id
   */
  @action clear(id) {
    const safeId = String(id);

    if (this.dataMap.has(safeId)) {
      this.dataMap.delete(safeId);
    }
  }

  /**
   * Replace a specific item in the store
   *
   * @param {string} id
   * @param {{}} data
   */
  @action replace(id, data) {
    const safeId = String(id);

    this.dataMap.set(safeId, {
      state: STATE_FULFILLED,
      [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
      data,
      error: null,
    });
  }

  /**
   * Ensures fresh content in the store
   *  Main method to use when you want to use this store
   *
   * @param {string} id
   * @param {boolean=} force Whether or not to force the refresh
   */
  refresh(id, force) {
    const safeId = String(id);
    if (force || !this.isItemAvailable(safeId)) {
      this.clear(id);

      this.makeRequest(id);
    }
  }

  /**
   * Checks if item is available in the store
   *  True if state = pending or fulfilled, and not expired
   *
   * @param {string} id
   * @returns {boolean}
   */
  isItemAvailable(id) {
    const safeId = String(id);
    const dataMapItem = this.dataMap.get(safeId);

    if (!dataMapItem) {
      return false;
    }

    const isValidState = includes([STATE_PENDING, STATE_FULFILLED], dataMapItem.state);
    const isNotExpired = (dataMapItem[EXPIRE_TIME] > Date.now());

    return (isValidState && isNotExpired);
  }

  /**
   * Makes the specified server request
   *  This should be overwritten in every class that extends this.
   *
   * @param {string} id
   */
  @action makeRequest(id) {
    const safeId = String(id);
    const notOverwrittenError = new Error(
      `The makeRequest function was not overwritten in the ${this.constructor.name} class declaration`
    );

    this.dataMap.set(safeId, {
      state: STATE_REJECTED,
      [EXPIRE_TIME]: null,
      data: null,
      error: notOverwrittenError,
    });

    throw notOverwrittenError;
  }

  /**
   * Runs handlers based on changes in the state.
   *
   * @param {string} id
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  case(id, handlers) {
    const safeId = String(id);
    const getFulfilled = () => this.getFulfilled(safeId);
    const getRejected = () => this.getRejected(safeId);

    const state = this.getState(safeId);
    return getCase(state, getFulfilled, getRejected, handlers);
  }

  /**
   * Gets a promise for this store.
   *
   * @param {string|number} id
   * @returns {Promise}
   */
  getPromise(id) {
    const safeId = String(id);

    return new Promise((resolve, reject) => {
      when(
        () => {
          const state = this.getState(safeId);
          return (state === STATE_FULFILLED || state === STATE_REJECTED);
        },
        () => {
          const state = this.getState(safeId);
          if (state === STATE_REJECTED) {
            reject(this.getRejected(safeId));
            return;
          }

          resolve(this.getFulfilled(safeId));
        },
        {name: `${this.constructor.name}GetPromise`}
      );
    });
  }

  /**
   * Changes an observable into a normal JS object.
   *
   * @param {*} item
   * @returns {*}
   */
  observableToJS = (item) => {
    if (!isObservable(item)) {
      return item;
    }
    return toJS(item);
  }
}

export default ApiMapBaseStore;
export const doNotInject = true;
