import {action, observable, when} from 'mobx';

import {STATE_FULFILLED, STATE_PENDING, STATE_PRE, STATE_REJECTED} from '../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../constants/storeConstants';
import {getCase} from '../../utils/apiStore';
import serverApi from '../../utils/serverApi';

/**
 * The EditorGetContentStore store.
 */
class EditorGetContentStore {
  /**
   * The map of each page of resources.
   *
   * @type {ObservableMap<string, {
   *   expireTime: number,
   *   state: Symbol,
   *   content: ?ObservableArray,
   *   library: ?ObservableArray,
   *   error: ?Error
   * }>}
   */
  @observable contentsById = observable.map();

  /**
   * Clears all the resource info
   */
  @action clearAll() {
    this.contentsById.clear();
  }

  /**
   * Gets content that was already fetched by its id.
   *
   * @param {number} contentId
   * @returns {{content: {}, library: {}}}
   */
  getContentById(contentId) {
    const safeContentId = String(contentId);
    const foundContent = this.contentsById.get(safeContentId);
    if (!foundContent) {
      return {
        content: null,
        library: null,
      };
    }

    return {
      content: foundContent.content || null,
      library: foundContent.library || null,
    };
  }

  /**
   * Gets the source for the content.
   *
   * @param {number} contentId
   * @returns {?{}}
   */
  getSource(contentId) {
    const safeContentId = String(contentId);

    const {content} = this.getContentById(safeContentId);

    try {
      return JSON.parse(content.contentFiles[0].createProjectJson);
    } catch (error) {
      return null;
    }
  }

  /**
   * Gets the fulfilled value of the store.
   * This is used in case().
   *
   * @param {number} contentId
   * @returns {?Array.<{}>}
   */
  getFulfilled(contentId) {
    return this.getContentById(contentId);
  }

  /**
   * Gets the rejected value of the store.
   * This is used in case().
   *
   * @param {number} contentId
   * @returns {?Error}
   */
  getRejected(contentId) {
    const safeContentId = String(contentId);
    const foundResource = this.contentsById.get(safeContentId);
    if (!foundResource || !foundResource.error) {
      return null;
    }
    return foundResource.error;
  }

  /**
   * Expires the cache for the given content id.
   *
   * @param {number} contentId
   */
  @action expireCacheForContent(contentId) {
    const safeContentId = String(contentId);

    this.contentsById.delete(safeContentId);
  }

  /**
   * Checks if content is already available via unexpired cache.
   *
   * @param {number} contentId
   * @returns {boolean}
   */
  @action isContentAvailable(contentId) {
    const safeContentId = String(contentId);

    const currentResource = this.contentsById.get(safeContentId);
    if (currentResource && currentResource.state === STATE_PENDING) {
      return true;
    }

    if (currentResource && currentResource[EXPIRE_TIME] <= Date.now()) {
      this.contentsById.delete(safeContentId);
    } else if (currentResource && currentResource.state !== STATE_REJECTED) {
      return true;
    }

    return false;
  }

  /**
   * Fetches content from the server.
   *
   * @param {number} contentId
   */
  @action fetchContentById(contentId) {
    const safeContentId = String(contentId);

    if (this.isContentAvailable(safeContentId)) {
      return;
    }

    this.contentsById.set(safeContentId, {
      [EXPIRE_TIME]: Date.now() + EXPIRES_PENDING,
      state: STATE_PENDING,
      content: null,
      library: null,
      error: null,
    });

    serverApi.contentGetById(safeContentId).then(
      action('getContentSuccess', (foundContent) => {
        this.contentsById.set(safeContentId, {
          [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
          state: STATE_FULFILLED,
          content: foundContent,
          library: null,
          error: null,
        });
      }),
      action('getContentError', (error) => {
        this.contentsById.set(safeContentId, {
          [EXPIRE_TIME]: null,
          state: STATE_REJECTED,
          content: null,
          error,
        });
      })
    );
  }

  /**
   * Runs handlers based on changes in the state.
   *
   * @param {number} contentId
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  case(contentId, handlers) {
    const safeContentId = String(contentId);

    const getFulfilled = () => {
      return this.getFulfilled(safeContentId);
    };
    const getRejected = () => {
      return this.getRejected(safeContentId);
    };

    let state = STATE_PRE;
    if (this.contentsById.has(safeContentId)) {
      state = this.contentsById.get(safeContentId).state;
    }

    return getCase(state, getFulfilled, getRejected, handlers);
  }

  /**
   * Gets a promise for this store.
   *
   * @param {string} contentId
   * @returns {Promise}
   */
  getPromise(contentId) {
    const safeContentId = String(contentId);

    const thisStore = this;

    return new Promise((resolve, reject) => {
      when(
        () => {
          const content = thisStore.contentsById.get(safeContentId);
          const state = (content) ? content.state : null;

          return (state === STATE_FULFILLED || state === STATE_REJECTED);
        },
        () => {
          const state = thisStore.contentsById.get(safeContentId).state;
          if (state === STATE_REJECTED) {
            reject(this.getRejected(safeContentId));
            return;
          }

          resolve(this.getFulfilled(safeContentId));
        },
        {name: 'editorGetContentStoreGetPromise'}
      );
    });
  }
}

export default new EditorGetContentStore();
