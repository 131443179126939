/* eslint-disable no-magic-numbers */

import lodash from 'lodash';

import {EASINGS} from '../../ecs/easingHelper';

/**
 * The name that signals this preset.
 * @const {string}
 */
export const PRESET_WORD_METEOR_OUT = 'wordMeteorOut';

/**
 * The preset details.
 *
 * @type {{
 *   name: string,
 *   flow: string,
 *   details: Object.<string, {
 *     easing: string,
 *     parse: boolean,
 *     values: *|Array.<*>,
 *     time: {start: (string|number), end: (string|number)}
 *   }>,
 *   template: {}
 * }}
 */
export const wordMeteorOutPresetDetails = {
  // The name of the preset.
  name: PRESET_WORD_METEOR_OUT,

  // A human friendly name for the preset.
  humanName: 'Word - Meteor Out',

  // The element names that this preset can be used for or true for all elements.
  elements: ['text'],

  // The preset direction: in, out, middle. This is used in the UI logic.
  flow: 'out',

  // Each property that will change during the transition.
  details: {
    'word.opacity': {
      // The name of the easing this property will use.
      easing: EASINGS.easeInExpo,

      // The start and end times for the transition of this property.
      time: {
        start: 'end.-2000',
        end: 'end.0',
      },

      // The value of the property that will change during the transition.
      values: [1, 0],

      // Whether or not to stagger the transition for each text or the stagger delay in milliseconds.
      stagger: true,

      // The amount of time in milliseconds that each text will animate, or false to auto-calculate.
      staggerDuration: false,

      // Do not immediately set properties. wait until animation starts
      immediateRender: false
    },
    'word.translateY': {
      // The name of the easing this property will use.
      easing: EASINGS.easeInExpo,

      // The start and end times for the transition of this property.
      time: {
        start: 'end.-2000',
        end: 'end.0',
      },

      // The value of the property that will change during the transition.
      values: [0, -100],

      // Whether or not to stagger the transition for each text or the stagger delay in milliseconds.
      stagger: true,

      // The amount of time in milliseconds that each text will animate, or false to auto-calculate.
      staggerDuration: false,

      // Do not immediately set properties. wait until animation starts
      immediateRender: false
    },
    'word.translateX': [{
      // The name of the easing this property will use.
      easing: EASINGS.easeInExpo,

      // The start and end times for the transition of this property.
      time: {
        start: 'end.-2000',
        end: 'end.0',
      },

      // The value of the property that will change during the transition.
      values: [0, -100],

      // Optional filters such as even, odd, etc.
      filters: ['even'],

      // Whether or not to stagger the transition for each text or the stagger delay in milliseconds.
      stagger: true,

      // The amount of time in milliseconds that each text will animate, or false to auto-calculate.
      staggerDuration: false,

      // Do not immediately set properties. wait until animation starts
      immediateRender: false
    }, {
      // The name of the easing this property will use.
      easing: EASINGS.easeInExpo,

      // The start and end times for the transition of this property.
      time: {
        start: 'end.-2000',
        end: 'end.0',
      },

      // The value of the property that will change during the transition.
      values: [0, 100],

      // Optional filters such as even, odd, etc.
      filters: ['odd'],

      // Whether or not to stagger the transition for each text or the stagger delay in milliseconds.
      stagger: true,

      // The amount of time in milliseconds that each text will animate, or false to auto-calculate.
      staggerDuration: false,

      // Do not immediately set properties. wait until animation starts
      immediateRender: false
    }],
  },

  // How this preset can be used in the source (including optional parameters).
  // This will be turned into a JSON string, so no functions can be used.
  template: {
    preset: PRESET_WORD_METEOR_OUT,
    options: {},
  },
};

wordMeteorOutPresetDetails.template.options = lodash.cloneDeep(wordMeteorOutPresetDetails.details);

// This defines the key in the presets object.
export const presetName = PRESET_WORD_METEOR_OUT;

// This defines the value of the presets object.
export default wordMeteorOutPresetDetails;
