import {action} from 'mobx';

import apiCompanySignGetAllStore from './apiCompanySignGetAllStore';
import ApiMapBaseStore from '../common/apiMapBaseStore';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';

/**
 * ApiSignUpdateStore
 */
class ApiSignUpdateStore extends ApiMapBaseStore {
  /**
   * Updates a sign through the api.
   *
   * @param {number} signId
   * @param {string} newSignData
   */
  @action makeRequest(signId, newSignData) {
    if (!signId) {
      this.state = STATE_REJECTED;
      this.error = new Error('Invalid sign id given to signUpdate.');
      return;
    }

    const safeSignId = String(signId);

    this.dataMap.set(safeSignId, {
      state: STATE_PENDING,
      [EXPIRE_TIME]: Date.now() + EXPIRES_PENDING,
      data: null,
      error: null,
    });

    serverApi.signUpdate(signId, newSignData).then(
      action('signUpdateSuccess', (wasSuccessful) => {
        this.dataMap.set(safeSignId, {
          state: STATE_FULFILLED,
          [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
          data: wasSuccessful,
          error: null,
        });

        apiCompanySignGetAllStore.refresh(true);
      }),
      action('signUpdateError', (signUpdateError) => {
        this.dataMap.set(safeSignId, {
          state: STATE_REJECTED,
          [EXPIRE_TIME]: null,
          data: null,
          error: signUpdateError,
        });
      })
    );
  }
}

export default new ApiSignUpdateStore();
