import {observable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import {Component} from 'react';

import mainConfig from '../../../config/main';

import {loadScriptFromSource} from '../../../utils/scriptHelper';

const CHARGEBEE_SCRIPT_URL = 'https://js.chargebee.com/v2/chargebee.js';

/**
 * The Chargebee Checkout Component.
 */
class ChargebeeCheckout extends Component {
  /**
   * The chargebee instance.
   * @type {null}
   */
  @observable chargebeeInstance = null;

  /**
   * Triggered when the component is first added to the repo
   */
  async componentDidMount() {
    await this.setChargebeeInstance();
  }

  /**
   * Triggered when the component updates
   */
  componentDidUpdate() {
    if (this.props.openCheckout && this.props.hostedPage !== null) {
      this.openCheckout();
    }
  }

  /**
   * Assigns the Chargebee Instance
   * @returns {Promise}
   */
  async setChargebeeInstance() {
    const wasLoaded = await loadScriptFromSource(CHARGEBEE_SCRIPT_URL);
    if (!wasLoaded) {
      return;
    }
    const Chargebee = window.Chargebee;
    this.chargebeeInstance = Chargebee.init({
      site: mainConfig.chargebee.site
    });
  }

  /**
   * Open the chargebee hosted page so the user can checkout
   */
  async openCheckout() {
    if (!this.chargebeeInstance) {
      await this.setChargebeeInstance();
    }

    this.chargebeeInstance.openCheckout({
      hostedPage: () => {
        return new Promise((resolve) => {
          resolve(this.props.hostedPage);
        });
      },
      success: () => {
        // TODO - find out why this is not working
        // this.props.onSuccessfulCheckout();
      },
      step: (value) => {
        if (value === 'thankyou_screen') {
          this.props.onSuccessfulCheckout();
        }
      }
    });
  }

  /**
   * Return a null "rendered" component so the Chargebee checkout will load
   *
   * @returns {function}
   */
  render() {
    return null;
  }
}

ChargebeeCheckout.propTypes = {
  onSuccessfulCheckout: PropTypes.func.isRequired,
  openCheckout: PropTypes.bool.isRequired,
  hostedPage: PropTypes.object,
};

export default observer(ChargebeeCheckout);
