import {action} from 'mobx';

import apiCompanySignGetAllStore from './apiCompanySignGetAllStore';
import apiAdminCompanySignGetAllStore from './apiAdminCompanySignGetAllStore';
import ApiMapBaseStore from '../common/apiMapBaseStore';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';

/**
 * ApiSignDeleteStore
 */
class ApiSignDeleteStore extends ApiMapBaseStore {
  /**
   * Deletes a sign through the api.
   *
   * @param {number} signId
   * @param {string} companyId
   */
  @action makeRequest(signId, companyId) {
    if (!signId) {
      this.state = STATE_REJECTED;
      this.error = new Error('Invalid sign id given to signDelete.');
      return;
    }

    const safeSignId = String(signId);

    this.dataMap.set(safeSignId, {
      state: STATE_PENDING,
      [EXPIRE_TIME]: Date.now() + EXPIRES_PENDING,
      data: null,
      error: null,
    });

    serverApi.signDelete(signId).then(
      action('signDeleteSuccess', (wasSuccessful) => {
        this.dataMap.set(safeSignId, {
          state: STATE_FULFILLED,
          [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
          data: wasSuccessful,
          error: null,
        });
        apiCompanySignGetAllStore.refresh(true);

        if (companyId) {
          apiAdminCompanySignGetAllStore.refresh(companyId, true);
        }
      }),
      action('signDeleteError', (signDeleteError) => {
        this.dataMap.set(safeSignId, {
          state: STATE_REJECTED,
          [EXPIRE_TIME]: null,
          data: null,
          error: signDeleteError,
        });

        apiCompanySignGetAllStore.refresh(true);
      })
    );
  }
}

export default new ApiSignDeleteStore();
