import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import SingleSlider from '../../common/singleSlider/SingleSlider';

import './timeControl.scss';

/**
 * The TimeControl component.
 *
 * @constructor
 */
export class TimeControl extends React.Component {
  /**
   * Sets the time level.
   *
   * @param {number} newTime
   */
  setTime = (newTime) => {
    let safeTime = parseInt(newTime, 10);
    if (!safeTime || safeTime < 1) {
      safeTime = 0;
    }

    this.props.timer.setTime(safeTime);

    if (this.props.onChangeTime) {
      this.props.onChangeTime(newTime);
    }
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const timer = this.props.timer;

    return (
      <div className="time-control">
        <SingleSlider
          className="time-slider"
          minValue={0}
          maxValue={timer.maxTime}
          value={timer.elapsedTime}
          step={10}
          onChange={this.setTime}
        />
      </div>
    );
  }
}

TimeControl.propTypes = {
  timer: MobxPropTypes.observableObject.isRequired,
  onChangeTime: PropTypes.func,
};

export default observer(TimeControl);
