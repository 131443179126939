import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import {observable, action, runInAction} from 'mobx';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import _throttle from 'lodash/throttle';
import React from 'react';
import {TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';

import {SCREEN_SIZE_MEDIUM} from '../../../../../constants/bootstrapConstants';
import {ALA_CARTE_CREDIT_LIST, ALA_CARTE_CREDITS} from '../../../../../constants/pricingConstants';
import {centsToDollars} from '../../../../../utils/mathHelper';
import inject from '../../../../hoc/injectHoc';
import AlaCarteItem from './AlaCarteItem';

import './alaCarte.scss';

/**
 * The AlaCarteTable component.
 */
class AlaCarteTable extends React.Component {
  /**
   * Whether the plans should be displayed in mobile view
   *
   * @type {boolean}
   */
  @observable isMobileView = false;

  /**
   * Item that is showing when in tabbed mobile view
   *
   * @type {string}
   */
  @observable activeItemTab = ALA_CARTE_CREDIT_LIST[0].id;

  /**
   * Credits for each A la Carte Item
   *
   * @type {{
   * download: number,
   * request: number
   * }}
   */
  @observable alaCarteCredits = {
    download: 0,
    request: 0,
  };

  /**
 * Determine if table should be in mobile view
 */
  checkWindowWidth = _throttle(() => {
    runInAction('setisMobileView', () => {
      const windowWidth = window.innerWidth;
      this.isMobileView = windowWidth < SCREEN_SIZE_MEDIUM;
    });
  // eslint-disable-next-line no-magic-numbers
  }, 500);

  /**
   * Triggered when the component first mounts to the page.
   */
  componentDidMount() {
    const {apiPlanGetAllStore, startingCredits, onPurchaseCredits} = this.props;

    apiPlanGetAllStore.refresh();

    if (startingCredits) {
      onPurchaseCredits(startingCredits);
    }

    // check window size immediately
    this.checkWindowWidth();
    window.addEventListener('resize', this.checkWindowWidth);
  }

  /**
   * Clean up window events to avoid memory leaks
   */
  @action componentWillUnmount() {
    window.removeEventListener('resize', this.checkWindowWidth);
  }

  /**
   * Selects the active item tab
   *
   * @param {number} itemId
   */
  @action selectActiveItemTab = (itemId) => {
    this.activeItemTab = itemId;
  }

  /**
   * Sets the credit quantities based on AlaCarteItem
   *
   * @param {string} itemId
   * @param {number} itemQty
   */
  @action updateAlaCarteCredits = (itemId, itemQty) => {
    if (typeof this.alaCarteCredits[itemId] !== 'undefined') {
      this.alaCarteCredits = {
        ...this.alaCarteCredits,
        [itemId]: itemQty,
      };
    }
  }

  /**
   * Initialize Credit quantity
   *
   * @param {string} itemCreditId Credit Id of the a la carte item being rendered
   * @returns {number}
   */
  initializeCredits = (itemCreditId) => {
    const {startingCredits} = this.props;

    if (startingCredits && startingCredits[itemCreditId]) {
      return startingCredits[itemCreditId];
    }

    return 0;
  }

  /**
   * Renders the mobile tab view of plans.
   *
   * @returns {{}}
   */
  renderPlanMobileView = () => {
    const {onPurchaseCredits} = this.props;
    const totalPriceCents = (this.alaCarteCredits.download * ALA_CARTE_CREDITS.download.pricePerCredit) + (this.alaCarteCredits.request * ALA_CARTE_CREDITS.request.pricePerCredit);

    return (
      <div className="mt-4 m-2">
        <Nav tabs className="item-list-tabs">
          {ALA_CARTE_CREDIT_LIST.map((item) => (
            <NavItem key={item.id}>
              <NavLink
                className={classNames({active: this.activeItemTab === item.id})}
                onClick={() => this.selectActiveItemTab(item.id)} key={item.id}
              >
                {item.name}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={this.activeItemTab} className="pt-3">
          {ALA_CARTE_CREDIT_LIST.map((item) => (<TabPane tabId={item.id} key={item.id}>
            <AlaCarteItem
              id={item.id}
              pricePerCredit={item.pricePerCredit}
              header={item.header}
              title={item.title}
              description={item.description}
              features={item.features}
              style={item.style}
              quantity={this.alaCarteCredits[item.id] || 0}
              updateCredit={this.updateAlaCarteCredits}
            />
          </TabPane>))}
        </TabContent>
        <div className="item-list-footer mt-4 text-center">
          <div>{this.alaCarteCredits.download} Downloads + {this.alaCarteCredits.request} Custom = Total {centsToDollars(totalPriceCents)}</div>
          <hr />
          <button
            type="button"
            className="btn item-select-button"
            onClick={() => onPurchaseCredits(this.alaCarteCredits)}
            disabled={false}
          >
            Purchase
          </button>
        </div>
      </div>
    );
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {onPurchaseCredits, disablePlanButtons} = this.props;

    return (
      <div className="container alacarte-table">
        {this.isMobileView ? this.renderPlanMobileView() : (
          <>
            <div className="row no-gutters">
              {ALA_CARTE_CREDIT_LIST.map((item) => {
                return (
                  <div key={item.id} className="col">
                    <AlaCarteItem
                      id={item.id}
                      pricePerCredit={item.pricePerCredit}
                      header={item.header}
                      title={item.title}
                      description={item.description}
                      features={item.features}
                      style={item.style}
                      quantity={this.alaCarteCredits[item.id] || 0}
                      updateCredit={this.updateAlaCarteCredits}
                    />
                  </div>
                );
              })}
            </div>
            <div className="row item-list-footer no-gutters">
              <button
                type="button"
                className="btn item-select-button"
                onClick={() => onPurchaseCredits(this.alaCarteCredits)}
                disabled={disablePlanButtons}
              >
                Purchase
              </button>
            </div>
          </>
        )}
      </div>
    );
  }
}

AlaCarteTable.propTypes = {
  apiPlanGetAllStore: MobxPropTypes.observableObject,
  disablePlanButtons: PropTypes.bool,
  onPurchaseCredits: PropTypes.func,
  startingCredits: PropTypes.object,
};

AlaCarteTable.wrappedComponent = {};
AlaCarteTable.wrappedComponent.propTypes = {
  apiPlanGetAllStore: MobxPropTypes.observableObject,
};

export default inject(AlaCarteTable)(
  observer(AlaCarteTable)
);
