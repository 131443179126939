import {action} from 'mobx';

import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import serverApi from '../../../utils/serverApi';
import ApiMapBaseStore from '../common/apiMapBaseStore';

/**
 * ApiCollectionGetOneStore
 */
class ApiCollectionGetOneStore extends ApiMapBaseStore {
  /**
   * Fetches collection information from the server.
   *
   * @param {string} collectionId
   */
  @action makeRequest(collectionId) {
    const safeCollectionId = String(collectionId);

    this.dataMap.set(safeCollectionId, {
      state: STATE_PENDING,
      [EXPIRE_TIME]: Date.now() + EXPIRES_PENDING,
      data: null,
      error: null,
    });

    serverApi.collectionGetOne(safeCollectionId).then(
      action('collectionGetOneSuccess', (foundRequest) => {
        this.dataMap.set(safeCollectionId, {
          state: STATE_FULFILLED,
          [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
          data: foundRequest,
          error: null,
        });
      }),
      action('collectionGetOneError', (collectionGetOneError) => {
        this.dataMap.set(safeCollectionId, {
          state: STATE_REJECTED,
          [EXPIRE_TIME]: null,
          data: null,
          error: collectionGetOneError,
        });
      })
    );
  }
}

export default new ApiCollectionGetOneStore();
