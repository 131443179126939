import lodash from 'lodash';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';
import {Progress, UncontrolledTooltip} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBan} from '@fortawesome/free-solid-svg-icons';

import inject from '../../hoc/injectHoc';
import {between} from '../../../utils/mathHelper';

import './fileUploadingProgress.scss';

/**
 * The maximum progress value.
 * @const {number}
 */
const MAX_PROGRESS = 90;

/**
 * The FileUploadingProgress component.
 *
 * @param {{
 *   apiFileUploadStore: ApiFileUploadStore,
 * }} props
 */
export class FileUploadingProgress extends React.Component {
  /**
   * Cancels the upload in progress for the given fileKey.
   *
   * @param {string} fileKey
   * @returns {function}
   */
  cancelUpload = (fileKey) => {
    return () => {
      const {apiFileUploadStore} = this.props;

      apiFileUploadStore.cancelUpload(fileKey);
    };
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {apiFileUploadStore} = this.props;

    if (!apiFileUploadStore.dataMap.size) {
      return (
        <div className="file-uploading-progress">
          <div className="uploading-file upload-empty">
            <h4 className="h5">All uploads completed.</h4>
          </div>
        </div>
      );
    }

    let renderFiles = [];
    apiFileUploadStore.dataMap.forEach((uploadData, fileKey) => {
      const uniqueKey = fileKey.replace(/[^a-zA-Z0-9]/ig, '');
      const progressValue = between(uploadData.progress, 0, MAX_PROGRESS);

      apiFileUploadStore.case(fileKey, {
        pending: () => {
          renderFiles.push(
            <div className="uploading-file upload-pending" key={uniqueKey}>
              <div className="file-name">{uploadData.data.name}</div>
              <div className="file-progress">
                <div className="file-progress-cancel">
                  <button
                    type="button"
                    className="btn btn-sm btn-link"
                    id={`cancel-${uniqueKey}`}
                    onClick={this.cancelUpload(fileKey)}
                  >
                    <FontAwesomeIcon
                      className="icon-bold"
                      icon={faBan}
                    />
                  </button>

                  <UncontrolledTooltip target={`cancel-${uniqueKey}`}>
                    Cancel Uploading of &quot;{uploadData.data.name}&quot;
                  </UncontrolledTooltip>
                </div>

                <Progress className="file-progress-bar" value={progressValue} striped={true} />
                <span className="file-progress-text">{progressValue}%</span>
              </div>
            </div>
          );
        },
        fulfilled: () => {
          renderFiles.push(
            <div className="uploading-file upload-fulfilled" key={uniqueKey}>
              <div className="file-name">{uploadData.data.name}</div>
              <div className="file-progress">
                <Progress className="file-progress-bar" value={100} />
                <span className="file-progress-text">100%</span>
              </div>
            </div>
          );
        },
        rejected: (uploadError) => {
          const wasCanceled = Boolean(uploadData.error.canceled);
          const fileErrorMsg = lodash.get(uploadError, 'fieldErrors.file') || lodash.get(uploadError, 'message');

          renderFiles.push(
            <div className="uploading-file upload-rejected" key={uniqueKey}>
              <div className="file-name">{uploadData.data.name}</div>

              {(!wasCanceled && fileErrorMsg) && (
                <div className="file-progress-error">
                  <span className="file-progress-text">Error: {fileErrorMsg}</span>
                </div>
              )}

              {(wasCanceled) ? (
                <div className="file-progress file-progress-canceled">
                  <Progress className="file-progress-bar" value={100} color="warning" />
                  <span className="file-progress-text">Canceled</span>
                </div>
              ) : (
                <div className="file-progress">
                  <Progress className="file-progress-bar" value={100} color="danger" />
                  <span className="file-progress-text">Error</span>
                </div>
              )}
            </div>
          );
        },
      });
    });

    return (
      <div className="file-uploading-progress">
        {renderFiles}
      </div>
    );
  }
}

FileUploadingProgress.propTypes = {
  apiFileUploadStore: MobxPropTypes.observableObject,
};

FileUploadingProgress.wrappedComponent = {};
FileUploadingProgress.wrappedComponent.propTypes = {
  apiFileUploadStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(FileUploadingProgress)(
  observer(FileUploadingProgress)
);
