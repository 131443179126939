/**
 * The list of custom font styles.
 *
 * @type {Object.<string, {}>}
 */
export const customExternalFontStyles = {
  'FONT_ALFASLABONE': {
    name: 'AlfaSlabOne',
    fontFamily: 'AlfaSlabOne, sans-serif',
  },
  'FONT_ANTON': {
    name: 'Anton',
    fontFamily: 'Anton, sans-serif',
  },
  'FONT_ARCHIVOBLACK': {
    name: 'ArchivoBlack',
    fontFamily: 'ArchivoBlack, sans-serif',
  },
  'FONT_ARVO': {
    name: 'Arvo',
    fontFamily: 'Arvo, sans-serif',
  },
  'FONT_AVENIRNEXTLTPROBOLD': {
    name: 'AvenirNextLTProBold',
    fontFamily: 'AvenirNextLTProBold, sans-serif',
  },
  'FONT_AVENIRNEXTLTPROBOLDCONDENSED': {
    name: 'AvenirNextLTProBoldCondensed',
    fontFamily: 'AvenirNextLTProBoldCondensed, sans-serif',
  },
  'FONT_AVENIRNEXTLTPRODEMI': {
    name: 'AvenirNextLTProDemi',
    fontFamily: 'AvenirNextLTProDemi, sans-serif',
  },
  'FONT_AVENIRNEXTLTPRODEMICONDENSED': {
    name: 'AvenirNextLTProDemiCondensed',
    fontFamily: 'AvenirNextLTProDemiCondensed, sans-serif',
  },
  'FONT_BALOO': {
    name: 'Baloo',
    fontFamily: 'Baloo, sans-serif',
  },
  'FONT_BANGERS': {
    name: 'Bangers',
    fontFamily: 'Bangers, sans-serif',
  },
  'FONT_BIORHYMEBOLD': {
    name: 'BioRhymeBold',
    fontFamily: 'BioRhymeBold, sans-serif',
  },
  'FONT_BITTERBOLD': {
    name: 'BitterBold',
    fontFamily: 'BitterBold, sans-serif',
  },
  'FONT_BLACKOPSONE': {
    name: 'BlackOpsOne',
    fontFamily: 'BlackOpsOne, sans-serif',
  },
  'FONT_BLANCHCAPS': {
    name: 'BlanchCaps',
    fontFamily: 'BlanchCaps, sans-serif',
  },
  'FONT_BOWLBYONE': {
    name: 'BowlbyOne',
    fontFamily: 'BowlbyOne, sans-serif',
  },
  'FONT_CARTONSLAB': {
    name: 'CartonSlab',
    fontFamily: 'CartonSlab, sans-serif',
  },
  'FONT_CHANGAONE': {
    name: 'ChangaOne',
    fontFamily: 'ChangaOne, sans-serif',
  },
  'FONT_CREEPSTER': {
    name: 'Creepster',
    fontFamily: 'Creepster, sans-serif',
  },
  'FONT_DELIUSUNICASEBOLD': {
    name: 'DeliusUnicaseBold',
    fontFamily: 'DeliusUnicaseBold, sans-serif',
  },
  'FONT_EDMONDSANSBOLD': {
    name: 'EdmondsansBold',
    fontFamily: 'EdmondsansBold, sans-serif',
  },
  'FONT_FIRASANSBLACK': {
    name: 'FiraSansBlack',
    fontFamily: 'FiraSansBlack, sans-serif',
  },
  'FONT_FIRASANSBOLD': {
    name: 'FiraSansBold',
    fontFamily: 'FiraSansBold, sans-serif',
  },
  'FONT_FIRASANSCONDENSEDBLACK': {
    name: 'FiraSansCondensedBlack',
    fontFamily: 'FiraSansCondensedBlack, sans-serif',
  },
  'FONT_FIRASANSCONDENSEDBOLD': {
    name: 'FiraSansCondensedBold',
    fontFamily: 'FiraSansCondensedBold, sans-serif',
  },
  'FONT_FIRASANSEXTRABOLD': {
    name: 'FiraSansExtraBold',
    fontFamily: 'FiraSansExtraBold, sans-serif',
  },
  'FONT_FIRASANSEXTRACONDENSEDBLACK': {
    name: 'FiraSansExtraCondensedBlack',
    fontFamily: 'FiraSansExtraCondensedBlack, sans-serif',
  },
  'FONT_FIRASANSEXTRACONDENSEDBOLD': {
    name: 'FiraSansExtraCondensedBold',
    fontFamily: 'FiraSansExtraCondensedBold, sans-serif',
  },
  'FONT_FRANKLINGOTHICDEMI': {
    name: 'FranklinGothicDemi',
    fontFamily: 'FranklinGothicDemi, sans-serif',
  },
  'FONT_FRANKLINGOTHICDEMICONDENSED': {
    name: 'FranklinGothicDemiCondensed',
    fontFamily: 'FranklinGothicDemiCondensed, sans-serif',
  },
  'FONT_FRANKLINGOTHICHEAVY': {
    name: 'FranklinGothicHeavy',
    fontFamily: 'FranklinGothicHeavy, sans-serif',
  },
  'FONT_FREDOKAONE': {
    name: 'FredokaOne',
    fontFamily: 'FredokaOne, sans-serif',
  },
  'FONT_FUTURASTDHEAVY': {
    name: 'FuturaStdHeavy',
    fontFamily: 'FuturaStdHeavy, sans-serif',
  },
  'FONT_GERMANIAONE': {
    name: 'GermaniaOne',
    fontFamily: 'GermaniaOne, sans-serif',
  },
  'FONT_GILSANSMTBOLD': {
    name: 'GilSansMTBold',
    fontFamily: 'GilSansMTBold, sans-serif',
  },
  'FONT_GILSANSMTEXTCONDENSEDBOLD': {
    name: 'GilSansMTExtCondensedBold',
    fontFamily: 'GilSansMTExtCondensedBold, sans-serif',
  },
  'FONT_GILSANSULTRABOLD': {
    name: 'GilSansUltraBold',
    fontFamily: 'GilSansUltraBold, sans-serif',
  },
  'FONT_GILSANSULTRABOLDCONDENSED': {
    name: 'GilSansUltraBoldCondensed',
    fontFamily: 'GilSansUltraBoldCondensed, sans-serif',
  },
  'FONT_GOTHAMBOLD': {
    name: 'GothamBold',
    fontFamily: 'GothamBold, sans-serif',
  },
  'FONT_GRAVITASONE': {
    name: 'GravitasOne',
    fontFamily: 'GravitasOne, sans-serif',
  },
  'FONT_HAETTENSCHWEILER': {
    name: 'Haettenschweiler',
    fontFamily: 'Haettenschweiler, sans-serif',
  },
  'FONT_HANALEIFILL': {
    name: 'HanaleiFill',
    fontFamily: 'HanaleiFill, sans-serif',
  },
  'FONT_HANDWRITTENCRYSTAL': {
    name: 'HandwrittenCrystal',
    fontFamily: 'HandwrittenCrystal, sans-serif',
  },
  'FONT_IMPACT': {
    name: 'Impact',
    fontFamily: 'Impact, sans-serif',
  },
  'FONT_INTRO': {
    name: 'Intro',
    fontFamily: 'Intro, sans-serif',
  },
  'FONT_KGHAPPYSOLID': {
    name: 'KGHappySolid',
    fontFamily: 'KGHappySolid, sans-serif',
  },
  'FONT_KALAMBOLD': {
    name: 'KalamBold',
    fontFamily: 'KalamBold, sans-serif',
  },
  'FONT_KELSONSANSBGBOLD': {
    name: 'KelsonSansBGBold',
    fontFamily: 'KelsonSansBGBold, sans-serif',
  },
  'FONT_KLINICSLABBOLD': {
    name: 'KlinicSlabBold',
    fontFamily: 'KlinicSlabBold, sans-serif',
  },
  'FONT_KNEWAVE': {
    name: 'Knewave',
    fontFamily: 'Knewave, sans-serif',
  },
  'FONT_LATOBLACK': {
    name: 'LatoBlack',
    fontFamily: 'LatoBlack, sans-serif',
  },
  'FONT_LATOBOLD': {
    name: 'LatoBold',
    fontFamily: 'LatoBold, sans-serif',
  },
  'FONT_LIBERATOR': {
    name: 'Liberator',
    fontFamily: 'Liberator, sans-serif',
  },
  'FONT_LOUISBOLD': {
    name: 'LouisBold',
    fontFamily: 'LouisBold, sans-serif',
  },
  'FONT_LUCKIESTGUY': {
    name: 'LuckiestGuy',
    fontFamily: 'LuckiestGuy, sans-serif',
  },
  'FONT_MERRIWEATHERBLACK': {
    name: 'MerriweatherBlack',
    fontFamily: 'MerriweatherBlack, sans-serif',
  },
  'FONT_MERRIWEATHERBOLD': {
    name: 'MerriweatherBold',
    fontFamily: 'MerriweatherBold, sans-serif',
  },
  'FONT_MODERNNOTWOZERO': {
    name: 'ModernNo20',
    fontFamily: 'ModernNo20, sans-serif',
  },
  'FONT_MOGRA': {
    name: 'Mogra',
    fontFamily: 'Mogra, sans-serif',
  },
  'FONT_MONTSERRATBLACK': {
    name: 'MontserratBlack',
    fontFamily: 'MontserratBlack, sans-serif',
  },
  'FONT_MONTSERRATBOLD': {
    name: 'MontserratBold',
    fontFamily: 'MontserratBold, sans-serif',
  },
  'FONT_MONTSERRATEXTRABOLD': {
    name: 'MontserratExtraBold',
    fontFamily: 'MontserratExtraBold, sans-serif',
  },
  'FONT_OLEOSCRIPT': {
    name: 'OleoScript',
    fontFamily: 'OleoScript, sans-serif',
  },
  'FONT_OPENSANSCONDENSEDBOLD': {
    name: 'OpenSansCondensedBold',
    fontFamily: 'OpenSansCondensedBold, sans-serif',
  },
  'FONT_OSWALD': {
    name: 'Oswald',
    fontFamily: 'Oswald, sans-serif',
  },
  'FONT_PACIFICO': {
    name: 'Pacifico',
    fontFamily: 'Pacifico, sans-serif',
  },
  'FONT_PASSIONONE': {
    name: 'PassionOne',
    fontFamily: 'PassionOne, sans-serif',
  },
  'FONT_PASSIONONEBLACK': {
    name: 'PassionOneBlack',
    fontFamily: 'PassionOneBlack, sans-serif',
  },
  'FONT_PERMANENTMARKER': {
    name: 'PermanentMarker',
    fontFamily: 'PermanentMarker, sans-serif',
  },
  'FONT_PIEDRA': {
    name: 'Piedra',
    fontFamily: 'Piedra, sans-serif',
  },
  'FONT_PIXELRAND': {
    name: 'PixelRand',
    fontFamily: 'PixelRand, sans-serif',
  },
  'FONT_POPPINSBLACK': {
    name: 'PoppinsBlack',
    fontFamily: 'PoppinsBlack, sans-serif',
  },
  'FONT_POPPINSBOLD': {
    name: 'PoppinsBold',
    fontFamily: 'PoppinsBold, sans-serif',
  },
  'FONT_POPPINSEXTRABOLD': {
    name: 'PoppinsExtraBold',
    fontFamily: 'PoppinsExtraBold, sans-serif',
  },
  'FONT_PROXIMANOVABOLD': {
    name: 'ProximaNovaBold',
    fontFamily: 'ProximaNovaBold, sans-serif',
  },
  'FONT_RALEWAYBLACK': {
    name: 'RalewayBlack',
    fontFamily: 'RalewayBlack, sans-serif',
  },
  'FONT_RALEWAYBOLD': {
    name: 'RalewayBold',
    fontFamily: 'RalewayBold, sans-serif',
  },
  'FONT_RIGHTEOUS': {
    name: 'Righteous',
    fontFamily: 'Righteous, sans-serif',
  },
  'FONT_ROBOTOBLACK': {
    name: 'RobotoBlack',
    fontFamily: 'RobotoBlack, sans-serif',
  },
  'FONT_ROBOTOBOLD': {
    name: 'RobotoBold',
    fontFamily: 'RobotoBold, sans-serif',
  },
  'FONT_ROBOTOCONDENSEDBOLD': {
    name: 'RobotoCondensedBold',
    fontFamily: 'RobotoCondensedBold, sans-serif',
  },
  'FONT_ROCKWELL': {
    name: 'Rockwell',
    fontFamily: 'Rockwell, sans-serif',
  },
  'FONT_ROCKWELLEXTRABOLD': {
    name: 'RockwellExtraBold',
    fontFamily: 'RockwellExtraBold, sans-serif',
  },
  'FONT_SEGOEUI': {
    name: 'SegoeUI',
    fontFamily: 'SegoeUI, sans-serif',
  },
  'FONT_SHOJUMARU': {
    name: 'Shojumaru',
    fontFamily: 'Shojumaru, sans-serif',
  },
  'FONT_SIGMARONE': {
    name: 'SigmarOne',
    fontFamily: 'SigmarOne, sans-serif',
  },
  'FONT_SPICYRICE': {
    name: 'SpicyRice',
    fontFamily: 'SpicyRice, sans-serif',
  },
  'FONT_STOLZL': {
    name: 'Stolzl',
    fontFamily: 'Stolzl, sans-serif',
  },
  'FONT_TITANONE': {
    name: 'TitanOne',
    fontFamily: 'TitanOne, sans-serif',
  },
  'FONT_THIRTYNINESTENCIL': {
    name: 'ThirtyNineStencil',
    fontFamily: 'ThirtyNineStencil, sans-serif',
  },
  'FONT_ZEICHEN': {
    name: 'Zeichen',
    fontFamily: 'Zeichen, sans-serif',
  },
};
