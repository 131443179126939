import lodash from 'lodash';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import * as resizeCorner from '../../../constants/resizeConstants';
import entityResizeHOC from '../../hoc/entityResizeHoc';

/**
 * The MovableCorner component.
 */
class MovableCorner extends React.Component {
  /**
   * Gets the cursor for the corner.
   * As the item rotates, updates the cursor to point in roughly the correct direction.
   *
   * @param {string} resizePosition
   * @returns {string}
   */
  getCursor(resizePosition) {
    const entity = this.props.entity;
    if (!entity) {
      // Don't do this logic for multiple entities, at least not yet...
      return (resizeCorner.CURSORS[resizePosition] || null);
    }

    const currentPosition = entity.get('position');
    const rotate = (currentPosition && currentPosition.rotate) ? currentPosition.rotate : 0;

    const step = 22.5;
    const rotationOffset = Math.ceil(Math.floor(rotate / step) / 2);
    if (!rotationOffset) {
      return (resizeCorner.CURSORS[resizePosition] || null);
    }

    const rotatedCursors = resizeCorner.ROTATED_CURSORS;
    const startIndex = lodash.findIndex(rotatedCursors, {position: resizePosition});
    if (startIndex < 0) {
      return null;
    }

    const newIndex = (startIndex + rotationOffset) % rotatedCursors.length;

    return rotatedCursors[newIndex].cursor;
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {fillColor, resizePosition, size, startX, startY, strokeColor, strokeWidth} = this.props;

    const style = {
      cursor: this.getCursor(resizePosition),
    };

    const defaultSize = 10;
    const safeSize = size || defaultSize;

    const path = [
      `M ${startX},${startY}`,
      `H ${startX + safeSize}`,
      `V ${startY + safeSize}`,
      `H ${startX}`,
      'Z',
    ].join(' ');

    return (
      <path
        className="movable-corner"
        fill={fillColor}
        fillOpacity="1"
        stroke={strokeColor}
        strokeOpacity="1"
        strokeWidth={strokeWidth}
        strokeLinecap="square"
        strokeLinejoin="miter"
        strokeMiterlimit="10"
        pointerEvents="visiblePainted"
        d={path}
        style={style}
      />
    );
  }
}

MovableCorner.propTypes = {
  fillColor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  game: MobxPropTypes.observableObject.isRequired,
  resizePosition: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  startX: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  startY: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  strokeColor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

  entities: PropTypes.arrayOf(MobxPropTypes.observableMap),
  entity: MobxPropTypes.observableMap,
  isRotated: PropTypes.bool,
  strokeWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

MovableCorner.defaultProps = {
  strokeWidth: 1,
};

export default entityResizeHOC(
  observer(MovableCorner)
);
