/**
 * Content file state ID indicating the content file is currently uploading.
 * @const {number}
 */
export const UPLOADING = 1;

/**
 * Content file state ID indicating the content file is queued for processing.
 * @const {number}
 */
export const QUEUED = 2;

/**
 * Content file state ID indicating the content file is in process.
 * @const {number}
 */
export const PROCESSING = 3;

/**
 * Content file state ID indicating the content file is ready.
 * @const {number}
 */
export const READY = 4;

/**
 * Content file state ID indicating the content file has errored.
 * @const {number}
 */
export const ERROR = 5;

/**
 * Content file state ID indicating the content file is new and has not been processed yet.
 * @type {number}
 */
export const NEW = 6;

/**
 * The list of content file state IDs that make up "In Process" files.
 * @const {number[]}
 */
export const IN_PROCESS = [
  NEW,
  UPLOADING,
  QUEUED,
  PROCESSING,
];

/**
 * The list of content file state IDs that indicate whether or not the content can be rendered.
 * @const {number[]}
 */
export const CAN_NOT_RENDER = [
  UPLOADING,
  QUEUED,
  PROCESSING,
];

/**
 * The list of content file state IDs that indicate whether or not the content needs more polling.
 * @const {number[]}
 */
export const POLLING_CONTINUES = [
  UPLOADING,
  QUEUED,
  PROCESSING,
];

/**
 * The collection content list type.
 * @const {string}
 */
export const CONTENT_LIST_COLLECTION = 'collection';

/**
 * The folder content list type.
 * @const {string}
 */
export const CONTENT_LIST_FOLDER = 'folder';

/**
 * The search list type.
 * @const {string}
 */
export const CONTENT_LIST_SEARCH = 'search';
