import interact from 'interactjs/src/index';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {findDOMNode} from 'react-dom';

import {actionCropComponent} from '../../display/components/action/actionCropComponent';
import {actionUpdateComponent} from '../../display/components/action/actionUpdateComponent';
import {interactionComponent} from '../../display/components/common/interactionComponent';
import {getIsCroppingLocked, getIsEntityTransitioning, getScaleFromElement} from '../../utils/dragDropHelper';

/**
 * Crops the entity.
 *
 * @param {ObservableMap} entity
 * @param {GameStore} game
 * @param {{top: number, left: number}} delta
 */
function moveCropEntity(entity, game, delta) {
  game.addAction({entityId: entity.get('id')}, actionCropComponent(
    delta || null,
    false
  ));
}

/**
 * Gets whether or not the entity is active.
 *
 * @param {ObservableMap} entity
 * @returns {boolean}
 */
function getIsActive(entity) {
  const interaction = entity.get('interaction');

  if (getIsCroppingLocked(entity)) {
    return false;
  }

  return Boolean(interaction && interaction.isActive);
}

/**
 * A higher order component wrapper that handles making an entity croppable.
 *
 * @param {Object} WrappedComponent
 * @returns {Object}
 */
export default function entityCropMoveHocWrapper(WrappedComponent) {
  /**
   * The EntityCropMoveHoc higher order component.
   */
  class EntityCropMoveHoc extends React.Component {
    /**
     * Whether or not this entity is currently active.
     * Used to track the change.
     *
     * @type {boolean}
     */
    @observable isActive = false;

    /**
     * The DOM element for the element.
     *
     * @type {HTMLElement}
     */
    @observable domEl = null;

    /**
     * The DOM element for the display element.
     *
     * @type {HTMLElement}
     */
    @observable displayEl = null;

    /**
     * The interactJS object initialized on the DOM element.
     *
     * @type {{draggable: function, resizable: function}}
     */
    @observable interaction = null;

    /**
     * Triggered right after the component mounts to the page.
     */
    componentDidMount() {
      this.checkActiveStateChange();
    }

    /**
     * Triggered when the component is about to unmount.
     */
    componentWillUnmount() {
      this.stopInteraction();
    }

    /**
     * Triggered when an observed item updates.
     */
    componentWillReact = () => {
      this.checkActiveStateChange();
    };

    /**
     * Checks whether or not the active state has changed and either allows it to be dragged or stops it from
     * dragging.
     */
    @action checkActiveStateChange = () => {
      const isActive = getIsActive(this.props.entity);

      if (!this.isActive && isActive) {
        this.initInteraction();
        this.isActive = true;
      } else if (this.isActive && !isActive) {
        this.stopInteraction();
        this.isActive = false;
      }
    };

    /**
     * Triggered right after the wrapped component is added to OR removed from the page.
     *
     * @param {{}} domEl
     */
    @action onChangeMount = (domEl) => {
      if (this.domEl) {
        return;
      }

      this.domEl = findDOMNode(domEl);
      this.displayEl = document.getElementById('display-crop');
    };

    /**
     * Handles the drag move event.
     *
     * @param {{dx: number, dy: number}} dragEvent
     */
    onMove = (dragEvent) => {
      const entity = this.props.entity;
      const game = this.props.game;

      if (getIsEntityTransitioning(entity, game.timer.elapsedTime, ['position'])) {
        // TODO: Indicate to the user why dragging isn't working.
        return;
      }

      const scale = getScaleFromElement(this.displayEl);
      const dy = (dragEvent.dy / scale);
      const dx = (dragEvent.dx / scale);

      moveCropEntity(entity, game, {
        y: dy,
        x: dx,
        width: 0,
        height: 0,
      });
    };

    /**
     * Handles the double click event.
     */
    onDoubleClick = () => {
      const entity = this.props.entity;
      const game = this.props.game;

      if (entity.get('element') !== 'image') {
        return;
      }

      const currentInteraction = entity.get('interaction');
      if (!currentInteraction.isActive) {
        return;
      }

      const newIsCropping = !currentInteraction.isCropping;

      const actionParams = {
        entityId: entity.get('id'),
      };

      game.addAction(actionParams, actionUpdateComponent(
        interactionComponent(true, newIsCropping)
      ));
    };

    /**
     * Starts the interactJS code.
     */
    @action initInteraction = () => {
      const {entity} = this.props;

      if (this.interaction) {
        // Make sure we don't have multiple dragging interactions on this element.
        this.interaction.unset();
      }

      if (getIsCroppingLocked(entity)) {
        return;
      }

      const interaction = interact(this.domEl);
      interaction.draggable({
        onmove: this.onMove,
      });

      interaction.on('doubletap', this.onDoubleClick);

      this.interaction = interaction;
    };

    /**
     * Unbinds the dragging code.
     */
    @action stopInteraction = () => {
      if (!this.interaction) {
        return;
      }

      this.interaction.unset();
      this.interaction = null;
    };

    /**
     * Renders the WrappedComponent.
     *
     * @returns {Object}
     */
    render() {
      if (!WrappedComponent) {
        return null;
      }

      if (this.props.ref) {
        throw new Error('EntityCropMoveHoc will override ref property given to the wrapped component.');
      }

      return (
        <WrappedComponent
          {...this.props}
          ref={this.onChangeMount}
        />
      );
    }
  }

  EntityCropMoveHoc.propTypes = {
    entity: MobxPropTypes.observableMap.isRequired,
    game: PropTypes.shape({
      setEntityComponents: PropTypes.func,
    }).isRequired,
    ref: PropTypes.func,
  };

  return observer(EntityCropMoveHoc);
}
