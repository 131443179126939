/**
 * Defines the route path for each page.
 */
export const homeRoute = '/';
export const dashboardRoute = '/dashboard';
export const dashboardListRoute = '/dashboard/:listType?/:listId?/:contentId?';
export const dashboardSearchRoute = '/dashboard/search/:contentId?';
export const editorRoute = '/editor/:contentId';
export const privacyPolicyRoute = '/privacy';
export const signupRoute = '/signup';
export const signupLinkRoute = '/signup-link';
export const loginRoute = '/login';
export const onboardingRoute = '/onboarding/:planId?/:downloadCreditQty?/:requestCreditQty?';
export const userPaymentRoute = '/payment/:planId?/:downloadCreditQty?/:requestCreditQty?';
export const userPaymentRouteNoPlanId = '/payment';
export const pricingRoute = '/pricing';
export const logoutRoute = '/logout';
export const prestoRoute = '/presto/:contentId';
export const signsRoute = '/signs';
export const renderRoute = '/render';
export const adminContentRequestsListRoute = '/admin/content-requests';
export const adminContentRequestRoute = '/admin/content-request/:requestId';
export const adminCompanyEditSignsRoute = '/admin/signs/:companyId';
export const adminBannersListRoute = '/admin/banners';
export const adminBannerEditRoute = '/admin/banner/:bannerId';
