import classNames from 'classnames';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import entityActivateHoc from '../../hoc/entityActivateHoc';

import './displayRectangle.scss';
import {onClickEntityEvent} from '../utils';

/**
 * The DisplayRectangle component.
 */
export class DisplayRectangle extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, style, topStyle, className, onEntityClick, game} = this.props;

    const entityId = entity.get('id');

    const rectangle = entity.get('rectangle');
    const rectangleStyle = rectangle.style;
    const rectangleBorder = rectangle.border;

    const width = parseInt(style.width, 10);
    const height = parseInt(style.height, 10);

    const rounded = rectangleStyle.rounded || 0;
    const borderSize = parseInt(rectangleBorder.width, 10) || 0;
    const halfBorderSize = borderSize / 2;

    const onClickEvent = onClickEntityEvent(game, entity, onEntityClick);
    return (
      <div
        id={entityId}
        className={classNames('display-rectangle', className)}
        style={{
          ...topStyle,
          pointerEvents: 'none',
        }}
      >
        <div style={style}>
          <svg viewBox={`0 0 ${width} ${height}`} className="display-rectangle-svg">
            <rect
              x={halfBorderSize}
              y={halfBorderSize}
              rx={rounded}
              ry={rounded}
              width={width - borderSize}
              height={height - borderSize}
              fill={rectangleStyle.color}
              fillOpacity={rectangleStyle.opacity}
              stroke={rectangleBorder.color}
              strokeOpacity={rectangleBorder.opacity}
              strokeWidth={borderSize}
              style={{
                cursor: onClickEvent ? 'pointer' : 'inherit',
                pointerEvents: 'visible',
              }}
              onClick={onClickEvent}
            />
          </svg>
        </div>
      </div>
    );
  }
}

DisplayRectangle.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
  style: PropTypes.object.isRequired,
  topStyle: PropTypes.object.isRequired,

  className: PropTypes.string,
  onEntityClick: PropTypes.func,
};

export default entityActivateHoc(
  observer(DisplayRectangle)
);
