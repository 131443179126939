import React from 'react';

import './waveGraphic.scss';
import classNames from 'classnames';
import propTypes from 'prop-types';

/**
 * The wave graphic component used on the login/signup pages.
 *
 * @returns {{}}
 */
const WaveGraphic = ({
  waveGraphicClass
}) => {
  return (
    <div className={classNames('wave-graphic', waveGraphicClass)}>
      <div className="wave" />
      <div className="wave" />
    </div>
  );
};

WaveGraphic.propTypes = {
  waveGraphicClass: propTypes.string,
};

export default WaveGraphic;
