import lodash from 'lodash';

import {EASINGS} from '../../ecs/easingHelper';
import overshootInTopGif from '../../../assets/images/transitions/overshootInTop.gif';

/**
 * The name that signals this preset.
 * @const {string}
 */
export const PRESET_OVERSHOOT_OUT_TOP = 'overshootOutTop';

/**
 * The preset details.
 *
 * @type {{
 *   name: string,
 *   humanName: string,
 *   flow: string,
 *   details: Object.<string, {
 *     easing: string,
 *     parse: boolean,
 *     values: *|Array.<*>,
 *     time: {start: (string|number), end: (string|number)}
 *   }>,
 *   template: {}
 * }}
 */
export const overshootOutTopPresetDetails = {
  // The name of the preset.
  name: PRESET_OVERSHOOT_OUT_TOP,

  // A human friendly name for the preset.
  humanName: 'Overshoot Out to the Top',

  // A preview of the transition.
  gif: overshootInTopGif,

  // The element names that this preset can be used for or true for all elements.
  elements: true,

  // The preset direction: in, out, middle. This is used in the UI logic.
  flow: 'out',

  // Each property that will change during the transition.
  details: {
    'position.y': {
      // The name of the easing this property will use.
      easing: EASINGS.easeInBack,

      // The start and end times for the transition of this property.
      time: {
        end: 'end.0',
        start: 'end.-653'
      },

      // The value of the property that will change during the transition.
      values: [
        'position.top',
        'off.top'
      ],
      immediateRender: false
    },
  },

  // How this preset can be used in the source (including optional parameters).
  // This will be turned into a JSON string, so no functions can be used.
  template: {
    preset: PRESET_OVERSHOOT_OUT_TOP,
    options: {},
  },
};

overshootOutTopPresetDetails.template.options = lodash.cloneDeep(overshootOutTopPresetDetails.details);

// This defines the key in the presets object.
export const presetName = PRESET_OVERSHOOT_OUT_TOP;

// This defines the value of the presets object.
export default overshootOutTopPresetDetails;
