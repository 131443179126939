import {action, observable, computed} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import ReactSelect from 'react-select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlay} from '@fortawesome/free-solid-svg-icons';
import {faFileImage} from '@fortawesome/free-regular-svg-icons';

import inject from '../../hoc/injectHoc';
import {STATE_PENDING, STATE_PRE} from '../../../constants/asyncConstants';
import {FILE_TYPE_AVI, FILE_TYPE_JPEG, FILE_TYPE_JPG, FILE_TYPE_MP4, FILE_TYPE_MPEG, FILE_TYPE_PNG, FILE_TYPE_WMV} from '../../../constants/fileTypeConstants';
import {SIGN_SELECT_OPTION_RENDERER, SIGN_SELECT_VALUE_RENDERER} from '../shared/utils';

import '../shared/sign-select.scss';

const RENDER_FILE_OPTIONS = [
  {
    label: 'Video',
    icon: faPlay,
    options: [
      {value: FILE_TYPE_AVI, label: 'avi'},
      {value: FILE_TYPE_MP4, label: 'mp4'},
      {value: FILE_TYPE_MPEG, label: 'mpeg'},
      {value: FILE_TYPE_WMV, label: 'wmv'},
    ]
  },
  {
    label: 'Image',
    icon: faFileImage,
    options: [
      {value: FILE_TYPE_JPEG, label: 'jpeg'},
      {value: FILE_TYPE_PNG, label: 'png'},
    ],
  }
];

/**
 * The SelectSignAndFileTypeModal component.
 */
export class SelectSignAndFileTypeModal extends React.Component {
  /**
   * The selected sign id.
   *
   * @type {?number}
   */
  @observable signId = null;

  /**
   * File Type Options
   *
   */
  @observable fileTypeOptions = RENDER_FILE_OPTIONS;

  /**
   * The desired file type.
   * default to mp4
   *
   * @type {string}
   */
  @observable fileType = FILE_TYPE_MP4;

  /**
   * User's signs
   *
   * @type {}
   */
  @observable signOptions = [];

  /**
   * User sign that includes downloadable info
   *
   * @type {array}
   */
  @observable userSigns = [];

  /**
   * Triggered when the component just mounted onto the page.
   */
  @action componentDidMount() {
    if (this.props.isOpen) {
      this.initializeModal();
    }
  }

  /**
   * Triggered when the component has just updated.
   *
   * @param {{isOpen: boolean}} prevProps
   */
  @action componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.initializeModal();
    }
  }

  /**
   * Confirm button text
   *
   * @type {string}
   */
  @computed get confirmButtonText() {
    const {allowDownloadExistingRenders} = this.props;

    if (!allowDownloadExistingRenders) {
      return 'Create';
    }

    const matchingSign = this.userSigns.find((sign) => sign.id === this.signId);

    if (!matchingSign) {
      return 'Create';
    }
    if (matchingSign.render && matchingSign.render.status === 'processing') {
      return 'Creating...';
    }
    if (matchingSign.render && matchingSign.render.status === 'error') {
      return 'Create';
    }
    if (matchingSign.render && matchingSign.render.status === 'ready') {
      return 'Download';
    }

    return 'Create';
  }

  /**
   * Whether or not to disable the 'Ok' button
   */
  @computed get isDisabled() {
    const {hideSignDropdown} = this.props;

    if (this.confirmButtonText === 'Creating...') {
      return true;
    }

    // if hide sign dropdown, only disable if fileType is not chosen
    if (hideSignDropdown) {
      return !this.fileType;
    }

    // else disable if sign or fileType is not chosen
    return (!this.signId || !this.fileType);
  }

  /**
   * Initializes the modal.
   */
  @action initializeModal = () => {
    const {content, apiContentRenderGetDownloadableStore, preselectedSignId} = this.props;

    apiContentRenderGetDownloadableStore.refresh(content.id, true);
    apiContentRenderGetDownloadableStore.getPromise(content.id).then(action('apiContentRenderGetDownloadableStore getPromise', (userSigns) => {
      this.userSigns = userSigns;
      const [firstSign] = userSigns;

      if (preselectedSignId) {
        this.onSignChange({
          value: preselectedSignId,
        });
      } else if (firstSign) {
        this.onSignChange({
          value: firstSign.id,
        });
      }
    }));
  };

  /**
   * Tears down the modal.
   */
  tearDownModal = () => {
    // Do something.
  };

  /**
   * Triggered when the modal is closed without a finishing.
   */
  onCancelModal = () => {
    const {onComplete} = this.props;

    onComplete({
      signId: null,
      fileType: null,
      confirmAction: null
    });

    this.tearDownModal();
  };

  /**
   * Triggered when the modal is closed after choosing a folder.
   */
  @action onCompleteModal = async () => {
    const {content, onComplete} = this.props;

    onComplete({
      content,
      signId: this.signId,
      fileType: this.fileType,
      confirmAction: this.confirmButtonText
    });

    this.tearDownModal();
  };

  /**
   * Triggered when the user changes file type
   */
  @action onFileTypeChange = ({value}) => {
    this.fileType = value;

    if (this.props.onChange) {
      this.props.onChange({
        signId: this.signId,
        fileType: this.fileType
      });
    }
  }

  /**
   * Triggered when the user changes sign selection
   */
  @action onSignChange = ({value}) => {
    const {allowDownloadExistingRenders, onChange} = this.props;
    this.signId = value;

    if (onChange) {
      onChange({
        signId: this.signId,
        fileType: this.fileType
      });
    }

    const matchingSign = this.userSigns.find((sign) => sign.id === this.signId);
    const downloadableFile = matchingSign
      ? matchingSign.file
      : null;

    // if modal does not allow downloading existing renders
    //  and existing file exists
    //  and existing file has extension
    // preselect existing file extension
    if (!allowDownloadExistingRenders
      && downloadableFile
      && downloadableFile.ext
    ) {
      this.fileTypeOptions = RENDER_FILE_OPTIONS;
      this.onFileTypeChange({
        value: downloadableFile.ext,
      });
      return;
    }

    // if modal does not allow downloading existing renders
    //  and existing file does not exist
    //   or file exists but does not have extension
    // preslect to mp4
    if (!allowDownloadExistingRenders
        && (
          !downloadableFile
          || (downloadableFile && !downloadableFile.ext) // this check is needed because the file can exist but be an empty object
        )
    ) {
      this.fileTypeOptions = RENDER_FILE_OPTIONS;
      this.onFileTypeChange({
        value: FILE_TYPE_MP4,
      });
      return;
    }

    // if file exists
    //  and file has extension
    //  and file extension is image
    // preselect to download image
    if (downloadableFile
      && downloadableFile.ext
      && ([FILE_TYPE_JPEG, FILE_TYPE_JPG, FILE_TYPE_PNG]).includes(downloadableFile.ext)
    ) {
      this.fileTypeOptions = this.fileTypeOptions = [
        {value: 'image', label: 'Image'},
      ];
      this.onFileTypeChange({
        value: 'image',
      });
      return;
    }

    // if file exists
    //  and file has extension
    // preselect to download video
    if (downloadableFile
      && downloadableFile.ext
    ) {
      this.fileTypeOptions = [
        {value: 'video', label: 'Video'},
        {value: 'thumbnail', label: 'Image'}
      ];
      this.onFileTypeChange({
        value: 'video',
      });
      return;
    }

    // default to mp4
    this.fileTypeOptions = RENDER_FILE_OPTIONS;
    this.onFileTypeChange({
      value: FILE_TYPE_MP4,
    });
  }

  /**
   * Renders the sign dropdown
   *
   * @param {boolean} signsAreLoading
   * @param {array} signOptions
   * @param {number} signId
   * @param {boolean} showNoSignsWarning
   * @returns {ReactElement}
   */
  @action renderSignDropdown = (
    signsAreLoading,
    signOptions,
    signId,
    showNoSignsWarning
  ) => {
    const {
      disableSignDropdown
    } = this.props;

    return (
      <>
        <ReactSelect
          id="user-sign-select"
          className="theme-light sign-select"
          classNamePrefix="react-select"
          isClearable={false}
          isMulti={false}
          isSearchable={false}
          isLoading={signsAreLoading}
          isDisabled={showNoSignsWarning || disableSignDropdown}
          onChange={this.onSignChange}
          options={signOptions}
          value={signOptions.find((option) => option.value === signId)}
          components={{
            Option: SIGN_SELECT_OPTION_RENDERER,
            SingleValue: SIGN_SELECT_VALUE_RENDERER,
          }}
        />
        {(showNoSignsWarning) && (<p className="text-dark">
            To download, please add a sign in your user menu.
        </p>)}
        </>
    );
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      isOpen,
      hideSignDropdown,
      content,
      modalBodyUpperSlot,
      modalBodyLowerSlot,
      modalHeaderText,
      apiContentRenderGetDownloadableStore,
    } = this.props;

    if (!isOpen) {
      return null;
    }

    const getSignsState = apiContentRenderGetDownloadableStore.getState(content.id);
    const signsAreLoading = getSignsState === STATE_PENDING || getSignsState === STATE_PRE;
    const fileTypeOptions = this.fileTypeOptions.toJSON();

    let signOptions = [];
    let showNoSignsWarning = false;

    if (!signsAreLoading) {
      const userSigns = apiContentRenderGetDownloadableStore.getFulfilled(content.id);

      // transform data for sign select
      signOptions = userSigns.map((item) => ({
        value: item.id,
        label: item.name,
        sign: {
          id: item.id,
          name: item.name,
          width: item.width,
          height: item.height,
          aspectRatio: item.aspectRatio,
        },
      }));

      if (signOptions.length === 0) {
        // if no signs available, show warning
        showNoSignsWarning = true;
      }
    }

    const selectedFileTypeOption = fileTypeOptions[0] && fileTypeOptions[0].options
      ? fileTypeOptions.reduce((acc, group) => [...acc, ...group.options], [])
        .find((option) => option.value === this.fileType)
      : fileTypeOptions.find((option) => option.value === this.fileType);

    return (
      <Modal
        isOpen={isOpen}
        toggle={this.onCancelModal}
        centered
      >
        {(modalHeaderText) && (
          <ModalHeader>
            {modalHeaderText}
          </ModalHeader>
        )}
        <ModalBody>
          {(modalBodyUpperSlot) && (
            modalBodyUpperSlot
          )}
          {(!hideSignDropdown) && (
            <div className="form-group">
              <label htmlFor="user-sign-select">Sign</label>
              {
                this.renderSignDropdown(
                  signsAreLoading,
                  signOptions,
                  this.signId,
                  showNoSignsWarning
                )
              }
            </div>
          )}
          <div className="form-group">
            <label htmlFor="file-type-select">File Type</label>
            <ReactSelect
              id="file-type-select"
              className="theme-light sign-select"
              classNamePrefix="react-select"
              isClearable={false}
              isMulti={false}
              isSearchable={false}
              onChange={this.onFileTypeChange}
              options={fileTypeOptions}
              value={selectedFileTypeOption}
              formatGroupLabel={({label, icon}) => (
                <div>
                  <FontAwesomeIcon
                    className="mr-1"
                    size="lg"
                    icon={icon}
                  /> {label}
                </div>
              )}
            />
          </div>
          {(modalBodyLowerSlot) && (
            modalBodyLowerSlot
          )}
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-default"
            onClick={this.onCancelModal}
          >
            Cancel
          </button>
          <button
            data-cy="select-file-type-button"
            type="button"
            className="btn btn-primary"
            onClick={this.onCompleteModal}
            disabled={this.isDisabled}
          >
            {this.confirmButtonText}
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

SelectSignAndFileTypeModal.propTypes = {
  content: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  allowDownloadExistingRenders: PropTypes.bool,
  apiContentFolderGetAllStore: MobxPropTypes.observableObject,
  apiContentRenderGetDownloadableStore: MobxPropTypes.observableObject,
  disableSignDropdown: PropTypes.bool,
  hideSignDropdown: PropTypes.bool,
  modalBodyLowerSlot: PropTypes.element,
  modalBodyUpperSlot: PropTypes.element,
  modalHeaderText: PropTypes.string,
  onChange: PropTypes.func,
  preselectedSignId: PropTypes.number
};

SelectSignAndFileTypeModal.defaultProps = {
  allowDownloadExistingRenders: false,
  disableSignDropdown: false,
  hideSignDropdown: false
};

SelectSignAndFileTypeModal.wrappedComponent = {};
SelectSignAndFileTypeModal.wrappedComponent.propTypes = {
  apiContentFolderGetAllStore: MobxPropTypes.observableObject.isRequired,
  apiContentRenderGetDownloadableStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(SelectSignAndFileTypeModal)(
  observer(SelectSignAndFileTypeModal)
);
