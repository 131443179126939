import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Tooltip} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinusCircle, faPlusCircle} from '@fortawesome/free-solid-svg-icons';

import SingleSlider from '../../common/singleSlider/SingleSlider';
import inject from '../../hoc/injectHoc';

import './zoomControl.scss';

/**
 * The ZoomControl component.
 *
 * @constructor
 */
export class ZoomControl extends React.Component {
  /**
   * Whether or not the tooltip for each button is showing.
   *
   * @type {{zoomBar: boolean, zoomIn: boolean, zoomOut: boolean}}
   */
  @observable isTooltipOpen = {
    zoomBar: false,
    zoomIn: false,
    zoomOut: false,
  };

  /**
   * Toggles the tooltip open or closed.
   *
   * @param {string} type
   * @returns {function}
   */
  onTooltipToggle = (type) => {
    return action(() => {
      this.isTooltipOpen[type] = !this.isTooltipOpen[type];
    });
  };

  /**
   * Sets the zoom level.
   *
   * @param {number} newZoomLevel
   */
  setZoomLevel = (newZoomLevel) => {
    const {displayZoomStore, onChangeZoom} = this.props;

    displayZoomStore.setZoomLevel(newZoomLevel);

    if (onChangeZoom) {
      onChangeZoom(newZoomLevel);
    }
  };

  /**
   * Alter the zoom level by a certain amount.
   *
   * @param {number} zoomChange
   */
  onZoomClick = (zoomChange) => {
    const {displayZoomStore} = this.props;
    const controlData = displayZoomStore.controlData;
    let newZoomLevel = displayZoomStore.zoomLevel + Number(zoomChange);

    if (newZoomLevel < controlData.min) {
      newZoomLevel = controlData.min;
    }

    if (newZoomLevel > controlData.max) {
      newZoomLevel = controlData.max;
    }

    this.setZoomLevel(newZoomLevel);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const zoomStore = this.props.displayZoomStore;
    const controlData = zoomStore.controlData;
    const ZOOM_INTERVAL = 10;

    return (
      <div className="zoom-control">
        <button
          type="button"
          id="control-btn-zoom-out"
          className="site-transparent-button zoom-icon"
          onClick={() => (this.onZoomClick(ZOOM_INTERVAL * -1))}
        >
          <FontAwesomeIcon icon={faMinusCircle} />
        </button>
        <Tooltip
          placement="left"
          isOpen={this.isTooltipOpen.zoomOut}
          target="control-btn-zoom-out"
          toggle={this.onTooltipToggle('zoomOut')}
        >
          Zoom Out
        </Tooltip>

        <button
          type="button"
          id="control-btn-zoom-in"
          className="site-transparent-button zoom-icon"
          onClick={() => (this.onZoomClick(ZOOM_INTERVAL))}
        >
          <FontAwesomeIcon icon={faPlusCircle} />
        </button>
        <Tooltip
          placement="left"
          isOpen={this.isTooltipOpen.zoomIn}
          target="control-btn-zoom-in"
          toggle={this.onTooltipToggle('zoomIn')}
        >
          Zoom In
        </Tooltip>

        {(controlData) ? (
          <SingleSlider
            className="zoom-slider"
            minValue={controlData.min}
            maxValue={controlData.max}
            tooltip="Zoom"
            value={zoomStore.zoomLevel}
            vertical={true}
            onChange={this.setZoomLevel}
          />
        ) : (
          <span>Loading...</span>
        )}

        {(controlData) && (
          <div className="zoom-label">
            {zoomStore.zoomLevel}%
          </div>
        )}
      </div>
    );
  }
}

ZoomControl.propTypes = {
  displayZoomStore: MobxPropTypes.observableObject,
  onChangeZoom: PropTypes.func,
};

ZoomControl.wrappedComponent = {};
ZoomControl.wrappedComponent.propTypes = {
  displayZoomStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(ZoomControl)(
  observer(ZoomControl)
);
