import React from 'react';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import {UncontrolledTooltip} from 'reactstrap';

import inject from '../../../../hoc/injectHoc';

import './downloadCreditsBadge.scss';

/**
 * Download Credits Badge component.
 */
class DownloadCreditsBadge extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {apiUserGetMeStore} = this.props;

    // TODO: Create a new store that gets the credits to prevent weird refreshes when we need to get the new credits.

    return apiUserGetMeStore.case({
      'pre': () => null,
      'pending': () => null,
      'rejected': () => null,
      'fulfilled': (user) => (
        <>
          <div id="download-credits-badge">
            <span>{user.projectContentCompany && user.projectContentCompany.downloadCredits || 0}</span>
          </div>
          <UncontrolledTooltip placement="bottom" target="download-credits-badge">
            Your Download Credits Remaining
          </UncontrolledTooltip>
        </>
      )
    });
  }
}

DownloadCreditsBadge.propTypes = {
  apiUserGetMeStore: MobxPropTypes.observableObject,
};

DownloadCreditsBadge.wrappedComponent = {};
DownloadCreditsBadge.wrappedComponent.propTypes = {
  apiUserGetMeStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(DownloadCreditsBadge)(
  observer(DownloadCreditsBadge)
);
