import {action} from 'mobx';

import ApiMapBaseStore from '../common/apiMapBaseStore';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';

/**
 * Fetches all folders for the current user from the api.
 */
class ApiFileGetUserFoldersStore extends ApiMapBaseStore {
  /**
   * Fetches user folders from the server.
   *
   * @param {string=} folderType
   */
  @action makeRequest(folderType) {
    const safeFolderType = (folderType) ? String(folderType) : null;

    this.dataMap.set(safeFolderType, {
      state: STATE_PENDING,
      [EXPIRE_TIME]: Date.now() + EXPIRES_PENDING,
      data: null,
      error: null,
    });

    serverApi.fileFolderGetUserFolders(safeFolderType).then(
      action('fileGetUserFoldersSuccess', (foundFolders) => {
        // public folders should be last in order
        const sortedFolders = foundFolders.sort((a, b) => {
          if (a.userId < b.userId) {
            return 1;
          } else if (a.userId > b.userId) {
            return -1;
          }

          return 0;
        });

        this.dataMap.set(safeFolderType, {
          state: STATE_FULFILLED,
          [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
          data: sortedFolders,
          error: null,
        });
      })
    ).catch(
      action('fileGetUserFoldersError', (foldersGetError) => {
        this.dataMap.set(safeFolderType, {
          state: STATE_REJECTED,
          [EXPIRE_TIME]: null,
          data: null,
          error: foldersGetError,
        });
      })
    );
  }
}

export default new ApiFileGetUserFoldersStore();
