/* eslint-disable no-magic-numbers */

import lodash from 'lodash';

import {EASINGS} from '../../ecs/easingHelper';
import easeScaleInGif from '../../../assets/images/transitions/easeScaleIn.gif';

/**
 * The name that signals this preset.
 * @const {string}
 */
export const PRESET_EASE_SCALE_OUT = 'easeScaleOut';

/**
 * The preset details.
 *
 * @type {{
 *   name: string,
 *   flow: string,
 *   details: Object.<string, {
 *     easing: string,
 *     parse: boolean,
 *     values: *|Array.<*>,
 *     time: {start: (string|number), end: (string|number)}
 *   }>,
 *   template: {}
 * }}
 */
export const easeScaleOutPresetDetails = {
  // The name of the preset.
  name: PRESET_EASE_SCALE_OUT,

  // A human friendly name for the preset.
  humanName: 'Scale Down - Out',

  // A preview of the transition.
  gif: easeScaleInGif,

  // The element names that this preset can be used for or true for all elements.
  elements: true,

  // The preset direction: in, out, middle. This is used in the UI logic.
  flow: 'out',

  // Each property that will change during the transition.
  details: {
    'transform.scale': {
      time: {
        end: 'end.0',
        start: 'end.-1000'
      },
      easing: EASINGS.easeInExpo,
      values: [
        1,
        0
      ],
      immediaterender: false
    },
  },

  // How this preset can be used in the source (including optional parameters).
  // This will be turned into a JSON string, so no functions can be used.
  template: {
    preset: PRESET_EASE_SCALE_OUT,
    options: {},
  },
};

easeScaleOutPresetDetails.template.options = lodash.cloneDeep(easeScaleOutPresetDetails.details);

// This defines the key in the presets object.
export const presetName = PRESET_EASE_SCALE_OUT;

// This defines the value of the presets object.
export default easeScaleOutPresetDetails;
