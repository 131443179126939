import {TimelineMax, TweenMax} from 'gsap';
import lodash from 'lodash';
import {runInAction, toJS} from 'mobx';
import uuid from 'uuid/v4';

import {
  generateStartAndEndDetails,
  initializeEntityTransition,
  parseTransitionDetails
} from '../../ecs/transitionHelper';

/**
 * Converts milliseconds to seconds when multiplied or seconds to milliseconds when divided.
 * @const {number}
 */
const MS_TO_SECONDS = 1000;

/**
 * The transition component.
 *
 * @param {Array.<{}>} transitions
 * @returns {{transition: Array.<{}>}}
 */
export function transitionComponent(transitions) {
  return {
    transition: transitions,
  };
}

/**
 * Gets the transition component from the source item.
 *
 * @param {{transitions: Array.<{}>}} item
 * @returns {{transition: Array.<{}>}}
 */
export function getTransitionFromSource(item) {
  if (!item.transitions) {
    return {};
  } else if (!item.transitions.map) {
    throw new Error('Item transitions must be an array.');
  }

  const parsedTransitions = item.transitions.map((transition) => {
    return getSingleTransitionFromSource(transition);
  });

  return transitionComponent(parsedTransitions);
}

/**
 * Gets the transition item for a single transition source item.
 *
 * @param {{}} transition
 * @returns {{}}
 */
export function getSingleTransitionFromSource(transition) {
  if (!transition.preset && !transition.details) {
    throw new Error('An invalid transition was defined.');
  }

  const uniqueId = transition.id || lodash.last(uuid().split('-'));
  const details = (!transition.preset) ? transition.details : null;

  return {
    id: uniqueId,
    name: transition.name || uniqueId,
    initialized: false,
    loadedPreset: false,
    preset: transition.preset || null,
    details,
    tween: null,
    defaults: { // These are needed for saving.
      easing: transition.easing || null,
      time: transition.time || null,
    },
    time: { // These will be updated when initialized.
      start: 0,
      end: 0,
    },
  };
}

/**
 * Initializes the transition.
 *
 * @param {{}} entity
 * @param {{}} entityTransition
 * @param {{}} game
 * @returns {boolean}
 */
export function initializeTransition(entity, entityTransition, game) {
  const transition = toJS(entityTransition);

  const parsedDetails = parseTransitionDetails(transition.details, transition, entity, game);
  if (!parsedDetails) {
    runInAction('transitionsComponentInitializeFail', () => {
      entityTransition.initialized = new Error('Could not initialize invalid transition.');
    });
    return false;
  }

  // Some transitions can be expanded or mutated based on what they are manipulating.
  // Such as letter transitions being applied to each letter in the text component.
  const lowestLevelDetails = initializeEntityTransition(entity, parsedDetails);

  // In order to tween the details, we need the startDetails and endDetails for each one.
  const {details, time} = generateStartAndEndDetails(lowestLevelDetails);

  const tweens = [];
  lodash.forEach(details, (tweenDetails) => {
    const target = {
      entity,
      path: tweenDetails.path,
    };

    // Update the endDetails with the proper delay, otherwise all tweens will run at the same time.
    tweenDetails.endDetails.delay += ((tweenDetails.time.start - time.start) / MS_TO_SECONDS);

    if (tweenDetails.startDetails) {
      tweens.push(
        TweenMax.fromTo(target, tweenDetails.duration, tweenDetails.startDetails, tweenDetails.endDetails)
      );
    } else {
      tweens.push(
        TweenMax.to(target, tweenDetails.duration, tweenDetails.endDetails)
      );
    }
  });

  // If we don't add the tweens this way, then the stagger 0 has no effect and so each tween is staggered.
  const tween = new TimelineMax({
    aligns: 'normal',
    stagger: 0,
    paused: true,
    tweens,
  });

  // Start the tween off paused and at time 0 (setting time 0 initializes the TweenMax object).
  tween.pause().seek(0);

  // Update the transition end time using the TweenMax calculated duration.
  // Things like repeat can change the total duration.
  const newDuration = tween._totalDuration * MS_TO_SECONDS;

  runInAction('transitionsComponentInitialize', () => {
    if (!entityTransition.id) {
      entityTransition.id = lodash.last(uuid().split('-'));
    }
    if (!entityTransition.name) {
      entityTransition.name = entityTransition.id;
    }
    entityTransition.time.start = time.start;
    entityTransition.time.end = parseInt(time.start + newDuration, 10);
    entityTransition.tween = tween;
    entityTransition.initialized = true;
  });
  return true;
}

/**
 * Updates the transition entity using the preset information.
 *
 * @param {{}} entity
 * @param {{}} transition
 * @param {Object.<string, Array.<{}>>} presetDetails
 * @returns {boolean}
 */
export function updateTransitionWithPreset(entity, transition, presetDetails) {
  if (transition.tween && transition.tween.kill) {
    // Release the existing TweenMax instance for garbage collection.
    transition.tween.kill();
  }

  runInAction('saveLoadedPreset', () => {
    transition.details = presetDetails;
    transition.loadedPreset = true;
    transition.initialized = false;
  });

  return true;
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {ObservableMap} entity
 * @returns {{}}
 */
export function getTransitionForSource(entity) {
  if (!entity.has('transition')) {
    return {};
  }

  const transitions = entity.get('transition');
  if (!transitions || !transitions.map) {
    return {};
  }

  const output = transitions.map((transition) => {
    return getSingleTransitionForSource(transition, true);
  }).filter((item) => item);

  return {
    transitions: output,
  };
}

/**
 * Parses an single entity back into source JSON.
 *
 * @param {Observable} transition
 * @param {boolean} skipInvalid If true, returns null for invalid transitions.
 * @returns {?{}}
 */
export function getSingleTransitionForSource(transition, skipInvalid) {
  const raw = Object.assign({}, toJS(transition));

  if (skipInvalid) {
    // If there are no details, then don't save this one.
    if (!raw.details || !lodash.size(raw.details)) {
      return null;
    }
  }

  const defaults = lodash.reduce(raw.defaults || {}, (filtered, itemValue, itemKey) => {
    if (itemValue !== null && itemValue !== undefined) {
      filtered[itemKey] = itemValue;
    }
    return filtered;
  }, {});

  const formatted = {
    id: raw.id,
    name: raw.name,
    ...defaults,
  };

  // Save either the preset details or the given details.
  if (raw.preset) {
    formatted.preset = raw.preset;
  } else {
    formatted.details = raw.details;
  }

  return formatted;
}
