import classNames from 'classnames';
import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAlignCenter, faAlignJustify, faAlignLeft, faAlignRight, faCaretDown} from '@fortawesome/free-solid-svg-icons';

import {actionAlignComponent} from '../../../../../display/components/action/actionAlignComponent';

import './alignEntitiesButtons.scss';

import {
  ALIGN_LEFT, ALIGN_CENTER, ALIGN_RIGHT,
  ALIGN_TOP, ALIGN_MIDDLE, ALIGN_BOTTOM,
  CENTER_HORIZONTALLY, CENTER_VERTICALLY,
  DISTRIBUTE_HORIZONTALLY, DISTRIBUTE_VERTICALLY
} from '../../../../../constants/entityOptionConstants';
import {getIsPositionLocked} from '../../../../../utils/dragDropHelper';

/**
 * The list of alignment buttons.
 *
 * @type {Array.<{label: string, style: string}>}
 */
const ALIGN_BUTTON_TYPES = [
  {id: 10, label: 'Align Left', style: ALIGN_LEFT, icon: faAlignLeft, single: true},
  {id: 20, label: 'Align Center', style: ALIGN_CENTER, icon: faAlignCenter, single: true},
  {id: 30, label: 'Align Right', style: ALIGN_RIGHT, icon: faAlignRight, single: true},
  {id: 40, label: 'Distribute Horizontally', style: DISTRIBUTE_HORIZONTALLY, icon: faAlignJustify},
  {id: 50, divider: true, single: true},

  {id: 60, label: 'Align Top', style: ALIGN_TOP, icon: faAlignLeft, iconRotation: 90, single: true},
  {id: 70, label: 'Align Middle', style: ALIGN_MIDDLE, icon: faAlignCenter, iconRotation: 90, single: true},
  {id: 80, label: 'Align Bottom', style: ALIGN_BOTTOM, icon: faAlignRight, iconRotation: 90, single: true},
  {id: 90, label: 'Distribute Vertically', style: DISTRIBUTE_VERTICALLY, icon: faAlignJustify, iconRotation: 90},
  {id: 100, divider: true},

  {id: 110, label: 'Center Horizontally', style: CENTER_HORIZONTALLY, icon: faAlignCenter},
  {id: 120, label: 'Center Vertically', style: CENTER_VERTICALLY, icon: faAlignCenter, iconRotation: 90},
];

/**
 * The AlignEntitiesButtons component.
 */
export class AlignEntitiesButtons extends React.Component {
  /**
   * Whether or not the dropdown is open.
   *
   * @type {boolean}
   */
  @observable isDropdownOpen = false;

  /**
   * The last align type selected.
   *
   * @type {{}}
   */
  @observable lastItemUsed = ALIGN_BUTTON_TYPES[1];

  /**
   * Opens the align drop down.
   */
  @action toggleDropdown = () => {
    this.isDropdownOpen = !this.isDropdownOpen;
  };

  /**
   * Filters out items that aren't valid for single items.
   *
   * @param {boolean} isSingle
   * @returns {function}
   */
  filterForSingle = (isSingle) => {
    return (item) => {
      if (!isSingle) {
        return true;
      }
      return item.single;
    };
  };

  /**
   * Aligns the entities.
   *
   * @param {{}} clicked
   * @returns {function()}
   */
  onClick = (clicked) => {
    return action(() => {
      this.lastItemUsed = clicked;

      const {game} = this.props;

      const entityIds = game.allActiveEntities.filter((activeEntity) => {
        return !getIsPositionLocked(activeEntity);
      }).map((activeEntity) => {
        return activeEntity.get('id');
      });
      const actionParams = {
        entityId: entityIds,
      };

      game.addAction(actionParams, actionAlignComponent(clicked.style));
    });
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {id, single} = this.props;

    const singleFilter = this.filterForSingle(single);

    return (
      <div id={id} className="align-entities-buttons">
        <button
          type="button"
          className={classNames('btn btn-sm btn-light form-button align-last-button')}
          onClick={this.onClick(this.lastItemUsed)}
        >
          <FontAwesomeIcon
            icon={this.lastItemUsed.icon}
            rotation={this.lastItemUsed.iconRotation}
          />
        </button>

        <Dropdown isOpen={this.isDropdownOpen} toggle={this.toggleDropdown}>
          <DropdownToggle
            className={classNames('btn btn-sm btn-light form-button align-dropdown')}
          >
            <FontAwesomeIcon icon={faCaretDown} />
          </DropdownToggle>
          <DropdownMenu right>
            {ALIGN_BUTTON_TYPES.filter(singleFilter).map((align) => {
              if (align.divider) {
                return (
                  <DropdownItem key={align.id} divider />
                );
              }

              return (
                <DropdownItem
                  key={align.id}
                  onClick={this.onClick(align)}
                  toggle={false}
                >{align.label}</DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

AlignEntitiesButtons.propTypes = {
  game: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  single: PropTypes.bool,
};

export default observer(AlignEntitiesButtons);
