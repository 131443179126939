import interact from 'interactjs/src/index';
import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {findDOMNode} from 'react-dom';

import {between} from '../../../../utils/mathHelper';

/**
 * A higher order component wrapper that handles making a slider handle's draggable.
 *
 * @param {Object} WrappedComponent
 * @returns {Object}
 */
export default function sliderHocWrapper(WrappedComponent) {
  /**
   * The SliderHoc higher order component.
   */
  class SliderHoc extends React.Component {
    /**
     * The slider react component.
     *
     * @type {?{}}
     */
    @observable slider = null;

    /**
     * The DOM element for the slider.
     *
     * @type {?HTMLElement}
     */
    @observable sliderEl = null;

    /**
     * The DOM element for the slider handle.
     *
     * @type {?HTMLElement}
     */
    @observable handleEl = null;

    /**
     * The interactJS objects initialized on the handle and slider.
     *
     * @type {Array.<{draggable: function, resizable: function}>}
     */
    @observable interactions = [];

    /**
     * The ongoing drag differing amount.
     * This will be reset after it hits 1.
     *
     * @type {number}
     */
    @observable dragDiff = 0;

    /**
     * Triggered when the component just mounted to the page.
     */
    componentDidMount() {
      this.initInteraction();
    }

    /**
     * Triggered when the component is about to unmount.
     */
    componentWillUnmount() {
      this.stopInteraction();
    }

    /**
     * Triggered right after the wrapped component is added to OR removed from the page.
     *
     * @param {{}} domEl
     */
    @action onChangeMount = (domEl) => {
      if (this.slider) {
        return;
      }

      this.slider = domEl;
      this.sliderEl = findDOMNode(domEl);
      if (!this.sliderEl) {
        return;
      }

      this.handleEl = this.sliderEl.querySelector('.single-slider-handle');
    };

    /**
     * Updates the handle and values when after the drag event triggers.
     *
     * @param {{dx: number, dy: number}} dragEvent
     */
    @action onMove = (dragEvent) => {
      const {minValue, maxValue, step, vertical} = this.slider.props;
      let safeStep = (step && step > 0) ? parseInt(step, 10) : 1;
      if (!safeStep) {
        safeStep = 1;
      }

      let percentChange = null;
      if (vertical) {
        const height = this.slider.getSliderHeight();
        percentChange = dragEvent.dy / height;
      } else {
        const width = this.slider.getSliderWidth();
        percentChange = dragEvent.dx / width;
      }

      const totalDiff = percentChange * (maxValue - minValue);

      // We don't want to trigger the zoom change except when we can move it 1 step.
      const ongoingDiff = totalDiff + this.dragDiff;

      let steps = 0;
      let newDragDiff = 0;
      if (ongoingDiff >= 0) {
        const roundedDiff = Math.floor(ongoingDiff);
        steps = Math.floor(roundedDiff / safeStep) * safeStep;
        newDragDiff = (ongoingDiff - roundedDiff) + (roundedDiff % safeStep);
      } else {
        const roundedDiff = Math.ceil(ongoingDiff);
        steps = Math.ceil(roundedDiff / safeStep) * safeStep;
        newDragDiff = (ongoingDiff - roundedDiff) + (roundedDiff % safeStep);
      }

      if (Math.abs(steps) < safeStep) {
        this.dragDiff = ongoingDiff;
        return;
      }

      const currentValue = this.slider.getValue();
      const newValue = between(currentValue + steps, minValue, maxValue);

      this.dragDiff = newDragDiff;
      this.props.onChange(newValue);
    };

    /**
     * Moves the handle when the track is clicked.
     *
     * @param {{stopPropagation: function}} clickEvent
     */
    @action onTrackClick = (clickEvent) => {
      clickEvent.stopPropagation();

      const {vertical} = this.slider.props;

      if (vertical) {
        const clickCoords = clickEvent.clientY;
        const handleCoords = this.handleEl.getBoundingClientRect().top;
        const offset = 8;
        const diffY = clickCoords - handleCoords - offset;

        this.onMove({dx: 0, dy: diffY});
        return;
      }

      const clickCoords = clickEvent.clientX;
      const handleCoords = this.handleEl.getBoundingClientRect().left;
      const offset = 8;
      const diffX = clickCoords - handleCoords - offset;

      this.onMove({dx: diffX, dy: 0});
    };

    /**
     * Starts the interactJS code.
     */
    @action initInteraction = () => {
      // Make sure we don't have multiple dragging interactions on this element.
      if (this.interactions.length) {
        this.interactions.forEach((interaction) => {
          interaction.unset();
        });
        this.interactions.clear();
      }

      const handleInteraction = interact(this.handleEl);
      handleInteraction.draggable({
        onmove: this.onMove
      });

      const sliderInteraction = interact(findDOMNode(this.slider));
      sliderInteraction.draggable({
        onmove: this.onMove
      });

      this.interactions.push(handleInteraction);
      this.interactions.push(sliderInteraction);
    };

    /**
     * Unbinds the dragging code.
     */
    @action stopInteraction = () => {
      if (!this.interactions.length) {
        return;
      }

      this.interactions.forEach((interaction) => {
        interaction.unset();
      });
      this.interactions.clear();
    };

    /**
     * Renders the WrappedComponent.
     *
     * @returns {Object}
     */
    render() {
      if (this.props.ref) {
        throw new Error('SliderHoc will override ref property given to the wrapped component.');
      }

      return (
        <WrappedComponent
          {...this.props}
          onTrackClick={this.onTrackClick}
          ref={this.onChangeMount}
        />
      );
    }
  }

  SliderHoc.propTypes = {
    onChange: PropTypes.func.isRequired,
    ref: PropTypes.func,
  };

  return observer(SliderHoc);
}
