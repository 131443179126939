/**
 * ID of New Content Collection
 * @const {string}
 */
export const NEW_CONTENT_COLLECTION_ID = 'new';

/**
 * Name of New Content Collection
 * @const {string}
 */
export const NEW_CONTENT_COLLECTION_NAME = 'New Additions';

/**
 * New Content Collection Item. This mimics a collection returned from the api
 * @const {object}
 */
export const NEW_CONTENT_COLLECTION_ITEM = {
  id: NEW_CONTENT_COLLECTION_ID,
  name: NEW_CONTENT_COLLECTION_NAME,
};
