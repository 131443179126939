import {positionComponent} from '../../../../../../display/components/common/positionComponent';
import {sizeComponent} from '../../../../../../display/components/common/sizeComponent';
import {videoComponent} from '../../../../../../display/components/type/videoComponent';
import {nameComponent} from '../../../../../../display/components/common/nameComponent';

/**
 * Gets the video data for a placeholder video.
 *
 * @param {number} fileId
 * @param {string} filename
 * @param {string} url
 * @param {number} duration
 * @param {string} videoType
 * @returns {function}
 */
export function getPlaceHolderVideoData(fileId, filename, url, duration, videoType) {
  return ({position, size}) => {
    const defaultSize = 200;
    const positionY = position.y || 0; // eslint-disable-line no-shadow
    const positionX = position.x || 0;
    const width = size.width || defaultSize;
    const height = size.height || defaultSize;

    const nameData = (filename) ? nameComponent(`Video: ${filename}`) : {};

    return {
      ...nameData,
      setup: {
        ...sizeComponent(width, height),
        ...positionComponent(positionY, positionX),
      },
      ...videoComponent(fileId, url, duration, videoType),
    };
  };
}
