import {action} from 'mobx';

import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {CONTENT_NEW} from '../../../constants/editorConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';
import ApiMapBaseStore from '../common/apiMapBaseStore';

/**
 * ApiContentGetOneStore
 */
class ApiContentGetOneStore extends ApiMapBaseStore {
  /**
   * Fetches the sources for the content.
   *
   * @param {number} contentId
   * @returns {Promise}
   */
  fetchContentSources(contentId) {
    return serverApi.contentSourceGetAll(
      contentId
    ).catch(() => {
      return null;
    }).then((foundContentSources) => {
      if (!foundContentSources || !foundContentSources.length) {
        return null;
      }

      return foundContentSources.reduce((allSources, foundSource) => {
        const {aspectRatio, source} = foundSource;
        if (!source) {
          return allSources;
        }

        if (typeof source === 'string') {
          try {
            allSources[aspectRatio] = JSON.parse(source);
          } catch (parseError) {
            allSources[aspectRatio] = new Error('Could not parse source JSON.');
          }
        } else {
          allSources[aspectRatio] = source;
        }

        return allSources;
      }, {});
    });
  }

  /**
   * Fetches content info from the server
   *
   * @param {string} contentId
   */
  @action makeRequest(contentId) {
    const safeContentId = String(contentId);

    if (safeContentId === CONTENT_NEW) {
      return;
    }

    this.dataMap.set(safeContentId, {
      state: STATE_PENDING,
      [EXPIRE_TIME]: Date.now() + EXPIRES_PENDING,
      data: null,
      error: null,
    });

    let content = null;

    serverApi.contentGetOne(
      safeContentId,
      true
    ).then((foundContent) => {
      content = foundContent;
      content.sources = {};

      return this.fetchContentSources(foundContent.id);
    }).then((contentSources) => {
      if (contentSources) {
        content.sources = contentSources;
      }
    }).then(action('contentGetOneSuccess', () => {
      this.dataMap.set(safeContentId, {
        state: STATE_FULFILLED,
        [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
        data: content,
        error: null,
      });
    })).catch(action('contentGetOneError', (fetchError) => {
      this.dataMap.set(safeContentId, {
        state: STATE_REJECTED,
        [EXPIRE_TIME]: null,
        data: null,
        error: fetchError,
      });
    }));
  }
}

export default new ApiContentGetOneStore();
