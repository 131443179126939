import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './textField.scss';

/**
 * Text input component with floating label.
 *
 * @param {{}} props
 * @returns {{}}
 */
const TextField = (props) => {
  const {label, type, errorMessage, ...inputProps} = props;

  const classes = classNames(
    'form-group',
    'text-field',
    {'floating-label': Boolean(props.label)},
    {'has-content': Boolean(props.value)}
  );

  return (
    <div className={classes}>
      <label>
        <input
          className="form-control"
          type={type}
          {...inputProps}
        />

        {label && <span>{label}</span>}
      </label>

      {(errorMessage) && (
        <div className="invalid-feedback">{errorMessage}</div>
      )}
    </div>
  );
};

TextField.defaultProps = {
  type: 'text',
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,

  errorMessage: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

export default TextField;
