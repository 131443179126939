import {action} from 'mobx';

import ApiMapBaseStore from '../common/apiMapBaseStore';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';

/**
 * ApiContentFolderGetContentStore
 */
class ApiContentFolderGetContentStore extends ApiMapBaseStore {
  /**
   * Fetches contentFolder content from the server.
   *
   * @param {string} contentFolderId
   */
  @action makeRequest(contentFolderId) {
    const safeContentFolderId = String(contentFolderId);

    this.dataMap.set(safeContentFolderId, {
      state: STATE_PENDING,
      [EXPIRE_TIME]: Date.now() + EXPIRES_PENDING,
      data: null,
      error: null,
    });

    serverApi.contentFolderGetContent(safeContentFolderId).then(
      action('collectionGetContentSuccess', (foundContent) => {
        const safeContent = (foundContent && Array.isArray(foundContent)) ? foundContent : [];

        this.dataMap.set(safeContentFolderId, {
          state: STATE_FULFILLED,
          [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
          data: safeContent,
          error: null,
        });
      }),
      action('contentFolderGetContentError', (contentFolderGetContentError) => {
        this.dataMap.set(safeContentFolderId, {
          state: STATE_REJECTED,
          [EXPIRE_TIME]: null,
          data: null,
          error: contentFolderGetContentError,
        });
      })
    );
  }
}

export default new ApiContentFolderGetContentStore();
