import classNames from 'classnames';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {UncontrolledTooltip} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashCan} from '@fortawesome/free-regular-svg-icons';

import inject from '../../../../hoc/injectHoc';
import LoadingIcon from '../../../../common/loadingIcon/LoadingIcon';
import {STATE_PENDING} from '../../../../../constants/asyncConstants';
import {getAssetUrl} from '../../../../../utils/assetHelper';

import './fileItem.scss';

/**
 * The URL to the placeholder template image.
 * @type {string}
 */
const placeholderTemplateUrl = getAssetUrl('placeholder-template.png');

/**
 * File Item component
 */
class FileItem extends Component {
  /**
   * Attempt to refresh on mount
   */
  componentDidMount() {
    this.refreshIfNecessary();
  }

  /**
   * Attempt to refresh on update
   *
   * @param {{}} prevProps
   */
  componentDidUpdate(prevProps) {
    const {apiFilePollStatusStore, fileId} = this.props;

    if (prevProps.fileId !== fileId) {
      apiFilePollStatusStore.cancelPollForFile(prevProps.fileId);

      this.refreshIfNecessary();
    }
  }

  /**
   * Triggered when the component is about to be removed from the page.
   */
  componentWillUnmount() {
    const {apiFilePollStatusStore, fileId} = this.props;

    apiFilePollStatusStore.cancelPollForFile(fileId);
  }

  /**
   * Checks to see if the file is processing, and starts polling it if true.
   */
  checkForProcessing = () => {
    const {apiFilePollStatusStore, file} = this.props;

    const isProcessing = this.isFilePending(file);
    if (!isProcessing) {
      return;
    }

    const {updatedAt} = file;
    const updated = moment(updatedAt);
    const limit = moment().subtract(2, 'hours');

    // Only poll if the file has been updated recently, otherwise we don't expect it to stop processing soon.
    if (!updated.isAfter(limit)) {
      return;
    }

    apiFilePollStatusStore.pollThenUpdateFile(file.id);
  };

  /**
   * Call refresh on the file if it wasn't passed in as a prop
   */
  refreshIfNecessary = () => {
    const {
      file,
      fileId,
      apiFileGetOneStore,
    } = this.props;

    if (!file) {
      apiFileGetOneStore.refresh(fileId);
    }

    this.checkForProcessing();
  };

  /**
   * Gets whether or not the file is pending.
   *
   * @param {{status: string}} file
   * @returns {boolean}
   */
  isFilePending = (file) => {
    const {apiFilePollStatusStore} = this.props;

    return apiFilePollStatusStore.isFileProcessing(file);
  };

  /**
   * Gets whether or not the file status was error.
   *
   * @param {{status: string}} file
   * @returns {boolean}
   */
  isFileError = (file) => {
    const {apiFilePollStatusStore} = this.props;

    return apiFilePollStatusStore.isFileError(file);
  };

  /**
   * Handles when the item is clicked.
   *
   * @param {{}} file
   * @returns {function}
   */
  onClickItem = (file) => {
    return () => {
      if (this.isFilePending(file)) {
        const {apiFilePollStatusStore, apiFileRerenderStore} = this.props;
        if (!apiFilePollStatusStore.hasProcessingExpired(file)) {
          return;
        } else if (apiFileRerenderStore.getState() === STATE_PENDING) {
          return;
        }

        this.restartRendering(file);
        return;
      }

      const {onClick} = this.props;
      onClick(file);
    };
  };

  /**
   * Deletes the video.
   *
   * @param {{id: number, name: string}} file
   * @returns {function}
   */
  onDeleteClick = (file) => {
    return (clickEvent) => {
      clickEvent.preventDefault();
      clickEvent.stopPropagation();

      const {onDelete} = this.props;

      onDelete(file);
    };
  };

  /**
   * Restarts the rendering of the file.
   *
   * @param {{}} file
   */
  restartRendering = (file) => {
    const {apiFileGetOneStore, apiFileRerenderStore} = this.props;

    // Allows the file rendering to restart.
    apiFileRerenderStore.makeRequest(file.id);
    apiFileRerenderStore.getPromise(file.id).then(() => {
      if (!this.props.file) {
        apiFileGetOneStore.refresh(file.id, true);
        apiFileGetOneStore.getPromise(file.id).then(() => {
          this.checkForProcessing();
        });
        return;
      }

      if (this.props.onRefresh) {
        this.props.onRefresh();
      }
    });
  };

  /**
   * Renders the component.
   *
   * @param {{}} file
   * @returns {{}}
   */
  renderContentItem = (file) => {
    const {apiFilePollStatusStore, disableDelete} = this.props;

    const thumbnailImageUrl = file.thumbnailPath || placeholderTemplateUrl;

    const isPending = this.isFilePending(file);
    const isError = this.isFileError(file);
    const isPolling = apiFilePollStatusStore.isPollingInProgress(file.id);

    let hasProcessingExpired = false;
    if (isPending) {
      hasProcessingExpired = apiFilePollStatusStore.hasProcessingExpired(file);
    }

    const onClick = isPending || isError
      ? undefined
      : this.onClickItem(file);

    return (
      <div className={classNames('select-media file-item', {'is-free': file.isFree, 'is-pending': isPending})}>
        <div
          className={classNames(
            'flex-fill img d-flex justify-content-center align-items-center align-content-center',
            {
              'cursor-pointer': !!onClick,
            }
          )}
          onClick={onClick}
        >
          <img src={thumbnailImageUrl} />
          <span
            className="file-name ellipsis"
            title={file.filename}
          >
            {file.filename}
          </span>

          {(isPending) && (
            <span className="pending-label">
              Pending
              {(hasProcessingExpired) && ('*')}

              {(isPolling) && (
                <LoadingIcon size="em" />
              )}
            </span>
          )}

          {(isError) && (
            <span className="error-label">
              Processing Error
            </span>
          )}
        </div>
        <div className="file-actions">
          {((!isPending || hasProcessingExpired) && (!disableDelete)) && (
            <>
              <button
                type="button"
                id={`file-delete-${file.id}`}
                className="btn btn-light py-1 px-3"
                onClick={this.onDeleteClick(file)}
              >
                <FontAwesomeIcon
                  icon={faTrashCan}
                  size="sm"
                />
              </button>
              <UncontrolledTooltip placement="bottom" target={`file-delete-${file.id}`}>
                Delete
              </UncontrolledTooltip>
            </>
          )}
        </div>
      </div>
    );
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      file,
      fileId,
      apiFileGetOneStore,
    } = this.props;

    if (file) {
      return this.renderContentItem(file);
    }

    return apiFileGetOneStore.case(fileId, {
      pre: () => null,
      pending: () => (
        <div className="d-flex flex-column select-media file-item is-loading">
          <LoadingIcon />
        </div>
      ),
      fulfilled: this.renderContentItem,
      rejected: () => null,
    });
  }
}

FileItem.propTypes = {
  fileId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,

  apiFileGetOneStore: MobxPropTypes.observableObject,
  apiFilePollStatusStore: MobxPropTypes.observableObject,
  apiFileRerenderStore: MobxPropTypes.observableObject,
  disableDelete: PropTypes.bool,
  file: PropTypes.object,
  onRefresh: PropTypes.func,
};
FileItem.defaultProps = {
  disableDelete: false,
};

FileItem.wrappedComponent = {};
FileItem.wrappedComponent.propTypes = {
  apiFileGetOneStore: MobxPropTypes.observableObject.isRequired,
  apiFilePollStatusStore: MobxPropTypes.observableObject.isRequired,
  apiFileRerenderStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(FileItem)(
  observer(FileItem)
);
