import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'react-dropzone';

import ConfirmModal from '../../modals/confirm/ConfirmModal';

import './fileUpload.scss';

/**
 * The limit on the number of files that can be uploaded at a time.
 * @const {number}
 */
const FILE_UPLOAD_LIMIT = 5;

/**
 * The limit (in MB) on how large an uploaded file can be.
 * @const {number}
 */
const FILE_SIZE_LIMIT_MB = 380;

/**
 * The limit (in bytes) on how large an uploaded file can be.
 * @const {number}
 */
const FILE_SIZE_LIMIT = FILE_SIZE_LIMIT_MB * 1024 * 1024; // eslint-disable-line no-magic-numbers

/**
 * The FileUpload component.
 */
export class FileUpload extends React.Component {
  /**
   * A reference to the file input element.
   *
   * @type {{current: ?HTMLElement}}
   */
  fileInput = React.createRef();

  /**
   * Whether or not the user tried to upload too many files.
   *
   * @type {boolean}
   */
  @observable tooManyError = false;

  /**
   * Triggered when the component props updated.
   *
   * @param {{openUploadDialog: boolean}} prevProps
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.openUploadDialog && this.props.openUploadDialog) {
      this.openFileUploadDialog();
    }
  }

  /**
   * Opens the file upload dialog if it is available.
   */
  openFileUploadDialog = () => {
    if (this.fileInput.current) {
      this.fileInput.current.click();
    }
  };

  /**
   * Limits the number of files when new files are dropped.
   *
   * @param {Array.<{}>} files
   * @param {Array.<{}>} rejectedFiles
   */
  @action limitFilesBeforeUpload = (files, rejectedFiles) => {
    const hasFiles = Boolean(files && files.length);
    const hasRejectedFiles = Boolean(rejectedFiles && rejectedFiles.length);

    if (!hasFiles && !hasRejectedFiles) {
      return;
    }

    const {onDrop} = this.props;

    if (files.length > FILE_UPLOAD_LIMIT) {
      this.tooManyError = true;
      return;
    }

    this.tooManyError = false;
    onDrop(files, this.mapRejectedToError(rejectedFiles));
  };

  /**
   * Maps the rejected files list to create an error for each one.
   *
   * @param {Array<{}>} rejectedFiles
   * @returns {Array<{}>}
   */
  mapRejectedToError = (rejectedFiles) => {
    if (!rejectedFiles || !rejectedFiles.length || !Array.isArray(rejectedFiles)) {
      return null;
    }

    return rejectedFiles.map((rejectedFile) => {
      let fileError = null;
      if (rejectedFile.size > FILE_SIZE_LIMIT) {
        fileError = new Error(`File size is above the limit of ${FILE_SIZE_LIMIT_MB} megabytes.`);
      } else {
        fileError = new Error('Invalid file type');
      }

      return {
        name: rejectedFile.name,
        error: fileError,
      };
    });
  };

  /**
   * The change handler after files have been selected from the Upload button.
   */
  onFileInputChange = () => {
    const fileEl = this.fileInput.current;
    const fileList = fileEl.files;

    // Note: IE11 requires this for loop approach, don't try to use something like Object.values().
    let files = [];
    for (let iter = 0; iter < fileList.length; iter += 1) {
      files.push(fileList[iter]);
    }

    fileEl.value = null;

    this.limitFilesBeforeUpload(files || []);
  };

  /**
   * Dismisses the too many error alert.
   */
  @action onDismissError = () => {
    this.tooManyError = false;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {fileMimes, hideDropzone} = this.props;
    const max = FILE_UPLOAD_LIMIT;

    return (
      <div className="file-upload">
        {(!hideDropzone) && (
          <Dropzone
            className="upload-zone"
            accept={fileMimes}
            activeClassName="is-active"
            maxSize={FILE_SIZE_LIMIT}
            multiple={false}
            onDrop={this.limitFilesBeforeUpload}
          >
            <p className="upload-text">
              Drag &amp; Drop up to {max} files here or {' '}
              <span className="upload-click-text">Click Here</span>
            </p>
          </Dropzone>
        )}

        <input
          multiple={false}
          type="file"
          id="file-upload-input"
          className="is-hidden"
          onChange={this.onFileInputChange}
          ref={this.fileInput}
        />

        {(this.tooManyError) && (
          <ConfirmModal
            confirmText={`Only ${max} files can be uploaded at one time.`}
            isOpen={true}
            onComplete={this.onDismissError}
          />
        )}
      </div>
    );
  }
}

FileUpload.propTypes = {
  onDrop: PropTypes.func.isRequired,
  openUploadDialog: PropTypes.bool.isRequired,

  fileMimes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  hideDropzone: PropTypes.bool,
};

FileUpload.defaultProps = {
  hideDropzone: false,
};

export default observer(FileUpload);
