import lodash from 'lodash';
import {toJS} from 'mobx';

/**
 * The default circle color.
 * @const {string}
 */
const DEFAULT_COLOR = '#000000';

/**
 * The default circle opacity.
 * @const {number}
 */
const DEFAULT_OPACITY = 1;

/**
 * The circle component.
 *
 * @param {{color: string, opacity: number}=} style
 * @param {{color: string, opacity: number, width: number}=} border
 * @returns {{circle: {}}}
 */
export function circleComponent(style, border) {
  return {
    circle: {
      style,
      border
    },
  };
}

/**
 * Gets the circle component from the source item.
 *
 * @param {{circle: {style: {}, border: {}}}} item
 * @param {{}=} variables
 * @returns {{circle: {style: {}, border: {}}}}
 */
export function getCircleFromSource(item, variables) {
  if (!item.circle) {
    return {};
  }

  const circle = {...item.circle};
  if (!circle.style) {
    circle.style = {};
  }
  if (!circle.border) {
    circle.border = {};
  }

  const canEdit = lodash.get(item, 'compose.canEdit');
  const variableName = lodash.get(item, 'compose.variableName');
  if (canEdit && variableName) {
    const variableValue = lodash.get(variables, `circle.${variableName}`, '');
    if (variableValue) {
      let styleColor, styleOpacity, borderColor, borderOpacity;
      if (lodash.isString(variableValue)) {
        // The old deprecated variable style is a string with | delimiters.
        const partsCount = 4;
        [styleColor, styleOpacity, borderColor, borderOpacity] = lodash.split(variableValue, '|', partsCount);
      } else {
        // The ()s fix an eslint parsing error.
        ({styleColor, styleOpacity, borderColor, borderOpacity} = variableValue);
      }

      circle.style.color = styleColor;
      circle.style.opacity = styleOpacity;
      circle.border.color = borderColor;
      circle.border.opacity = borderOpacity;
    }
  }

  const style = {
    color: circle.style.color || DEFAULT_COLOR,
    opacity: getOpacity(circle.style),
  };
  const border = {
    color: circle.border.color || DEFAULT_COLOR,
    opacity: getOpacity(circle.border),
    width: circle.border.width || 0,
  };

  return circleComponent(
    style,
    border,
  );
}

/**
 * Gets the opacity or the default.
 *
 * @param {{opacity: number}} item
 * @returns {number}
 */
function getOpacity(item) {
  if (!item || item.opacity === undefined) {
    return DEFAULT_OPACITY;
  }

  const safeOpacity = Number(item.opacity);
  if (!safeOpacity && safeOpacity !== 0) {
    return DEFAULT_OPACITY;
  }

  return safeOpacity;
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {{}} entity
 * @returns {{}}
 */
export function getCircleForSource(entity) {
  if (!entity.has('circle')) {
    return {};
  }

  const compose = entity.get('compose');
  const circle = entity.get('circle');

  const canEdit = lodash.get(compose, 'canEdit', false);
  const variableName = lodash.get(compose, 'variableName');

  const outCircle = {};

  if (!canEdit || !variableName) {
    outCircle.style = toJS(circle.style);
    outCircle.border = toJS(circle.border);
  } else {
    outCircle.border = {
      width: circle.border.width || 0,
    };
  }

  return {
    circle: lodash.cloneDeep(outCircle),
  };
}
