import {action} from 'mobx';

import apiContentFolderGetContentStore from '../contentFolder/apiContentFolderGetContentStore';
import ApiBaseStore from '../common/apiBaseStore';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';

/**
 * ApiContentDeleteStore
 */
class ApiContentDeleteManyStore extends ApiBaseStore {
  /**
   * Makes the api request.
   *
   * @param {Array.<number|string>} contentIds
   * @param {number} folderId
   */
  @action makeRequest(contentIds, folderId) {
    if (!contentIds) {
      this.state = STATE_REJECTED;
      this.error = new Error('Invalid content ids given to contentDeleteMany.');
      return;
    }

    this.state = STATE_PENDING;
    this[EXPIRE_TIME] = Date.now() + EXPIRES_PENDING;

    serverApi.contentDeleteMany(
      contentIds
    ).then(
      action('contentDeleteManySuccess', () => {
        this.error = null;
        this.state = STATE_FULFILLED;
        this.data = true;
        this[EXPIRE_TIME] = Date.now() + EXPIRES_IN;

        // Clear the folder content cache.
        apiContentFolderGetContentStore.refresh(folderId, true);
      }),
      action('contentDeleteManyError', (error) => {
        this.error = error;
        this.state = STATE_REJECTED;
        this.data = false;
      })
    );
  }
}

export default new ApiContentDeleteManyStore();
