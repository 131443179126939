import classNames from 'classnames';
import lodash from 'lodash';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import ImageGroup from './components/ImageGroup';

import './displayImage.scss';

/**
 * The DisplayImage component.
 */
export class DisplayImage extends React.Component {
  /**
   * Translates the filters into css styles for the image.
   *
   * @param {{opacity: number}} image
   * @returns {{filter: string, opacity: number}}
   */
  getFiltersForImage = (image) => {
    const filters = {};

    const dropShadow = image.dropShadow;
    if (dropShadow && (dropShadow.x || dropShadow.y)) {
      filters.dropShadow = lodash.defaults(dropShadow, {
        blur: 0,
        color: '#000',
        x: 0,
        y: 0,
      });
    }

    if (image.opacity !== undefined) {
      filters.opacity = image.opacity;
    }

    return filters;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, game, style, topStyle, className, onEntityClick} = this.props;

    const entityId = entity.get('id');
    const image = entity.get('image');
    if (!image.url) {
      return null;
    }

    const gameResolution = game.resolution;

    // Disable rotation on the svg element.
    const svgStyle = {
      ...topStyle,
      clipPath: '',
      pointerEvents: 'none',
    };

    const imageFilters = this.getFiltersForImage(image);
    const filterProps = {};

    if (imageFilters.opacity && imageFilters.opacity !== 1) {
      filterProps.opacity = imageFilters.opacity;
    }

    const dropShadowId = (imageFilters.dropShadow) ? `image-drop-shadow-${entityId}` : null;
    if (dropShadowId) {
      filterProps.filter = `url(#${dropShadowId})`;
    }

    const clipPathId = `clip-display-image-${entityId}`;

    return (
      <svg
        id={entityId}
        className={classNames('display-image', className)}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke="none"
        preserveAspectRatio="none"
        pointerEvents="none"
        viewBox={`0 0 ${svgStyle.width} ${svgStyle.height}`}
        style={svgStyle}
      >
        {(dropShadowId) && (
          <filter id={dropShadowId}>
            <feDropShadow
              dx={imageFilters.dropShadow.x}
              dy={imageFilters.dropShadow.y}
              stdDeviation={imageFilters.dropShadow.blur}
              floodColor={imageFilters.dropShadow.color}
              floodOpacity="1"
            />
          </filter>
        )}

        <clipPath id={clipPathId}>
          <rect
            x={gameResolution.width}
            y={gameResolution.height}
            width={gameResolution.width}
            height={gameResolution.height}
          />
        </clipPath>

        <g
          className="image-clip-group"
          clipPath={`url(#${clipPathId})`}
          {...filterProps}
          pointerEvents="visible"
        >
          <ImageGroup
            entity={entity}
            game={game}
            style={style}
            offsetTop={gameResolution.height}
            offsetLeft={gameResolution.width}
            onEntityClick={onEntityClick}
          />
        </g>
      </svg>
    );
  }
}

DisplayImage.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
  style: PropTypes.object.isRequired,
  topStyle: PropTypes.object.isRequired,

  className: PropTypes.string,
  onEntityClick: PropTypes.func,
};

export default observer(DisplayImage);
