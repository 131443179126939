import mainConfig from '../config/main';

/**
 * Gets an off-site asset url for the given asset name.
 *
 * @param {string} assetName This must match the filename of the asset.
 * @returns {string}
 */
export function getAssetUrl(assetName) {
  return `${mainConfig.assets.offSiteUrl}/${assetName}`;
}

/**
 * Gets the marketing asset url for the given asset name.
 *
 * @param {string} assetName This must match the filename of the asset.
 * @returns {string}
 */
export function getMarketingAssetUrl(assetName) {
  return `${mainConfig.assets.marketingUrl}/${assetName}`;
}
