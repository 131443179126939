import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import entityRotateHOC from '../../hoc/entityRotateHoc';

/**
 * The RotationIndicator component.
 */
class RotationIndicator extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {controlOffset, direction, fillColor, offsetX, strokeColor, offsetY} = this.props;

    const style = {
      cursor: 'crosshair',
    };

    const circleRadius = 8;
    const lineLength = controlOffset + 40; // eslint-disable-line no-magic-numbers

    let cx = null;
    let cy = null;
    let endPoint = null;
    if (direction === 'down') {
      cx = offsetX;
      cy = offsetY + lineLength;

      endPoint = `L ${offsetX},${offsetY + lineLength}`;
    } else if (direction === 'left') {
      cx = offsetX - lineLength;
      cy = offsetY;

      endPoint = `L ${offsetX - lineLength},${offsetY}`;
    } else if (direction === 'left') {
      cx = offsetX + lineLength;
      cy = offsetY;

      endPoint = `L ${offsetX + lineLength},${offsetY}`;
    } else {
      cx = offsetX;
      cy = offsetY - lineLength;

      endPoint = `L ${offsetX},${offsetY - lineLength}`;
    }

    const linePath = [
      `M ${offsetX},${offsetY}`,
      endPoint,
      'Z',
    ].join(' ');

    return (
      <g className="rotation-path" pointerEvents="visiblePainted">
        <path
          stroke={fillColor}
          strokeOpacity="1"
          strokeWidth="4"
          strokeLinecap="square"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
          pointerEvents="visiblePainted"
          d={linePath}
          style={style}
        />

        <ellipse
          fill={fillColor}
          fillOpacity="1"
          stroke={strokeColor}
          strokeOpacity="1"
          strokeWidth="3"
          strokeMiterlimit="10"
          pointerEvents="visiblePainted"
          cx={cx}
          cy={cy}
          rx={circleRadius}
          ry={circleRadius}
          style={style}
        />

        <text
          className="rotation-label"
          x={cx}
          y={cy}
          dx={10}
          dy={10}
        >Rotating</text>
      </g>
    );
  }
}

RotationIndicator.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  fillColor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  game: MobxPropTypes.observableObject.isRequired,
  offsetX: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  offsetY: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  strokeColor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

  controlOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
};

RotationIndicator.defaultProps = {
  controlOffset: 0,
  direction: 'up',
};

export default entityRotateHOC(
  observer(RotationIndicator)
);
