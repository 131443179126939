import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';
import {Tooltip} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';

import LoadingIcon from '../../../../common/loadingIcon/LoadingIcon';
import inject from '../../../../hoc/injectHoc';
import InputStringModal from '../../../../modals/inputString/InputStringModal';

import './collectionAddButton.scss';

/**
 * The CollectionAddButton component.
 */
export class CollectionAddButton extends React.Component {
  /**
   * Whether or not the modal is open.
   *
   * @type {boolean}
   */
  @observable isNameModalOpen = false;

  /**
   * Whether or not the tooltip is open.
   *
   * @type {boolean}
   */
  @observable isTooltipOpen = false;

  /**
   * Triggered when the add button is clicked.
   */
  @action onNewCollectionClick = () => {
    this.isNameModalOpen = true;
  };

  /**
   * Closes the name modal.
   */
  @action closeNameModal = () => {
    this.isNameModalOpen = false;
  };

  /**
   * Triggered when the name modal is completed.
   *
   * @param {string} newCollectionName
   */
  @action onNameCreated = (newCollectionName) => {
    if (!newCollectionName) {
      this.isNameModalOpen = false;
      return;
    }

    const {apiCollectionCreateStore} = this.props;

    apiCollectionCreateStore.makeRequest(newCollectionName);

    apiCollectionCreateStore.getPromise().then(() => {
      this.closeNameModal();
    });
  };

  /**
   * Toggles the tooltip.
   */
  @action toggleTooltip = () => {
    this.isTooltipOpen = !this.isTooltipOpen;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {apiCollectionCreateStore} = this.props;

    const loading = () => (<LoadingIcon size="em" />);
    const notLoading = () => (<FontAwesomeIcon icon={faPlus} />);

    return (
      <div className="collection-add-button">
        <button
          type="button"
          className="btn btn-sm btn-default"
          id="collection-add-btn"
          onClick={this.onNewCollectionClick}
        >
          {apiCollectionCreateStore.case({
            pre: notLoading,
            pending: loading,
            fulfilled: notLoading,
            rejected: notLoading,
          })}
        </button>

        <Tooltip
          placement="right"
          isOpen={this.isTooltipOpen}
          target="collection-add-btn"
          toggle={this.toggleTooltip}
        >
          Create New Collection
        </Tooltip>

        {(this.isNameModalOpen) && (
          <InputStringModal
            isOpen={true}
            onComplete={this.onNameCreated}
            placeholder="New Collection Name"
            title="New Collection Name"
          />
        )}
      </div>
    );
  }
}

CollectionAddButton.propTypes = {
  apiCollectionCreateStore: MobxPropTypes.observableObject,
};

CollectionAddButton.wrappedComponent = {};
CollectionAddButton.wrappedComponent.propTypes = {
  apiCollectionCreateStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(CollectionAddButton)(
  observer(CollectionAddButton)
);
