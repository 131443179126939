/**
 * avi file type
 * @const {string}
 */
export const FILE_TYPE_AVI = 'avi';

/**
 * mp4 file type
 * @const {string}
 */
export const FILE_TYPE_MP4 = 'mp4';

/**
 * mpeg file type
 * @const {string}
 */
export const FILE_TYPE_MPEG = 'mpeg';

/**
 * jpeg file type
 * @const {string}
 */
export const FILE_TYPE_JPEG = 'jpeg';

/**
 * wmv file type (necessary for Watchfire signs)
 */
export const FILE_TYPE_WMV = 'wmv';

/**
 * jpg file type
 * @const {string}
 */
export const FILE_TYPE_JPG = 'jpg';

/**
 * png file type
 * @const {string}
 */
export const FILE_TYPE_PNG = 'png';

