import classNames from 'classnames';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import entityActivateHoc from '../../hoc/entityActivateHoc';
import {STATE_PAUSED, STATE_PLAYING, STATE_STOPPED} from '../../../constants/displayItemConstants';
import {onClickEntityEvent} from '../utils';

/**
 * The amount of time that is allowed to drift before the video currentTime will be corrected.
 * @const {number}
 */
const ALLOWED_TIME_DRIFT = 99;

/**
 * The time conversion from seconds to milliseconds.
 * @const {number}
 */
const SECONDS_TO_MILLSECONDS = 1000;

/**
 * Whether or not this is a render flow.
 * The render webpack flow will change this value.
 * This will prevent the video element from playing/stopping.
 *
 * @type {boolean}
 */
const IS_RENDER_FLOW = false;

/**
 * The DisplayVideo component.
 */
export class DisplayVideo extends React.Component {
  /**
   * The current playback state of the video element.
   */
  @observable videoState = STATE_STOPPED;

  /**
   * A reference to the video player element.
   *
   * @type {{current: HTMLElement}}
   */
  playerRef = React.createRef();

  /**
   * Triggered when the observed items (video) in the component update.
   */
  componentWillReact = () => {
    const {entity} = this.props;
    const video = entity.get('video');
    const videoEl = this.playerRef.current;
    if (!videoEl || !video.fileId) {
      return;
    }

    this.updateVideoTime(video, videoEl);
    this.updateVideoState(video, videoEl);
  };

  /**
   * Updates the video element time if it drifts too far from the video entity time.
   *
   * @param {{time: number}} video
   * @param {HTMLElement} videoEl
   */
  updateVideoTime = (video, videoEl) => {
    const driftAmount = Math.abs((videoEl.currentTime * SECONDS_TO_MILLSECONDS) - video.time);

    // If the video time and the video element's time has drifted too much, then force the video element time.
    if (driftAmount > ALLOWED_TIME_DRIFT) {
      videoEl.currentTime = video.time / SECONDS_TO_MILLSECONDS;
    }
  };

  /**
   * Updates the state (paused/playing/stopped) of the video from the video entity state.
   *
   * @param {{state: string}} video
   * @param {HTMLElement} videoEl
   */
  @action updateVideoState = (video, videoEl) => {
    if (IS_RENDER_FLOW) {
      // During render flows, we don't want videos starting in stopping,
      // we just want to change its time frame by frame.
      return;
    }

    const currentState = this.videoState;
    const entityState = video.state;

    // If the state of the video has not changed, then we don't need to do anything.
    if (currentState === entityState) {
      return;
    }

    if (currentState === STATE_STOPPED) {
      if (entityState === STATE_PLAYING) {
        const playResponse = videoEl.play();
        if (playResponse.catch) {
          playResponse.catch(() => {
            // Do nothing but catching here prevent an annoying error message from being logged.
          });
        }
      } else if (entityState === STATE_PAUSED) {
        videoEl.pause();
      }
    } else if (currentState === STATE_PLAYING) {
      videoEl.pause();

      if (entityState === STATE_STOPPED) {
        videoEl.currentTime = 0;
      }
    } else if (currentState === STATE_PAUSED) {
      if (entityState === STATE_STOPPED) {
        videoEl.currentTime = 0;
      } else if (entityState === STATE_PLAYING) {
        const playResponse = videoEl.play();
        if (playResponse.catch) {
          playResponse.catch(() => {
            // Do nothing but catching here prevent an annoying error message from being logged.
          });
        }
      }
    }

    this.videoState = entityState;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, style, topStyle, className, onEntityClick, game} = this.props;

    const entityId = entity.get('id');
    const video = entity.get('video');
    if (!video.url) {
      return null;
    }

    let videoType = String(video.type).trim();
    if (['video/mp4', 'video/webm', 'video/ogg'].indexOf(videoType) === -1) {
      videoType = 'video/mp4';
    }

    const videoSource = video.url;

    /*
     * This is very important!
     * MobX won't fire the componentWillReact method when the state or time changes
     * unless it is referenced in this render().
     */
    video.state; // eslint-disable-line no-unused-expressions
    video.time; // eslint-disable-line no-unused-expressions

    const videoId = `display-video-el-${entityId}`;

    const onClickEvent = onClickEntityEvent(game, entity, onEntityClick);

    return (
      <div
        id={entityId}
        className={classNames('display-video', className)}
        style={{
          ...topStyle,
          pointerEvents: 'none',
        }}
      >
        <div style={style}>
          <video
            id={videoId}
            height={style.height}
            width={style.width}
            loop={true}
            preload="auto"
            ref={this.playerRef}
            key={videoSource}
            style={{
              cursor: onClickEvent ? 'pointer' : 'inherit',
              pointerEvents: 'auto',
            }}
            onClick={onClickEvent}
          >
            <source
              src={videoSource}
              type={videoType}
            />

            {(videoSource.indexOf('webm') !== -1) && (
              <source
                src={videoSource.replace('.webm', '.mov')}
                type="video/quicktime"
              />
            )}

            Your browser is not supported for playing this video. Please use the latest Chrome or Firefox browser.
          </video>
        </div>
      </div>
    );
  }
}

DisplayVideo.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
  style: PropTypes.object.isRequired,
  topStyle: PropTypes.object.isRequired,

  className: PropTypes.string,
  onEntityClick: PropTypes.func,
};

export default entityActivateHoc(
  observer(DisplayVideo)
);
