import {observable, action} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {UncontrolledTooltip} from 'reactstrap';

import inject from '../../../../hoc/injectHoc';
import ContentRequestModal from '../../../../modals/contentRequest/ContentRequestModal';

import './contentRequestButton.scss';

/**
 * Content Request Button component.
 */
class ContentRequestButton extends React.Component {
  /**
   * Whether or not the modal is open.
   * @type {boolean}
   */
  @observable isContentRequestModalOpen = false;

  /**
   * Trigger when opening the modal.
   */
  @action onOpenModal = () => {
    this.isContentRequestModalOpen = true;
  };

  /**
   * Trigger when closing the modal.
   */
  @action onCloseModal = () => {
    this.isContentRequestModalOpen = false;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {apiUserGetMeStore, isBadge} = this.props;

    return apiUserGetMeStore.case({
      'pre': () => null,
      'pending': () => null,
      'rejected': () => null,
      'fulfilled': (user) => (
        <div className="content-request-button">
          {(isBadge) && (<button
            className="btn content-request-btn"
            id="content-request-btn"
            type="button"
            onClick={this.onOpenModal}
          >
            {user.projectContentCompany && user.projectContentCompany.requestCredits}
          </button>)}

          {(!isBadge) && (<button
            className="btn btn-light info-btn-responsive"
            id="content-request-btn"
            type="button"
            onClick={this.onOpenModal}
          >
            Submit
          </button>)}

          <UncontrolledTooltip placement="bottom" target="content-request-btn">
            Submit a Content Request
          </UncontrolledTooltip>

          {(this.isContentRequestModalOpen) && (
            <ContentRequestModal
              isOpen={true}
              hasCredits={user.projectContentCompany && user.projectContentCompany.requestCredits > 0}
              planHasCredits={user.plan ? user.plan.requestCredits > 0 : false}
              onComplete={this.onCloseModal}
            />
          )}
        </div>
      )
    });
  }
}

ContentRequestButton.propTypes = {};

ContentRequestButton.propTypes = {
  isBadge: PropTypes.bool.isRequired,
  apiUserGetMeStore: MobxPropTypes.observableObject,
};

ContentRequestButton.wrappedComponent = {};
ContentRequestButton.wrappedComponent.propTypes = {
  apiUserGetMeStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(ContentRequestButton)(
  observer(ContentRequestButton)
);
