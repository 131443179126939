import moment from 'moment';
import lodash from 'lodash';

import {ratioToNumber} from './mathHelper';
import config from '../config/main';
import {ERROR, IN_PROCESS, NEW} from '../constants/contentConstants';

/**
 * The max height allowed for the image thumbnail.
 * @const {number}
 */
export const THUMBNAIL_MAX_HEIGHT = 159;

/**
 * The max width allowed for the image thumbnail.
 * @const {number}
 */
export const THUMBNAIL_MAX_WIDTH = 220;

/**
 * Gets the url to the content's main image/video using the content file id.
 *
 * @param {number} contentFileId
 * @param {boolean} isVideo
 * @returns {string}
 */
export function getMainUrlByFileId(contentFileId, isVideo) {
  const videoPost = (isVideo) ? 'Mp4' : '';
  return `${config.api.url}/File/Video/${contentFileId}/Master${videoPost}`;
}

/**
 * Gets the url to the content's preview image/video using the content file id.
 *
 * @param {number} contentFileId
 * @returns {string}
 */
export function getPreviewUrlByFileId(contentFileId) {
  return `${config.api.url}/File/Video/${contentFileId}/Preview`;
}

/**
 * Gets the url to the content's preview image/video using the content file id.
 *
 * @param {number} contentFileId
 * @returns {string}
 */
export function getThumbnailUrlByFileId(contentFileId) {
  return `${config.api.url}/File/Video/${contentFileId}/Thumbnail`;
}

/**
 * Gets whether or not the given content file record has the file key.
 *
 * @param {{}} contentFile
 * @param {string} fileKey
 * @returns {boolean}
 */
export function getContentFileHasFile(contentFile, fileKey) {
  if (!contentFile || !contentFile[fileKey]) {
    return false;
  }
  return (String(contentFile[fileKey]).toLowerCase() !== 'unknown');
}

/**
 * Gets the url to the content's preview image/video.
 *
 * @param {{contentFiles: Array.<{id: number}>}} content
 * @param {boolean} isVideo
 * @returns {{isImage: boolean, url: ?string}}
 */
export function getPreviewUrlForContent(content, isVideo) {
  if (!content.contentFiles || !content.contentFiles.length || !content.contentFiles[0]) {
    return {
      isImage: true,
      url: null,
    };
  }

  const contentFile = content.contentFiles[0];

  const hasThumbnail = getContentFileHasFile(contentFile, 'thumbnailFileName');
  const hasBgContent = Boolean(contentFile.createBgContentId);

  // Processing videos must show an image (either their thumbnail or the thumbnail of their bg content).
  if (isVideo && (isProcessing(content) || hasProcessingError(content))) {
    if (hasThumbnail) {
      return {
        isImage: true,
        url: getThumbnailUrlByFileId(contentFile.id),
      };
    }

    return {
      isImage: true,
      url: (hasBgContent) ? getThumbnailUrlByFileId(contentFile.createBgContentId) : null,
    };
  }

  const hasPreviewFile = getContentFileHasFile(contentFile, 'previewFileName');
  const hasImageMasterFile = getContentFileHasFile(contentFile, 'masterFileName');

  // Order of display masterFile/previewFile > thumbnail > bgContentFile

  if (!isVideo && hasImageMasterFile) {
    return {
      isImage: true,
      url: getMainUrlByFileId(contentFile.id),
    };
  } else if (isVideo && hasPreviewFile) {
    return {
      isImage: false,
      url: getPreviewUrlByFileId(contentFile.id),
    };
  } else if (hasThumbnail) {
    return {
      isImage: true,
      url: getThumbnailUrlByFileId(contentFile.id),
    };
  } else if (!hasBgContent) {
    return {
      isImage: true,
      url: null,
    };
  }

  const bgContentFileId = contentFile.createBgContentId;
  return {
    isImage: !isVideo,
    url: getMainUrlByFileId(bgContentFileId, isVideo),
  };
}

/**
 * Gets the url to the content's thumbnail.
 *
 * @param {{contentFiles: Array.<{id: number}>}} content
 * @returns {?string}
 */
export function getThumbnailUrlForContent(content) {
  if (!content.contentFiles || !content.contentFiles.length) {
    return null;
  }

  const firstContentFile = content.contentFiles[0];

  const hasThumbnail = getContentFileHasFile(firstContentFile, 'thumbnailFileName');
  const hasBgContent = Boolean(firstContentFile.createBgContentId);

  if (hasThumbnail) {
    return getThumbnailUrlByFileId(firstContentFile.id);
  } else if (!hasBgContent) {
    return null;
  }

  return getThumbnailUrlByFileId(firstContentFile.createBgContentId);
}

/**
 * Moves the content to a new category.
 *
 * @param {{id: number}} content
 * @param {{categoryId: number, library: {}, libraryId: number, libraryType: number}} newLocationData
 * @returns {Promise.<number>}
 */
export function moveContentLocation(content, newLocationData) { // eslint-disable-line no-unused-vars
  // Move content and delete this promise.
  return Promise.resolve(true);
}

/**
 * Clones the content to a new category.
 *
 * @param {{id: number}} content
 * @param {{
 *   categoryId: number,
 *   library: {},
 *   libraryId: number,
 *   libraryType: number,
 *   contentName: string,
 *   isGlobal: boolean
 * }} newLocationData
 * @returns {Promise.<number>}
 */
export function cloneContent(content, newLocationData) { // eslint-disable-line no-unused-vars
  // Clone content and delete this promise.
  return Promise.resolve(true);
}

/**
 * Determines if a content is currently processing.
 *
 * @param {{}} content
 * @param {{allowNew: boolean}=} options
 * @returns {boolean}
 */
export function isProcessing(content, options) {
  if (!content.contentFiles || !content.contentFiles.length) {
    return false;
  }

  const stateId = Number(content.contentFiles[0].contentFileStateId);

  const safeOptions = options || {};
  if (safeOptions.allowNew && stateId === NEW) {
    return false;
  }

  return lodash.includes(IN_PROCESS, stateId);
}

/**
 * Determines if a content threw an error during processing.
 *
 * @param {{}} content
 * @returns {boolean}
 */
export function hasProcessingError(content) {
  if (!content.contentFiles || !content.contentFiles.length) {
    return false;
  }
  return (ERROR === content.contentFiles[0].contentFileStateId);
}

/**
 * Determines whether or not the processing for content has expired, meaning that it is unlikely to finish.
 *
 * @param {{}} content
 * @returns {boolean}
 */
export function hasProcessingExpired(content) {
  if (!content.contentFiles || !content.contentFiles.length) {
    return false;
  } else if (!isProcessing(content, {allowNew: true})) {
    return false;
  }

  const firstContentFile = content.contentFiles[0];
  const lastUpdated = moment(firstContentFile.updateDate);
  const oneDayAgo = moment().subtract(1, 'day');

  return (lastUpdated.isBefore(oneDayAgo));
}

/**
 * Gets a width and height for the image that maintains the aspect ratio but stays within the max width and height.
 *
 * @param {number} productWidth
 * @param {number} productHeight
 * @param {number} maxWidth
 * @param {number} maxHeight
 * @returns {{height: number, width: number}}
 */
export function getAspectRatioFit(productWidth, productHeight, maxWidth, maxHeight) {
  const ratio = Math.min(maxWidth / productWidth, maxHeight / productHeight);

  const MAX_DECIMALS = 4;
  return {
    height: Number.parseFloat(productHeight * ratio).toFixed(MAX_DECIMALS),
    width: Number.parseFloat(productWidth * ratio).toFixed(MAX_DECIMALS),
  };
}

/**
 * Parses the content sources into sizes that fit the sign sizes.
 *
 * @param {Array.<{aspectRatio: string}>} signs
 * @param {{}} content
 * @param {Array.<{aspectRatio: string}>} defaultSigns
 * @returns {{}} The fitted content sources.
 */
export function parseContentIntoSignSizes(signs, content, defaultSigns) {
  const sources = content.sources;

  let validSigns = (signs || []).filter((sign) => sign.aspectRatio);
  if (!validSigns.length && defaultSigns) {
    validSigns = defaultSigns;
  }

  const existingRatios = lodash.map(sources, (source, sourceAspectRatio) => {
    return {ratioMultiplier: ratioToNumber(sourceAspectRatio), source};
  });

  const fittedSources = {};
  validSigns.forEach((sign) => {
    const signAspectRatio = sign.aspectRatio;
    const aspectMultiplier = ratioToNumber(signAspectRatio);
    const signDimensions = {
      height: sign.height,
      width: sign.width,
      aspectRatio: signAspectRatio
    };

    // if user's sign aspect ratio is found, use content as is
    if (sources[signAspectRatio]) {
      const newSource = lodash.cloneDeep(sources[signAspectRatio]);
      newSource.signDimensions = signDimensions;
      newSource.aspectRatio = signAspectRatio;

      fittedSources[signAspectRatio] = newSource;
      return;
    }

    // First find the closest matching existing aspect ratio.
    const sortedRatios = lodash.sortBy(existingRatios, ({ratioMultiplier}) => {
      return Math.abs(ratioMultiplier - aspectMultiplier);
    });

    // use closest aspect ratio
    const closestSource = sortedRatios[0];
    const newSource = lodash.cloneDeep(closestSource.source);
    newSource.signDimensions = signDimensions;
    newSource.aspectRatio = signAspectRatio;

    // we are not setting width or height based on sign width or height
    // because we want to preview the closest aspect ratio to the user
    // if we preview with the signs width & height (at the unsupported aspect ratio)
    // assets would not fit correctly

    fittedSources[signAspectRatio] = newSource;
  });

  return fittedSources;
}
