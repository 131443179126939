import React from 'react';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faTimes} from '@fortawesome/free-solid-svg-icons';

import {Modal} from 'reactstrap';

import Link from '../../common/link/Link';
import DownloadCreditsBadge from './components/downloadCreditsBadge/DownloadCreditsBadge';
import ContentRequestButton from './components/contentRequestButton/ContentRequestButton';
import EditorNavItem from './components/editorNavItem/EditorNavItem';
import EditorTemplateNavItem from './components/editorTemplateNavItem/EditorTemplateNavItem';
import EnvironmentWarning from './components/environmentWarning/EnvironmentWarning';
import LogoNavItem from './components/logoNavItem/LogoNavItem';
import UserNavItem from './components/userNavItem/UserNavItem';
import inject from '../../hoc/injectHoc';
import {dashboardRoute, pricingRoute} from '../../routePaths';
import mainConfig from '../../../config/main';
import {NAV_DASHBOARD, NAV_EDITOR, NAV_PRESTO} from '../../../constants/navConstants';
import {buildWordpressUrl} from '../../../utils/routeHelper';

import './mainNavBar.scss';

const assetBase = mainConfig.assets.marketingUrl;
const s3Assets = {
  prestoTutorialVideo: `${assetBase}/presto-tutorial.mp4`
};

/**
 * The MainNavBar component.
 */
export class MainNavBar extends React.Component {
  /**
   * Flag controlling the state of the Presto tutorial modal.
   *
   * @type {boolean}
   */
  @observable isPrestoModalOpen = false;

  /**
   * Opens Presto tutorial modal.
   */
  @action openPrestoModal = () => {
    this.isPrestoModalOpen = true;
  }

  /**
   * Closes Presto tutorial modal.
   */
  @action closePrestoModal = () => {
    this.isPrestoModalOpen = false;
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {isPrestoModalOpen} = this;
    const {navType, navigateContentStore, apiUserGetMeStore} = this.props;

    const appUser = apiUserGetMeStore.getFulfilled();
    const showCreditBadges = appUser && appUser.role;

    const isEditorOrPresto = (navType === NAV_PRESTO || navType === NAV_EDITOR);
    const isDashboard = (navType === NAV_DASHBOARD);
    const showEnvWarnings = mainConfig.navBar.showEnvWarnings;

    const whatIsPrestoLink = buildWordpressUrl('/what-is-presto');
    const faqLink = buildWordpressUrl('/faq');
    const tutorialsLink = buildWordpressUrl('/tutorials');
    const blogLink = buildWordpressUrl('/blog');
    const caseStudiesLink = buildWordpressUrl('/case-studies');
    const contactUsLink = buildWordpressUrl('/contact');
    const referFriendLink = 'https://info.projectcontent.com/refer-a-friend';
    const contentPlaybookLink = 'https://info.projectcontent.com/playbook-0';

    const {isDashboardSidebarOpen} = navigateContentStore;

    return (
      <>
        <header id="main-navbar">
          <nav className="navbar navbar-expand-lg navbar-light">
            {(isEditorOrPresto) ? (
              <div className="navbar-left d-flex align-items-center col-6">
                <EditorNavItem />
                <EditorTemplateNavItem />
              </div>
            ) : (
              <div className={classNames('navbar-left navbar-left-logo-container d-flex align-items-center', {'dashboard-menu': isDashboard})}>
                {(isDashboard) && (
                  <button
                    type="button"
                    className="btn btn-icon btn-lg d-lg-none"
                    onClick={() => {
                      navigateContentStore.toggleDashboardSidebar();
                    }}
                  >
                    <FontAwesomeIcon
                      icon={isDashboardSidebarOpen ? faTimes : faBars}
                    />
                  </button>
                )}

                <LogoNavItem />
              </div>
            )}

            <button
              className="navbar-toggle-button ml-auto btn btn-outline-light d-lg-none"
              type="button"
              data-toggle="collapse"
              data-target="#mainNavbar"
              aria-controls="mainNavbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <FontAwesomeIcon
                className="font-weight-bold"
                icon={faBars}
              />
            </button>

            <div className="collapse navbar-collapse" id="mainNavbar">
              <ul className="navbar-nav ml-auto">
                {(showEnvWarnings) && (
                  <li className="nav-item">
                    <EnvironmentWarning shouldShow={true} />
                  </li>
                )}

                {showCreditBadges && (
                  <>
                    <li className="nav-item">
                      <span className="badge-item" id="badge-item-request">
                        <ContentRequestButton
                          isBadge={true}
                        />

                        <div className="badge-label">
                          Custom Requests
                        </div>
                      </span>
                    </li>
                    <li className="nav-item badge-item" id="badge-item-download">
                      <DownloadCreditsBadge />

                      <div className="badge-label">
                        Content Downloads
                      </div>
                    </li>
                  </>
                )}
                <li className="nav-item">
                  <a href={whatIsPrestoLink} className="nav-link btn btn-link">
                    What is Presto?
                  </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link btn btn-link" to={dashboardRoute}>Templates</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link btn btn-link" to={pricingRoute}>Pricing</Link>
                </li>
                <li className="nav-item nav-dropdown">
                  <button type="button" className="nav-link nav-dropdown-toggle btn btn-link">Learn</button>
                  <ul className="nav-dropdown-item-list">
                    <li className="nav-dropdown-item">
                      <a className="btn btn-link" href={faqLink}>FAQ</a>
                    </li>
                    <li className="nav-dropdown-item">
                      <a className="btn btn-link" href={tutorialsLink}>Tutorials</a>
                    </li>
                    <li className="nav-dropdown-item">
                      <a className="btn btn-link" href={blogLink}>Blog</a>
                    </li>
                    <li className="nav-dropdown-item">
                      <a className="btn btn-link" href={caseStudiesLink}>Case Studies</a>
                    </li>
                    <li className="nav-dropdown-item">
                      <a className="btn btn-link" href={contentPlaybookLink}>Content Playbook</a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a className="nav-link btn btn-link" href={contactUsLink}>Contact Us</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link btn btn-link" href={referFriendLink}>Refer A Friend</a>
                </li>
                <UserNavItem isResponsive={false} />
              </ul>
            </div>
          </nav>
        </header>

        <Modal className="content-info-modal" isOpen={isPrestoModalOpen} toggle={this.closePrestoModal} centered>
          <div className="content-preview">
            <video className="modal-preview" autoPlay playsInline controls controlsList="nodownload">
              <source
                src={s3Assets.prestoTutorialVideo}
                type="video/mp4"
              />

              Your browser is not supported for playing this video. Please use the latest Chrome or Firefox browser.
            </video>
          </div>
        </Modal>
      </>
    );
  }
}

MainNavBar.propTypes = {
  apiUserGetMeStore: MobxPropTypes.observableObject,
  navType: PropTypes.symbol,
  navigateContentStore: MobxPropTypes.observableObject,
};

MainNavBar.defaultProps = {
  navType: null,
};

MainNavBar.wrappedComponent = {};
MainNavBar.wrappedComponent.propTypes = {
  apiUserGetMeStore: MobxPropTypes.observableObject.isRequired,
  navigateContentStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(MainNavBar)(
  observer(MainNavBar)
);
