/**
 * The resize top left corner.
 * @const {string}
 */
export const TOP_LEFT = 'topLeft';

/**
 * The resize top middle corner.
 * @const {string}
 */
export const TOP_MIDDLE = 'topMiddle';

/**
 * The resize top right corner.
 * @const {string}
 */
export const TOP_RIGHT = 'topRight';

/**
 * The resize middle left corner.
 * @const {string}
 */
export const MIDDLE_LEFT = 'middleLeft';

/**
 * The resize middle right corner.
 * @const {string}
 */
export const MIDDLE_RIGHT = 'middleRight';

/**
 * The resize bottom left corner.
 * @const {string}
 */
export const BOTTOM_LEFT = 'bottomLeft';

/**
 * The resize bottom middle corner.
 * @const {string}
 */
export const BOTTOM_MIDDLE = 'bottomMiddle';

/**
 * The resize bottom right corner.
 * @const {string}
 */
export const BOTTOM_RIGHT = 'bottomRight';

/**
 * The cursors for each resize position/corner.
 * @const {Object.<string, string>}
 */
export const CURSORS = {
  [TOP_LEFT]: 'nw-resize',
  [TOP_MIDDLE]: 'n-resize',
  [TOP_RIGHT]: 'ne-resize',
  [MIDDLE_LEFT]: 'w-resize',
  [MIDDLE_RIGHT]: 'e-resize',
  [BOTTOM_LEFT]: 'sw-resize',
  [BOTTOM_MIDDLE]: 's-resize',
  [BOTTOM_RIGHT]: 'se-resize',
};

/**
 * The order of cursors rotating counter-clockwise.
 *
 * @type {Array.<{position: string, cursor: string}>}
 */
export const ROTATED_CURSORS = [
  {position: TOP_MIDDLE, cursor: 'n-resize'},
  {position: TOP_RIGHT, cursor: 'ne-resize'},
  {position: MIDDLE_RIGHT, cursor: 'e-resize'},
  {position: BOTTOM_RIGHT, cursor: 'se-resize'},
  {position: BOTTOM_MIDDLE, cursor: 's-resize'},
  {position: BOTTOM_LEFT, cursor: 'sw-resize'},
  {position: MIDDLE_LEFT, cursor: 'w-resize'},
  {position: TOP_LEFT, cursor: 'nw-resize'},
];

/**
 * All the left orientation positions.
 * @const {string[]}
 */
export const LEFTS = [
  TOP_LEFT,
  MIDDLE_LEFT,
  BOTTOM_LEFT,
];

/**
 * All the right orientation positions.
 * @const {string[]}
 */
export const RIGHTS = [
  TOP_RIGHT,
  MIDDLE_RIGHT,
  BOTTOM_RIGHT,
];

/**
 * All the top orientation positions.
 * @const {string[]}
 */
export const TOPS = [
  TOP_LEFT,
  TOP_MIDDLE,
  TOP_RIGHT,
];

/**
 * All the bottom orientation positions.
 * @const {string[]}
 */
export const BOTTOMS = [
  BOTTOM_LEFT,
  BOTTOM_MIDDLE,
  BOTTOM_RIGHT,
];

/**
 * All the pure corner positions.
 * @const {string[]}
 */
export const CORNERS = [
  TOP_LEFT,
  TOP_RIGHT,
  BOTTOM_LEFT,
  BOTTOM_RIGHT,
];
