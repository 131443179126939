import lodash from 'lodash';
import {action} from 'mobx';
import moment from 'moment';

import apiFileGetFolderFilesStore from './apiFileGetFolderFilesStore';
import apiFileGetOneStore from './apiFileGetOneStore';
import ApiMapBaseStore from '../common/apiMapBaseStore';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import serverApi from '../../../utils/serverApi';

/**
 * The amount of time between polling calls.
 * @const {number[]}
 */
const POLLING_INTERVALS = [
  // eslint-disable-next-line no-magic-numbers
  10000
];

/**
 * The number of errors that will cancel a poll.
 * @const {number}
 */
const CANCEL_ERROR_COUNT = 4;

/**
 * The maximum number of times polling will occur.
 * @const {number}
 */
const MAX_POLL_COUNT = 180;

/**
 * Polls for file status updates from the api.
 */
class ApiFilePollStatusStore extends ApiMapBaseStore {
  /**
   * The map of ids that can be used to cancel the active polling.
   *
   * @type {Object.<number, number>}
   */
  cancelTimeoutIds = {};

  /**
   * Whether or not the given file is processing.
   *
   * @param {{status: string}} file
   * @returns {boolean}
   */
  isFileProcessing = (file) => {
    if (!file || !file.status) {
      return false;
    }

    return (file.status !== 'ready' && file.status !== 'error');
  };

  /**
   * Whether or not the given file has an error status.
   *
   * @param {{status: string}} file
   * @returns {boolean}
   */
  isFileError = (file) => {
    if (!file || !file.status) {
      return false;
    }

    return (file.status === 'error');
  };

  /**
   * Clears all the resource info
   */
  @action clearAll() {
    lodash.forEach(this.cancelTimeoutIds, (timeoutId) => {
      clearTimeout(timeoutId);
    });

    this.dataMap.clear();
  }

  /**
   * Cancels any active polling for the given file id.
   *
   * @param {number} fileId
   */
  cancelPollForFile(fileId) {
    const safeFileId = String(fileId);

    const timeoutId = this.cancelTimeoutIds[safeFileId];
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    this.dataMap.delete(safeFileId);
  }

  /**
   * Gets the next poll interval.
   *
   * @param {number} currentCount
   * @returns {number}
   */
  getNextPollInterval = (currentCount) => {
    const safeCount = Number(currentCount);

    if (!currentCount || !safeCount) {
      return POLLING_INTERVALS[0];
    } else if (POLLING_INTERVALS[safeCount]) {
      return POLLING_INTERVALS[safeCount];
    }

    return lodash.last(POLLING_INTERVALS);
  };

  /**
   * Checks if file is already being polled.
   *
   * @param {number} fileId
   * @returns {boolean}
   */
  isPollingInProgress(fileId) {
    const safeFileId = String(fileId);

    const currentResource = this.dataMap.get(safeFileId);
    return (currentResource && currentResource.state === STATE_PENDING);
  }

  /**
   * Checks if the item has not be updated in a while, which might indicate the processing has stalled out.
   *
   * @param {{id: number, updatedAt: string}} file
   * @returns {boolean}
   */
  hasProcessingExpired(file) {
    const updated = moment(file.updatedAt);
    const limit = moment(file.processingExpireTime);

    return updated.isBefore(limit);
  }

  /**
   * Polls file from the server until the enters a good file state.
   *
   * @param {number} fileId
   */
  @action pollFileById(fileId) {
    const safeFileId = String(fileId);

    if (this.isPollingInProgress(safeFileId)) {
      return;
    }

    this.dataMap.set(safeFileId, {
      state: STATE_PENDING,
      count: 0,
      data: null,
      errorCount: 0,
      error: null,
      successError: false,
    });

    this.cancelTimeoutIds[safeFileId] = setTimeout(() => {
      this.pollingLoop(safeFileId);
    }, this.getNextPollInterval(0));
  }

  /**
   * Starts or continues a polling loop.
   * Should not be called directly.
   *
   * @param {number} fileId
   */
  @action pollingLoop(fileId) {
    const safeFileId = String(fileId);

    // Get the simple file status info.
    serverApi.fileGetStatus(safeFileId).then(
      action('getFileSuccess', (foundFile) => {
        const currentPoll = this.dataMap.get(safeFileId);
        const newCount = currentPoll.count + 1;

        const isPending = this.isFileProcessing(foundFile);
        if (!isPending) {
          delete this.cancelTimeoutIds[safeFileId];

          this.endPollingSuccess(safeFileId, foundFile, newCount);
          return;
        }

        if (newCount >= MAX_POLL_COUNT) {
          delete this.cancelTimeoutIds[safeFileId];

          this.dataMap.set(safeFileId, {
            ...currentPoll,
            state: STATE_REJECTED,
            count: newCount,
            error: new Error('Polling max count reached.'),
          });
          return;
        }

        this.dataMap.set(safeFileId, {
          ...currentPoll,
          count: newCount,
        });

        this.cancelTimeoutIds[safeFileId] = setTimeout(() => {
          this.pollingLoop(safeFileId);
        }, this.getNextPollInterval(newCount));
      }),
      action('getFileError', (error) => {
        const currentPoll = this.dataMap.get(safeFileId);
        const newCount = currentPoll.errorCount + 1;

        if (newCount >= CANCEL_ERROR_COUNT) {
          delete this.cancelTimeoutIds[safeFileId];

          this.dataMap.set(safeFileId, {
            ...currentPoll,
            state: STATE_REJECTED,
            errorCount: newCount,
            error,
          });

          return;
        }

        this.dataMap.set(safeFileId, {
          ...currentPoll,
          state: STATE_REJECTED,
          errorCount: newCount,
          error,
        });

        this.cancelTimeoutIds[safeFileId] = setTimeout(() => {
          this.pollingLoop(safeFileId);
        }, this.getNextPollInterval(currentPoll.count));
      })
    );
  }

  /**
   * Finishes a polling as a success.
   *
   * @param {number} fileId
   * @param {{}} file
   * @param {number} newCount
   */
  @action endPollingSuccess(fileId, file, newCount) {
    const safeFileId = String(fileId);
    const currentPoll = this.dataMap.get(safeFileId);

    // Get the full file info including folder information.
    serverApi.fileGetOne(fileId, true).then(
      action('getFileSuccess', (foundFile) => {
        this.dataMap.set(safeFileId, {
          ...currentPoll,
          state: STATE_FULFILLED,
          count: newCount,
          data: foundFile,
          error: null,
        });
      }),
      action('getFileError', (error) => {
        this.dataMap.set(safeFileId, {
          ...currentPoll,
          state: STATE_REJECTED,
          count: newCount,
          data: null,
          error,
          successError: true,
        });
      })
    );
  }

  /**
   * Polls file and updates it in the other stores when it is finished processing.
   *
   * @param {number} fileId
   */
  pollThenUpdateFile(fileId) {
    const safeFileId = String(fileId);

    this.pollFileById(safeFileId);

    this.getPromise(safeFileId).then((file) => {
      if (!file || !file.id) {
        return;
      }

      apiFileGetOneStore.refresh(safeFileId, true);

      if (file.folderIds && file.folderIds.length) {
        file.folderIds.map((folderId) => {
          apiFileGetFolderFilesStore.refresh(folderId, 1, true);
        });
      }
    }).catch(() => {
      // We will just ignore polling errors.
    });
  }
}

export default new ApiFilePollStatusStore();
