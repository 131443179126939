import lodash from 'lodash';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * The searchable and sortable table body component.
 *
 * @constructor
 */
export class SmartTBody extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {className} = this.props;

    const columns = this.props.columns || [];
    const rows = this.props.rows || [];

    return (
      <tbody className={className}>
        {rows.map((row) => {
          if (!row.id) {
            throw new Error('SmartTBody: Every row must have an truthy id property.');
          } else if (row.hide) {
            return null;
          }

          if (row.colSpanKey) {
            const colSpan = columns.length;
            const column = lodash.find(columns, (findColumn) => {
              return findColumn.key === row.colSpanKey;
            });

            if (!column) {
              throw new Error('SmartTBody: colSpanKey did not match any columns.');
            }

            return (
              <tr key={row.id} className={row.className}>
                <td
                  key={column.key}
                  className={column.className}
                  colSpan={colSpan}
                >{row[column.key] || ''}</td>
              </tr>
            );
          }

          return (
            <tr key={row.id} className={row.className}>
              {columns.map((column) => {
                if (column.hide) {
                  return null;
                }

                return (
                  <td
                    key={column.key}
                    className={column.className}
                    colSpan={column.colSpan}
                  >{row[column.key] || ''}</td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  }
}

SmartTBody.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,

  className: PropTypes.string,
  id: PropTypes.string,
};

export default observer(SmartTBody);
