import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import './environmentWarning.scss';

/**
 * The EnvironmentWarning component.
 */
export class EnvironmentWarning extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {shouldShow} = this.props;

    if (!shouldShow) {
      return null;
    }

    return (
      <div id="environment-warning" className="d-flex align-items-center">
        <div className="warning-banner">Dev Mode</div>
      </div>
    );
  }
}

EnvironmentWarning.propTypes = {
  shouldShow: PropTypes.bool,
};

EnvironmentWarning.defaultProps = {
  shouldShow: false,
};

export default observer(EnvironmentWarning);
