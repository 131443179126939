import lodash from 'lodash';

/**
 * Tells the parser to split the text into individual letters.
 * This is used for animation purposes.
 * @const {string}
 */
export const SPLIT_ON_LETTERS = 'splitOnLetters';

/**
 * Tells the parser to split the text into individual words.
 * This is used for animation purposes.
 * @const {string}
 */
export const SPLIT_ON_WORDS = 'splitOnWords';

/**
 * Parses the given text from markdown to word/letter objects.
 *
 * @param {string} markdownText
 * @param {string=} splitOn
 * @param {{}=} textComponent
 * @returns {Array.<Array.<{}>>}
 */
export function parseMarkdownToWords(markdownText, splitOn, textComponent) {
  if (!markdownText) {
    return [];
  }

  const rawLines = String(markdownText).split(/\n/).filter((item) => item);

  return rawLines.map((rawLine, lineIndex) => {
    const lineWords = lineToWords(rawLine, lineIndex, splitOn);
    if (!lineWords) {
      return null;
    }

    const lineAlignment = getLineAlignment(lineWords);
    const transitionWords = addTransitionPropertiesToWords(lineWords);

    const finalWords = parseComponentValues(transitionWords, textComponent);

    return {
      id: lineIndex + 1,
      alignment: lineAlignment,
      ...getLineSize(lineWords),
      words: finalWords,
    };
  }).filter((item) => item);
}

/**
 * Splits a markdown line into its words.
 *
 * @param {string} markdownLine
 * @param {number} lineIndex
 * @param {boolean} splitOn
 * @returns {?Array.<{}>}
 */
function lineToWords(markdownLine, lineIndex, splitOn) {
  if (!markdownLine) {
    return null;
  }

  const lineParts = String(markdownLine).split(
    /({[^}]+?}|\+\+|\*+)/
  ).filter(
    (item) => item
  ).reduce((final, item) => {
    if (item === '_') {
      // If we don't check for just underscore, the next check will break italics when mixed with bold/underline.
      final.push(item);
    } else if (lodash.startsWith(item, '_') && lodash.endsWith(item, '_')) {
      // Accounts for markdown italics which are surrounded by underscores.
      final.push('_');
      final.push(lodash.trim(item, '_'));
      final.push('_');
    } else {
      final.push(item);
    }

    return final;
  }, []);

  return parseWordCluster([], lineParts, lineIndex, {
    alignment: [],
    lineHeight: [],
    letterSpacing: [],
    fill: [],
    fontSize: [],
    font: [],
    isBold: [],
    isBoldClose: false,
    isItalic: [],
    isItalicClose: false,
    isUnderlined: [],
    isUnderlinedClose: false,
  }, splitOn);
}

/**
 * Parses the word clusters for the given line parts.
 *
 * @param {Array.<{}>} words
 * @param {Array.<{}>} lineParts
 * @param {number} lineIndex
 * @param {Object.<string, Array>} states
 * @param {string=} splitOn
 * @returns {Array.<{}>}
 */
function parseWordCluster(words, lineParts, lineIndex, states, splitOn) {
  if (!lineParts || !lineParts.length) {
    return words;
  }

  // Sometimes spaces won't have a font size, so we need to get the previous word's size to use for them.
  let lastFontSize = 0;

  lineParts.forEach((linePart) => {
    if (linePart.substr(0, 2) === '{!') {
      parseBracesCloseState(linePart, states);
      return;
    } else if (linePart.substr(0, 1) === '{') {
      parseBracesIntoState(linePart, states);
      return;
    }

    // Account for default markdown like ++, **, etc.
    if (parseMarkdownInlines(linePart, states)) {
      return;
    }

    const closeTag = 'Close';
    const wordState = lodash.reduce(states, (finalState, stateValues, stateName) => {
      if (stateName.substr(0 - closeTag.length) === closeTag) {
        return finalState;
      }

      const lastValue = lodash.last(stateValues);
      if (lastValue !== undefined) {
        finalState[stateName] = lodash.last(stateValues);
      }

      return finalState;
    }, {});

    if (wordState.fontSize) {
      lastFontSize = wordState.fontSize;
    }

    const safeText = linePart.replace(/\\/g, '');

    let textParts = [safeText];
    if (splitOn === SPLIT_ON_LETTERS) {
      // Parse each letter into its own word object.
      textParts = safeText.split('');
    } else if (splitOn === SPLIT_ON_WORDS) {
      // Parse each words in the text into its own word object.
      textParts = safeText.split(' ');
    }

    textParts.forEach((textPart, textIndex) => {
      const isLast = (textIndex === textParts.length - 1);
      const text = (splitOn === SPLIT_ON_WORDS && !isLast) ? textPart + ' ' : textPart;

      words.push({
        id: `${lineIndex + 1}-${words.length + 1}`,
        text,
        letterSpacing: 0,
        lineHeight: 1,
        opacity: 1,
        fontSize: lastFontSize,
        ...wordState,
      });
    });
  });

  return words;
}

/**
 * Parses markdown items in braces into the state.
 *
 * @param {string} linePart
 * @param {Object.<string, Array>} states
 * @returns {{}}
 */
function parseBracesIntoState(linePart, states) {
  const start = linePart.substring(1, 2);
  const inside = linePart.substring(1, linePart.length - 1);

  if (linePart === '{-}') {
    // Deprecated, do nothing.
  } else if (start === '\\') {
    states.alignment.push('left');
  } else if (start === '|') {
    states.alignment.push('center');
  } else if (start === '/') {
    states.alignment.push('right');
  } else if (start === '$') {
    states.lineHeight.push(Number(inside.substring(1)));
  } else if (start === '%') {
    states.letterSpacing.push(Number(inside.substring(1)));
  } else if (start === '#') {
    states.fill.push(inside);
  } else if (inside.match(/^[0-9]+$/)) {
    states.fontSize.push(Number(inside));
  } else if (inside.match(/^[a-zA-Z ]+$/)) {
    states.font.push(inside);
  }

  return states;
}

/**
 * Parses markdown item closures into the state.
 *
 * @param {string} linePart
 * @param {Object.<string, Array>} states
 * @returns {{}}
 */
function parseBracesCloseState(linePart, states) {
  const start = linePart.substring(2, 3); // eslint-disable-line no-magic-numbers
  const inside = linePart.substring(2, linePart.length - 1);

  if (linePart === '{!-}') {
    // Deprecated, do nothing.
  } else if (start === '\\') {
    const lastIndex = lodash.lastIndexOf(states.alignment, 'left');
    lodash.pullAt(states.alignment, [lastIndex]);
  } else if (start === '|') {
    const lastIndex = lodash.lastIndexOf(states.alignment, 'center');
    lodash.pullAt(states.alignment, [lastIndex]);
  } else if (start === '/') {
    const lastIndex = lodash.lastIndexOf(states.alignment, 'right');
    lodash.pullAt(states.alignment, [lastIndex]);
  } else if (start === '$') {
    const lastIndex = lodash.lastIndexOf(states.lineHeight, Number(inside.substring(1)));
    lodash.pullAt(states.lineHeight, [lastIndex]);
  } else if (start === '%') {
    const lastIndex = lodash.lastIndexOf(states.letterSpacing, Number(inside.substring(1)));
    lodash.pullAt(states.letterSpacing, [lastIndex]);
  } else if (start === '#') {
    const lastIndex = lodash.lastIndexOf(states.fill, inside);
    lodash.pullAt(states.fill, [lastIndex]);
  } else if (inside.match(/^[0-9]+$/)) {
    const lastIndex = lodash.lastIndexOf(states.fontSize, Number(inside));
    lodash.pullAt(states.fontSize, [lastIndex]);
  } else if (inside.match(/^[a-zA-Z ]+$/)) {
    const lastIndex = lodash.lastIndexOf(states.font, inside);
    lodash.pullAt(states.font, [lastIndex]);
  }

  return states;
}

/**
 * Parses regular markdown items (bold, italic, underline, etc) into the state.
 *
 * @param {string} linePart
 * @param {Object.<string, Array>} states
 * @returns {{}}
 */
function parseMarkdownInlines(linePart, states) {
  if (linePart.substr(0, 2) === '++') {
    if (states.isUnderlinedClose) {
      states.isUnderlined.pop();
      states.isUnderlinedClose = false;
    } else {
      states.isUnderlined.push(true);
      states.isUnderlinedClose = true;
    }
    return states;
  }

  if (linePart.substr(0, 1) === '*') {
    if (states.isBoldClose) {
      states.isBold.pop();
      states.isBoldClose = false;
    } else {
      states.isBold.push(true);
      states.isBoldClose = true;
    }
    return states;
  }

  if (linePart.substr(0, 1) === '_') {
    if (states.isItalicClose) {
      states.isItalic.pop();
      states.isItalicClose = false;
    } else {
      states.isItalic.push(true);
      states.isItalicClose = true;
    }
    return states;
  }

  return null;
}

/**
 * Gets the size for a line.
 *
 * @param {Array.<{}>} lineWords
 * @returns {{letterSpacing: number, lineHeight: number}}
 */
function getLineSize(lineWords) {
  return lineWords.reduce((found, word) => {
    if (word.letterSpacing && word.letterSpacing > found.letterSpacing) {
      found.letterSpacing = word.letterSpacing;
    }
    if (word.lineHeight && word.lineHeight > found.lineHeight) {
      found.lineHeight = word.lineHeight;
    }
    return found;
  }, {letterSpacing: 1, lineHeight: 0});
}

/**
 * Gets the alignment for a line.
 *
 * @param {Array.<{}>} lineWords
 * @returns {string}
 */
function getLineAlignment(lineWords) {
  return lineWords.reduce((previousAlignment, word) => {
    if (previousAlignment) {
      return previousAlignment;
    } else if (word.alignment) {
      return word.alignment;
    }
    return previousAlignment;
  }, null) || 'left';
}

/**
 * Puts the dy for each word.
 *
 * @param {Array.<{}>} lineWords
 * @returns {Array.<{}>}
 */
function addTransitionPropertiesToWords(lineWords) {
  return lineWords.map((word) => {
    return {
      ...word,
      blur: 0,
      brightness: 1,
      contrast: 1,
      'drop-shadow': null,
      'hue-rotate': 0,
      invert: 0,
      opacity: 1,
      perspective: null,
      perspectiveOrigin: null,
      rotate: 0,
      rotateX: 0,
      rotateY: 0,
      rotateZ: 0,
      saturate: 0,
      scale: 1,
      scaleX: 1,
      scaleY: 1,
      scaleZ: 1,
      sepia: 0,
      skew: 0,
      skewX: 0,
      skewY: 0,
      stroke: null,
      translate: 0,
      translateX: 0,
      translateY: 0,
      translateZ: 0,
    };
  });
}

/**
 * Parses the component values.
 *
 * @param {Object[]} words
 * @param {{}} textComponent
 * @returns {Object[]}
 */
function parseComponentValues(words, textComponent) {
  if (!textComponent) {
    return words;
  }

  const {dropShadow, opacity, stroke} = textComponent;

  const dropShadowToEm = 100;
  const strokeToEm = 1000;

  return words.map((word) => {
    const newValues = {};
    if (dropShadow && (dropShadow.x || dropShadow.y)) {
      const shadowValues = lodash.defaults(dropShadow, {
        blur: 0,
        color: '#000',
        x: 0,
        y: 0,
      });
      const xInEm = shadowValues.x / dropShadowToEm;
      const yInEm = shadowValues.y / dropShadowToEm;
      newValues['drop-shadow'] = `${xInEm}em ${yInEm}em ${shadowValues.blur}px ${shadowValues.color}`;
    }

    if (stroke) {
      const strokeValues = lodash.defaults(stroke, {
        color: '#000',
        width: 0,
      });
      const widthInEm = strokeValues.width / strokeToEm;

      newValues.stroke = `${widthInEm}em ${strokeValues.color}`;
    }

    if (opacity !== undefined && opacity !== 1) {
      newValues.opacity = Number(opacity) || 1;
    }

    return {
      ...word,
      ...newValues,
    };
  });
}
