import PropTypes from 'prop-types';
import React from 'react';
import {observer} from 'mobx-react';
import classNames from 'classnames';

import cookieHelper from '../../../utils/cookieHelper';
import MainNavBar from '../../common/mainNavBar/MainNavBar';
import UserEmulationBar from '../../common/userEmulationBar/UserEmulationBar';
import MaintenanceModeBar from '../../common/maintenanceModeBar/maintenanceModeBar';

import './mainLayout.scss';

/**
 * The MainLayout component.
 */
export class MainLayout extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const isEmulating = !!cookieHelper.getEmulationAuthId();
    const isInMaintenanceMode = false;

    return (
      <div id="main-layout">
        <MainNavBar
          location={this.props.location}
          match={this.props.match}
          navType={this.props.navType}
        />

        {(isEmulating) && <UserEmulationBar />}
        {(isInMaintenanceMode) && <MaintenanceModeBar />}

        <div id="main-content">
          <main
            id="main-page"
            role="main"
            className={classNames({
              'with-emulation-bar': isEmulating,
            })}
          >
            {this.props.page}
          </main>
        </div>
      </div>
    );
  }
}

MainLayout.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.object,
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  page: PropTypes.element.isRequired,

  navType: PropTypes.symbol,
};

MainLayout.defaultProps = {
  navType: null,
};

export default observer(MainLayout);
