import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import cookieHelper from '../../../utils/cookieHelper';
import UserEmulationBar from '../../common/userEmulationBar/UserEmulationBar';
import MaintenanceModeBar from '../../common/maintenanceModeBar/maintenanceModeBar';

import './simpleLayout.scss';

/**
 * The SimpleLayout component.
 */
export class SimpleLayout extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const isEmulating = !!cookieHelper.getEmulationAuthId();
    const isInMaintenanceMode = false;

    return (
      <div id="simple-layout">
        {(isEmulating) && <UserEmulationBar />}
        {(isInMaintenanceMode) && <MaintenanceModeBar />}

        <div
          className={classNames('simple-page', {
            'with-emulation-bar': isEmulating,
          })}
        >
          {this.props.page}
        </div>

      </div>
    );
  }
}

SimpleLayout.propTypes = {
  page: PropTypes.element,
};

export default SimpleLayout;
