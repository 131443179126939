import lodash from 'lodash';
import {observable} from 'mobx';

/**
 * Uses webpack's require.context to get all store files.
 *
 * @returns {Object}
 */
function getStoreContext() {
  return require.context('./', true, /^\.\/([^/]+\/)*.+Store\.js$/);
}

/**
 * Inject all the stores from stores/* folders using Webpack magic (require.context).
 * We are doing this in lieu of importing each store and manually doing an Object.assign().
 *
 * @param {Object} storeContext
 * @returns {Map} An observable map of all the stores.
 */
function getStores(storeContext) {
  const storeModules = storeContext || getStoreContext();

  const allStores = {};

  storeModules.keys().forEach(function parseModulesIntoStores(modulePath) {
    const moduleData = storeModules(modulePath);

    if (moduleData.doNotInject) {
      return;
    }

    let storeName = moduleData.storeName;
    if (!storeName) {
      const nameParts = String(modulePath).split('/');
      storeName = nameParts.pop().split('.')[0];
    }

    allStores[storeName] = moduleData.default;
  });

  allStores.rootStore = Object.assign({}, allStores);

  return allStores;
}

// Inject all the stores from state/* folders using Webpack magic (require.context).
// We are doing this in lieu of importing each store and manually doing an Object.assign().
const storeModules = getStoreContext();
const providedStores = observable(getStores(storeModules));

/**
 * Hot reloads the stores.
 *
 * @param {Map} currentStores
 */
export function hotReloadStores(currentStores) {
  if (!module.hot) {
    return;
  }

  // Enable Webpack hot module replacement for stores
  module.hot.accept(storeModules.id, () => {
    lodash.forEach(getStores(), (newStore, storeName) => {
      currentStores[storeName] = newStore;
    });
  });
}

export default providedStores;
