import {_gsScope} from 'gsap/TweenLite';
import {isObservableMap} from 'mobx';

import {updateEntity} from '../ecs/entityHelper';

/**
 * The opacity plugin for the ECS game engine.
 */
function opacityPluginFactory() {
  _gsScope._gsDefine.plugin({
    /*
     * The name of the property that will get intercepted and handled by this plugin (obviously change it to whatever
     * you want, typically it is camelCase starting with lowercase).
     */
    propName: 'opacity',

    /*
     * The priority in the rendering pipeline (0 by default). A priority of -1 would mean this plugin will run after
     * all those with 0 or greater. A priority of 1 would get run before 0, etc. This only matters when a plugin
     * relies on other plugins finishing their work before it runs (or visa-versa).
     */
    priority: 0,

    /*
     * The API should stay 2 - it just gives us a way to know the method/property structure so that if in the future
     * we change to a different TweenPlugin architecture, we can identify this plugin's structure.
     */
    API: 2,

    // Your plugin's version number.
    version: '1.0.0',

    /*
     * An array of property names whose tweens should be overwritten by this plugin. For example, if you
     * create a 'scale' plugin that handles both 'scaleX' and 'scaleY', the overwriteProps would
     * be ['scaleX','scaleY'] so that if there's a scaleX or scaleY tween in-progress when a new 'scale' tween
     * starts (using this plugin), it would overwrite the scaleX or scaleY tween.
     */
    overwriteProps: ['opacity'],

    /**
     * The game entity that will be updated.
     *
     * @type {ObservableMap}
     */
    entity: null,

    /**
     * The (lodash) path to the target within the entity.
     *
     * <pre><code>
     *   const target = lodash.get(this.entity, this.path);
     * </code></pre>
     *
     * @type {?string}
     */
    path: null,

    /**
     * The starting opacity value.
     *
     * @type {number}
     */
    opacityStart: 0,

    /**
     * The total amount of change in the opacity (end value - start value).
     *
     * @type {number}
     */
    opacityTotalDelta: 0,

    /**
     * The init function is called when the tween renders for the first time. This is where initial values should
     * be recorded and any setup routines should run.
     *
     * Example: TweenLite.to(target, 1, {opacity: value});
     *
     * @param {{}} target The target of the tween. In cases where the tween's original target is an array
     *                    (or jQuery object), this target will be the individual object inside that array
     *                    (a new plugin instance is created for each target in the array).
     * @param {{}} target.entity The entity object that will be manipulated.
     * @param {{}} target.path The (lodash) path to the actual target within the entity.
     * @param {*} finalValue The value that is passed as the special property value.
     * @returns {boolean} This function should return true unless you want to have TweenLite/Max skip the plugin
     *                    altogether and instead treat the property/value like a normal tween.
     */
    init: function init({entity, path}, finalValue) {
      // This plugin should only target MobX Observable Maps and valid game engine entities.
      if (!isObservableMap(entity) || !entity.get('element')) {
        // If the target is invalid, the parameter will just be ignored.
        return false;
      }

      // We record the target so that we can refer to it in the set method when doing updates.
      this.entity = entity;
      this.path = path;

      const opacityStartValue = entity.get('visible').opacity;

      this.opacityStart = opacityStartValue;
      this.opacityTotalDelta = finalValue - opacityStartValue;

      return true;
    },

    /**
     * Called each time the values should be updated, and the ratio gets passed as the only parameter
     * (typically it's a value between 0 and 1, but it can exceed those when using an ease like
     * Elastic.easeOut, Back.easeOut, etc).
     *
     * @param {number} ratio
     */
    set: function set(ratio) {
      const oldOpacity = this.entity.get('visible').opacity;
      const newOpacity = this.opacityStart + (this.opacityTotalDelta * ratio);

      if (oldOpacity === newOpacity) {
        // No need to update if the values did not change.
        return;
      }

      // Update the entity the correct way.
      updateEntity(this.entity, 'visible', {opacity: newOpacity}, 'opacityPluginUpdateEntity');
    },
  });
}

if (!_gsScope._gsQueue) {
  _gsScope._gsQueue = [];
}

_gsScope._gsQueue.push(opacityPluginFactory);

if (_gsScope._gsDefine) {
  _gsScope._gsQueue.pop()();
}
