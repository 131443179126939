import {toJS} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import {adjustForAlignment, getEntityPositionAlignment} from '../../../display/ecs/entityHelper';

import './displayHighlight.scss';

/**
 * Highlight entities need have the highest (except for crop) zIndex in the display.
 * So this needs to be a very high number and higher than any other zIndex (except for the crop layer).
 *
 * @const {number}
 */
const ACTIVE_ENTITY_ZINDEX = 1000001;

/**
 * The fill color for the interaction control.
 * @const {string}
 */
const HIGHLIGHT_COLOR = 'rgb(0,150,253,.4)';

/**
 * The DisplayHighlight component.
 */
class DisplayHighlight extends React.Component {
  /**
   * Gets the styles from the entity.
   *
   * @param {{}} entity
   * @param {{height: number, width: number}} resolution
   * @returns {{}}
   */
  getStylesFromEntity = (entity, resolution) => {
    const safeEntity = toJS(entity);

    const entityAlignmentX = getEntityPositionAlignment(entity, 'x');
    const entityAlignmentY = getEntityPositionAlignment(entity, 'y');

    const styles = {
      zIndex: ACTIVE_ENTITY_ZINDEX,
    };
    if (safeEntity.size) {
      styles.width = safeEntity.size.width;
      styles.height = safeEntity.size.height;
    }
    if (safeEntity.position) {
      styles.position = 'absolute';
      styles.top = safeEntity.position.y;
      styles.left = safeEntity.position.x;
      if (safeEntity.position.rotate) {
        styles.transform = `rotate(${safeEntity.position.rotate}deg)`;
        styles.WebkitTransform = `rotate(${safeEntity.position.rotate}deg)`;
      }
    }

    if (safeEntity.element === 'image') {
      const image = safeEntity.image;
      styles.top = (image.y !== undefined) ? image.y : styles.top;
      styles.left = (image.x !== undefined) ? image.x : styles.left;
      styles.height = (image.height !== undefined) ? image.height : styles.height;
      styles.width = (image.width !== undefined) ? image.width : styles.width;
    }

    styles.top = adjustForAlignment(entityAlignmentY, styles.top, styles.height, resolution.height);
    styles.left = adjustForAlignment(entityAlignmentX, styles.left, styles.width, resolution.width);

    return styles;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      /* @type {ObservableStore} */ entity,
      /* @type {GameStore} */ game,
    } = this.props;

    if (!entity.has('visible') || !entity.get('visible').isVisible) {
      return null;
    } else if (entity.get('element') === 'line') {
      return null;
    }

    const style = this.getStylesFromEntity(entity, game.resolution);
    const padding = 10;

    const outlinePath = [
      `M ${padding},${padding}`,
      `H ${style.width + padding}`,
      `V ${style.height + padding}`,
      `H ${padding}`,
      'Z',
    ];

    const outlineWidth = 3;

    style.top -= padding;
    style.left -= padding;
    style.width += (padding * 2);
    style.height += (padding * 2);

    return (
      <svg
        className="display-highlight"
        style={style}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        fillRule="evenodd"
        fill="none"
        stroke="none"
        strokeLinecap="square"
        strokeMiterlimit="10"
        overflow="hidden"
        preserveAspectRatio="none"
        pointerEvents="none"
        viewBox={`0 0 ${style.width} ${style.height}`}
      >
        <g pointerEvents="visiblePainted">
          <path
            className="movable-path"
            stroke={HIGHLIGHT_COLOR}
            fill="none"
            strokeOpacity="1"
            strokeWidth={outlineWidth}
            strokeLinecap="square"
            strokeLinejoin="miter"
            strokeMiterlimit="10"
            pointerEvents="all"
            d={outlinePath.join(' ')}
          />
        </g>
      </svg>
    );
  }
}

DisplayHighlight.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(DisplayHighlight);
