import jQuery from 'jquery';
import lodash from 'lodash';

/**
 * The debounce time for the text resize function.
 * @const {number}
 */
const DEBOUNCE_TIME = 200;

/**
 * The maximum number of iterations (in case there is a bug).
 *
 * @const {number}
 */
const ITERATION_LIMIT = 500;

/**
 * The fitting steps.
 *
 * @const {number[]}
 */
const FIT_STEPS = [250, 100, 50, 25, 10, 5, 1]; // eslint-disable-line no-magic-numbers

/**
 * Adjusts the text size until it fits in the entity container box.
 *
 * @param {HTMLElement} wrapperElement
 * @returns {Promise<number>} The number of iterations it took to calculate. Helpful for optimization.
 */
export async function fitTextToEntity(wrapperElement) {
  const $textWrapper = jQuery(wrapperElement);
  if (!$textWrapper.length) {
    return 0;
  }

  if (document.fonts) {
    // wait for fonts to be loaded before resizing. This fixes
    // an issue where text would resize (through js) before the font was loaded,
    // then when the font loaded, css resized again and the text was too large or too small
    await document.fonts.ready;
  }

  const settings = {
    fontRatio: 20,
    initialOffset: 0,
  };

  const mainWidth = $textWrapper.width();
  const mainHeight = $textWrapper.height();

  const fontSizes = [];

  const lines = [];
  $textWrapper.children().each((index, element) => {
    const $line = jQuery(element);

    const textItems = [];

    $line.children().each((unused, childElement) => {
      const $text = jQuery(childElement);
      textItems.push($text);
    });

    lines.push(textItems);
  });

  let iterations = 0;

  lines.forEach((textItems) => {
    const fontBase = mainWidth / settings.fontRatio;

    let testFontSize = fontBase + settings.initialOffset;
    setAllFontSizes(textItems, testFontSize);

    const startingWidth = getTotalWidth(textItems);
    if (startingWidth > mainWidth) {
      const shrinkOutput = shrinkUntilFit(textItems, testFontSize, mainWidth);
      testFontSize = shrinkOutput.newFontSize;
      iterations += shrinkOutput.iterations;
    } else if (startingWidth < mainWidth) {
      const growOutput = growUntilFit(textItems, testFontSize, mainWidth);
      testFontSize = growOutput.newFontSize;
      iterations += growOutput.iterations;
    }

    fontSizes.push(testFontSize);
  });

  // Now adjust all items to have the smallest font size.
  const smallestFontSize = lodash.min(fontSizes);
  setAllFontSizes(lines, smallestFontSize);

  // Now make sure the text fits inside the height of the wrapping box.
  const shrinkOutput = shrinkHeightToFit(lines, smallestFontSize, mainHeight);
  iterations += shrinkOutput.iterations;

  return iterations;
}

/**
 * Gets a unique debounced fit text function.
 * Each component instance needs their own unique debounce function.
 *
 * @returns {function}
 */
export function loadDebouncedFitText() {
  return lodash.debounce(fitTextToEntity, DEBOUNCE_TIME, {
    leading: false,
    trailing: true,
  });
}

/**
 * Sets the given font size on all jQuery elements given.
 *
 * @param {jQuery[]|Array<jQuery[]>} textItems
 * @param {number} newFontSize
 */
function setAllFontSizes(textItems, newFontSize) {
  textItems.forEach(($text) => {
    if (Array.isArray($text)) {
      setAllFontSizes($text, newFontSize);
      return;
    }

    $text.css('font-size', `${newFontSize}px`);
  });
}

/**
 * Gets the sum total height of all the jQuery elements given.
 *
 * @param {jQuery[]} textItems
 * @returns {number}
 */
function getTotalWidth(textItems) {
  return lodash.sumBy(textItems, ($text) => {
    return $text.width();
  });
}

/**
 * Gets the sum total height of all the jQuery elements given.
 *
 * @param {Array<jQuery[]>} lines
 * @returns {number}
 */
function getTotalHeight(lines) {
  return lodash.sumBy(lines, (textItems) => {
    let totalHeight = 0;

    lodash.forEach(textItems, ($text) => {
      const fontSize = $text.css('font-size');
      const textHeight = $text.height();

      if (fontSize.indexOf('px') === -1) {
        if (textHeight > totalHeight) {
          totalHeight = textHeight;
        }
        return;
      }

      const safeFontSize = parseInt(fontSize, 10);
      const largerHeight = ((textHeight > safeFontSize) ? textHeight : safeFontSize);

      if (largerHeight > totalHeight) {
        totalHeight = largerHeight;
      }
    });

    return totalHeight;
  });
}

/**
 * Shrinks the given font size until it fits within the given width.
 *
 * @param {jQuery[]} textItems
 * @param {number} fontSize
 * @param {number} mainWidth
 * @param {number=} stepIndex
 * @param {number=} iterations
 * @returns {{newFontSize: number, iterations: number}}
 */
function shrinkUntilFit(textItems, fontSize, mainWidth, stepIndex, iterations) {
  let testFontSize = fontSize;

  const safeStepIndex = stepIndex || 0;
  const step = FIT_STEPS[safeStepIndex];

  let hasUpdated = false;
  let safeIterations = iterations || 0;
  safeIterations += 1;

  while (getTotalWidth(textItems) > mainWidth && safeIterations < ITERATION_LIMIT) {
    hasUpdated = true;
    safeIterations += 1;
    testFontSize -= step;
    if (testFontSize <= 0) {
      break;
    }

    setAllFontSizes(textItems, testFontSize);
  }

  const isLastStep = (safeStepIndex >= FIT_STEPS.length - 1);

  if (hasUpdated && !isLastStep) {
    // Grow it back up by one step so it is still outside the main width.
    testFontSize += step;
    setAllFontSizes(textItems, testFontSize);
  }

  if (isLastStep) {
    return {
      newFontSize: testFontSize,
      iterations: safeIterations,
    };
  }

  return shrinkUntilFit(textItems, testFontSize, mainWidth, safeStepIndex + 1, safeIterations);
}

/**
 * Grows the given font size until it fits within the given width.
 *
 * @param {jQuery[]} textItems
 * @param {number} fontSize
 * @param {number} mainWidth
 * @param {number=} stepIndex
 * @param {number=} iterations
 * @returns {{newFontSize: number, iterations: number}}
 */
function growUntilFit(textItems, fontSize, mainWidth, stepIndex, iterations) {
  let testFontSize = fontSize;

  const safeStepIndex = stepIndex || 0;
  const step = FIT_STEPS[safeStepIndex];

  let hasUpdated = false;
  let safeIterations = iterations || 0;
  safeIterations += 1;

  while (getTotalWidth(textItems) < mainWidth && safeIterations < ITERATION_LIMIT) {
    hasUpdated = true;
    safeIterations += 1;
    testFontSize += step;

    setAllFontSizes(textItems, testFontSize);
  }

  if (hasUpdated) {
    // Shrink it back down by one step so it is inside the main width.
    testFontSize -= step;
    setAllFontSizes(textItems, testFontSize);
  }

  if (safeStepIndex >= FIT_STEPS.length - 1) {
    return {
      newFontSize: testFontSize,
      iterations: safeIterations,
    };
  }

  return growUntilFit(textItems, testFontSize, mainWidth, safeStepIndex + 1, safeIterations);
}

/**
 * Shrinks the given font size until the given elements fit within the given height.
 *
 * @param {Array<jQuery[]>} lines
 * @param {number} fontSize
 * @param {number} mainHeight
 * @param {number=} stepIndex
 * @param {number=} iterations
 * @returns {{newFontSize: number, iterations: number}}
 */
function shrinkHeightToFit(lines, fontSize, mainHeight, stepIndex, iterations) {
  let testFontSize = fontSize;

  const safeStepIndex = stepIndex || 0;
  const step = FIT_STEPS[safeStepIndex];

  let hasUpdated = false;
  let safeIterations = iterations || 0;
  safeIterations += 1;

  while (getTotalHeight(lines) > mainHeight && safeIterations < ITERATION_LIMIT) {
    hasUpdated = true;
    safeIterations += 1;
    testFontSize -= step;
    if (testFontSize <= 0) {
      break;
    }

    setAllFontSizes(lines, testFontSize);
  }

  if (hasUpdated) {
    // Grow it back up by one step so it is still outside the main width.
    testFontSize += step;
    setAllFontSizes(lines, testFontSize);
  }

  if (safeStepIndex >= FIT_STEPS.length - 1) {
    return {
      newFontSize: testFontSize,
      iterations: safeIterations,
    };
  }

  return shrinkHeightToFit(lines, testFontSize, mainHeight, safeStepIndex + 1, safeIterations);
}
