import React from 'react';

import {ReactComponent as AccountIcon} from '../../../assets/icons/account.svg';
import {ReactComponent as CanvasIcon} from '../../../assets/icons/canvas.svg';
import {ReactComponent as DesignWandIcon} from '../../../assets/icons/design_wand.svg';
import {ReactComponent as FileIcon} from '../../../assets/icons/file.svg';
import {ReactComponent as FolderIcon} from '../../../assets/icons/folder.svg';
import {ReactComponent as FolderOpenIcon} from '../../../assets/icons/folder_open.svg';
import {ReactComponent as ScheduleIcon} from '../../../assets/icons/schedule.svg';
import {ReactComponent as SignIcon} from '../../../assets/icons/sign.svg';
import {ReactComponent as UploadIcon} from '../../../assets/icons/upload.svg';
import {ReactComponent as UnsplashIcon} from '../../../assets/icons/unsplash_icon.svg';

const ICON_DICTIONARY = {
  'account': AccountIcon,
  'canvas': CanvasIcon,
  'design-wand': DesignWandIcon,
  'file': FileIcon,
  'folder': FolderIcon,
  'folder-open': FolderOpenIcon,
  'sign': SignIcon,
  'schedule': ScheduleIcon,
  'upload': UploadIcon,
  'unsplash': UnsplashIcon,
};

const CustomIcon = (customIconProps) => {
  const {type, className, style = {}} = customIconProps;
  const mergedStyles = {
    height: '1.25em',
    ...style,
  };

  return React.createElement(ICON_DICTIONARY[type], {
    className,
    style: mergedStyles,
  });
};

export default CustomIcon;
