import lodash from 'lodash';
import {action, computed, observable, runInAction, toJS} from 'mobx';

import displayEditorStore from '../display/displayEditorStore';
import {parseSourceFromGame} from '../../display/ecs/sourceHelper';

/**
 * The debounce time for the add point function.
 * @const {number}
 */
const DEBOUNCE_TIME = 500;

/**
 * Adds a new game history point.
 *
 * @param {GameStore} game
 * @param {GameHistoryStore} store
 */
function addPoint(game, store) {
  const source = parseSourceFromGame(game, {forHistory: true});
  const entities = source.entities;
  const gameTime = game.timer.time;
  const variables = toJS(displayEditorStore.variables);

  runInAction('addHistoryPoint', () => {
    store.redoHistory.clear();

    if (store.currentPoint) {
      store.history.push(store.currentPoint);
    }

    store.isInUndoProcess = false;

    store.currentPoint = observable({
      entities,
      gameTime,
      variables,
    });
  });
}

/**
 * The debounced add point function.
 *
 * @type {Function}
 */
const debouncedAddPoint = lodash.debounce(addPoint, DEBOUNCE_TIME, {
  leading: true,
  trailing: false,
});

/**
 * The game history store.
 */
export class GameHistoryStore {
  /**
   * The current history point.
   *
   * @type {?{gameTime: number, entities: Array.<{}>}}
   */
  @observable currentPoint = null;

  /**
   * The recorded change points.
   *
   * @type {Array.<{gameTime: number, entities: Array.<{}>}>}
   */
  @observable history = [];

  /**
   * The recorded redo points.
   *
   * @type {Array.<{gameTime: number, entities: Array.<{}>}>}
   */
  @observable redoHistory = [];

  /**
   * Whether or not an undo/redo process is taking place.
   *
   * @type {boolean}
   */
  @observable isInUndoProcess = false;

  /**
   * Whether or not the history has an undo point.
   *
   * @returns {number}
   */
  @computed get hasUndo() {
    return this.history.length;
  }

  /**
   * Whether or not the history has a redo point.
   *
   * @returns {number}
   */
  @computed get hasRedo() {
    return this.redoHistory.length;
  }

  /**
   * Adds a new game history point.
   *
   * @param {GameStore} game
   */
  @action addPoint(game) {
    debouncedAddPoint(game, this);
  }

  /**
   * Starts the undo/redo process.
   */
  @action startProcess() {
    if (this.isInUndoProcess) {
      return;
    }

    // Create a new point using the current game state.
    // We might not need this anymore since history is created on (most) game actions.
    // this.addPoint(game);
    // this.popUndoPoint();

    this.isInUndoProcess = true;
  }

  /**
   * Takes the last history point off the stack and pushes it to the redo stack.
   *
   * @returns {{}}
   */
  @action popUndoPoint() {
    this.redoHistory.push(this.currentPoint);

    const lastPoint = this.history.pop();
    this.currentPoint = lastPoint;

    return toJS(lastPoint);
  }

  /**
   * Takes the last redo point off the stack and pushes it to the undo stack.
   *
   * @returns {{}}
   */
  @action popRedoHistory() {
    this.history.push(this.currentPoint);

    const lastPoint = this.redoHistory.pop();
    this.currentPoint = lastPoint;

    return toJS(lastPoint);
  }
}
