import React from 'react';
import PropTypes from 'prop-types';
import {withAuth0} from '@auth0/auth0-react';

/**
 * The LoginPage component.
 *
 * This component is needed so external sites can link to the apps login page.
 * With this, an external site can use the link https://app.projectcontent.com/login
 * Now this app will generate the necesary auth0 login route and redirect to it.
 *
 * This is needed because the auth0 login page needs query params that can only be
 * generated by this app. These query params expire after a period of time.
 */
export class LoginPage extends React.Component {
  /**
   * Redirects user to auth0 login page on load/mount.
   */
  componentDidMount() {
    const {auth0} = this.props;
    auth0.loginWithRedirect();
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    return (<></>
    );
  }
}

LoginPage.propTypes = {
  auth0: PropTypes.object,
};

export default withAuth0(LoginPage);
