import lodash from 'lodash';

/**
 * Uses webpack's require.context to get all preset files.
 *
 * @returns {Object}
 */
function getPresetContext() {
  return require.context('./', true, /^\.\/([^/]+\/)*.+Preset\.js$/);
}

/**
 * Inject all the presets from presets/* folders using Webpack magic (require.context).
 * We are doing this in lieu of importing each preset and manually doing an Object.assign().
 *
 * @param {Object} presetContext
 * @returns {Map} An observable map of all the presets.
 */
function getPresets(presetContext) {
  const presetModules = presetContext || getPresetContext();

  const allPresets = {};

  presetModules.keys().forEach(function parseModulesIntoPresets(modulePath) {
    const moduleData = presetModules(modulePath);

    if (moduleData.doNotInject) {
      return;
    }

    let presetName = moduleData.presetName;
    if (!presetName) {
      const nameParts = String(modulePath).split('/');
      presetName = nameParts.pop().split('.')[0];
    }

    allPresets[presetName] = moduleData.default;
  });

  return allPresets;
}

// Inject all the presets from transitionPresets/* folders using Webpack magic (require.context).
// We are doing this in lieu of importing each preset and manually doing an Object.assign().
const presetModules = getPresetContext();
const providedPresets = getPresets(presetModules);

/**
 * Hot reloads the presets.
 *
 * @param {Map} currentPresets
 */
export function hotReloadPresets(currentPresets) {
  if (!module.hot) {
    return;
  }

  // Enable Webpack hot module replacement for presets
  module.hot.accept(presetModules.id, () => {
    lodash.forEach(getPresets(), (newPreset, presetName) => {
      currentPresets[presetName] = newPreset;
    });
  });
}

export default providedPresets;
