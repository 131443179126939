import classNames from 'classnames';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import loadingSvg from '../../../assets/images/loading2.svg';

import './loadingIcon.scss';

const VALID_SIZES = ['em', 'xs', 'sm', 'md', 'lg'];

/**
 * Gets the dynamic classes for loading-icon.
 *
 * @param {string} size
 * @returns {{}}
 */
function getClasses(size) {
  if (!lodash.includes(VALID_SIZES, size)) {
    return {};
  }

  return {
    [`loading-icon-${size}`]: true
  };
}

/**
 * The loading icon component.
 *
 * @param {{}} param
 * @param {string} param.size
 * @returns {{}}
 */
const LoadingIcon = ({size}) => {
  const classes = getClasses(size);

  return (
    <div className={classNames('loading-icon', classes)}>
      <img className="loading-img" src={loadingSvg} alt="Loading..." />
    </div>
  );
};

LoadingIcon.propTypes = {
  size: PropTypes.string
};

export default LoadingIcon;
