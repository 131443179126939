import React from 'react';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBuilding, faUser} from '@fortawesome/free-regular-svg-icons';

import cookieHelper from '../../../utils/cookieHelper';
import inject from '../../hoc/injectHoc';
import {STATE_REJECTED} from '../../../constants/asyncConstants';
import mainConfig from '../../../config/main';

import './userEmulationBar.scss';

/**
 * The UserEmulationBar component.
 */
export class UserEmulationBar extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {apiUserGetMeStore, apiUserClearEmulateStore} = this.props;
    const myUser = apiUserGetMeStore.getFulfilled();

    const emulationId = cookieHelper.getEmulationAuthId();

    const hasUserGetMeError = apiUserGetMeStore.state === STATE_REJECTED;

    const onCancel = () => {
      apiUserClearEmulateStore.makeRequest();
      apiUserClearEmulateStore.getPromise().then(() => {
        window.location.href = `${mainConfig.hub.appUrl}/admin/users`;
      });
    };

    // show emulation bar if super-admin is in emulation mode
    // but emulated user does not yet exist in PC. this is needed when super-admins
    // emulate and sign up a user
    if (emulationId && hasUserGetMeError) {
      return (
        <div className="user-emulation-bar m-0 row no-gutters">
          <div className="col-12 col-sm d-flex justify-content-between align-items-center flex-grow-0 px-3 py-2 bg-primary">
            <span className="text-black-50 font-weight-bold">Emulating</span>
            <button
              type="button"
              className="btn btn-outline-light btn-sm rounded-pill d-sm-none"
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
          <div className="col flex-grow-1 d-flex flex-wrap px-3 py-2">
            <div className="d-flex align-items-center mr-3">
              <FontAwesomeIcon
                className="mr-2"
                icon={faUser}
                size="lg"
              />
              <span>Auth0 ID: {emulationId}</span>
            </div>
          </div>
          <div className="d-none d-sm-block col-12 col-sm flex-grow-0 px-3 py-2">
            <button
              type="button"
              className="btn btn-outline-light btn-sm rounded-pill"
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      );
    }

    if (!myUser) {
      return (null);
    }

    return (
      <div className="user-emulation-bar m-0 row no-gutters">
        <div className="col-12 col-sm d-flex justify-content-between align-items-center flex-grow-0 px-3 py-2 bg-primary">
          <span className="text-black-50 font-weight-bold">Emulating</span>
          <button
            type="button"
            className="btn btn-outline-light btn-sm rounded-pill d-sm-none"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
        <div className="col flex-grow-1 d-flex flex-wrap px-3 py-2">
          <div className="d-flex align-items-center mr-3">
            <FontAwesomeIcon
              className="mr-2"
              icon={faBuilding}
              size="lg"
            />
            <span>{myUser.company.name || 'Emulated Company'}</span>
          </div>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              className="mr-2"
              icon={faUser}
              size="lg"
            />
            <span>{myUser.fullName} ({myUser.role})</span>
          </div>
        </div>
        <div className="d-none d-sm-block col-12 col-sm flex-grow-0 px-3 py-2">
          <button
            type="button"
            className="btn btn-outline-light btn-sm rounded-pill"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }
}

UserEmulationBar.propTypes = {
  apiUserClearEmulateStore: MobxPropTypes.observableObject,
  apiUserGetMeStore: MobxPropTypes.observableObject,
};

UserEmulationBar.defaultProps = {
};

UserEmulationBar.wrappedComponent = {};
UserEmulationBar.wrappedComponent.propTypes = {
  apiUserClearEmulateStore: MobxPropTypes.observableObject.isRequired,
  apiUserGetMeStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(UserEmulationBar)(
  observer(UserEmulationBar)
);
