import lodash from 'lodash';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import {GENERAL_FOLDER_USER_ID} from '../../../constants/userConstants';
import LoadingIcon from '../loadingIcon/LoadingIcon';
import inject from '../../hoc/injectHoc';

/**
 * The name of the folder created if non exist.
 * @const {string}
 */
const DEFAULT_NEW_FOLDER_NAME = 'My Files';

/**
 * The PreloadWritableFolder component.
 */
export class PreloadWritableFolder extends React.Component {
  /**
   * Whether or not the writable folder is loading.
   *
   * @type {boolean}
   */
  @observable isLoading = true;

  /**
   * An error that occurred while trying to load the writable folder.
   *
   * @type {string}
   */
  @observable loadingError = '';

  /**
   * Triggered when the component is first mounted to the page.
   */
  componentDidMount() {
    const {folderType, apiFileGetUserFoldersStore} = this.props;

    apiFileGetUserFoldersStore.refresh(folderType);
    apiFileGetUserFoldersStore.getPromise(folderType).catch(() => {
      const loadError = new Error('An error occurred while trying to load the folders.');
      this.setLoadingError(loadError.message);
      return loadError;
    }).then(this.onFoldersLoaded);
  }

  /**
   * Triggered when the folder loading call finishes.
   *
   * @param {(Array.<{}>|Error)} existingFolders
   */
  onFoldersLoaded = (existingFolders) => {
    if (existingFolders instanceof Error) {
      return;
    }

    const {onFoldersFound} = this.props;

    if (existingFolders) {
      const writableFolders = lodash.filter(existingFolders, this.isFolderWritable);

      if (writableFolders.length && writableFolders[0]) {
        if (onFoldersFound) {
          onFoldersFound(writableFolders);
        }

        // A writable folder exists, so display the children.
        this.stopLoading();
        return;
      }
    }

    const {newFolderName, folderType, apiFileFolderCreateStore, apiFileGetUserFoldersStore} = this.props;

    const createFolderName = newFolderName || DEFAULT_NEW_FOLDER_NAME;

    // We need to create a writable folder.
    apiFileFolderCreateStore.makeRequest(
      createFolderName,
      folderType,
    );
    apiFileFolderCreateStore.getPromise().then((newFolderData) => {
      apiFileGetUserFoldersStore.clear(null);
      apiFileGetUserFoldersStore.refresh(folderType, true);

      if (onFoldersFound) {
        onFoldersFound([{id: newFolderData.id, name: createFolderName}]);
      }

      this.stopLoading();
    }).catch(() => {
      this.setLoadingError('An error occurred while trying to create a writable folder.');
    });
  };

  /**
   * Determines whether or not the given folder is writable.
   *
   * @param {{id: number, isGlobal: boolean}} folder
   * @returns {boolean}
   */
  isFolderWritable = (folder) => {
    return (folder.id && !folder.isDesign && folder.userId !== GENERAL_FOLDER_USER_ID);
  };

  /**
   * Sets the loading error message.
   *
   * @param {string} errorMessage
   */
  @action setLoadingError = (errorMessage) => {
    this.loadingError = String(errorMessage);
  };

  /**
   * Stops the loading and displays the children.
   */
  @action stopLoading = () => {
    this.isLoading = false;
    this.loadingError = null;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {children} = this.props;

    if (!this.isLoading) {
      return children;
    }

    if (this.loadingError) {
      return (
        <div className="alert alert-warning">{this.loadingError}</div>
      );
    }

    return (
      <LoadingIcon size="lg" />
    );
  }
}

PreloadWritableFolder.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  folderType: PropTypes.string.isRequired,

  apiFileFolderCreateStore: MobxPropTypes.observableObject,
  apiFileGetUserFoldersStore: MobxPropTypes.observableObject,
  newFolderName: PropTypes.string,
  onFoldersFound: PropTypes.func,
};

PreloadWritableFolder.wrappedComponent = {};
PreloadWritableFolder.wrappedComponent.propTypes = {
  apiFileFolderCreateStore: MobxPropTypes.observableObject.isRequired,
  apiFileGetUserFoldersStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(PreloadWritableFolder)(
  observer(PreloadWritableFolder)
);
