import axios from 'axios';
import assignDeep from 'assign-deep';
import lodash from 'lodash';
import {fromPromise} from 'mobx-utils';

import {toJSRecursive} from './mobxHelper';
import config from '../config/main';
import {reportApiError} from '../utils/sentryHelper';
import auth0 from '../utils/auth0';
import routerStore from '../stores/active/routerStore';
import {logoutRoute} from '../components/routePaths';

const STATUS_CODE_NO_CONTENT = 204;

/**
 * The HTTP status code for an unauthorized request.
 * Usually means the token is missing or expired.
 *
 * @const {number}
 */
const UNAUTHORIZED_STATUS_CODE = 401;

/**
 * The default Axios options to make for each request unless overridden.
 *
 * @type {{}}
 */
const defaultOptions = {
  headers: {},
  withCredentials: true,
  baseURL: config.api.url,
};

/**
 * The default csrf Axios options to send the csrf token.
 *
 * @type {{}}
 */
const csrfOptions = {
  xsrfCookieName: 'csrf',
  xsrfHeaderName: 'X-CSRF-Token',
};

/**
 * A regexp matching the CSRF value from the document.cookies.
 * @const {RegExp}
 */
const CSRF_REGEXP = new RegExp(`${csrfOptions.xsrfCookieName}=([^;]+)`, 'i');

/**
 * Makes a general request to the server based on the given options.
 *
 * @param {{}} options See Axios Options.
 * @returns {Promise}
 */
function serverRequest(options) {
  // Only send csrf token for non-get requests.
  const requestCsrfOptions = (options.method && options.method !== 'get') ? csrfOptions : {};

  const combinedOptions = assignDeep({}, defaultOptions, requestCsrfOptions, options);

  // Persist the original data because assignDeep turns everything into an
  // object. This is a problem if sending multipart/form-data bodies.
  if (options.data) {
    combinedOptions.data = options.data;

    if (!(combinedOptions.data instanceof FormData)) {
      // For non-formData, make sure no observables or maps exist in the data to prevent the request being sent
      // as multipart/form-data.
      combinedOptions.data = toJSRecursive(combinedOptions.data);
    }
  }

  // Process a cancelable callback.
  if (combinedOptions.cancelable) {
    const cancelTokenSource = axios.CancelToken.source();
    combinedOptions.cancelToken = cancelTokenSource.token;
    combinedOptions.cancelable(cancelTokenSource.cancel);

    delete combinedOptions.cancelable;
  }

  const apiPromise = () => axios(combinedOptions).catch((err) => {
    if (axios.isCancel(err)) {
      const canceledError = new Error('canceled');
      canceledError.canceled = true;
      throw canceledError;
    }

    const response = err.response;
    const isCSRF = err.message.indexOf('Invalid CSRF') !== -1;

    if (response && response.status === UNAUTHORIZED_STATUS_CODE && isCSRF) {
      routerStore.push(logoutRoute);
    } else {
      reportApiError(err, combinedOptions, response);
    }

    let safeError = {
      type: 'UnknownServerError',
      message: 'An unknown internal server error occurred.',
    };
    if (err.response && err.response.data) {
      if (typeof err.response.data === 'string') {
        safeError = {
          type: 'ServerError',
          message: err.response.data
        };
      } else {
        safeError = err.response.data;
      }
    }

    throw safeError;
  }).then((response) => {
    if (!response || !response.data) {
      return null;
    }

    let finalData = response.data;
    if (options.paginated) {
      const paginationData = {
        pages: Number(lodash.get(response, 'headers.x-page-count', 0)),
        total: Number(lodash.get(response, 'headers.x-result-count', 0)),
      };

      finalData = {
        pagination: paginationData,
        response: finalData,
      };
    }

    return finalData;
  });

  return fromPromise(
    auth0.isAuthenticated().then((isAuthenticated) => {
      // if user is authenticated, attach token to headers
      if (isAuthenticated) {
        return auth0.getTokenSilently()
          .then((token) => {
            defaultOptions.headers.Authorization = `Bearer ${token}`;

            return apiPromise();
          });
      }

      // API expects some kind of value on each request. "false" is used
      // to reduce calls to hub
      defaultOptions.headers.Authorization = 'Bearer false';

      // else call api
      return apiPromise();
    }),
  );
}

/**
 * Makes a fetch call to the server based on the given options.
 *
 * @param {{}} apiOptions See Fetch request options.
 * @param {{paginated: boolean}=} parseOptions
 * @returns {Promise}
 */
function serverFetch(apiOptions, parseOptions) {
  const {url, options} = parseFetchOptions(apiOptions);

  const apiPromise = () => fetch(url, options).then((response) => {
    if (response.ok) {
      return parseFetchSuccess(response, options, parseOptions);
    }

    return parseFetchError(response, options, parseOptions).then((responseError) => {
      throw responseError;
    });
  });

  return fromPromise(
    auth0.isAuthenticated().then((isAuthenticated) => {
      // if user is authenticated, attach token to headers
      if (isAuthenticated) {
        return auth0.getTokenSilently()
          .then((token) => {
            options.headers.set('Authorization', `Bearer ${token}`);

            return apiPromise();
          });
      }

      // API expects some kind of value on each request. "false" is used
      // to reduce calls to hub
      options.headers.set('Authorization', 'Bearer false');

      // else call api
      return apiPromise();
    })
  );
}

/**
 * Makes a fetch call to hub based on the given options.
 *
 * @param {{}} apiOptions See Fetch request options.
 * @param {{paginated: boolean}=} parseOptions
 * @returns {Promise}
 */
function serverHubFetch(apiOptions, parseOptions) {
  const {url, options} = parseFetchOptions({
    ...apiOptions,
    baseUrl: config.hub.url,
  });

  const apiPromise = () => fetch(url, options).then((response) => {
    if (response.ok) {
      return parseFetchSuccess(response, options, parseOptions);
    }

    return parseFetchError(response, options, parseOptions).then((responseError) => {
      throw responseError;
    });
  });

  return fromPromise(
    auth0.isAuthenticated().then((isAuthenticated) => {
      // if user is authenticated, attach token to headers
      if (isAuthenticated) {
        return auth0.getTokenSilently()
          .then((token) => {
            options.headers.set('Authorization', `Bearer ${token}`);

            return apiPromise();
          });
      }

      // API expects some kind of value on each request. "false" is used
      // to reduce calls to hub
      options.headers.set('Authorization', 'Bearer false');

      // else call api
      return apiPromise();
    })
  );
}

/**
 * Parses the options into fetch options.
 *
 * @param {{}} apiOptions
 * @returns {{url: string, options: {}}}
 */
function parseFetchOptions(apiOptions) {
  const {
    url,
    data,
    params,
    baseUrl = config.api.url,
    headers = {},
    ...fetchOptions
  } = (apiOptions || {});

  const slash = (url[0] !== '/') ? '/' : '';
  const fullHeadersJSON = {
    ...fetchOptions.headers,
    ...headers
  };

  const safeUrl = new URL(`${baseUrl}${slash}${url}`);
  const fullHeaders = new Headers(fullHeadersJSON || {});

  if (fetchOptions.cancelable) {
    throw new Error('ServerFetch does not support cancelable requests, use ServerRequest instead.');
  }

  parseFetchParams(fetchOptions, params, safeUrl);

  parseFetchBody(fetchOptions, data, fullHeaders);

  parseFetchCSRF(fetchOptions, fullHeaders);

  /* Cookies */
  // Same-origin would be better but this only calls the origin defined in the config, so it should be ok.
  if (!fetchOptions.credentials) {
    fetchOptions.credentials = 'include';
  }

  fetchOptions.headers = fullHeaders;

  // Make sure the method is all uppercased.
  fetchOptions.method = (fetchOptions.method) ? String(fetchOptions.method).toUpperCase() : 'GET';

  return {
    url: safeUrl.toString(),
    options: fetchOptions,
  };
}

/**
 * Parses the url query params into the given url object.
 *
 * @param {{}} fetchOptions
 * @param {{}} params
 * @param {URL} safeUrl
 */
function parseFetchParams(fetchOptions, params, safeUrl) {
  if (!params) {
    return;
  }

  lodash.forEach(params, (value, key) => {
    if (Array.isArray(value)) {
      safeUrl.searchParams.append(`${key}[]`, value);
    } else {
      safeUrl.searchParams.set(key, value);
    }
  });
}

/**
 * Parses the body content for the fetch request.
 *
 * @param {{}} fetchOptions
 * @param {{}} data
 * @param {Header} headers
 */
function parseFetchBody(fetchOptions, data, headers) {
  if (!data) {
    return;
  }

  if (data instanceof FormData) {
    // By not setting the Content-Type header, the browser should detect FormData and add the boundary for us.
    fetchOptions.body = data;
  } else {
    headers.set('Content-Type', 'application/json; charset=utf-8');
    fetchOptions.body = JSON.stringify(data);

    if (data && !fetchOptions.body) {
      throw new Error(
        'ServerFetch only supports FormData and JSON body data, use ServerRequest for other types of data.'
      );
    }
  }
}

/**
 * Parses the CSRF token into the headers.
 *
 * @param {{}} options
 * @param {Header} headers
 */
function parseFetchCSRF(options, headers) {
  if (!options || !options.method || options.method === 'get') {
    return;
  }

  const parts = String(document.cookie).match(CSRF_REGEXP);
  const csrfValue = (parts && parts.length > 1 && parts[1]) ? parts[1] : null;

  if (csrfValue) {
    headers.set(csrfOptions.xsrfHeaderName, csrfValue);
  }
}

/**
 * Parses the response object into its body data.
 *
 * @param {Response} response
 * @returns {Promise}
 */
function parseResponseToData(response) {
  const type = String(response.headers.get('Content-Type'));

  if (response.statusText === 'No Content' || response.status === STATUS_CODE_NO_CONTENT) {
    return null;
  }

  if (type.indexOf('text/html') !== -1) {
    return response.text();
  } else if (type.indexOf('application/json') !== -1) {
    return response.json();
  } else if (type.indexOf('multipart/form-data') !== -1) {
    return response.formData();
  }

  let final = null;
  try {
    final = response.json();
  } catch (parseError) {
    final = response.text();
  }

  return final;
}

/**
 * Parses the response to a fetch success.
 *
 * @param {Response} response
 * @returns {Promise}
 */
function parseFetchSuccess(response) {
  return parseResponseToData(response);
}

/**
 * Parses a bad fetch response into an api error.
 *
 * @param {Response} response
 * @param {{}} fetchOptions
 * @returns {{type: string, message: string}}
 */
async function parseFetchError(response, fetchOptions) {
  const apiError = await parseResponseToData(response);

  let isInvalidCSRF = false;
  if (apiError && apiError.message) {
    isInvalidCSRF = String(apiError.message).indexOf('Invalid CSRF') !== -1;
  }

  if (response.status === UNAUTHORIZED_STATUS_CODE && isInvalidCSRF) {
    routerStore.push(logoutRoute);
  } else {
    reportApiError(apiError, fetchOptions, response);
  }

  let safeError = {
    type: 'UnknownServerError',
    message: 'An unknown internal server error occurred.',
  };
  if (apiError) {
    if (typeof apiError === 'string') {
      safeError = {
        type: 'ServerError',
        message: apiError
      };
    } else {
      safeError = apiError;
    }
  }

  return safeError;
}

/**
 * Builds the url for the given request.
 *
 * @param {{}} options See Axios Options.
 * @returns {string}
 */
function buildUrl(options) {
  const combinedOptions = assignDeep({}, defaultOptions, options);
  let baseUrl = String(combinedOptions.baseURL);
  if (baseUrl.substr(-1) === '/') {
    baseUrl = baseUrl.substring(0, baseUrl.length - 1);
  }

  let url = combinedOptions.url;
  if (url[0] === '/') {
    url = url.substr(1);
  }

  return baseUrl + '/' + url;
}

/**
 * Converts a response headers to an object.
 *
 * @param {Headers} responseHeaders
 * @returns {{}}
 */
export function getResponseHeaders(responseHeaders) {
  if (!(responseHeaders instanceof Headers) || !responseHeaders.entries) {
    return responseHeaders;
  }

  const headers = {};
  const entries = [...responseHeaders.entries()];

  entries.forEach(([key, value]) => {
    headers[key] = value;
  });

  return headers;
}

export default {
  all: axios.all,
  buildUrl: buildUrl,
  fetch: serverFetch,
  hubFetch: serverHubFetch,
  request: serverRequest,
  UNAUTHORIZED_STATUS_CODE,
};
