import {action} from 'mobx';

import {EXPIRES_IN, EXPIRES_PENDING, EXPIRE_TIME} from '../../../constants/storeConstants';
import {STATE_FULFILLED, STATE_PENDING, STATE_REJECTED} from '../../../constants/asyncConstants';
import serverApi from '../../../utils/serverApi';
import ApiBaseStore from '../common/apiBaseStore';

/**
 * ApiBannerGetAllStore
 */
class ApiBannerGetAllStore extends ApiBaseStore {
  /**
   * Fetches all signs for the currently logged in user.
   */
  @action makeRequest() {
    this.state = STATE_PENDING;
    this[EXPIRE_TIME] = Date.now() + EXPIRES_PENDING;

    serverApi.bannerGetAll()
      .then(action('bannerGetAllSuccess', (banners) => {
        this.state = STATE_FULFILLED;
        this[EXPIRE_TIME] = Date.now() + EXPIRES_IN;
        this.data = banners;
        this.error = null;
      })).catch(action('bannerGetAllError', (bannersGetError) => {
        this.state = STATE_REJECTED;
        this[EXPIRE_TIME] = null;
        this.data = null;
        this.error = bannersGetError;
      }));
  }
}

export default new ApiBannerGetAllStore();
