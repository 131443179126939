import classNames from 'classnames';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import {onClickEntityEvent} from '../utils';

/**
 * What to divide by to turn milliseconds into seconds.
 * @const {number}
 */
const TO_SECONDS = 1000;

/**
 * The number of decimals to show for the time.
 * @const {number}
 */
const SHOW_DECIMALS = 4;

/**
 * The DisplayTimer component.
 */
export class DisplayTimer extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {style, entity, className, onEntityClick, game} = this.props;

    const entityId = entity.get('id');
    const timer = entity.get('timer');

    const onClickEvent = onClickEntityEvent(game, entity, onEntityClick);

    /*
      Note: at the time of adding the "click to activate layer" feature (PS-192)
      the timer layer is currently not implemented. So this feature is untested for the timer layer.
      If we do add the timer layer into the editor we need to test the "click to activate" feature
    */
    return (
      <div
        id={entityId}
        className={classNames('display-timer', className)}
        style={{
          ...style,
          pointerEvents: 'none',
        }}
      >
        <span
          style={{
            cursor: onClickEvent ? 'pointer' : 'inherit',
            pointerEvents: 'auto',
          }}
          onClick={onClickEvent}
        >
          {(timer.time / TO_SECONDS).toFixed(SHOW_DECIMALS)}
        </span>
      </div>
    );
  }
}

DisplayTimer.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
  style: PropTypes.object.isRequired,

  className: PropTypes.string,
  onEntityClick: PropTypes.func,
};

export default observer(DisplayTimer);
