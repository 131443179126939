import {cropComponent} from '../../../../../../display/components/common/cropComponent';
import {cropShapeComponent, RECTANGLE} from '../../../../../../display/components/common/cropShapeComponent';
import {positionComponent} from '../../../../../../display/components/common/positionComponent';
import {sizeComponent} from '../../../../../../display/components/common/sizeComponent';
import {imageComponent} from '../../../../../../display/components/type/imageComponent';
import {nameComponent} from '../../../../../../display/components/common/nameComponent';

/**
 * Gets the image data for a placeholder image.
 *
 * @param {number} fileId
 * @param {string} filename
 * @param {string} url
 * @returns {function}
 */
export function getPlaceHolderImageData(fileId, filename, url) {
  return ({position, size}) => {
    const defaultSize = 200;
    const positionY = position.y || 0; // eslint-disable-line no-shadow
    const positionX = position.x || 0;
    const width = size.width || defaultSize;
    const height = size.height || defaultSize;

    const nameData = (filename) ? nameComponent(`Image: ${filename}`) : {};

    return {
      ...nameData,
      setup: {
        ...cropComponent('0%', '0%', '100%', '100%'),
        ...cropShapeComponent(RECTANGLE),
        ...sizeComponent(width, height),
        ...positionComponent(positionY, positionX),
      },
      ...imageComponent(fileId, url, {
        imageY: positionY,
        imageX: positionX,
        imageWidth: width,
        imageHeight: height,
      }),
    };
  };
}
