import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import inject from '../../hoc/injectHoc';

import EditorEntityAddButton from '../../sidebars/editorEntity/components/entityAddButton/EditorEntityAddButton';

import './addLayerControl.scss';

/**
 * The AddLayerControl component.
 */
export class AddLayerControl extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {game, timer} = this.props;

    return (
      <div className="add-layer-controls">
        <EditorEntityAddButton game={game} timer={timer} buttonText="Add a Layer" />
      </div>
    );
  }
}

AddLayerControl.propTypes = {
  game: MobxPropTypes.observableObject.isRequired,
  timer: MobxPropTypes.observableObject.isRequired,
};

AddLayerControl.wrappedComponent = {};
AddLayerControl.wrappedComponent.propTypes = {
};

export default inject(AddLayerControl)(
  observer(AddLayerControl)
);
