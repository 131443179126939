import PropTypes from 'prop-types';
import React from 'react';
import ColorPicker from 'rc-color-picker';

import './rcColorPicker.scss';
import './colorSelector.scss';

/**
 * The default color to fall back to.
 * @const {string}
 */
const DEFAULT_COLOR = '#000000';

/**
 * The default opacity to fall back to.
 * @const {string}
 */
const DEFAULT_OPACITY = 1;

/**
 * The factor to convert to a percentage.
 * @const {number}
 */
const TO_PERCENTAGE = 100;

/**
 * The ColorSelector component.
 *
 * @param {{}} params
 * @param {string} params.colorValue
 * @param {string=} params.id
 * @param {number} params.opacityValue
 * @param {function(string)} params.onChangeColor
 * @param {function()} params.onClose
 * @param {boolean=} params.skipAlpha
 * @returns {React.Component}
 */
export const ColorSelector = ({colorValue, id, opacityValue, onChangeColor, onClose, skipAlpha}) => {
  /**
   * Sends updates with the new change value.
   *
   * @param {{color: string, alpha: number}} colorData
   */
  const onChange = (colorData) => {
    onChangeColor(colorData.color, colorData.alpha / TO_PERCENTAGE);
  };

  const safeOpacityValue = Number(opacityValue);
  const alpha = (!safeOpacityValue && safeOpacityValue !== 0) ? DEFAULT_OPACITY : safeOpacityValue;

  return (
    <div className="color-selector">
      <ColorPicker
        id={id || 'color-selector-input'}
        animation="slide-up"
        color={colorValue || DEFAULT_COLOR}
        alpha={alpha * TO_PERCENTAGE}
        onChange={onChange}
        onClose={onClose}
        placement="bottomRight"
        enableAlpha={!skipAlpha}
      />
    </div>
  );
};

ColorSelector.propTypes = {
  onChangeColor: PropTypes.func.isRequired,

  colorValue: PropTypes.string,
  id: PropTypes.string,
  onClose: PropTypes.func,
  opacityValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  skipAlpha: PropTypes.bool,
};

ColorSelector.defaultProps = {
  colorValue: DEFAULT_COLOR,
  id: '',
  opacityValue: DEFAULT_OPACITY,
  skipAlpha: false,
};

export default ColorSelector;
