import lodash from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {observer} from 'mobx-react';

import DraftPreview from '../draftPreview/DraftPreview';
import RenderingPreview from '../renderingPreview/RenderingPreview';
import RenderProgress from '../../../../common/renderProgress/RenderProgress';
import {getAssetUrl} from '../../../../../utils/assetHelper';

import './contentPreview.scss';

/**
 * The URL to the placeholder template image.
 * @type {string}
 */
const placeholderTemplateUrl = getAssetUrl('placeholder-template.png');

/**
 * The ContentPreview component.
 */
class ContentPreview extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {content} = this.props;
    if (!content) {
      return null;
    }

    // Determine if item is being rendered or had a render error.
    const isProcessing = (content.displayStatus === 'processing');
    const hasRenderingError = (content.displayStatus === 'error');

    let thumbnailImageUrl = lodash.get(content, 'thumbnailPath');
    if (!thumbnailImageUrl) {
      thumbnailImageUrl = placeholderTemplateUrl;
    }

    const mediaPreviewUrl = lodash.get(content, 'displayPath');
    const showMediaPreview = Boolean(mediaPreviewUrl && !isProcessing && !hasRenderingError);
    const isDraft = Boolean(lodash.get(content, 'isDraft', false));
    const showPlaceholder = Boolean(!showMediaPreview && !isDraft && !isProcessing && !hasRenderingError);
    const previewisImage = (String(mediaPreviewUrl).indexOf('.jpg') !== -1)
      || (String(mediaPreviewUrl).indexOf('.jpeg') !== -1)
      || (String(mediaPreviewUrl).indexOf('.png') !== -1);

    return (
      <div className="content-preview ratio ratio-16x9">
        {(showMediaPreview) && (
          (!previewisImage)
            ? (<video
              className="modal-preview"
              muted
              playsInline
              controls
              controlsList="nodownload"
              onContextMenu={(e) => e.preventDefault()}
            >
              <source
                src={mediaPreviewUrl}
                type="video/mp4"
              />
              <source
                src={mediaPreviewUrl}
                type="video/webm"
              />
              <source
                src={mediaPreviewUrl.replace('.webm', '.mov')}
                type="video/quicktime"
              />
              Your browser is not supported for playing this video. Please use the latest Chrome or Firefox browser.
            </video>)
            : (
              <img
                className="modal-preview"
                src={mediaPreviewUrl}
              />
            )
        )}

        {(isDraft) && (
          <DraftPreview />
        )}

        {(!isDraft && (isProcessing || hasRenderingError)) && (
          <div>
            <RenderingPreview hasError={hasRenderingError} />
            <RenderProgress content={content} />
          </div>
        )}

        {(showPlaceholder) && (
          <div className="modal-image" style={{backgroundImage: `url('${thumbnailImageUrl}')`}} />
        )}
      </div>
    );
  }
}

ContentPreview.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
};

export default observer(ContentPreview);
