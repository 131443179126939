export default {
  DAY_ALIGN: '|',
  HIGH_ALIGN: '\\',
  LOW_ALIGN: '\\',

  DAY_WIDTH_RATIO: 0.8553,
  DAY_HEIGHT_RATIO: 0.0456,
  DAY_ONE_POSITION_X_RATIO: 0.065,
  DAY_ONE_POSITION_Y_RATIO: 0.0622,
  DAY_TWO_POSITION_X_RATIO: 0.065,
  DAY_TWO_POSITION_Y_RATIO: 0.3798,
  DAY_THREE_POSITION_X_RATIO: 0.065,
  DAY_THREE_POSITION_Y_RATIO: 0.6994,

  ICON_WIDTH_RATIO: 0.7634,
  ICON_HEIGHT_RATIO: 0.3429,
  ICON_ONE_POSITION_X_RATIO: -0.1884,
  ICON_ONE_POSITION_Y_RATIO: 0.0485,
  ICON_TWO_POSITION_X_RATIO: -0.1884,
  ICON_TWO_POSITION_Y_RATIO: 0.3661,
  ICON_THREE_POSITION_X_RATIO: -0.1884,
  ICON_THREE_POSITION_Y_RATIO: 0.6857,

  HIGH_WIDTH_RATIO: 0.4459,
  HIGH_HEIGHT_RATIO: 0.0997,
  HIGH_ONE_POSITION_X_RATIO: 0.5128,
  HIGH_ONE_POSITION_Y_RATIO: 0.1182,
  HIGH_TWO_POSITION_X_RATIO: 0.5128,
  HIGH_TWO_POSITION_Y_RATIO: 0.4358,
  HIGH_THREE_POSITION_X_RATIO: 0.5128,
  HIGH_THREE_POSITION_Y_RATIO: 0.7554,

  LOW_WIDTH_RATIO: 0.3774,
  LOW_HEIGHT_RATIO: 0.0558,
  LOW_ONE_POSITION_X_RATIO: 0.5356,
  LOW_ONE_POSITION_Y_RATIO: 0.2045,
  LOW_TWO_POSITION_X_RATIO: 0.5356,
  LOW_TWO_POSITION_Y_RATIO: 0.5221,
  LOW_THREE_POSITION_X_RATIO: 0.5356,
  LOW_THREE_POSITION_Y_RATIO: 0.8417,
};
