import {toJS} from 'mobx';
import PropTypes from 'prop-types';
import React from 'react';

import ColorSelector from '../../../../common/colorSelector/ColorSelector';
import {actionUpdateComponent} from '../../../../../display/components/action/actionUpdateComponent';
import {getComposeForSource} from '../../../../../display/components/common/composeComponent';
import {
  DEFAULT_STROKE,
  getTextForSource,
  getTextFromSource
} from '../../../../../display/components/type/textComponent';

import './strokeColorSelect.scss';

/**
 * The StrokeColorSelect component.
 *
 * @param {{}} props
 * @param {DisplayEditorStore} props.displayEditorStore
 * @param {ObservableMap} props.entity
 * @param {GameStore} props.game
 * @returns {React.Component}
 */
export const StrokeColorSelect = (props) => {
  const {
    /** @type DisplayEditorStore} */ displayEditorStore,
    /** @type {ObservableMap} */ entity,
    /** @type {GameStore} */ game,
  } = props;

  /**
   * Updates the text entity when it is changed.
   *
   * @param {number} newColor
   */
  const onChange = (newColor) => {
    const actionParams = {
      entityId: entity.get('id'),
    };

    const composeSource = getComposeForSource(entity);
    const textSource = getTextForSource(entity);
    if (!textSource.text.stroke) {
      textSource.text.stroke = {...DEFAULT_STROKE};
    }
    textSource.text.stroke.color = newColor;

    game.addAction(actionParams, actionUpdateComponent(
      getTextFromSource(
        {...textSource, ...composeSource},
        toJS(displayEditorStore.variables)
      )
    ));
  };

  const text = entity.get('text');
  const value = (text.stroke) ? text.stroke.color : '#000';

  return (
    <div className="stroke-color-select">
      <ColorSelector
        id="stroke-color-selector"
        colorValue={value}
        skipAlpha={true}
        onChangeColor={(color) => onChange(color)}
      />
    </div>
  );
};

StrokeColorSelect.propTypes = {
  displayEditorStore: PropTypes.object.isRequired,
  entity: PropTypes.object.isRequired,
  game: PropTypes.object.isRequired,
};

export default StrokeColorSelect;
