import lodash from 'lodash';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import ConfirmModal from '../../../../modals/confirm/ConfirmModal';
import SelectFolderModal from '../../../../modals/selectFolder/SelectFolderModal';
import SelectSignAndFileTypeModal from '../../../../modals/selectSignAndFileType/SelectSignAndFileTypeModal';
import inject from '../../../../hoc/injectHoc';
import {CONTENT_LIST_FOLDER} from '../../../../../constants/contentConstants';
import {OUT_OF_CREDITS_TEXT} from '../../../../../constants/messageConstants';

import './prestoSaveButton.scss';

/**
 * The PrestoSaveButton component.
 */
export class PrestoSaveButton extends React.Component {
  /**
   * Whether or not the folder modal is open.
   *
   * @type {boolean}
   */
  @observable isFolderModalOpen = false;

  /**
   * Whether or not the select file is open.
   *
   * @type {boolean}
   */
  @observable isSelectSignModalOpen = false;

  /**
   * Whether or not the confirm modal before saving is open.
   *
   * @type {boolean}
   */
  @observable isBeforeSaveConfirmOpen = false;

  /**
   * Whether or not the dropdown for the save options is open.
   *
   * @type {boolean}
   */
  @observable isSaveAlertOpen = false;

  /**
   * The file type to render
   *
   * @type {string}
   */
  @observable fileTypeToRender = null;

  /**
   * The save alert message.
   *
   * @type {string}
   */
  @observable saveAlertMessage = '';

  /**
   * Opens the folder modal if the save was confirmed.
   *
   * @param {boolean} wasConfirmed
   */
  @action onSaveConfirmComplete = (wasConfirmed) => {
    this.isBeforeSaveConfirmOpen = false;

    if (!wasConfirmed) {
      return;
    }

    this.isSelectSignModalOpen = true;
  };

  /**
   * Closes the save alert.
   */
  @action closeAlertModal = () => {
    this.isSaveAlertOpen = false;
  };

  /**
   * Toggles the save alert.
   */
  @action openSaveAlert = () => {
    this.isSaveAlertOpen = true;
  };

  /**
   * Triggered when the user clicks to save the video.
   *
   * @param {{}} clickEvent
   */
  @action onSaveVideoClick = (clickEvent) => {
    clickEvent.preventDefault();

    this.isBeforeSaveConfirmOpen = true;
  };

  /**
   * Closes the folder data and saves the content.
   *
   * @param {{}} param
   * @param {?number} param.folderId
   * @param {string} param.contentName
   */
  @action closeFolderModal = ({folderId, contentName}) => {
    this.isFolderModalOpen = false;

    if (!contentName) {
      return;
    }

    const {onCreated, saveNewTemplate} = this.props;

    let contentId = null;

    saveNewTemplate(
      folderId,
      contentName,
      false
    ).catch(
      action('saveTemplateErrorToAlert', (saveError) => {
        if (lodash.get(saveError, 'type') === 'OutOfCreditsError') {
          this.saveAlertMessage = 'You do not have enough download credits remaining to save this content.'
            + ' Please save it as a draft until you have more credits available.';
        } else {
          this.saveAlertMessage = 'An error occurred while trying to save this template.'
            + ' Please wait a few minutes and try again.';
        }

        this.openSaveAlert();

        return null;
      })
    ).then((newContent) => {
      if (!newContent || !newContent.id) {
        return null;
      }

      contentId = newContent.id;

      return this.startRenderProcess(contentId);
    }).then(() => {
      if (contentId) {
        onCreated(null, {
          contentId,
          listType: CONTENT_LIST_FOLDER,
          listId: folderId,
        });
      }
    });
  };

  /**
   * Closes the select sign modal
   *
   * @param {{}} param
   * @param {?number} param.signId
   * @param {?string} param.fileType
   * @returns {void}
   */
  @action closeSelectSignModal = ({fileType}) => {
    this.isSelectSignModalOpen = false;

    if (!fileType) {
      return null;
    }

    this.fileTypeToRender = fileType;

    const {content, onCreated, updateTemplate} = this.props;

    // if the content is not a draft, then we need to have user pick a folder and name
    if (!content.isDraft) {
      this.isFolderModalOpen = true;
      return null;
    }

    // if it is already a draft, then just save it.
    return updateTemplate(false).then(
      () => {
        return this.startRenderProcess(content.id).then(() => {
          if (onCreated) {
            onCreated(null, {contentId: content.id});
          }
        });
      },
      action('updateTemplateErrorToAlert', (updateError) => {
        if (lodash.get(updateError, 'type') === 'OutOfCreditsError') {
          this.saveAlertMessage = 'You do not have enough download credits remaining to publish this content.'
            + ' Please save it as a draft until you have more credits available.';
        } else {
          this.saveAlertMessage = 'An error occurred while trying to save this template.'
            + ' Please wait a few minutes and try again.';
        }

        this.openSaveAlert();
      })
    );
  }

  /**
   * Starts the rendering process.
   *
   * @param {number} contentId
   * @returns {Promise}
   */
  startRenderProcess = (contentId) => {
    const {apiContentRenderAllStore} = this.props;

    apiContentRenderAllStore.makeRequest(contentId, this.fileTypeToRender);

    // TODO - update to render only one sign?
    return apiContentRenderAllStore.getPromise(contentId).catch(() => {
      // Ignore render errors.
    });
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {content, disabled} = this.props;

    // This makes sure MobX re-renders the component when the saveAlertMessage is updated.
    this.saveAlertMessage; // eslint-disable-line no-unused-expressions

    return (
      <div className="presto-save-button">
        <button
          className="btn btn-secondary action-button save-button"
          type="button"
          disabled={disabled}
          onClick={this.onSaveVideoClick}
          data-cy="presto-bottom-bar-publish-button"
        >Publish</button>

        {

          // message alert modal
          (this.isSaveAlertOpen) && (
            <ConfirmModal
              isOpen={true}
              confirmText={this.saveAlertMessage}
              isYesNo={false}
              title="After Saved"
              onComplete={this.closeAlertModal}
            />
          )
        }

        {

          // confirm credit use modal
          (this.isBeforeSaveConfirmOpen) && (
            <ConfirmModal
              isOpen={true}
              bodyText={(
                <>
                  <div>
                    {OUT_OF_CREDITS_TEXT}
                  </div>
                  <br />
                  <div>Are you sure you are ready to save?</div>
                </>
              )}
              confirmText=""
              isYesNo={true}
              title="Are you sure you are ready to save?"
              onComplete={this.onSaveConfirmComplete}
            />
          )
        }

        {

          // select sign and file type modal
          (this.isSelectSignModalOpen) && (
            <SelectSignAndFileTypeModal
              modalHeaderText="File Type"
              modalBodyUpperSlot={(<p className="small">
                Select a file format to create your design in:
              </p>)}
              isOpen={true}
              hideSignDropdown={true}
              content={content}
              onComplete={this.closeSelectSignModal}
            />
          )
        }

        {

          // select folder modal
          (this.isFolderModalOpen) && (
            <SelectFolderModal
              defaultName={(content && content.name) ? content.name : ''}
              isOpen={true}
              onComplete={this.closeFolderModal}
            />
          )
        }
      </div>
    );
  }
}

PrestoSaveButton.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  onCreated: PropTypes.func.isRequired,
  saveNewTemplate: PropTypes.func.isRequired,
  updateTemplate: PropTypes.func.isRequired,

  apiContentRenderAllStore: MobxPropTypes.observableObject,
  disabled: PropTypes.bool,
};

PrestoSaveButton.wrappedComponent = {};
PrestoSaveButton.wrappedComponent.propTypes = {
  apiContentRenderAllStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(PrestoSaveButton)(
  observer(PrestoSaveButton)
);
