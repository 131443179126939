import {observable, runInAction} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React, {Component} from 'react';

import inject from '../../hoc/injectHoc';

import mainConfig from '../../../config/main';

import {loadScriptFromSource} from '../../../utils/scriptHelper';
import LoadingIcon from '../../common/loadingIcon/LoadingIcon';

const CHARGEBEE_SCRIPT_URL = 'https://js.chargebee.com/v2/chargebee.js';

/**
 * The Chargebee Checkout Component.
 */
class ChargebeePortal extends Component {
  /**
   * The chargebee instance.
   * @type {null}
   */
  @observable chargebeeInstance = null;

  /**
   * Is Loading Chargebee Portal
   * @type {boolean}
   */
  @observable isLoadingChargebeePortal = false;

  /**
   * Triggered when the component is first added to the repo
   */
  componentDidMount() {
    loadScriptFromSource(CHARGEBEE_SCRIPT_URL).then((wasLoaded) => {
      if (!wasLoaded) {
        return;
      }
      const Chargebee = window.Chargebee;
      this.chargebeeInstance = Chargebee.init({
        site: mainConfig.chargebee.site
      });
      this.chargebeeInstance = Chargebee.getInstance();
    });
  }

  /**
   * Open the chargebee portal so the user can update their subscription
   */
  openChargebeePortal = async () => {
    // Setup Chargebee Portal Session
    const {user, apiUserUpdateSubscriptionStore} = this.props;

    try {
      runInAction('Set the Chargebee Portal Loading', () => {
        this.isLoadingChargebeePortal = true;
      });

      apiUserUpdateSubscriptionStore.makeRequest({
        userId: user.projectContentUserId
      });
      const chargebeePortalResponse = await apiUserUpdateSubscriptionStore.getPromise();

      this.chargebeeInstance.setPortalSession(chargebeePortalResponse);

      // Open Chargebee Portal
      const cbPortal = this.chargebeeInstance.createChargebeePortal();
      cbPortal.open({
        close() {
          // close callbacks
        }
      });
    } catch (error) {
      runInAction('Error loading chargebee hosted page.', () => {
        // eslint-disable-next-line no-console
        console.error('Chargebee Portal Error: ', error);
      });
    } finally {
      runInAction('Set the Chargebee Portal Loading', () => {
        this.isLoadingChargebeePortal = false;
      });
    }
  }

  /**
   * Return a button that triggers the chargebee portal to open
   *
   * @returns {function}
   */
  render() {
    const {user} = this.props;

    const userIsSubscriber = user.projectContentCompany && user.projectContentCompany.subscriberUserId === user.projectContentUserId;

    if (!userIsSubscriber) {
      return null;
    }

    return (
      <button
        type="button"
        className="dropdown-item"
        disabled={this.isLoadingChargebeePortal}
        onClick={this.openChargebeePortal}
      >
        {this.isLoadingChargebeePortal ? <LoadingIcon size="em" /> : 'Subscription Summary and Billing'}
      </button>
    );
  }
}

ChargebeePortal.propTypes = {
  apiUserUpdateSubscriptionStore: MobxPropTypes.observableObject,
  user: MobxPropTypes.observableObject,
};

ChargebeePortal.wrappedComponent = {};
ChargebeePortal.wrappedComponent.propTypes = {
  apiUserUpdateSubscriptionStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(ChargebeePortal)(
  observer(ChargebeePortal)
);
