import React from 'react';
import {toJS} from 'mobx';
import lodash from 'lodash';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';

import EditorSidebarTitle from '../../common/editorSidebarTitle/EditorSidebarTitle';
import EditAlignmentControls from '../editAlignment/EditAlignmentControls';
import EditComposeControls from '../editCompose/EditComposeControls';
import EditEffectControls from '../editEffect/EditEffectControls';
import EditPositionControls from '../editPosition/EditPositionControls';
import EditTimelineControls from '../editTimeline/EditTimelineControls';
import {actionUpdateComponent} from '../../../display/components/action/actionUpdateComponent';
import {actionUpdateVariableComponent} from '../../../display/components/action/actionUpdateVariableComponent';
import {getComposeForSource} from '../../../display/components/common/composeComponent';
import {getRectangleForSource, getRectangleFromSource} from '../../../display/components/type/rectangleComponent';

import ColorSelector from '../../common/colorSelector/ColorSelector';
import ThicknessSelector from '../../common/thicknessSelector/ThicknessSelector';

import './editRectangleControls.scss';

/**
 * The EditRectangleControls component.
 */
export class EditRectangleControls extends React.Component {
  /**
   * Updates the rectangle entity when it is changed.
   *
   * @param {{
   *   styleColor: string,
   *   styleOpacity: number,
   *   borderColor: string,
   *   borderOpacity: number,
   * }} changes
   */
  onChangeRectangle = (changes) => {
    const {
      /** @type {DisplayEditorStore} */ displayEditorStore,
      /** @type {ObservableMap} */ entity,
      /** @type {GameStore} */ game,
    } = this.props;

    const {border, style} = entity.get('rectangle');
    const compose = entity.get('compose') || {};

    if (compose.variableName) {
      const element = entity.get('element');

      let currentVariable = displayEditorStore.getVariable(element, compose.variableName, {});
      if (lodash.isString(currentVariable)) {
        // Supported for deprecated string variables.
        const parts = currentVariable.split('|');
        currentVariable = {
          styleColor: parts[0],
          styleOpacity: parts[1],
          borderColor: parts[2],
          borderOpacity: parts[3], // eslint-disable-line no-magic-numbers
        };
      }

      const variableValue = lodash.defaults(
        lodash.pick(changes, ['styleColor', 'styleOpacity', 'borderColor', 'borderOpacity']),
        currentVariable,
        {styleColor: style.color, styleOpacity: style.opacity},
        {borderColor: border.color, borderOpacity: border.opacity},
      );

      displayEditorStore.setVariable(element, compose.variableName, variableValue);
    }

    const sourceVariables = toJS(displayEditorStore.variables);

    const entityUpdates = lodash.filter(game.entities, (gameEntity) => {
      if (gameEntity.get('id') === entity.get('id')) {
        return true;
      }

      if (!gameEntity.has('compose') || gameEntity.get('element') !== 'rectangle') {
        return false;
      } else if (gameEntity.get('compose').variableName !== compose.variableName) {
        return false;
      }

      return true;
    }).reduce((final, updateEntity) => {
      const updateId = updateEntity.get('id');
      const currentRectangle = updateEntity.get('rectangle');

      const rectangleSource = getRectangleForSource(updateEntity);
      const composeSource = getComposeForSource(updateEntity);

      rectangleSource.rectangle.style = lodash.defaults({
        color: changes.styleColor,
        opacity: changes.styleOpacity,
      }, currentRectangle.style || {});

      rectangleSource.rectangle.border = lodash.defaults({
        color: changes.borderColor,
        opacity: changes.borderOpacity,
      }, currentRectangle.border || {});

      final[updateId] = getRectangleFromSource(
        {...rectangleSource, ...composeSource},
        sourceVariables
      );
      return final;
    }, {});

    const actionParams = {
      entityId: entity.get('id'),
    };

    // This will rebuild the component for the entities using the new variable value.
    game.addAction(actionParams, actionUpdateVariableComponent(
      entityUpdates
    ));
  };

  /**
   * Updates the rectangle direct items (non-variable) when it is changed.
   *
   * @param {number} borderWidth
   * @param {number} rounded
   */
  onChangeRectangleDirect = (borderWidth, rounded) => {
    const {
      /** @type {ObservableMap} */ entity,
      /** @type {GameStore} */ game,
    } = this.props;

    const actionParams = {
      entityId: entity.get('id'),
    };

    const updates = {};
    if (borderWidth != null) {
      updates.border = {width: borderWidth || 0};
    }
    if (rounded != null) {
      updates.style = {rounded: rounded || 0};
    }

    // This will rebuild the component for the entities using the new variable value.
    game.addAction(actionParams, actionUpdateComponent({
      rectangle: updates,
    }));
  };

  /**
   * Renders the rectangle controls.
   *
   * @returns {{}}
   */
  renderRectangleControls() {
    const {entity, game} = this.props;

    const rectangle = entity.get('rectangle');
    const {border, style} = rectangle;

    return (
      <div className="group-controls">
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="rect-color-input">Fill</label>
              <ColorSelector
                id="rect-color-input"
                colorValue={style.color}
                opacityValue={style.opacity}
                onChangeColor={(color, opacity) => this.onChangeRectangle({
                  styleColor: color,
                  styleOpacity: opacity,
                })}
              />
            </div>
          </div>

          {(!game.composeMode) && (
            <div className="col">
              <div className="form-group">
                <label htmlFor="rect-rounded-input">Corner</label>
                <ThicknessSelector
                  id="rect-rounded-input"
                  thicknessValue={style.rounded}
                  onChangeThickness={(rounded) => this.onChangeRectangleDirect(null, rounded)}
                />
              </div>
            </div>
          )}
        </div>
        <div className="row">
          {(!game.composeMode || border.width > 0) && (
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="rect-border-color-input">Border</label>
                <ColorSelector
                  id="rect-border-color-input"
                  colorValue={border.color}
                  opacityValue={border.opacity}
                  onChangeColor={(color, opacity) => this.onChangeRectangle({
                    borderColor: color,
                    borderOpacity: opacity,
                  })}
                />
              </div>
            </div>
          )}

          {(!game.composeMode) && (
            <div className="col">
              <div className="form-group">
                <label htmlFor="rect-border-width-input">Width</label>
                <ThicknessSelector
                  id="rect-border-width-input"
                  allowZero={true}
                  thicknessValue={border.width}
                  onChangeThickness={(width) => this.onChangeRectangleDirect(width)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, game} = this.props;

    return (
      <div className="edit-rectangle-controls">
        <div className="rectangle-group">
          <EditorSidebarTitle title="Rectangle" />
          {this.renderRectangleControls()}
        </div>

        <EditComposeControls entity={entity} game={game} />
        <EditPositionControls entity={entity} game={game} />
        <EditAlignmentControls entity={entity} game={game} />
        <EditTimelineControls entity={entity} game={game} />
        <EditEffectControls entity={entity} game={game} />
      </div>
    );
  }
}

EditRectangleControls.propTypes = {
  displayEditorStore: PropTypes.object.isRequired,
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(EditRectangleControls);
