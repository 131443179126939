/* eslint-disable */

import {toJS} from 'mobx';

import {parseFramesPerSecond} from './ecs/timeHelper';
import {getTimerEntity} from './ecs/entityHelper';
import {parseFilesFromEntities, parseSourceFromGame} from './ecs/sourceHelper';
import {validateSource} from './ecs/sourceValidator';
import {systemProrities} from './ecs/systemProrities';
import presets, {hotReloadPresets} from './transitionPresets/index';

/* Stores */
import {GameStore, parseGameConfigFromSource} from '../stores/game/gameStore';
import {GameHistoryStore} from '../stores/game/gameHistoryStore';
import {GameTimerStore} from '../stores/game/gameTimerStore';

/* Systems */
import {actionEntitySystem} from './systems/actionEntitySystem';
import {actionPurgeSystem} from './systems/actionPurgeSystem';
import {actionUnitSystem} from './systems/actionUnitSystem';
import {actionUpdatingSystem} from './systems/actionUpdatingSystem';
import {actionUpdatingVariableSystem} from './systems/actionUpdatingVariableSystem';
import {alignmentSystem} from './systems/alignmentSystem';
import {croppingSystem} from './systems/croppingSystem';
import {groupingSystem} from './systems/groupingSystem';
import {interactionSystem} from './systems/interactionSystem';
import {linePositioningSystem} from './systems/linePositioningSystem';
import {orderingSystem} from './systems/orderingSystem';
import {positioningAlignSystem} from './systems/positioningAlignSystem';
import {positioningSystem} from './systems/positioningSystem';
import {sizingSystem} from './systems/sizingSystem';
import {trackingSystem} from './systems/trackingSystem';
import {transitionSystem} from './systems/transitionSystem';
import {visibilitySystem} from './systems/visibilitySystem';

/* Transition Plugins */
import './transitionPlugins/letterPlugin';
import './transitionPlugins/linePlugin';
import './transitionPlugins/maskPlugin';
import './transitionPlugins/opacityPlugin';
import {positionPluginSetGame} from './transitionPlugins/positionPlugin';
import './transitionPlugins/transformPlugin';
import './transitionPlugins/wordPlugin';
import './transitionPlugins/zIndexPlugin';

// Enable hot reloading of presets.
hotReloadPresets(presets);

/**
 * Loads up a game using a source file.
 *
 * @param {{}} source
 * @param {{}=} variables Optional. The variables to replace items in the source.
 * @param {boolean=} isContinuous Optional. Whether or not the timer should be in continuous mode.
 * @returns {{game: GameStore, timer: GameTimerStore}}
 */
export function loadGameFromSource(source, variables, isContinuous) {
  // First make sure the source file is valid.
  const validatedSource = validateSource(source);

  const gameConfig = parseGameConfigFromSource(validatedSource);

  const game = new GameStore(gameConfig);
  game.setFps(parseFramesPerSecond(gameConfig.fps));
  game.addSystem(actionEntitySystem(game), systemProrities.entity);
  game.addSystem(visibilitySystem(game), systemProrities.visibility);
  game.addSystem(groupingSystem(game), systemProrities.visibility);
  game.addSystem(interactionSystem(game), systemProrities.visibility);
  game.addSystem(alignmentSystem(game), systemProrities.aligning);
  game.addSystem(linePositioningSystem(game), systemProrities.positioning);
  game.addSystem(orderingSystem(game), systemProrities.positioning);
  game.addSystem(positioningAlignSystem(game), systemProrities.positioningAlign);
  game.addSystem(positioningSystem(game), systemProrities.positioning);
  game.addSystem(croppingSystem(game), systemProrities.cropping);
  game.addSystem(sizingSystem(game), systemProrities.sizing);
  game.addSystem(trackingSystem(game), systemProrities.tracking);
  game.addSystem(transitionSystem(game, presets), systemProrities.transition);
  game.addSystem(actionUnitSystem(game), systemProrities.typeUpdate);
  game.addSystem(actionUpdatingSystem(game), systemProrities.typeUpdate);
  game.addSystem(actionUpdatingVariableSystem(game), systemProrities.typeUpdate);
  game.addSystem(actionPurgeSystem(game), systemProrities.cleanup);

  validatedSource.entities.forEach((sourceEntity) => {
    game.addSourceEntity(sourceEntity, variables);
  });
  game.addEntity(
    getTimerEntity(game.endTime),
    true,
    'entity__main-timer'
  );

  const timer = new GameTimerStore();
  timer.setFps(game.fps);
  timer.setIsContinuous(Boolean(isContinuous));
  timer.setTickHandler(function handleTick(event) {
    game.update(event.time, event);
  });

  /**
   * Attach the timer and history to the game.
   */
  game.setTimer(timer);
  game.setHistory(new GameHistoryStore());
  timer.setMaxTime(game.endTime);

  // Initializes the display and loads everything into js memory.
  game.update(0);

  // Store the start of the game undo point.
  game.addUndoPoint();

  // Send the game to any transition plugins that need it.
  positionPluginSetGame(game);

  return {game, timer};
}

/**
 * Gets the final source JSON using the game object.
 *
 * @param {GameStore} game
 * @returns {{resolution: {width: number, height: number}, endTime: number, entities: Array<{type: string}>}}
 */
export function getSourceFromGame(game) {
  return parseSourceFromGame(game);
}

/**
 * Gets the file records using the game object.
 *
 * @param {GameStore} game
 * @returns {Array.<{id: number, filename: string}>}
 */
export function getFilesFromGame(game) {
  return parseFilesFromEntities(toJS(game.entities));
}

/**
 * Gets the file records using the game source object.
 *
 * @param {{entities: []}} source
 * @returns {Array.<{id: number, filename: string}>}
 */
export function getFilesFromSource(source) {
  return parseFilesFromEntities(source.entities);
}
