import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Modal, ModalBody, ModalFooter} from 'reactstrap';

import './NewAspectRatioModal.scss';

/**
 * The NewAspectRatioModal component.
 */
class NewAspectRatioModal extends React.Component {
  /**
   * The ratio input value.
   *
   * @type {string}
   */
  @observable newRatio = '';

  /**
   * The copy-from input value.
   *
   * @type {string}
   */
  @observable copyFrom = '';

  /**
   * The reference to the input element.
   *
   * @type {{current: ?HTMLElement}}
   */
  inputElement = React.createRef();

  /**
   * Initializes the modal.
   */
  @action initializeModal = () => {
    if (this.inputElement && this.inputElement.current) {
      this.inputElement.current.focus();
    }

    if (this.props.startingCopyFrom) {
      this.copyFrom = String(this.props.startingCopyFrom);
    }
  };

  /**
   * Resets the modal.
   */
  @action resetModal = () => {
    const {startingCopyFrom} = this.props;

    this.newRatio = '';
    this.copyFrom = startingCopyFrom;
  };

  /**
   * Triggered when the modal is opened.
   */
  onModalOpened = () => {
    this.initializeModal();
  };

  /**
   * Triggered when the modal is cancelled.
   */
  onCancelModal = () => {
    const {onComplete} = this.props;

    onComplete(null, null);

    this.resetModal();
  };

  /**
   * Triggered when the modal is completed.
   */
  onCompleteModal = () => {
    const {onComplete} = this.props;

    onComplete(
      String(this.newRatio),
      String(this.copyFrom)
    );

    this.resetModal();
  };

  /**
   * Triggered when the ratio input changes.
   *
   * @param {{}} changeEvent
   */
  @action onRatioSelected = (changeEvent) => {
    this.newRatio = changeEvent.target.value;
  };

  /**
   * Triggered when the copy-from input changes.
   *
   * @param {{}} changeEvent
   */
  @action onCopyFromSelected = (changeEvent) => {
    this.copyFrom = changeEvent.target.value;
  };

  /**
   * Prevents the submission of the form and instead submits the modal.
   *
   * @param {{preventDefault: function}} submitEvent
   */
  onSubmit = (submitEvent) => {
    submitEvent.preventDefault();

    this.onCompleteModal();
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {isOpen, newAspectRatios, currentAspectRatios} = this.props;

    if (!isOpen) {
      return null;
    }

    return (
      <Modal
        className="new-aspect-ratio-modal"
        isOpen={isOpen}
        toggle={this.onCancelModal}
        onOpened={this.onModalOpened}
        centered
      >
        <ModalBody>
          <form onSubmit={this.onSubmit}>
            <div className="form-group">
              <label htmlFor="dimensions-dropdown" className="site-label">New Aspect Ratio:</label>
              <select
                className="form-control"
                id="dimensions-dropdown"
                onChange={this.onRatioSelected}
                value={this.newRatio}
              >
                <option disabled hidden value="">-- Select One --</option>
                {newAspectRatios.map(({aspectRatio, label}) => {
                  return (
                    <option
                      key={aspectRatio}
                      value={aspectRatio}
                    >{label}</option>
                  );
                })}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="copy-from-dropdown" className="site-label">Copy From:</label>
              <select
                className="form-control"
                id="copy-from-dropdown"
                value={this.copyFrom}
                onChange={this.onCopyFromSelected}
              >
                {currentAspectRatios.map(({aspectRatio, label}) => {
                  return (
                    <option
                      key={aspectRatio}
                      value={aspectRatio}
                    >{label}</option>
                  );
                })}
              </select>
              <small className="form-text text-light">
                Determines which layout is copied to the new aspect ratio.
              </small>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-default" onClick={this.onCancelModal}>Cancel</button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.onCompleteModal}
            disabled={(!this.newRatio)}
          >
            Save
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

NewAspectRatioModal.propTypes = {
  currentAspectRatios: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  newAspectRatios: PropTypes.array.isRequired,
  onComplete: PropTypes.func.isRequired,

  startingCopyFrom: PropTypes.string,
};

NewAspectRatioModal.defaultProps = {
  startingCopyFrom: '',
};

export default observer(NewAspectRatioModal);
