import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import AdvancedFeedControls from './components/advancedFeedIconControls/AdvancedFeedIconControls';
import EditorSidebarTitle from '../../common/editorSidebarTitle/EditorSidebarTitle';
import EditAlignmentControls from '../editAlignment/EditAlignmentControls';
import EditComposeControls from '../editCompose/EditComposeControls';
import EditPositionControls from '../editPosition/EditPositionControls';
import EditUnitsControls from '../editUnits/EditUnitsControls';

import './editFeedIconControls.scss';

/**
 * The EditFeedIconControls component.
 */
export class EditFeedIconControls extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      /** @type {DisplayEditorStore} */ displayEditorStore,
      /** @type {ObservableMap} */ entity,
      /** @type {GameStore} */ game,
    } = this.props;

    return (
      <div className="edit-feed-icon-controls">
        <div className="feed-icon-group">
          <EditorSidebarTitle title="Feed Icon" />
        </div>

        <AdvancedFeedControls displayEditorStore={displayEditorStore} entity={entity} game={game} />

        <EditComposeControls entity={entity} game={game} />
        <EditPositionControls entity={entity} game={game} />
        <EditAlignmentControls entity={entity} game={game} />
        <EditUnitsControls entity={entity} game={game} />
      </div>
    );
  }
}

EditFeedIconControls.propTypes = {
  displayEditorStore: PropTypes.object.isRequired,
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(EditFeedIconControls);
