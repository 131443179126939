import React from 'react';
import {observer} from 'mobx-react';
import classNames from 'classnames';

import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import _capitalize from 'lodash/capitalize';

import inject from '../../../../hoc/injectHoc';

import './transitionLibraryItem.scss';

/**
 * The TransitionEntityItem component.
 */
class TransitionEntityItem extends React.Component {
  /**
   * Select a transition
   */
  onSelectTransitionEntity = () => {
    const {transitionEntity, onClick} = this.props;

    onClick(transitionEntity.key);
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {transitionEntity, isActive, onChangeDirection, onClick} = this.props;
    const transitionEntityOptions = Object.keys(transitionEntity.presets).map((option) => ({
      label: `${_capitalize(option)}`,
      value: option,
    }));

    const selectedTransitionEntity = transitionEntity.presets[transitionEntity.selectedDirection];
    const showDirectionSelect = transitionEntityOptions[0].value !== 'default';
    const transitionGifSrc =
      selectedTransitionEntity && selectedTransitionEntity.in && selectedTransitionEntity.in.gif
        ? selectedTransitionEntity.in.gif
        : '';

    return (
      <>
        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-one-fifth">
          <div
            className={classNames('transition-entity', {active: isActive})}
            onClick={onClick}
          >
            <div className="transition-entity-img">
              <img src={transitionGifSrc} alt={`${transitionEntity.label} ${transitionEntity.selectedDirection}`} />
            </div>
            <div className="transition-entity-label">{transitionEntity.label}</div>
            {/* Need to swallow the click event for react select with stopPropagation */}
            {showDirectionSelect && (<div className="transition-entity-select" onClick={(e) => e.stopPropagation()}>
              <ReactSelect
                className="site-select theme-input-white"
                classNamePrefix="react-select"
                isClearable={false}
                isMulti={false}
                onChange={onChangeDirection}
                options={transitionEntityOptions}
                value={transitionEntityOptions.find((option) => option.value === transitionEntity.selectedDirection)}
              />
            </div>)}
          </div>
        </div>
      </>
    );
  }
}

TransitionEntityItem.propTypes = {
  onChangeDirection: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  transitionEntity: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
};

TransitionEntityItem.defaultProps = {
};

TransitionEntityItem.wrappedComponent = {};
TransitionEntityItem.wrappedComponent.propTypes = {
};

export default inject(TransitionEntityItem)(
  observer(TransitionEntityItem)
);
