import {action} from 'mobx';

import ApiBaseStore from '../common/apiBaseStore';
import {STATE_PENDING, STATE_FULFILLED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';

/**
 * Update an exisiting collection example.
 */
class ApiCollectionExampleUpdateStore extends ApiBaseStore {
  /**
   * Updates an existing collection using the api.
   *
   * @param {number} collectionExampleId
   * @param {{}} collectionExampleData
   */
  @action makeRequest(collectionExampleId, collectionExampleData) {
    this.state = STATE_PENDING;
    this.data = null;
    this.error = null;
    this[EXPIRE_TIME] = Date.now() + EXPIRES_PENDING;

    serverApi.collectionExampleUpdate(collectionExampleId, collectionExampleData).then(
      action('collectionExampleUpdateSuccess', (newCollectionExampleData) => {
        this.state = STATE_FULFILLED;
        this.data = newCollectionExampleData;
        this.error = null;
        this[EXPIRE_TIME] = Date.now() + EXPIRES_IN;
      })
    ).catch(
      action('collectionExampleUpdateError', (collectionExampleUpdateError) => {
        this.state = STATE_FULFILLED;
        this.data = null;
        this.error = collectionExampleUpdateError;
      })
    );
  }
}

export default new ApiCollectionExampleUpdateStore();
