/* eslint-disable no-magic-numbers */
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import classNames from 'classnames';

import inject from '../../hoc/injectHoc';
import sunnyIcon from '../../../assets/icons/sunny_icon.png';
import partlySunnyIcon from '../../../assets/icons/partly_sunny_icon.png';
import rainyIcon from '../../../assets/icons/rainy_icon.png';
import {userPaymentRoute} from '../../routePaths';
import {replaceRouteParams} from '../../../utils/routeHelper';
import {SUPER_ADMIN_ROLE} from '../../../constants/userConstants';

import './selectFeedModal.scss';
import {getExtendedWeatherLayers} from './extendedWeather';
import {CounterModal} from './counter/CounterModal';

/**
 * The default modal title.
 * @const {string}
 */
const DEFAULT_TITLE = 'Select Feed';

/**
 * The SelectFeedModal component.
 */
export class SelectFeedModal extends React.Component {
  /**
   * Determines which Feed folder is open
   */
  @observable selectedFeedFolder = 'all'

  /*
   * Determines if the Counter Modal is open
   */
  @observable isCounterModalOpen = false

  /**
   * Triggered when the component has just updated.
   *
   * @param {{isOpen: boolean}} prevProps
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.initializeModal();
    }
  }

  /**
   * Initializes the modal.
   */
  @action initializeModal = () => {
    // Put any initializations here.
    this.toggleCounterModal(false);
  };

  /**
   * Sets which feed folder is open.
   * @param {'all' | 'included' | 'premium'} feedFolderKey
   */
  @action setSelectedFeedFolder = (feedFolderKey) => {
    this.selectedFeedFolder = feedFolderKey;
  }

  /**
   * Toggle the Counter modal
   *
   * @param {boolean} isShown
   */
  @action toggleCounterModal = (isShown) => {
    if (typeof isShown === 'boolean') {
      this.isCounterModalOpen = isShown;
    } else {
      this.isCounterModalOpen = !this.isCounterModalOpen;
    }
  };

  /**
   * Calculate and generate feed items
   * @returns {*}
   */
  generateFeedItems = () => {
    const {resolution} = this.props;

    return [
      {
        name: 'Current Temp',
        type: 'Weather',
        placeholder: '100°',
        entities: [
          {
            type: 'Weather',
            entityType: 'feed',
            markdown: '{|}{$1.00}{%1}{100}{#ffffff}{poppinsbold}100°{!poppinsbold}{!#ffffff}{!100}{!%1}{!$1.00}{!|}'
          }
        ],
        isPremium: false,
        onClick: (feedItem) => this.onCompleteModal(feedItem)
      },
      {
        name: 'Current Time',
        type: 'CurrentTime',
        placeholder: '12:00',
        entities: [
          {
            type: 'CurrentTime',
            entityType: 'feed',
            markdown: '{|}{$1.00}{%1}{100}{#ffffff}{poppinsbold}12:00{!poppinsbold}{!#ffffff}{!100}{!%1}{!$1.00}{!|}',
          }
        ],
        isPremium: false,
        onClick: (feedItem) => this.onCompleteModal(feedItem)
      },
      {
        name: 'Current Date',
        type: 'CurrentDate',
        placeholder: 'MM/DD/YYYY',
        entities: [
          {
            type: 'CurrentDate',
            entityType: 'feed',
            markdown: '{|}{$1.00}{%1}{100}{#ffffff}{poppinsbold}MM/DD/YYYY{!poppinsbold}{!#ffffff}{!100}{!%1}{!$1.00}{!|}',
          }
        ],
        isPremium: false,
        onClick: (feedItem) => this.onCompleteModal(feedItem)
      },
      {
        name: '3 Day Forecast',
        feedType: '3DayForecast',
        placeholder:
          <div className="mx-2 flex-grow-1 row no-gutters">
            <div className="col-4 px-1">
              <div className="forecast-card">
                <p>TODAY</p>
                <img src={sunnyIcon} />
                <p>100°</p>
                <p>80°</p>
              </div>
            </div>
            <div className="col-4 px-1">
              <div className="forecast-card">
                <p>MON</p>
                <img src={rainyIcon} />
                <p>100°</p>
                <p>80°</p>
              </div>
            </div>
            <div className="col-4 px-1">
              <div className="forecast-card">
                <p>TUE</p>
                <img src={partlySunnyIcon} />
                <p>100°</p>
                <p>80°</p>
              </div>
            </div>
          </div>,
        entities: getExtendedWeatherLayers(resolution),
        isPremium: true,
        onClick: (feedItem) => this.onCompleteModal(feedItem)
      },
      {
        name: 'Counter',
        type: 'Counter',
        placeholder:
          <div className="counter">
            <div
              className="counter-inner-container"
            >
              <div>0</div>
              <div>2</div>
              <div>
                3<br />
                4
              </div>
            </div>
          </div>,
        entities: [
          {
            type: 'Counter',
            entityType: 'feed',
            markdown: '{|}{$1.00}{%1}{100}{#ffffff}{poppinsbold}10 days{!poppinsbold}{!#ffffff}{!100}{!%1}{!$1.00}{!|}'
          }
        ],
        isPremium: false,
        onClick: () => this.toggleCounterModal(true)
      },
    ];
  }

  /**
   * Triggered when the modal is closed without finishing.
   */
  onCancelModal = () => {
    const {onComplete} = this.props;

    onComplete(false);
  };

  /**
   * Triggered when the modal is closed after choosing a feed type.
   * @param {{}} feedItem
   */
  @action onCompleteModal = async (feedItem) => {
    const {onComplete} = this.props;

    onComplete(feedItem);
  };

  /**
   * Redirects to the sign up page.
   */
  onUpgrade = () => {
    const {routerStore} = this.props;

    const toRoute = replaceRouteParams(userPaymentRoute, {planId: ''});
    routerStore.push(userPaymentRoute, {
      addTo: toRoute,
    });
  };

  /**
   * Redirects to the sign up page.
   *
   * @param {{}} feedItem
   * @param {boolean} userHasAccessToPremium
   * @returns {null}
   */
  onFeedItemClick = (feedItem, userHasAccessToPremium) => {
    if (feedItem.isPremium && !userHasAccessToPremium) {
      return null;
    }

    feedItem.onClick(feedItem);
    return null;
  };

  /**
   * Render the feed item
   * @param {{}} feedItem
   * @param {boolean} userHasAccessToPremium
   *
   * @returns {{}}
   */
  renderFeedItem = (feedItem, userHasAccessToPremium) => {
    return (
      <div key={feedItem.name} className="col-sm-12 col-lg-6 col-xl-4">
        <div
          className={classNames('d-flex flex-column feed-item', {'premium-feed-item': feedItem.isPremium && !userHasAccessToPremium})}
          onClick={() => this.onFeedItemClick(feedItem, userHasAccessToPremium)}
        >
          <div className="feed-item-thumbnail">
            {feedItem.placeholder}
          </div>
          <div className="feed-item-info p-3">
            <div className="feed-item-name">
              {feedItem.name}
            </div>
          </div>
        </div>
        {(feedItem.isPremium && !userHasAccessToPremium) && (
          <button
            className="btn btn-secondary action-button save-button mb-3"
            type="button"
            onClick={this.onUpgrade}
          >Upgrade Plan To Use</button>)}
      </div>
    );
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      isOpen,
      apiUserGetMeStore,
      resolution,
      title
    } = this.props;

    const feedItems = this.generateFeedItems();
    const includedFeedItems = feedItems.filter((feedItem) => !feedItem.isPremium);
    const premiumFeedItems = feedItems.filter((feedItem) => feedItem.isPremium);
    const user = apiUserGetMeStore.getFulfilled();
    const userHasPaidPlan = user && user.plan ? (user.plan.price > 0 || user.plan.name === 'Trial') : false;
    const userIsSuperAdmin = user && user.role === SUPER_ADMIN_ROLE;
    const userHasAccessToPremium = userHasPaidPlan || userIsSuperAdmin;

    const feedItemsToShow = {
      all: feedItems,
      included: includedFeedItems,
      premium: premiumFeedItems
    };

    if (!isOpen) {
      return null;
    }

    return (
      <Modal isOpen={isOpen} toggle={this.onCancelModal} className="select-feed-modal" centered>
        <ModalHeader toggle={this.onCancelModal}>{title}</ModalHeader>
        <ModalBody>
          <div>
            <div className="select-feed-main">
              <div className="left-side">
                <div
                  className={classNames('feed-type-list-item d-flex align-items-center', {active: this.selectedFeedFolder === 'all'})}
                  onClick={() => this.setSelectedFeedFolder('all')}
                >
                  <div>
                    All Feeds
                  </div>
                  <div className="feed-type-list-item-count ml-auto">
                    {feedItems.length}
                  </div>
                </div>
                <div
                  className={classNames('feed-type-list-item d-flex align-items-center', {active: this.selectedFeedFolder === 'included'})}
                  onClick={() => this.setSelectedFeedFolder('included')}
                >
                  <div>
                    Included Feeds
                  </div>
                  <div className="feed-type-list-item-count ml-auto">
                    {includedFeedItems.length}
                  </div>
                </div>
                <div
                  className={classNames('feed-type-list-item d-flex align-items-center', {active: this.selectedFeedFolder === 'premium'})}
                  onClick={() => this.setSelectedFeedFolder('premium')}
                >
                  <div>
                    Premium Feeds
                  </div>
                  <div className="feed-type-list-item-count ml-auto">
                    {premiumFeedItems.length}
                  </div>
                </div>
              </div>
              <div className="right-side container-fluid">
                <div className="row">
                  {feedItemsToShow[this.selectedFeedFolder].map((feedItem) => this.renderFeedItem(feedItem, userHasAccessToPremium))}
                </div>
              </div>
            </div>
          </div>
          <CounterModal
            isOpen={this.isCounterModalOpen}
            resolution={resolution}
            onCancelModal={this.toggleCounterModal}
            onComplete={this.onCompleteModal}
          />
        </ModalBody>
      </Modal>
    );
  }
}

SelectFeedModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  resolution: MobxPropTypes.observableObject.isRequired,

  apiUserGetMeStore: MobxPropTypes.observableObject,
  routerStore: MobxPropTypes.observableObject,
  title: PropTypes.string,
};

SelectFeedModal.defaultProps = {
  title: DEFAULT_TITLE,
};

SelectFeedModal.wrappedComponent = {};
SelectFeedModal.wrappedComponent.propTypes = {
  apiUserGetMeStore: MobxPropTypes.observableObject.isRequired,
  resolution: MobxPropTypes.observableObject.isRequired,
  routerStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(SelectFeedModal)(
  observer(SelectFeedModal)
);
