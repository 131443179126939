import {STATE_PRE, STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../constants/asyncConstants';

/**
 * The api store helper items.
 * This should be attached to an API store using extendObservable().
 *
 * @type {{}}
 */
export const apiStore = {
  /**
   * The state of the async process.
   *
   * @type {Symbol}
   */
  state: STATE_PRE,

  /**
   * The async state before any async calls have been made.
   * @const {Symbol}
   */
  PRE: STATE_PRE,

  /**
   * The async state for when an async call is ongoing/pending.
   *
   * @const {Symbol}
   */
  PENDING: STATE_PENDING,

  /**
   * The async state after an async call was rejected.
   *
   * @const {Symbol}
   */
  REJECTED: STATE_REJECTED,

  /**
   * The async state after an async call was successful.
   *
   * @const {Symbol}
   */
  FULFILLED: STATE_FULFILLED,
};

/**
 * Gets the case statement for an api store.
 *
 * @param {Symbol} state
 * @param {function} getFulfilled
 * @param {function} getRejected
 * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
 * @returns {{}}
 */
export function getCase(state, getFulfilled, getRejected, handlers) {
  if (!handlers) {
    throw new Error('Invalid handlers given to case.');
  }

  switch (state) {
    case STATE_PENDING:
      return handlers.pending && handlers.pending();
    case STATE_FULFILLED:
      return handlers.fulfilled && handlers.fulfilled(getFulfilled());
    case STATE_REJECTED:
      return handlers.rejected && handlers.rejected(getRejected());
    case STATE_PRE:
    default:
      return handlers.pre && handlers.pre();
  }
}
