import lodash from 'lodash';
import {runInAction} from 'mobx';

import {clearTransitionCache, updateEntity} from '../ecs/entityHelper';
import displayEditorStore from '../../stores/display/displayEditorStore';

/**
 * The name of the system.
 * @const {string}
 */
export const SIZING_SYSTEM = 'sizingSystem';

/**
 * The number to divide by to convert to a percentage.
 *
 * @const {number}
 */
const TO_PERCENT = 100;

/**
 * Gets a new instance of the sizing system.
 *
 * @param {GameStore} game
 * @returns {{name: string, update: systemUpdate}}
 */
export function sizingSystem(game) {
  /**
   * Converts a size percentage to the size in pixels.
   *
   * @param {string} percentage
   * @param {number} gameResolution
   * @returns {number}
   */
  function parsePercentageToPixels(percentage, gameResolution) {
    if (String(percentage).indexOf('%') === -1) {
      return percentage;
    }

    const percentageValue = Number.parseFloat(percentage);
    const pixelValue = Number((percentageValue / TO_PERCENT) * gameResolution);

    if (pixelValue > gameResolution) {
      return gameResolution;
    }

    return pixelValue;
  }

  /**
   * Called when the game loop updates.
   *
   * @param {Array.<{}>} actions
   */
  function systemRunActions(actions) {
    actions.forEach((actionEntity) => {
      // First check for required components.
      if (!actionEntity.has('actionGameResize')) {
        return;
      }

      const actionGameResize = actionEntity.get('actionGameResize');

      game.setResolution(actionGameResize.newWidth, actionGameResize.newHeight);
      displayEditorStore.setCurrentAspectRatio(actionGameResize.newAspectRatio);

      game.entities.forEach((entity) => {
        updateSizeForEntity(entity, true);
        updatePositionForEntity(entity, true);
        updateImageForEntity(entity, true);

        clearTransitionCache(entity);
      });
    });
  }

  /**
   * Updates the entity size.
   *
   * @param {{}} entity
   * @param {boolean} forceUpdate
   */
  function updateSizeForEntity(entity, forceUpdate) {
    // First check for required components.
    if (!entity || !entity.has('size')) {
      return;
    }

    const currentSize = entity.get('size');
    const safeSize = lodash.cloneDeep(currentSize);
    let hasChanges = false;

    if (currentSize.default.widthIsPercent && (forceUpdate || currentSize.widthIsPercent)) {
      safeSize.width = parsePercentageToPixels(currentSize.default.width, game.resolution.width);
      safeSize.widthIsPercent = false;
      hasChanges = true;
    }
    if (currentSize.default.heightIsPercent && (forceUpdate || currentSize.heightIsPercent)) {
      safeSize.height = parsePercentageToPixels(currentSize.default.height, game.resolution.height);
      safeSize.heightIsPercent = false;
      hasChanges = true;
    }

    if (!hasChanges) {
      return;
    }

    // Now update the entity.
    runInAction('sizingSystemUpdateEntitySize', () => {
      updateEntity(entity, 'size', safeSize);
    });

    clearTransitionCache(entity);
  }

  /**
   * Updates the entity image.
   *
   * @param {{}} entity
   * @param {boolean} forceUpdate
   */
  function updateImageForEntity(entity, forceUpdate) {
    // First check for required components.
    if (!entity || !entity.has('image')) {
      return;
    }

    const currentImage = entity.get('image');
    const safeImage = lodash.cloneDeep(currentImage);
    let hasChanges = false;

    const updateCheck = (paramName, widthOrHeight) => {
      const param = String(paramName);
      const isPercentParam = `${paramName}IsPercent`;
      const resolutionParam = String(widthOrHeight);

      if (currentImage.default[isPercentParam] && (forceUpdate || currentImage[isPercentParam])) {
        safeImage[param] = parsePercentageToPixels(
          currentImage.default[param],
          game.resolution[resolutionParam]
        );
        safeImage[isPercentParam] = false;
        hasChanges = true;
      }
    };

    updateCheck('width', 'width');
    updateCheck('height', 'height');
    updateCheck('x', 'width');
    updateCheck('y', 'height');

    if (!hasChanges) {
      return;
    }

    // Now update the entity.
    runInAction('sizingSystemUpdateEntityImage', () => {
      updateEntity(entity, 'image', safeImage);
    });

    clearTransitionCache(entity);
  }

  /**
   * Updates the entity position.
   *
   * @param {{}} entity
   * @param {boolean} forceUpdate
   */
  function updatePositionForEntity(entity, forceUpdate) {
    // First check for required components.
    if (!entity || !entity.has('position')) {
      return;
    }

    const currentPosition = entity.get('position');
    const safePosition = lodash.cloneDeep(currentPosition);
    let hasChanges = false;

    if (currentPosition.default.xIsPercent && (forceUpdate || currentPosition.xIsPercent)) {
      safePosition.x = parsePercentageToPixels(currentPosition.default.x, game.resolution.width);
      safePosition.xIsPercent = false;
      hasChanges = true;
    }
    if (currentPosition.default.yIsPercent && (forceUpdate || currentPosition.yIsPercent)) {
      safePosition.y = parsePercentageToPixels(currentPosition.default.y, game.resolution.height);
      safePosition.yIsPercent = false;
      hasChanges = true;
    }

    if (!hasChanges) {
      return;
    }

    // Now update the entity.
    runInAction('sizingSystemUpdateEntityPosition', () => {
      updateEntity(entity, 'position', safePosition);
    });

    clearTransitionCache(entity);
  }

  /**
   * Called when the game loop updates.
   *
   * @param {Array.<{}>} entities
   */
  function systemUpdate(entities) {
    entities.forEach((entity) => {
      updateSizeForEntity(entity);
      updatePositionForEntity(entity);
      updateImageForEntity(entity);
    });
  }

  return {
    name: SIZING_SYSTEM,
    runActions: systemRunActions,
    update: systemUpdate
  };
}
