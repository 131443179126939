/**
 * The action align component.
 *
 * @param {string} alignment
 * @returns {{actionAlign: {alignment: string}}}
 */
export function actionAlignComponent(alignment) {
  return {
    actionAlign: {
      alignment,
    },
  };
}
