import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencil, faSave, faTimes} from '@fortawesome/free-solid-svg-icons';

import inject from '../../../../hoc/injectHoc';
import {CONTENT_LIST_COLLECTION, CONTENT_LIST_FOLDER} from '../../../../../constants/contentConstants';

import './contentName.scss';

/**
 * The ContentName component.
 */
export class ContentName extends React.Component {
  /**
   * Whether or not the name is being edited.
   *
   * @type {boolean}
   */
  @observable isEditingName = false;

  /**
   * Current state of name (for editing).
   *
   * @type {string}
   */
  @observable name = '';

  /**
   * Triggered when name input is changed.
   *
   * @param {{}} clickEvent
   */
  @action onEditName = (clickEvent) => {
    this.name = clickEvent.target.value || '';
  };

  /**
   * Triggered when edit name button is clicked
   */
  @action onEditNameClick = () => {
    const {content} = this.props;

    this.name = content.name;
    this.isEditingName = true;
  };

  /**
   * Triggered when edit name button is clicked
   */
  @action onSaveCancelClick = () => {
    this.name = '';
    this.isEditingName = false;
  };

  /**
   * Triggered when the save name button is clicked
   */
  @action onSaveNameClick = () => {
    if (!this.name) {
      this.name = '';
      this.isEditingName = false;
      return;
    }

    const {
      content,
      folderId,
      folderType,
      onRename,
      /** @type {ApiContentFolderGetContentStore} */ apiContentFolderGetContentStore,
      /** @type {ApiCollectionGetContentStore} */ apiCollectionGetContentStore,
      /** @type {ApiContentUpdateStore} */ apiContentUpdateStore,
    } = this.props;

    const safeName = String(this.name).trim();

    apiContentUpdateStore.makeRequest(content.id, {name: safeName});

    apiContentUpdateStore.getPromise().then(() => {
      // Clear the folder cache after rename.
      if (folderType === CONTENT_LIST_FOLDER) {
        apiContentFolderGetContentStore.refresh(folderId, true);
      } else if (folderType === CONTENT_LIST_COLLECTION) {
        apiCollectionGetContentStore.refresh(folderId, true);
      }

      if (onRename) {
        onRename();
      }
    }).then(action('cancelEditContentName', () => {
      this.name = '';
      this.isEditingName = false;
    }));
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {content, user} = this.props;

    // Is owner
    let shouldNameBeEditable = !content.isGlobal;
    if (content.isGlobal && user && user.canDesign) {
      shouldNameBeEditable = true;
    }

    return (
      <div className="content-name mb-3 mb-md-0">
        {(this.isEditingName) ? (
          <input
            className="edit-name-input"
            value={this.name}
            onChange={this.onEditName}
          />
        ) : (
          <h4 className="mb-0 font-weight-bold">
            {content.name}
          </h4>
        )}

        {(shouldNameBeEditable) && (
          (this.isEditingName) ? (
            <>
              <button
                className="btn btn-link p-0 ml-2 edit-name-button"
                type="button"
                onClick={this.onSaveNameClick}
              >
                <FontAwesomeIcon icon={faSave} />
              </button>

              <button
                className="btn btn-link p-0 ml-2 cancel-name-button"
                type="button"
                onClick={this.onSaveCancelClick}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </>
          ) : (
            <button
              className="btn btn-link p-0 ml-2 edit-name-button"
              type="button"
              onClick={this.onEditNameClick}
            >
              <FontAwesomeIcon icon={faPencil} />
            </button>
          )
        )}
      </div>
    );
  }
}

ContentName.propTypes = {
  content: PropTypes.object.isRequired,

  apiCollectionGetContentStore: MobxPropTypes.observableObject,
  apiContentFolderGetContentStore: MobxPropTypes.observableObject,
  apiContentUpdateStore: MobxPropTypes.observableObject,
  folderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  folderType: PropTypes.string,
  onRename: PropTypes.func,
  user: PropTypes.object,
};

ContentName.wrappedComponent = {};
ContentName.wrappedComponent.propTypes = {
  apiCollectionGetContentStore: MobxPropTypes.observableObject.isRequired,
  apiContentFolderGetContentStore: MobxPropTypes.observableObject.isRequired,
  apiContentUpdateStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(ContentName)(
  observer(ContentName)
);
