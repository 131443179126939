import React, {Component} from 'react';
import {observable, action} from 'mobx';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faMinus} from '@fortawesome/free-solid-svg-icons';

import circleCheckPng from '../../../../../assets/icons/circle_check.png';

import './alaCarte.scss';

const MAX_CREDIT_COUNT = 99;
const CENTS_IN_DOLLAR = 100;

/**
 * The AlaCarteItem component.
 */
class AlaCarteItem extends Component {
  /**
   * How many credits the user would like to purchase
   *
   * @type {number}
   */
  @observable creditCount = 0;

  /**
   * Triggered when the component first mounts to the page.
   */
  @action componentDidMount() {
    const {quantity} = this.props;

    if (quantity) {
      this.creditCount = quantity;
    }
  }

  /**
   * Updates the Credit Count
   *
   * @param {number} newCreditCount
   */
  @action onCreditChange(newCreditCount) {
    const {id, updateCredit} = this.props;
    const validCreditCount = newCreditCount <= MAX_CREDIT_COUNT && newCreditCount >= 0;
    if (validCreditCount) {
      updateCredit(id, parseInt(newCreditCount, 10));
    }
  }

  /**
   * Renders the credit buttons and inputs.
   *
   * @returns {{}}
   */
  renderCreditInput = () => {
    const {quantity} = this.props;
    const onCreditIncrement = () => this.onCreditChange(quantity + 1);
    const onCreditDecrement = () => this.onCreditChange(quantity - 1);

    return (
      <div className="credit-buttons">
        <button
          data-cy="download-credit-decrement-button"
          type="button"
          className="btn btn-sm btn-dark"
          onClick={onCreditDecrement}
        >
          <FontAwesomeIcon icon={faMinus} />
        </button>
        <input
          className="credit-field"
          maxLength="2"
          value={quantity}
          onChange={(e) => this.onCreditChange(e.target.value)}
        />
        <button
          data-cy="download-credit-increment-button"
          type="button"
          className="btn btn-sm btn-dark"
          onClick={onCreditIncrement}
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>
    );
  };

  /**
   * Renders the AlaCarteItem Component
   * @returns {{}}
   */
  render() {
    const {header, quantity, title, description, pricePerCredit, features, style} = this.props;

    return (
      <>
        <div className={`alacarte-item ${style.background}`}>
          <div className="text-center">
            <div className={`item-header ${style.header}`}>{header}</div>
            <div className="item-title">
              Credit Total
            </div>
            <div className="text-center text-uppercase pb-3">
              ${(pricePerCredit / CENTS_IN_DOLLAR)} per credit
            </div>
            {this.renderCreditInput()}
            <div className="item-price">
              <div className="item-price-text">Total</div>
              <sup className="item-currency">$</sup>
              <span className="item-price-number">{(pricePerCredit / CENTS_IN_DOLLAR) * quantity}</span>
            </div>
          </div>
          <div className="item-description">
            <div className="item-description-title">
              {title}
            </div>
            <div className="item-description-content">
              {description}
            </div>
          </div>
          <div className="item-features">
            {/* Loop through the features */}
            {features.map((feature) => {
              return (
                <div className="d-flex py-1" key={feature}>
                  <img src={circleCheckPng} height={25} width={25} />
                  <div className="item-feature-content">{feature}</div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

AlaCarteItem.propTypes = {
  description: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  pricePerCredit: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  updateCredit: PropTypes.func.isRequired,

  features: PropTypes.arrayOf(PropTypes.string),
  style: PropTypes.object,
};

AlaCarteItem.defaultProps = {
  features: [],
  style: {
    header: 'primary',
    background: 'white'
  },
};

export default observer(AlaCarteItem);
