/**
 * Gets the data for a placeholder circle.
 *
 * @returns {{circle: {}}}
 */
export function getPlaceHolderCircleData() {
  return {
    circle: {
      style: {
        color: '#000000',
        opacity: 1,
      },
      border: {
        color: '#000000',
        opacity: 1,
        width: 0,
      }
    }
  };
}
