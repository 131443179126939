import {toJS} from 'mobx';
import PropTypes from 'prop-types';
import React from 'react';

import ColorSelector from '../../../../common/colorSelector/ColorSelector';
import {actionUpdateComponent} from '../../../../../display/components/action/actionUpdateComponent';
import {getComposeForSource} from '../../../../../display/components/common/composeComponent';
import {
  DEFAULT_DROP_SHADOW,
  getTextForSource,
  getTextFromSource
} from '../../../../../display/components/type/textComponent';

import './dropShadowColorSelect.scss';

/**
 * The DropShadowColorSelect component.
 *
 * @param {{}} props
 * @param {DisplayEditorStore} props.displayEditorStore
 * @param {ObservableMap} props.entity
 * @param {GameStore} props.game
 * @returns {React.Component}
 */
export const DropShadowColorSelect = (props) => {
  const {
    /** @type DisplayEditorStore} */ displayEditorStore,
    /** @type {ObservableMap} */ entity,
    /** @type {GameStore} */ game,
  } = props;

  /**
   * Updates the text entity when it is changed.
   *
   * @param {number} newColor
   */
  const onChange = (newColor) => {
    const actionParams = {
      entityId: entity.get('id'),
    };

    const composeSource = getComposeForSource(entity);
    const textSource = getTextForSource(entity);
    if (!textSource.text.dropShadow) {
      textSource.text.dropShadow = {...DEFAULT_DROP_SHADOW};
    }
    textSource.text.dropShadow.color = newColor;

    game.addAction(actionParams, actionUpdateComponent(
      getTextFromSource(
        {...textSource, ...composeSource},
        toJS(displayEditorStore.variables)
      )
    ));
  };

  const text = entity.get('text');
  const value = (text.dropShadow) ? text.dropShadow.color : '#000';

  return (
    <div className="drop-shadow-color-select">
      <ColorSelector
        id="drop-shadow-color-selector"
        colorValue={value}
        skipAlpha={true}
        onChangeColor={(color) => onChange(color)}
      />
    </div>
  );
};

DropShadowColorSelect.propTypes = {
  displayEditorStore: PropTypes.object.isRequired,
  entity: PropTypes.object.isRequired,
  game: PropTypes.object.isRequired,
};

export default DropShadowColorSelect;
