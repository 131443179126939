/* eslint-disable no-magic-numbers */
import React from 'react';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import {observable, action} from 'mobx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBell} from '@fortawesome/free-regular-svg-icons';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uuid from 'uuid/v4';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import ReactSelect from 'react-select';

import inject from '../../../hoc/injectHoc';
import './counterModal.scss';

const COUNTER_DIRECTION_OPTIONS = [
  {label: 'Countdown', value: 'down'},
  {label: 'Countup', value: 'up'}
];

/**
 * The CounterModal component.
 */
export class CounterModal extends React.Component {
  /**
   * List of selected time increments
   */
  @observable timeIncrements = {
    days: {
      key: 'days',
      label: 'Days',
      isSelected: true
    },
    hours: {
      key: 'hours',
      label: 'Hours',
      isSelected: false
    },
    minutes: {
      key: 'minutes',
      label: 'Minutes',
      isSelected: false
    },
  }

  /**
   * Whether or not the counter should be a countdown or a countup
   */
  @observable direction = COUNTER_DIRECTION_OPTIONS[0]

  /**
   * Selects or deselects a time increment
   *
   * @param {{key: string, label: string, isSelected: boolean}} timeIncrementSelected
   */
  @action toggleTimeIncrement = (timeIncrementSelected) => {
    const timeIncrement = this.timeIncrements[timeIncrementSelected.key];
    this.timeIncrements[timeIncrement.key] = {...timeIncrement, isSelected: !timeIncrement.isSelected};
  };

  /**
   * Sets the direction of the counter
   *
   * @param {{}} direction
   */
  @action setDirection = (direction) => {
    this.direction = direction;
  }

  /**
   * Creates the entities needed for the counter feed
   *
   * @param {{width: number, height: number}} resolution
   * @returns {*}
   */
  counterEntitiesByTimeIncrement = (resolution) => {
    const MAX_NUMBER_OF_ENTITIES = 4;
    const HALF_OF_ENTITY_WIDTH = 200;
    const X_POSITION = (resolution.width / MAX_NUMBER_OF_ENTITIES - HALF_OF_ENTITY_WIDTH);
    const Y_POSITION = 440;
    const counterUuid = uuid();
    const typePrefix = `Count${this.direction.value}`;
    const namePrefix = this.direction.value === 'up' ? 'CtUp' : 'CtDn';

    return {
      days: {
        name: `${namePrefix} DD ${counterUuid.slice(0, 5)}`,
        type: `${typePrefix}|Days|${counterUuid}`,
        entityType: 'feed',
        size: {width: 300, height: 150},
        position: {x: X_POSITION * 1, y: Y_POSITION},
        markdown: '{|}{$1.00}{%1}{100}{#ffffff}{poppinsbold}DD{!poppinsbold}{!#ffffff}{!100}{!%1}{!$1.00}{!|}'
      },
      hours: {
        name: `${namePrefix} HH ${counterUuid.slice(0, 5)}`,
        type: `${typePrefix}|Hours|${counterUuid}`,
        entityType: 'feed',
        size: {width: 300, height: 150},
        position: {x: X_POSITION * 2, y: Y_POSITION},
        markdown: '{|}{$1.00}{%1}{100}{#ffffff}{poppinsbold}HH{!poppinsbold}{!#ffffff}{!100}{!%1}{!$1.00}{!|}'
      },
      minutes: {
        name: `${namePrefix} MM ${counterUuid.slice(0, 5)}`,
        type: `${typePrefix}|Minutes|${counterUuid}`,
        entityType: 'feed',
        size: {width: 300, height: 150},
        position: {x: X_POSITION * 3, y: Y_POSITION},
        markdown: '{|}{$1.00}{%1}{100}{#ffffff}{poppinsbold}MM{!poppinsbold}{!#ffffff}{!100}{!%1}{!$1.00}{!|}'
      },
    };
  };

  submitCounterLayers = () => {
    const {onComplete, resolution} = this.props;
    const allCounterEntities = this.counterEntitiesByTimeIncrement(resolution);

    const validCounterEntities = [];
    Object.values(this.timeIncrements).forEach((timeIncrement) => {
      if (timeIncrement.isSelected) {
        validCounterEntities.push(allCounterEntities[timeIncrement.key]);
      }
    });

    onComplete({
      entities: validCounterEntities
    });
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {isOpen, onCancelModal} = this.props;

    return (
      <Modal
        isOpen={isOpen}
        toggle={onCancelModal}
        backdrop={true}
        className="counter-modal"
        centered
      >
        <ModalHeader toggle={onCancelModal}>Format Counter</ModalHeader>
        <ModalBody>
          <div className="m-2">
            <label className="ml-2">Direction:</label>
            <ReactSelect
              value={this.direction}
              options={COUNTER_DIRECTION_OPTIONS}
              onChange={this.setDirection}
            />
            <sub className="ml-2">{this.direction.value === 'down' ? 'ex. 10 days until anniversary' : 'ex. 10 days since last incident'}</sub>
          </div>
          <div className="modal-body-content-wrapper d-flex">
            {Object.values(this.timeIncrements).map((timeIncrement) => (<button
              key={timeIncrement.label}
              type="button"
              className={classNames('time-increment-button', {'is-selected': timeIncrement.isSelected})}
              onClick={() => this.toggleTimeIncrement(timeIncrement)}
            >{timeIncrement.label}</button>)
            )}
          </div>
          <div className="setup-info-box">
            <FontAwesomeIcon
              className="mx-1"
              icon={faBell}
            />
            Design your template here and set the date when you Schedule your content.
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.submitCounterLayers}>Start Designing</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

CounterModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancelModal: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  resolution: MobxPropTypes.observableObject.isRequired
};

CounterModal.wrappedComponent = {};
CounterModal.wrappedComponent.propTypes = {
  resolution: MobxPropTypes.observableObject.isRequired,
};

export default inject(CounterModal)(
  observer(CounterModal)
);
