/* eslint-disable camelcase */
import {Auth0Client} from '@auth0/auth0-spa-js';

import config from '../config/main';

const auth0 = new Auth0Client({
  domain: config.auth0.domain,
  clientId: config.auth0.clientId,
  useRefreshTokens: true,
  useRefreshTokensFallback: true,
  authorizationParams: {
    audience: config.auth0.audience,
    redirect_uri: config.auth0.redirectUri,
  },
  cookieDomain: config.auth0.cookieDomain,
});

export default auth0;
