import {action} from 'mobx';

import ApiMapBaseStore from '../common/apiMapBaseStore';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';

/**
 * ApiCollectionGetContentStore
 */
class ApiCollectionGetContentStore extends ApiMapBaseStore {
  /**
   * Fetches collection content from the server.
   *
   * @param {string} collectionId
   */
  @action makeRequest(collectionId) {
    const safeCollectionId = String(collectionId);

    this.dataMap.set(safeCollectionId, {
      state: STATE_PENDING,
      [EXPIRE_TIME]: Date.now() + EXPIRES_PENDING,
      data: null,
      error: null,
    });

    serverApi.collectionGetContent(safeCollectionId).then(
      action('collectionGetContentSuccess', (foundContent) => {
        const safeContent = (foundContent && Array.isArray(foundContent)) ? foundContent : [];

        this.dataMap.set(safeCollectionId, {
          state: STATE_FULFILLED,
          [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
          data: safeContent,
          error: null,
        });
      }),
      action('collectionGetContentError', (collectionGetContentError) => {
        this.dataMap.set(safeCollectionId, {
          state: STATE_REJECTED,
          [EXPIRE_TIME]: null,
          data: null,
          error: collectionGetContentError,
        });
      })
    );
  }
}

export default new ApiCollectionGetContentStore();
