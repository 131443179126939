import classNames from 'classnames';
import lodash from 'lodash';
import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Tooltip} from 'reactstrap';

import sliderHoc from './hoc/sliderHoc';
import {between} from '../../../utils/mathHelper';

import './singleSlider.scss';

/**
 * The pixel size for the handle radius.
 * This must match the $handle-radius variable in singleSlider.scss.
 *
 * @const {number}
 */
const HANDLE_RADIUS = 6;

/**
 * The SingleSlider component.
 */
export class SingleSlider extends React.Component {
  /**
   * Whether or not the tooltip is showing.
   *
   * @type {boolean}
   */
  @observable isTooltipOpen = false;

  /**
   * The element node.
   *
   * @type {{current: ?HTMLElement}}
   */
  domRef = React.createRef();

  /**
   * A reference to the handle element.
   *
   * @type {{current: ?HTMLElement}}
   */
  handleRef = React.createRef();

  /**
   * Toggles the tooltip open or closed.
   */
  @action onTooltipToggle = () => {
    this.isTooltipOpen = !this.isTooltipOpen;
  };

  /**
   * Calculates the x position for the handle.
   *
   * @param {number} xValue
   * @returns {number}
   */
  calculateLineX = (xValue) => {
    const {elongateHandle, minValue, maxValue} = this.props;
    const safeElongate = parseInt(elongateHandle, 10) || 0;

    const width = this.getSliderWidth() - safeElongate;
    const totalDelta = maxValue - minValue;
    const adjustedValue = xValue - minValue;
    const normalizedX = (adjustedValue / totalDelta) * width;

    return Math.round(normalizedX) || 0;
  };

  /**
   * Calculates the y position for the handle.
   * This is for when the slider is vertical.
   *
   * @param {number} yValue
   * @returns {number}
   */
  calculateLineY = (yValue) => {
    const {elongateHandle, minValue, maxValue} = this.props;
    const safeElongate = parseInt(elongateHandle, 10) || 0;

    const height = this.getSliderHeight() - safeElongate;
    const totalDelta = maxValue - minValue;
    const adjustedValue = yValue - minValue;
    const normalizedY = (adjustedValue / totalDelta) * height;

    return Math.round(normalizedY);
  };

  /**
   * Gets the height of the slider.
   *
   * @returns {number}
   */
  getSliderHeight = () => {
    const placeholderHeight = 100;
    return lodash.get(this.domRef, 'current.clientHeight', placeholderHeight);
  };

  /**
   * Gets the width of the slider.
   *
   * @returns {number}
   */
  getSliderWidth = () => {
    const placeholderWidth = 100;
    return lodash.get(this.domRef, 'current.clientWidth', placeholderWidth);
  };

  /**
   * Gets the current value.
   *
   * @returns {number}
   */
  getValue = () => {
    const {maxValue, minValue, value} = this.props;

    return between(value, minValue, maxValue);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {className, elongateHandle, onTrackClick, tooltip, vertical} = this.props;

    const safeElongate = parseInt(elongateHandle, 10) || 0;
    const safeValue = this.getValue();
    const handleStyles = {};

    if (vertical) {
      const handleY = this.calculateLineY(safeValue);

      handleStyles.top = (handleY - HANDLE_RADIUS);
      handleStyles.bottom = 'auto';
      handleStyles.height = (HANDLE_RADIUS * 2) + safeElongate;
    } else {
      const handleX = this.calculateLineX(safeValue);

      handleStyles.left = (handleX - HANDLE_RADIUS);
      handleStyles.right = 'auto';
      handleStyles.width = (HANDLE_RADIUS * 2) + safeElongate;
    }

    const extraClasses = {};
    if (className) {
      extraClasses[className] = true;
    }
    if (vertical) {
      extraClasses.vertical = true;
    }

    return (
      <div
        className={classNames('single-slider', extraClasses)}
        onMouseDown={onTrackClick}
        ref={this.domRef}
      >
        <div className="single-slider-track" />
        <div className="single-slider-handle" style={handleStyles} ref={this.handleRef} />

        {(tooltip && this.handleRef.current) && (
          <Tooltip
            placement="left"
            isOpen={this.isTooltipOpen}
            target={this.handleRef.current}
            toggle={this.onTooltipToggle}
          >{tooltip}</Tooltip>
        )}
      </div>
    );
  }
}

SingleSlider.propTypes = {
  maxValue: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,

  className: PropTypes.string,
  elongateHandle: PropTypes.number,
  onTrackClick: PropTypes.func, // Injected by the sliderHOC.
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tooltip: PropTypes.string,
  vertical: PropTypes.bool,
};

SingleSlider.defaultProps = {
  elongateHandle: 0,
  step: 1,
  vertical: false,
};

export default sliderHoc(
  observer(SingleSlider)
);
