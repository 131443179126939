/* eslint-disable no-magic-numbers */
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Modal, ModalBody, ModalHeader, ModalFooter, InputGroup, InputGroupText, Input, InputGroupAddon, Alert} from 'reactstrap';
import {observable, action, runInAction} from 'mobx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleNotch, faSearch} from '@fortawesome/free-solid-svg-icons';
import _debounce from 'lodash/debounce';
import _throttle from 'lodash/throttle';
import memoizeOne from 'memoize-one';

import inject from '../../hoc/injectHoc';
import CustomIcon from '../../../components/common/customIcon/CustomIcon';
import {STATE_FULFILLED, STATE_PENDING, STATE_REJECTED} from '../../../constants/asyncConstants';
import {SCREEN_SIZE_LARGE, SCREEN_SIZE_SMALL} from '../../../constants/bootstrapConstants';
import config from '../../../config/main';
import PreviewImageModal from '../previewImageModal/PreviewImageModal';

import './unsplashImageModal.scss';

const DEBOUNCE_SEARCH_TIME_IN_MILLISECONDS = 500;

const generateImageAuthorAttributionUrl = (authorUrl) =>
  `${authorUrl}?utm_source=${config.unsplash.appName}&utm_medium=referral`;

const generateUnsplashAttributionUrl = () =>
  `https://unsplash.com/?utm_source=${config.unsplash.appName}&utm_medium=referral`;

const memoizeUnflattenArray = memoizeOne(
  (array, columnCount = 3) => {
    const initialColumns = Array.apply(null, Array(columnCount)).map(() => ([]));

    return array.filter((item) => (
      typeof item.urls !== 'undefined'
      && typeof item.urls.thumb !== 'undefined'
      && typeof item.urls.regular !== 'undefined'
    )).reduce((acc, item, index) => {
      const mod = index % columnCount;
      acc[mod].push(item);

      return acc;
    }, initialColumns);
  }
);

/**
 * The UnsplashImageModal component.
 */
class UnsplashImageModal extends React.Component {
  /**
   * The search string
   *
   * @type {string}
   */
  @observable searchValue = '';

  /**
   * Image results
   *
   * @type {Array}
   */
  @observable images = [];

  /**
   * Page of paginated results
   *
   * @type {number}
   */
  @observable page = 1;

  /**
   * Total pages available for current search value
   *
   * @type {number}
   */
  @observable totalPages = 1;

  /**
   * Amount of image columns to show
   *
   * @type {number}
   */
  @observable columnCount = 3;

  /**
   * image to preview
   *
   * @type {object|null}
   */
  @observable selectedImage = null;

  /**
   * Debounced search handler
   */
  debouncedOnSearch = _debounce(action('debouncedOnSearch', async (searchValue) => {
    // reset to page 1 for new search value
    this.page = 1;
    this.searchUnsplash(searchValue, this.page);
  }), DEBOUNCE_SEARCH_TIME_IN_MILLISECONDS);

  /**
   * Check window size
   */
  checkWindowWidth = _throttle(() => {
    runInAction('checkWindowSizeUnsplashImageModal', () => {
      const windowWidth = window.innerWidth;

      // used to match up render with bootstrap columns. if this becomes
      // un-performant. maybe switch to always be 4 columns and classes "col col-12 col-sm-6 col-lg-3"
      // so we can remove this resize check
      this.columnCount = windowWidth >= SCREEN_SIZE_SMALL && windowWidth < SCREEN_SIZE_LARGE
        ? 2
        : 3;
    });
  }, 500);

  /**
   * Triggered when component mounts
   */
  @action componentDidMount() {
    this.checkWindowWidth();
    window.addEventListener('resize', this.checkWindowWidth);
  }

  /**
   * Triggered when the component updates
   *
   * @param {{}} prevProps
   */
  @action componentDidUpdate(prevProps) {
    if (this.props.isOpen === true && prevProps.isOpen === false && this.images.length === 0) {
      this.searchValue = '';
      this.page = 1;
      this.searchUnsplash(this.searchValue, this.page);
    }
  }

  /**
   * Clean up window events to avoid memory leaks
   */
  @action componentWillUnmount() {
    window.removeEventListener('resize', this.checkWindowWidth);
  }

  /**
   * Event handler when search input changes
   *
   * @param {{}} changeEvent
   */
  @action onSearch = (changeEvent) => {
    this.searchValue = changeEvent.target.value;
    this.debouncedOnSearch(changeEvent.target.value);
  }

  /**
   * Event handler when user clicks "Load more" button
   */
  @action onClickLoadMore = () => {
    this.page += 1;
    this.searchUnsplash(this.searchValue, this.page);
  }

  /**
   * Make search request
   *
   * @param {string} searchValue
   * @param {number} page
   */
  @action searchUnsplash = async (searchValue, page) => {
    const {apiUnsplashPhotoSearchStore} = this.props;

    apiUnsplashPhotoSearchStore.makeRequest(searchValue, page);
    try {
      const res = await apiUnsplashPhotoSearchStore.getPromise();

      runInAction('searchUnsplashSuccess', () => {
        this.totalPages = res.total_pages;
        this.images = page === 1
          ? res.results
          : [...this.images, ...res.results];
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error searching unsplash', error);
    }
  }

  /**
   * On preview image click
   *
   * @param {object} image
   */
  @action onImagePreviewClick = (image) => {
    this.selectedImage = image;
  }

  /**
   * On hide preview modal
   */
  @action onHidePreviewImageModal = () => {
    this.selectedImage = null;
  }

  /**
   * On use image from preview modal
   *
   * @param {string} url
   */
  @action onUseImage = () => {
    const {apiUnsplashPhotoTrackStore, onImageSelect} = this.props;
    if (
      this.selectedImage
      && this.selectedImage.links
      && this.selectedImage.links.download_location
    ) {
      apiUnsplashPhotoTrackStore.makeRequest(this.selectedImage.links.download_location);
    }

    onImageSelect(this.selectedImage);
    this.onHidePreviewImageModal();
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {isOpen, onCancel, apiUnsplashPhotoSearchStore} = this.props;

    const sortedImages = memoizeUnflattenArray(this.images, this.columnCount);

    return (
      <Modal
        className="unsplash-image-modal"
        isOpen={isOpen}
        backdrop={true}
        toggle={onCancel}
        size="xl"
      >
        <ModalHeader
          className="text-dark-blue"
          toggle={onCancel}
        >
          <CustomIcon
            type="unsplash"
            style={{
              width: '32px',
              height: '32px',
            }}
            className="mr-3"
          />
          Search Unsplash for Images
        </ModalHeader>
        <ModalBody className="py-3">
          <InputGroup size="sm">
            <InputGroupAddon addonType="prepend">
              <InputGroupText className="bg-transparent">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Search unsplash images"
              onChange={this.onSearch}
              value={this.searchValue}
            />
            {(apiUnsplashPhotoSearchStore.state === STATE_PENDING) && (
              <InputGroupAddon addonType="append">
                <InputGroupText className="bg-transparent">
                  <FontAwesomeIcon
                    icon={faCircleNotch}
                    spin
                  />
                </InputGroupText>
              </InputGroupAddon>
            )}
          </InputGroup>

          {(apiUnsplashPhotoSearchStore.state === STATE_REJECTED) && (
            <Alert
              className="mb-0 mt-3"
              color="danger"
            >
              There was an error searching for images. Please try again.
            </Alert>
          )}

          {(this.images.length === 0 && apiUnsplashPhotoSearchStore.state === STATE_FULFILLED) && (
            <p className="my-3">
              No results found for given query. Try a different search.
            </p>
          )}

          {(this.images.length === 0 && apiUnsplashPhotoSearchStore.state === STATE_PENDING) && (
            <div className="d-flex justify-content-center p-4">
              <FontAwesomeIcon
                icon={faCircleNotch}
                spin
                style={{
                  width: '32px',
                  height: '32px',
                }}
              />
            </div>
          )}

          {(this.images.length > 0 ) && (
            <div className="row mt-3 image-row">
              {(sortedImages.map((columnImages, columnIndex) => (
                <div
                  className="col col-12 col-sm-6 col-lg-4"
                  // eslint-disable-next-line react/no-array-index-key
                  key={columnIndex}
                >
                  {(columnImages.map((image, imageIndex) => (
                    <div
                      className="image-container mb-3 cursor-zoom-in"

                      // need imageIndex because unsplash /photos route does
                      // not always return unique results
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${image.id}-${imageIndex}`}
                      onClick={() => this.onImagePreviewClick(image)}
                    >
                      <img
                        style={{width: '100%'}}
                        src={image.urls.thumb}
                        alt={image.alt_description}
                        title={image.alt_description}
                      />
                      <div className="image-data">
                        {(image.user) && (
                          <div className="image-attribution p-2">
                            {(image.user.profile_image && image.user.profile_image.medium) && (
                              <img
                                className="image-attribution-image mr-2 d-block d-sm-none d-md-block"
                                src={image.user.profile_image.medium}
                              />
                            )}
                            <div className="image-attribution-text">
                              Photo by&nbsp;
                              {(image.user.links && image.user.links.html)
                                ? (<a
                                  className="text-decoration-underline text-white"
                                  href={generateImageAuthorAttributionUrl(image.user.links.html)}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  onClick={(clickEvent) => clickEvent.stopPropagation()}
                                >
                                  {image.user.name || '-'}
                                </a>
                                )
                                : <>{image.user.name || '-'}</>
                              }
                              <br />
                              <span className="small">
                                on&nbsp;
                                <a
                                  className="text-decoration-underline text-white"
                                  href={generateUnsplashAttributionUrl()}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  onClick={(clickEvent) => clickEvent.stopPropagation()}
                                >
                                  Unsplash
                                </a>
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )))}
                </div>
              )))}
            </div>
          )}

          {(this.images.length > 0 && this.page < this.totalPages) && (
            <div className="mt-3">
              <button
                className="btn btn-sm btn-outline-dark w-100"
                type="button"
                onClick={this.onClickLoadMore}
                disabled={apiUnsplashPhotoSearchStore.state === STATE_PENDING}
              >
                Load more&nbsp;
                {(apiUnsplashPhotoSearchStore.state === STATE_PENDING) && (
                  <FontAwesomeIcon
                    icon={faCircleNotch}
                    spin
                  />
                )}
              </button>
            </div>
          )}

          {(this.selectedImage) && (
            <PreviewImageModal
              isOpen={true}
              onCancel={this.onHidePreviewImageModal}
              onSubmitClick={this.onUseImage}
              url={this.selectedImage.urls.regular}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-light"
            type="button"
            onClick={onCancel}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

UnsplashImageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onImageSelect: PropTypes.func.isRequired,
  apiUnsplashPhotoSearchStore: MobxPropTypes.observableObject,
  apiUnsplashPhotoTrackStore: MobxPropTypes.observableObject,
};

UnsplashImageModal.wrappedComponent = {};
UnsplashImageModal.wrappedComponent.propTypes = {
  apiUnsplashPhotoSearchStore: MobxPropTypes.observableObject,
  apiUnsplashPhotoTrackStore: MobxPropTypes.observableObject,
};

export default inject(UnsplashImageModal)(
  observer(UnsplashImageModal)
);
