import {action} from 'mobx';

import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';
import ApiMapBaseStore from '../common/apiMapBaseStore';

/**
 * ApiContentSearchStore
 */
class ApiContentSearchStore extends ApiMapBaseStore {
  /**
   * Builds the data map key for search value and global state.
   *
   * @param {string} searchValue
   * @param {boolean} globalOnly
   * @param {boolean} simple
   * @returns {string}
   */
  buildKey(searchValue, globalOnly, simple) {
    const globalPost = (globalOnly) ? '-1' : '-0';
    const simplePost = (simple) ? '-1' : '-0';

    return String(searchValue) + globalPost + simplePost;
  }

  /**
   * Ensures fresh content in the store.
   *
   * @param {string} searchValue
   * @param {boolean} globalOnly
   * @param {boolean} simple
   * @param {boolean} force Whether or not to force the refresh
   */
  refresh(searchValue, globalOnly, simple, force) {
    const safeKey = this.buildKey(searchValue, globalOnly, simple);

    if (force || !this.isItemAvailable(safeKey)) {
      this.clear(safeKey);

      this.makeRequest(searchValue, globalOnly, simple);
    }
  }

  /**
   * Fetches content info from the server based on a search term.
   *
   * @param {string} searchValue
   * @param {boolean} globalOnly
   * @param {boolean} simple
   */
  @action makeRequest(searchValue, globalOnly, simple) {
    const safeKey = this.buildKey(searchValue, globalOnly, simple);

    this.dataMap.set(safeKey, {
      state: STATE_PENDING,
      [EXPIRE_TIME]: Date.now() + EXPIRES_PENDING,
      data: null,
      error: null,
    });

    serverApi.contentSearch(
      String(searchValue),
      Boolean(globalOnly),
      Boolean(simple)
    ).then(action('contentSearchSuccess', (foundContent) => {
      this.dataMap.set(safeKey, {
        state: STATE_FULFILLED,
        [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
        data: foundContent,
        error: null,
      });
    })).catch(action('contentSearchError', (fetchError) => {
      this.dataMap.set(safeKey, {
        state: STATE_REJECTED,
        [EXPIRE_TIME]: null,
        data: null,
        error: fetchError,
      });
    }));
  }
}

export default new ApiContentSearchStore();
