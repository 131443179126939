import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import DropShadowSizeSelect from '../dropShadowSizeSelect/DropShadowSizeSelect';
import DropShadowBlurSelect from '../dropShadowBlurSelect/DropShadowBlurSelect';
import DropShadowColorSelect from '../dropShadowColorSelect/DropShadowColorSelect';
import OpacitySelect from '../opacitySelect/OpacitySelect';

import './advancedImageControls.scss';

/**
 * The AdvancedImageControls component.
 */
export class AdvancedImageControls extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      /** @type {DisplayEditorStore} */ displayEditorStore,
      /** @type {ObservableMap} */ entity,
      /** @type {GameStore} */ game,
    } = this.props;

    if (game.composeMode) {
      // Compose mode disables editing these values.
      return null;
    }

    return (
      <div className="advanced-image-controls control-group">
        <div className="group-header">
          <span className="group-header-label">Drop Shadow</span>
        </div>

        <div className="group-controls">
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="stroke-selector-input">X</label>
                <DropShadowSizeSelect
                  coordinate="x"
                  displayEditorStore={displayEditorStore}
                  entity={entity}
                  game={game}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="stroke-color-selector">Y</label>
                <DropShadowSizeSelect
                  coordinate="y"
                  displayEditorStore={displayEditorStore}
                  entity={entity}
                  game={game}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="stroke-selector-input">Blur</label>
                <DropShadowBlurSelect
                  displayEditorStore={displayEditorStore}
                  entity={entity}
                  game={game}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="stroke-color-selector">Color</label>
                <DropShadowColorSelect
                  displayEditorStore={displayEditorStore}
                  entity={entity}
                  game={game}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="group-header">
          <span className="group-header-label">Opacity</span>
        </div>

        <div className="group-controls">
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="stroke-selector-input">Opacity</label>
                <OpacitySelect
                  displayEditorStore={displayEditorStore}
                  game={game}
                  entity={entity}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AdvancedImageControls.propTypes = {
  displayEditorStore: PropTypes.object.isRequired,
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(AdvancedImageControls);
