import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import moment from 'moment';
import React, {Component} from 'react';

import {MOMENT_LONG_DATE_FORMAT} from '../../../constants/uiConstants';
import Link from '../../common/link/Link';
import SmartTable from '../../common/smartTable/SmartTable';
import inject from '../../hoc/injectHoc';
import {adminContentRequestRoute, dashboardRoute} from '../../routePaths';

import './adminContentRequestsListPage.scss';

/**
 * The AdminContentRequestListPage component.
 */
export class AdminContentRequestsListPage extends Component {
  /**
   * Triggered when the component first mounts to the page.
   */
  componentDidMount() {
    const {apiAdminContentRequestGetAllStore} = this.props;

    apiAdminContentRequestGetAllStore.refresh();
  }

  /**
   * Renders the table when items are loading.
   *
   * @param {Array<{}>} columns
   * @returns {{}}
   */
  renderLoadingTable = (columns) => {
    return (
      <SmartTable
        className="table-dark table-striped table-hover"
        columns={columns}
        rows={[]}
        loading={true}
      />
    );
  };

  /**
   * Parses the content requests into the table rows.
   *
   * @param {Array<{}>} requests
   * @returns {Array<{}>}
   */
  parseRequestsToRow = (requests) => {
    return requests.map((request) => {
      const user = request.user
        ? `${request.user.fullName} (${request.user.email})`
        : '';

      return {
        id: request.id,
        name: (
          <Link
            className="btn btn-sm btn-primary"
            to={adminContentRequestRoute}
            params={{requestId: request.id}}
          >{request.id}</Link>
        ),
        user,
        purpose: request.purpose,
        emails: (request.emails) ? request.emails.join(', ') : '',
        dateSubmitted: moment(request.createdAt).format(MOMENT_LONG_DATE_FORMAT),
      };
    });
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {apiAdminContentRequestGetAllStore} = this.props;

    const columns = [
      {key: 'name', label: 'REQUEST ID'},
      {key: 'user', label: 'USER'},
      {key: 'purpose', label: 'PURPOSE'},
      {key: 'emails', label: 'EMAILS'},
      {key: 'dateSubmitted', label: 'DATE SUBMITTED'},
    ];

    return (
      <div id="admin-content-request-list-page" className="system-page full-height">
        <div className="container">
          <Link to={dashboardRoute}>
            <h4 className="h6">← Back to Dashboard</h4>
          </Link>
          {apiAdminContentRequestGetAllStore.case({
            pre: () => this.renderLoadingTable(columns),
            pending: () => this.renderLoadingTable(columns),
            rejected: () => (
              <div className="alert alert-warning">
                The list of content requests could not be loaded.
              </div>
            ),
            fulfilled: (requests) => (
              <SmartTable
                className="table-dark table-striped table-hover"
                columns={columns}
                rows={this.parseRequestsToRow(requests)}
              />
            ),
          })}
        </div>
      </div>
    );
  }
}

AdminContentRequestsListPage.propTypes = {
  apiAdminContentRequestGetAllStore: MobxPropTypes.observableObject,
  routerStore: MobxPropTypes.observableObject,
};

AdminContentRequestsListPage.wrappedComponent = {};
AdminContentRequestsListPage.wrappedComponent.propTypes = {
  apiAdminContentRequestGetAllStore: MobxPropTypes.observableObject.isRequired,
  routerStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(AdminContentRequestsListPage)(
  observer(AdminContentRequestsListPage)
);
