import lodash from 'lodash';

/**
 * The default line color.
 * @const {string}
 */
const DEFAULT_COLOR = '#000000';

/**
 * The default line opacity.
 * @const {number}
 */
const DEFAULT_OPACITY = 1;

/**
 * The default line thickness.
 * @const {number}
 */
const DEFAULT_THICKNESS = 3;

/**
 * The line component.
 *
 * @param {{x: number, y: number}} startPoint
 * @param {{x: number, y: number}} endPoint
 * @param {number} thickness
 * @param {{color: string, opacity: number}=} style
 * @returns {{line: {}}}
 */
export function lineComponent(startPoint, endPoint, thickness, style) {
  return {
    line: {
      startPoint,
      endPoint,
      thickness,
      style
    },
  };
}

/**
 * Gets the line component from the source item.
 *
 * @param {{line: {startPoint: {}, endPoint: {}, thickness: number, style: {}}}} item
 * @param {{}=} variables
 * @returns {{line: {startPoint: {}, endPoint: {}, thickness: number, style: {}}}}
 */
export function getLineFromSource(item, variables) {
  if (!item.line) {
    return {};
  }

  const line = lodash.defaultsDeep({}, item.line, {
    style: {
      color: DEFAULT_COLOR,
      opacity: DEFAULT_OPACITY,
    },
    thickness: DEFAULT_THICKNESS,
  });

  const canEdit = lodash.get(item, 'compose.canEdit');
  const variableName = lodash.get(item, 'compose.variableName');
  if (canEdit && variableName) {
    const variableValue = lodash.get(variables, `line.${variableName}`, '');
    if (variableValue && lodash.isString(variableValue)) {
      // The old deprecated variable style is a string with | delimiters.
      const partsCount = 2;
      const [styleColor, styleOpacity] = lodash.split(variableValue, '|', partsCount);

      line.style.color = styleColor;
      line.style.opacity = styleOpacity;
    } else if (variableValue) {
      line.style.color = variableValue.styleColor;
      line.style.opacity = variableValue.styleOpacity;
    }
  }

  return lineComponent(
    line.startPoint,
    line.endPoint,
    line.thickness,
    line.style,
  );
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {{}} entity
 * @returns {{}}
 */
export function getLineForSource(entity) {
  if (!entity.has('line')) {
    return {};
  }

  const compose = entity.get('compose');
  const line = entity.get('line');

  const canEdit = lodash.get(compose, 'canEdit', false);
  const variableName = lodash.get(compose, 'variableName');

  const outLine = {
    startPoint: line.startPoint,
    endPoint: line.endPoint,
    thickness: line.thickness,
    style: line.style,
  };

  if (canEdit && variableName) {
    lodash.unset(outLine, 'style');
  }

  return {
    line: lodash.cloneDeep(outLine),
  };
}
