import lodash from 'lodash';

import {EASINGS} from '../../ecs/easingHelper';
import flyInTopGif from '../../../assets/images/transitions/flyInTop.gif';

/**
 * The name that signals this preset.
 * @const {string}
 */
export const PRESET_EASE_IN_EDGE_TOP = 'easeInEdgeTop';

/**
 * The preset details.
 *
 * @type {{
 *   name: string,
 *   humanName: string,
 *   flow: string,
 *   details: Object.<string, {
 *     easing: string,
 *     parse: boolean,
 *     values: *|Array.<*>,
 *     time: {start: (string|number), end: (string|number)}
 *   }>,
 *   template: {}
 * }}
 */
export const easeInEdgeTopPresetDetails = {
  // The name of the preset.
  name: PRESET_EASE_IN_EDGE_TOP,

  // A human friendly name for the preset.
  humanName: 'Ease In From Edge Top',

  // A preview of the transition.
  gif: flyInTopGif,

  // The element names that this preset can be used for or true for all elements.
  elements: true,

  // The preset direction: in, out, middle. This is used in the UI logic.
  flow: 'in',

  // Each property that will change during the transition.
  details: {
    'position.y': {
      time: {
        start: 'start.0',
        end: 'start.1000'
      },
      easing: EASINGS.easeOutPower3,
      values: [
        'off.top',
        'position.top'
      ],
      immediateRender: true
    },
  },

  // How this preset can be used in the source (including optional parameters).
  // This will be turned into a JSON string, so no functions can be used.
  template: {
    preset: PRESET_EASE_IN_EDGE_TOP,
    options: {},
  },
};

easeInEdgeTopPresetDetails.template.options = lodash.cloneDeep(easeInEdgeTopPresetDetails.details);

// This defines the key in the presets object.
export const presetName = PRESET_EASE_IN_EDGE_TOP;

// This defines the value of the presets object.
export default easeInEdgeTopPresetDetails;
