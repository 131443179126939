import lodash from 'lodash';
import {action, runInAction} from 'mobx';

import apiContentFolderGetContentStore from './apiContentFolderGetContentStore';
import ApiMapBaseStore from '../common/apiMapBaseStore';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';

/**
 * The amount of time between polling calls.
 * @const {number[]}
 */
const POLLING_INTERVALS = [
  // eslint-disable-next-line no-magic-numbers
  30000 // 30 seconds
];

/**
 * The number of errors that will cancel a poll.
 * @const {number}
 */
const CANCEL_ERROR_COUNT = 4;

/**
 * The maximum number of times polling will occur.
 * @const {number}
 */
const MAX_POLL_COUNT = 180;

/**
 * Polls for content render status updates from the api.
 */
class ApiContentFolderPollContentStore extends ApiMapBaseStore {
  /**
   * The map of ids that can be used to cancel the active polling.
   *
   * @type {Object.<number, number>}
   */
  cancelTimeoutIds = {};

  /**
   * Whether or not the given any content folder is processing.
   *
   * @param {{status: string, displayStatus: string}[]} contentFolder
   * @returns {boolean}
   */
  isAnyContentProcessing = (contentFolder) => {
    if (!contentFolder) {
      return false;
    }

    const anyContentIsProcessing = contentFolder.find((content) => {
      const contentStatus = content.status || content.displayStatus;

      return (contentStatus === 'processing' || contentStatus === 'pending');
    });

    return typeof anyContentIsProcessing !== 'undefined';
  };

  /**
   * Clears all the polling.
   */
  @action clearAll() {
    lodash.forEach(this.cancelTimeoutIds, (timeoutId) => {
      clearTimeout(timeoutId);
    });

    this.dataMap.clear();
  }

  /**
   * Cancels any active polling for the given content folder id.
   *
   * @param {number} contentFolderId
   */
  cancelPollForContentFolder(contentFolderId) {
    const safeContentFolderId = String(contentFolderId);

    const timeoutId = this.cancelTimeoutIds[safeContentFolderId];
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    this.dataMap.delete(safeContentFolderId);
  }

  /**
   * Gets the next poll interval.
   *
   * @param {number} currentCount
   * @returns {number}
   */
  getNextPollInterval = (currentCount) => {
    const safeCount = Number(currentCount);

    if (!currentCount || !safeCount) {
      return POLLING_INTERVALS[0];
    } else if (POLLING_INTERVALS[safeCount]) {
      return POLLING_INTERVALS[safeCount];
    }

    return lodash.last(POLLING_INTERVALS);
  };

  /**
   * Checks if content is already being polled.
   *
   * @param {number} contentFolderId
   * @returns {boolean}
   */
  isPollingInProgress(contentFolderId) {
    const safeContentFolderId = String(contentFolderId);

    const currentResource = this.dataMap.get(safeContentFolderId);
    return (typeof currentResource !== 'undefined' && currentResource.state === STATE_PENDING);
  }

  /**
   * Polls content from the server until the enters a good content state.
   *
   * @param {number} contentFolderId
   */
  @action pollContentFolderById(contentFolderId) {
    const safeContentFolderId = String(contentFolderId);

    if (this.isPollingInProgress(safeContentFolderId)) {
      return;
    }

    this.dataMap.set(safeContentFolderId, {
      state: STATE_PENDING,
      count: 0,
      data: null,
      errorCount: 0,
      error: null,
      successError: false,
    });

    this.cancelTimeoutIds[safeContentFolderId] = setTimeout(() => {
      this.pollingLoop(safeContentFolderId);
    }, this.getNextPollInterval(0));
  }

  /**
   * Starts or continues a polling loop.
   * Should not be called directly.
   *
   * @param {number} contentFolderId
   */
  @action pollingLoop(contentFolderId) {
    const safeContentFolderId = String(contentFolderId);

    // TODO make progress on page nicer?
    apiContentFolderGetContentStore.refresh(safeContentFolderId, true);
    apiContentFolderGetContentStore.getPromise(safeContentFolderId).then((foundContentFolder) => {
      const safeContentFolder = (foundContentFolder && Array.isArray(foundContentFolder)) ? foundContentFolder : [];

      const currentPoll = this.dataMap.get(safeContentFolderId);
      const newCount = currentPoll.count + 1;

      const isAnyPending = this.isAnyContentProcessing(safeContentFolder);
      if (!isAnyPending) {
        delete this.cancelTimeoutIds[safeContentFolderId];

        this.endPollingSuccess(safeContentFolderId, safeContentFolder, newCount);
        return;
      }

      if (newCount >= MAX_POLL_COUNT) {
        delete this.cancelTimeoutIds[safeContentFolderId];

        this.dataMap.set(safeContentFolderId, {
          ...currentPoll,
          state: STATE_REJECTED,
          count: newCount,
          error: new Error('Polling max count reached.'),
        });
        return;
      }

      this.dataMap.set(safeContentFolderId, {
        ...currentPoll,
        count: newCount,
      });

      this.cancelTimeoutIds[safeContentFolderId] = setTimeout(() => {
        this.pollingLoop(safeContentFolderId);
      }, this.getNextPollInterval(newCount));
    }).catch((error) => {
      const currentPoll = this.dataMap.get(safeContentFolderId);
      const newCount = currentPoll.errorCount + 1;

      if (newCount >= CANCEL_ERROR_COUNT) {
        delete this.cancelTimeoutIds[safeContentFolderId];

        this.dataMap.set(safeContentFolderId, {
          ...currentPoll,
          state: STATE_REJECTED,
          errorCount: newCount,
          error,
        });

        return;
      }

      this.dataMap.set(safeContentFolderId, {
        ...currentPoll,
        state: STATE_REJECTED,
        errorCount: newCount,
        error,
      });

      this.cancelTimeoutIds[safeContentFolderId] = setTimeout(() => {
        this.pollingLoop(safeContentFolderId);
      }, this.getNextPollInterval(currentPoll.count));
    });
  }

  /**
   * Finishes a polling as a success.
   *
   * @param {number} contentFolderId
   * @param {{id: number, status: string, displayStatus: string}[]} contentFolder
   * @param {number} newCount
   */
  @action endPollingSuccess(contentFolderId, contentFolder, newCount) {
    const safeContentFolderId = String(contentFolderId);
    const currentPoll = this.dataMap.get(safeContentFolderId);

    runInAction('getContentSuccess', () => {
      this.dataMap.set(contentFolderId, {
        ...currentPoll,
        state: STATE_FULFILLED,
        count: newCount,
        data: contentFolder,
        error: null,
      });
    });
  }

  /**
   * Polls content and updates it in the other stores when it is finished processing.
   *
   * @param {number} contentFolderId
   */
  pollThenUpdateContent(contentFolderId) {
    const safeContentFolderId = String(contentFolderId);

    this.pollContentFolderById(safeContentFolderId);
  }
}

export default new ApiContentFolderPollContentStore();
