import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader, Alert} from 'reactstrap';
import {observable, computed, action} from 'mobx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleNotch} from '@fortawesome/free-solid-svg-icons';

import inject from '../../hoc/injectHoc';
import {STATE_PENDING} from '../../../constants/asyncConstants';
import {GENERATE_TEXT_CONTENT_TYPES} from './options';

import './generateTextModal.scss';

/**
 * The GenerateTextModal component.
 */
class GenerateTextModal extends React.Component {
  /**
   * form data
   *
   * @type {Object}
   */
  @observable form = {
    subject: '',
    contentType: GENERATE_TEXT_CONTENT_TYPES[0],
  };

  /**
   * Tracks if the form is disabled
   *
   * @returns {boolean}
   */
  @computed get isSubmitDisabled() {
    const {apiGenerateTextStore} = this.props;

    return Boolean(this.form.subject === '' || apiGenerateTextStore.getState() === STATE_PENDING);
  }

  /**
   * Tracks if the cancel button should be disabled
   *
   * @returns {boolean}
   */
  @computed get isCancelDisabled() {
    const {apiGenerateTextStore} = this.props;

    return Boolean(apiGenerateTextStore.getState() === STATE_PENDING);
  }

  /**
   * input & select onChange handler
   *
   * @param {{}} changeEvent
   */
  @action onChange = (changeEvent) => {
    const target = changeEvent.target;
    const value = target.value;

    if (typeof this.form[target.name] !== 'undefined') {
      this.form[target.name] = value;
    }
  };

  /**
   * Submit form event
   *
   * @param {{}} submitEvent
   */
  @action onSubmit = (submitEvent) => {
    const {apiGenerateTextStore} = this.props;

    submitEvent.preventDefault();

    apiGenerateTextStore.makeRequest(this.form.subject, this.form.contentType);
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {isOpen, onCancel, onResultClick, apiGenerateTextStore} = this.props;

    return (
      <Modal
        className="generate-text-modal"
        isOpen={isOpen}
        backdrop={true}
        toggle={onCancel}
      >
        <ModalHeader className="text-dark-blue">
          Generate content for your sign
        </ModalHeader>
        <ModalBody>
          <p className="mb-4">
            Tell us what you want to say today
          </p>
          <h5 className="mb-3">Copy Generation</h5>
          <form
            id="generate-text-form"
            onSubmit={this.onSubmit}
          >
            <div className="form-group">
              <label htmlFor="generate-text-subject">Subject</label>
              <input
                id="generate-text-subject"
                name="subject"
                className="form-control form-control-sm"
                aria-describedby="generate-text-subject-help"
                value={this.form.subject}
                onChange={this.onChange}
              />
              <small
                id="generate-text-subject-help"
                className="form-text text-muted"
              >
                Enter a topic or theme to serve as the foundation for creating your personalized advertising content.
              </small>
            </div>

            <div className="form-group">
              <label htmlFor="generate-text-content-type">Message Type</label>
              <select
                id="generate-text-content-type"
                name="contentType"
                className="form-control form-control-sm"
                value={this.form.contentType}
                onChange={this.onChange}
              >
                {GENERATE_TEXT_CONTENT_TYPES.map((value) => (
                  <option
                    key={value}
                    value={value}
                  >
                    {value}
                  </option>
                ))}
              </select>
            </div>
          </form>

          {apiGenerateTextStore.case({
            fulfilled: (data) => {
              const results = data && data.result && data.result.copy
                ? data.result.copy
                : [];
              return (<>
                <hr />
                <div>
                  <h6 className="d-inline mr-2">Results</h6>
                  <small>(Click option to use in text layer)</small>
                </div>
                {(results.length !== 0)
                  ? (
                    <ul className="prompt-results-list">
                      {results.map((value) => (
                        <li
                          className="mb-2 position-relative"
                          key={value}
                        >
                          <button
                            className="btn btn-outline-secondary btn-sm prompt-results-button"
                            type="button"
                            onClick={() => onResultClick(value)}
                          >
                            {value}
                          </button>
                        </li>
                      ))}
                    </ul>
                  )
                  : (
                    <span className="text-muted">No results found. Please try different parameters.</span>
                  )}
              </>
              );
            },
            rejected: () => (
              <Alert
                className="mb-0"
                color="danger"
              >
                There was an error generating the text. Please try again.
              </Alert>
            ),
          })}
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-light"
            type="button"
            onClick={onCancel}
            disabled={this.isCancelDisabled}
          >
            Cancel
          </button>
          <button
            className="btn btn-dark-blue"
            type="submit"
            form="generate-text-form"
            disabled={this.isSubmitDisabled}
          >
            {(apiGenerateTextStore.getState() === STATE_PENDING)
              ? (<>
                <FontAwesomeIcon
                  className="mr-2"
                  spin
                  icon={faCircleNotch}
                />
                Generating
              </>)
              : (<>Generate</>)
            }
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

GenerateTextModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onResultClick: PropTypes.func.isRequired,
  apiGenerateTextStore: MobxPropTypes.observableObject,
};

GenerateTextModal.wrappedComponent = {};
GenerateTextModal.wrappedComponent.propTypes = {
  apiGenerateTextStore: MobxPropTypes.observableObject,
};

export default inject(GenerateTextModal)(
  observer(GenerateTextModal)
);
