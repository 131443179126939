import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React, {Fragment} from 'react';

import DurationControls from '../duration/DurationControls';
import EntityTimeline from '../entityTimeline/EntityTimeline';
import EditorEntityAddButton from '../../sidebars/editorEntity/components/entityAddButton/EditorEntityAddButton';
import PlayerControls from '../player/PlayerControls';
import TimeControl from '../time/TimeControl';
import {timeFormat} from '../../../utils/timeFormat';

import './playbackControls.scss';

/**
 * The PlaybackControls component.
 */
export class PlaybackControls extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {allowDurationChange, game, timer} = this.props;

    return (
      <div id="playback-controls" className="playback-controls">
        {(allowDurationChange) ? (
          <Fragment>
            <div className="controls-wrapper video-timeline">
              <div className="entity-info">
                <EditorEntityAddButton className="entity-add-button" game={game} timer={timer} />
                <DurationControls game={game} timer={timer} />
                <PlayerControls class="time-control" game={game} timer={timer} />
                {(timer) && (
                  <div className="timer">{timeFormat(timer.time)}s</div>
                )}
              </div>
              <div className="time-control">
                <TimeControl timer={timer} />
              </div>
            </div>

            <div className="controls-wrapper timeline">
              {(allowDurationChange) && (
                <EntityTimeline game={game} timer={timer} />
              )}
            </div>
          </Fragment>
        ) : (
          <div className="inline-playback-controls">
            <div className="inline-controls-wrapper player-controls-wrapper">
              <PlayerControls game={game} timer={timer} />
            </div>
            <div className="inline-controls-wrapper time-control-wrapper">
              <TimeControl timer={timer} />
            </div>
            <div className="inline-controls-wrapper time-tracker-wrapper">
              <div className="timer">
                {(timer) && (
                  <div>{timeFormat(timer.time)}s</div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

PlaybackControls.propTypes = {
  game: MobxPropTypes.observableObject.isRequired,

  allowDurationChange: PropTypes.bool,
  timer: MobxPropTypes.observableObject,
};

PlaybackControls.defaultProps = {
  allowDurationChange: true,
};

export default observer(PlaybackControls);
