import {action} from 'mobx';

import {EXPIRES_IN, EXPIRES_PENDING, EXPIRE_TIME} from '../../../constants/storeConstants';
import {STATE_FULFILLED, STATE_PENDING, STATE_REJECTED} from '../../../constants/asyncConstants';
import serverApi from '../../../utils/serverApi';
import ApiBaseStore from '../common/apiBaseStore';

/**
 * ApiUnsplashPhotoSearchStore
 */
class ApiUnsplashPhotoSearchStore extends ApiBaseStore {
  /**
   * Search unsplash photos
   *
   * @param {string} searchValue
   * @param {number} page
   */
  @action makeRequest(searchValue, page) {
    this.state = STATE_PENDING;
    this[EXPIRE_TIME] = Date.now() + EXPIRES_PENDING;

    serverApi.unsplashPhotoSearch(searchValue, page)
      .then(action('unsplashPhotoSearchSuccess', (res) => {
        this.state = STATE_FULFILLED;
        this[EXPIRE_TIME] = Date.now() + EXPIRES_IN;
        this.data = res;
        this.error = null;
      })).catch(action('unsplashPhotoSearchError', (unsplashPhotoSearchError) => {
        this.state = STATE_REJECTED;
        this[EXPIRE_TIME] = null;
        this.data = null;
        this.error = unsplashPhotoSearchError;
      }));
  }
}

export default new ApiUnsplashPhotoSearchStore();
