import lodash from 'lodash';
import classNames from 'classnames';
import {observable, runInAction, toJS, transaction} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import {withAuth0} from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import React from 'react';

import PrestoBottomBar from './components/prestoBottomBar/PrestoBottomBar';
import ErrorBoundary from '../../common/errorBoundary/ErrorBoundary';
import LoadingIcon from '../../common/loadingIcon/LoadingIcon';
import DisplayControl from '../../controls/display/DisplayControl';
import PlaybackControls from '../../controls/playback/PlaybackControls';
import ZoomControl from '../../controls/zoom/ZoomControl';
import Display from '../../display/display/Display';
import inject from '../../hoc/injectHoc';
import {userPaymentRoute} from '../../routePaths';
import DisplaySidebar from '../../sidebars/display/DisplaySidebar';
import {STATE_PENDING, STATE_PRE} from '../../../constants/asyncConstants';
import {DEMO_STORAGE_NAME} from '../../../constants/demoConstants';
import {loadGameFromSource} from '../../../display/game';
import {parseContentIntoSignSizes} from '../../../utils/contentsHelper';
import {blockTransition} from '../../../utils/history';
import {replaceRouteParams} from '../../../utils/routeHelper';
import cookieHelper from '../../../utils/cookieHelper';

import './prestoPage.scss';

/**
 * If display height adjustment fails, this will be the default height.
 * @const {number}
 */
const DEFAULT_DISPLAY_HEIGHT = 360;

/**
 * The height of the display will be set to (#main-page height - x) where x is this value.
 * This is the height of the bottom-bar plus the height of the playback controls.
 * @const {number}
 */
const DISPLAY_SUBTRACT_FROM_HEIGHT = 145;

/**
 * The updateDisplaySize function can only run once per this many milliseconds.
 * @const {number}
 */
const THROTTLE_TIME = 750;

/**
 * The PrestoPage component.
 */
export class PrestoPage extends React.Component {
  /**
   * The currently loaded aspect ratio.
   *
   * @type {?string}
   */
  @observable loadedAspectRatio = null;

  /**
   * The current height of the display.
   * This will change as the window resizes.
   *
   * @type {number}
   */
  @observable displayHeight = DEFAULT_DISPLAY_HEIGHT;

  /**
   * Whether or not this is a demo flow.
   *
   * @type {boolean}
   */
  @observable isDemoFlow = false;

  /**
   * The updateDisplaySize function throttled so it doesn't fire too much while dragging the frame.
   *
   * @type {?function}
   */
  throttledUpdateDisplaySize = null;

  /**
   * The function used to remove the block on router transitions.
   *
   * @type {?function}
   */
  unblockRouterTransitions = null;

  /**
   * @constructor
   * @param {{}} props
   * @param {{}} componentContext
   */
  constructor(props, componentContext) {
    super(props, componentContext);

    this.throttledUpdateDisplaySize = lodash.throttle(this.updateDisplaySize, THROTTLE_TIME);

    this.preloadContent(props);
  }

  /**
   * Triggered when the component is added to the page.
   */
  componentDidMount() {
    window.addEventListener('resize', this.throttledUpdateDisplaySize);
    window.addEventListener('beforeunload', this.onPageUnload);

    this.unblockRouterTransitions = blockTransition(() => {
      if (!this.hasChanges()) {
        return undefined;
      }
      return 'You have unsaved changes. Are you sure you want to navigate away?';
    });
  }

  /**
   * Triggered when the component is removed from the page.
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledUpdateDisplaySize);
    window.removeEventListener('beforeunload', this.onPageUnload);

    if (this.unblockRouterTransitions) {
      this.unblockRouterTransitions();
    }
  }

  /**
   * Triggered when the component reacts to MobX changes.
   */
  componentWillReact() {
    const {displayEditorStore} = this.props;

    const currentAspectRatio = displayEditorStore.currentAspectRatio;

    if (this.loadedAspectRatio && currentAspectRatio && this.loadedAspectRatio !== currentAspectRatio) {
      const newSource = toJS(displayEditorStore.sources.get(String(currentAspectRatio)));

      this.initGame(newSource);

      runInAction('prestoPageSetUpdatedAspectRatio', () => {
        this.loadedAspectRatio = currentAspectRatio;
      });
    }
  }

  /**
   * Whether or not there are changes to the game/video/image.
   *
   * @returns {boolean}
   */
  hasChanges = () => {
    const {displayEditorStore} = this.props;
    if (!displayEditorStore.game || !displayEditorStore.game.history) {
      return false;
    }

    const {hasUndo, hasRedo} = displayEditorStore.game.history;
    return (hasUndo || hasRedo);
  };

  /**
   * Triggered when the page attempts to unload if changes have been made.
   * This will open a confirm dialog using the returned string.
   *
   * @param {{}} unloadEvent
   */
  onPageUnload = (unloadEvent) => {
    if (this.hasChanges()) {
      unloadEvent.returnValue = 'You have unsaved changes. Are you sure you want to navigate away?';
    }
  };

  /**
   * Triggered when the content is saved.
   *
   * @param {?Error} copyError
   * @param {{}=} dashboardParams
   */
  onCopyCreated = (copyError, dashboardParams) => {
    if (this.unblockRouterTransitions) {
      this.unblockRouterTransitions();
    }

    const {navigateContentStore, apiContentFolderGetContentStore} = this.props;

    // refresh content folder so template statuses are properly updated
    apiContentFolderGetContentStore.refresh(dashboardParams.listId, true);

    navigateContentStore.navigateToDashboard(null, dashboardParams);
  };

  /**
   * Redirects to the sign up page.
   */
  onSignUp = () => {
    if (this.unblockRouterTransitions) {
      this.unblockRouterTransitions();
    }

    const {loginWithRedirect} = this.props.auth0;

    try {
      loginWithRedirect({
        authorizationParams: {
          'screen_hint': 'signup',
        }});
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('ERROR SIGNING UP: ', err);
    }
  };

  /**
   * Redirects to the sign up page.
   */
  onUpgrade = () => {
    if (this.unblockRouterTransitions) {
      this.unblockRouterTransitions();
    }

    const {routerStore} = this.props;

    const toRoute = replaceRouteParams(userPaymentRoute, {planId: ''});
    routerStore.push(userPaymentRoute, {
      addTo: toRoute,
    });
  };

  /**
   * Gets the demo data from local storage if it is available.
   *
   * @returns {?{id: number, variables: {}}}
   */
  getDemoData = () => {
    const activeDemo = localStorage.getItem(DEMO_STORAGE_NAME);
    if (!activeDemo) {
      return null;
    }

    let demoItems;
    try {
      demoItems = JSON.parse(activeDemo);
    } catch (parseError) {
      // Ignore the demo if the data is corrupted.
      return null;
    }

    if (!demoItems || !demoItems.id || !demoItems.variables) {
      return null;
    }

    return demoItems;
  };

  /**
   * Clears the demo data.
   */
  clearDemoData = () => {
    localStorage.removeItem(DEMO_STORAGE_NAME);
  };

  /**
   * Preloads the content for the page.
   *
   * @param {{match: {params: {contentId: number}}}} props
   */
  preloadContent = (props) => {
    const {
      /** @type DisplayEditorStore */ displayEditorStore,
      /** @type ApiContentGetOneStore */ apiContentGetOneStore,
      /** @type apiCompanySignGetAllStore */ apiCompanySignGetAllStore,
    } = props;

    displayEditorStore.setGame(null);
    displayEditorStore.setTimer(null);
    displayEditorStore.setContent(null);

    const contentId = this.getContentId(props);
    apiContentGetOneStore.refresh(contentId);
    apiCompanySignGetAllStore.refresh();

    let contentData = null;
    let signs = null;

    const contentPromise = apiContentGetOneStore.getPromise(contentId).then((foundContentData) => {
      contentData = foundContentData;
    });
    const signsPromise = apiCompanySignGetAllStore.getPromise().then((foundSigns) => {
      if (foundSigns && foundSigns.length) {
        signs = foundSigns;
      }
    }).catch(() => {
      signs = null;
    });

    const placeholderSigns = [{
      aspectRatio: '16:9',
    }];

    Promise.all([contentPromise, signsPromise]).then(() => {
      const fittedSources = parseContentIntoSignSizes(signs, contentData, placeholderSigns);

      const aspectRatio = lodash.first(lodash.keys(fittedSources));

      const signDimensions = fittedSources[aspectRatio].signDimensions;

      displayEditorStore.setAllSources(fittedSources);
      displayEditorStore.setAllVariables(contentData.variables);
      displayEditorStore.setCurrentAspectRatio(aspectRatio);
      displayEditorStore.setDefaultAspectRatio(aspectRatio);
      displayEditorStore.setCurrentSignDimensions(signDimensions);
      displayEditorStore.setDefaultSignDimensions(signDimensions);
      displayEditorStore.setContent(contentData);

      const demoData = this.getDemoData();
      if (demoData && demoData.variables) {
        displayEditorStore.setAllVariables(demoData.variables);
      }

      // Make sure the demo data is removed.
      this.clearDemoData();

      runInAction('prestoPageSetLoadedAspectRatio', () => {
        this.isDemoFlow = Boolean(demoData && demoData.variables);
        this.loadedAspectRatio = aspectRatio;
      });

      const safeSource = fittedSources[aspectRatio];
      if (!safeSource || safeSource instanceof Error) {
        if (safeSource instanceof Error) {
          console.error(safeSource); // eslint-disable-line no-console
        }
        throw new Error('No Presto source found.');
      }

      this.initGame(safeSource);
    }).catch((initError) => {
      displayEditorStore.setContent(initError);
      console.error(initError); // eslint-disable-line no-console
    });
  };

  /**
   * Initializes the game from the source and makes sure the timer is setup properly.
   *
   * @param {{}} source
   */
  initGame = (source) => {
    if (!source) {
      return;
    }
    const {displayEditorStore, displayZoomStore} = this.props;

    const safeVariables = toJS(displayEditorStore.variables);

    const {game, timer} = loadGameFromSource(source, safeVariables, true);
    const gameResolution = game.resolution;

    game.setComposeMode(true);

    // We want the requestAnimationFrame going at all times, so prime the timer (start and reset).
    timer.start();
    timer.stopAndReset();

    // Initialize game and timer before adding them to the store.
    displayEditorStore.setTimer(timer);
    displayEditorStore.setGame(game);

    transaction(() => {
      displayZoomStore.setGameSize(gameResolution.width, gameResolution.height);
      this.updateDisplaySize();
      displayZoomStore.setZoomLevel(displayZoomStore.controlData.start);
    });
  };

  /**
   * Gets the content id from the url params.
   *
   * @param {{match: {params: {contentId: number}}}=} props
   * @returns {?number}
   */
  getContentId = (props) => {
    return (props || this.props).match.params.contentId;
  };

  /**
   * Updates the zoom display size.
   */
  updateDisplaySize = () => {
    const {displayEditorStore, displayZoomStore} = this.props;
    if (!displayEditorStore.game) {
      return;
    }

    const game = displayEditorStore.game;

    const prestoPage = document.getElementById('main-page');
    if (!prestoPage) {
      return;
    }

    runInAction('prestoPageUpdateHeight', () => {
      if (game.endTime > 0) {
        this.displayHeight = (prestoPage.clientHeight - DISPLAY_SUBTRACT_FROM_HEIGHT);
      } else {
        this.displayHeight = prestoPage.clientHeight;
      }
    });

    const displayEl = document.getElementById('display');

    if (displayEl) {
      displayZoomStore.setDisplaySize(displayEl.clientWidth, this.displayHeight);
    }
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {displayEditorStore, displayZoomStore, apiContentGetOneStore, apiUserGetMeStore} = this.props;
    const {game, timer} = this.props.displayEditorStore;

    if (game) {
      // Allows the componentWillReact to trigger when the currentAspectRatio is updated.
      displayEditorStore.currentAspectRatio; // eslint-disable-line no-unused-expressions
    }

    const contentId = this.getContentId();

    const currentState = apiContentGetOneStore.getState(contentId);
    if (lodash.includes([STATE_PENDING, STATE_PRE], currentState)) {
      return (
        <div id="presto-page" className="system-page">
          <div className="col">
            <LoadingIcon />
          </div>
        </div>
      );
    }

    const content = apiContentGetOneStore.getFulfilled(contentId);
    const hasTimeLine = (game) ? game.hasTimeLine() : false;
    const isEmulating = !!cookieHelper.getEmulationAuthId();
    const isInMaintenanceMode = false;

    if (!game) {
      return (
        <div id="presto-page" className="system-page">
          <div className="col">
            {apiContentGetOneStore.case(contentId, {
              pre: () => (<LoadingIcon size="lg" />),
              pending: () => (<LoadingIcon size="lg" />),
              fulfilled: () => (
                <div className="alert alert-warning">
                  Could not load Presto because no source was found.
                </div>
              ),
            })}
          </div>
        </div>
      );
    }

    return (
      <div id="presto-page" className="system-page">
        <ErrorBoundary message="An error occurred and Presto could not be loaded.">
          <div className={classNames('presto-top', {'has-message-bar': isEmulating || isInMaintenanceMode})}>
            <DisplaySidebar canMinimize={false} />

            <div className="main-display">
              <div className="display-wrapper">
                <Display game={game} height={this.displayHeight} zoomStore={displayZoomStore} />
              </div>

              {(hasTimeLine) && (
                <PlaybackControls game={game} timer={timer} allowDurationChange={false} />
              )}

              <div className="display-controls">
                <div className="controls-wrapper">
                  <DisplayControl game={game} zoomStore={displayZoomStore} />
                </div>
                <div className="controls-wrapper">
                  <ZoomControl />
                </div>
              </div>
            </div>
          </div>

          <div className="presto-bottom">
            {apiUserGetMeStore.case({
              'pre': () => (<LoadingIcon />),
              'pending': () => (<LoadingIcon />),
              'rejected': () => (
                <PrestoBottomBar
                  content={content}
                  game={game}
                  skipToEnd={this.isDemoFlow}
                  onCreated={this.onCopyCreated}
                  onSignUp={this.onSignUp}
                  onUpgrade={this.onUpgrade}
                />
              ),
              'fulfilled': (user) => (
                <PrestoBottomBar
                  content={content}
                  game={game}
                  user={user}
                  skipToEnd={this.isDemoFlow}
                  onCreated={this.onCopyCreated}
                  onSignUp={this.onSignUp}
                  onUpgrade={this.onUpgrade}
                />
              ),
            })}
          </div>
        </ErrorBoundary>
      </div>
    );
  }
}

PrestoPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,

  apiCompanySignGetAllStore: MobxPropTypes.observableObject,
  apiContentFolderGetContentStore: MobxPropTypes.observableObject,
  apiContentGetOneStore: MobxPropTypes.observableObject,
  apiUserGetMeStore: MobxPropTypes.observableObject,
  auth0: PropTypes.object,
  displayEditorStore: MobxPropTypes.observableObject,
  displayZoomStore: MobxPropTypes.observableObject,
  navigateContentStore: MobxPropTypes.observableObject,
  routerStore: MobxPropTypes.observableObject,
};

PrestoPage.wrappedComponent = {};
PrestoPage.wrappedComponent.propTypes = {
  apiCompanySignGetAllStore: MobxPropTypes.observableObject.isRequired,
  apiContentFolderGetContentStore: MobxPropTypes.observableObject.isRequired,
  apiContentGetOneStore: MobxPropTypes.observableObject.isRequired,
  apiUserGetMeStore: MobxPropTypes.observableObject.isRequired,
  auth0: MobxPropTypes.observableObject.isRequired,
  displayEditorStore: MobxPropTypes.observableObject.isRequired,
  displayZoomStore: MobxPropTypes.observableObject.isRequired,
  navigateContentStore: MobxPropTypes.observableObject.isRequired,
  routerStore: MobxPropTypes.observableObject.isRequired,
};

export default withAuth0(inject(PrestoPage)(
  observer(PrestoPage)
));
