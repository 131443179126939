import classNames from 'classnames';
import lodash from 'lodash';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ReactSelect from 'react-select';

import countries from './countries.json';

/**
 * The list of country label/value pairs for react-select.
 *
 * @type {Array.<{label: string, value: string}>}
 */
const COUNTRY_VALUES = lodash.map(countries, (countryName, countryAbbreviation) => {
  return {label: countryName, value: countryAbbreviation};
});

/**
 * The CountrySelect component.
 */
class CountrySelect extends Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {className, classNamePrefix, id, onChange, theme, value} = this.props;

    return (
      <ReactSelect
        id={id}
        className={classNames(className, 'site-select', `theme-${theme}`)}
        classNamePrefix={classNamePrefix}
        placeholder="Country"
        isClearable={false}
        isMulti={false}
        onChange={onChange}
        options={COUNTRY_VALUES}
        value={COUNTRY_VALUES.find((option) => option.value === value)}
      />
    );
  }
}

CountrySelect.propTypes = {
  onChange: PropTypes.func.isRequired,

  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  id: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark', 'input-white']),
  value: PropTypes.string,
};

CountrySelect.defaultProps = {
  theme: 'light',
};

export default observer(CountrySelect);
