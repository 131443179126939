import React from 'react';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import {UncontrolledTooltip} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle, faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';

import inject from '../../hoc/injectHoc';

import './editScreenshotTimeControl.scss';

/**
 * The conversion factor for going from milliseconds to seconds.
 *
 * @const {number}
 */
const MS_TO_SECONDS = 1000;

/**
 * Formats the time for the display.
 *
 * @param {number} time
 * @returns {string}
 */
function formatDisplayTime(time) {
  if (time === null) {
    return 'Auto';
  }

  const safeTime = time || 0;
  const formattedTime = (safeTime / MS_TO_SECONDS).toFixed(2);

  return `${formattedTime} Sec`;
}

/**
 * The EditScreenshotTimeControl component.
 */
export class EditScreenshotTimeControl extends React.Component {
    /**
   * Whether or not the screenshot control section is open.
   *
   * @type {boolean}
   */
    @observable isControlGroupOpen = false;

    @action onToggleControlGroup = () => {
      this.isControlGroupOpen = !this.isControlGroupOpen;
    }

  /**
   * Sets the screenshot time to the current timer time.
   *
   * @param {boolean} updateAllSources
   * @returns {function}
   */
  onSetScreenshotClick = (updateAllSources) => {
    return () => {
      const {
        /** @type {GameStore} */ game,
      } = this.props;

      const {
        /** @type {GameTimerStore} */ timer,
      } = game;

      const newTimes = [timer.time];

      this.updateCurrentSource(newTimes);

      if (updateAllSources) {
        this.updateAllSources(newTimes);
      }
    };
  };

  /**
   * Updates the screenshot times for the active source only.
   *
   * @param {number[]} newScreenshotTimes
   */
  updateCurrentSource(newScreenshotTimes) {
    const {
      /** @type {GameStore} */ game,
    } = this.props;

    game.setScreenshotTimes(newScreenshotTimes);
  }

  /**
   * Updates the screenshot times for all the current sources.
   *
   * @param {number[]} newScreenshotTimes
   */
  updateAllSources(newScreenshotTimes) {
    const {
      /** @type {DisplayEditorStore} */ displayEditorStore,
    } = this.props;

    if (!displayEditorStore.sources || !displayEditorStore.sources.size) {
      return;
    }

    displayEditorStore.sources.forEach((sourceData) => {
      sourceData.screenshotTimes = newScreenshotTimes;
    });
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      /** @type {GameStore} */ game,
    } = this.props;

    // if (game.composeMode) {
    //   // Compose mode disables most editing abilities.
    //   return null;
    // }

    // For now, only allow one screenshot time.
    const screenshotTime = (game.screenshotTimes) ? game.screenshotTimes[0] : null;

    return (
      <div className="edit-screenshot-time-controls control-group">
        <div className="group-header">
          <span className="group-header-label">Static Image</span>
          <button
            type="button"
            className="btn"
            onClick={this.onToggleControlGroup}
          >
            <FontAwesomeIcon icon={this.isControlGroupOpen ? faChevronUp : faChevronDown} />
          </button>
        </div>

        {this.isControlGroupOpen && (<div className="group-controls">
          <div className="row">
            <div className="col current-time">
              <span className="current-time-label">Current Static Image Time: </span>
              <span className="current-time-value">{formatDisplayTime(screenshotTime)}</span>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="form-group">
                <h5 className="set-buttons-header d-flex align-items-center">
                  Set Static Image Time
                  <FontAwesomeIcon
                    id="static-image-time-tooltip-icon"
                    className="ml-1"
                    icon={faInfoCircle}

                  />
                  <UncontrolledTooltip
                    placement="top"
                    target="static-image-time-tooltip-icon"
                  >
                    Move the playhead to the time you&apos;d like to use for your image file. Then hit the &quot;This Size Only&quot; or &quot;All Sizes&quot; button.
                  </UncontrolledTooltip>
                </h5>

                <button
                  type="button"
                  className="btn btn-primary btn-block set-button"
                  onClick={this.onSetScreenshotClick(false)}
                >This Size Only</button>

                <button
                  type="button"
                  className="btn btn-primary btn-block set-button"
                  onClick={this.onSetScreenshotClick(true)}
                >All Sizes</button>
              </div>
            </div>
          </div>
        </div>)}
      </div>
    );
  }
}

EditScreenshotTimeControl.propTypes = {
  game: MobxPropTypes.observableObject.isRequired,

  displayEditorStore: MobxPropTypes.observableObject,
};

EditScreenshotTimeControl.wrappedComponent = {};
EditScreenshotTimeControl.wrappedComponent.propTypes = {
  displayEditorStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(EditScreenshotTimeControl)(
  observer(EditScreenshotTimeControl)
);
