import {action, observable, reaction, toJS} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import lodash from 'lodash';
import React from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Tooltip} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faEllipsisV, faFont, faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {faTrashCan, faCopy} from '@fortawesome/free-regular-svg-icons';

import inject from '../../../../hoc/injectHoc';
import entityReorderHoc, {LOCKED_CLASS} from '../../../../hoc/entityReorderHoc';
import InputStringModal from '../../../../modals/inputString/InputStringModal';
import {ACTIVE_DELAY_MS} from '../../../../../constants/displayConstants';
import {actionUpdateComponent} from '../../../../../display/components/action/actionUpdateComponent';
import {timeComponent} from '../../../../../display/components/common/timeComponent';
import {formatInputTime, between} from '../../../../../utils/mathHelper';
import {actionInteractionComponent} from '../../../../../display/components/action/actionInteractionComponent';
import {actionRenameEntityComponent} from '../../../../../display/components/action/actionRenameEntityComponent';
import {actionRemoveEntityComponent} from '../../../../../display/components/action/actionRemoveEntityComponent';
import {actionDuplicateEntityComponent} from '../../../../../display/components/action/actionDuplicateEntityComponent';
import {transitionComponent} from '../../../../../display/components/common/transitionComponent';
import {visibleComponent} from '../../../../../display/components/common/visibleComponent';
import {FLOWS, findLongestTransitionsByFlow, calculateNewTransitionTime} from '../../../../../display/ecs/transitionHelper';

import './entityTimelineItem.scss';
import TimelineSlider from '../../../../common/timelineSlider/TimelineSlider';
import {triggerResize} from '../../../../../utils/windowHelper';

/**
 * The minimum amount of time in milliseconds by which the start and end time can be separated.
 *
 * @const {number}
 */
const MINIMUM_SEPARATION = 100;

/**
 * The EntityTimelineItem component.
 *
 * @param {{
 *   displayEditorStore: DisplayEditorStore,
 * }} props
 */
export class EntityTimelineItem extends React.Component {
  /**
   * The entity start time.
   *
   * @type {number}
   */
  @observable startTime = 0;

  /**
   * The entity end time.
   *
   * @type {number}
   */
  @observable endTime = 0;

  /**
   * The disposer function for the mobX reaction.
   *
   * @type {?function}
   */
  @observable reactionDisposer = null;

  /**
   * Whether or not the menu is open.
   *
   * @type {boolean}
   */
  @observable isMenuOpen = false;

  /**
   * Whether or not the rename modal is open.
   *
   * @type {boolean}
   */
  @observable isRenameModalOpen = false;

  /**
   * Whether or not the tooltip is open.
   *
   * @type {boolean}
   */
  @observable isTooltipOpen = false;

  /**
   * @constructor
   * @param {{game: GameStore}} props
   * @param {{}} componentContext
   */
  constructor(props, componentContext) {
    super(props, componentContext);

    const {entity} = props;
    const currentTime = entity.get('time');
    if (currentTime) {
      this.updateStartTime(currentTime.start, true);
      this.updateEndTime(currentTime.end, true);
    }
  }

  /**
   * Triggered right after the component is added to the page.
   */
  componentDidMount() {
    this.reactionDisposer = reaction(
      () => {
        const entityTimes = this.props.entity.get('time');
        return {
          start: entityTimes.start,
          end: entityTimes.end,
        };
      },
      (entityTimes) => {
        this.updateStartTime(entityTimes.start, true);
        this.updateEndTime(entityTimes.end, true);
      }, {
        fireImmediately: true,
        name: 'updateInputTimesOnSliderChange'
      }
    );
  }

  /**
   * Triggered when the component is about to be removed from the page.
   */
  componentWillUnmount() {
    if (this.reactionDisposer) {
      this.reactionDisposer();
    }
  }

  /**
   * Finds in and out transitions
   *
   * @returns {[in, out]}
   */
  getEntityTransitions() {
    const {entity} = this.props;

    const entityTransitions = toJS(entity.get('transition') || []);

    // Shows the handles for the in and out transitions
    const transitionFlowTypes = [
      {
        flowDirection: FLOWS.in,
        handleTime: 'time.end' // show the end of the in transition
      },
      {
        flowDirection: FLOWS.out,
        handleTime: 'time.start' // show the start of the out transition
      }
    ];

    const longestTransitionHandles = findLongestTransitionsByFlow(entityTransitions);

    const transitionHandles = transitionFlowTypes.map((flowType) => {
      const entityTransition = longestTransitionHandles[flowType.flowDirection];

      return lodash.get(entityTransition, flowType.handleTime, null);
    });

    return transitionHandles;
  }

  /**
   * Activates the entity.
   *
   * @param {boolean} onlyActivate If true, the entity can't be deselected.
   * @param {{}=} clickEvent
   */
  activateEntity = (onlyActivate, clickEvent) => {
    const {entity, game, timer} = this.props;

    const holdingShift = Boolean(clickEvent && clickEvent.shiftKey);
    const isDoubleClick = Boolean(clickEvent && clickEvent.detail === 2);

    const interaction = entity.get('interaction');
    const isActive = Boolean(interaction && interaction.isActive);

    const actionParams = {
      entityId: entity.get('id'),
      skipHistory: true,
    };

    if (isDoubleClick) {
      this.onOpenRenameModal();
    }

    /**
   * Activates the entity.
   *
   * @param {boolean=} setTime
   */
    function activate(setTime = true) {
      game.addAction(actionParams, actionInteractionComponent(true, holdingShift, false));

      if (setTime) {
        const activeTime = entity.get('time').active;
        timer.setTime(activeTime);
      }
    }

    /**
   * Deactivates the entity.
   */
    function deactivate() {
      game.addAction(actionParams, actionInteractionComponent(false, holdingShift, false));
    }

    if (onlyActivate) {
      activate(true);
    } else if (isActive) {
      deactivate();
    } else {
      activate(true);
    }
  };

  /**
   * Find activated entities
   *
   * @returns {[]}
   */
  findActiveEntities = () => {
    const {game} = this.props;

    const allEntities = lodash.get(game, 'entities', []);

    return allEntities.filter((entity) => {
      const interaction = entity.get('interaction');
      const active = Boolean(interaction && interaction.isActive);

      return active;
    });
  }

  /**
   * Triggered when the dropdown open button is clicked.
   *
   * @param {{stopPropagation: function}} clickEvent
   */
  @action onClickDropdown = (clickEvent) => {
    // We want to prevent the click on the list-item, so stop propagation.
    clickEvent.stopPropagation();

    this.isMenuOpen = !this.isMenuOpen;
    if (this.isMenuOpen) {
      // If the menu is now open, make sure to activate this entity to avoid zIndex stacking context issues.
      this.activateEntity(true);
    }
  };

  /**
   * Triggered when the the entity rename option is clicked.
   */
  @action onOpenRenameModal = () => {
    this.isRenameModalOpen = true;
  };

  /**
   * Triggered when the the entity rename option is clicked.
   *
   * @param {string} newName
   */
  @action onEntityRename = (newName) => {
    this.isRenameModalOpen = false;

    if (!newName) {
      // The modal must have been cancelled.
      return;
    }

    // The maximum character length for entity names.
    const maxLength = 39;

    let safeName = String(newName);
    if (safeName.length > maxLength) {
      safeName = safeName.substr(0, maxLength) + '...';
    }

    const {entity, game} = this.props;

    const actionParams = {
      entityId: entity.get('id')
    };

    game.addAction(actionParams, Object.assign(
      {},
      actionRenameEntityComponent(safeName),
      actionInteractionComponent(true)
    ));
  };

  /**
   * Triggered when the the entity delete option is clicked.
   *
   * @param {{}} clickEvent
   */
  onEntityDelete = (clickEvent) => {
    clickEvent.stopPropagation();

    const {entity, game} = this.props;

    const actionParams = {
      entityId: entity.get('id')
    };

    game.addAction(actionParams, actionRemoveEntityComponent());

    triggerResize();
  };

  /**
   * Triggered when the the entity duplicate option is clicked.
   *
   * @param {{}} clickEvent
   */
  onEntityDuplicate = (clickEvent) => {
    clickEvent.stopPropagation();

    const {entity, game} = this.props;

    const actionParams = {
      entityId: entity.get('id')
    };

    game.addAction(actionParams, Object.assign(
      {},
      actionDuplicateEntityComponent(),
      actionInteractionComponent(true)
    ));

    triggerResize();
  };

  /**
   * Triggered when the actions dropdown button is clicked.
   *
   * @param {{stopPropagation: function}} clickEvent
   */
  onClickStopPropagation = (clickEvent) => {
    clickEvent.stopPropagation();
  };

  /**
   * Triggered when the start time slider changes.
   *
   * @param {number} totalDiff
   */
  onChangeStart = (totalDiff) => {
    const {game, entity: componentEntity} = this.props;
    const activeEntities = this.findActiveEntities();

    /**
     * Change start time of specified entityId
     *
     * @param {{}} entity
     */
    function changeStart(entity) {
      const entityTime = entity.get('time');
      const {start, active} = entityTime;
      const end = entityTime.end || game.endTime;
      const actionParams = {
        entityId: entity.get('id'),
      };

      let newValue = between(start + totalDiff, 0, game.endTime);
      newValue = newValue + MINIMUM_SEPARATION >= end ? end - MINIMUM_SEPARATION : newValue;

      let timeInMS = Math.floor(newValue);
      timeInMS = timeInMS > game.endTime ? game.endTime : timeInMS;
      timeInMS = timeInMS < 0 ? 0 : timeInMS;
      timeInMS = timeInMS > end - MINIMUM_SEPARATION ? end - MINIMUM_SEPARATION : timeInMS;

      const activeDiff = Math.max(active - start, ACTIVE_DELAY_MS);

      let newActive = activeDiff + timeInMS;
      newActive = newActive > end ? end : newActive;

      game.addAction(actionParams, actionUpdateComponent(
        timeComponent(
          timeInMS,
          end,
          newActive
        ))
      );
    }

    const entityInteraction = componentEntity.get('interaction');
    const isComponentEntityActive = entityInteraction && entityInteraction.isActive;

    if (isComponentEntityActive) {
      activeEntities.forEach((activeEntity) => {
        changeStart(activeEntity);
      });
    } else {
      changeStart(componentEntity);
    }
  };

  /**
   * Triggered when the end time slider changes.
   *
   * @param {number} totalDiff
   */
  onChangeEnd = (totalDiff) => {
    const {game, entity: componentEntity} = this.props;
    const activeEntities = this.findActiveEntities();

    /**
     * Change end time of specified entityId
     *
     * @param {{}} entity
     */
    function changeEnd(entity) {
      const entityTime = entity.get('time');
      const {start, active} = entityTime;
      const end = entityTime.end || game.endTime;
      const actionParams = {
        entityId: entity.get('id'),
      };

      let newValue = between(end + totalDiff, MINIMUM_SEPARATION, game.endTime);
      newValue = newValue - MINIMUM_SEPARATION <= start ? start + MINIMUM_SEPARATION : newValue;

      let timeInMS = Math.floor(newValue);
      timeInMS = timeInMS > game.endTime ? game.endTime : timeInMS;
      timeInMS = timeInMS < MINIMUM_SEPARATION ? MINIMUM_SEPARATION : timeInMS;
      timeInMS = timeInMS < start + MINIMUM_SEPARATION ? start + MINIMUM_SEPARATION : timeInMS;

      const newActive = (active < timeInMS) ? active : timeInMS;

      game.addAction(actionParams, actionUpdateComponent(
        timeComponent(
          start,
          timeInMS,
          newActive
        ))
      );
    }

    const entityInteraction = componentEntity.get('interaction');
    const isComponentEntityActive = entityInteraction && entityInteraction.isActive;
    if (isComponentEntityActive) {
      activeEntities.forEach((activeEntity) => {
        changeEnd(activeEntity);
      });
    } else {
      changeEnd(componentEntity);
    }
  };

  /**
   * Triggered when the end time slider changes.
   *
   * @param {number} totalDiff
   */
  onChangeFull = (totalDiff) => {
    const {game, entity: componentEntity} = this.props;
    const activeEntities = this.findActiveEntities();

    /**
     * Change full time of specified entity
     *
     * @param {{}} entity
     */
    function changeFull(entity) {
      const currentTime = entity.get('time');
      const actionParams = {
        entityId: entity.get('id'),
      };
      const entityDuration = currentTime.end - currentTime.start;

      const timeInMS = Math.floor(totalDiff);
      let start = between(currentTime.start + timeInMS, 0, game.endTime);
      let end = between(currentTime.end + timeInMS, MINIMUM_SEPARATION, game.endTime);

      // Validate and set start time
      start = start > game.endTime ? game.endTime : start;
      start = start < 0 ? 0 : start;
      start = start > end - MINIMUM_SEPARATION ? end - MINIMUM_SEPARATION : start;
      start = end >= game.endTime ? game.endTime - entityDuration : start;

      // Validate and set end time
      end = end > game.endTime ? game.endTime : end;
      end = end < MINIMUM_SEPARATION ? MINIMUM_SEPARATION : end;
      end = end < start + MINIMUM_SEPARATION ? start + MINIMUM_SEPARATION : end;
      end = start <= 0 ? entityDuration : end;

      // need to find the true change in time in the case
      // multiple bars are dragged where at least one bar doesn't move.
      // in this case we don't want to move the active time
      const trueDiff = start - currentTime.start;

      let newActive = currentTime.active + trueDiff;
      newActive = newActive < start ? ACTIVE_DELAY_MS : newActive;
      newActive = newActive > end ? end : newActive;

      game.addAction(actionParams, actionUpdateComponent(
        timeComponent(
          start,
          end,
          newActive
        ))
      );
    }

    const entityInteraction = componentEntity.get('interaction');
    const isComponentEntityActive = entityInteraction && entityInteraction.isActive;
    if (isComponentEntityActive) {
      activeEntities.forEach((activeEntity) => {
        changeFull(activeEntity);
      });
    } else {
      changeFull(componentEntity);
    }
  };

  /**
   * Triggered when the in transition slider changes.
   *
   * @param {number} totalDiff
   */
  onChangeIn = (totalDiff) => {
    const {game, entity: componentEntity} = this.props;
    const activeEntities = this.findActiveEntities();

    /**
     * Change end time of in transition
     *
     * @param {{}} entity
     */
    function changeEndTimeOfInTransition(entity) {
      const actionParams = {
        entityId: entity.get('id'),
      };

      const entityTime = toJS(entity.get('time'));

      const entityTransitions = toJS(entity.get('transition') || []);
      entityTransitions.forEach((transition) => {
        const isInFlow = lodash.get(transition, 'details.flow', null) === FLOWS.in;
        if (isInFlow) {
          Object.values(transition.details).forEach((transitionDetail) => {
            const hasTimeProperty = typeof transitionDetail === 'object' && transitionDetail.hasOwnProperty('time');
            if (hasTimeProperty) {
              const absoluteTransitionEndTime = between(transition.time.end + totalDiff, entityTime.start + MINIMUM_SEPARATION, entityTime.end - MINIMUM_SEPARATION);
              const newEndTime = calculateNewTransitionTime(absoluteTransitionEndTime, entityTime, transitionDetail);
              if (absoluteTransitionEndTime < transition.time.start) {
                transitionDetail.time.start = calculateNewTransitionTime(absoluteTransitionEndTime - MINIMUM_SEPARATION, entityTime, transitionDetail);
              }
              transitionDetail.time.end = newEndTime;
            }
          });
        }
      });

      const visible = entity.get('visible');

      game.addAction(actionParams, actionUpdateComponent({
        ...transitionComponent(entityTransitions),
        ...visibleComponent(visible.isVisible, 1),
      }));
    }

    const entityInteraction = componentEntity.get('interaction');
    const isComponentEntityActive = entityInteraction && entityInteraction.isActive;
    if (isComponentEntityActive) {
      activeEntities.forEach((activeEntity) => {
        changeEndTimeOfInTransition(activeEntity);
      });
    } else {
      changeEndTimeOfInTransition(componentEntity);
    }
  };

  /**
   * Triggered when the out transition slider changes.
   *
   * @param {number} totalDiff
   */
  onChangeOut = (totalDiff) => {
    const {game, entity: componentEntity} = this.props;
    const activeEntities = this.findActiveEntities();

    /**
     * Change transition out time of specified entity
     *
     * @param {{}} entity
     */
    function changeStartTimeOfOutTransition(entity) {
      const actionParams = {
        entityId: entity.get('id'),
      };

      const entityTime = toJS(entity.get('time'));

      const entityTransitions = toJS(entity.get('transition') || []);

      entityTransitions.forEach((transition) => {
        const isOutFlow = lodash.get(transition, 'details.flow', null) === FLOWS.out;
        if (isOutFlow) {
          Object.values(transition.details).forEach((transitionDetail) => {
            const hasTimeProperty = typeof transitionDetail === 'object' && transitionDetail.hasOwnProperty('time');
            if (hasTimeProperty) {
              const absoluteTransitionStartTime = between(transition.time.start + totalDiff, entityTime.start + MINIMUM_SEPARATION, entityTime.end - MINIMUM_SEPARATION);

              const newStartTime = calculateNewTransitionTime(absoluteTransitionStartTime, entityTime, transitionDetail);
              if (absoluteTransitionStartTime > transition.time.end) {
                transition.time.end = calculateNewTransitionTime(absoluteTransitionStartTime + MINIMUM_SEPARATION, entityTime, transitionDetail);
              }
              transitionDetail.time.start = newStartTime;
            }
          });
        }
      });

      const visible = entity.get('visible');

      game.addAction(actionParams, actionUpdateComponent({
        ...transitionComponent(entityTransitions),
        ...visibleComponent(visible.isVisible, 1),
      }));
    }

    const entityInteraction = componentEntity.get('interaction');
    const isComponentEntityActive = entityInteraction && entityInteraction.isActive;
    if (isComponentEntityActive) {
      activeEntities.forEach((activeEntity) => {
        changeStartTimeOfOutTransition(activeEntity);
      });
    } else {
      changeStartTimeOfOutTransition(componentEntity);
    }
  };

  /**
   * Updates the start time input value.
   *
   * @param {number} newStartTime
   * @param {boolean=} formatFromMS
   */
  @action updateStartTime = (newStartTime, formatFromMS = false) => {
    if (formatFromMS) {
      this.startTime = formatInputTime(newStartTime);
    } else {
      this.startTime = newStartTime;
    }
  };

  /**
   * Updates the start time input value.
   *
   * @param {number} newEndTime
   * @param {boolean=} formatFromMS
   */
  @action updateEndTime = (newEndTime, formatFromMS = false) => {
    if (formatFromMS) {
      this.endTime = formatInputTime(newEndTime);
    } else {
      this.endTime = newEndTime;
    }
  };

  /**
   * Toggles the tooltip.
   */
  @action toggleTooltip = () => {
    this.isTooltipOpen = !this.isTooltipOpen;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      /** @type {ObservableMap} */ entity,
      /** @type {GameStore} */ game,
      /** @type {Boolean} */ disableOrdering
    } = this.props;

    const entityTime = entity.get('time');
    const startTime = entityTime.start;
    const endTime = entityTime.end || game.endTime;
    const maxTime = game.endTime;

    const [transitionIn, transitionOut] = this.getEntityTransitions() || [];

    const elementId = `timeline-selector-${entity.get('id')}`;

    const interaction = entity.get('interaction');
    const active = Boolean(interaction && interaction.isActive);
    const orderLocked = (entity.has('locked') && lodash.includes(entity.get('locked'), 'order'));

    const entityIsFeed = entity.get('element').includes('feed');

    const topClasses = {
      active,
      [LOCKED_CLASS]: orderLocked || entityIsFeed, // feed should be pinned to top of layers and not allowed to be re-ordered
    };

    let entityName = lodash.upperFirst(entity.get('element'));
    if (entity.has('name')) {
      entityName = entity.get('name');
    }

    const iconId = `entity-icon-${entity.get('id')}`;

    return (
      <a
        className={classNames('entity-timeline-item', topClasses)}
        title={entityName}
      >
        <div
          id="entity-info"
          className="entity-info"
          onClick={(clickEvent) => this.activateEntity(false, clickEvent)}
        >
          {(!disableOrdering) && (
            (entityIsFeed)
              ? (<>
              <span
                id={iconId}
                className="mr-2"
                onClick={this.onClickStopPropagation}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
              <Tooltip
                placement="right"
                isOpen={this.isTooltipOpen}
                target={iconId}
                toggle={this.toggleTooltip}
              >
                Feed layers are always rendered on top of the content and cannot be re-ordered.
              </Tooltip>
            </>)
              : (<span
                id={iconId}
                className="entity-reorder-action mr-2"
                onClick={this.onClickStopPropagation}
              >
                <FontAwesomeIcon icon={faBars} />
              </span>)
          )}

          <div className="entity-name">{entityName}</div>

          <div>
            <div className="entity-actions-wrapper">
              <span className="entity-actions">
                <Dropdown direction="up" isOpen={this.isMenuOpen} toggle={this.onClickDropdown}>
                  <DropdownToggle className="btn btn-sm btn-link btn-icon" tag="button">
                    <FontAwesomeIcon icon={faEllipsisV} />
                  </DropdownToggle>
                  {/* need this property even though react throws an "unknown prop" console.error
                  this prop ultimately gets passed down to react-popper so the dropdown can appear
                  outside the overflow: hidden container
                  presumably updating reactstrap to 7.1+ will fix the unknown prop error */}
                  <DropdownMenu positionFixed={true}>
                    <DropdownItem
                      onClick={this.onOpenRenameModal}
                    >
                      <FontAwesomeIcon icon={faFont} />
                    Change Name
                    </DropdownItem>

                    <DropdownItem
                      onClick={this.onEntityDuplicate}
                    >
                      <FontAwesomeIcon icon={faCopy} />
                    Duplicate
                    </DropdownItem>

                    <DropdownItem onClick={this.onEntityDelete}>
                      <FontAwesomeIcon icon={faTrashCan} />
                    Delete
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </span>
              {(this.isRenameModalOpen) && (
                <InputStringModal
                  isOpen={true}
                  title="Rename Layer"
                  onComplete={this.onEntityRename}
                  startingValue={entityName}
                />
              )}
            </div>
          </div>
        </div>

        <TimelineSlider
          className="entity-time-control"
          id={elementId}
          minValue={0}
          maxValue={maxTime}
          onChangeStart={this.onChangeStart}
          onChangeEnd={this.onChangeEnd}
          onChangeFull={this.onChangeFull}
          onChangeIn={this.onChangeIn}
          onChangeOut={this.onChangeOut}
          startValue={startTime}
          endValue={endTime}
          inValue={transitionIn}
          outValue={transitionOut}
          minSeparation={MINIMUM_SEPARATION}
          isActive={active}
        />
      </a>
    );
  }
}

EntityTimelineItem.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
  timer: MobxPropTypes.observableObject.isRequired,

  disableOrdering: PropTypes.bool,
  isLast: PropTypes.bool,
};

EntityTimelineItem.wrappedComponent = {};
EntityTimelineItem.wrappedComponent.propTypes = {
  displayEditorStore: MobxPropTypes.observableObject.isRequired,
};

export default entityReorderHoc(
  inject(EntityTimelineItem)(
    observer(EntityTimelineItem)
  )
);
