import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropType from 'prop-types';
import React from 'react';

import entityCropMoveHOC from '../../../hoc/entityCropMoveHoc';

/**
 * The CropOutline component.
 */
class CropOutline extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {path, strokeColor, strokeWidth} = this.props;

    return (
      <path
        className="crop-outline"
        stroke={strokeColor}
        fill="none"
        strokeOpacity="1"
        strokeWidth={strokeWidth}
        strokeLinecap="square"
        strokeLinejoin="miter"
        strokeMiterlimit="10"
        pointerEvents="all"
        d={path}
      />
    );
  }
}

CropOutline.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
  path: PropType.string.isRequired,
  strokeColor: PropType.oneOfType([PropType.number, PropType.string]).isRequired,
  strokeWidth: PropType.oneOfType([PropType.number, PropType.string]).isRequired,
};

export default entityCropMoveHOC(
  observer(CropOutline)
);
