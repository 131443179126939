import {STATE_STOPPED} from '../../../constants/displayItemConstants';

/**
 * The timer component.
 *
 * @param {number} maxTime
 * @param {number} time
 * @param {string} state
 * @returns {{timer: {time: number, state: string, maxTime: number}}}
 */
export function timerComponent(maxTime, time, state) {
  return {
    timer: {
      maxTime,
      time: time || 0,
      state: state || STATE_STOPPED,
    },
  };
}

/**
 * Gets the timer component from the source item.
 *
 * @param {{}} item
 * @returns {{timer: {time: number, state: string, maxTime: number}}}
 */
export function getTimerFromSource(item) {
  return timerComponent(item.endTime);
}

/**
 * Parses an entity back into source JSON.
 *
 * @returns {{}}
 */
export function getTimerForSource() {
  return {
    timer: {},
  };
}
