/**
 * The name component.
 *
 * @param {string} entityName
 * @returns {{name: string}}
 */
export function nameComponent(entityName) {
  if (!entityName) {
    throw new Error('No name given to nameComponent.');
  }

  const safeEntityName = String(entityName).replace(/\n/g, ' ');

  return {
    name: safeEntityName,
  };
}

/**
 * Gets the name component from the source item.
 *
 * @param {{name: string}} item
 * @returns {{name: string}}
 */
export function getNameFromSource(item) {
  if (!item.name) {
    return {};
  }
  return nameComponent(item.name);
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {ObservableMap} entity
 * @returns {{}}
 */
export function getNameForSource(entity) {
  if (!entity.has('name')) {
    return {};
  }

  return {
    name: String(entity.get('name'))
  };
}
