import lodash from 'lodash';
import {action} from 'mobx';

import ApiBaseStore from '../common/apiBaseStore';
import apiUserGetMeStore from '../user/apiUserGetMeStore';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';

/**
 * ApiContentRequestCreateStore
 */
class ApiContentRequestCreateStore extends ApiBaseStore {
  /**
   * Creates a new content request entry on the server.
   *
   * @param {{}} requestData
   * @param {[]=} requestFiles
   */
  @action makeRequest(requestData, requestFiles) {
    this.state = STATE_PENDING;
    this[EXPIRE_TIME] = Date.now() + EXPIRES_PENDING;

    const requestForm = new FormData();
    lodash.forEach(requestData, (fieldValue, fieldName) => {
      if (Array.isArray(fieldValue)) {
        fieldValue.forEach((subValue) => {
          requestForm.append(`${fieldName}[]`, subValue);
        });
      } else {
        requestForm.append(fieldName, fieldValue);
      }
    });

    if (requestFiles && requestFiles.length) {
      requestFiles.map((file) => {
        requestForm.append('fileIds[]', file.id);
        return file.id;
      });
    }

    serverApi.contentRequestCreate(
      requestForm
    ).then(
      action('contentRequestCreateSuccess', (newContentRequestId) => {
        this.state = STATE_FULFILLED;
        this.data = newContentRequestId;
        this.error = null;
        this[EXPIRE_TIME] = Date.now() + EXPIRES_IN;

        // Refresh the user to get the new request credit count.
        apiUserGetMeStore.refresh(true);
      }),
      action('contentRequestCreateError', (error) => {
        this.state = STATE_REJECTED;
        this.data = null;
        this.error = error;
      })
    );
  }
}

export default new ApiContentRequestCreateStore();
