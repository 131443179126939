/**
 * The action remove entity.
 *
 * @returns {{actionRemoveEntity: {}}}
 */
export function actionRemoveEntityComponent() {
  return {
    actionRemoveEntity: {},
  };
}
