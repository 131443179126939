import {action} from 'mobx';

import apiCollectionGetAllStore from './apiCollectionGetAllStore';
import ApiBaseStore from '../common/apiBaseStore';
import {STATE_PENDING, STATE_FULFILLED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';

/**
 * Creates a new collection.
 */
class ApiCollectionCreateStore extends ApiBaseStore {
  /**
   * Creates a new collection using the api.
   *
   * @param {string} collectionName
   */
  @action makeRequest(collectionName) {
    const safeCollectionName = String(collectionName).trim();

    this.state = STATE_PENDING;
    this.data = null;
    this.error = null;
    this[EXPIRE_TIME] = Date.now() + EXPIRES_PENDING;

    serverApi.collectionCreate(safeCollectionName).then(
      action('collectionCreateSuccess', (newCollectionData) => {
        this.state = STATE_FULFILLED;
        this.data = newCollectionData;
        this.error = null;
        this[EXPIRE_TIME] = Date.now() + EXPIRES_IN;

        apiCollectionGetAllStore.refresh(true);
      })
    ).catch(
      action('collectionCreateError', (collectionCreateError) => {
        this.state = STATE_FULFILLED;
        this.data = null;
        this.error = collectionCreateError;
      })
    );
  }
}

export default new ApiCollectionCreateStore();
