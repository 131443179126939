import {action} from 'mobx';

import ApiBaseStore from '../common/apiBaseStore';
import {STATE_PENDING, STATE_FULFILLED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import serverApi from '../../../utils/serverApi';

/**
 * Creates a new collection example.
 */
class ApiCollectionExampleCreateStore extends ApiBaseStore {
  /**
   * Creates a new collection using the api.
   *
   * @param {number} collectionId
   * @param {{}} collectionExampleData
   */
  @action makeRequest(collectionId, collectionExampleData) {
    this.state = STATE_PENDING;
    this.data = null;
    this.error = null;
    this[EXPIRE_TIME] = Date.now() + EXPIRES_PENDING;

    serverApi.collectionExampleCreate(collectionId, collectionExampleData).then(
      action('collectionExampleCreateSuccess', (newCollectionExampleData) => {
        this.state = STATE_FULFILLED;
        this.data = newCollectionExampleData;
        this.error = null;
        this[EXPIRE_TIME] = Date.now() + EXPIRES_IN;
      })
    ).catch(
      action('collectionExampleCreateError', (collectionExampleCreateError) => {
        this.state = STATE_FULFILLED;
        this.data = null;
        this.error = collectionExampleCreateError;
      })
    );
  }
}

export default new ApiCollectionExampleCreateStore();
