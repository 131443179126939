import {action, computed, observable} from 'mobx';

/**
 * The percent of the display width/height that the panning is allowed to show as empty.
 * @const {number}
 */
const PAN_LIMIT = 0.25;

/**
 * The amount the zoom out can go in past the point where any edges meet (in percentage).
 * @const {number}
 */
const ZOOM_OUT_LIMIT = 0.5;

/**
 * The amount the zoom can go in.
 * @const {number}
 */
const ZOOM_IN_LIMIT = 2;

/**
 * The amount of zoom points from 0 to 1.
 * @const {number}
 */
const ZOOM_POINTS = 100;

/**
 * Makes sure the pan value doesn't pass the max boundary.
 *
 * @param {number} pan
 * @param {number} max
 * @returns {number}
 */
function checkBoundaries(pan, max) {
  if (pan < 0 && max < 0 && pan < max) {
    return max;
  } else if (pan < 0 && max >= 0 && Math.abs(pan) > max) {
    return -max;
  } else if (pan >= 0 && max >= 0 && pan > max) {
    return max;
  } else if (pan >= 0 && max < 0 && pan > Math.abs(max)) {
    return -max;
  }
  return pan;
}

/**
 * The display zoom store.
 */
class DisplayZoomStore {
  /**
   * The width of the display.
   *
   * @type {number}
   */
  @observable displayWidth = 0;

  /**
   * The height of the display.
   *
   * @type {number}
   */
  @observable displayHeight = 0;

  /**
   * The width of the game.
   *
   * @type {number}
   */
  @observable gameWidth = 0;

  /**
   * The height of the game.
   *
   * @type {number}
   */
  @observable gameHeight = 0;

  /**
   * The zoom level of the game.
   *
   * @type {number}
   */
  @observable zoomLevel = ZOOM_POINTS;

  /**
   * The amount the item has been panned horizontally.
   * Positive is right, negative is left.
   *
   * @type {number}
   */
  @observable panX = 0;

  /**
   * The amount the item has been panned vertically.
   * Positive is up, negative is down.
   *
   * @type {number}
   */
  @observable panY = 0;

  /**
   * Calculates the min and max scale amount for the zoom control.
   *
   * @returns {{min: number, max: number, start: number, step: number}}
   */
  @computed get controlData() {
    if (!this.checkSizes()) {
      return null;
    }

    const widthScale = (this.displayWidth / this.gameWidth);
    const heightScale = (this.displayHeight / this.gameHeight);

    const scale = Math.min(heightScale, widthScale);
    const largerScale = Math.max(heightScale, widthScale);

    const start = Math.floor(ZOOM_POINTS * scale);
    const min = Math.min(ZOOM_POINTS, Math.floor(start * ZOOM_OUT_LIMIT));
    const max = Math.max(largerScale * ZOOM_POINTS, Math.floor(ZOOM_POINTS * ZOOM_IN_LIMIT));

    return {
      min,
      max,
      start,
      step: 1, // Do not change this! Adjust ZOOM_POINTS instead.
    };
  }

  /**
   * Calculates the min and max scale amount for the zoom control.
   *
   * @returns {{minX: number, maxX: number, minY: number, maxY: number, step: number}}
   */
  @computed get panData() {
    if (!this.checkSizes()) {
      return null;
    }

    const scale = this.zoomLevel / ZOOM_POINTS;

    const toEdgeOfGameX = ((scale * this.gameWidth) - this.displayWidth) / 2;
    const toEdgeOfGameY = ((scale * this.gameHeight) - this.displayHeight) / 2;
    const maxXValue = Math.floor(toEdgeOfGameX + (this.displayWidth * PAN_LIMIT));
    const maxYValue = Math.floor(toEdgeOfGameY + (this.displayHeight * PAN_LIMIT));

    return {
      minX: (maxXValue > 0) ? -maxXValue : maxXValue,
      minY: (maxYValue > 0) ? -maxYValue : maxYValue,
      maxX: (maxXValue > 0) ? maxXValue : -maxXValue,
      maxY: (maxYValue > 0) ? maxYValue : -maxYValue,
      step: 1,
    };
  }

  /**
   * Gets the data needed to zoom the display using a css transform.
   *
   * @returns {{offsetX: number, offsetY: number, scale: number}}
   */
  @computed get transformData() {
    if (!this.checkSizes()) {
      return null;
    }

    const scale = this.zoomLevel / ZOOM_POINTS;

    const diffX = ((this.displayWidth - this.gameWidth) / 2);
    const diffY = ((this.displayHeight - this.gameHeight) / 2);

    const offsetX = ((this.gameWidth * (1 - scale)) / 2) + diffX + this.panX;
    const offsetY = ((this.gameHeight * (1 - scale)) / 2) + diffY + this.panY;

    return {
      offsetX,
      offsetY,
      scale,
    };
  }

  /**
   * Sets the display width and height.
   *
   * @param {number} newDisplayWidth
   * @param {number} newDisplayHeight
   */
  @action setDisplaySize(newDisplayWidth, newDisplayHeight) {
    this.displayWidth = parseInt(newDisplayWidth, 10);
    this.displayHeight = parseInt(newDisplayHeight, 10);
  }

  /**
   * Sets the game width and height.
   *
   * @param {number} newGameWidth
   * @param {number} newGameHeight
   */
  @action setGameSize(newGameWidth, newGameHeight) {
    this.gameWidth = parseInt(newGameWidth, 10);
    this.gameHeight = parseInt(newGameHeight, 10);
  }

  /**
   * Sets the new zoom level.
   *
   * @param {number} newZoomLevel
   */
  @action setZoomLevel(newZoomLevel) {
    const safeZoomLevel = parseInt(newZoomLevel, 10);
    if (!safeZoomLevel || this.zoomLevel < 1) {
      this.zoomLevel = ZOOM_POINTS;
    } else {
      this.zoomLevel = safeZoomLevel;
    }

    this.setPanValues(this.panX, this.panY);
  }

  /**
   * Sets the new pan values.
   *
   * @param {number} newPanX
   * @param {number} newPanY
   */
  @action setPanValues(newPanX, newPanY) {
    if (!this.panData) {
      return;
    }

    const {maxX, maxY} = this.panData;

    if (newPanX !== null && newPanX !== undefined) {
      this.panX = checkBoundaries(newPanX, maxX);
    }

    if (newPanY !== null && newPanY !== undefined) {
      this.panY = checkBoundaries(newPanY, maxY);
    }
  }

  /**
   * Updates the pan values based on the given deltas (changes).
   *
   * @param {?number} deltaX
   * @param {?number} deltaY
   */
  @action updatePanDelta(deltaX, deltaY) {
    if (!this.panData) {
      return;
    }

    const {maxX, maxY} = this.panData;

    if (deltaX !== null && deltaX !== undefined) {
      this.panX = checkBoundaries(this.panX - deltaX, maxX);
    }

    if (deltaY !== null && deltaY !== undefined) {
      this.panY = checkBoundaries(this.panY - deltaY, maxY);
    }
  }

  /**
   * Makes sure the display and game sizes were defined.
   *
   * @returns {boolean} True if everything is defined, false otherwise.
   */
  checkSizes() {
    if (!this.displayWidth || !this.displayHeight) {
      return false;
    } else if (!this.gameWidth || !this.gameHeight) {
      return false;
    }
    return true;
  }
}

export default new DisplayZoomStore();
