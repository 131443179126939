import {LINE_HEIGHT_FIXED, markdownToClassMap} from '../../components/controls/editText/constants/styleConstants';

/**
 * The whole font tag regexp.
 * @type {RegExp}
 */
const FONT_TAG_REGEXP = /{!?([0-9]+|[a-z]+|\$[0-9.]+|%[0-9]+|#[0-9a-f]+|[\\|/-])}/g;

/**
 * Matches the block (div) font styles, all others are inline (span).
 * @type {RegExp}
 */
const BLOCK_FONT_REGEXP = /{!?([\\|/-])}/g;

/**
 * Matches the color styles.
 * @type {RegExp}
 */
const COLOR_REGEXP = /{(#[0-9a-f]+)}/;

/**
 * Matches the font size styles.
 * @type {RegExp}
 */
const FONT_SIZE_REGEXP = /{([0-9]+)}/;

/**
 * Matches the line height styles.
 * @type {RegExp}
 */
const LINE_HEIGHT_REGEXP = /{\$([0-9.]+)}/;

/**
 * Matches the letter spacing styles.
 * @type {RegExp}
 */
const LETTER_SPACING_REGEXP = /{%([0-9]+)}/;

/**
 * The open character code.
 * @type {number}
 */
const OPEN_CHAR_CODE = 0x7B; // '{'

/**
 * Gets if a character is allowed in the font style.
 *
 * @param {number} ch
 * @returns {boolean}
 */
function isValidCharacter(ch) {
  const toLowerCase = 0x20;
  const aCode = 0x61;
  const zCode = 0x7a;
  const zeroCode = 0x30;
  const nineCode = 0x39;
  const bangCode = 0x21;
  const poundCode = 0x23;
  const dollarCode = 0x24;
  const percentCode = 0x25;

  const leftCode = 0x5c;
  const centerCode = 0x7c;
  const rightCode = 0x2f;
  const justifyCode = 0x2d;

  if (ch >= zeroCode && ch <= nineCode) {
    // If it is a number, return true.
    return true;
  } else if (ch === poundCode || ch === bangCode || ch === dollarCode || ch === percentCode) {
    // If the character is #, !, $, or %, return true.
    return true;
  } else if (ch === leftCode || ch === centerCode || ch === rightCode || ch === justifyCode) {
    // If the character is an alignment character (\|/-), return true.
    return true;
  }

  const lc = ch | toLowerCase; // eslint-disable-line no-bitwise

  // If it is a letter, return true.
  return (lc >= aCode && lc <= zCode);
}

/**
 * Parses out font styles for markdown-it.
 * Looks for these types of tags: {|}, {tahoma}, {15}, {#fa00af}, {$1}, {%1}
 *   Alignment: {\}, {|}, {/}, {-}
 *   Font: {tahoma}, {ariel}, etc
 *   Font Size: {15}, {20}, etc
 *   Font Color: {#fa00af}, {#000000}, etc
 *   Line Height: {$10}, {$29}, etc
 *   Letter Spacing: {%1}, {%10}, etc
 *
 * @param {{}} state
 * @param {boolean} silent
 * @returns {boolean}
 */
function parseFontInline(state, silent) {
  const pos = state.pos;
  const max = state.posMax;

  if (state.src.charCodeAt(pos) !== OPEN_CHAR_CODE || pos + 2 >= max) {
    return false;
  }

  // Quick fail on second character.
  const nextChar = state.src.charCodeAt(pos + 1);
  if (!isValidCharacter(nextChar)) {
    return false;
  }

  const validMatches = state.src.slice(pos).match(FONT_TAG_REGEXP);
  if (!validMatches) {
    return false;
  }

  const match = validMatches[0];
  const isClose = (match[1] === '!');
  const isBlock = Boolean(match.match(BLOCK_FONT_REGEXP));
  const isColor = match.match(COLOR_REGEXP);
  const isFontSize = match.match(FONT_SIZE_REGEXP);
  const isLineHeight = match.match(LINE_HEIGHT_REGEXP);
  const isLetterSpacing = match.match(LETTER_SPACING_REGEXP);

  const className = getClassName(
    match,
    Boolean(isColor),
    Boolean(isFontSize),
    Boolean(isLineHeight),
    Boolean(isLetterSpacing)
  );
  const cssStyles = getCssStyles(match, isColor, isFontSize, isLineHeight, isLetterSpacing);

  if (!silent) {
    const token = state.push('font_inline', isBlock ? 'div' : 'span', 0);
    if (isClose) {
      token.nesting = -1;
    } else {
      token.nesting = 1;
      token.attrs = [['class', className]];

      if (cssStyles) {
        token.attrs.push(['style', cssStyles]);
      }
    }
    token.content = state.src.slice(pos, pos + match.length);
  }

  state.pos += match.length;
  return true;
}

/**
 * Gets the css styles.
 *
 * @param {string} symbol
 * @param {string[]|null} isColor
 * @param {string[]|null} isFontSize
 * @param {string[]|null} isLineHeight
 * @param {string[]|null} isLetterSpacing
 * @returns {string}
 */
function getCssStyles(symbol, isColor, isFontSize, isLineHeight, isLetterSpacing) {
  const cssStyles = [];
  if (isColor) {
    cssStyles.push(`color:${isColor[1]};`);
  } else if (isFontSize) {
    cssStyles.push(`font-size:${isFontSize[1]}px;`);
  } else if (isLineHeight) {
    const heightValue = isLineHeight[1] || 1;
    const adjustedValue = parseFloat(heightValue).toFixed(LINE_HEIGHT_FIXED);
    cssStyles.push(`line-height:${adjustedValue};`);
  } else if (isLetterSpacing) {
    cssStyles.push(`letter-spacing:${isLetterSpacing[1]}px;`);
  }
  return cssStyles.join('');
}

/**
 * Gets the class name for the style.
 *
 * @param {string} tag
 * @param {boolean} isColor
 * @param {boolean} isFontSize
 * @param {boolean} isLineHeight
 * @param {boolean} isLetterSpacing
 * @returns {string}
 */
function getClassName(tag, isColor, isFontSize, isLineHeight, isLetterSpacing) {
  if (isColor) {
    return markdownToClassMap['{#}'];
  } else if (isFontSize) {
    return markdownToClassMap['{0}'];
  } else if (isLineHeight) {
    return markdownToClassMap['{$}'];
  } else if (isLetterSpacing) {
    return markdownToClassMap['{%}'];
  }

  const matchingClasses = markdownToClassMap[tag];
  return matchingClasses || '';
}

/**
 * Parses the font styles as a markdown-it plugin.
 *
 * @param {{}} md
 */
export function fontStylesPlugin(md) {
  md.inline.ruler.after('emphasis', 'font_inline', parseFontInline);
}
