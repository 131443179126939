import lodash from 'lodash';
import {observer} from 'mobx-react';
import {observable, action, computed} from 'mobx';
import React from 'react';
import PropTypes from 'prop-types';

import {TRANSITION_LIBRARY_ITEMS} from './transitionItems';
import TransitionLibraryItem from '../transitionLibraryItem/TransitionLibraryItem';

import inject from '../../../../hoc/injectHoc';

import './transitionLibraryItemList.scss';

let initialLibraryItems = Object.entries(TRANSITION_LIBRARY_ITEMS).map(([key, value]) => {
  const [firstOption] = Object.keys(value.presets);

  return {
    key,
    ...value,
    selectedDirection: firstOption,
  };
});

/**
 * The TransitionEntityList component.
 */
class TransitionEntityList extends React.Component {
  /**
   * The transition entities to be shown in the library.
   *
   * @type {Array}
   */
  @observable transitionEntities = initialLibraryItems;

  /**
   * The active transition entity key name.
   *
   * @type {?string}
   */
  @observable activeTransitionEntityKey = null;

  /**
   * Only show valid transitions based on the entity type. i.e. don't show text transitions on shape entities
   */
  @computed get validTransitionEntities() {
    const {entity} = this.props;

    const elementName = entity.get('element');

    const validTransitionEntities = lodash.reduce(this.transitionEntities, (allowed, transitionEntity) => {
      const allowedElement = transitionEntity.elements === true || lodash.includes(transitionEntity.elements, elementName);
      if (allowedElement) {
        allowed.push(transitionEntity);
      }

      return allowed;
    }, []);

    return validTransitionEntities;
  }

  /**
   * Update the transition entities
   *
   * @param {{label: string, value: string}} selectedDirection
   * @param {string} transitionKey
   */
  @action onChangeTransitionDirection = (selectedDirection, transitionKey) => {
    const transitionIndex = this.transitionEntities.findIndex((entity) => entity.key === transitionKey);
    const direction = selectedDirection.value;
    this.transitionEntities[transitionIndex] = {
      ...this.transitionEntities[transitionIndex],
      selectedDirection: direction
    };

    this.onSelectTransitionEntity(transitionKey);
  }

  /**
   * Select a transition
   *
   * @param {string} transitionKey
   */
  @action onSelectTransitionEntity = (transitionKey) => {
    const {onSelect} = this.props;

    const transition = this.transitionEntities.find((entity) => entity.key === transitionKey);
    const selectedTransitionKeys = {
      transition: transition.key,
      direction: transition.selectedDirection
    };
    onSelect(selectedTransitionKeys);

    this.activeTransitionEntityKey = transition.key;
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    return (
      <>
        <div className="transition-entity-list d-flex flex-wrap container-fluid row no-gutters">
          {this.validTransitionEntities.map((transitionEntity) => {
            const isActive = this.activeTransitionEntityKey === transitionEntity.key;

            return (
              <TransitionLibraryItem
                key={`${transitionEntity.key}`}
                transitionEntity={transitionEntity}
                onChangeDirection={(e) => this.onChangeTransitionDirection(e, transitionEntity.key)}
                onClick={() => this.onSelectTransitionEntity(transitionEntity.key)}
                isActive={isActive}
              />
            );
          })}
        </div>
      </>
    );
  }
}

TransitionEntityList.propTypes = {
  entity: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

TransitionEntityList.defaultProps = {
};

TransitionEntityList.wrappedComponent = {};
TransitionEntityList.wrappedComponent.propTypes = {
};

export default inject(TransitionEntityList)(
  observer(TransitionEntityList)
);
