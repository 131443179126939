import React, {Component} from 'react';
import PropTypes from 'prop-types';

import nextLEDLogo from '../../../../../assets/images/next-logo-horizontal.png';

import './signManufacturerBanner.scss';

const bannerValues = {
  nextled: {
    leftRightColorClass: 'nextled',
    logo: nextLEDLogo,
    creditText: '$600 credit',
    creditTextColor: 'red',
  }
};

/**
 * The SignManufacturerBanner component.
 */
class SignManufacturerBanner extends Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {signManufacturer} = this.props;

    if (!bannerValues[signManufacturer]) {
      return (<></>);
    }

    const {logo, leftRightColorClass, creditText, creditTextColor = 'red'} = bannerValues[signManufacturer];

    return (
      <div className="banner-container">
        <div className={`left-color ${leftRightColorClass}`} />
        <div className="banner-text">Do you have a
          <img
            className="banner-text-logo"
            src={logo}
            alt={`${signManufacturer} Logo`}
          />
          sign? Claim your <span className={creditTextColor}>{creditText}</span> toward a paid plan below.</div>
        <div className={`right-color ${leftRightColorClass}`} />
      </div>
    );
  }
}

SignManufacturerBanner.propTypes = {
  signManufacturer: PropTypes.oneOf(['nextled']),
};

export default SignManufacturerBanner;
