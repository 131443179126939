import lodash from 'lodash';
import {action, observable, runInAction, toJS, transaction} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import ErrorBoundary from '../../common/errorBoundary/ErrorBoundary';
import LoadingIcon from '../../common/loadingIcon/LoadingIcon';
import DisplayControl from '../../controls/display/DisplayControl';
import PlaybackControls from '../../controls/playback/PlaybackControls';
import ZoomControl from '../../controls/zoom/ZoomControl';
import Display from '../../display/display/Display';
import inject from '../../hoc/injectHoc';
import {editorRoute} from '../../routePaths';
import EditorSide from './components/editorSide/EditorSide';
import SelectSignModal from '../../modals/selectSign/SelectSignModal';
import {CONTENT_NEW} from '../../../constants/editorConstants';
import {parseSourceForNewGame} from '../../../display/ecs/sourceHelper';
import {loadGameFromSource} from '../../../display/game';
import {blockTransition, routeHistory} from '../../../utils/history';
import {replaceRouteParams} from '../../../utils/routeHelper';
import {changeDisplayEditorStoreDimensions} from '../../../utils/displayEditorStoreHelper';
import {SUPER_ADMIN_ROLE} from '../../../constants/userConstants';

// import source from '../../../display/source.json';

import './editorPage.scss';

/**
 * If display height adjustment fails, this will be the default height.
 * @const {number}
 */
const DEFAULT_DISPLAY_HEIGHT = 360;

/**
 * The updateDisplaySize function can only run once per this many milliseconds.
 * @const {number}
 */
const THROTTLE_TIME = 750;

/**
 * The height of the playback controls with timelines. Needs to be the same as found in the playbackControls.scss file.
 * @const {number}
 */
const PLAYBACK_CONTROLS_HEIGHT = 250;

/**
 * The EditorPage component.
 */
export class EditorPage extends React.Component {
  /**
   * The currently loaded aspect ratio.
   *
   * @type {?string}
   */
  @observable loadedAspectRatio = null;

  /**
   * Whether or not the user has permission to view this page.
   *
   * @type {?boolean}
   */
  @observable canViewPage = null;

  /**
   * Flag to show/hide sign select modal
   *
   * @type {boolean}
   */
  @observable showSignSelectModal = false;

  /**
   * Pre-selected sign id for sign select modal
   *
   * @type {?number}
   */
  @observable preselectedSignId = null;

  /**
   * The current height of the display.
   * This will change as the window resizes.
   *
   * @type {number}
   */
  @observable displayHeight = DEFAULT_DISPLAY_HEIGHT;

  /**
   * The current height of the playback controls.
   * This will change as the window resizes.
   *
   * @type {number}
   */
  @observable playbackControlsHeight = PLAYBACK_CONTROLS_HEIGHT;

  /**
   * The updateDisplaySize function throttled so it doesn't fire too much while dragging the frame.
   *
   * @type {?function}
   */
  throttledUpdateDisplaySize = null;

  /**
   * The function used to remove the block on router transitions.
   *
   * @type {?function}
   */
  unblockRouterTransitions = null;

  /**
   * @constructor
   * @param {{}} props
   * @param {{}} componentContext
   */
  constructor(props, componentContext) {
    super(props, componentContext);

    this.throttledUpdateDisplaySize = lodash.throttle(this.updateDisplaySize, THROTTLE_TIME);
  }

  /**
   * Triggered when the component is added to the page.
   */
  async componentDidMount() {
    window.addEventListener('resize', this.throttledUpdateDisplaySize);
    window.addEventListener('beforeunload', this.onPageUnload);

    const {apiUserGetMeStore, apiCompanySignGetAllStore} = this.props;

    apiUserGetMeStore.refresh();
    apiUserGetMeStore.getPromise().then((user) => {
      runInAction('editorPageUserLoaded', () => {
        this.canViewPage = !!user.role;
      });
    }).catch(() => {
      runInAction('editorPageUserError', () => {
        this.canViewPage = false;
      });
    });
    apiCompanySignGetAllStore.refresh();

    runInAction('editorPageDefinedUnblock', () => {
      this.unblockRouterTransitions = blockTransition(() => {
        if (!this.hasChanges()) {
          return undefined;
        }
        return 'You have unsaved changes. Are you sure you want to navigate away?';
      });
    });

    try {
      const signs = await apiCompanySignGetAllStore.getPromise();
      const user = await apiUserGetMeStore.getPromise();
      const contentId = this.getContentId(this.props);

      const isSuperAdmin = user.role === SUPER_ADMIN_ROLE;

      await this.preloadContent();

      if (
        contentId === CONTENT_NEW
        && !isSuperAdmin
        && signs.length === 1
      ) {
        this.changeDimensions(signs[0].aspectRatio);
      } else if (
        contentId === CONTENT_NEW
        && !isSuperAdmin
        && signs.length > 1
      ) {
        runInAction('setShowSignSelectModal', () => {
          this.preselectedSignId = signs[0].id;
          this.showSignSelectModal = true;
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Error fetching signs', error);
    }
  }

  /**
   * Triggered when the props change.
   *
   * @param {{}} prevProps
   */
  componentDidUpdate(prevProps) {
    if (prevProps.match.params.contentId !== this.props.match.params.contentId) {
      this.preloadContent();
    }
  }

  /**
   * Triggered when the component is removed from the page.
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledUpdateDisplaySize);
    window.removeEventListener('beforeunload', this.onPageUnload);

    if (this.unblockRouterTransitions) {
      this.unblockRouterTransitions();
    }
  }

  /**
   * Triggered when the component reacts to MobX changes.
   */
  componentWillReact() {
    const {displayEditorStore} = this.props;

    const currentAspectRatio = displayEditorStore.currentAspectRatio;

    if (this.loadedAspectRatio && currentAspectRatio && this.loadedAspectRatio !== currentAspectRatio) {
      const newSource = toJS(displayEditorStore.sources.get(String(currentAspectRatio)));

      this.initGame(newSource);

      runInAction('editorPageSetUpdatedAspectRatio', () => {
        this.loadedAspectRatio = currentAspectRatio;
      });

      // recalcuate display. this fixes a bug where if 2 different aspect ratios
      // have a different amount of layers, the display element will properly resize
      // based on the height of the playback controls (See https://projectcontent.atlassian.net/browse/PC-404)
      // we use setTimeout(fn, 0) so the update happens after current js thread execution. This is so the element
      // heights are properly set before we measure the height
      setTimeout(this.updateDisplaySize, 0);
    }
  }

  /**
   * Updates the aspet ratio of the system.
   * @param {string} newAspectRatio
   * @returns {void}
   */
  @action changeDimensions = (newAspectRatio) => {
    const {displayEditorStore} = this.props;

    changeDisplayEditorStoreDimensions(displayEditorStore, newAspectRatio);
  }

  @action onSignSelect = ({
    signId,
    confirmAction
  }) => {
    const {apiCompanySignGetAllStore} = this.props;
    const signs = apiCompanySignGetAllStore.getFulfilled();

    if (confirmAction === 'Next') {
      const matchingSign = signs.find((sign) => sign.id === signId);
      this.changeDimensions(matchingSign.aspectRatio);
    } else {
      this.changeDimensions(signs[0].aspectRatio);
    }

    this.showSignSelectModal = false;
  }

  /**
   * Whether or not there are changes to the game/video/image.
   *
   * @returns {boolean}
   */
  hasChanges = () => {
    const {displayEditorStore} = this.props;
    if (!displayEditorStore.game || !displayEditorStore.game.history) {
      return false;
    }

    const {hasUndo, hasRedo} = displayEditorStore.game.history;
    return (hasUndo || hasRedo);
  };

  /**
   * Triggered when the page attempts to unload if changes have been made.
   * This will open a confirm dialog using the returned string.
   *
   * @param {{}} unloadEvent
   */
  onPageUnload = (unloadEvent) => {
    if (this.hasChanges()) {
      unloadEvent.returnValue = 'You have unsaved changes. Are you sure you want to navigate away?';
    }
  };

  /**
   * Doesn't show the unsaved changes message when redirecting the page.
   */
  @action unblockRouterTransition = () => {
    if (this.unblockRouterTransitions) {
      this.unblockRouterTransitions();
    }
  }

  /**
   * Refreshes the page when the content is created.
   *
   * @param {number} newContentId
   */
  @action onContentCreated = (newContentId) => {
    this.unblockRouterTransition();

    routeHistory.replace(
      replaceRouteParams(editorRoute, {contentId: newContentId})
    );
  };

  /**
   * Gets the content id from the url params.
   *
   * @param {{match: {params: {contentId: number}}}=} props
   * @returns {?number}
   */
  getContentId = (props) => {
    return (props || this.props).match.params.contentId;
  };

  /**
   * Gets the content promise.
   *
   * @returns {Promise<{id: ?number, height: number, width: number}>}}
   */
  fetchContent = async () => {
    const safeProps = this.props;

    const {apiContentGetOneStore, apiUserGetMeStore, apiCompanySignGetAllStore} = safeProps;
    apiUserGetMeStore.refresh();
    const isUserMade = await apiUserGetMeStore.getPromise().then((user) => user.role !== SUPER_ADMIN_ROLE);
    const isSuperAdmin = await apiUserGetMeStore.getPromise().then((user) => user.role === SUPER_ADMIN_ROLE);

    const contentId = this.getContentId(safeProps);

    if (contentId === CONTENT_NEW) {
      const startingAspectRatio = '16:9';
      const startingHeight = 1080;
      const startingWidth = 1920;

      return {
        id: null,
        height: startingHeight,
        width: startingWidth,
        aspectRatio: startingAspectRatio,
        variables: {},
        sources: {
          [startingAspectRatio]: parseSourceForNewGame(startingWidth, startingHeight),
        },
        isUserMade,
        isFree: isUserMade
      };
    }

    apiContentGetOneStore.makeRequest(contentId);
    const contentData = await apiContentGetOneStore.getPromise(contentId);

    // if user is not admin, find sources that match up with sign.
    // This filters out the 16:9 preview source
    if (!isSuperAdmin) {
      const signs = await apiCompanySignGetAllStore.getPromise();

      contentData.sources = Object.keys(contentData.sources).reduce((acc, aspectRatioKey) => {
        const source = contentData.sources[aspectRatioKey];
        if (signs.find((sign) => sign.aspectRatio === aspectRatioKey)) {
          acc[aspectRatioKey] = source;
        }

        return acc;
      }, {});
    }

    return contentData;
  };

  /**
   * Preloads the content for the page.
   *
   * @returns {Promise}
   */
  preloadContent = async () => {
    const {displayEditorStore} = this.props;

    displayEditorStore.setGame(null);
    displayEditorStore.setTimer(null);
    displayEditorStore.setContent(null);

    return this.fetchContent().then((contentData) => {
      const aspectRatio = contentData.aspectRatio || lodash.first(lodash.keys(contentData.sources));

      displayEditorStore.setAllSources(contentData.sources);
      displayEditorStore.setAllVariables(contentData.variables);
      displayEditorStore.setCurrentAspectRatio(aspectRatio);
      displayEditorStore.setDefaultAspectRatio(aspectRatio);
      displayEditorStore.setContent(contentData);

      runInAction('editorPageSetLoadedAspectRatio', () => {
        this.loadedAspectRatio = aspectRatio;
      });

      const safeSource = contentData.sources[aspectRatio];
      if (!safeSource || safeSource instanceof Error) {
        if (safeSource instanceof Error) {
          console.error(safeSource); // eslint-disable-line no-console
        }
        throw new Error('No editor source found.');
      }

      this.initGame(safeSource);
    }).catch((initError) => {
      displayEditorStore.setContent(initError);
      console.error(initError); // eslint-disable-line no-console
    });
  };

  /**
   * Initializes the game from the source and makes sure the timer is setup properly.
   *
   * @param {{}} source
   */
  initGame = (source) => {
    if (!source) {
      return;
    }
    const {displayEditorStore, displayZoomStore} = this.props;

    const safeVariables = toJS(displayEditorStore.variables);

    const {game, timer} = loadGameFromSource(source, safeVariables, true);
    const gameResolution = game.resolution;

    // We want the requestAnimationFrame going at all times, so prime the timer (start and reset).
    timer.start();
    timer.stopAndReset();

    // Initialize game and timer before adding them to the store.
    displayEditorStore.setTimer(timer);
    displayEditorStore.setGame(game);

    transaction(() => {
      displayZoomStore.setGameSize(gameResolution.width, gameResolution.height);
      this.updateDisplaySize();

      const zoomStart = lodash.get(displayZoomStore.controlData, 'start');
      if (zoomStart) {
        displayZoomStore.setZoomLevel(zoomStart);
      }
    });
  };

  /**
   * Updates the zoom display size.
   */
  updateDisplaySize = () => {
    const {displayEditorStore, displayZoomStore} = this.props;
    if (!displayEditorStore.game) {
      return;
    }

    const editorPage = document.getElementById('main-page');
    if (!editorPage) {
      return;
    }

    const playbackControlsEl = document.getElementById('playback-controls');

    runInAction('editorPageUpdateHeight', () => {
      if (playbackControlsEl) {
        this.playbackControlsHeight = playbackControlsEl.clientHeight;
      }
      this.displayHeight = editorPage.clientHeight;
    });

    const displayEl = document.getElementById('display');

    if (displayEl) {
      displayZoomStore.setDisplaySize(displayEl.clientWidth, (this.displayHeight - this.playbackControlsHeight));
    }
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {displayEditorStore, displayZoomStore} = this.props;
    const {game, timer} = this.props.displayEditorStore;

    if (this.canViewPage === null) {
      return (
        <div id="editor-page" className="system-page">
          <div className="col">
            <LoadingIcon />
          </div>
        </div>
      );
    } else if (!this.canViewPage) {
      return (
        <div id="editor-page" className="no-permissions">
          <span>You must be logged in to access the Canvas Editor.</span>
        </div>
      );
    }

    if (game) {
      // Allows the componentWillReact to trigger when the currentAspectRatio is updated.
      displayEditorStore.currentAspectRatio; // eslint-disable-line no-unused-expressions
    }

    if (this.showSignSelectModal) {
      return (<SelectSignModal
        isOpen={true}
        confirmButtonText="Next"
        modalBodyUpperSlot={(<p className="small">
          Choose a sign profile to begin:
        </p>)}
        modalHeaderText="New Canvas Project"
        preselectedSignId={this.preselectedSignId}
        onComplete={this.onSignSelect}
      />);
    }

    const content = displayEditorStore.content;
    if (!content) {
      return (
        <div id="editor-page" className="system-page">
          <div className="col">
            <LoadingIcon />
          </div>
        </div>
      );
    } else if (!game || content instanceof Error) {
      return (
        <div id="editor-page" className="system-page">
          <div className="col">
            <div className="alert alert-warning">
              Could not load editor because no source was found.
            </div>
          </div>
        </div>
      );
    }

    return (
      <div id="editor-page" className="system-page">
        <ErrorBoundary message="An error occurred and the editor could not be loaded.">
          <EditorSide content={content} onCreated={this.onContentCreated} unblockRouterTransition={this.unblockRouterTransition} />

          <div className="main-display">
            <div className="display-wrapper">
              <Display game={game} height={this.displayHeight - this.playbackControlsHeight} zoomStore={displayZoomStore} />
            </div>

            {(game && game.hasTimeLine()) && (
              <PlaybackControls game={game} timer={timer} allowDurationChange={true} />
            )}

            <div className="display-controls">
              <div className="controls-wrapper">
                <DisplayControl game={game} zoomStore={displayZoomStore} />
              </div>
              <div className="controls-wrapper">
                <ZoomControl />
              </div>
            </div>
          </div>
        </ErrorBoundary>
      </div>
    );
  }
}

EditorPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,

  apiCompanySignGetAllStore: MobxPropTypes.observableObject,
  apiContentGetOneStore: MobxPropTypes.observableObject,
  apiUserGetMeStore: MobxPropTypes.observableObject,
  displayEditorStore: MobxPropTypes.observableObject,
  displayZoomStore: MobxPropTypes.observableObject,
};

EditorPage.wrappedComponent = {};
EditorPage.wrappedComponent.propTypes = {
  apiCompanySignGetAllStore: MobxPropTypes.observableObject.isRequired,
  apiContentGetOneStore: MobxPropTypes.observableObject.isRequired,
  apiUserGetMeStore: MobxPropTypes.observableObject.isRequired,
  displayEditorStore: MobxPropTypes.observableObject.isRequired,
  displayZoomStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(EditorPage)(
  observer(EditorPage)
);
