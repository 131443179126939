import lodash from 'lodash';

import {EASINGS} from '../../ecs/easingHelper';
import {PARSE_OFF, PARSE_POSITION} from '../../ecs/transitionHelper';
import flyInLeftGif from '../../../assets/images/transitions/flyInLeft.gif';

/**
 * The name that signals this preset.
 * @const {string}
 */
export const PRESET_FLY_OUT_LEFT = 'flyOutLeft';

/**
 * The preset details.
 *
 * @type {{
 *   name: string,
 *   flow: string,
 *   details: Object.<string, {
 *     easing: string,
 *     parse: boolean,
 *     values: *|Array.<*>,
 *     time: {start: (string|number), end: (string|number)}
 *   }>,
 *   template: {}
 * }}
 */
export const flyOutLeftPresetDetails = {
  // The name of the preset.
  name: PRESET_FLY_OUT_LEFT,

  // A human friendly name for the preset.
  humanName: 'Fly Out From Left',

  // A preview of the transition.
  gif: flyInLeftGif,

  // The element names that this preset can be used for or true for all elements.
  elements: true,

  // The preset direction: in, out, middle. This is used in the UI logic.
  flow: 'out',

  // Each property that will change during the transition.
  details: {
    'position.x': {
      // The name of the easing this property will use.
      easing: EASINGS.easeInQuad,

      // The start and end times for the transition of this property.
      time: {
        start: 'end.-1000',
        end: 'end.0',
      },

      // The value of the property that will change during the transition.
      values: [`${PARSE_POSITION}.left`, `${PARSE_OFF}.left`],
      immediateRender: false
    },
  },

  // How this preset can be used in the source (including optional parameters).
  // This will be turned into a JSON string, so no functions can be used.
  template: {
    preset: PRESET_FLY_OUT_LEFT,
    options: {},
  },
};

flyOutLeftPresetDetails.template.options = lodash.cloneDeep(flyOutLeftPresetDetails.details);

// This defines the key in the presets object.
export const presetName = PRESET_FLY_OUT_LEFT;

// This defines the value of the presets object.
export default flyOutLeftPresetDetails;
