import lodash from 'lodash';
import {action} from 'mobx';

import apiContentGetOneStore from './apiContentGetOneStore';
import ApiBaseStore from '../common/apiBaseStore';
import apiUserGetMeStore from '../user/apiUserGetMeStore';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../../constants/storeConstants';
import {toJSRecursive} from '../../../utils/mobxHelper';
import {reportIssue} from '../../../utils/sentryHelper';
import serverApi from '../../../utils/serverApi';

/**
 * ApiContentUpdateStore
 */
class ApiContentUpdateStore extends ApiBaseStore {
  /**
   * Updates the JSON source of the active content record.
   *
   * @param {number} contentId
   * @param {Object.<string, {}>} sources
   * @param {{}} newVariables
   * @param {boolean} isFree
   * @param {boolean} isDraft
   * @param {boolean} isUserMade
   */
  updateActiveContentSource(contentId, sources, newVariables, isFree, isDraft, isUserMade) {
    if (!contentId) {
      return;
    }

    const contentBody = {
      id: contentId,
    };

    if (sources) {
      contentBody.sources = sources;
    }

    if (newVariables) {
      contentBody.variables = newVariables;
    }

    contentBody.isFree = Boolean(isFree);
    contentBody.isDraft = Boolean(isDraft);
    contentBody.isUserMade = Boolean(isUserMade);

    this.makeRequest(contentId, contentBody);
  }

  /**
   * Fetches content info from the server
   *
   * @param {number} contentId
   * @param {{sources: {}}} contentUpdates
   */
  @action makeRequest(contentId, contentUpdates) {
    if (!contentId) {
      this.state = STATE_REJECTED;
      this.error = new Error('Invalid content id given to contentUpdate.');
      return;
    } else if (!contentUpdates || !lodash.size(contentUpdates)) {
      this.state = STATE_REJECTED;
      this.error = new Error('Invalid content update data given to contentUpdate.');
      return;
    }

    const safeContentData = lodash.clone(toJSRecursive(contentUpdates));

    const safeContentId = String(contentId);
    const safeUpdateContent = lodash.clone(safeContentData);
    lodash.unset(safeUpdateContent, 'id');
    lodash.unset(safeUpdateContent, 'sources');

    if (!lodash.size(safeUpdateContent)) {
      reportIssue(new Error('Content save has no data after no id or sources.'), {
        givenContentData: contentUpdates,
        safeContentData,
      });
    }

    this.state = STATE_PENDING;
    this[EXPIRE_TIME] = Date.now() + EXPIRES_PENDING;

    serverApi.contentUpdate(
      safeContentId,
      safeUpdateContent
    ).then(() => {
      if (!safeContentData.sources) {
        return false;
      }

      return this.updateContentSources(safeContentId, safeContentData.sources);
    }).then(
      action('contentUpdateSuccess', () => {
        this.error = null;
        this.state = STATE_FULFILLED;
        this.data = true;
        this[EXPIRE_TIME] = Date.now() + EXPIRES_IN;

        // Clear the content cache.
        apiContentGetOneStore.refresh(safeContentId, true);

        // Refresh the user to get the new download credit count.
        apiUserGetMeStore.refresh(true);
      }),
      action('contentUpdateError', (error) => {
        this.error = error;
        this.state = STATE_REJECTED;
        this.data = false;
      })
    );
  }

  /**
   * Updates the content sources.
   *
   * @param {number} contentId
   * @param {Object.<string, {}>} sources
   * @returns {Promise}
   */
  updateContentSources(contentId, sources) {
    if (!sources) {
      return Promise.resolve(false);
    }

    const updatedSources = {};
    lodash.forEach(sources, (source, aspectRatio) => {
      updatedSources[aspectRatio] = {...source, aspectRatio};
    });

    return serverApi.contentSourceSetAll(contentId, updatedSources);
  }
}

export default new ApiContentUpdateStore();
