import {action, observable} from 'mobx';

import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import serverApi from '../../../utils/serverApi';
import ApiBaseStore from '../common/apiBaseStore';

/**
 * The user store to check if a user can update their payment plan
 */
class ApiUserCanUpdatePlanStore extends ApiBaseStore {
  /**
   * Flag to determine if user can update their plan
   *
   * @type {boolean}
   */
  @observable userCanUpdatePlan = false;

  /**
   * Posts userOnboard to the server.
   *
   * @param {{}} body
   * @param {string} body.planId
   * @param {string=} body.paymentToken
   * @param {number} body.quantity
   * @param {{}=} body.signData
   * @param {{}=} body.userData
   */
  @action makeRequest() {
    if (this.state === STATE_PENDING) {
      // Prevent double requests.
      return;
    }

    this.state = STATE_PENDING;

    serverApi.canUserUpdatePlan().then(
      action('getUserCanUpdatePlanSuccess', (data) => {
        this.error = null;
        this.state = STATE_FULFILLED;
        this.userCanUpdatePlan = data;
      }),
      action('getUserCanUpdatePlanError', (userCanUpdateError) => {
        this.error = userCanUpdateError;
        this.state = STATE_REJECTED;
      })
    );
  }
}

export default new ApiUserCanUpdatePlanStore();
